import {
    accountsDefinition,
    bookkeepingDefinition,
    giftcardsDefinition,
    hourSalesDefinition,
    inventoryProductNotesDefinition,
    inventoryProductsDefinition,
    invoicesDefinition,
    paymentsDefinition,
    productProfitDefinition,
    productSalesDefinition,
    ReportDefinition,
    ReportTypes,
} from "lib";

export const availableReportDefinitions: Record<
    keyof typeof ReportTypes,
    ReportDefinition
> = {
    [ReportTypes.ACCOUNTS]: accountsDefinition,
    [ReportTypes.BOOKKEEPING]: bookkeepingDefinition,
    [ReportTypes.GIFTCARDS]: giftcardsDefinition,
    [ReportTypes.HOUR_SALES]: hourSalesDefinition,
    [ReportTypes.INVOICES]: invoicesDefinition,
    [ReportTypes.PAYMENTS]: paymentsDefinition,
    [ReportTypes.INVENTORY_PRODUCT_NOTES]: inventoryProductNotesDefinition,
    [ReportTypes.PRODUCT_INVENTORY]: inventoryProductsDefinition,
    [ReportTypes.PRODUCT_PROFIT]: productProfitDefinition,
    [ReportTypes.PRODUCT_SALES]: productSalesDefinition,
};
