import type { StyleFunction } from "@venuepos/react-common";

export const reportStyleFunc: StyleFunction = theme => ({
    row: {
        justifyContent: "flex-start",
        flexDirection: "row",
        flexWrap: "wrap",
        minWidth: theme.dimensions.maxFormWidth,
    },
    rowReverse: {
        flexDirection: "row-reverse",
    },
    flex: {
        flex: 1,
    },
    inputCell: {
        flex: 1,
        minWidth: 150,
        maxWidth: "50%",
        paddingHorizontal: theme.spacingScale,
    },

    // Used in some cases to override the maxWidth of .inputCell
    inputCellMax: {
        maxWidth: undefined,
    },
});
