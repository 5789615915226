import type { IMerchantCreate, IMerchantInput } from "./IMerchant";
import type { ValidationSchema } from "../validation";

export const schemaMerchantCreate: ValidationSchema<IMerchantCreate> = {
    name: { required: true },
    email: { required: true },
    currency: { required: true },
    giftcardVatBookkeepingMethod: { required: true },
};

export const schemaMerchantInput: ValidationSchema<IMerchantInput> = {
    name: { required: true },
};
