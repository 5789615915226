import { ApolloError } from "@apollo/client";
import { StackScreenProps } from "@react-navigation/stack";
import {
    Alert,
    Button,
    Loading,
    PrinterWrapper,
    StyleFunction,
    Surface,
    Text,
    useAuth,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQCardTransactionQuery, useCardTransactionQuery } from "graphql-sdk";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import React from "react";

import { RootStackParamList } from "../../../navigation";
import { AdminContainer } from "../../container";

type ScreenProps = StackScreenProps<
    RootStackParamList,
    "CARD_TRANSACTION_VIEW"
>;

export function CardTransactionScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.invoice");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const transactionId = route.params.id;
    const { loading, error, data } = useCardTransactionQuery({
        variables: {
            id: transactionId,
        },
        fetchPolicy: "no-cache",
    });

    return (
        <AdminContainer>
            <View>
                <Button
                    style={styles.backButton}
                    size="small"
                    iconName="chevron-left"
                    onPress={() => navigate("CARD_TRANSACTIONS")}
                >
                    {t("common.back", "Back")}
                </Button>
            </View>
            <Transaction loading={loading} error={error} data={data} />
        </AdminContainer>
    );
}

function Transaction({
    loading,
    error,
    data,
}: {
    loading: boolean;
    error?: ApolloError;
    data?: GQCardTransactionQuery;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    if (loading) {
        return <Loading />;
    }

    if (error) {
        <Alert type="error">
            {t(
                "backoffice.error.from_server",
                "There was an error: {{errorText}}",
                {
                    errorText: error.message,
                }
            )}
        </Alert>;
    }

    if (!data?.cardTransaction.receipt) {
        return (
            <Alert type="warning">
                {t(
                    "backoffice.card_transaction.missing",
                    "The card transaction receipt is missing."
                )}
            </Alert>
        );
    }

    return (
        <PrinterWrapper>
            <Surface>
                <Text style={styles.receipt}>
                    {data?.cardTransaction.receipt}
                </Text>
            </Surface>
        </PrinterWrapper>
    );
}

const styleFunc: StyleFunction = theme => ({
    backButton: {
        width: theme.spacingScale * 10,
        marginBottom: theme.spacingScale,
    },
    receipt: {
        ...theme.fonts.monospace,
        fontSize: 14,
    },
});
