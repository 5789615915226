import { useNavigation } from "@react-navigation/core";
import {
    Button,
    Headline,
    InputControl,
    Spacer,
    Surface,
    Text,
    TextInput,
    useMe,
    useTheme,
} from "@venuepos/react-common";
import { useUserDeleteMutation } from "graphql-sdk";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export function UserDeleteForm({
    username,
    userId,
}: {
    username: string;
    userId: string;
}) {
    const { navigate } = useNavigation();
    const [deleteUserQuery] = useUserDeleteMutation();
    const me = useMe();
    const theme = useTheme();
    const [t] = useTranslation();
    const [enteredUsername, setEnteredUsername] = useState<string>("");
    const [error, setError] = useState<string>();

    const deleteUser = useCallback(async () => {
        if (enteredUsername !== username) {
            setError(
                t(
                    "backoffice.user_form.wrong_username",
                    "You have not entered the correct username."
                )
            );
            return;
        } else {
            setError(undefined);
        }

        await deleteUserQuery({ variables: { id: userId } });

        navigate("USERS");
    }, [deleteUserQuery, enteredUsername, navigate, t, userId, username]);

    return (
        <Surface>
            <Headline size="h3">
                {t("backoffice.user_form.delete_headline", "Delete user")}
            </Headline>
            {userId === me.user?.id ? (
                <Text>
                    {t(
                        "backoffice.user_form.cannot_delete_yourself",
                        "You can not delete your self."
                    )}
                </Text>
            ) : (
                <>
                    <Text>
                        {t(
                            "backoffice.user_form.delete_info",
                            "To delete this user, you'll have to enter the username of user into the field below, and click the delete button."
                        )}
                    </Text>
                    <Spacer space={2} />
                    <InputControl error={error}>
                        <TextInput
                            onChangeText={text => setEnteredUsername(text)}
                        />
                    </InputControl>
                    <Button
                        style={{ backgroundColor: theme.colors.error }}
                        onPress={deleteUser}
                    >
                        {t("backoffice.user_form.delete_button", "Delete user")}
                    </Button>
                </>
            )}
        </Surface>
    );
}
