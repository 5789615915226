import {
    Alert,
    Button,
    ColorIndicator,
    DataTable,
    Icon,
    IconButton,
    Loading,
    SearchInput,
    Surface,
    useAuth,
    usePagination,
    useTheme,
} from "@venuepos/react-common";
import { useIntegrationsQuery } from "graphql-sdk";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";
import { IntegrationTypes } from "lib";

type ScreenProps = RootStackScreenProps<"INTEGRATIONS">;

export function IntegrationsScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.merchant_settings");

    const [t] = useTranslation();
    const theme = useTheme();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({ initialSortBy: "name", initialSortDirection: "ASC" });
    const [search, setSearch] = useState<string>("");
    const integrationTypesArray: IntegrationTypes[] = [
        "E-CONOMIC",
        "PARTNER-API",
        "VENUE-ACCESS",
    ];

    const { data, loading, error, refetch } = useIntegrationsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const handleEdit = useCallback(
        (id: string) => {
            navigate("INTEGRATION_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list (in case the user changed to a different page)
            onPageChange(0);
        },
        [onPageChange]
    );

    const renderIntegrationRow = useCallback(
        (item, index) => (
            <DataTable.Row key={index.toString()}>
                <DataTable.Cell
                    onPress={() => handleEdit(item.id)}
                    style={styles.iconColumn}
                >
                    <ColorIndicator
                        status={item.active ? "success" : "warning"}
                    />
                </DataTable.Cell>
                <DataTable.Cell onPress={() => handleEdit(item.id)}>
                    {item.name}
                </DataTable.Cell>
                <DataTable.Cell onPress={() => handleEdit(item.id)}>
                    {item.type}
                </DataTable.Cell>
                <DataTable.Cell numeric>
                    <IconButton
                        color={theme.colors.secondary}
                        name="edit"
                        onPress={() => handleEdit(item.id)}
                    />
                </DataTable.Cell>
            </DataTable.Row>
        ),
        [handleEdit, theme.colors.secondary]
    );

    if (error) {
        return (
            <Alert type="error">
                {t("common.error", "There was an error: {{errorText}}", {
                    errorText: error.message,
                })}
            </Alert>
        );
    }
    return (
        <AdminContainer>
            <View style={styles.container}>
                <Button
                    style={styles.button}
                    onPress={() => {
                        navigate("INTEGRATION_CREATE");
                    }}
                    disabled={
                        data?.integrations.data.length ===
                        integrationTypesArray.length
                    }
                >
                    {t(
                        "backoffice.integration_list.create_integration",
                        "Create integration"
                    )}
                </Button>
            </View>
            <SearchInput onChange={handleSearchTextChange} />
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "active" && sortDirection}
                            onPress={() => onSortChange("active")}
                            style={styles.iconColumn}
                        >
                            {t("common.active", "Active")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "type" && sortDirection}
                            onPress={() => onSortChange("type")}
                        >
                            {t("common.type", "Type")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.sortIcon}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {(loading && <Loading />) ||
                        data!.integrations.data.map(renderIntegrationRow)}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.integrations.pagination.pages}
                        itemCount={data?.integrations.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        justifyContent: "space-between",
        marginBottom: 20,
        flexWrap: "wrap",
    },
    button: { width: 200, alignSelf: "flex-end" },
    sortIcon: {
        justifyContent: "flex-end",
    },
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 60 },
});
