import React from "react";
import { useTranslation } from "react-i18next";
import { AdminContainer } from "../container";
import { useAuth, Tabs } from "@venuepos/react-common";
import { LayoutsTab } from "./layouts-tab";
import { TableLayoutsTab } from "./tables/tables-tab";

export * from "./create/create";
export * from "./edit/edit";
export * from "./tables";

export function LayoutsScreen() {
    const auth = useAuth();
    auth.enforce(["merchant.layout.write", "merchant.layout.delete"], "OR");

    const [t] = useTranslation();

    return (
        <AdminContainer>
            <Tabs
                labels={[
                    t("backoffice.layouts.headline", "Layouts"),
                    t("backoffice.table_layouts.headline", "Table layouts"),
                ]}
            >
                <LayoutsTab />
                <TableLayoutsTab />
            </Tabs>
        </AdminContainer>
    );
}
