import {
    createNavigationContainerRef,
    NavigationContainer,
} from "@react-navigation/native";
import { routeConfigToLinks } from "@venuepos/react-common";
import React from "react";

import { RootDrawerParamList } from "../navigation";
import { DrawerScreen } from "./navigator-drawer";
import { routeConfig } from "./navigator-routes";

export * from "./navigator-drawer";
export * from "./navigator-routes";

const navigationRef = createNavigationContainerRef<RootDrawerParamList>();

const linkingConfig = {
    screens: {
        admin: {
            path: "admin",
            screens: routeConfigToLinks(routeConfig),
        },
    },
};

const linking = {
    prefixes: ["/"],
    config: linkingConfig,
};

export const AdminNavigator = () => (
    <NavigationContainer
        ref={navigationRef}
        linking={linking}
        documentTitle={{
            formatter: (options, route) =>
                `${options?.title ?? route?.name} - VM POS Backoffice`,
        }}
    >
        <DrawerScreen />
    </NavigationContainer>
);
