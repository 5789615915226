import { useFocusEffect } from "@react-navigation/native";
import {
    LoadingScreen,
    StyleFunction,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQAccountInput,
    useAccountLazyQuery,
    useAccountSaveMutation,
} from "graphql-sdk";
import { schemaAccount } from "lib";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { AdminContainer } from "../../..";
import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../navigation";
import { AccountForm } from "../account-form";
import { AccountTags } from "./account-tags";

type ScreenProps = RootStackScreenProps<"ACCOUNT_EDIT">;

export function AccountEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.accounts");

    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const { handleMutationError } = useHandleMutationError();
    const [accountEdit] = useAccountSaveMutation();
    const [getAccount, account] = useAccountLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<GQAccountInput>(schemaAccount, null);
    const [{ values }, { setDefaultValues }] = form;
    const accountId = route.params.id;

    const fetchConfiguration = useMemo(
        () => ({
            variables: {
                id: accountId,
                tagPagination: {
                    sort: "name",
                    sortDirection: "DESC",
                    pageSize: 99999,
                    page: 0,
                },
            },
        }),
        [accountId]
    );

    useEffect(() => {
        if (!accountId) {
            navigate("ACCOUNTS");
        } else {
            getAccount(fetchConfiguration);
        }
    }, [getAccount, navigate, accountId, fetchConfiguration]);

    useFocusEffect(
        useCallback(() => {
            getAccount(fetchConfiguration);
        }, [fetchConfiguration, getAccount])
    );

    useEffect(() => {
        if (account.data && account.data.account) {
            setDefaultValues(account.data.account as GQAccountInput);
        }
    }, [account.data, setDefaultValues]);

    const edit = useCallback(async () => {
        if (!account.data || !account.data.account || !values) {
            return;
        }
        await handleMutationError(
            async () =>
                await accountEdit({
                    variables: {
                        id: account.data!.account.id,
                        account: {
                            name: values.name,
                            type: values.type,
                            status: values.status,
                            amount: values.amount,
                            externalId: values.externalId,
                            vatId: values.vatId,
                        },
                    },
                }),
            t("common.saved", "Saved"),
            () => {
                navigate("ACCOUNT_EDIT", {
                    id: accountId,
                });
            }
        );
    }, [
        account.data,
        values,
        handleMutationError,
        t,
        accountEdit,
        navigate,
        accountId,
    ]);

    if (!account.data) {
        return <LoadingScreen style="light" />;
    }

    return (
        <AdminContainer testID="accountEditScreen">
            <View style={styles.container}>
                <View style={styles.left}>
                    <AccountForm
                        id={account.data.account.id}
                        vatId={account.data.account.vat?.id}
                        form={form}
                        onSubmit={edit}
                        submitButton={["common.save", "Save"]}
                        isCreate={false}
                        enabledTags={account.data.account.tags.data.map(
                            itr => itr.id
                        )}
                        isGiftCard={account.data.account.type === "GIFT_CARD"}
                    />
                </View>
                <View style={styles.right}>
                    <AccountTags account={account.data.account.id} />
                </View>
            </View>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    headline: {
        marginBottom: 0,
        textAlign: "center",
    },
    centerText: {
        textAlign: "center",
    },

    container: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    left: {
        marginBottom: theme.spacingScale * 2,
        marginRight: theme.spacingScale * 3,
        minWidth: 400,
    },
    right: {
        minWidth: 250,
    },

    textLink: {
        ...theme.styles.link,
    },
});
