// Purpose: Protect the app against mounting anything before it has be determined if the client is signed in
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useAuthSession } from "./session";
import { View, ViewStyle } from "react-native";
import { MeType, useMeLazy } from "./use-me";
import { addExtraLoggingData, addLoggingTag } from "../error-logger";
import { OfflineScreen } from "../../components";

export type AuthenticationState =
    | "AUTHENTICATED"
    | "UNAUTHENTICATED"
    | "INIT"
    | "LOADING"
    | "DETERMINE"
    | "OFFLINE"
    | "REQUIRE_NEW_PASSWORD";

export function ProvideAuth({
    children,
    isSignedIn,
    renderLoginScreen,
    initialMeChange,
    onlineRequired,
    renderRequiredPasswordScreen: renderRequiredPasswordScreen,
    checkForRequiredPassword,
}: {
    children: React.ReactNode;
    isSignedIn: (me: MeType) => boolean;
    renderLoginScreen: () => ReactElement;
    initialMeChange?: (
        me: MeType | undefined,
        setMe: (newMe: MeType | undefined) => void
    ) => void;
    onlineRequired?: boolean;
    renderRequiredPasswordScreen?: () => ReactElement;
    checkForRequiredPassword: boolean;
}) {
    const [authState, setAuthState] = useState<AuthenticationState>("INIT");
    const session = useAuthSession(["authToken"]);
    const [{ authToken }, setSessionValue] = session;
    const [{ load: loadMe, forget: forgetMe, set: setMe }, me] = useMeLazy();

    const handleCatch = useCallback((error: Error) => {
        if (
            error.message.includes("Failed to fetch") ||
            error.message.includes("Network request failed")
        ) {
            setAuthState("OFFLINE");
        } else {
            // Means that the auth token couldn't be verified by the server
            console.warn("Auth token could not be verified by the server");
            setAuthState("UNAUTHENTICATED");
        }
    }, []);

    // Determine what to do on initial state
    useEffect(() => {
        if (authState !== "INIT") {
            return;
        }

        if (initialMeChange) {
            initialMeChange(me, setMe);
        }

        if (authToken) {
            setAuthState("LOADING");
            loadMe()
                .catch(handleCatch)
                .then(() => setAuthState("DETERMINE"));
        } else {
            setAuthState("UNAUTHENTICATED");
        }
    }, [authState, authToken, handleCatch, initialMeChange, loadMe, me, setMe]);

    // Handle check of authentication
    useEffect(() => {
        if (
            !(
                authState === "DETERMINE" ||
                authState === "UNAUTHENTICATED" ||
                authState === "OFFLINE"
            ) ||
            !me
        ) {
            return;
        }

        if (isSignedIn(me)) {
            setAuthState("AUTHENTICATED");
        } else {
            setAuthState("UNAUTHENTICATED");
        }
    }, [authState, authToken, isSignedIn, me, setSessionValue]);

    // Listen for sign out
    useEffect(() => {
        if (authState === "AUTHENTICATED" && !authToken) {
            setAuthState("UNAUTHENTICATED");
            forgetMe();
        }
    }, [authState, authToken, forgetMe, me]);

    // Update logging with authentication context
    useEffect(() => {
        addLoggingTag("cashRegisterId", me?.cashRegister?.id);
        addLoggingTag("userId", me?.user?.id);
        addLoggingTag("merchantId", me?.merchant?.id);

        addExtraLoggingData("authScope", {
            cashRegisterId: me?.cashRegister?.id,
            cashRegisterName: me?.cashRegister?.name,
            userId: me?.user?.id,
            userUsername: me?.user?.username,
            merchantId: me?.merchant?.id,
            merchantName: me?.merchant?.name,
        });
    }, [me]);

    useEffect(() => {
        if (!me) return;
        if (isSignedIn(me)) {
            if (
                checkForRequiredPassword === true &&
                me.user?.requirePasswordChange
            ) {
                setAuthState("REQUIRE_NEW_PASSWORD");
            }
        }
    }, [me, isSignedIn, checkForRequiredPassword]);

    if (onlineRequired && authState === "OFFLINE") {
        return <OfflineScreen />;
    }

    const signedIn =
        authToken &&
        ((checkForRequiredPassword === true &&
            me?.user?.requirePasswordChange === false) ||
            checkForRequiredPassword === false) &&
        isSignedIn(me!);
    const loading = authState === "LOADING";

    const style: ViewStyle = {
        flex: 1,
        display: !loading && !signedIn ? "flex" : "none",
    };

    return (
        <>
            {authState === "REQUIRE_NEW_PASSWORD" &&
                renderRequiredPasswordScreen && (
                    <View style={style}>{renderRequiredPasswordScreen()}</View>
                )}
            {authState !== "INIT" && authState !== "REQUIRE_NEW_PASSWORD" && (
                <View style={style}>{renderLoginScreen()}</View>
            )}
            {signedIn && children}
        </>
    );
}
