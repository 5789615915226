import {
    LoadingScreen,
    StyleFunction,
    Surface,
    useAuth,
    useForm,
    useMe,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    useIntegrationLazyQuery,
    useIntegrationSaveMutation,
} from "graphql-sdk";
import {
    IIntegrationInput,
    IMerchantChartOfAccountInput,
    IntegrationTypes,
    schemaIntegrationInput,
} from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { GeneralForm } from "../general-form";
import { IntegrationScreen } from "../integration-screen";

type ScreenProps = RootStackScreenProps<"INTEGRATION_EDIT">;

export function EditIntegrationScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.merchant_settings");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [integrationEdit] = useIntegrationSaveMutation();
    const { handleMutationError } = useHandleMutationError();
    const [getIntegration, integration] = useIntegrationLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<IIntegrationInput>(schemaIntegrationInput, null);
    const [{ values }, { setDefaultValues }] = form;
    const integrationId = route.params.id;
    const me = useMe();

    useEffect(() => {
        if (!integrationId) {
            navigate("INTEGRATIONS");
            return;
        }

        getIntegration({ variables: { id: integrationId } });
    }, [getIntegration, navigate, integrationId]);

    useEffect(() => {
        if (integration.data && integration.data.integration && me.merchant) {
            const p = integration.data.integration;
            setDefaultValues({
                type: p.type as IntegrationTypes,
                active: p.active,
                name: p.name,
                apiKey: p.apiKey,
                merchantChartOfAccounts: p.merchantChartOfAccounts,
                merchantId: me.merchant.id,
            });
        }
    }, [integration.data, setDefaultValues, me]);

    const edit = useCallback(async () => {
        if (
            !integration ||
            !integration.data ||
            !integration.data.integration ||
            !values ||
            values.type === undefined
        ) {
            return;
        }

        await handleMutationError(async () => {
            const merchantChartOfAccounts: IMerchantChartOfAccountInput[] = [];
            if (values.merchantChartOfAccounts.length > 0) {
                values.merchantChartOfAccounts.forEach(mcoa => {
                    let merchantChartOfAccount: IMerchantChartOfAccountInput = {
                        chartOfAccountsId: mcoa.chartOfAccountsId,
                        merchantAccountNo: mcoa.merchantAccountNo,
                        name: mcoa.name,
                    };
                    merchantChartOfAccounts.push(merchantChartOfAccount);
                });
            }

            await integrationEdit({
                variables: {
                    id: integration.data!.integration!.id,
                    integration: {
                        type: values.type!,
                        active: values.active,
                        name: values.name,
                        apiKey: values.apiKey,
                        merchantChartOfAccounts: merchantChartOfAccounts,
                        merchantId: values.merchantId,
                    },
                },
            });
        }, t("common.saved", "Saved"));
    }, [integration, values, handleMutationError, t, integrationEdit]);

    if (!integrationId || !integration.data || !integration.data.integration) {
        return <LoadingScreen style="light" />;
    }

    return (
        <IntegrationScreen>
            <Surface>
                <View style={styles.container}>
                    <GeneralForm
                        form={form}
                        onSubmit={edit}
                        submitButton={["common.edit", "Edit"]}
                        pickerDisabled={true}
                    />
                </View>
            </Surface>
        </IntegrationScreen>
    );
}
const styleFunc: StyleFunction = theme => {
    return {
        container: {
            width: "100%",
            maxWidth: theme.dimensions.maxFormWidth,
            alignSelf: "flex-start",
        },
    };
};
