import {
    Loading,
    LoadingScreen,
    PageTitle,
    StyleFunction,
    Surface,
    Tabs,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import { useDepartmentLazyQuery, useDepartmentSaveMutation } from "graphql-sdk";
import {
    DepartmentConfig,
    departmentConfigDefault,
    departmentConfigSchema,
    IDepartmentInput,
    schemaDepartment,
} from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import {
    EntityConfigForm,
    EntityConfigFormEntities,
} from "../../../components/entity-config";
import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackParamList, RootStackScreenProps } from "../../../navigation";
import { DepartmentForm } from "../department-form";
import { DepartmentScreen } from "../department-screen";

type ScreenProps = RootStackScreenProps<"DEPARTMENT_EDIT">;

export function DepartmentEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.department.write");

    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [departmentEdit] = useDepartmentSaveMutation();
    const { handleMutationError } = useHandleMutationError();
    const [getDepartment, department] = useDepartmentLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<IDepartmentInput>(schemaDepartment, null);
    const [{ values }, { setDefaultValues }] = form;
    const departmentId = route.params.id;

    useEffect(() => {
        if (!departmentId) {
            navigate("DEPARTMENTS");
        } else {
            getDepartment({ variables: { id: departmentId } });
        }
    }, [getDepartment, navigate, departmentId]);

    useEffect(() => {
        if (department.data && department.data.department) {
            const p = department.data.department;
            setDefaultValues({
                name: p.name,
                externalId: p.externalId ?? "",
            });
        }
    }, [department.data, setDefaultValues]);

    const edit = useCallback(async () => {
        if (
            !department ||
            !department.data ||
            !department.data.department ||
            !values
        ) {
            return;
        }

        await handleMutationError(
            async () =>
                await departmentEdit({
                    variables: {
                        id: department.data!.department!.id,
                        department: {
                            name: values.name,
                            externalId: values.externalId,
                        },
                    },
                }),
            t("backoffice.department.saved", "Department saved"),
            () => {
                navigate("DEPARTMENTS", { refetch: true });
            }
        );
    }, [department, values, handleMutationError, t, departmentEdit, navigate]);

    if (!department.data || !department.data.department || !departmentId) {
        return <LoadingScreen style="light" />;
    }

    // Entity configs
    const entityConfigs: EntityConfigFormEntities = [];
    entityConfigs.push({
        id: department.data.department.merchant.id,
        type: "merchant",
    });
    entityConfigs.push({
        id: departmentId,
        type: "department",
        referrer: "DEPARTMENT_EDIT" as keyof RootStackParamList,
    });

    const tabs = [
        t("backoffice.common.configuration", "Configuration"),
        t("backoffice.common.department", "Department"),
    ];

    if (!department) {
        return <Loading />;
    }

    return (
        <DepartmentScreen>
            <PageTitle>{department.data.department.name}</PageTitle>
            <Surface>
                <View style={styles.container}>
                    <Tabs labels={tabs}>
                        <EntityConfigForm<DepartmentConfig>
                            entities={entityConfigs}
                            defaultValues={departmentConfigDefault}
                            schema={departmentConfigSchema}
                        />
                        <DepartmentForm
                            form={form}
                            onSubmit={edit}
                            submitButton={["common.save", "Save"]}
                        />
                    </Tabs>
                </View>
            </Surface>
        </DepartmentScreen>
    );
}

const styleFunc: StyleFunction = theme => {
    return {
        container: {
            maxWidth: theme.dimensions.maxFormWidth,
        },
    };
};
