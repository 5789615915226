import React, { ReactNode } from "react";
import { View, StyleSheet } from "react-native";
import { useTheme } from "../../theme";

export function PaddedLayout({ children }: { children: ReactNode }) {
    const theme = useTheme();
    return (
        <View
            style={[
                styles.container,
                { backgroundColor: theme.colors.primary },
            ]}
        >
            {children}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 20,
        flex: 1,
    },
});
