import React, { createElement, useCallback, useState } from "react";
import {
    StyleSheet,
    StyleProp,
    TextStyle,
    ViewStyle,
    View,
} from "react-native";
import { InputLabel } from "../input-label";
import { StyleFunction, useThemedStyle } from "../../theme";
import { Text } from "../text";
import { RequiredIndicator } from "../required-input";

export function NumberInput(props: {
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    defaultValue?: number | null;
    value?: number | null;
    onChange?: (date: number | null) => void;
    style?: StyleProp<ViewStyle | TextStyle>;
    min?: number;
    max?: number;
    unit?: string;
    percentageToRate?: boolean;
    decimals?: number;
    testID?: string;
    required?: boolean;
}) {
    let initValue: number | null = 0;
    if (props.value !== undefined) {
        initValue = props.value;
    } else if (props.defaultValue !== undefined) {
        initValue =
            props.defaultValue === null
                ? props.defaultValue
                : clampValue(props.defaultValue, props);
    }

    if (props.percentageToRate && initValue !== null) {
        initValue = rateToPercentage(initValue);
    }

    const [value, setValue] = useState<string | number>(
        initValue === null ? "" : initValue
    );
    const styles = useThemedStyle(styleFunc);

    const onChange = useCallback(
        (event: any) => {
            const str = event.target.value;
            let val = str === "" ? "" : Number(event.target.value);
            setValue(val);

            if (props.onChange) {
                if (typeof val === "number") {
                    if (props.percentageToRate) {
                        val = rateToPercentage(
                            clampValue(percentageToRate(val), props)
                        );
                    } else {
                        val = clampValue(val, props);
                    }
                }

                if (typeof val === "string") {
                    props.onChange(null);
                } else {
                    if (props.percentageToRate) {
                        props.onChange(percentageToRate(val));
                    } else {
                        props.onChange(roundDecimals(val, props));
                    }
                }
            }
        },
        [props]
    );

    const onBlur = useCallback(
        (event: any) => {
            const isNan =
                event.target.value === "" || isNaN(event.target.value);

            if (
                isNan &&
                (props.value === null || props.defaultValue === null)
            ) {
                setValue("");
                if (props.onChange) {
                    props.onChange(null);
                }
            } else {
                let val = 0;
                if (!isNan) {
                    val = Number(event.target.value);
                }

                if (props.percentageToRate) {
                    val = rateToPercentage(
                        clampValue(percentageToRate(val), props)
                    );
                } else {
                    val = clampValue(roundDecimals(val, props), props);
                }
                setValue(val);

                if (props.onChange) {
                    if (props.percentageToRate) {
                        props.onChange(percentageToRate(val));
                    } else {
                        props.onChange(val);
                    }
                }
            }
        },
        [props]
    );

    let valueArg = value;
    if (props.value !== undefined && props.value !== null) {
        if (props.percentageToRate) {
            valueArg = rateToPercentage(props.value);
        } else {
            valueArg = props.value;
        }
    }

    return (
        <>
            {props.label && (
                <InputLabel>
                    {props.label} {props.required && <RequiredIndicator />}
                </InputLabel>
            )}
            <View style={styles.container}>
                {props.unit && (
                    <View style={styles.unit}>
                        <Text style={styles.unitText}>{props.unit}</Text>
                    </View>
                )}
                {createElement("input", {
                    onChange,
                    onBlur,
                    type: "number",
                    value: valueArg,
                    style: StyleSheet.flatten([
                        styles.input,
                        props.style,
                        props.disabled ? styles.disabled : undefined,
                        props.unit ? styles.unitInput : undefined,
                    ]),
                    placeholder: props.placeholder,
                    disabled: props.disabled,
                    min: props.min,
                    max: props.max,
                    "data-testid": props.testID,
                    id: props.testID,
                })}
            </View>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flexDirection: "row",
    },
    input: {
        ...theme.styles.input,
        minHeight: 40,
        backgroundColor: theme.colors.white,
        paddingLeft: 8,
        paddingRight: 8,
        borderStyle: "solid",
        borderRadius: 8,
        width: "100%",
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: theme.colors.secondary,
        marginBottom: 10,
        flex: 1,
    },
    disabled: {
        backgroundColor: theme.colors.grey50,
        borderColor: theme.colors.grey500,
        color: theme.colors.grey800,
    },
    unitInput: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    unit: {
        backgroundColor: theme.colors.secondary,
        paddingHorizontal: 12,
        justifyContent: "center",
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        height: 40,
    },
    unitText: {
        color: theme.colors.white,
        fontSize: 14,
        fontWeight: "bold",
    },
});

function clampValue(value: number, props: { min?: number; max?: number }) {
    let val = value;

    if (props.min !== undefined) {
        val = Math.max(props.min, val);
    }
    if (props.max !== undefined) {
        val = Math.min(props.max, val);
    }

    return val;
}

export function percentageToRate(value: number): number {
    return Math.round(value * 100) / 10000;
}

export function rateToPercentage(value: number): number {
    return Math.round(value * 10000) / 100;
}

function roundDecimals(value: number, props: { decimals?: number }): number {
    if (props.decimals === undefined) {
        return value;
    }

    const sqrt = Math.pow(10, props.decimals);
    return Math.round(value * sqrt) / sqrt;
}
