import React, { useState } from "react";
import { View } from "react-native";
import { useTranslation } from "react-i18next";
import type { LayoutSection } from "lib";
import {
    Button,
    Headline,
    IconButton,
    InputControl,
    Picker,
    StyleFunction,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";

export function SendToSectionModal({
    initialSection,
    sections,
    onClose,
}: {
    initialSection: LayoutSection["id"];
    sections: Pick<LayoutSection, "id" | "label">[];
    onClose: (value?: LayoutSection["id"] | "NEW") => void;
}) {
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const [t] = useTranslation();
    const [section, setSection] = useState<LayoutSection["id"]>(initialSection);

    return (
        <View>
            <View style={styles.box}>
                <IconButton
                    onPress={() => {
                        onClose(undefined);
                    }}
                    name="close"
                    color={theme.colors.textDark}
                    style={styles.closeButton}
                />
                <Headline size="h5" style={styles.headline}>
                    {t(
                        "backoffice.layout.move_to_section_headline",
                        "Send to section"
                    )}
                </Headline>
                <InputControl>
                    <Picker
                        onValueChange={value => {
                            setSection(value);
                        }}
                        selectedValue={section}
                    >
                        {sections.map(sectionItr => (
                            <Picker.Item
                                key={sectionItr.id}
                                value={sectionItr.id}
                                label={sectionItr.label}
                            />
                        ))}
                        <Picker.Item
                            key="NEW"
                            value="NEW"
                            label={t(
                                "backoffice.layout.move_to_new_section",
                                "New section..."
                            )}
                        />
                    </Picker>
                </InputControl>
                <Button onPress={() => onClose(section)}>
                    {t("backoffice.layout.send", "Send")}
                </Button>
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    box: {
        width: "100%",
        minWidth: 320,
        backgroundColor: theme.colors.white,
        padding: theme.spacingScale * 2,
        borderRadius: theme.borderRadius,
    },
    closeButton: {
        position: "absolute",
        right: theme.spacingScale * 2,
    },
});
