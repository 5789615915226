import {
    StyleProp,
    Text as TextImpl,
    TextProps as TextPropsImpl,
    TextStyle,
} from "react-native";
import React from "react";
import { useTheme } from "../../theme";

interface HeadlineProps extends TextPropsImpl {
    style?: StyleProp<TextStyle>;
    children: React.ReactNode;
    size?: "h1" | "h2" | "h3" | "h4" | "h5";
}

export function Headline(props: HeadlineProps) {
    const theme = useTheme();
    return (
        <TextImpl
            {...props}
            style={[
                theme.headlines[props.size ? props.size : "h1"],
                props.style,
            ]}
        />
    );
}
