import type { ConditionLogicOperator, ValidationSchema } from "lib";
import {
    GQDiscountInput,
    GQDiscountType,
    GQDiscountValueType,
} from "graphql-sdk";
import type { DateTimeConstraint } from "./date-time-constraint-form";
import type { DiscountType } from "lib";

export type DiscountFormConditionInputs = {
    productConditionsLogicOperator: ConditionLogicOperator;
    productConditions: DiscountType["conditions"];
    groupConditions: DiscountType["conditions"];
    tagConditionsLogicOperator: ConditionLogicOperator;
    tagConditions: DiscountType["conditions"];
    customerConditionsLogicOperator: ConditionLogicOperator;
    customerConditions: DiscountType["conditions"];
    departmentConditionsLogicOperator: ConditionLogicOperator;
    departmentConditions: DiscountType["conditions"];
};

export type DiscountFormInputs = Omit<
    GQDiscountInput,
    "itemQuery" | "conditions"
> &
    DiscountFormConditionInputs & {
        dateTimeConstraints: DateTimeConstraint[];
    };

export const defaultDiscount: DiscountFormInputs = {
    name: "",
    lineDescription: "",
    type: GQDiscountType.Product,
    valueType: GQDiscountValueType.Percentage,
    value: 0,
    maxPerOrder: null,
    dateTimeConstraints: [],
    productConditionsLogicOperator: "AND",
    productConditions: [],
    groupConditions: [],
    tagConditionsLogicOperator: "AND",
    tagConditions: [],
    customerConditionsLogicOperator: "AND",
    customerConditions: [],
    departmentConditionsLogicOperator: "OR",
    departmentConditions: [],
    active: true,
};

export const schemaDiscount: ValidationSchema<DiscountFormInputs> = {
    name: { required: true },
    lineDescription: { required: true },
    value: { required: true, number: true, minValue: { value: 0.01 } },
};
