import {
    ButtonDimensions,
    ButtonPosition,
    GridDimensions,
    LayoutButton,
} from "lib";

export const checkButtonCollision = (
    buttonId: LayoutButton["id"],
    dimensions: ButtonDimensions,
    position: ButtonPosition,
    buttonPositionArray: string[],
    gridDimensions: GridDimensions
): LayoutButton["id"] | false => {
    if (!buttonId) {
        return false;
    }

    let jStart = position.y;
    let iStart = position.x;
    let jMax = position.y + dimensions.height;
    let iMax = position.x + dimensions.width;

    let collision: string | false = false;
    for (let i = iStart; i < iMax; i++) {
        for (let j = jStart; j < jMax; j++) {
            if (collision) {
                return collision;
            }

            const arrayIndex = j * gridDimensions.columns + i;

            collision =
                !!buttonPositionArray[arrayIndex] &&
                buttonPositionArray[arrayIndex] !== buttonId
                    ? buttonPositionArray[arrayIndex]
                    : false;
        }
    }

    return collision;
};
