import type { IPaymentMethodInput, ValidationSchema } from "..";

export type PaymentMethodInputForm = Omit<
    IPaymentMethodInput,
    "paymentType"
> & {
    paymentType: IPaymentMethodInput["paymentType"] | null;
};
export const schemaPaymentMethod: ValidationSchema<PaymentMethodInputForm> = {
    name: { required: true },
    paymentType: { required: true },
    enabled: { required: true },
    currencyId: { required: true },
};
