import {
    Alert,
    LoadingScreen,
    useAuth,
    useForm,
    useToast,
} from "@venuepos/react-common";
import {
    GQDiscountInput,
    useDiscountLazyQuery,
    useDiscountSaveMutation,
    useDiscountsQuery,
} from "graphql-sdk";
import { OrderConditions, parseError } from "lib";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { RootStackScreenProps } from "../../navigation";
import { DiscountForm } from "./discount-form";
import { DiscountScreen } from "./discount-screen";
import { DiscountFormInputs, schemaDiscount } from "./forms";
import { convertFromValues, convertToValues } from "./methods";

import type { ApolloError } from "@apollo/client";
import { StyleSheet } from "react-native";
type ScreenProps = RootStackScreenProps<"DISCOUNT_EDIT">;

export function DiscountEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.discount");

    const [t] = useTranslation();
    const toast = useToast();
    const [discountEdit] = useDiscountSaveMutation();
    const [getDiscount, discount] = useDiscountLazyQuery({
        fetchPolicy: "no-cache",
    });
    const { data } = useDiscountsQuery({
        variables: {
            pagination: {
                pageSize: 999999,
            },
        },
    });
    const [loaded, setLoaded] = useState<boolean>(false);
    const form = useForm<DiscountFormInputs>(schemaDiscount, null);
    const [{ values }, { setDefaultValues }] = form;
    const discountId = route.params.id;
    const [alert, setAlert] = useState<string | undefined>();

    useEffect(() => {
        if (!discountId) {
            navigate("DISCOUNTS");
        } else {
            setLoaded(false);
            getDiscount({ variables: { id: discountId } });
        }
    }, [getDiscount, discountId, navigate]);

    const checkGroupProductIdIntersection = useCallback(() => {
        // Find any active group discount that has overlap in productIds
        if (
            data !== undefined &&
            discount.data !== undefined &&
            discount.data.discount !== undefined &&
            discount.data.discount !== null &&
            values &&
            discount.data.discount.active === true
        ) {
            // Build a list of product ids in the discount we try to save
            const currentDiscountProductIds: string[] = [];
            for (let i = 0; i < values.groupConditions.length; i++) {
                const args = values.groupConditions[i]
                    .args as OrderConditions["hasGroupQuantity"];
                currentDiscountProductIds.push(...args.productIds);
            }

            // Examine product ids of all other group discounts
            for (let i = 0; i < data.discounts.data.length; i++) {
                // Ignore if not group, active or the discount we a trying to edit
                if (
                    data.discounts.data[i].type !== "GROUP" ||
                    data.discounts.data[i].active === false ||
                    (discount.data.discount.id !== undefined &&
                        discount.data.discount.id === data.discounts.data[i].id)
                ) {
                    continue;
                }
                const [conditions] = convertToValues(
                    data.discounts.data[i].type,
                    JSON.parse(data.discounts.data[i].conditions),
                    JSON.parse(data.discounts.data[i].itemQuery)
                );

                for (let j = 0; j < conditions.groupConditions.length; j++) {
                    const args = conditions.groupConditions[j]
                        .args as OrderConditions["hasGroupQuantity"];

                    const result = args.productIds.filter(o =>
                        currentDiscountProductIds.some(id => o === id)
                    );
                    if (result.length > 0) {
                        return true;
                    }
                }
            }
            return false;
        }
    }, [data, discount.data, values]);

    useEffect(() => {
        if (discount.data && discount.data.discount) {
            const d = discount.data.discount;
            const [conditions, dateTimeConstraints] = convertToValues(
                d.type,
                JSON.parse(d.conditions),
                JSON.parse(d.itemQuery)
            );

            setDefaultValues({
                name: d.name,
                lineDescription: d.lineDescription,
                type: d.type,
                valueType: d.valueType,
                value: d.value,
                maxPerOrder: d.maxPerOrder,
                dateTimeConstraints,
                ...conditions,
                active: d.active,
            });

            // Check if any active group discount has overlap in productIds
            const intersectionFound = checkGroupProductIdIntersection();

            if (intersectionFound) {
                setAlert(
                    t(
                        "backoffice.discount.group_item_warning",
                        "There are multiple group discounts that uses the same products"
                    )
                );
            } else {
                setAlert(undefined);
            }

            setLoaded(true);
        }
    }, [
        alert,
        checkGroupProductIdIntersection,
        discount.data,
        setDefaultValues,
        t,
    ]);

    const edit = useCallback(async () => {
        if (!discount.data || !discount.data.discount || !values) {
            return;
        }

        const [conditions, itemQuery, maxPerOrder] = convertFromValues(values);

        try {
            await discountEdit({
                variables: {
                    id: discount.data.discount.id,
                    discount: {
                        name: values.name,
                        lineDescription: values.lineDescription,
                        type: values.type,
                        valueType: values.valueType,
                        value: values.value,
                        maxPerOrder,
                        itemQuery: JSON.stringify(itemQuery),
                        conditions: JSON.stringify(conditions),
                        active: values.active,
                    },
                },
            });
        } catch (e) {
            const error = parseError<GQDiscountInput>(e as ApolloError);

            switch (error.type) {
                case "VALIDATION":
                    toast.error(
                        t(
                            "backoffice.discount.save_failed.validation",
                            "Discount save failed validation"
                        )
                    );
                    break;
                case "NOT_UNIQUE":
                    toast.error(
                        t(
                            "backoffice.discount.save_failed.not_unique",
                            "Discount save failed, not unique: {{fields}}",
                            {
                                fields: error.data.join(", "),
                            }
                        )
                    );
                    break;
            }
            return;
        }
        toast.success(t("backoffice.discount.saved", "Discount saved"));
    }, [discount.data, discountEdit, t, toast, values]);

    if (!loaded) {
        return <LoadingScreen style="light" />;
    }

    return (
        <DiscountScreen>
            {alert !== undefined && (
                <Alert type="warning" style={styles.alert}>
                    {alert}
                </Alert>
            )}
            <DiscountForm
                form={form}
                onSubmit={edit}
                submitButton={["common.save", "Save"]}
            />
        </DiscountScreen>
    );
}

const styles = StyleSheet.create({
    alert: {
        marginBottom: 20,
    },
});
