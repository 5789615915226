import {
    Alert,
    Button,
    ColorIndicator,
    DataTable,
    Icon,
    Loading,
    SearchInput,
    StyleFunction,
    Surface,
    useAuth,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import { useUsersQuery } from "graphql-sdk";
import { formatDateTime } from "lib";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, View } from "react-native";

import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";

import type { AvailableLocale } from "locales";
type ScreenProps = RootStackScreenProps<"USERS">;

export function UserListScreen({ navigation: { navigate } }: ScreenProps) {
    const styles = useThemedStyle(styleFunc);
    const auth = useAuth();
    auth.enforce(
        [
            "merchant.user.write",
            "merchant.user.delete",
            "admin.user.write",
            "admin.user.delete",
        ],
        "OR"
    );

    const [t] = useTranslation();
    const [{ locale }] = useAdminSession(["locale"]);
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({ initialSortBy: "username" });
    const [search, setSearch] = useState<string>("");

    // GraphQL
    const { data, loading, error } = useUsersQuery({
        fetchPolicy: "no-cache",
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        },
    });

    // Actions
    const handleEdit = useCallback(
        (id: string) => {
            navigate("USER_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list (in case the user changed to a different page)
            onPageChange(0);
        },
        [onPageChange]
    );

    if (error) {
        return (
            <Alert type="error">
                {t("common.error", "There was an error: {{errorText}}", {
                    errorText: error.message,
                })}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <View style={styles.container}>
                <View style={styles.buttons}>
                    {auth.may(
                        ["merchant.user.write", "admin.user.write"],
                        "OR"
                    ) && (
                        <Button
                            onPress={() => {
                                navigate("USER_CREATE");
                            }}
                            style={styles.button}
                        >
                            {t("backoffice.user_list.create_user", "Create")}
                        </Button>
                    )}
                </View>
            </View>
            <SearchInput onChange={handleSearchTextChange} />
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "active" && sortDirection}
                            onPress={() => onSortChange("active")}
                            style={styles.activeColumn}
                        >
                            {t("backoffice.user.active", "Active")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "username" && sortDirection
                            }
                            onPress={() => onSortChange("username")}
                        >
                            {t("backoffice.user.username", "Username")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "firstName" && sortDirection
                            }
                            onPress={() => onSortChange("firstName")}
                        >
                            {t("backoffice.user.firstName", "First name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "lastName" && sortDirection
                            }
                            onPress={() => onSortChange("lastName")}
                        >
                            {t("backoffice.user.lastName", "Last name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "email" && sortDirection}
                            onPress={() => onSortChange("email")}
                        >
                            {t("backoffice.user.email", "E-mail")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t("backoffice.user.role", "Role")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "createdAt" && sortDirection
                            }
                            onPress={() => onSortChange("createdAt")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.sortColumn}>
                            <Icon name="sort" color={styles.sortColumn.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading || !data ? (
                        <Loading />
                    ) : (
                        <FlatList
                            data={data && data.users.data}
                            renderItem={({ item }) => (
                                <DataTable.Row
                                    onPress={() => handleEdit(item.id)}
                                >
                                    <DataTable.Cell style={styles.activeColumn}>
                                        <ColorIndicator
                                            status={
                                                item.active
                                                    ? "success"
                                                    : "warning"
                                            }
                                        />
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {item.username}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {item.firstName}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {item.lastName}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {item.email}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {item.roles
                                            .map(role => role.role.name)
                                            .sort((roleA, roleB) =>
                                                roleA.localeCompare(roleB)
                                            )
                                            .join(", ")}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {formatDateTime(
                                            item.createdAt,
                                            locale as AvailableLocale
                                        )}
                                    </DataTable.Cell>
                                    <DataTable.Cell style={styles.sortColumn} />
                                </DataTable.Row>
                            )}
                            keyExtractor={item => item.id}
                        />
                    )}

                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.users.pagination.pages}
                        itemCount={data?.users.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "space-between",
        marginBottom: theme.spacingScale * 2,
        flexWrap: "wrap",
    },
    buttons: {
        alignSelf: "flex-end",
        alignItems: "flex-end",
        flexDirection: "row",
        flex: 0,
    },
    button: {
        marginLeft: theme.spacingScale,
    },
    activeColumn: {
        flexBasis: 60,
        flexGrow: 0,
        flexShrink: 0,
    },
    sortColumn: {
        flexBasis: 40,
        flexShrink: 0,
        flexGrow: 0,
        color: theme.colors.black,
    },
});
