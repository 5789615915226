import React from "react";
import { useTranslation } from "react-i18next";
import { usePrinterFormQuery } from "graphql-sdk";
import type { GQPrinterSave } from "graphql-sdk";
import {
    Surface,
    InputControl,
    TextInput,
    Button,
} from "@venuepos/react-common";
import type { Form as FormComponent } from "@venuepos/react-common";

export function Form(props: {
    form: FormComponent<GQPrinterSave>;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const { data } = usePrinterFormQuery();

    if (!values || !data) {
        return null;
    }

    return (
        <Surface>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name || ""}
                    onChangeText={text => setValue("name", text)}
                />
            </InputControl>

            <Button onPress={handleSubmit(props.onSubmit)}>
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>
        </Surface>
    );
}
