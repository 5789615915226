import { ButtonDimensions, ButtonPosition, GridDimensions } from "lib";

/**
 * Set a value in the Button Position Cache.
 */
export const setButtonPosition = (
    buttonPositions: string[],
    gridDimensions: GridDimensions,
    position: ButtonPosition,
    dimensions: ButtonDimensions,
    value: string
): string[] => {
    if (value === undefined) {
        return buttonPositions;
    }

    if (typeof value !== "string") {
        return buttonPositions;
    }
    let heightStart = position.y;
    let widthStart = position.x;
    let heightMax = Math.min(
        gridDimensions.rows,
        position.y + dimensions.height
    );

    let widthMax = Math.min(
        gridDimensions.columns,
        position.x + dimensions.width
    );

    for (let i = widthStart; i < widthMax; i++) {
        for (let j = heightStart; j < heightMax; j++) {
            const arrayIndex = j * gridDimensions.columns + i;

            buttonPositions[arrayIndex] = value;
        }
    }
    return buttonPositions;
};
