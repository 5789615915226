import {
    Button,
    IconButton,
    StyleFunction,
    Text,
    useConfirm,
    useThemedStyle,
} from "@venuepos/react-common";
import type {
    ButtonDimensions,
    ButtonPosition,
    GridDimensions,
    LayoutSection,
    LayoutTableButton,
    TableSection,
} from "lib";
import React, { useCallback, useMemo, useReducer, useState } from "react";
import "react-grid-layout/css/styles.css";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import "react-resizable/css/styles.css";
import {
    LayoutAction,
    MoveActionOptions,
} from "../../layout-operation-reducer";
import { SectionTabHeader } from "../../section-tab-header";
import { useEditButtonModal } from "./edit-button-modal";
import { GridEditor } from "./grid-editor";
import {
    TableLayoutAction,
    tableLayoutReducer,
} from "./table-layout-operations-reducer";

export const ITEM_MARGIN_HORISONTAL = 10;
export const ITEM_MARGIN_VERTICAL = 10;
export const ITEM_MARGIN: [number, number] = [
    ITEM_MARGIN_HORISONTAL,
    ITEM_MARGIN_VERTICAL,
];

export const CONTAINER_PADDING_HORISONTAL = 10;
export const CONTAINER_PADDING_VERTICAL = 10;
export const CONTAINER_PADDING: [number, number] = [
    CONTAINER_PADDING_HORISONTAL,
    CONTAINER_PADDING_VERTICAL,
];

export function LayoutEditor({
    onSubmit,
    layoutData,
    dimensions,
}: {
    onSubmit: (layoutSections: TableSection[]) => void;
    layoutData: TableSection[];
    dimensions: GridDimensions;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const confirm = useConfirm();
    const renderEditButtonModal = useEditButtonModal();
    const [state, dispatch] = useReducer(tableLayoutReducer, {
        sections: layoutData,
        currentSectionIndex: 0,
    });

    const [gridWidth, setGridWidth] = useState<number>(100);
    const [columnWidth, setColumnWidth] = useState<number>(
        gridWidth / dimensions.columns
    );

    const addNewSection = () => {
        dispatch({
            type: LayoutAction.ADD_SECTION,
            translationFunction: t,
        });
    };

    /**
     * Deletes the section with the given index.
     * Also, makes sure that there is always at least one section in the collection.
     */
    const handleDeleteSection = useCallback(
        async (sectionId: string) => {
            if (!sectionId) {
                console.error(
                    "There is no id. What would you have me do, huh?"
                );
                return;
            }

            if (
                !(await confirm(
                    t(
                        "backoffice.layout.delete_section_header",
                        "Delete section?"
                    ),
                    t(
                        "backoffice.layout.delete_section_body",
                        "Are you sure you want delete this section?"
                    )
                ))
            ) {
                return;
            }

            dispatch({
                type: LayoutAction.DELETE_SECTION,
                sectionId,
                translationFunction: t,
            });
        },
        [confirm, t]
    );

    /**
     *
     * The height of the grid =
     *              no. rows * column width +
     *              no. of spaces between rows * margin +
     *              container padding * 2 (top and bottom)
     *
     * The column width is used for both height and width to make the cells squared.
     */
    const gridHeight = useMemo(
        () =>
            columnWidth * dimensions.rows +
            (dimensions.rows - 1) * ITEM_MARGIN_VERTICAL +
            CONTAINER_PADDING_VERTICAL * 2,
        [columnWidth, dimensions.rows]
    );

    const handleOnSubmit = useCallback(() => {
        onSubmit(state.sections);
    }, [onSubmit, state.sections]);

    const handleOnLayout = useCallback(
        ({
            nativeEvent: {
                layout: { width },
            },
        }) => {
            let colWidth = Math.min(50, width / dimensions.columns);

            setGridWidth(colWidth * dimensions.columns);
            setColumnWidth(colWidth);
        },
        [dimensions.columns]
    );

    const handleRenameSection = (sectionId: string, newLabel: string) => {
        dispatch({
            type: LayoutAction.RENAME_SECTION,
            sectionId,
            newLabel,
        });
    };

    const handleSelectSection = (sectionId: string) => {
        dispatch({ type: LayoutAction.SELECT_SECTION, sectionId });
    };

    const handleMoveSection = (
        sectionId: LayoutSection["id"],
        direction: MoveActionOptions
    ) => {
        dispatch({ type: LayoutAction.MOVE_SECTION, sectionId, direction });
    };

    const handleToggleRenaming = (sectionId: LayoutSection["id"]) => {
        dispatch({ type: LayoutAction.TOGGLE_RENAME_SECTION, sectionId });
    };

    const handleAddButton = (button: LayoutTableButton) =>
        dispatch({
            type: TableLayoutAction.ADD_BUTTON_TO_SECTION,
            newButton: button,
        });

    const handleShowButtonSettings = useCallback(
        async (button: LayoutTableButton) => {
            const editButtonResult = await renderEditButtonModal({
                button,
            });

            if (!editButtonResult) {
                return;
            }

            if (
                editButtonResult.action !== "DELETE" &&
                editButtonResult.action !== "EDIT"
            ) {
                console.error(
                    "Unknown EditButtonResult.action:",
                    editButtonResult.action,
                    ":",
                    editButtonResult
                );
            }

            if (editButtonResult.action === "DELETE") {
                if (
                    !(await confirm(
                        t(
                            "backoffice.layout.delete_button_header",
                            "Delete button?"
                        ),
                        t(
                            "backoffice.layout.delete_button_body",
                            "Are you sure you want delete this button?"
                        )
                    ))
                ) {
                    return;
                }

                dispatch({
                    type: TableLayoutAction.REMOVE_BUTTON_FROM_SECTION,
                    buttonId: button.id,
                });
                return;
            }

            if (editButtonResult.action === "EDIT") {
                dispatch({
                    type: TableLayoutAction.UPDATE_BUTTON_IN_SECTION,
                    updatedButton: {
                        ...button,
                        ...editButtonResult.formValues,
                    },
                });
            }
        },
        [confirm, renderEditButtonModal, t]
    );

    const handleShowButtonById = useCallback(
        (buttonId: LayoutTableButton["id"]) => {
            const foundButtonIndex = state.sections[
                state.currentSectionIndex
            ].buttons.findIndex(buttonItr => buttonItr.id === buttonId);
            if (foundButtonIndex === -1) {
                console.error(
                    "Invalid input for handleShowButtonModal. Requested button",
                    buttonId,
                    "not found. Back off."
                );
                return;
            }

            handleShowButtonSettings(
                state.sections[state.currentSectionIndex].buttons[
                    foundButtonIndex
                ]
            );
        },
        [handleShowButtonSettings, state.currentSectionIndex, state.sections]
    );

    const handleResizeButton = (
        buttonId: LayoutTableButton["id"],
        buttonDimensions: ButtonDimensions
    ) => {
        dispatch({
            type: TableLayoutAction.RESIZE_BUTTON_IN_SECTION,
            buttonId,
            buttonDimensions: buttonDimensions,
        });
    };

    const handleMoveButton = (
        buttonId: LayoutTableButton["id"],
        position: ButtonPosition
    ) => {
        dispatch({
            type: TableLayoutAction.MOVE_BUTTON_IN_SECTION,
            buttonId,
            position,
        });
    };

    return (
        <View>
            <View style={styles.sectionTabContainer}>
                <View style={styles.sectionTabs} testID="layout:sectionHeaders">
                    {state.sections.map((section, sectionIndex) => (
                        <SectionTabHeader
                            key={`section_${section.id}`}
                            sectionId={section.id}
                            label={section.label}
                            onPress={handleSelectSection}
                            onDelete={handleDeleteSection}
                            onChange={handleRenameSection}
                            activeTab={
                                sectionIndex === state.currentSectionIndex
                            }
                            allowMoveBefore={sectionIndex !== 0}
                            allowMoveAfter={
                                sectionIndex !== state.sections.length - 1
                            }
                            onMoveSection={handleMoveSection}
                            onToggleRenaming={handleToggleRenaming}
                            renaming={!!section?.renaming}
                        />
                    ))}
                </View>
                <IconButton
                    name="plus-circle"
                    color={styles.icon.color}
                    onPress={addNewSection}
                    size="large"
                    testID="layout:addSection"
                />
            </View>
            <View onLayout={handleOnLayout}>
                {state.sections
                    .filter(
                        (_, sectionIndex) =>
                            sectionIndex === state.currentSectionIndex
                    )
                    .map(section => (
                        <View key={section.id} style={styles.gridHolder}>
                            <GridEditor
                                dimensions={dimensions}
                                gridHeight={gridHeight}
                                gridWidth={gridWidth}
                                buttons={section.buttons}
                                onShowButtonSettings={handleShowButtonById}
                                onAddButton={handleAddButton}
                                onMoveButton={handleMoveButton}
                                onResizeButton={handleResizeButton}
                            />
                        </View>
                    ))}
            </View>
            <Button onPress={handleOnSubmit} style={styles.saveButton}>
                <Text>{t("common.save", "Save")}</Text>
            </Button>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    saveButton: {
        marginTop: theme.spacingScale * 2,
    },
    sectionTabs: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "stretch",
    },
    sectionTabContainer: {
        flex: 1,
        flexDirection: "row",
        marginBottom: theme.spacingScale * 2,
    },
    gridHolder: {
        position: "relative",
    },
    icon: {
        color: theme.colors.grey250,
    },
});
