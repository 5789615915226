import { DrawerContentComponentProps } from "@react-navigation/drawer";
import { DrawerActions } from "@react-navigation/native";
import React, { useCallback, useState } from "react";
import { Image, View } from "react-native";

import { IMenuItem, StyleFunction, useThemedStyle } from "../../";
import { MenuItem } from "./menu-item";

export * from "./types";

const logo = require("@venuepos/react-common/assets/img/vp-logo-white.png");

export function MenuBar({
    items = [],
    navigation,
    children,
}: {
    items: IMenuItem[];
    navigation: DrawerContentComponentProps["navigation"];
    children?: React.ReactNode;
}) {
    const styles = useThemedStyle(styleFunc);
    const [expandedItem, setExpandedItem] = useState<IMenuItem | undefined>(
        () => items.find(item => (item.isExpanded ? item : undefined))
    );

    const handleItemPress = useCallback(
        (item: IMenuItem) => {
            if (item === expandedItem) {
                // If the item is already expanded, close it.
                setExpandedItem(undefined);
            } else if (item.items && item.items.length) {
                // If this item has sub-items, then expand it.
                setExpandedItem(item);
            }

            navigation.dispatch(DrawerActions.closeDrawer());

            if (!item.link) {
                return;
            }

            if (typeof item.link === "function") {
                item.link();
                return;
            }

            navigation.navigate(item.link);
        },
        [expandedItem, navigation]
    );

    const handleItemExpand = useCallback((item: IMenuItem) => {
        if (!item.items || item.items.length === 0) {
            return;
        }

        setExpandedItem(prev => {
            if (prev === undefined || prev !== item) {
                return item;
            }
            return undefined;
        });
    }, []);

    return (
        <View style={styles.barContainer} testID="leftMenu">
            <View style={styles.logoItem}>
                <Image style={styles.logo} source={logo} />
            </View>

            {children}

            {/* Check if one of the top level items are expanded - if that's the case, then the child items will be checked */}
            {items.map(item => {
                return (
                    <MenuItem
                        onPress={handleItemPress}
                        onExpand={handleItemExpand}
                        key={item.name}
                        item={item}
                        isExpanded={
                            expandedItem?.name === item.name ||
                            item.isExpanded ||
                            false
                        }
                    />
                );
            })}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    barContainer: {
        flex: 1,
        elevation: 8,
        backgroundColor: theme.colors.secondary,
    },

    logoItem: {
        marginVertical: theme.spacingScale * 3,
        alignItems: "center",
    },

    logo: {
        width: 200,
        height: 62,
    },
});
