import { Text, useThemedStyle } from "@venuepos/react-common";
import React from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";

import type { ValidationErrorData } from "lib";
import type { StyleFunction } from "@venuepos/react-common";
export function ValidationErrorsList({
    errors,
}: {
    errors: { errors: ValidationErrorData[]; rowIndex: number }[];
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    if (errors.length === 0) {
        return null;
    }

    return (
        <ScrollView style={styles.validationErrors}>
            {errors.map(rowError => (
                <View key={rowError.rowIndex}>
                    <Text style={styles.validationErrorsText}>{`${t(
                        "import.invalid_row",
                        {
                            rowIndex: rowError.rowIndex + 1,
                        }
                    )}`}</Text>
                    {Object.entries(rowError.errors).map(
                        ([validationRule, rule]) => (
                            <Text
                                key={`${rowError.rowIndex}-${validationRule}`}
                                style={styles.validationErrorsText}
                            >
                                {`- ${validationRule}: ${t(
                                    rule.error,
                                    rule.options
                                )}`}
                            </Text>
                        )
                    )}
                </View>
            ))}
        </ScrollView>
    );
}
const styleFunc: StyleFunction = theme => ({
    validationErrors: {
        marginTop: theme.spacingScale,
        maxHeight: 350,
        padding: theme.spacingScale,
        backgroundColor: theme.colors.grey100,
        borderColor: theme.colors.grey250,
        borderWidth: 1,
    },
    validationErrorsText: {
        color: theme.colors.grey800,
        fontSize: 12,
    },
});
