import { Headline, useMe, useThemedStyle } from "@venuepos/react-common";
import { useTranslation } from "locales";
import React from "react";
import { View } from "react-native";
import { AdminContainer } from "../container";
import { CashRegisterDashboard } from "./cash-register";
import { dashboardStyleFunc } from "./styles";
import { InvoiceDashboard, InvoicesPerMonthDashboard } from "./invoice";

export function AdminDashboardScreen() {
    const [t] = useTranslation();
    const sharedStyles = useThemedStyle(dashboardStyleFunc);
    const me = useMe();

    return (
        <AdminContainer>
            <View style={sharedStyles.topRow} testID="adminDashboard">
                <Headline size="h2">
                    {t("dashboard.title", "Welcome, {{ firstName }}", {
                        firstName: me.user?.firstName,
                    })}
                </Headline>
            </View>
            <CashRegisterDashboard />
            <InvoiceDashboard />
            <InvoicesPerMonthDashboard />
        </AdminContainer>
    );
}
