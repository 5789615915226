import { View } from "react-native";
import {
    InputControl,
    Picker,
    RelativePeriodPicker,
    useForm,
    useThemedStyle,
    Loading,
} from "@venuepos/react-common";
import { RelativePeriodStrings, ReportTypes } from "lib";
import React, { useMemo } from "react";
import { reportStyleFunc } from "./styles";
import { defaultReportForm, schemaReportForm } from "./validation";
import { useTranslation } from "react-i18next";
import { useAdminSession } from "../../session";

export function BasicForm({
    reportType,
    period,
    onReportTypeChange,
    onPeriodChange,
}: {
    reportType: keyof typeof ReportTypes;
    period: RelativePeriodStrings;
    onReportTypeChange: (reportType: keyof typeof ReportTypes) => void;
    onPeriodChange: ({
        selectedPeriod,
        from,
        to,
    }: {
        selectedPeriod: RelativePeriodStrings;
        from: Date;
        to: Date;
    }) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(reportStyleFunc);
    const [{ locale }] = useAdminSession(["locale"]);

    const [{ errors: formErrors }] = useForm(
        schemaReportForm,
        defaultReportForm
    );

    // order the available report types by name
    const orderedReportTypes = useMemo(
        () =>
            Object.values(ReportTypes)
                .map(type => ({
                    reportType: type,
                    label: t(`report.types.${type}`),
                }))
                .sort((type1, type2) => type1.label.localeCompare(type2.label)),
        [t]
    );

    if (!period) {
        return <Loading />;
    }

    return (
        <View style={styles.row}>
            <InputControl
                error={formErrors.type}
                style={styles.inputCell}
                description={t(
                    "report.description.type_picker",
                    "Pick a report type (hourly sales, product inventory, etc.)"
                )}
            >
                <Picker
                    label={t("common.type", "Type")}
                    onValueChange={value =>
                        onReportTypeChange(value as keyof typeof ReportTypes)
                    }
                    selectedValue={reportType}
                >
                    {orderedReportTypes.map(type => (
                        <Picker.Item
                            value={type.reportType}
                            label={type.label}
                            key={type.reportType}
                        />
                    ))}
                </Picker>
            </InputControl>
            <InputControl
                style={styles.inputCell}
                description={t(
                    "report.description.period_picker",
                    "A pre-defined period for the report."
                )}
            >
                <RelativePeriodPicker
                    locale={locale}
                    label={t("report.report_period", "Report period")}
                    onValueChange={onPeriodChange}
                    value={period}
                    showDates={false}
                />
            </InputControl>
        </View>
    );
}
