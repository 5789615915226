import type {
    ValidationError,
    ValidationErrors,
    ValidationResolvers,
    ValidationRule,
    ValidationRuleName,
    ValidationRuleOptionMessage,
    ValidationRuleResolver,
    ValidationSchema,
} from "./types";
import type { TFunction } from "i18next";
import { loadResolvers, ValidationRules } from "./rules";

let resolvers: ValidationResolvers = {};

export function initResolvers() {
    if (Object.keys(resolvers).length === 0) {
        loadResolvers();
    }
}

export function addResolver<RuleName extends ValidationRuleName>(
    rule: RuleName,
    resolver: ValidationRuleResolver<RuleName>
): void {
    resolvers[rule] = resolver;
}

function formatMessage(message: string, options: any) {
    for (const i in options) {
        message = message.replace(
            new RegExp("\\{" + i + "\\}", "g"),
            options[i]
        );
    }
    return message;
}

export function validateField<FormValues>(
    value: any,
    values: FormValues | null,
    fieldRules: ValidationRules<FormValues>,
    t?: TFunction
): ValidationError | null {
    for (const r in fieldRules) {
        const rule = r as ValidationRuleName;
        const options = fieldRules[
            rule
        ] as ValidationRule<ValidationRuleOptionMessage>;

        if (resolvers[rule] && !resolvers[rule].rule(value, options, values)) {
            if (Array.isArray(fieldRules[rule])) {
                const message = fieldRules[rule] as [string, string?];
                if (t) {
                    return {
                        message: t(message[0], message[1], options),
                    };
                } else {
                    return {
                        error: message[0],
                        options,
                    };
                }
            } else if (typeof fieldRules[rule] === "object") {
                if (t) {
                    return {
                        message: options.message
                            ? t(options.message[0], options.message[1], options)
                            : t(resolvers[rule]!.message, options),
                    };
                } else {
                    return {
                        error: options.message
                            ? options.message[0]
                            : resolvers[rule]!.message,
                        options,
                    };
                }
            } else if (fieldRules[rule] === true) {
                if (t) {
                    return {
                        message: formatMessage(
                            t(resolvers[rule]!.message),
                            options
                        ),
                    };
                } else {
                    return {
                        error: resolvers[rule]!.message,
                        options,
                    };
                }
            }
        }
    }
    return null;
}

export function validationResolve<FormValues>(
    values: FormValues | null,
    schema: ValidationSchema<FormValues>,
    t?: TFunction
): ValidationErrors<FormValues> | null {
    const errors: ValidationErrors<FormValues> = {};
    if (values !== null) {
        for (const field in values) {
            const val = values[field];
            const fieldRules = schema[field as keyof FormValues] as {
                [key: string]: ValidationRule;
            };

            const validate = validateField(val, values, fieldRules, t);
            if (validate) {
                errors[field as keyof FormValues] = validate;
            }
        }
    }
    return Object.keys(errors).length > 0 ? errors : null;
}
