import type { AuthToken } from "lib";
import { useSession as createSession } from "@peteck/react-context-session";
import type { MeType } from "./use-me";

export type AuthSessionData = {
    authToken?: AuthToken;
    me?: MeType;
};

export const authSessionDataDefault: AuthSessionData = {};

export const useAuthSession = createSession<AuthSessionData>();
