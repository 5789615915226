import { useContext } from "react";
import { ModalContext } from "./context";
import type { RenderFunction } from "./index";

export function useModal<ReturnType = any>() {
    const context = useContext(ModalContext);
    if (context === null) {
        throw new Error(
            "Modal context is unset. Did you add ProvideModal to your app yet?"
        );
    }

    return { render: context.render as RenderFunction<ReturnType> };
}
