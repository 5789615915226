import React, { ReactNode } from "react";
import {
    StyleProp,
    StyleSheet,
    View,
    ViewStyle,
    TextStyle,
} from "react-native";
import { StyleFunction, useThemedStyle } from "../../theme";
import { Text } from "../text";

export function Header({
    children,
    style,
    testID,
}: {
    children: ReactNode;
    style?: StyleProp<ViewStyle>;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);
    return (
        <View style={[styles.header, style]} testID={testID}>
            {children}
        </View>
    );
}

export function Title({
    children,
    numberOfLines = 1,
    style,
    textStyle,
    testID,
}: {
    children?: string;
    numberOfLines?: number;
    style?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);
    return (
        <View style={[styles.titleContainer, style]} testID={testID}>
            <Text
                style={[styles.title, textStyle]}
                numberOfLines={numberOfLines}
            >
                {children}
            </Text>
        </View>
    );
}

export function Row({
    style,
    children,
    testID,
}: {
    style?: StyleProp<ViewStyle>;
    testID?: string;
    children: ReactNode;
}) {
    const styles = useThemedStyle(styleFunc);
    return (
        <View nativeID="row" style={[styles.row, style]} testID={testID}>
            {children}
        </View>
    );
}

export function Cell({
    children = "",
    style,
    textStyle,
    testID,
}: {
    children?: string | ReactNode;
    style?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);

    const child =
        typeof children === "string" || typeof children === "number" ? (
            <Text numberOfLines={1} style={textStyle} ellipsizeMode="middle">
                {children}
            </Text>
        ) : (
            children
        );

    return (
        <View style={[styles.cell, style]} testID={testID}>
            {child}
        </View>
    );
}

export function Table({
    children,
    style,
    testID,
}: {
    children: ReactNode;
    style?: StyleProp<ViewStyle>;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);
    return (
        <View style={[styles.table, style]} testID={testID}>
            {children}
        </View>
    );
}

Table.Header = Header;
Table.Title = Title;
Table.Row = Row;
Table.Cell = Cell;

const styleFunc: StyleFunction = theme => ({
    header: {
        flexDirection: "row",
        height: 48,
        paddingHorizontal: 16,
        borderBottomWidth: StyleSheet.hairlineWidth * 2,
        borderBottomColor: theme.colors.grey50,
    },
    titleContainer: {
        flex: 1,
        flexDirection: "row",
        alignContent: "center",
        paddingVertical: 12,
    },
    title: {
        height: 24,
        lineHeight: 24,
        fontSize: 16,
        fontWeight: "700",
        alignItems: "center",
        color: theme.colors.textDark,
    },
    row: {
        flex: 1,
        flexDirection: "row",
        borderStyle: "solid",
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: theme.colors.grey50,
        minHeight: 48,
        paddingHorizontal: 16,
    },
    cell: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
    },
    table: {
        width: "100%",
    },
});
