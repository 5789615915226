import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    GQCashRegisterEditFormQuery,
    useCashRegisterEditMutation,
} from "graphql-sdk";
import type { ICashRegisterInput } from "lib";
import { useForm, LoadingScreen, Button } from "@venuepos/react-common";
import { CashRegisterCommonForm } from "./cash-register-common-form";
import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";

export function CashRegisterEditForm({
    data,
    nameChanged,
}: {
    data: GQCashRegisterEditFormQuery;
    nameChanged: (name: string) => void;
}) {
    const [t] = useTranslation();
    const [cashRegisterEdit] = useCashRegisterEditMutation();
    const { handleMutationError } = useHandleMutationError();
    const form = useForm<ICashRegisterInput>(
        {
            name: { required: true },
        },
        null
    );
    const [{ values }, { handleSubmit, setDefaultValues }] = form;

    useEffect(() => {
        if (data && data.cashRegister) {
            setDefaultValues({
                name: data.cashRegister.name,
                departmentId: data.cashRegister.department
                    ? data.cashRegister.department.id
                    : null,
            });
        }
    }, [data, setDefaultValues]);

    const save = useCallback(async () => {
        if (!data || !data.cashRegister || !values) {
            return;
        }

        await handleMutationError(
            async () =>
                await cashRegisterEdit({
                    variables: {
                        id: data.cashRegister!.id,
                        cashRegister: {
                            name: values.name,
                            departmentId: values.departmentId,
                        },
                    },
                }),
            t("backoffice.cash_register_edit.saved", "Cash register saved"),
            () => {
                nameChanged(values.name);
            }
        );
    }, [cashRegisterEdit, data, handleMutationError, nameChanged, t, values]);

    if (
        !data ||
        !data.cashRegister ||
        !data.departments ||
        !data.departments.data ||
        !values
    ) {
        return <LoadingScreen style="light" />;
    }

    return (
        <>
            <CashRegisterCommonForm
                departments={data.departments.data}
                form={form}
            />
            <Button onPress={handleSubmit(save)} testID="cashRegister:save">
                {t("common.save", "Save")}
            </Button>
        </>
    );
}
