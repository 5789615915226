import {
    DataTable,
    Icon,
    Loading,
    SearchInput,
    StyleFunction,
    Surface,
    useAuth,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQCashRegistersStatusQuery,
    useCashRegistersStatusQuery,
} from "graphql-sdk";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootStackScreenProps } from "../../../navigation";
import { useAdminSession } from "../../../session";
import { AdminContainer } from "../../container";
import { CashRegisterStatusRow } from "./cash-register-status-row";

type ScreenProps = RootStackScreenProps<"CASH_REGISTER_STATUS">;

export function CashRegisterStatusListScreen({ route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["admin.role"]);

    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [{ locale }] = useAdminSession(["locale"]);
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "deviceLastSeen",
        initialSortDirection: "DESC",
    });

    const [search, setSearch] = useState<string>("");

    // GraphQL
    const { data, loading, refetch } = useCashRegistersStatusQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        },
        fetchPolicy: "no-cache",
    });
    CashRegisterStatusRow;
    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const renderCashRegisterStatusRow = useCallback(
        (
            item: GQCashRegistersStatusQuery["cashRegistersStatus"]["data"][0],
            rowIndex
        ) => (
            <CashRegisterStatusRow
                key={item.id}
                item={item}
                locale={locale}
                style={rowIndex % 2 === 1 ? styles.oddRow : undefined}
            />
        ),
        [locale, styles.oddRow]
    );
    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list
            onPageChange(0);
        },
        [onPageChange]
    );

    return (
        <AdminContainer>
            <SearchInput onChange={handleSearchTextChange} />
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "cash_register" && sortDirection
                            }
                            onPress={() => onSortChange("cash_register")}
                        >
                            {t(
                                "backoffice.common.cash_register",
                                "Cash Register"
                            )}
                        </DataTable.Title>

                        <DataTable.Title
                            sortDirection={
                                sortBy === "merchant" && sortDirection
                            }
                            onPress={() => onSortChange("merchant")}
                        >
                            {t("backoffice.common.merchant", "Merchant")}
                        </DataTable.Title>

                        <DataTable.Title
                            sortDirection={
                                sortBy === "lastNotifySentAt" && sortDirection
                            }
                            onPress={() => onSortChange("lastNotifySentAt")}
                        >
                            {t(
                                "backoffice.cash_register_status.last_notify_sent_at",
                                "Last notify"
                            )}
                        </DataTable.Title>

                        <DataTable.Title
                            sortDirection={
                                sortBy === "lastAppDataSentAt" && sortDirection
                            }
                            onPress={() => onSortChange("lastAppDataSentAt")}
                        >
                            {t(
                                "backoffice.cash_register_status.last_app_data_sent_at",
                                "App data sent"
                            )}
                        </DataTable.Title>

                        <DataTable.Title
                            sortDirection={
                                sortBy === "deviceLastSeen" && sortDirection
                            }
                            onPress={() => onSortChange("deviceLastSeen")}
                        >
                            {t(
                                "backoffice.cash_register_status.last_seen",
                                "Last seen"
                            )}
                        </DataTable.Title>

                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={styles.iconColumn.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        data?.cashRegistersStatus.data.map(
                            renderCashRegisterStatusRow
                        )
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={
                            data?.cashRegistersStatus.pagination.pages
                        }
                        itemCount={
                            data?.cashRegistersStatus.pagination.resultCount
                        }
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: theme.spacingScale * 6,
        color: theme.colors.black,
    },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});
