import type {
    ValidationSchema,
    ProductButton,
    ILayoutInput,
    ILayoutCreate,
} from "lib";

export const defaultLayout: ILayoutCreate = {
    name: "",
    data: [],
    dimensions: { columns: 0, rows: 0 },
    layoutType: "",
};

export const defaultEditLayout: ILayoutInput = {
    name: "",
    data: [],
};

export const schemaProductButton: ValidationSchema<ProductButton> = {
    height: { required: true, number: true, minValue: { value: 1 } },
    width: { required: true, number: true, minValue: { value: 1 } },
    x: { required: true, number: true },
    y: { required: true, number: true },
    productId: { required: true },
};
