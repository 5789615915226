import { useNavigation } from "@react-navigation/native";
import {
    Button,
    Form,
    IconButton,
    InputControl,
    Loading,
    NumberInput,
    Picker,
    StyleFunction,
    Surface,
    TextInput,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { useInventoryProductNoteTemplatesQuery } from "graphql-sdk";
import type { IInventoryProductInput } from "lib";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { RadioButton } from "react-native-paper";

export function InventoryQuantity(props: {
    form: Form<IInventoryProductInput & { type: "quantity" | "difference" }>;
    onSubmit: () => void;
    unit: string;
    loading: boolean;
}) {
    const [{ values, errors }, { handleSubmit, setValues }] = props.form;
    const [t] = useTranslation();
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);
    const { navigate } = useNavigation();

    const [type, setType] = useState<string>("quantity");

    const handleInputType = (newValue: string) => {
        return setType(newValue);
    };

    const { data: noteTemplateData, loading } =
        useInventoryProductNoteTemplatesQuery({
            variables: {
                pagination: {
                    page: 0,
                    pageSize: 99999,
                },
            },
            fetchPolicy: "no-cache",
        });

    const handleNoteTemplateChange = useCallback(
        item => {
            setValues({ note: item });
        },
        [setValues]
    );

    if (!values) {
        return (
            <Surface>
                <Loading />
            </Surface>
        );
    }

    return (
        <Surface>
            <IconButton
                name="settings"
                style={styles.closeButton}
                onPress={() => {
                    navigate("INVENTORY_PRODUCT_NOTE_TEMPLATES");
                }}
            />
            <View>
                <RadioButton.Group onValueChange={handleInputType} value={type}>
                    <View style={styles.container}>
                        <View style={styles.radioButton}>
                            <RadioButton
                                value="quantity"
                                color={theme.colors.secondary}
                            />
                        </View>
                        <InputControl
                            error={errors.quantity}
                            description={t(
                                "backoffice.product_form.inventory_quantity_description",
                                "Enter the stock quantity."
                            )}
                        >
                            <View>
                                <NumberInput
                                    unit={props.unit}
                                    defaultValue={values?.quantity}
                                    label={t("common.quantity", "Quantity")}
                                    onChange={newValue => {
                                        if (newValue !== null) {
                                            setValues({
                                                quantity: newValue,
                                                type: "quantity",
                                            });
                                        }
                                    }}
                                    decimals={0}
                                    disabled={type !== "quantity"}
                                />
                            </View>
                        </InputControl>
                    </View>
                    <View style={styles.container}>
                        <View style={styles.radioButton}>
                            <RadioButton
                                value="difference"
                                color={theme.colors.secondary}
                            />
                        </View>
                        <InputControl
                            error={errors.quantity}
                            description={t(
                                "backoffice.product_form.inventory_difference_description",
                                "Enter the stock difference."
                            )}
                        >
                            <NumberInput
                                unit={props.unit}
                                defaultValue={0}
                                label={t("common.difference", "Difference")}
                                onChange={newValue => {
                                    if (newValue !== null) {
                                        setValues({
                                            quantity: newValue,
                                            type: "difference",
                                        });
                                    }
                                }}
                                decimals={0}
                                disabled={type !== "difference"}
                            />
                        </InputControl>
                    </View>
                </RadioButton.Group>
            </View>

            <InputControl
                description={t(
                    "backoffice.product_form.inventory_note_description",
                    "Optional note."
                )}
            >
                <TextInput
                    label={t("common.note", "Note")}
                    placeholder={t(
                        "backoffice.common.enter_note",
                        "Enter note"
                    )}
                    value={values.note || ""}
                    onChangeText={text => setValues({ note: text })}
                    testID="note"
                    required={false}
                />
            </InputControl>

            {loading ? (
                <Loading />
            ) : noteTemplateData &&
              noteTemplateData?.inventoryProductNoteTemplates.data.length >
                  0 ? (
                <InputControl
                    description={t(
                        "backoffice.product_form.inventory_note_template_description",
                        "Choose a note from templates (Optional)"
                    )}
                >
                    <Picker onValueChange={handleNoteTemplateChange}>
                        <Picker.Item
                            label={t(
                                "backoffice.product_form.inventory_note_template",
                                "Note from template"
                            )}
                            key="template:placeholder"
                        />
                        {noteTemplateData?.inventoryProductNoteTemplates.data.map(
                            (item, index) => {
                                return (
                                    <Picker.Item
                                        label={item.note}
                                        key={"template:" + index}
                                    />
                                );
                            }
                        )}
                    </Picker>
                </InputControl>
            ) : null}
            <Button
                onPress={handleSubmit(props.onSubmit)}
                loading={props.loading}
            >
                {t("common.save", "Save")}
            </Button>
        </Surface>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: { flexDirection: "row" },
    radioButton: { alignSelf: "center" },
    closeButton: {
        color: theme.colors.textDark,
        alignSelf: "flex-end",
    },
});
