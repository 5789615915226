import {
    CheckBox,
    DataTable,
    Icon,
    SortDirection,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import React from "react";
import { useTranslation } from "react-i18next";

export function ProductTableHeader({
    sortByColumn,
    sortDirection,
    onSortChange,
    showSelectAll,
    allSelected,
    onSelectAll,
}: {
    sortByColumn?: string;
    sortDirection: SortDirection;
    onSortChange: (field: string) => void;
    showSelectAll: boolean;
    allSelected: boolean;
    onSelectAll: (value: boolean) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    return (
        <DataTable.Header>
            <DataTable.Title style={styles.iconColumn}>
                <CheckBox
                    value={allSelected}
                    onValueChange={onSelectAll}
                    disabled={!showSelectAll}
                    testID="layout:selectAllProducts"
                />
            </DataTable.Title>
            <DataTable.Title
                sortDirection={sortByColumn === "name" && sortDirection}
                onPress={() => onSortChange("name")}
                style={styles.productNameColumn}
            >
                {t("common.name", "Name")}
            </DataTable.Title>
            <DataTable.Title
                sortDirection={sortByColumn === "groupName" && sortDirection}
                onPress={() => onSortChange("groupName")}
            >
                {t("backoffice.product.group", "Group")}
            </DataTable.Title>
            <DataTable.Title
                sortDirection={sortByColumn === "amount" && sortDirection}
                onPress={() => onSortChange("amount")}
                numeric
            >
                {t("backoffice.product.price", "Price")}
            </DataTable.Title>
            <DataTable.Title style={styles.colorColumn} numeric>
                <Icon name="sort" color={styles.sortIcon.color} />
            </DataTable.Title>
        </DataTable.Header>
    );
}

const styleFunc: StyleFunction = theme => ({
    productNameColumn: {
        flexGrow: 1,
        flexBasis: 250,
    },
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 40 },
    colorColumn: {
        justifyContent: "center",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
    },
    endColumn: {
        paddingRight: 16,
    },
    sortIcon: {
        color: theme.colors.black,
    },
});
