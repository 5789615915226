import React, { useCallback, useState } from "react";
import {
    SwitchProps as SwitchPropsImpl,
    Switch as SwitchImpl,
} from "react-native";
import _ from "lodash";

interface SwitchProps extends SwitchPropsImpl {
    defaultValue?: boolean;
    disabled?: boolean;
}

export function Switch(props: SwitchProps) {
    const [value, setValue] = useState<boolean>(
        props.value || props.defaultValue || false
    );

    const onValueChange = useCallback(
        async (changedValue: boolean) => {
            if (props.disabled === true) {
                return;
            }

            setValue(changedValue);
            if (props.onValueChange) {
                await props.onValueChange(changedValue);
            }
        },
        [props]
    );

    const omitted = _.omit(props, "value");
    return (
        <SwitchImpl
            {...omitted}
            value={value}
            onValueChange={onValueChange}
            thumbColor={props.thumbColor}
        />
    );
}
