import {
    DataTable,
    Icon,
    Loading,
    SearchInput,
    StyleFunction,
    Surface,
    useAuth,
    usePagination,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQPrintersQuery,
    usePrinterDeleteMutation,
    usePrintersQuery,
} from "graphql-sdk";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";
import { PrinterRow } from "./printer-row";

type ScreenProps = RootStackScreenProps<"PRINTERS">;

export function Printers({ navigation: { navigate }, route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.printer");

    const theme = useTheme();
    const [t] = useTranslation();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
    });

    const styles = useThemedStyle(styleFunc);
    const [search, setSearch] = useState<string>("");

    const [deletePrinter] = usePrinterDeleteMutation();
    const { handleMutationError } = useHandleMutationError();

    // GraphQL
    const { data, loading, refetch } = usePrintersQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const handleView = useCallback(
        (id: string) => {
            navigate("PRINTER_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleDelete = useCallback(
        async (id: string) => {
            await handleMutationError(
                async () => await deletePrinter({ variables: { id } }),
                t("backoffice.printer.deleted", "Printer deleted"),
                async () => {
                    await refetch();
                }
            );
        },
        [deletePrinter, handleMutationError, refetch, t]
    );

    const renderPrinterRow = useCallback(
        (item: GQPrintersQuery["printers"]["data"][0]) => (
            <PrinterRow
                key={item.id}
                item={item}
                onSelect={handleView}
                onDelete={handleDelete}
            />
        ),
        [handleDelete, handleView]
    );

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list
            onPageChange(0);
        },
        [onPageChange]
    );

    return (
        <AdminContainer>
            <SearchInput onChange={handleSearchTextChange} />
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("backoffice.printer.name.header", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "manufacturer" && sortDirection
                            }
                            onPress={() => onSortChange("manufacturer")}
                            style={styles.smallColumn}
                        >
                            {t(
                                "backoffice.printer.manufacturer.header",
                                "Manufacturer"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "type" && sortDirection}
                            onPress={() => onSortChange("type")}
                            style={styles.smallColumn}
                        >
                            {t("backoffice.printer.type.header", "Type")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "serial" && sortDirection}
                            onPress={() => onSortChange("serial")}
                        >
                            {t("backoffice.printer.serial.header", "Serial")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "class" && sortDirection}
                            onPress={() => onSortChange("class")}
                            style={styles.smallColumn}
                        >
                            {t("backoffice.printer.class.header", "Class")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "cash_register" && sortDirection
                            }
                            onPress={() => onSortChange("cash_register")}
                        >
                            {t(
                                "backoffice.printer.cash_register.header",
                                "Cash register"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "created_at" && sortDirection
                            }
                            onPress={() => onSortChange("created_at")}
                        >
                            {t("backoffice.printer.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {!loading ? (
                        data?.printers.data.map(renderPrinterRow)
                    ) : (
                        <Loading />
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.printers.pagination.pages}
                        itemCount={data?.printers.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "space-between",
        marginBottom: theme.spacingScale * 2,
        flexWrap: "wrap",
    },
    actionButtonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    button: {
        alignSelf: "flex-end",
        marginLeft: theme.spacingScale,
    },
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 40 },
    smallColumn: {
        flexBasis: 140,
        flexGrow: 0,
    },
});
