import { TextInput } from "@venuepos/react-common";
import React from "react";
import { useTranslation } from "react-i18next";

import type { InputTypes } from "lib";
// Handles types: text and number
export function EntityFormTextInput({
    higherOrder,
    type,
    value,
    onValueChange,
    disabled,
    testID,
}: {
    higherOrder: any;
    type: InputTypes;
    value: any;
    onValueChange: (v: any) => void;
    disabled: boolean;
    testID?: string;
}) {
    const [t] = useTranslation();

    if (!disabled) {
        if (type === "text") {
            return (
                <TextInput
                    placeholder={t(
                        "backoffice.entity_config.enter_value",
                        "Enter value"
                    )}
                    defaultValue={value}
                    onChangeText={onValueChange}
                    testID={testID}
                />
            );
        } else {
            return (
                <TextInput
                    placeholder={t(
                        "backoffice.entity_config.enter_value",
                        "Enter value"
                    )}
                    defaultValue={String(value)}
                    onChangeText={val => onValueChange(Number(val))}
                    testID={testID}
                />
            );
        }
    } else {
        return (
            <TextInput
                key={"disabled"}
                value={String(higherOrder)}
                disabled={true}
                testID={testID}
            />
        );
    }
}
