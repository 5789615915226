import React, { useCallback } from "react";
import { View } from "react-native";
import type { GQProductsQuery } from "graphql-sdk";
import {
    useTheme,
    useThemedStyle,
    StyleFunction,
    DataTable,
    Icon,
    CheckBox,
    Text,
    ColorIndicator,
} from "@venuepos/react-common";

export function ProductRow({
    item,
    formattedAmount,
    selectedProduct,
    alreadyAdded,
    onSelect,
    onMultiSelectChange,
}: {
    item: GQProductsQuery["products"]["data"][0];
    formattedAmount: string;
    selectedProduct: boolean;
    alreadyAdded: boolean;
    onSelect: (productId: GQProductsQuery["products"]["data"][0]["id"]) => void;
    onMultiSelectChange: (
        selected: boolean,
        productId: GQProductsQuery["products"]["data"][0]["id"]
    ) => void;
}) {
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();

    const handleSelect = useCallback(() => {
        onSelect(item.id);
    }, [item.id, onSelect]);

    const handleMultiSelectChange = useCallback(
        value => onMultiSelectChange(value, item.id),
        [item.id, onMultiSelectChange]
    );

    return (
        <DataTable.Row
            style={alreadyAdded ? styles.alreadyAdded : undefined}
            testID={`layout:product:${item.name}:${
                alreadyAdded ? "isAdded" : "notAdded"
            }`}
        >
            <DataTable.Cell style={styles.iconColumn}>
                <CheckBox
                    value={selectedProduct}
                    onValueChange={handleMultiSelectChange}
                    style={styles.checkbox}
                    testID={`layout:check:${item.name}`}
                />
            </DataTable.Cell>
            <DataTable.Cell
                style={styles.productNameColumn}
                testID={`layout:select:${item.name}`}
                onPress={handleSelect}
            >
                {item.parent ? (
                    <View>
                        <Text style={styles.parentName}>
                            {item.parent.name}
                            <Icon
                                name="arrow-down"
                                size="tiny"
                                color={theme.colors.grey500}
                                style={styles.arrowIcon}
                            />
                        </Text>
                        <Text numberOfLines={1} style={styles.productName}>
                            {item.name}
                        </Text>
                    </View>
                ) : (
                    <Text style={styles.productName} numberOfLines={1}>
                        {item.name}
                    </Text>
                )}
            </DataTable.Cell>
            <DataTable.Cell>
                <Text numberOfLines={1}>{item.group!.name}</Text>
            </DataTable.Cell>
            <DataTable.Cell numeric>
                <Text numberOfLines={1}>{formattedAmount}</Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.colorColumn}>
                <ColorIndicator
                    color={item.group!.color}
                    copyOnClick={true}
                    size="large"
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    groupColor: {
        borderRadius: theme.spacingScale / 2,
        width: theme.spacingScale,
        height: theme.spacingScale,
    },
    parentName: {
        fontSize: 10,
        color: theme.colors.grey800,
    },
    checkbox: {
        marginVertical: 6,
    },
    arrowIcon: {
        paddingLeft: theme.spacingScale / 2,
    },
    productNameColumn: {
        flexGrow: 1,
        flexBasis: 250,
    },
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 40 },
    colorColumn: {
        justifyContent: "center",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
    },
    alreadyAdded: {
        backgroundColor: theme.colors.grey100,
        borderBottomColor: theme.colors.grey250,
    },
    productName: {
        color: theme.colors.secondary,
        textDecorationStyle: "solid",
        textDecorationColor: theme.colors.secondary,
        textDecorationLine: "underline",
    },
});
