import {
    DateTimeTypes,
    inRangeDate,
    inRangeDateTime,
    inRangeTime,
    inRangeWeek,
    inRangeWeekDay,
} from "../date";
import type { OrderConditionContext } from "./order-condition";

type ItemFilters = {
    productId: string;
} & DateTimeTypes;

type ItemFilter = {
    type: keyof ItemFilters;
} & Partial<ItemFilters>;

export type ItemFilterQuery = ItemFilter[][];

/**
 * Find products that can be used based on the filter.
 * An example could be that the condition only applies to a product id that is added within a specific time interval.
 */
export function filterOrderItems(
    orderContextItems: OrderConditionContext["items"],
    filterQuery: ItemFilterQuery
): OrderConditionContext["items"] {
    if (filterQuery.length < 1) {
        return orderContextItems;
    }

    let filteredItems: OrderConditionContext["items"] = [];
    filterQuery.forEach(filters => {
        let items = [...orderContextItems];
        filters.forEach(filter => {
            switch (filter.type) {
                case "productId":
                    const productId = filter.productId!;
                    items = items.filter(item => item.productId === productId);
                    break;

                case "dateTime":
                    const dateTime = filter.dateTime!;
                    items = items.filter(item =>
                        inRangeDateTime(
                            item.addedAt,
                            dateTime.from,
                            dateTime.to
                        )
                    );
                    break;

                case "date":
                    const date = filter.date!;
                    items = items.filter(item =>
                        inRangeDate(item.addedAt, date.from, date.to)
                    );
                    break;

                case "time":
                    const time = filter.time!;
                    items = items.filter(item =>
                        inRangeTime(item.addedAt, time.from, time.to)
                    );
                    break;

                case "week":
                    const week = filter.week!;
                    items = items.filter(item =>
                        inRangeWeek(item.addedAt, week.from, week.to)
                    );
                    break;

                case "weekDay":
                    const weekDay = filter.weekDay!;
                    items = items.filter(item =>
                        inRangeWeekDay(item.addedAt, weekDay.from, weekDay.to)
                    );
                    break;
            }
        });
        filteredItems = filteredItems.concat(items);
    });
    return filteredItems;
}

export function assignOrderItemFilter<T extends keyof ItemFilters>(
    type: T,
    args: ItemFilters[T]
): ItemFilter {
    const filter = {
        type,
    };
    (filter as any)[type] = args;
    return filter;
}
