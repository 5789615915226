import React, { useCallback } from "react";
import type { LayoutTableButton, ValidationSchema } from "lib";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";
import {
    InputControl,
    Loading,
    TextInput,
    Button,
    useForm,
} from "@venuepos/react-common";

export type TableButtonFormType = Pick<LayoutTableButton, "buttonType">;

export type TableButtonInput = TableButtonFormType & {
    label?: LayoutTableButton["label"];
};

export const defaultTableButton: TableButtonInput = {
    buttonType: "TABLE",
    label: "",
};

export const schemaTableButton: ValidationSchema<TableButtonInput> = {
    label: { required: true },
};

export function TableButtonForm({
    children,
    onSubmit: submitCallback,
    values: defaultValues,
}: {
    children: React.ReactNode;
    onSubmit: (button: LayoutTableButton) => void;
    values: TableButtonInput;
}) {
    const [t] = useTranslation();
    const form = useForm(
        schemaTableButton,
        defaultValues || defaultTableButton
    );
    const [{ values, errors }, { setValue, handleSubmit }] = form;

    const onSubmit = useCallback(() => {
        submitCallback(values as LayoutTableButton);
    }, [submitCallback, values]);

    if (!values) {
        return <Loading />;
    }

    return (
        <>
            <View style={styles.onTop}>
                <InputControl error={errors.label}>
                    <TextInput
                        label={t("layout.function_button_form.label", "Label")}
                        defaultValue={values.label}
                        onChangeText={value => {
                            setValue("label", value);
                        }}
                    />
                </InputControl>
            </View>
            <View style={styles.buttonContainer}>
                {children}
                <Button
                    style={styles.submitButton}
                    type="primary"
                    onPress={handleSubmit(onSubmit)}
                >
                    {t("layout.function_button_form.types.apply", "Apply")}
                </Button>
            </View>
        </>
    );
}

const styles = StyleSheet.create({
    onTop: {
        zIndex: 2,
    },
    buttonContainer: {
        flexDirection: "row",
    },
    submitButton: {
        flex: 1,
    },
});
