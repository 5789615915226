import type { ValidationSchema } from "@venuepos/react-common";
import { set as setDate } from "date-fns";
import { RelativePeriods, RelativePeriodStrings, ReportTypes } from "lib";

type ReportForm = {
    type: keyof typeof ReportTypes;
    from: Date;
    to: Date;
    period: RelativePeriodStrings;
    startOfDay: number;
};

const now = new Date();
export const defaultReportForm: ReportForm = {
    type: ReportTypes.PRODUCT_SALES,
    from: setDate(new Date(), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    }),
    to: setDate(
        new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1),
        {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
        }
    ),
    period: RelativePeriods.TODAY,
    startOfDay: 0,
};

export const schemaReportForm: ValidationSchema<ReportForm> = {
    type: { required: true },
    from: { required: true },
    to: { required: true },
    startOfDay: { required: true, wholeNumber: true },
};
