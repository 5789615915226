import {
    ITheme,
    LoadingScreen,
    StyleFunction,
    useAuth,
    useForm,
    useMe,
    useThemedStyle,
    useToast,
} from "@venuepos/react-common";
import { useRoleCreateMutation } from "graphql-sdk";
import { defaultRolePermissions, IRoleSave, rolePermissionsToInput } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { RootStackScreenProps } from "../../navigation";
import { defaultRole, schemaRole } from "./forms";
import { RoleForm } from "./role-form";
import { RoleScreen } from "./role-screen";

type ScreenProps = RootStackScreenProps<"ROLE_CREATE">;

export function RoleCreateScreen({ navigation: { navigate } }: ScreenProps) {
    const me = useMe();
    const auth = useAuth();
    auth.enforce(["merchant.role", "admin.role"], "OR");

    const [t] = useTranslation();
    const toast = useToast();
    const [roleCreate, { loading: createLoading }] = useRoleCreateMutation();
    const form = useForm<IRoleSave>(schemaRole, defaultRole);
    const [{ values }, { reset }] = form;
    const styles = useThemedStyle(styleFunc);
    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await roleCreate({
            variables: {
                role: {
                    name: values.name,
                    type: me.role!.type,
                    shared: false,
                    permissions: values.permissions,
                },
            },
        });

        reset();
        toast.success(t("backoffice.role_form.created", "Role created"));
        navigate("ROLES");
    }, [me.role, navigate, reset, roleCreate, t, toast, values]);

    if (!values || !me.role) {
        return <LoadingScreen style="light" />;
    }

    return (
        <RoleScreen>
            <View style={styles.box}>
                <View style={styles.container}>
                    <RoleForm
                        form={form}
                        merchant={me.merchant}
                        onSubmit={create}
                        submitButton={t("common.create", "Create")}
                        permissionsValues={rolePermissionsToInput(
                            defaultRolePermissions
                        )}
                        loading={createLoading}
                        roleType={me.role.type}
                        readOnly={false}
                    />
                </View>
            </View>
        </RoleScreen>
    );
}

const styleFunc: StyleFunction = (theme: ITheme) => {
    return {
        box: {
            width: "100%",
            backgroundColor: theme.colors.white,
        },
        container: {
            width: "100%",
            maxWidth: 600,
            alignSelf: "flex-start",
        },
    };
};
