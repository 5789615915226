// Purpose: Just somewhere we can try out React Native Paper components without making a mess

import React, { useState } from "react";
import { View } from "react-native";
import {
    Menu,
    Divider,
    Button as PaperButton,
    Searchbar,
    Chip,
} from "react-native-paper";
import {
    DateTimePickerInput,
    MultiPicker,
    RelativePeriodPicker,
    Spacer,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import { useAdminSession } from "../../session";
import { dateToDateTime, RelativePeriods, RelativePeriodStrings } from "lib";
import { useTranslation } from "react-i18next";

export function PaperDemoScreen() {
    const [t] = useTranslation();
    const [{ locale }] = useAdminSession(["locale"]);
    const styles = useThemedStyle(styleFunc);

    const [fromDate, setFromDate] = useState<Date>(new Date("2021-07-01"));
    const [toDate, setToDate] = useState<Date>(new Date("2021-07-02"));

    const [visible, setVisible] = useState(false);

    const [selectedItems, setSelectedItems] = useState<(number | string)[]>([
        "foo",
    ]);

    const [selectedPeriod, setSelectedPeriod] = useState<RelativePeriodStrings>(
        RelativePeriods.TODAY
    );

    const closeMenu = () => {
        setVisible(false);
    };

    const openMenu = () => {
        setVisible(true);
    };

    return (
        <View>
            <View style={styles.exampleContainer}>
                <Menu
                    visible={visible}
                    onDismiss={closeMenu}
                    anchor={
                        <PaperButton onPress={openMenu}>Show menu</PaperButton>
                    }
                >
                    <Menu.Item
                        onPress={() => ({
                            // empty
                        })}
                        title="Item 1"
                    />
                    <Menu.Item
                        onPress={() => ({
                            // empty
                        })}
                        title="Item 2"
                    />
                    <Divider />
                    <Menu.Item
                        onPress={() => ({
                            // empty
                        })}
                        title="Item 3"
                    />
                </Menu>
                <Spacer space={10} />
                <View>
                    <Searchbar placeholder="Search" value="moo" />
                    <PaperButton
                        onPress={() => {
                            console.log("Booyaaa");
                        }}
                        theme={{ roundness: 3 }}
                    >
                        Press me
                    </PaperButton>
                </View>
            </View>
            <View style={styles.pickerContainer}>
                <MultiPicker
                    values={[
                        { value: "foo", label: "Foo" },
                        { value: "bar", label: "Bar" },
                        null,
                        { value: "baz", label: "Baz" },
                        {
                            value: 42,
                            label: "The answer to Life, The Universe and All That",
                        },
                        { value: -1, label: "Negative one!1" },
                    ]}
                    selectedValues={selectedItems}
                    onValueChange={values => setSelectedItems(values)}
                    label={"Active multi-select"}
                    placeholder={"All"}
                />
                <Spacer space={10} />

                <MultiPicker
                    values={[
                        { value: "foo", label: "Foo" },
                        { value: "bar", label: "Bar" },
                        null,
                        { value: "baz", label: "Baz" },
                        {
                            value: 42,
                            label: "The answer to Life, The Universe and All That",
                        },
                        { value: -1, label: "Negative one!1" },
                    ]}
                    fixedValues={["foo"]}
                    selectedValues={selectedItems}
                    onValueChange={values => setSelectedItems(values)}
                    label={"Multi-select with fixed values"}
                    placeholder={"All"}
                />
                <Spacer space={10} />

                <MultiPicker
                    disabled={true}
                    values={[
                        { value: "foo", label: "Foo" },
                        null, // A divider element
                        { value: "bar", label: "Bar" },
                        { value: "baz", label: "Baz" },
                        {
                            value: 42,
                            label: "The answer to Life, The Universe and All That",
                        },
                        { value: -1, label: "Negative one!1" },
                    ]}
                    selectedValues={selectedItems}
                    onValueChange={values => setSelectedItems(values)}
                    label={"Disabled multi-select"}
                />
                <Spacer space={10} />

                <MultiPicker
                    values={[
                        { value: "foo", label: "Foo" },
                        { value: "bar", label: "Bar" },
                        { value: "baz", label: "Baz" },
                        {
                            value: 42,
                            label: "The answer to Life, The Universe and All That",
                        },
                        { value: -1, label: "Negative one!1" },
                        // { value: 14 }, // DEV remove the comment from this line to see how the multi-picker handles values without labels
                    ]}
                    selectedValues={["foo", "woo", 999, ...selectedItems]}
                    fixedValues={["foo", "adr"]}
                    onValueChange={values => {
                        setSelectedItems(values);
                    }}
                    label={"Multi-select with faulty input values"}
                />
            </View>

            {/*A test of the RN Paper chips*/}
            <View style={styles.chipContainer}>
                <Chip
                    key="chip1"
                    style={styles.chip}
                    textStyle={styles.chipText}
                >
                    {"Chip 1 with a rather long name"}
                </Chip>
                <Chip
                    key="chip2"
                    style={styles.chip}
                    textStyle={styles.chipText}
                >
                    {"Chip 2 also has a long hame "}
                </Chip>
                <Chip
                    key="chip3"
                    style={styles.chip}
                    textStyle={styles.chipText}
                >
                    {"Chip 3"}
                </Chip>
                <Chip
                    key="chipFoo"
                    style={styles.chip}
                    textStyle={styles.chipText}
                >
                    {"Chip Foo"}
                </Chip>
                <Chip
                    key="chipBar"
                    style={styles.chip}
                    mode="outlined"
                    textStyle={styles.chipText}
                >
                    {"Chip Bar"}
                </Chip>
                <Chip
                    key="chipBaz"
                    style={styles.chip}
                    textStyle={styles.chipText}
                >
                    {"Chip Baz"}
                </Chip>
                <Chip
                    key="chip5"
                    style={styles.chip}
                    textStyle={styles.chipText}
                >
                    {
                        "Chip five has the longest name of all the chips in this demo"
                    }
                </Chip>
                <Chip
                    key="chip4"
                    style={styles.chip}
                    textStyle={styles.chipText}
                    onClose={() => console.log("closing chip 4")}
                >
                    {"Chip 4 is closable"}
                </Chip>
            </View>

            <View>
                <View style={styles.row}>
                    <DateTimePickerInput
                        label={t("common.from", "From")}
                        dateTimeType="date"
                        value={dateToDateTime(fromDate)}
                        style={styles.horizontal}
                    />
                    <DateTimePickerInput
                        label={t("common.to", "To")}
                        dateTimeType="date"
                        value={dateToDateTime(toDate)}
                        style={styles.horizontal}
                    />
                </View>

                <RelativePeriodPicker
                    locale={locale}
                    onValueChange={({ selectedPeriod: period, from, to }) => {
                        setSelectedPeriod(period);
                        setFromDate(from);
                        setToDate(to);
                        console.log(
                            "Period",
                            period,
                            "is from",
                            from,
                            "to",
                            to
                        );
                    }}
                    value={selectedPeriod}
                    style={styles.periodPicker}
                />
            </View>

            <PaperButton
                onPress={() => {
                    const newFromDate = new Date(
                        fromDate.getFullYear(),
                        fromDate.getMonth(),
                        fromDate.getDate() - 1
                    );
                    setFromDate(newFromDate);
                }}
                mode="outlined"
            >
                {"Set From back one day ..."}
            </PaperButton>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    chip: {
        maxWidth: theme.spacingScale * 20,
        marginRight: theme.spacingScale,
        marginBottom: theme.spacingScale,
    },
    chipText: {
        maxWidth: theme.spacingScale * 15,
    },
    chipContainer: {
        flexWrap: "wrap",
        flexDirection: "row",
        padding: theme.spacingScale,
        borderColor: "hotpink",
        borderWidth: 1,
        borderStyle: "dashed",
        maxWidth: theme.spacingScale * 50,
    },
    exampleContainer: {
        paddingTop: theme.spacingScale * 5,
        flexDirection: "row",
        justifyContent: "center",
    },

    pickerContainer: {
        padding: theme.spacingScale * 2,
        backgroundColor: "lightgoldenrodyellow",
        margin: theme.spacingScale * 4,
    },
    periodPicker: { marginHorizontal: 20, marginVertical: 40 },
    horizontal: { marginHorizontal: 20 },
    row: { flexDirection: "row" },
});
