import {
    DataTable,
    IconButton,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import type { GQBookkeepingAccountQuery } from "graphql-sdk";
import { formatAmount, formatDateTime, SupportedCurrenciesType } from "lib";
import { AvailableLocale, useTranslation } from "locales";
import React, { useCallback } from "react";

export function TransactionTableRow({
    item,
    locale,
    currency,
    onShowInvoice,
}: {
    item: GQBookkeepingAccountQuery["bookkeepingAccount"]["bookkeepingTransactions"]["data"][0];
    locale: AvailableLocale;
    currency: SupportedCurrenciesType;
    onShowInvoice: (invoiceId: string) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const handleShowInvoice = useCallback(() => {
        if (!item.invoiceId) {
            return;
        }
        return onShowInvoice(item.invoiceId);
    }, [item.invoiceId, onShowInvoice]);

    // i18next t("backoffice.accounts.transaction_type.CREDIT", "Credit")
    // i18next t("backoffice.accounts.transaction_type.DEBIT", "Debit")

    return (
        <DataTable.Row>
            <DataTable.Cell>
                {formatDateTime(
                    item && item.transactionAt,
                    locale as AvailableLocale
                )}
            </DataTable.Cell>
            <DataTable.Cell>
                {t(
                    "backoffice.accounts.transaction_type." + item.type,
                    item.type
                )}
            </DataTable.Cell>
            <DataTable.Cell numeric>
                {formatAmount(item.amount, currency, {
                    locale,
                    printCurrency: true,
                })}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.iconColumn}>
                {item.invoiceId !== null && (
                    <IconButton
                        color={styles.iconColumn.color}
                        name="order"
                        onPress={handleShowInvoice}
                    />
                )}
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
        color: theme.colors.secondary,
    },
});
