import {
    Headline,
    IconButton,
    SortDirection,
    StyleFunction,
    Tabs,
    useModal,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { TagsCreateTab } from "./tags-create-tab";
import { TagsFindTab } from "./tags-find-tab";
import { TagsViewTab } from "./tags-view-tab";

import type { ITag } from "lib";
import type { GQPagination, GQTagScreenFieldsFragment } from "graphql-sdk";

export type AssignTagsModalOutput = ITag["id"][];

export type TagFetchResult = {
    data: GQTagScreenFieldsFragment[];
    loading: boolean;
    pagination: Pick<
        GQPagination,
        "pages" | "sort" | "sortDirection" | "resultCount"
    >;
};

export type AssignTagsModalProps = {
    onClose: (tags?: AssignTagsModalOutput) => void;
    enabledTags: ITag["id"][];
    tagsFindQuery: (variables: {
        pagination: {
            page: number;
            pageSize: number;
            sort: string | undefined;
            sortDirection: SortDirection;
        };
        search: {
            query: string;
        };
    }) => Promise<TagFetchResult>;
};

export function AssignTagsModal({
    onClose,
    tagsFindQuery,
    enabledTags,
}: AssignTagsModalProps) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();

    return (
        <View style={styles.container} testID="modal:assignTags">
            <View style={styles.headerContainer}>
                <Headline size="h2" style={styles.title}>
                    {t("backoffice.account.tag_operations.tags", "Tags")}
                </Headline>
                <IconButton
                    size="large"
                    color={theme.colors.primary}
                    name="times-circle"
                    onPress={() => onClose()}
                    testID="modal:assignTags:close"
                />
            </View>

            <Tabs
                labels={[
                    t("backoffice.account.tag_operations.View", "View"),
                    t("backoffice.account.tag_operations.Find", "Find"),
                    t("backoffice.account.tag_operations.Create", "Create"),
                ]}
            >
                <TagsViewTab onClose={onClose} enabledTags={enabledTags} />
                <TagsFindTab
                    tagsFindQuery={tagsFindQuery}
                    onClose={onClose}
                    enabledTags={enabledTags}
                />
                <TagsCreateTab onClose={onClose} enabledTags={enabledTags} />
            </Tabs>
        </View>
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    container: {
        backgroundColor: theme.colors.white,
        padding: theme.spacingScale * 2,
        alignSelf: "center",
        width: "100%",
        maxWidth: 1000,
        maxHeight: dimensions.height - 100,
    },
    headerContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginHorizontal: theme.spacingScale * 3,
        marginVertical: theme.spacingScale * 1.5,
    },
});

export function useAssignTagsModal() {
    const { render } = useModal<AssignTagsModalOutput | undefined>();
    return (props: Omit<AssignTagsModalProps, "onClose">) =>
        render(onClose => <AssignTagsModal {...props} onClose={onClose} />);
}
