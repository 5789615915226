import { useNavigation, useFocusEffect } from "@react-navigation/native";
import {
    Alert,
    Button,
    DataTable,
    Icon,
    IconButton,
    Loading,
    SearchInput,
    StyleFunction,
    Surface,
    useAuth,
    useConfirm,
    usePagination,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    useLayoutCopyMutation,
    useLayoutDeleteMutation,
    useProductLayoutsQuery,
} from "graphql-sdk";
import { formatDateTime } from "lib";
import { AvailableLocale } from "locales";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, View } from "react-native";
import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { useAdminSession } from "../../session";
import { LayoutScreen } from "./layout-screen";

export function LayoutsTab() {
    const auth = useAuth();
    auth.enforce(["merchant.layout.write", "merchant.layout.delete"], "OR");

    const { navigate } = useNavigation();
    const [{ locale }] = useAdminSession(["locale"]);
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const confirm = useConfirm();
    const [layoutDelete] = useLayoutDeleteMutation();
    const [layoutCopy] = useLayoutCopyMutation();
    const { handleMutationError } = useHandleMutationError();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
        initialSortDirection: "ASC",
    });
    const [search, setSearch] = useState<string>("");

    const { data, loading, error, refetch } = useProductLayoutsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        },
        fetchPolicy: "cache-and-network",
    });

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    const handleDelete = useCallback(
        async (id: string) => {
            if (
                !(await confirm(
                    t("backoffice.layouts.delete", "Delete layout?"),
                    t(
                        "backoffice.layouts.delete_explain",
                        "If you choose to delete this layout, the cash registers that use this layout, will stop working. Make sure that no cash register is using this layout before you continue.\n\nAre you sure, you want to delete this layout?"
                    )
                ))
            ) {
                return;
            }

            await handleMutationError(
                async () =>
                    await layoutDelete({
                        variables: {
                            id,
                        },
                    }),
                t("backoffice.layout.deleted.success", "Layout deleted"),
                async () => {
                    await refetch();
                }
            );
        },
        [confirm, handleMutationError, layoutDelete, refetch, t]
    );

    // Actions
    const handleEdit = useCallback(
        (id: string) => {
            navigate("LAYOUT_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleCopy = useCallback(
        async (id: string, title: string) => {
            if (title === undefined) {
                return;
            }
            await handleMutationError(
                async () =>
                    await layoutCopy({
                        variables: {
                            id,
                            newTitle: `${t(
                                "backoffice.layout.copy.titlePrefix",
                                "Copy:"
                            )} ${title}`,
                        },
                    }),
                t("backoffice.layout.copied.success", "Layout copied"),
                async () => {
                    await refetch();
                }
            );
        },
        [handleMutationError, layoutCopy, refetch, t]
    );

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list (in case the user changed to a different page)
            onPageChange(0);
        },
        [onPageChange]
    );

    if (error) {
        return (
            <LayoutScreen>
                <Alert type="error">
                    {t("common.error", "There was an error: {{errorText}}", {
                        errorText: error.message,
                    })}
                </Alert>
            </LayoutScreen>
        );
    }

    return (
        <LayoutScreen>
            {auth.may(["merchant.layout.write"]) ? (
                <View style={styles.container}>
                    <Button
                        testID="createLayout"
                        style={styles.button}
                        onPress={() => {
                            navigate("LAYOUT_CREATE");
                        }}
                    >
                        {t(
                            "backoffice.layout_list.create_layout",
                            "Create layout"
                        )}
                    </Button>
                </View>
            ) : null}
            <SearchInput
                onChange={handleSearchTextChange}
                testID="layout:layoutSearch"
            />
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.layout.size",
                                "Size (columns x rows)"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "created_at" && sortDirection
                            }
                            onPress={() => onSortChange("created_at")}
                            style={styles.createdAtColumn}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.iconColumn} numeric>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        <FlatList
                            data={data?.productLayouts.data}
                            renderItem={({ item }) => (
                                <DataTable.Row
                                    onPress={() => handleEdit(item.id)}
                                >
                                    <DataTable.Cell
                                        testID={`layout:${item.name}`}
                                    >
                                        {item.name}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {`${item.columns} x ${item.rows}`}
                                    </DataTable.Cell>
                                    <DataTable.Cell
                                        style={styles.createdAtColumn}
                                    >
                                        {formatDateTime(
                                            item.createdAt,
                                            locale as AvailableLocale
                                        )}
                                    </DataTable.Cell>
                                    <DataTable.Cell
                                        numeric
                                        style={styles.iconColumn}
                                    >
                                        {auth.may([
                                            "merchant.layout.write",
                                        ]) && (
                                            <>
                                                <IconButton
                                                    color={styles.icon.color}
                                                    name="edit"
                                                    onPress={() =>
                                                        handleEdit(item.id)
                                                    }
                                                    testID={`layout:edit:${item.name}`}
                                                />
                                                <IconButton
                                                    color={styles.icon.color}
                                                    name="copy"
                                                    onPress={() =>
                                                        handleCopy(
                                                            item.id,
                                                            item.name
                                                        )
                                                    }
                                                    testID={`layout:copy:${item.name}`}
                                                />
                                            </>
                                        )}
                                        {auth.may([
                                            "merchant.layout.delete",
                                        ]) && (
                                            <IconButton
                                                color={styles.icon.color}
                                                name="delete"
                                                onPress={() =>
                                                    handleDelete(item.id)
                                                }
                                                testID={`layout:delete:${item.name}`}
                                            />
                                        )}
                                    </DataTable.Cell>
                                </DataTable.Row>
                            )}
                            keyExtractor={(_, index) => index.toString()}
                        />
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.productLayouts.pagination.pages}
                        itemCount={data?.productLayouts.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </LayoutScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        alignItems: "flex-end",
        marginBottom: theme.spacingScale * 2,
    },
    button: { width: 200 },

    sortIcon: {
        justifyContent: "flex-end",
        color: theme.colors.black,
    },

    icon: {
        color: theme.colors.secondary,
    },
    iconColumn: { flexBasis: 120, flexGrow: 0, flexShrink: 0 },
    createdAtColumn: { flexBasis: 200, flexGrow: 0, flexShrink: 0 },
});
