import { useTheme } from "../../theme";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";
import React from "react";
import { PaddedLayout } from "../padded-layout";
import { Text } from "../text";
import { Headline } from "../headline";

export function OfflineScreen() {
    const theme = useTheme();
    const [t] = useTranslation();

    return (
        <PaddedLayout>
            <View style={theme.styles.viewCenter}>
                <Headline style={styles.text}>
                    {t("offline.no_connection", "No connection")}
                </Headline>
                <Text style={[theme.styles.textCenter, styles.text]}>
                    {t(
                        "offline.no_connection_info",
                        "Can not connect to our server. Make sure your device is connected to the internet and try again."
                    )}
                </Text>
            </View>
        </PaddedLayout>
    );
}

const styles = StyleSheet.create({
    text: {
        color: "white",
    },
});
