import React from "react";
import {
    DataTable,
    InputControl,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";

import { View, TextInput } from "react-native";
import { useTranslation } from "react-i18next";
import type { IMerchantChartOfAccountInput } from "lib";
import { produce } from "immer";

export function ChartOfAccounts(props: {
    chartOfAccounts: IMerchantChartOfAccountInput[];
    setValue: any;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const { chartOfAccounts, setValue } = props;

    const changeMerchantAccountNo = (
        merchantAccountNo: string,
        existingMerchantAccountNo: string
    ) => {
        setValue(
            "merchantChartOfAccounts",
            produce(chartOfAccounts, draft => {
                const item = draft.find(
                    itr => itr.merchantAccountNo === existingMerchantAccountNo
                );

                if (item) {
                    item.merchantAccountNo = merchantAccountNo;
                }
            })
        );
    };

    return (
        <View style={styles.container}>
            {chartOfAccounts && chartOfAccounts.length > 0 && (
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title>
                            {t("common.chart_of_accounts_name", "Account name")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "common.merchant_account_no",
                                "Merchant's account no."
                            )}
                        </DataTable.Title>
                    </DataTable.Header>
                    {chartOfAccounts.map((item, index) => (
                        <DataTable.Row key={index.toString()}>
                            <DataTable.Cell>{item.name}</DataTable.Cell>
                            <DataTable.Cell>
                                <InputControl style={styles.inputContainer}>
                                    <TextInput
                                        defaultValue={item.merchantAccountNo}
                                        onChangeText={text =>
                                            changeMerchantAccountNo(
                                                text,
                                                item.merchantAccountNo
                                            )
                                        }
                                        style={styles.input}
                                    />
                                </InputControl>
                            </DataTable.Cell>
                        </DataTable.Row>
                    ))}
                </DataTable>
            )}
        </View>
    );
}

const styleFunc: StyleFunction = theme => {
    return {
        container: {
            width: "100%",
            maxWidth: theme.dimensions.maxFormWidth,
            alignSelf: "flex-start",
        },
        inputContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacingScale,
            marginTop: theme.spacingScale,
        },
        input: {
            ...theme.fonts.regular,
            borderRadius: 8,
            borderColor: theme.colors.secondary,
            borderWidth: theme.borderWidth,
            padding: theme.spacingScale,
            textAlign: "right",
        },
    };
};
