import { Menu } from "react-native-paper";
import React, { useMemo, useState } from "react";
import { Text } from "../text";
import { useTranslation } from "react-i18next";
import {
    View,
    StyleProp,
    ViewStyle,
    TouchableOpacity,
    StyleSheet,
} from "react-native";
import type { StyleFunction } from "../../theme";
import { useThemedStyle } from "../../theme";
import { IconButton } from "../icon-button";
import { Icon } from "../icon";
import {
    parseRelativePeriod,
    RelativePeriods,
    RelativePeriodStrings,
} from "lib";
import { InputLabel } from "../input-label";

const RelativePeriodValues = Object.values(RelativePeriods);

export function RelativePeriodPicker(props: {
    locale: string;
    value?: RelativePeriodStrings;
    label?: string;
    showDates?: boolean;
    onValueChange?: ({
        selectedPeriod,
        from,
        to,
    }: {
        selectedPeriod: RelativePeriodStrings;
        from: Date;
        to: Date;
    }) => void;
    style?: StyleProp<ViewStyle>;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [visible, setVisible] = useState<boolean>(false);
    const closeMenu = () => setVisible(false);
    const openMenu = () => setVisible(true);
    const { value = RelativePeriods.TODAY, showDates = true } = props;

    const visibleDates = useMemo(() => {
        if (!showDates) {
            return null;
        }

        let formattedFromDate,
            formattedToDate = "";
        const { fromDate, toDate } = parseRelativePeriod(value);
        const datetimeFormatter = new Intl.DateTimeFormat(
            props.locale || undefined
        );

        formattedFromDate = datetimeFormatter.format(fromDate);
        formattedToDate = datetimeFormatter.format(toDate);

        return (
            <>
                <Text style={styles.dateFrom}>({formattedFromDate}</Text>
                <Icon
                    name="arrow-right"
                    size="small"
                    color={styles.arrow.color}
                    style={styles.arrow}
                />
                <Text style={styles.dateTo}>{formattedToDate})</Text>
            </>
        );
    }, [
        props.locale,
        showDates,
        styles.arrow,
        styles.dateFrom,
        styles.dateTo,
        value,
    ]);

    const handleItemPress = (selectedPeriod: RelativePeriodStrings) => {
        closeMenu();
        if (!props.onValueChange) {
            return;
        }

        const { fromDate, toDate } = parseRelativePeriod(selectedPeriod);
        props.onValueChange({
            selectedPeriod: selectedPeriod,
            from: fromDate,
            to: toDate,
        });
    };

    return (
        <>
            {props.label && <InputLabel>{props.label}</InputLabel>}
            <TouchableOpacity
                style={[styles.container, props.style]}
                onPress={openMenu}
            >
                <View style={styles.textContainer}>
                    <Text style={styles.period}>
                        {t("date.relative." + value, "date.relative." + value)}
                    </Text>
                    {visibleDates}
                </View>
                <Menu
                    visible={visible}
                    onDismiss={closeMenu}
                    anchor={
                        <IconButton
                            onPress={openMenu}
                            name="calendar"
                            color={styles.menu.color}
                            size="small"
                        />
                    }
                >
                    {RelativePeriodValues.map(period => (
                        <Menu.Item
                            key={period}
                            onPress={() => handleItemPress(period)}
                            title={t(`date.relative.${period}`, period)}
                            style={
                                value === period ? styles.selectedValue : null
                            }
                        />
                    ))}
                </Menu>
            </TouchableOpacity>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    arrow: {
        color: theme.colors.textDark,
        marginHorizontal: theme.spacingScale / 2,
    },
    container: {
        paddingHorizontal: theme.spacingScale / 2,
        borderColor: theme.colors.secondary,
        borderWidth: StyleSheet.hairlineWidth,
        borderRadius: theme.borderRadiusSmall,
        flexDirection: "row",
        backgroundColor: theme.colors.white,
        minHeight: theme.spacingScale * 4,
        marginBottom: theme.spacingScale,
        alignItems: "center",
    },
    dateFrom: {
        fontSize: 16,
        lineHeight: 16,
        fontStyle: "italic",
    },
    dateTo: {
        fontSize: 16,
        lineHeight: 16,
        fontStyle: "italic",
    },
    menu: {
        color: theme.colors.textDark,
    },
    period: {
        ...theme.styles.input,
        marginRight: theme.spacingScale / 2,
    },
    selectedValue: {
        backgroundColor: theme.colors.grey100,
    },
    textContainer: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
    },
});
