import {
    LoadingScreen,
    PageTitle,
    Surface,
    Tabs,
    Text,
    useAuth,
    useThemedStyle,
} from "@venuepos/react-common";
import { useCashRegisterEditFormQuery } from "graphql-sdk";
import {
    CashRegisterConfig,
    cashRegisterConfigDefault,
    cashRegisterConfigSchema,
} from "lib";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import {
    EntityConfigForm,
    EntityConfigFormEntities,
} from "../../components/entity-config";
import { RootStackParamList, RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";
import { CashRegisterClaimForm } from "./cash-register-claim-form";
import { CashRegisterEditForm } from "./cash-register-edit-form";
import { DeviceInfo } from "./device-info";

import type { StyleFunction } from "@venuepos/react-common";
type ScreenProps = RootStackScreenProps<"CASH_REGISTER_EDIT">;

export function CashRegisterEditScreen({ route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.cash_register.write");

    const styles = useThemedStyle(styleFunc);
    const [name, setName] = useState<string>("");
    const cashRegisterId = route.params.id;
    const [t] = useTranslation();
    const { data } = useCashRegisterEditFormQuery({
        variables: { id: cashRegisterId },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (data && data.cashRegister) {
            setName(data.cashRegister.name);
        }
    }, [data]);

    if (!data || !data.cashRegister) {
        return <LoadingScreen style="light" />;
    }

    const tabs = [
        t("backoffice.common.configuration", "Configuration"),
        t("backoffice.common.cash_register", "Cash Register"),
    ];

    const connectedToDevice = data.cashRegister.device !== null;
    if (connectedToDevice) {
        tabs.push(t("backoffice.cash_register.device", "Device"));
    } else {
        tabs.push(t("backoffice.cash_register.claim", "Claim"));
    }

    // Entity configs
    const entityConfigs: EntityConfigFormEntities = [];
    entityConfigs.push({
        id: data.cashRegister.merchant.id,
        type: "merchant",
    });
    if (data.cashRegister.department) {
        entityConfigs.push({
            id: data.cashRegister.department.id,
            type: "department",
        });
    }
    entityConfigs.push({
        id: cashRegisterId,
        type: "cashRegister",
        referrer: "CASH_REGISTER_EDIT" as keyof RootStackParamList,
    });

    return (
        <AdminContainer>
            <PageTitle>{name}</PageTitle>
            <View style={styles.box}>
                <View style={styles.container}>
                    <Surface>
                        <Tabs labels={tabs}>
                            <EntityConfigForm<CashRegisterConfig>
                                entities={entityConfigs}
                                defaultValues={cashRegisterConfigDefault}
                                schema={cashRegisterConfigSchema}
                            />
                            <CashRegisterEditForm
                                nameChanged={setName}
                                data={data}
                            />
                            {connectedToDevice ? (
                                <DeviceInfo
                                    device={data.cashRegister.device!}
                                />
                            ) : (
                                <>
                                    <Text style={styles.claimInfo}>
                                        {t(
                                            "backoffice.cash_register_edit.claim_device_info",
                                            "This cash register has not yet been connected to a device. Before the cash register can be used, a device has to be connected. To claim a device for this cash register, click the following button."
                                        )}
                                    </Text>
                                    <CashRegisterClaimForm
                                        cashRegisterId={cashRegisterId}
                                    />
                                </>
                            )}
                        </Tabs>
                    </Surface>
                </View>
            </View>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => {
    return {
        box: {
            width: "100%",
            backgroundColor: theme.colors.white,
        },
        container: {
            width: "100%",
            maxWidth: theme.dimensions.maxFormWidth,
            alignSelf: "flex-start",
        },

        claimInfo: {
            marginBottom: theme.spacingScale * 2,
        },
    };
};
