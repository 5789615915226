import "react-native-gesture-handler";
import React from "react";
import { Providers, AdminNavigator } from "./screens";

import { setupReact } from "locales";
import { withProfiler } from "@venuepos/react-common";
setupReact();

export function App() {
    return (
        <Providers>
            <AdminNavigator />
        </Providers>
    );
}

export default withProfiler(App);
