import { ReportDefinition, ReportTypes } from "./types";

export const inventoryProductNotesDefinition: ReportDefinition = {
    translationKey: "report.inventory-product-notes.name",
    reportType: ReportTypes.INVENTORY_PRODUCT_NOTES,
    // This report is based on https://blog.locale.ai/how-to-merge-consecutive-rows-in-sql/
    primaryTable: `(
        SELECT	t2.*,
                -- If there is no note and there is an order_id, group the data (normal sales)
                SUM(
                CASE
                    WHEN note IS NULL AND order_id IS NOT NULL AND prev_2 IS NOT NULL THEN 0
                    ELSE 1
                END
                ) OVER (ORDER BY row_number) AS grp
        FROM (
            SELECT	t1.*,
                    -- Add the previous rows note on this row
                    LAG(note) OVER (ORDER BY row_number) AS prev_1,
                    LAG(order_id) OVER (ORDER BY row_number) AS prev_2
            FROM (
                -- Add row number to all rows
                SELECT	*,
                          ROW_NUMBER() OVER (ORDER BY inventory_product_id, transaction_at) AS row_number
                FROM	rm_inventory_product_transaction
            ) t1
        ) t2
    ) t3`,
    joins: {
        rm_product_to_inventory_product: {
            sql: "JOIN rm_product_to_inventory_product ON rm_product_to_inventory_product.inventory_product_id = t3.inventory_product_id",
        },
        product: {
            sql: "JOIN product ON product.id = rm_product_to_inventory_product.product_id",
            dependOn: ["rm_product_to_inventory_product"],
        },
        product_group: {
            sql: "JOIN product_group ON product_group.id = product.group_id",
            dependOn: ["product"],
        },
        user: {
            sql: 'LEFT JOIN	"user" ON "user".id = user_id',
        },
    },
    fields: {
        product_name: {
            translationKey: "report.columns.product_name",
            type: "SIMPLE",
            field: { table: "product", column: "name" },
            selectedByDefault: true,
            dependOnJoin: ["product"],
        },
        product_group: {
            translationKey: "report.columns.product_group_name",
            type: "SIMPLE",
            field: { table: "product_group", column: "name" },
            selectedByDefault: false,
            dependOnJoin: ["product_group"],
        },
        external_id: {
            translationKey: "report.columns.product_external_id",
            type: "SIMPLE",
            field: { table: "product", column: "external_id" },
            dependOnJoin: ["product"],
            selectedByDefault: false,
        },
        unit_price: {
            translationKey: "report.columns.unit_price",
            type: "CUSTOM",
            content: "MIN(ROUND(product.amount / 100::numeric, 2)::real)",
            selectedByDefault: true,
            dependOnJoin: ["product"],
            formatAs: "AMOUNT",
            noSummation: true,
        },
        cost_unit_price: {
            translationKey: "report.columns.cost_unit_price",
            type: "CUSTOM",
            content: "MIN(ROUND(product.amount / 100::numeric, 2)::real)",
            selectedByDefault: true,
            dependOnJoin: ["product"],
            formatAs: "AMOUNT",
            noSummation: true,
        },
        cost_amount: {
            translationKey: "report.columns.cost_amount",
            type: "CUSTOM",
            content:
                "SUM(ROUND(t3.quantity * product.cost_amount / 100::numeric, 2))::real",

            selectedByDefault: true,
            dependOnJoin: ["product"],
            formatAs: "AMOUNT",
            noSummation: true,
        },
        quantity: {
            translationKey: "report.columns.quantity",
            type: "CUSTOM",
            content: " COALESCE(SUM(quantity), 0)::integer",
            selectedByDefault: true,
            required: true,
            formatAs: "QUANTITY",
        },
        transaction_at: {
            translationKey: "report.columns.transaction_at",
            type: "CUSTOM",
            content:
                "MIN(CASE WHEN note IS NOT NULL OR order_id IS NULL THEN TO_CHAR(transaction_at::TIMESTAMPTZ AT TIME ZONE {{time_zone}}, 'YYYY-MM-DD HH24:MI:SS') ELSE NULL END)",
            selectedByDefault: true,
            required: true,
        },
        note: {
            translationKey: "report.columns.note",
            type: "CUSTOM",
            content:
                "MIN(CASE WHEN note IS NOT NULL THEN note WHEN note IS NULL AND order_id IS NULL THEN 'translation:no_note' ELSE NULL END)",
            selectedByDefault: true,
            required: true,
        },
        username: {
            translationKey: "report.columns.username",
            type: "CUSTOM",
            content:
                'MIN(CASE WHEN note IS NOT NULL OR order_id IS NULL THEN "user".username ELSE NULL END)',
            selectedByDefault: true,
            dependOnJoin: ["user"],
        },
        // The data in the column row_number is only for internal use (sorting), remove it from the data
        row_number: {
            translationKey: "row_number",
            type: "CUSTOM",
            content: "MIN(t3.row_number)",
            selectedByDefault: true,
            required: true,
            internalUse: true,
        },
    },
    filters: {
        merchant_id: {
            field: { table: "product", column: "merchant_id" },
            translationKey: "report.columns.merchant_id",
            type: "EQUALS",
            internalUse: true,
            dependOnJoin: ["product"],
        },
        report_date: {
            field: {
                table: "t3",
                column: "transaction_at",
            },
            translationKey: "report.columns.transaction_at",
            type: "BETWEEN",
        },
        product: {
            field: { table: "product", column: "id" },
            translationKey: "report.columns.product_name",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["product"],
        },
    },
    grouping: {
        "t3.inventory_product_id": {
            translationKey: "report.columns.inventory_product_id",
            type: "LABEL",
            internalUse: true,
        },
        "product.name": {
            translationKey: "report.columns.product_name",
            type: "LABEL",
            internalUse: true,
            dependOnJoin: ["product"],
        },
        "product.external_id": {
            translationKey: "report.columns.product_external_id",
            type: "LABEL",
            internalUse: true,
            dependOnJoin: ["product"],
        },
        "product_group.name": {
            translationKey: "report.columns.product_group_name",
            type: "LABEL",
            internalUse: true,
            dependOnJoin: ["product_group"],
        },
        grp: {
            translationKey: "report.columns.grp",
            type: "LABEL",
            internalUse: true,
        },
        note: {
            translationKey: "report.columns.note",
            type: "LABEL",
            internalUse: true,
        },
    },
    ordering: {
        transaction_at: {
            translationKey: "transaction_at",
            type: "CUSTOM",
            content: "row_number",
        },
    },
};
