import type { ReportDefinition, ReportRequest, ReportTypes } from "lib";
import { produce } from "immer";
import { availableReportDefinitions } from "./types";
import type { AvailableLocale } from "locales";
import type { TFunction } from "i18next";

export function updateReportDate(
    reportDefinitionFilters: ReportDefinition["filters"],
    reportQuery: ReportRequest<ReportDefinition>,
    from?: Date,
    to?: Date
) {
    // If there are no from/to dates at all, then back off - we are going to need at least one.
    if (!from && !to) {
        return reportQuery;
    }

    const dateFilters = Object.keys(reportDefinitionFilters).filter(
        filterName =>
            ["BETWEEN", "TO", "FROM"].includes(
                reportDefinitionFilters[filterName]?.type
            )
    );

    // If there are no date filters
    if (dateFilters.length === 0) {
        return reportQuery;
    }

    let updatedFrom: Date, updatedTo: Date;
    if (to) {
        updatedTo = new Date(
            to.getFullYear(),
            to.getMonth(),
            to.getDate(),
            to.getHours(),
            to.getMinutes(),
            to.getSeconds(),
            to.getMilliseconds()
        );
    }

    if (from) {
        updatedFrom = new Date(
            from.getFullYear(),
            from.getMonth(),
            from.getDate(),
            from.getHours(),
            from.getMinutes(),
            from.getSeconds(),
            from.getMilliseconds()
        );
    }

    // Update the report query with from/to fields:
    // * If there is a "BETWEEN" type, then set both the From and To values
    // * If there is a "TO" type, then set only To
    // * If there is a "FROM" type, then set only From
    return produce(reportQuery, draft => {
        if (dateFilters.length !== 0) {
            let valueObject = {};
            dateFilters.map(dateFilterName => {
                if (
                    reportDefinitionFilters[dateFilterName].type ===
                        "BETWEEN" &&
                    from &&
                    to
                ) {
                    valueObject = {
                        to: updatedTo.toISOString(),
                        from: updatedFrom.toISOString(),
                    };
                }
                if (
                    reportDefinitionFilters[dateFilterName].type === "TO" &&
                    to
                ) {
                    valueObject = {
                        to: updatedTo.toISOString(),
                    };
                }
                if (
                    reportDefinitionFilters[dateFilterName].type === "FROM" &&
                    from
                ) {
                    valueObject = {
                        from: updatedFrom.toISOString(),
                    };
                }
                draft.filters[dateFilterName] = { values: valueObject };
            });
        }
    });
}

export function resolveInitialFields(
    defaultSelectedFields: ReportDefinition["fields"]
) {
    const returnValue: Record<string, Record<string, undefined>> = {};

    Object.entries(defaultSelectedFields).forEach(([key, value]) => {
        if (!value.selectedByDefault) {
            return;
        }
        returnValue[key] = {};
    });

    return returnValue;
}

export function getInitialReportQuery(
    locale: AvailableLocale,
    timezone: string,
    reportType: keyof typeof ReportTypes
) {
    return {
        language: locale as AvailableLocale,
        reportType,
        timezone,
        fields: resolveInitialFields(
            availableReportDefinitions[reportType as keyof typeof ReportTypes]
                .fields
        ),
        filters: {},
        grouping: {},
        ordering: {},
    };
}

export function resolveFieldValues(
    definitionFields: ReportDefinition["fields"],
    t: TFunction
) {
    let allFieldOptions = [];
    let preselectedFields: string[] = [];
    let requiredFields: string[] = [];

    let fieldLabel: keyof ReportDefinition["fields"];
    for (fieldLabel in definitionFields) {
        const field = definitionFields[fieldLabel];

        if (field && field.type === "CUSTOM" && field.internalUse) {
            continue;
        }
        allFieldOptions.push({
            label: t(`${field.translationKey}`, field.translationKey),
            value: fieldLabel,
        });

        if (field.selectedByDefault) {
            preselectedFields.push(fieldLabel);
        }

        if (field.required) {
            requiredFields.push(fieldLabel);
        }
    }

    return {
        fields: allFieldOptions,
        selectedFields: preselectedFields,
        requiredFields,
    };
}
