import { useState, useRef, useEffect } from "react";

const DEFAULT_PAGE_SIZE = 25;

interface PaginationOptions {
    initialPage?: number; // Can be used by navigation, to set the initial page number
    initialPageSize?: number;
    initialSortBy?: PaginationOutput["sortBy"];
    initialSortDirection?: PaginationOutput["sortDirection"];
}
export type SortDirection = "ASC" | "DESC";

interface PaginationOutput {
    page: number;
    pageSize: number;
    sortDirection: SortDirection;
    sortBy: string | undefined;
    onPageChange: (pageNr: number) => void;
    onPageSizeChange: (size: number) => void;
    onSortChange: (field: string) => void;
}

// Handles pageNumbers, sort, sortDirection and handlers to make it easy to add DataTables to pages
export function usePagination(options?: PaginationOptions): PaginationOutput {
    const [page, setPage] = useState<number>(options?.initialPage || 0);
    const [pageSize, setPageSize] = useState(
        options?.initialPageSize || DEFAULT_PAGE_SIZE
    );
    const [sortBy, setSortBy] = useState<string | undefined>(
        options?.initialSortBy
    );
    const [sortDirection, setSortDirection] = useState<SortDirection>(
        options?.initialSortDirection || "ASC"
    );

    const sortByRef = useRef<string>();

    // We need to track sortBy's previous values to know if we're changing direction
    useEffect(() => {
        sortByRef.current = sortBy;
    }, [sortBy]);

    function onSortChange(fieldName: string) {
        if (fieldName === sortByRef.current) {
            // Only switch direction

            setSortDirection(prev => (prev === "ASC" ? "DESC" : "ASC"));
        } else {
            setSortBy(fieldName);
            setSortDirection("ASC");
        }
    }

    // When the page size changes, reset to first page
    const handlePageSizeChange = (newPageSize: number) => {
        if (newPageSize <= 0) {
            return;
        }

        setPageSize(newPageSize);
        setPage(0);
    };

    return {
        page,
        pageSize,
        sortDirection,
        sortBy,
        onSortChange, // Setting the same sortBy twice toggles order
        onPageChange: setPage,
        onPageSizeChange: handlePageSizeChange,
    };
}
