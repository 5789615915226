import React, { ReactElement } from "react";
import {
    ProvideThemeContext,
    ProvideToast,
    ToastContainer,
    ProvideGraphQL,
    MeType,
    ProvideAuth,
    AppErrorBoundary,
    ProvideModal,
} from "@venuepos/react-common";
import { LoginScreen } from "./login";
import { Platform } from "react-native";
import { ProvideAdminSession, useAdminSession } from "../session";
import { useTranslation } from "locales";
import { useEffect } from "react";
import { ChangePassword } from "./user/password/change-password";

function isSignedIn(me: MeType): boolean {
    if (!me.user || !me.role) {
        return false;
    }

    if (me.role.type === "MERCHANT" && !me.merchant) {
        return false;
    }

    return true;
}

function AdminHooks({ children }: { children: ReactElement }) {
    const [{ locale }] = useAdminSession(["locale"]);
    const [, options] = useTranslation();
    useEffect(() => {
        options.changeLanguage(locale);
    }, [locale, options]);
    return children;
}

export function Providers({ children }: { children: ReactElement }) {
    return (
        <ProvideThemeContext>
            <ProvideAdminSession>
                <ProvideGraphQL>
                    <AppErrorBoundary>
                        <ProvideAuth
                            isSignedIn={isSignedIn}
                            renderLoginScreen={() => <LoginScreen />}
                            onlineRequired={true}
                            renderRequiredPasswordScreen={() => (
                                <ChangePassword />
                            )}
                            checkForRequiredPassword={true}
                        >
                            <ProvideToast>
                                <ProvideModal>
                                    <AdminHooks>{children}</AdminHooks>
                                    <ToastContainer />
                                    {Platform.OS === "web" ? (
                                        <div id="root-portal" />
                                    ) : null}
                                </ProvideModal>
                            </ProvideToast>
                        </ProvideAuth>
                    </AppErrorBoundary>
                </ProvideGraphQL>
            </ProvideAdminSession>
        </ProvideThemeContext>
    );
}
