import { useFocusEffect } from "@react-navigation/native";
import {
    Headline,
    LoadingScreen,
    Surface,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQTagInput,
    useTagFormQuery,
    useTagLazyQuery,
    useTagSaveMutation,
} from "graphql-sdk";
import { schemaTag } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { Form } from "../form";
import { TagScreen } from "../tag-screen";

import type { StyleFunction } from "@venuepos/react-common";
import { Account } from "./account";
import { Customer } from "./customer";
type ScreenProps = RootStackScreenProps<"TAG_EDIT">;

export function TagEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.tag");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const tagId = route.params.id;

    const [tagEdit] = useTagSaveMutation();
    const [getTag, tag] = useTagLazyQuery({
        fetchPolicy: "no-cache",
    });

    const form = useForm<GQTagInput>(schemaTag, null);
    const [{ values }, { setDefaultValues }] = form;
    const { handleMutationError } = useHandleMutationError();
    const { data, refetch } = useTagFormQuery();

    useEffect(() => {
        if (!tagId) {
            navigate("TAGS");
            return;
        }

        getTag({ variables: { id: tagId } });
    }, [getTag, navigate, tagId]);

    useEffect(() => {
        if (tag.data && tag.data.tag) {
            const tagData = tag.data.tag;
            setDefaultValues({
                identifier: tagData.identifier,
                tagType: tagData.tagType,
                name: tagData.name || "",
                groupId: tagData.group?.id || "",
            });
        }
    }, [tag.data, setDefaultValues]);

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    const handleEdit = useCallback(async () => {
        if (!tag.data || !tag.data.tag || !values) {
            return;
        }

        await handleMutationError(
            async () =>
                await tagEdit({
                    variables: {
                        id: tag.data!.tag.id,
                        tag: {
                            identifier: values.identifier,
                            tagType: values.tagType,
                            name: values.name || null,
                            groupId: values.groupId || null,
                            accountId: tag.data?.tag.account?.id,
                            customerId: tag.data?.tag.customer?.id,
                        },
                    },
                }),
            t("backoffice.tag.saved", "Tag saved"),
            () => {
                navigate("TAGS");
            }
        );
    }, [handleMutationError, navigate, t, tag.data, tagEdit, values]);

    if (!tag.data || !tag.data.tag) {
        return <LoadingScreen style="light" />;
    }

    return (
        <TagScreen>
            <View style={styles.container}>
                <View style={styles.left}>
                    <Form
                        form={form}
                        formData={data}
                        onSubmit={handleEdit}
                        submitButton={["common.save", "Save"]}
                    />
                </View>
                <View style={styles.right}>
                    {(tag.data.tag.account || tag.data.tag.customer) && (
                        <Surface>
                            <Headline size="h5">Links</Headline>
                            {tag.data.tag.account && (
                                <Account account={tag.data.tag.account.id} />
                            )}
                            {tag.data.tag.customer && (
                                <Customer customer={tag.data.tag.customer.id} />
                            )}
                        </Surface>
                    )}
                </View>
            </View>
        </TagScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    left: {
        marginBottom: theme.spacingScale * 2,
        marginRight: theme.spacingScale * 3,
        minWidth: 400,
    },
    right: {
        minWidth: 250,
    },
});
