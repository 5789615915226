import { t } from "i18next";
import React from "react";
import { View } from "react-native";
import { StyleFunction, useThemedStyle } from "../../theme";
import { Text } from "../text";
import { Icon } from "../icon";

export function RequiredText() {
    const styles = useThemedStyle(styleFunc);
    return (
        <View>
            <Text style={styles.required_input_description}>
                <RequiredIndicator />
                {t(
                    "backoffice.required_input_description",
                    "marks that the field is required."
                )}
            </Text>
        </View>
    );
}

export function RequiredIndicator() {
    const styles = useThemedStyle(styleFunc);
    return <Icon style={styles.icon} size="tiny" name="asterisk" />;
}

const styleFunc: StyleFunction = theme => ({
    required_input_description: {
        ...theme.fonts.thin,
        fontSize: 12,
        marginTop: theme.spacingScale * 2,
        color: theme.colors.grey800,
    },
    icon: {
        color: theme.colors.error,
        marginRight: theme.spacingScale / 2,
    },
});
