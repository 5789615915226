import {
    Button,
    DataTable,
    Icon,
    Loading,
    SearchInput,
    Surface,
    useAuth,
    usePagination,
    useTheme,
} from "@venuepos/react-common";
import { useRolesQuery } from "graphql-sdk";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, StyleSheet, View } from "react-native";

import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";

type ScreenProps = RootStackScreenProps<"ROLES">;

export function RoleListScreen({ navigation: { navigate } }: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["merchant.role", "admin.role"], "OR");

    const theme = useTheme();
    const [t] = useTranslation();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination();
    const [search, setSearch] = useState<string>("");

    // GraphQL
    const { data, loading, error } = useRolesQuery({
        fetchPolicy: "no-cache",
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        },
    });

    // Actions
    const handleEdit = useCallback(
        (id: string) => {
            navigate("ROLE_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list (in case the user changed to a different page)
            onPageChange(0);
        },
        [onPageChange]
    );

    return (
        <AdminContainer>
            <View style={styles.container}>
                <View style={styles.buttons}>
                    <Button
                        onPress={() => {
                            navigate("ROLE_CREATE");
                        }}
                        style={styles.button}
                    >
                        {t("backoffice.role_list.create_role", "Create")}
                    </Button>
                </View>
            </View>
            <SearchInput onChange={handleSearchTextChange} />
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                            style={styles.nameColumn}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        {auth.may(["admin.role"]) && (
                            <DataTable.Title
                                sortDirection={
                                    sortBy === "merchantName" && sortDirection
                                }
                                onPress={() => onSortChange("merchantName")}
                            >
                                {t("backoffice.role_list.merchant", "Merchant")}
                            </DataTable.Title>
                        )}
                        <DataTable.Title style={styles.sortIcon}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {!error && !loading && data && data.roles.data ? (
                        <FlatList
                            data={data && data.roles.data}
                            renderItem={({ item }) => (
                                <DataTable.Row
                                    onPress={() => handleEdit(item.id)}
                                >
                                    <DataTable.Cell style={styles.nameColumn}>
                                        {item.name}
                                    </DataTable.Cell>
                                    {auth.may(["admin.role"]) && (
                                        <DataTable.Cell>
                                            {item.merchant?.name}
                                        </DataTable.Cell>
                                    )}
                                </DataTable.Row>
                            )}
                            keyExtractor={(_, index) => index.toString()}
                        />
                    ) : (
                        <Loading />
                    )}

                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.roles.pagination.pages}
                        itemCount={data?.roles.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        justifyContent: "space-between",
        marginBottom: 20,
        flexWrap: "wrap",
    },
    buttons: {
        alignSelf: "flex-end",
        alignItems: "flex-end",
        flexDirection: "row",
        flex: 0,
    },
    button: {
        marginLeft: 10,
    },
    sortIcon: {
        justifyContent: "flex-end",
    },
    nameColumn: {
        maxWidth: 300,
    },
});
