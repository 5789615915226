import type { ConditionMethods, ConditionSchema } from "./types";
import {
    DateTimeType,
    DateType,
    inRangeDate,
    inRangeDateTime,
    inRangeTime,
    inRangeWeek,
    inRangeWeekDay,
    TimeType,
    WeekDayType,
    WeekType,
} from "../date";
import type { ITag, ITagGroup } from "../tag";
import type { OrderContext } from "../order";
import type { ICustomer, ICustomerGroup } from "../customer";
import { IDepartment } from "../..";

export type OrderConditionContext = {
    items: Pick<OrderContext["items"][0], "productId" | "addedAt" | "itemId">[];
    returnedItems: Pick<
        OrderContext["returnedItems"][0],
        "productId" | "addedAt" | "itemId"
    >[];
    openedAt: DateTimeType;
    tags: (Pick<OrderContext["tags"][0], "id"> & {
        group?: Pick<
            Exclude<OrderContext["tags"][0]["group"], undefined>,
            "id"
        >;
    })[];
    customer?: Pick<Exclude<OrderContext["customer"], undefined>, "id"> & {
        group?: Pick<
            Exclude<
                Exclude<OrderContext["customer"], undefined>["group"],
                undefined
            >,
            "id"
        >;
    };
    departmentId?: OrderContext["departmentId"];
};

export type OrderConditions = ConditionSchema<{
    hasProductQuantity: {
        productId: string;
        requiredQuantity: number;
    };
    hasReturnQuantity: {
        productId: string;
        requiredQuantity: number;
    };
    hasGroupQuantity: {
        productIds: string[];
        requiredQuantity: number;
        ofSameId: boolean;
    };
    hasTag: {
        tagId: ITag["id"];
    };
    hasTagGroup: {
        tagGroupId: ITagGroup["id"];
    };
    hasCustomer: {
        customerId: ICustomer["id"];
    };
    hasCustomerGroup: {
        customerGroupId: ICustomerGroup["id"];
    };
    hasDepartment: { departmentId: IDepartment["id"] };
    dateTime: {
        from?: DateType & TimeType;
        to?: DateType & TimeType;
    };
    date: {
        from?: DateType;
        to?: DateType;
    };
    time: {
        from?: TimeType;
        to?: TimeType;
    };
    week: {
        from?: WeekType;
        to?: WeekType;
    };
    weekDay: {
        from?: WeekDayType;
        to?: WeekDayType;
    };
}>;

export type OrderConditionOutput = {
    productId?: string;
    productIds?: string[];
    productGroupId?: string;
    itemIds: number[];
    requiredQuantity: number;
    departmentId?: string;
};

export const orderConditions: ConditionMethods<
    OrderConditionContext,
    OrderConditions,
    OrderConditionOutput
> = {
    hasProductQuantity: (orderContext, { productId, requiredQuantity }) => {
        let itemIds: number[] = [];
        for (let i = 0; i < orderContext.items.length; i++) {
            if (orderContext.items[i].productId === productId) {
                itemIds.push(orderContext.items[i].itemId);
            }
        }

        if (itemIds.length >= requiredQuantity) {
            return { productId, requiredQuantity, itemIds };
        } else {
            return false;
        }
    },
    hasReturnQuantity: (orderContext, { productId, requiredQuantity }) => {
        let itemIds: number[] = [];
        for (let i = 0; i < orderContext.returnedItems.length; i++) {
            if (orderContext.returnedItems[i].productId === productId) {
                itemIds.push(orderContext.returnedItems[i].itemId);
            }
        }

        if (itemIds.length >= requiredQuantity) {
            return { productId, requiredQuantity, itemIds };
        } else {
            return false;
        }
    },
    hasGroupQuantity: (
        orderContext,
        { productIds, requiredQuantity, ofSameId }
    ) => {
        let itemIds: number[] = [];

        if (ofSameId) {
            const usableProductIds: string[] = [];
            for (let i = 0; i < productIds.length; i++) {
                const iIds = orderContext.items.filter(
                    itr => itr.productId === productIds[i]
                );
                if (iIds.length >= requiredQuantity) {
                    itemIds = itemIds.concat(iIds.map(itr => itr.itemId));
                    usableProductIds.push(productIds[i]);
                }
            }
            return { usableProductIds, requiredQuantity, ofSameId, itemIds };
        } else {
            for (let i = 0; i < orderContext.items.length; i++) {
                const pId = orderContext.items[i].productId;
                if (pId && productIds.includes(pId)) {
                    itemIds.push(orderContext.items[i].itemId);
                }
            }
            if (itemIds.length >= requiredQuantity) {
                return {
                    productIds,
                    requiredQuantity,
                    ofSameId,
                    itemIds,
                };
            }
        }

        return false;
    },
    hasTag: (orderContext, { tagId }) => {
        return orderContext.tags.findIndex(itr => itr.id === tagId) >= 0;
    },
    hasTagGroup: (orderContext, { tagGroupId }) => {
        return (
            orderContext.tags.findIndex(
                itr => itr.group?.id && itr.group?.id === tagGroupId
            ) >= 0
        );
    },
    hasCustomer: (orderContext, { customerId }) => {
        return (
            !!orderContext.customer && orderContext.customer.id === customerId
        );
    },
    hasCustomerGroup: (orderContext, { customerGroupId }) => {
        return (
            !!orderContext.customer &&
            !!orderContext.customer.group &&
            orderContext.customer.group.id === customerGroupId
        );
    },
    hasDepartment: (orderContext, { departmentId }) => {
        return orderContext.departmentId === departmentId;
    },
    dateTime: (orderContext, { from, to }) => {
        return inRangeDateTime(orderContext.openedAt, from, to);
    },
    date: (orderContext, { from, to }) => {
        return inRangeDate(orderContext.openedAt, from, to);
    },
    time: (orderContext, { from, to }) => {
        return inRangeTime(orderContext.openedAt, from, to);
    },
    week: (orderContext, { from, to }) => {
        return inRangeWeek(orderContext.openedAt, from, to);
    },
    weekDay: (orderContext, { from, to }) => {
        return inRangeWeekDay(orderContext.openedAt, from, to);
    },
};
