// Purpose: Provides app wide session for the admin app

import {
    AuthSessionData,
    authSessionDataDefault,
    PersistedKeys,
    ProvidePersistedSession,
} from "@venuepos/react-common";
import { useSession as createSession } from "@peteck/react-context-session";
import React, { ReactElement } from "react";

type AdminSessionData = AuthSessionData & {
    locale: string;
};

const adminDefaultData: AdminSessionData = {
    ...authSessionDataDefault,
    locale: getLang(),
};

const adminPersistKeys: PersistedKeys<AdminSessionData> = [
    "authToken",
    "locale",
];

export const useAdminSession = createSession<AdminSessionData>();

// @TODO: Move into apps/admin
export function ProvideAdminSession({ children }: { children: ReactElement }) {
    return (
        <ProvidePersistedSession
            persistKeys={adminPersistKeys}
            defaultData={adminDefaultData}
            sessionContext={"admin-session"}
        >
            {children}
        </ProvidePersistedSession>
    );
}

// @TODO: Make this cross-platform
function getLang() {
    if (!navigator) {
        return "da";
    }
    if (navigator.languages !== undefined) {
        return navigator.languages[0];
    }
    return navigator.language;
}
