import { ReportDefinition, ReportTypes } from "./types";

export const inventoryProductsDefinition: ReportDefinition = {
    translationKey: "report.inventory-products.name",
    reportType: ReportTypes.PRODUCT_INVENTORY,
    primaryTable: "rm_inventory_product_transaction",
    joins: {
        inventory_product: {
            sql: "JOIN rm_inventory_product ON rm_inventory_product.id = rm_inventory_product_transaction.inventory_product_id",
        },
        product_to_inventory_product: {
            sql: "JOIN rm_product_to_inventory_product ON rm_product_to_inventory_product.inventory_product_id = rm_inventory_product.id",
        },
        product: {
            sql: "JOIN product ON product.id = rm_product_to_inventory_product.product_id",
        },
        product_group: {
            sql: "JOIN product_group ON product_group.id = product.group_id",
            dependOn: ["product"],
        },
    },
    fields: {
        product_external_id: {
            translationKey: "report.columns.product_external_id",
            // NOTE: The relation between inventory products and products is NOT 1:1.
            // Therefore this calculation only works as long as our UI only supports 1:1 relations.
            type: "SIMPLE",
            field: { table: "product", column: "external_id" },
            dependOnJoin: [
                "inventory_product",
                "product_to_inventory_product",
                "product",
            ],
            selectedByDefault: true,
        },
        product_name: {
            translationKey: "report.columns.product_name",
            type: "CUSTOM",
            // NOTE: The relation between inventory products and products is NOT 1:1.
            // Therefore this calculation only works as long as our UI only supports 1:1 relations.
            content:
                "COALESCE(rm_inventory_product.name, product.name, 'translation:unknown_inventory_product')",
            selectedByDefault: true,
            dependOnJoin: [
                "inventory_product",
                "product_to_inventory_product",
                "product",
            ],
            required: true,
            formatAs: "SIMPLE",
        },
        product_group_name: {
            translationKey: "report.columns.product_group_name",
            type: "CUSTOM",
            // NOTE: The relation between inventory products and products is NOT 1:1.
            // Therefore this calculation only works as long as our UI only supports 1:1 relations.
            content:
                "COALESCE(product_group.name, 'translation:no_product_group')",
            selectedByDefault: true,
            dependOnJoin: [
                "inventory_product",
                "product_to_inventory_product",
                "product",
                "product_group",
            ],
        },
        report_date: {
            translationKey: "report.columns.report_date",
            type: "CUSTOM",
            content:
                "TO_CHAR(DATE_TRUNC('day', rm_inventory_product_transaction.transaction_at::TIMESTAMPTZ AT TIME ZONE {{time_zone}}), 'YYYY-MM-DD')",
            selectedByDefault: true,
            formatAs: "DATE",
        },
        quantity: {
            translationKey: "report.columns.quantity",
            type: "SUM_INT",
            field: {
                table: "rm_inventory_product_transaction",
                column: "quantity",
            },
            selectedByDefault: true,
            formatAs: "QUANTITY",
        },
        unit_price: {
            translationKey: "report.columns.unit_price",
            type: "CUSTOM",
            content: "ROUND(product.amount / 100::numeric, 2)::real",
            selectedByDefault: true,
            dependOnJoin: [
                "inventory_product",
                "product_to_inventory_product",
                "product",
            ],
            formatAs: "AMOUNT",
            noSummation: true,
        },
        cost_unit_price: {
            translationKey: "report.columns.cost_unit_price",
            type: "CUSTOM",
            content: "ROUND(product.cost_amount / 100::numeric, 2)::real",
            selectedByDefault: true,
            dependOnJoin: [
                "inventory_product",
                "product_to_inventory_product",
                "product",
            ],
            formatAs: "AMOUNT",
            noSummation: true,
        },
        cost_amount: {
            translationKey: "report.columns.cost_amount_total",
            type: "CUSTOM",
            // NOTE: The relation between inventory products and products is NOT 1:1.
            // Therefore this calculation only works as long as our UI only supports 1:1 relations.
            content:
                "SUM(ROUND(rm_inventory_product_transaction.quantity * product.cost_amount / 100::numeric, 2))::real",
            selectedByDefault: true,
            dependOnJoin: [
                "inventory_product",
                "product_to_inventory_product",
                "product",
            ],
            formatAs: "AMOUNT",
        },
    },
    filters: {
        merchant_id: {
            field: { table: "rm_inventory_product", column: "merchant_id" },
            translationKey: "report.columns.merchant_id",
            type: "EQUALS",
            internalUse: true,
            dependOnJoin: ["inventory_product"],
        },
        report_date: {
            field: {
                table: "rm_inventory_product_transaction",
                column: "transaction_at",
            },
            translationKey: "report.columns.transaction_at",
            type: "BETWEEN",
        },
        product_group: {
            field: { table: "product_group", column: "id" },
            translationKey: "report.columns.product_group_name",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["product_group"],
        },
        product: {
            field: { table: "product", column: "id" },
            translationKey: "report.columns.product_name",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["product"],
        },
    },
    grouping: {
        inventory_product: {
            type: "CUSTOM",
            translationKey: "report.columns.inventory_product",
            content: "rm_inventory_product.id",
            internalUse: true,
        },
        product_name: {
            type: "LABEL",
            translationKey: "report.columns.product_name",
            internalUse: true,
        },
        report_date: {
            translationKey: "report.columns.report_date",
            type: "LABEL",
        },
    },
    ordering: {
        product_external_id: {
            translationKey: "report.columns.product_external_id",
            type: "FIELD",
            field: "product_external_id",
        },
        report_date: {
            translationKey: "report.columns.report_date",
            type: "CUSTOM",
            content: "report_date",
        },
        product_name: {
            translationKey: "report.columns.product_name",
            type: "CUSTOM",
            content: "product_name",
        },
        product_group_name: {
            translationKey: "report.columns.product_group_name",
            type: "CUSTOM",
            content: "product_group_name",
        },
        quantity: {
            translationKey: "report.columns.quantity",
            type: "FIELD",
            field: "rm_inventory_product.quantity",
        },
        unit_price: {
            translationKey: "report.columns.unit_price",
            type: "CUSTOM",
            content: "unit_price",
        },
    },
};
