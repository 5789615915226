import {
    DataTable,
    IconButton,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import type { GQCardTransactionsQuery } from "graphql-sdk";
import { formatAmount, formatDateTime, SupportedCurrenciesType } from "lib";
import type { AvailableLocale } from "locales";
import { StyleProp, ViewStyle } from "react-native";
import React from "react";

export function CardTransactionRow({
    item,
    currency,
    locale,
    onSelect,
    style,
}: {
    item: GQCardTransactionsQuery["cardTransactions"]["data"][0];
    currency: SupportedCurrenciesType;
    locale: string;
    onSelect: () => void;
    style?: StyleProp<ViewStyle>;
}) {
    const styles = useThemedStyle(styleFunc);

    return (
        <DataTable.Row onPress={onSelect} style={style}>
            <DataTable.Cell>{item.cashRegister.name}</DataTable.Cell>
            <DataTable.Cell>{item.status}</DataTable.Cell>
            <DataTable.Cell numeric>
                {formatAmount(item.amount, currency, {
                    locale: locale as AvailableLocale,
                })}
            </DataTable.Cell>
            <DataTable.Cell numeric>
                {formatDateTime(item.transactionAt, locale as AvailableLocale)}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.iconColumn}>
                {item.receipt ? (
                    <IconButton
                        color={styles.iconColumn.color}
                        name="order"
                        onPress={onSelect}
                    />
                ) : null}
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: theme.spacingScale * 6,
        color: theme.colors.secondary,
    },
});
