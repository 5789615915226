import { produce } from "immer";
import React, { useCallback, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Chip, Divider, Menu } from "react-native-paper";
import { IconButton } from "..";
import { InputLabel, Text, useThemedStyle } from "../../";
import type { StyleFunction } from "../../theme";
import { pickerStyleFunc } from "./styles";
import { MultiPickerItem, MultiPickerProps, ValueItem } from "./types";

const uniqueItems = (
    value: number | string,
    index: number,
    self: (string | number)[]
) => self.indexOf(value) === index;

export function MultiPicker(props: MultiPickerProps) {
    const styles = useThemedStyle(styleFunc);
    const sharedStyles = useThemedStyle(pickerStyleFunc);
    let { selectedValues = [], fixedValues = [] } = props;
    const [visible, setVisible] = useState<boolean>(false);

    //
    // Values to use in the UI
    //
    const remainingItems: MultiPickerItem[] = props.values.filter(
        itr =>
            itr === null ||
            (!fixedValues.includes(itr.value) &&
                !selectedValues.includes(itr.value))
    );

    const validValues: ValueItem[] = props.values
        .filter((itr: MultiPickerItem) => itr !== null)
        .map(itr => itr as ValueItem);

    const remainingValues: ValueItem[] = validValues.filter(itr =>
        remainingItems.includes(itr)
    );

    //
    // Functions and callbacks
    //
    const openMenu = () => {
        if (props.disabled) {
            return;
        }
        setVisible(true);
    };
    const closeMenu = () => setVisible(false);

    const addToSelection = useCallback(
        (selectedValue: number | string) => {
            if (props.disabled || !props.onValueChange) {
                return;
            }

            closeMenu();

            const updatedValues = produce(selectedValues, draft => {
                draft.push(selectedValue);
            });

            props.onValueChange(
                validValues
                    // extract the value, ignore the label
                    .map(item => item.value)
                    // Find all selected or required values in the right order
                    .filter(value =>
                        [...fixedValues, ...updatedValues].includes(value)
                    )
                    // only return unique elements
                    .filter(uniqueItems)
            );
        },
        [fixedValues, props, selectedValues, validValues]
    );

    const removeSelectedValue = useCallback(
        valueToRemove => {
            if (props.disabled || !props.onValueChange) {
                return;
            }

            // remove the clicked value
            const filteredItems = selectedValues.filter(
                (itr: number | string) => itr !== valueToRemove
            );

            // only return unique elements
            props.onValueChange(
                validValues
                    // extract the value, ignore the label
                    .map(item => item.value)
                    // Find all selected or required values in the right order
                    .filter(value =>
                        [...fixedValues, ...filteredItems].includes(value)
                    )
                    // only return unique elements
                    .filter(uniqueItems)
            );
        },
        [fixedValues, props, selectedValues, validValues]
    );

    const removeAllSelectedValues = () => {
        if (props.disabled || (!props.onValueChange && !props.onClear)) {
            return;
        }

        if (props.onClear) {
            props.onClear();
            return;
        }

        if (props.onValueChange) {
            // only return the fixed values - if there are any
            props.onValueChange(
                validValues
                    // extract the value, ignore the label
                    .map(item => item.value)
                    // Find all selected or required values in the right order
                    .filter(value => [...fixedValues].includes(value))
                    // only return unique elements
                    .filter(uniqueItems)
            );
        }
    };

    return (
        <View>
            {props.label && <InputLabel>{props.label}</InputLabel>}
            <View
                style={[
                    styles.container,
                    props.disabled ? sharedStyles.disabled : null,
                    props.containerStyle,
                ]}
            >
                <TouchableOpacity
                    onPress={openMenu}
                    style={[
                        styles.chipContainer,
                        props.chipContainerStyle,
                        props.disabled ? styles.disabledContainer : null,
                    ]}
                    disabled={props.disabled}
                >
                    {/* If there are no selected (or fixed) values AND there is a placeholder text, then show it */}
                    {selectedValues.length === 0 &&
                        fixedValues.length === 0 &&
                        props.placeholder !== undefined &&
                        props.placeholder !== "" && (
                            <Text
                                key="placeholder"
                                style={styles.placeholderText}
                            >
                                {props.placeholder}
                            </Text>
                        )}
                    {selectedValues
                        .filter(value =>
                            validValues.find(
                                (itr: ValueItem) => itr.value === value
                            )
                        )
                        .map((value: number | string) => {
                            const item: ValueItem | undefined =
                                validValues.find(
                                    (itr: ValueItem) => itr.value === value
                                );

                            if (!item) {
                                return null;
                            }

                            const chipLabel =
                                !item || !item.label ? value : item.label;
                            return (
                                <Chip
                                    mode={
                                        fixedValues.includes(value)
                                            ? "flat"
                                            : "outlined"
                                    }
                                    key={value}
                                    disabled={props.disabled}
                                    onClose={
                                        fixedValues.includes(value)
                                            ? undefined
                                            : () => removeSelectedValue(value)
                                    }
                                    style={[styles.chip, props.chipStyle]}
                                    textStyle={[
                                        styles.chipText,
                                        props.chipTextStyle,
                                    ]}
                                >
                                    {chipLabel}
                                    {}
                                </Chip>
                            );
                        })}
                </TouchableOpacity>
                {selectedValues.length !== 0 && (
                    <IconButton
                        name="close"
                        disabled={props.disabled}
                        onPress={removeAllSelectedValues}
                        color={styles.menu.color}
                        style={{
                            backgroundColor:
                                styles.chipContainer.backgroundColor,
                        }}
                    />
                )}
                <View key="menuHolder" style={styles.menuHolder}>
                    <Menu
                        visible={visible}
                        onDismiss={closeMenu}
                        anchor={
                            <IconButton
                                onPress={openMenu}
                                disabled={
                                    props.disabled ||
                                    remainingValues.length === 0
                                }
                                name="dropdown"
                                color={styles.menu.color}
                                style={styles.menu}
                            />
                        }
                    >
                        {remainingItems.map(
                            (itr: MultiPickerItem, index: number) => {
                                if (itr === null) {
                                    return <Divider key={index} />;
                                }

                                const item: ValueItem | undefined =
                                    validValues.find(
                                        (validItem: ValueItem) =>
                                            validItem.value === itr.value
                                    );

                                const menuItemLabel =
                                    !item || !item.label
                                        ? itr.value
                                        : item.label;

                                return (
                                    <Menu.Item
                                        key={itr.value}
                                        title={menuItemLabel}
                                        onPress={() =>
                                            addToSelection(itr.value)
                                        }
                                    />
                                );
                            }
                        )}
                    </Menu>
                </View>
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    chip: {
        maxWidth: theme.spacingScale * 25,
        marginBottom: theme.spacingScale / 2,
        marginRight: theme.spacingScale,
    },
    chipText: {
        maxWidth: theme.spacingScale * 20, // Needs to be smaller than chip.maxWidth - and also leave place for ellipsis and close icon.
        marginTop: 0,
        marginBottom: 0,
    },
    chipContainer: {
        backgroundColor: theme.colors.white,
        paddingHorizontal: theme.spacingScale,
        paddingTop: theme.spacingScale / 2,
        paddingBottom: 0,
        flexDirection: "row",
        flexWrap: "wrap",
        flex: 1,
        borderBottomLeftRadius: theme.borderRadiusSmall,
        borderTopLeftRadius: theme.borderRadiusSmall,
    },
    container: {
        flexDirection: "row",
        flex: 1,
        borderColor: theme.colors.secondary,
        borderWidth: StyleSheet.hairlineWidth,
        borderRadius: theme.borderRadiusSmall,
        minHeight: theme.spacingScale * 4,
        alignItems: "center",
        marginBottom: theme.spacingScale,
    },
    menu: {
        color: theme.colors.textDark,
    },
    menuHolder: {
        alignItems: "flex-start",
    },
    placeholderText: {
        ...theme.styles.input,
        color: theme.colors.textDark,
        paddingBottom: theme.spacingScale / 2,
    },
    disabledContainer: {
        backgroundColor: "transparent",
    },
});
