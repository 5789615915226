import {
    LoadingScreen,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQCustomerInput,
    useCustomerLazyQuery,
    useCustomerSaveMutation,
} from "graphql-sdk";
import { schemaCustomer } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../navigation";
import { CustomerForm } from "../customer-form";
import { CustomerScreen } from "../customer-screen";

import type { StyleFunction } from "@venuepos/react-common";
import { CustomerTags } from "./customer-tags";
type ScreenProps = RootStackScreenProps<"CUSTOMER_EDIT">;

export function CustomerEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.customer");

    const [t] = useTranslation();
    const [customerEdit] = useCustomerSaveMutation();
    const [getCustomer, customer] = useCustomerLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<GQCustomerInput>(schemaCustomer, null);
    const [{ values }, { setDefaultValues }] = form;
    const styles = useThemedStyle(styleFunc);
    const { handleMutationError } = useHandleMutationError();
    const customerId = route.params.id;

    useEffect(() => {
        if (!customerId) {
            navigate("ACCOUNT_PAYMENTS");
            return;
        }
        getCustomer({
            variables: {
                id: customerId,
                tagPagination: {
                    sort: "createdAt",
                    sortDirection: "DESC",
                    pageSize: 99999,
                    page: 0,
                },
            },
        });
    }, [getCustomer, navigate, customerId]);

    useEffect(() => {
        if (customer.data && customer.data.customer) {
            const customerData = customer.data.customer;
            setDefaultValues({
                name: customerData.name,
                address: customerData.address,
                zip: customerData.zip,
                city: customerData.city,
                email: customerData.email || "",
                phoneNumber: customerData.phoneNumber || "",
                cvrNumber: customerData.cvrNumber || "",
                contactName: customerData.contactName || "",
                groupId: customerData.group?.id || "",
                comment: customerData.comment || "",
            });
        }
    }, [customer.data, setDefaultValues]);

    const edit = useCallback(async () => {
        if (!customer.data || !customer.data.customer || !values) {
            return;
        }

        await handleMutationError(
            async () =>
                await customerEdit({
                    variables: {
                        id: customer.data!.customer.id,
                        customer: {
                            name: values.name,
                            address: values.address,
                            zip: values.zip,
                            city: values.city,
                            phoneNumber: values.phoneNumber || null,
                            email: values.email || null,
                            cvrNumber: values.cvrNumber || null,
                            contactName: values.contactName || null,
                            comment: values.comment || null,
                            groupId: values.groupId || null,
                        },
                    },
                }),
            t("common.saved", "Saved"),
            () => {
                navigate("CUSTOMER_VIEW", {
                    id: customer.data!.customer.id,
                });
            }
        );
    }, [customer.data, customerEdit, handleMutationError, navigate, t, values]);

    if (!customer.data || !customer.data.customer) {
        return <LoadingScreen style="light" />;
    }

    return (
        <CustomerScreen>
            <View style={styles.container}>
                <View style={styles.left}>
                    <View style={styles.formBox} testID="customerAccountEdit">
                        <CustomerForm
                            id={customer.data.customer.id}
                            form={form}
                            onSubmit={edit}
                            submitButton={["common.save", "Save"]}
                            enabledTags={customer.data.customer.tags.data.map(
                                itr => itr.id
                            )}
                        />
                    </View>
                </View>
                <View style={styles.right}>
                    <CustomerTags customer={customer.data.customer.id} />
                </View>
            </View>
        </CustomerScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    left: {
        marginBottom: theme.spacingScale * 2,
        marginRight: theme.spacingScale * 3,
        minWidth: 400,
    },
    right: {
        minWidth: 250,
    },

    formContainer: {
        width: "100%",
        backgroundColor: theme.colors.white,
    },
    formBox: {
        maxWidth: theme.dimensions.maxFormWidth,
        backgroundColor: theme.colors.white,
    },
});
