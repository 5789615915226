import { useAuth, useForm } from "@venuepos/react-common";
import {
    GQDiscountCreateMutation,
    useDiscountCreateMutation,
} from "graphql-sdk";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { RootStackScreenProps } from "../../navigation";
import { DiscountForm } from "./discount-form";
import { DiscountScreen } from "./discount-screen";
import { defaultDiscount, DiscountFormInputs, schemaDiscount } from "./forms";
import { convertFromValues } from "./methods";

import type { FetchResult } from "@apollo/client";
import { useHandleMutationError } from "../../hooks";
type ScreenProps = RootStackScreenProps<"DISCOUNT_CREATE">;

export function DiscountCreateScreen({
    navigation: { navigate },
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.discount");

    const [t] = useTranslation();
    const [discountCreate] = useDiscountCreateMutation();
    const { handleMutationError } = useHandleMutationError();

    const form = useForm<DiscountFormInputs>(schemaDiscount, defaultDiscount);
    const [{ values }, { reset }] = form;

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        let discount: FetchResult<
            GQDiscountCreateMutation,
            Record<string, any>,
            Record<string, any>
        >;
        const [conditions, itemQuery, maxPerOrder] = convertFromValues(values);
        await handleMutationError(
            async () => {
                discount = await discountCreate({
                    variables: {
                        discount: {
                            name: values.name,
                            lineDescription: values.lineDescription,
                            type: values.type,
                            valueType: values.valueType,
                            value: values.value,
                            maxPerOrder,
                            itemQuery: JSON.stringify(itemQuery),
                            conditions: JSON.stringify(conditions),
                            active: values.active,
                        },
                    },
                });
            },
            t("backoffice.discount.created", "Discount created"),
            () => {
                reset();

                if (!discount.data) {
                    return;
                }

                navigate("DISCOUNT_EDIT", {
                    id: discount.data.discountCreate.id,
                });
            }
        );
    }, [values, handleMutationError, t, discountCreate, reset, navigate]);

    return (
        <DiscountScreen>
            <DiscountForm
                form={form}
                onSubmit={create}
                submitButton={["common.create", "Create"]}
            />
        </DiscountScreen>
    );
}
