import Encoder from "esc-pos-encoder";
import { rawForStrikethroughChars, rawForUserDefinedChars } from "./functions";
const iconv = require("iconv-lite");

type QRSizeType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export function encodeEscposReceipt(receipt: string) {
    let encoder = new Encoder();
    encoder.initialize();
    encoder.codepage("cp865");

    // Test if we have any strikethrough chars in the text.
    if (/\u0336/mu.test(receipt) || /(~~)([^~]+)(~~)/g.test(receipt)) {
        // Include user-defined chars for strikethrough
        encoder.raw(rawForStrikethroughChars());
    }

    const regexBarcode =
        /{{barcode:(?<value>[\w-]+),(?<type>\w+),(?<height>\d+),(?<width>\d+),(?<paddingBottom>\d+),(?<showNumber>true|false)}}/;

    const regexFeed = /\{\{feed:(\d+?)\}\}/;
    const regexDone = /\{\{done\}\}/;

    const regexBold = /(?:\*\*)(?<bold>[^*]+)(?:\*\*)/g;
    const regexStrikethrough = /(?:~~)(?<strikethrough>[^~]+)(?:~~)/g;

    // Center everything
    encoder.align("center");

    let hasCut = false;
    const lines = receipt.split(/\r?\n/);

    for (let i = 0; i < lines.length; i++) {
        let line = lines[i];
        // Note: {{center}} and {h3}} is not implemented for ESCPOS printer
        line = line.replace("{{center}}", "").replace("{{h3}}", "");

        if (regexDone.test(line)) {
            regexDone.lastIndex = 0;
            const matches = line.match(regexDone);

            if (matches && matches.length > 0) {
                // The printer seems to cut before the last lines, add a few newlines
                encoder.raw([0x1b, 0x64, 4]);
                encoder.cut("partial");
                hasCut = true;
            }
        } else if (regexBarcode.test(line)) {
            const matches = line.match(regexBarcode);

            if (matches && matches.length > 0) {
                encoder.align("center");

                const value = matches.groups!.value;
                const type = matches.groups!.type;
                const height = Number(matches.groups!.height);
                const showNumber = matches.groups!.showNumber;

                if (type === "CODE_128") {
                    if (value.length > 20) {
                        encoder.raw([0x1d, 0x77, 1]); // Reduce size if length > 20
                    }
                    encoder.raw([0x1d, 0x68, height]); // GS h (Set barcode height (50))

                    // Show barcode as human-readable below barcode
                    if (showNumber === "true") {
                        encoder.raw([0x1d, 0x48, 2]); // ESC H (below)
                    }

                    // Note that we only support Code128 for now
                    const bytes = iconv.encode(value, "ascii");
                    encoder.raw([
                        0x1d, // ESC
                        0x6b, // k
                        0x49, // Code128
                        bytes.length + 2, // Length of contents + the 2 bytes for selecting CODE B
                        0x7b, // Select CODE B
                        0x42, // Select CODE B
                        bytes,
                    ]);
                } else if (type === "QR_CODE") {
                    let size: QRSizeType = 1;
                    switch (true) {
                        case height < 75:
                            size = 1;
                            break;
                        case height < 100:
                            size = 2;
                            break;
                        case height < 125:
                            size = 3;
                            break;
                        case height < 150:
                            size = 4;
                            break;
                        case height < 200:
                            size = 5;
                            break;
                        case height < 250:
                            size = 6;
                            break;
                        case height < 300:
                            size = 7;
                            break;
                        default:
                            size = 8;
                            break;
                    }
                    encoder.qrcode(value, 2, size, "m");
                }
            }
        } else if (regexFeed.test(line)) {
            const matches = line.match(regexFeed);

            if (matches && matches.length > 0) {
                const feeds = Number(matches[1]);
                encoder.raw([0x1b, 0x64, feeds]); // ESC d n (Print and feed n lines)
            }
        } else if (line.includes(String.fromCharCode(parseInt("0E", 16)))) {
            // If we have the split char 0x0e we do a cut
            encoder.cut("partial");
        } else if (regexBold.test(line) || regexStrikethrough.test(line)) {
            const formattingIndex: {
                start: number;
                end: number;
                type: "bold" | "strikethrough";
            }[] = [];

            regexBold.lastIndex = 0;
            regexStrikethrough.lastIndex = 0;

            const boldMatches = line.matchAll(regexBold);
            for (const match of boldMatches) {
                formattingIndex.push({
                    start: match.index!,
                    end: match.index! + match[1].length + 4,
                    type: "bold",
                });
            }

            const strikethroughMatches = line.matchAll(regexStrikethrough);
            for (const match of strikethroughMatches) {
                formattingIndex.push({
                    start: match.index!,
                    end: match.index! + match[1].length + 4,
                    type: "strikethrough",
                });
            }
            const sortedFormattingIndex = formattingIndex.sort((a, b) =>
                a.start < b.start ? -1 : 1
            );

            if (formattingIndex.length > 0) {
                let lastPos = 0;
                for (let j = 0; j < sortedFormattingIndex.length; j++) {
                    const f = sortedFormattingIndex[j];
                    encoder.text(line.substring(lastPos, f.start));
                    if (f.type === "bold") {
                        encoder.bold(true);
                        // Not really ideal to add 4 spaces as replacement for the ** ** but probably the best for now
                        encoder.text(
                            "    " + line.substring(f.start + 2, f.end - 2)
                        );
                        encoder.bold(false);
                        lastPos = f.end;
                    }
                    // Note that we only have defined strikethough for [0-9,.-]
                    if (f.type === "strikethrough") {
                        encoder.raw(rawForUserDefinedChars(true));
                        // Not really ideal to add 4 spaces as replacement for the ~~ ~~ but probably the best for now
                        encoder.text(
                            "    " + line.substring(f.start + 2, f.end - 2)
                        );
                        encoder.raw(rawForUserDefinedChars(false));
                        lastPos = f.end;
                    }
                }
                encoder.line(line.substring(lastPos, line.length));
            }
        } else {
            encoder.line(line);
        }
    }

    if (!hasCut) {
        // The printer seems to cut before the last lines, add a few newlines
        encoder.raw([0x1b, 0x64, 4]);
        encoder.cut("partial");
    }
    return encoder.encode();
}
