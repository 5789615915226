import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ITheme, RequiredText, StyleFunction } from "@venuepos/react-common";
import {
    Button,
    Form,
    InputControl,
    Surface,
    TextInput,
    useThemedStyle,
} from "@venuepos/react-common";
import type { GQAccountInput } from "graphql-sdk";

export function AccountForm(props: {
    id: string | undefined;
    form: Form<GQAccountInput>;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;

    if (!values) {
        return null;
    }

    return (
        <View style={styles.formBox}>
            <Surface>
                <InputControl error={errors.name}>
                    <TextInput
                        label={t("common.name", "Name")}
                        placeholder={t(
                            "backoffice.common.enter_name",
                            "Enter name"
                        )}
                        defaultValue={values.name}
                        onChangeText={text => setValue("name", text)}
                        required={true}
                    />
                </InputControl>

                <Button onPress={handleSubmit(props.onSubmit)}>
                    {t(props.submitButton[0], props.submitButton[1])}
                </Button>
                <RequiredText />
            </Surface>
        </View>
    );
}

const styleFunc: StyleFunction = (theme: ITheme) => ({
    formBox: {
        maxWidth: theme.dimensions.maxFormWidth,
    },
});
