import { ReportDefinition, ReportTypes } from "./types";

export const paymentsDefinition: ReportDefinition = {
    translationKey: "report.payments.name",
    reportType: ReportTypes.PAYMENTS,
    primaryTable: "rm_invoice_payment",
    joins: {
        cash_register: {
            sql: "LEFT JOIN cash_register ON cash_register.id = rm_invoice.cash_register_id",
            dependOn: ["invoice"],
        },
        invoice: {
            sql: "JOIN rm_invoice ON rm_invoice.id = rm_invoice_payment.invoice_id",
        },
        department: {
            sql: "LEFT JOIN department ON rm_invoice.department_id = department.id",
            dependOn: ["invoice"],
        },
        user: {
            sql: 'JOIN "user" ON "user".id = rm_invoice.user_id',
            dependOn: ["invoice"],
        },
    },
    fields: {
        report_date: {
            translationKey: "report.columns.report_date",
            type: "CUSTOM",
            content:
                "TO_CHAR(rm_invoice.created_at::TIMESTAMPTZ AT TIME ZONE {{time_zone}}, 'YYYY-MM-DD HH24:MI:SS')",
            selectedByDefault: false,
            dependOnJoin: ["invoice"],
            formatAs: "DATETIME",
        },
        department_name: {
            translationKey: "report.columns.department_name",
            type: "SIMPLE",
            field: { table: "department", column: "name" },
            selectedByDefault: true,
            dependOnJoin: ["department"],
            default: "No department",
        },
        payment_methods: {
            translationKey: "report.columns.payment_methods",
            type: "REPLACEMENT_MARKER",
            marker: "##extra_fields##",
            selectedByDefault: true,
            formatAs: "AMOUNT",
        },
        transactions: {
            translationKey: "report.columns.transactions",
            type: "COUNT",
            field: { table: "rm_invoice_payment", column: "id" },
            selectedByDefault: false,
            formatAs: "QUANTITY",
        },
        total: {
            translationKey: "report.columns.total",
            type: "SUM_AMOUNT",
            field: { table: "rm_invoice_payment", column: "amount" },
            selectedByDefault: true,
            formatAs: "AMOUNT",
        },
        user_name: {
            translationKey: "report.columns.user_name",
            type: "SIMPLE",
            field: { table: "user", column: "username" },
            selectedByDefault: false,
            dependOnJoin: ["user"],
        },
    },
    filters: {
        cash_register: {
            field: { table: "cash_register", column: "id" },
            translationKey: "report.columns.cash_register",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["cash_register"],
        },
        department: {
            field: { table: "department", column: "id" },
            translationKey: "report.columns.department",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["department"],
        },
        merchant_id: {
            field: { table: "rm_invoice", column: "merchant_id" },
            translationKey: "report.columns.merchant_id",
            type: "EQUALS",
            internalUse: true,
            dependOnJoin: ["invoice"],
        },
        state: {
            translationKey: "report.columns.state",
            type: "CUSTOM",
            content: "rm_invoice_payment.state = 'SUCCESSFUL'",
            internalUse: true,
        },
        report_date: {
            field: {
                table: "rm_invoice",
                column: "created_at",
            },
            translationKey: "report.columns.created_at",
            type: "BETWEEN",
            dependOnJoin: ["invoice"],
        },
        user: {
            field: { table: "user", column: "id" },
            translationKey: "report.columns.user_name",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["user"],
        },
    },
    grouping: {
        department: {
            translationKey: "report.columns.department",
            type: "DEPARTMENT",
            dependOnJoin: ["department"],
        },
        year: {
            translationKey: "report.columns.year",
            type: "YEAR",
            field: { table: "rm_invoice", column: "created_at" },
        },
        month: {
            translationKey: "report.columns.month",
            type: "MONTH",
            field: { table: "rm_invoice", column: "created_at" },
        },
        day: {
            translationKey: "report.columns.day",
            type: "DAY",
            field: { table: "rm_invoice", column: "created_at" },
        },
        hour: {
            translationKey: "report.columns.hour",
            type: "HOUR",
            field: { table: "rm_invoice", column: "created_at" },
        },
    },
    ordering: {
        report_date: {
            translationKey: "report.columns.report_date",
            type: "CUSTOM",
            content: "report_date",
        },
    },
};
