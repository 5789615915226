import { useAuth, useForm } from "@venuepos/react-common";
import { useProductGroupCreateMutation } from "graphql-sdk";
import {
    defaultProductGroup,
    IProductGroupInput,
    schemaProductGroup,
} from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../navigation";
import { AdminContainer } from "../../../container";
import { ProductGroupForm } from "../product-group-form";

type ScreenProps = RootStackScreenProps<"PRODUCT_GROUP_CREATE">;

export function ProductGroupCreateScreen({
    navigation: { navigate },
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.product");

    const [t] = useTranslation();
    const [productGroupCreate] = useProductGroupCreateMutation();
    const { handleMutationError } = useHandleMutationError();

    const form = useForm<IProductGroupInput>(
        schemaProductGroup,
        defaultProductGroup
    );
    const [{ values }, { reset }] = form;

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await handleMutationError(
            async () =>
                await productGroupCreate({
                    variables: {
                        productGroup: {
                            externalId: values.externalId,
                            name: values.name,
                            vatId: values.vatId!,
                            color: values.color,
                            excludeFromDiscounts: values.excludeFromDiscounts,
                            printerId: values.printerId || null,
                            isExternal: values.isExternal,
                        },
                    },
                }),
            t("backoffice.product_group.created", "Product group created"),
            () => {
                reset();
                navigate("PRODUCT_GROUPS", { refetch: true });
            }
        );
    }, [handleMutationError, navigate, productGroupCreate, reset, t, values]);

    return (
        <AdminContainer>
            <ProductGroupForm
                form={form}
                onSubmit={create}
                submitButton={["common.create", "Create"]}
            />
        </AdminContainer>
    );
}
