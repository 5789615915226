import type { ITheme, ThemeFont } from "../provider";
import { DefaultTheme } from "@react-navigation/native";

const colors = {
    textDark: "#3b3b3b",
    textLight: "#eeeeee",

    // Brand colors
    primary: "#201c35",
    secondary: "#1A428A",
    tertiary: "#345F9C",

    // Grayscale variants. Dark to bright
    black: "#000000",
    grey50: "#f7f7f7",
    grey100: "#E8E8E8",
    grey250: "#CCC",
    grey500: "#909090",
    grey800: "#434343",
    lightGrey: "#C4C4C4",
    white: "#FFFFFF",

    // message warning levels: error -> success
    error: "#c00f4f",
    errorl1: "#de3163",
    warning: "#F47418",
    info: "#345F9C",
    success: "#76B72A",
    toastDefault: "#eeeeee",

    // UI details
    semiTransparentBlack: "rgba(0,0,0,0.3)",
    transparentLine: "rgba(255,255,255,0.4)",

    // utilities from react-native-paper
    background: "#f7f7f7",
    surface: "#FFFFFF",
    accent: "#FFFFFF",
    text: "#3b3b3b",
    onSurface: "#FFFFFF",
    onBackground: "#E8E8E8",
    disabled: "#CCC",
    placeholder: "#3b3b3b",
    backdrop: "#3b3b3b",
    notification: "#3b3b3b",
};

const breakpoint = {
    portraitWidth: 600,
    tabletWidth: 601,
    largeTabletWidth: 1280,
};

const dimensions = {
    maxFormWidth: 600,
};

const fonts: Record<keyof ITheme["fonts"], ThemeFont> = {
    regular: {
        fontFamily: "NunitoSans-Regular",
        fontWeight: "normal",
    },
    medium: {
        fontFamily: "Montserrat-Bold",
        fontWeight: "bold",
    },
    light: {
        fontFamily: "NunitoSans-Regular",
        fontWeight: "300",
    },
    thin: {
        fontFamily: "NunitoSans-Regular",
        fontWeight: "200",
    },
    monospace: {
        fontFamily: "monospace",
    },
};

export const theme: ITheme = {
    dark: false,
    animation: {
        scale: 1.5,
    },
    styles: {
        text: {
            fontSize: 18,
            fontFamily: "NunitoSans-Regular",
        },
        input: {
            fontSize: 16,
            fontFamily: "NunitoSans-Regular",
        },
        viewCenter: {
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
        },
        textcenter: {
            textAlign: "center",
        },
        shadow1: {
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,

            elevation: 5,
        },
        inputDescription: {
            color: colors.grey800,
            fontSize: 12,
            fontFamily: "NunitoSans-Regular",
        },
        link: {
            ...fonts.regular,
            textDecorationLine: "underline",
            color: colors.secondary,
            fontSize: 18,
        },
    },
    spacingScale: 10,
    borderWidth: 1,
    borderRadius: 15,
    borderRadiusTiny: 4,
    borderRadiusButton: 35,
    borderRadiusSmall: 8,
    borderRadiusLarge: 35,

    headlines: {
        h1: {
            fontFamily: "Montserrat-Bold",
            fontSize: 36,
            lineHeight: 48,
            marginBottom: 16,
        },
        h2: {
            fontFamily: "Montserrat-Bold",
            fontSize: 32,
            lineHeight: 40,
            marginBottom: 16,
        },
        h3: {
            fontFamily: "Montserrat-Bold",
            fontSize: 30,
            lineHeight: 40,
            marginBottom: 16,
        },
        h4: {
            fontFamily: "Montserrat-Bold",
            fontSize: 26,
            lineHeight: 36,
            marginBottom: 16,
        },
        h5: {
            fontFamily: "Montserrat-Bold",
            fontSize: 18,
            lineHeight: 32,
            marginBottom: 16,
        },
    },
    navigation: {
        ...DefaultTheme,
        dark: false,
        colors: {
            ...DefaultTheme.colors,
            notification: "#c00f4f",
        },
    },
    fonts,
    colors,
    breakpoint,
    dimensions,
    roundness: 8,
};
