import _ from "lodash";

export type GraphQLInfoState = {
    connected: boolean;
};

export type GraphQLStateListener = (stateInfo: GraphQLInfoState) => void;

let graphQLStateListenerNextId: number = 0;
let graphQLStateListeners: Record<number, GraphQLStateListener> = {};

const defaultStateInfo: GraphQLInfoState = {
    connected: true, // We'll assume that the client can connect with the GraphQL server until proven otherwise
};

export const GraphQLState = {
    addEventListener: (listener: GraphQLStateListener) => {
        const listenerId = Number(graphQLStateListenerNextId); // Use Number to clone the value instead of the reference
        graphQLStateListeners[graphQLStateListenerNextId] = listener;
        graphQLStateListenerNextId++;

        return () => {
            delete graphQLStateListeners[listenerId];
        };
    },
    dispatch(stateInfo: GraphQLInfoState) {
        // Don't dispatch if the new info is equal to the current
        if (_.isEqual(stateInfo, GraphQLState.currentState)) {
            return;
        }

        GraphQLState.currentState = stateInfo;

        for (const id in graphQLStateListeners) {
            if (graphQLStateListeners.hasOwnProperty(id)) {
                graphQLStateListeners[id](GraphQLState.currentState);
            }
        }
    },
    currentState: defaultStateInfo,
};
