// will convert a camelCase string
// myCamelCaseString = My Camel Case String
export function camelToLabel(key: string) {
    return key.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
    });
}

export function snakeToCamel(str: string) {
    return str.replace(/([-_][a-z])/g, (group: string) =>
        group.toUpperCase().replace("_", "")
    );
}

export function snakeToHuman(str: string) {
    const withSpaces = str.replace(/_/g, " ");
    return withSpaces.replace(/^\w/, c => c.toUpperCase());
}

export function camelToSnake(str: string) {
    return str
        .replace(/[\w]([A-Z])/g, function (m: string) {
            return m[0] + "_" + m[1];
        })
        .toLowerCase();
}

export function mapRowFromSnakecase<T>(row: any): T {
    const obj: any = {};
    for (const property in row) {
        obj[snakeToCamel(property)] = row[property];
    }
    return obj;
}

export function mapRowsFromSnakecase<T>(rows: any[]): T[] {
    const objs: any = [];

    if (rows.length === 0) {
        return objs;
    }

    // First by populating the output array with empty objects
    for (let i = 0; i < rows.length; i++) {
        objs.push({});
    }

    // To keep the number of "snakeToCamel" function call at a minimum, we will
    // use the first row as a template by mapping every row one property at a time
    for (const property in rows[0]) {
        const camel = snakeToCamel(property);
        for (let i = 0; i < rows.length; i++) {
            objs[i][camel] = rows[i][property];
        }
    }

    return objs;
}

export function mapToSnakeFromCamel<T>(row: any): T {
    const obj: any = {};
    for (const property in row) {
        obj[camelToSnake(property)] = row[property];
    }
    return obj;
}
