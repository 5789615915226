import {
    Headline,
    ReportTable,
    Spacer,
    Surface,
    useThemedStyle,
} from "@venuepos/react-common";
import { View } from "react-native";
import React from "react";
import { useTranslation } from "react-i18next";
import { reportStyleFunc } from "./styles";
import type { ReportTypes } from "lib";
import type { AvailableLocale } from "locales";

export function ReportPreview(props: {
    reportType: keyof typeof ReportTypes;
    locale: AvailableLocale;
    data: any;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(reportStyleFunc);
    return (
        <Surface>
            <View style={styles.row}>
                <Headline size="h4" style={styles.flex}>
                    {t(`report.types.${props.reportType}`, props.reportType)}
                </Headline>
            </View>
            <Spacer space={2} />
            <ReportTable report={props.data as any} locale={props.locale} />
        </Surface>
    );
}
