import React, { ReactNode, useMemo } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { useTranslation } from "react-i18next";
import { DataTable as PaperDataTable } from "react-native-paper";

const defaultPageSizes: number[] = [5, 10, 25, 50, 100];

function availablePageSizes(extraPageSize?: number): number[] {
    if (extraPageSize === undefined) {
        // just return the defaults
        return defaultPageSizes;
    }

    // add the extra item and filter out duplicates
    return Array.from(new Set([...defaultPageSizes, extraPageSize]));
}

export function DataTablePagination({
    label = "",
    style,
    onPageChange,
    numberOfPages,
    page,
    pageSize,
    onSizeChange,
    disablePageSizeSelector,
    itemCount,
}: {
    page: number;
    numberOfPages?: number;
    pageSize: number;
    onPageChange: (page: number) => void;
    onSizeChange?: (size: number) => void;
    style?: StyleProp<ViewStyle>;
    label?: ReactNode;
    disablePageSizeSelector?: boolean;
    itemCount?: number;
}) {
    const [t] = useTranslation();

    const from = page * pageSize;
    const to = Math.min(
        (page + 1) * pageSize,
        itemCount ?? Number.MAX_SAFE_INTEGER
    );
    const pageSizeItems = useMemo(
        () =>
            disablePageSizeSelector
                ? undefined
                : availablePageSizes(pageSize).sort(
                      (a: number, b: number) => a - b
                  ),
        [disablePageSizeSelector, pageSize]
    );

    let pagerLabel = label;
    if (!pagerLabel) {
        if (itemCount) {
            pagerLabel = t(
                "ui.pagination.label_with_count",
                "{{from}}-{{to}} of {{itemCount}}",
                { from: from + 1, to, itemCount: itemCount }
            );
        } else {
            pagerLabel = t("ui.pagination.label", "{{from}}-{{to}}", {
                from: itemCount === 0 ? from : from + 1,
                to,
            });
        }
    }

    return (
        <PaperDataTable.Pagination
            label={pagerLabel}
            page={page}
            numberOfPages={numberOfPages || 0}
            onPageChange={onPageChange}
            onItemsPerPageChange={onSizeChange}
            numberOfItemsPerPage={pageSize}
            numberOfItemsPerPageList={pageSizeItems}
            showFastPaginationControls
            style={style}
        />
    );
}
