import { AdminContainer } from "../container";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";

export function RoleScreen({
    children,
    maxWidth,
}: {
    children: React.ReactNode;
    maxWidth?: number;
}) {
    const styles = styleFunc(maxWidth);

    return (
        <AdminContainer>
            <View style={styles}>{children}</View>
        </AdminContainer>
    );
}

const styleFunc = (maxWidth?: number): StyleProp<ViewStyle> => {
    if (maxWidth) {
        return {
            width: "100%",
            maxWidth,
            alignSelf: "center",
        };
    } else {
        return {};
    }
};
