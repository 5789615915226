import { useAuth, useForm, useThemedStyle } from "@venuepos/react-common";
import { GQAccountCreateMutation, useAccountCreateMutation } from "graphql-sdk";
import { defaultAccount, schemaAccount, schemaGiftCardAccount } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { AdminContainer } from "../../..";
import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../navigation";
import { CreateForm } from "./create-form";

import type { FetchResult } from "@apollo/client";
import type { StyleFunction } from "@venuepos/react-common";
import type { GQAccountCreate } from "graphql-sdk";
type ScreenProps = RootStackScreenProps<"ACCOUNT_CREATE">;

export function AccountCreateScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.accounts");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const { handleMutationError } = useHandleMutationError();
    const [accountCreate] = useAccountCreateMutation();

    // The validation schema is used for both create and edit, but VAT can only be set during create...
    // so vatId is required in this screen. Compare to the Edit screen, where schemaAccount is not extended
    const type = route.params.type;
    const formSchema =
        type === "GIFT_CARD" ? schemaGiftCardAccount : schemaAccount;
    const form = useForm<GQAccountCreate>(formSchema, {
        ...defaultAccount,
        vatId: "",
    });
    const [{ values: formValues }, { reset: resetForm }] = form;

    const myCustomerId =
        route.params.referrer?.route &&
        route.params.referrer.route === "CUSTOMER_VIEW"
            ? route.params.referrer?.id
            : undefined;

    const create = useCallback(async () => {
        if (!formValues) {
            return;
        }
        let account: FetchResult<
            GQAccountCreateMutation,
            Record<string, any>,
            Record<string, any>
        >;
        await handleMutationError(
            async () => {
                account = await accountCreate({
                    variables: {
                        account: {
                            name: formValues.name,
                            type: type,
                            status: formValues.status,
                            amount: formValues.amount,
                            customerId: myCustomerId,
                            vatId: formValues.vatId,
                            externalId: formValues.externalId,
                        },
                    },
                });
            },
            t("common.saved", "Saved"),
            () => {
                resetForm();
                if (
                    route.params.referrer?.id &&
                    route.params.referrer?.route &&
                    route.params.referrer?.route === "CUSTOMER_VIEW"
                ) {
                    navigate("CUSTOMER_VIEW", {
                        id: route.params.referrer.id,
                    });
                    return;
                }

                if (!account.data) {
                    return;
                }

                navigate("ACCOUNT_EDIT", {
                    id: account.data.accountCreate.id,
                });
            }
        );
    }, [
        formValues,
        handleMutationError,
        t,
        accountCreate,
        type,
        myCustomerId,
        resetForm,
        route.params.referrer?.id,
        route.params.referrer?.route,
        navigate,
    ]);

    return (
        <AdminContainer testID="accountCreateScreen">
            <View style={styles.container}>
                <CreateForm
                    id={undefined}
                    form={form}
                    onSubmit={create}
                    submitButton={["common.create", "Create"]}
                    isGiftcard={type === "GIFT_CARD"}
                />
            </View>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        width: "100%",
        backgroundColor: theme.colors.white,
        alignSelf: "flex-start",
    },
});
