import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import type {
    GQProductLayoutFormQuery,
    GQTableLayoutFormQuery,
} from "graphql-sdk";
import {
    Form,
    InputControl,
    Picker,
    Button,
    Text,
    TextInput,
    NumberInput,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import type { ILayoutCreate } from "lib";
import { View } from "react-native";

export function LayoutCreateForm(props: {
    form: Form<ILayoutCreate>;
    data: GQTableLayoutFormQuery | GQProductLayoutFormQuery;
    onSubmit: () => void;
    submitButton: [string, string];
    layoutSizes: any[];
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const [columnCount, setColumnCount] = useState<number>(10);
    const [rowCount, setRowCount] = useState<number>(10);

    const handleColumnCountChange = useCallback(
        newValue => {
            if (typeof newValue !== "number") {
                setColumnCount(1);
            } else {
                setColumnCount(newValue);
            }
            setValue("dimensions", {
                columns: newValue!,
                rows: rowCount,
            });
        },
        [rowCount, setValue]
    );

    const handleRowCountChange = useCallback(
        newValue => {
            if (typeof newValue !== "number") {
                setRowCount(1);
            } else {
                setRowCount(newValue);
            }
            setValue("dimensions", {
                columns: columnCount,
                rows: newValue!,
            });
        },
        [columnCount, setValue]
    );

    const handleLayoutChange = useCallback(
        value => {
            if (value === "none") {
                setValue("dimensions", { columns: 0, rows: 0 });
                return;
            }

            const selectedLayoutSize = props.layoutSizes[Number(value)];
            setValue("dimensions", {
                columns: selectedLayoutSize.columns,
                rows: selectedLayoutSize.rows,
            });
            setColumnCount(selectedLayoutSize.columns);
            setRowCount(selectedLayoutSize.rows);
        },
        [props.layoutSizes, setValue]
    );

    if (!values || !props.data) {
        return null;
    }

    return (
        <>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name}
                    onChangeText={text => setValue("name", text)}
                    testID="layout:name"
                    required={true}
                />
            </InputControl>

            <InputControl error={errors.dimensions}>
                <Picker
                    label={t(
                        "backoffice.layout_form.size",
                        "Select a layout format"
                    )}
                    onValueChange={handleLayoutChange}
                    selectedValue="none"
                    testID="layout:dimensions"
                >
                    <Picker.Item
                        label={t(
                            "backoffice.layout_form.pick_format",
                            "Pick a format"
                        )}
                        value="none"
                    />
                    {props.layoutSizes.map((layout, index) => {
                        return (
                            <Picker.Item
                                label={layout.label}
                                value={String(index)}
                                key={`layout_${index}`}
                                testID={"layout:dimensions:" + layout.label}
                            />
                        );
                    })}
                </Picker>
            </InputControl>

            <InputControl error={errors.dimensions}>
                <View style={styles.rowContainer}>
                    <View style={styles.column}>
                        <NumberInput
                            value={columnCount}
                            label={t(
                                "backoffice.layout_form.columns",
                                "Columns"
                            )}
                            unit={t(
                                "backoffice.layout_form.columns",
                                "Columns"
                            )}
                            defaultValue={10}
                            decimals={0}
                            max={100}
                            min={1}
                            onChange={handleColumnCountChange}
                            testID="layout:columns"
                        />
                    </View>
                    <View style={styles.separatorColumn}>
                        <Text style={styles.separatorText}>x</Text>
                    </View>
                    <View style={styles.column}>
                        <NumberInput
                            value={rowCount}
                            label={t("backoffice.layout_form.rows", "Rows")}
                            unit={t("backoffice.layout_form.rows", "Rows")}
                            defaultValue={10}
                            decimals={0}
                            max={100}
                            min={1}
                            onChange={handleRowCountChange}
                            testID="layout:rows"
                        />
                    </View>
                </View>
            </InputControl>

            <Button onPress={handleSubmit(props.onSubmit)} testID="layout:save">
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContainer: {
        flexDirection: "row",
        flex: 1,
    },
    column: { flex: 1 },
    separatorColumn: {
        marginHorizontal: theme.spacingScale,
        alignSelf: "center",
    },
    separatorText: {
        paddingTop: theme.spacingScale * 1.5,
    },
});
