import { ReportDefinition, ReportTypes } from "./types";

export const bookkeepingDefinition: ReportDefinition = {
    translationKey: "report.bookkeeping.name",
    reportType: ReportTypes.BOOKKEEPING,
    primaryTable: "rm_account",
    joins: {
        account_bookkeeping_transaction: {
            sql: "LEFT JOIN rm_account_bookkeeping_transaction ON rm_account_bookkeeping_transaction.account_id = rm_account.id",
        },
    },
    fields: {
        account_name: {
            translationKey: "report.columns.account_name",
            type: "SIMPLE",
            field: { table: "rm_account", column: "name" },
            selectedByDefault: true,
        },
        report_date: {
            translationKey: "report.columns.report_date",
            type: "CUSTOM",
            content:
                "TO_CHAR(rm_account_bookkeeping_transaction.transaction_at::TIMESTAMPTZ AT TIME ZONE {{time_zone}}, 'YYYY-MM-DD HH24:MI:SS')",
            selectedByDefault: true,
            formatAs: "DATETIME",
        },
        transaction_type: {
            translationKey: "report.columns.transaction_type",
            type: "SIMPLE",
            field: {
                table: "rm_account_bookkeeping_transaction",
                column: "type",
            },
            selectedByDefault: true,
            dependOnJoin: ["account_bookkeeping_transaction"],
        },
        amount: {
            translationKey: "report.columns.amount",
            type: "AMOUNT",
            field: {
                table: "rm_account_bookkeeping_transaction",
                column: "amount",
            },
            selectedByDefault: true,
            dependOnJoin: ["account_bookkeeping_transaction"],
            formatAs: "AMOUNT",
        },
    },
    filters: {
        merchant_id: {
            field: { table: "rm_account", column: "merchant_id" },
            translationKey: "report.columns.merchant_id",
            type: "EQUALS",
            internalUse: true,
        },
        report_date: {
            field: {
                table: "rm_account_bookkeeping_transaction",
                column: "transaction_at",
            },
            translationKey: "report.columns.report_date",
            type: "BETWEEN",
        },
        type: {
            translationKey: "report.columns.type",
            type: "CUSTOM",
            content: "rm_account.type = 'BOOKKEEPING'",
            internalUse: true,
        },
        gdpr: {
            translationKey: "report.columns.gdpr",
            type: "CUSTOM",
            content:
                "rm_account_bookkeeping_transaction.transaction_at > NOW() - INTERVAL '5 YEARS'",
            dependOnJoin: ["account_transaction"],
            internalUse: true,
        },
    },
    grouping: {},
    ordering: {
        account_name: {
            translationKey: "report.columns.account_name",
            type: "FIELD",
            field: "rm_account.name",
        },
        transaction_at: {
            translationKey: "report.columns.transaction_at",
            type: "FIELD",
            field: "rm_account_bookkeeping_transaction.transaction_at",
        },
    },
};
