// Purpose: Our current version of React Native Web doesn't have useWindowDimensions hook
// - Upgrading is not possible before we are running RN 0.63 so this should help with adding dimensions to useThemedStyle
// Most of it is borrowed from RNW: https://github.com/necolas/react-native-web/commit/df1b62cd454565cc3d07662f84848b483c6f8744

import { Dimensions, ScaledSize } from "react-native";
import { useEffect, useState } from "react";

export function useDimensions() {
    const [dims, setDims] = useState(() => Dimensions.get("window"));

    useEffect(() => {
        function handleChange({ window }: { window: ScaledSize }) {
            setDims(window);
        }
        const subscription = Dimensions.addEventListener(
            "change",
            handleChange
        );

        // We might have missed an update between calling `get` in render and
        // `addEventListener` in this handler, so we set it here. If there was
        // no change, React will filter out this update as a no-op.
        setDims(Dimensions.get("window"));
        return () => {
            if (subscription) {
                subscription.remove();
            }
        };
    }, []);
    return dims;
}
