import React from "react";
import { useTranslation } from "react-i18next";
import { DataTable, Icon, useTheme } from "@venuepos/react-common";
import type { SortDirection } from "@venuepos/react-common";
import { StyleSheet } from "react-native";

export function TransactionTableHeader({
    onSortChange,
    sortBy,
    sortDirection,
}: {
    onSortChange: (field: string) => void;
    sortBy: string | undefined;
    sortDirection: SortDirection;
}) {
    const [t] = useTranslation();
    const theme = useTheme();

    return (
        <DataTable.Header>
            <DataTable.Title
                sortDirection={sortBy === "transactionAt" && sortDirection}
                onPress={() => onSortChange("transactionAt")}
            >
                {t("common.created_at", "Created at")}
            </DataTable.Title>
            <DataTable.Title
                sortDirection={sortBy === "username" && sortDirection}
                onPress={() => onSortChange("username")}
            >
                {t("common.user", "User")}
            </DataTable.Title>
            <DataTable.Title
                sortDirection={sortBy === "tagIdentifier" && sortDirection}
                onPress={() => onSortChange("tagIdentifier")}
            >
                {t("common.tag", "Tag")}
            </DataTable.Title>
            <DataTable.Title
                numeric
                sortDirection={sortBy === "amount" && sortDirection}
                onPress={() => onSortChange("amount")}
            >
                {t("common.amount", "Beløb")}
            </DataTable.Title>
            <DataTable.Title numeric style={styles.iconColumn}>
                <Icon name="sort" color={theme.colors.black} />
            </DataTable.Title>
        </DataTable.Header>
    );
}

const styles = StyleSheet.create({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
    },
});
