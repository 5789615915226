import { LayoutButton } from "lib";
import { ColorResult } from "react-color";
import { hex } from "wcag-contrast";

const hexRGBPattern = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;
const black = "#000";

/**
 * The given color value is measured against black ("#000") to check if the contrast ratio is above or below the threshold
 * @param color A string that can be recognized as a hex color code (e.g. "#c0ffee", or "#4b4b4b")
 * @param threshold The threshold for the contrast ratio. Default is 10.
 * @throws InvalidArgumentError When the input argument is not a valid hex string
 * @returns boolean
 */
export const contrastToBlack = (
    color: string,
    threshold: number = 10
): boolean => {
    if (!color || !isHexColor(color)) {
        throw new Error(
            "Invalid Argument: Color code is not a hex RGB color, e.g. #ac387b: " +
                color
        );
    }

    return hex(color, black) < threshold;
};

export const isHexColor = (color: ColorResult["hex"]): boolean =>
    hexRGBPattern.test(color);

/**
 * This function helps determine the color of the button on the layout (be it in Admin or the POS).
 *
 * If the first argument (`color`) is "valid", it is returned. Otherwise the second argument is returned (if it's valid).
 * As a final fallback, "#fff" (White as hex color) is returned if the second argument is unusable.
 *
 * @param color  A string that can be recognized as a hex color code (e.g. "#c0ffee", or "#4b4b4b")
 * @param fallbackColor  A string that can be recognized as a hex color code (e.g. "#c0ffee", or "#4b4b4b")
 * @returns One of `color`, `fallbackColor` or `#fff`.
 */
export const getButtonColor = (
    color: LayoutButton["color"],
    fallbackColor: LayoutButton["color"] | undefined
): ColorResult["hex"] => {
    if (color && color !== "" && isHexColor(color)) {
        return color;
    }

    if (fallbackColor && fallbackColor !== "" && isHexColor(fallbackColor)) {
        return fallbackColor;
    }

    return "#fff";
};
