import {
    createDrawerNavigator,
    DrawerContentComponentProps,
} from "@react-navigation/drawer";
import { useNavigationState } from "@react-navigation/native";
import {
    MenuBar,
    MenuData,
    PERMANENT_DRAWER_WIDTH,
    useDimensions,
} from "@venuepos/react-common";
import React, { useCallback } from "react";
import { RootDrawerParamList, RootStackParamList } from "../navigation";

import { StackScreen } from "../navigation/navigator-stack";
import { useAdminMenuItems } from "./menu";
import { routeConfig } from "./navigator-routes";

const Drawer = createDrawerNavigator<RootDrawerParamList>();

export function DrawerScreen<RouteKey extends keyof RootStackParamList>() {
    const dimensions = useDimensions();
    const extraItems = useAdminMenuItems();

    const currentRoute = useNavigationState(state => {
        if (!state) {
            return;
        }

        const drawerLevelIndex = state.index === undefined ? 0 : state.index;
        const drawerLevelState = state.routes[drawerLevelIndex].state;

        if (drawerLevelState === undefined) {
            return;
        }

        const stackLevelIndex =
            drawerLevelState.index === undefined ? 0 : drawerLevelState.index;
        const stackLevelRouteName =
            drawerLevelState.routes[stackLevelIndex].name;

        return stackLevelRouteName;
    });

    const drawerContent = useCallback(
        (props: DrawerContentComponentProps) => (
            <MenuData
                routeConfig={routeConfig}
                render={menuItems => (
                    <MenuBar
                        items={[...menuItems, ...extraItems]}
                        navigation={props.navigation}
                    />
                )}
                currentRoute={(currentRoute as RouteKey) ?? "DASHBOARD"}
            />
        ),
        [currentRoute, extraItems]
    );

    return (
        <Drawer.Navigator
            initialRouteName="admin"
            drawerContent={drawerContent}
            // Theme isn't ready, so we cant use it here
            screenOptions={{
                drawerType:
                    dimensions.width >= PERMANENT_DRAWER_WIDTH
                        ? "permanent"
                        : "front",
                headerShown: false,
            }}
        >
            <Drawer.Screen name="admin" component={StackScreen} />
        </Drawer.Navigator>
    );
}
