import React, { useCallback, useMemo, useRef, useState } from "react";
import {
    Button,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useErrorLogger,
    useForm,
    useThemedStyle,
    useToast,
} from "@venuepos/react-common";
import { AdminContainer } from "../container";
import { useTranslation } from "react-i18next";
import { defaultReportForm, schemaReportForm } from "./validation";
import {
    RelativePeriods,
    RelativePeriodStrings,
    ReportDefinition,
    ReportFormat,
    ReportRequest,
    ReportTypes,
} from "lib";
import { View } from "react-native";
import { useAccessToken } from "../../hooks/use-access-token";
import { useAdminSession } from "../../session";
import { reportStyleFunc } from "./styles";
import { useFetch } from "../../hooks/use-fetch";
import {
    getInitialReportQuery,
    resolveInitialFields,
    updateReportDate,
} from "./functions";
import { ReportPreview } from "./report-preview";
import { availableReportDefinitions } from "./types";
import type { AvailableLocale } from "locales";
import { BasicForm } from "./basic-form";
import { AdvancedForm } from "./advanced-form";
import { produce } from "immer";
import { useEffect } from "react";

const dateTimeOptions = Intl.DateTimeFormat().resolvedOptions();
const tz = dateTimeOptions.timeZone;
let use12hours: boolean = !!dateTimeOptions.hour12;

export function ReportScreen() {
    const auth = useAuth();
    auth.enforce("merchant.report");

    const [t] = useTranslation();
    const myStyles = useThemedStyle(styleFunc);
    const styles = useThemedStyle(reportStyleFunc);
    const toast = useToast();
    const logger = useErrorLogger();
    const [{ locale }] = useAdminSession(["locale"]);
    const accessToken = useAccessToken();

    const [{ values, errors: formErrors }, { handleSubmit, setValues }] =
        useForm(schemaReportForm, defaultReportForm);
    const formRef = useRef<HTMLFormElement>(null);
    const authorizedFetch = useFetch();
    const [preview, setPreview] = useState<unknown[] | undefined>();

    const [reportType, setReportType] = useState<keyof typeof ReportTypes>(
        defaultReportForm.type
    );

    // If the selected type was not found as an available report definition,
    // then use the default report definition for the form as a fallback.
    const selectedReportDefinition: ReportDefinition =
        availableReportDefinitions[reportType || defaultReportForm.type];

    const [reportQuery, setReportQuery] = useState<
        ReportRequest<ReportDefinition>
    >({
        language: locale as AvailableLocale,
        reportType: reportType,
        timezone: tz,
        fields: resolveInitialFields(selectedReportDefinition.fields),
        filters: {},
        grouping: {},
        ordering: {},
    });

    // If the admin session language is changed, since the user started the report screen, then change the language for the report data
    useEffect(() => {
        if (reportQuery.language !== locale) {
            setReportQuery(
                produce(
                    reportQuery,
                    (draft: ReportRequest<ReportDefinition>) => {
                        draft.language = locale as AvailableLocale;
                    }
                )
            );

            // If the language changed, then remove the report preview - it does not match the current selection anymore.
            setPreview(undefined);
        }
    }, [locale, reportQuery]);

    // This updated object is used in various places ...
    const reportQueryWithReportDate = JSON.stringify(
        updateReportDate(
            selectedReportDefinition.filters,
            reportQuery,
            values?.from,
            values?.to
        )
    );

    const handleReportTypeChange = useCallback(
        (value: keyof typeof ReportTypes) => {
            setReportType(value);

            // Reset the report query object
            setReportQuery(
                getInitialReportQuery(locale as AvailableLocale, tz, value)
            );
            setPreview(undefined);
        },
        [locale]
    );

    const handlePeriodChange = useCallback(
        ({
            selectedPeriod,
            from,
            to,
        }: {
            selectedPeriod: RelativePeriodStrings;
            from: Date;
            to: Date;
        }) => {
            setValues({
                from,
                to,
                period: selectedPeriod,
            });
            setPreview(undefined);
        },
        [setValues]
    );

    const handleFieldsChange = useCallback(
        fields => {
            setReportQuery(
                produce(
                    reportQuery,
                    (draft: ReportRequest<ReportDefinition>) => {
                        draft.fields = fields;
                    }
                )
            );
        },
        [reportQuery]
    );

    const handleFiltersChange = useCallback(
        filters => {
            setReportQuery(
                produce(
                    reportQuery,
                    (draft: ReportRequest<ReportDefinition>) => {
                        draft.filters = filters;
                    }
                )
            );
        },
        [reportQuery]
    );
    const handleGroupingChange = useCallback(
        grouping => {
            setReportQuery(
                produce(
                    reportQuery,
                    (draft: ReportRequest<ReportDefinition>) => {
                        draft.grouping = grouping;
                    }
                )
            );
        },
        [reportQuery]
    );
    const handleOrderingChange = useCallback(
        ordering => {
            setReportQuery(
                produce(
                    reportQuery,
                    (draft: ReportRequest<ReportDefinition>) => {
                        draft.ordering = ordering;
                    }
                )
            );
        },
        [reportQuery]
    );

    const handleDateChange = useCallback(
        (date: Date, dateField: string) => {
            let valueObj: (
                | {
                      period: RelativePeriods;
                      from?: Date;
                  }
                | {
                      period: RelativePeriods;
                      to?: Date;
                  }
            ) & { startOfDay?: number } = {
                period: RelativePeriods.USER_DEFINED,
            };

            switch (dateField) {
                case "from": {
                    valueObj = {
                        from: date,
                        period: RelativePeriods.USER_DEFINED,
                    };
                    break;
                }
                case "to": {
                    valueObj = {
                        to: date,
                        period: RelativePeriods.USER_DEFINED,
                    };
                    break;
                }
                default: {
                    // do nothing if false values are used.
                }
            }

            if (
                values &&
                values.from.getHours() === values.to.getHours() &&
                values.from.getMinutes() === 0 &&
                values.to.getMinutes() === 0
            ) {
                valueObj.startOfDay = values.from.getHours();
            } else if (
                values &&
                (values.from.getHours() !== values.to.getHours() ||
                    values.from.getMinutes() !== 0 ||
                    values.to.getMinutes() !== 0)
            ) {
                valueObj.startOfDay = -1;
            }
            setValues(valueObj);
        },
        [setValues, values]
    );

    const handleStartOfDayChange = useCallback(
        (value: string) => {
            if (value !== "USER_DEFINED") {
                const newFromDate = values?.from;
                newFromDate?.setHours(Number(value));
                newFromDate?.setMinutes(0);
                const newToDate = values?.to;
                newToDate?.setHours(Number(value));
                newToDate?.setMinutes(0);
                setValues({
                    from: newFromDate,
                    to: newToDate,
                    startOfDay: Number(value),
                });
            } else {
                setValues({ startOfDay: -1 });
            }
        },
        [setValues, values?.from, values?.to]
    );

    async function handleDownloadPress(format: keyof typeof ReportFormat) {
        if (!formRef.current) {
            // DOM Form not ready
            return;
        }

        formRef.current.querySelector("#format")?.setAttribute("value", format);

        formRef.current.submit();
    }

    const handleViewPress = useCallback(async () => {
        if (!values || !values.to || !values.from) {
            return;
        }

        try {
            const data = new URLSearchParams();
            data.append("type", reportType);
            data.append("request", reportQueryWithReportDate);
            data.append("format", ReportFormat.JSON);

            const initOptions = {
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded;charset=UTF-8",
                },
                method: "post",
                body: data,
            };

            const res = await authorizedFetch(
                `/api/report/${reportType.toLowerCase()}`,
                initOptions
            );

            const resBody = await res.text();
            try {
                const json = JSON.parse(resBody);
                setPreview(json);
            } catch (jsonErr) {
                throw new Error(resBody || "Unknown error");
            }
        } catch (error: any) {
            let errorText = t(
                "report.errors.unknown",
                "Failed to generate report"
            );

            if (error.message) {
                errorText += `: ${error.message}`;
            }

            toast.error(errorText);
            logger.captureError(error);
        }
    }, [
        values,
        reportType,
        reportQueryWithReportDate,
        authorizedFetch,
        t,
        toast,
        logger,
    ]);

    const reportPreview = useMemo(() => {
        if (!preview) {
            return null;
        }

        return (
            <>
                <Spacer space={2} />
                <ReportPreview
                    reportType={reportType}
                    data={preview}
                    locale={locale as AvailableLocale}
                />
            </>
        );
    }, [locale, preview, reportType]);

    if (!values) {
        return <Loading />;
    }

    return (
        <AdminContainer>
            <Surface>
                <BasicForm
                    reportType={reportType}
                    period={values.period}
                    onPeriodChange={handlePeriodChange}
                    onReportTypeChange={handleReportTypeChange}
                />
                <AdvancedForm
                    use12hours={use12hours}
                    reportDefinition={selectedReportDefinition}
                    reportQuery={reportQuery}
                    formValues={values}
                    formErrors={formErrors}
                    onFieldsChange={handleFieldsChange}
                    onFiltersChange={handleFiltersChange}
                    onOrderingChange={handleOrderingChange}
                    onGroupingChange={handleGroupingChange}
                    onDateChange={handleDateChange}
                    onStartOfDayChange={handleStartOfDayChange}
                />
                <View style={[styles.row, styles.rowReverse]}>
                    <Button
                        onPress={handleSubmit(handleViewPress)}
                        type="primary"
                        style={myStyles.downloadButton}
                    >
                        {t("common.view", "View")}
                    </Button>
                    <Button
                        onPress={handleSubmit(() =>
                            handleDownloadPress(ReportFormat.XLS)
                        )}
                        type="secondary"
                        iconName="arrow-down"
                        style={myStyles.downloadButton}
                        key="xls"
                    >
                        {t("common.excel", "Excel")}
                    </Button>
                    <Button
                        onPress={handleSubmit(() =>
                            handleDownloadPress(ReportFormat.PDF)
                        )}
                        type="secondary"
                        iconName="arrow-down"
                        key="pdf"
                        style={myStyles.downloadButton}
                    >
                        {t("common.pdf", "PDF")}
                    </Button>
                    <Button
                        onPress={handleSubmit(() =>
                            handleDownloadPress(ReportFormat.CSV)
                        )}
                        type="secondary"
                        iconName="arrow-down"
                        key="csv"
                        style={myStyles.downloadButton}
                    >
                        {t("common.csv", "CSV")}
                    </Button>
                </View>
            </Surface>
            <form
                target="_blank"
                ref={formRef}
                action={`/api/report/${reportType.toLowerCase()}`}
                method="post"
            >
                <input
                    type="hidden"
                    name="request"
                    id="request"
                    value={reportQueryWithReportDate}
                />
                <input
                    type="hidden"
                    name="format"
                    id="format"
                    value={ReportFormat.JSON}
                />
                <input
                    type="hidden"
                    name="token"
                    id="token"
                    value={accessToken}
                />
            </form>
            {reportPreview}
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    downloadButton: {
        marginLeft: theme.spacingScale,
    },
});
