import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    useCustomerFormQuery,
    GQCustomerInput,
    useCustomerAssignTagsMutation,
    GQTagsWithoutCustomerQuery,
    TagsWithoutCustomerDocument,
} from "graphql-sdk";
import {
    Form as FormComponent,
    TextInput,
    Picker,
    Button,
    useThemedStyle,
    SortDirection,
    RequiredText,
} from "@venuepos/react-common";
import type { StyleFunction, ITheme } from "@venuepos/react-common";

import { InputControl, Surface } from "@venuepos/react-common";
import { View } from "react-native";
import { useApolloClient } from "@apollo/client";
import {
    AssignTagsModalProps,
    useAssignTagsModal,
} from "../../tags/assign-tags-modal/assign-tags-modal";

export function CustomerForm(props: {
    id: string | undefined;
    form: FormComponent<GQCustomerInput>;
    onSubmit: () => void;
    submitButton: [string, string];
    enabledTags?: AssignTagsModalProps["enabledTags"];
}) {
    const [setCustomerAssignTags] = useCustomerAssignTagsMutation();
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const { data } = useCustomerFormQuery();
    const graphqlClient = useApolloClient();
    const assignTagsModal = useAssignTagsModal();
    const [enabledTags, setEnabledTags] = useState<
        AssignTagsModalProps["enabledTags"]
    >(props.enabledTags || []);

    const handleTagsFindTabQuery = useCallback(
        async (variables: {
            pagination: {
                page: number;
                pageSize: number;
                sort: string | undefined;
                sortDirection: SortDirection;
            };
            search: {
                query: string;
            };
        }) => {
            const tagsWithoutCustomer =
                await graphqlClient.query<GQTagsWithoutCustomerQuery>({
                    query: TagsWithoutCustomerDocument,
                    variables,
                    fetchPolicy: "no-cache",
                });

            if (!tagsWithoutCustomer.data) {
                throw new Error("Failed to fetch tags data");
            }

            return {
                data: tagsWithoutCustomer.data.tagsWithoutCustomer.data,
                loading: tagsWithoutCustomer.loading,
                pagination:
                    tagsWithoutCustomer.data.tagsWithoutCustomer.pagination,
            };
        },
        [graphqlClient]
    );

    const handleAssignTags = useCallback(async () => {
        const tags = await assignTagsModal({
            enabledTags,
            tagsFindQuery: handleTagsFindTabQuery,
        });
        if (tags === undefined) {
            // No update
            return;
        }
        await setCustomerAssignTags({
            variables: { id: props.id!, tagIds: tags || [] },
        });
        setEnabledTags(tags);
    }, [
        assignTagsModal,
        enabledTags,
        handleTagsFindTabQuery,
        props.id,
        setCustomerAssignTags,
    ]);

    if (!values || !data) {
        return null;
    }

    return (
        <Surface>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.customer_form.enter_name",
                        "Enter a customer name (i.e. company name, personal name)"
                    )}
                    defaultValue={values.name}
                    onChangeText={text => setValue("name", text)}
                    testID="name"
                    required={true}
                />
            </InputControl>

            <InputControl error={errors.groupId}>
                <Picker
                    label={t(
                        "backoffice.customer_form.customer_group",
                        "Customer Group"
                    )}
                    onValueChange={value => {
                        setValue("groupId", value);
                    }}
                    selectedValue={values.groupId || ""}
                    testID="group"
                >
                    <Picker.Item
                        label={t(
                            "backoffice.customer_form.choose_customer_group",
                            "Choose a customer group"
                        )}
                        value={""}
                    />
                    {data.customerGroups.data.map(customerGroup => {
                        if (!customerGroup) {
                            return null;
                        }
                        return (
                            <Picker.Item
                                key={customerGroup.id}
                                value={customerGroup.id}
                                label={customerGroup.name}
                                testID={"item:" + customerGroup.name}
                            />
                        );
                    })}
                </Picker>
            </InputControl>

            <InputControl error={errors.address}>
                <TextInput
                    label={t("backoffice.customer_form.address", "address")}
                    placeholder={t(
                        "backoffice.customer_form.enter_address",
                        "Enter address"
                    )}
                    defaultValue={values.address}
                    onChangeText={text => setValue("address", text)}
                    testID="address"
                    required={true}
                />
            </InputControl>

            <View style={styles.zipCityContainer}>
                <InputControl error={errors.zip} style={styles.zipInput}>
                    <TextInput
                        label={t("backoffice.customer_form.zip", "zip")}
                        placeholder={t(
                            "backoffice.customer_form.enter_zip",
                            "Enter zip"
                        )}
                        defaultValue={values.zip}
                        onChangeText={text => setValue("zip", text)}
                        testID="zip"
                        required={true}
                    />
                </InputControl>

                <InputControl error={errors.city} style={styles.cityContainer}>
                    <TextInput
                        label={t("backoffice.customer_form.city", "city")}
                        placeholder={t(
                            "backoffice.customer_form.enter_city",
                            "Enter city"
                        )}
                        defaultValue={values.city}
                        onChangeText={text => setValue("city", text)}
                        testID="city"
                        required={true}
                    />
                </InputControl>
            </View>

            <InputControl error={errors.email}>
                <TextInput
                    label={t("backoffice.customer_form.email", "email")}
                    placeholder={t(
                        "backoffice.customer_form.enter_email",
                        "Enter email"
                    )}
                    defaultValue={values.email || ""}
                    onChangeText={text => setValue("email", text)}
                    testID="email"
                    required={true}
                />
            </InputControl>

            <InputControl error={errors.phoneNumber}>
                <TextInput
                    label={t(
                        "backoffice.customer_form.phone_number",
                        "Phone no."
                    )}
                    placeholder={t(
                        "backoffice.customer_form.enter_phone_number",
                        "Enter a phone number"
                    )}
                    defaultValue={values.phoneNumber || ""}
                    onChangeText={text => setValue("phoneNumber", text)}
                    testID="phoneNumber"
                />
            </InputControl>

            <InputControl error={errors.cvrNumber}>
                <TextInput
                    label={t("backoffice.customer_form.cvr_number", "CVR no.")}
                    placeholder={t(
                        "backoffice.customer_form.enter_cvr_number",
                        "Enter a CVR number"
                    )}
                    defaultValue={values.cvrNumber || ""}
                    onChangeText={text => setValue("cvrNumber", text)}
                    testID="cvrNumber"
                />
            </InputControl>

            <InputControl error={errors.contactName}>
                <TextInput
                    label={t(
                        "backoffice.customer_form.contact_name",
                        "Contact name"
                    )}
                    placeholder={t(
                        "backoffice.customer_form.enter_contact_name",
                        "Enter a contact name (an Att.: name)"
                    )}
                    defaultValue={values.contactName || ""}
                    onChangeText={text => setValue("contactName", text)}
                    testID="contactName"
                />
            </InputControl>

            <InputControl error={errors.comment}>
                <TextInput
                    label={t("backoffice.customer_form.comment", "comment")}
                    placeholder={t(
                        "backoffice.customer_form.enter_comment",
                        "Enter comment"
                    )}
                    defaultValue={values.comment || ""}
                    onChangeText={text => setValue("comment", text)}
                    testID="comment"
                />
            </InputControl>

            {props.id !== undefined && (
                <Button
                    style={styles.assignTagsButton}
                    onPress={handleAssignTags}
                    testID="assignTags"
                >
                    {t("backoffice.customer.assign_tags.button", "Assign tags")}
                </Button>
            )}

            <Button onPress={handleSubmit(props.onSubmit)} testID="save">
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>

            <RequiredText />
        </Surface>
    );
}

const styleFunc: StyleFunction = (theme: ITheme) => ({
    zipCityContainer: {
        flexDirection: "row",
    },
    cityContainer: {
        flex: 1,
    },
    zipInput: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 80,
        marginRight: theme.spacingScale,
    },
    assignTagsButton: {
        marginBottom: 20,
    },
});
