import type { ReactElement } from "react";
import { IMenuItem } from "../menubar";
import { Route, useMenuFromRouteConfig } from "./routing";

// Translates navigation state to currentRoute and current parent
export function MenuData<RouteKey extends keyof ReactNavigation.RootParamList>({
    render,
    routeConfig,
    currentRoute,
}: {
    render: (menuItems: IMenuItem[]) => ReactElement;
    routeConfig: Record<string, Route>;
    currentRoute?: RouteKey;
}) {
    const items = useMenuFromRouteConfig(routeConfig, currentRoute as RouteKey);

    return render(items);
}
