import {
    Alert,
    Button,
    DataTable,
    Icon,
    IconButton,
    Loading,
    PageTitle,
    SearchInput,
    Surface,
    useAuth,
    usePagination,
    useTheme,
} from "@venuepos/react-common";
import { useDepartmentDeleteMutation, useDepartmentsQuery } from "graphql-sdk";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, StyleSheet, View } from "react-native";

import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";

type ScreenProps = RootStackScreenProps<"DEPARTMENTS">;

export function DepartmentListScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce(
        ["merchant.department.write", "merchant.department.delete"],
        "OR"
    );

    const [t] = useTranslation();
    const theme = useTheme();
    const [deleteDepartment] = useDepartmentDeleteMutation();
    const { handleMutationError } = useHandleMutationError();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination();
    const [search, setSearch] = useState<string>("");

    const { data, loading, error, refetch } = useDepartmentsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const handleDelete = useCallback(
        async (id: string) => {
            await handleMutationError(
                async () => await deleteDepartment({ variables: { id } }),
                t(
                    "backoffice.department.deleted.success",
                    "Department deleted"
                ),
                async () => {
                    await refetch();
                }
            );
        },
        [deleteDepartment, handleMutationError, refetch, t]
    );

    const handleEdit = useCallback(
        (id: string) => {
            navigate("DEPARTMENT_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list (in case the user changed to a different page)
            onPageChange(0);
        },
        [onPageChange]
    );

    if (error) {
        return (
            <Alert type="error">
                {t("common.error", "There was an error: {{errorText}}", {
                    errorText: error.message,
                })}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <View style={styles.container}>
                <PageTitle>
                    {t("backoffice.departments.headline", "Departments")}
                </PageTitle>
                {auth.may("merchant.department.write") && (
                    <Button
                        style={styles.button}
                        onPress={() => {
                            navigate("DEPARTMENT_CREATE");
                        }}
                    >
                        {t(
                            "backoffice.department_list.create_department",
                            "Create department"
                        )}
                    </Button>
                )}
            </View>
            <SearchInput onChange={handleSearchTextChange} />
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        {auth.may(
                            [
                                "merchant.department.write",
                                "merchant.department.delete",
                            ],
                            "OR"
                        ) && (
                            <DataTable.Title style={styles.sortIcon}>
                                <Icon name="sort" color={theme.colors.black} />
                            </DataTable.Title>
                        )}
                    </DataTable.Header>
                    {!loading && data ? (
                        <FlatList
                            data={data.departments.data}
                            renderItem={({ item }) => (
                                <DataTable.Row
                                    onPress={
                                        auth.may("merchant.department.write")
                                            ? () => handleEdit(item.id)
                                            : undefined
                                    }
                                >
                                    <DataTable.Cell>{item.name}</DataTable.Cell>
                                    <DataTable.Cell numeric>
                                        {auth.may(
                                            "merchant.department.write"
                                        ) && (
                                            <IconButton
                                                color={theme.colors.secondary}
                                                name="edit"
                                                onPress={() =>
                                                    handleEdit(item.id)
                                                }
                                            />
                                        )}
                                        {auth.may(
                                            "merchant.department.delete"
                                        ) && (
                                            <IconButton
                                                color={theme.colors.secondary}
                                                name="delete"
                                                onPress={() =>
                                                    handleDelete(item.id)
                                                }
                                            />
                                        )}
                                    </DataTable.Cell>
                                </DataTable.Row>
                            )}
                            keyExtractor={(_, index) => index.toString()}
                        />
                    ) : (
                        <Loading />
                    )}

                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.departments.pagination.pages}
                        itemCount={data?.departments.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        justifyContent: "space-between",
        marginBottom: 20,
        flexWrap: "wrap",
    },
    button: { width: 200, alignSelf: "flex-end" },
    sortIcon: {
        justifyContent: "flex-end",
    },
});
