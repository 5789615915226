export * from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const routingInstrumentation = null;

export function Tracing() {
    return new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", /venuepos\.net/],
    });
}
