import React from "react";
import {
    Headline,
    Loading,
    Text,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQDashboard } from "graphql-sdk";
import { formatAmount } from "lib";
import { AvailableLocale } from "locales";
import { useTranslation } from "react-i18next";
import { useAdminSession } from "../../../session";
import { dashboardStyleFunc } from "../styles";

export function InvoiceAverageTotal({ data }: { data?: GQDashboard }) {
    const merchantConfig = useMerchantConfig();
    const [t] = useTranslation();
    const sharedStyles = useThemedStyle(dashboardStyleFunc);
    const [{ locale }] = useAdminSession(["locale"]);

    return (
        <>
            <Headline style={sharedStyles.totalHeadline} size="h5">
                {`${t(
                    "dashboard.widgets.avg_transaction",
                    "Avg. transaction"
                )} *`}
            </Headline>
            {data ? (
                <Text style={sharedStyles.totalValue}>
                    {formatAmount(
                        data.totals.avgTransaction,
                        merchantConfig.currency,
                        {
                            locale: locale as AvailableLocale,
                            printCurrency: true,
                        }
                    )}
                </Text>
            ) : (
                <Loading message={t("common.loading", "Loading")} />
            )}
            <Text style={sharedStyles.noteText}>
                {`* ${t(
                    "dashboard.turnover_disclaimer",
                    "Turnover is excluding giftcard usage"
                )}`}
            </Text>
        </>
    );
}
