import React from "react";
import { useContext, createContext } from "react";
import type { ImageStyle, StyleProp, TextStyle, ViewStyle } from "react-native";
import { Provider as PaperProvider } from "react-native-paper";
import type { Theme as NavigationTheme } from "@react-navigation/native";

import { theme as defaultTheme } from "./themes/venue-default";

// Required to make React Native paper work with TypeScript
declare global {
    namespace ReactNativePaper {
        interface Theme extends ITheme {}
    }
}

// Eventually we want the actual theme data moved into their own files.

export interface ThemeFont {
    fontFamily: string;
    fontWeight?:
        | "normal"
        | "bold"
        | "100"
        | "200"
        | "300"
        | "400"
        | "500"
        | "600"
        | "700"
        | "800"
        | "900";
}

export interface ThemeColors {
    textDark: string;
    textLight: string;

    // brand colors
    primary: string;
    secondary: string;
    tertiary: string;

    // Grayscale variants. Dark to bright
    black: string;
    grey50: string;
    grey100: string;
    grey250: string;
    grey500: string;
    grey800: string;
    lightGrey: string;
    white: string;

    // message warning levels: error -> success
    error: string;
    errorl1: string;
    warning: string;
    info: string;
    success: string;
    toastDefault: string;

    // UI details
    semiTransparentBlack: string;
    transparentLine: string;

    // utilities from react-native-paper
    background: string;
    surface: string;
    accent: string;
    text: string;
    onSurface: string;
    onBackground: string;
    disabled: string;
    placeholder: string;
    backdrop: string;
    notification: string;
}

export interface ITheme {
    // react-native-paper
    dark: boolean;
    roundness: number;
    fonts: {
        regular: ThemeFont;
        medium: ThemeFont;
        light: ThemeFont;
        thin: ThemeFont;
        monospace: ThemeFont;
    };
    animation: {
        scale: number;
    };
    // itheme
    styles: { [key: string]: ViewStyle | TextStyle | ImageStyle };
    headlines: {
        h1: StyleProp<TextStyle>;
        h2: StyleProp<TextStyle>;
        h3: StyleProp<TextStyle>;
        h4: StyleProp<TextStyle>;
        h5: StyleProp<TextStyle>;
    };
    spacingScale: number;
    borderRadius: number;
    borderRadiusButton: number;
    borderRadiusTiny: number;
    borderRadiusSmall: number;
    borderRadiusLarge: number;
    borderWidth: number;
    colors: ThemeColors;
    navigation: NavigationTheme;
    breakpoint: {
        portraitWidth: number;
        tabletWidth: number;
        largeTabletWidth: number;
    };
    dimensions: {
        maxFormWidth: number;
    };
}

export const currentTheme: ITheme = defaultTheme;

export const ThemeContext = createContext<ITheme | undefined>(undefined);

export function useTheme(): ITheme {
    const ctx = useContext(ThemeContext);
    if (ctx === undefined) {
        throw new Error(
            "Theme Context is undefined. Remember to wrap your app in <ProvideThemeContext />"
        );
    }
    return ctx;
}

export function ProvideThemeContext({
    children,
}: {
    children: React.ReactNode;
}) {
    // When we make theme switching possible, then PaperProvider needs to get a consumer of our themeprovider
    // This is not necessary while we have only one theme
    return (
        <ThemeContext.Provider value={currentTheme}>
            <PaperProvider theme={currentTheme}>{children}</PaperProvider>
        </ThemeContext.Provider>
    );
}
