import React, { ReactNode, useRef, useState } from "react";
import {
    Animated,
    Platform,
    StyleProp,
    TextStyle,
    TouchableOpacity,
    View,
    ViewStyle,
} from "react-native";
import { Icon } from "../icon";
import { StyleFunction, useTheme, useThemedStyle } from "../../theme";
import { Text } from "../text";

export function Accordion(props: {
    title?: string | ReactNode;
    showContents?: boolean;
    iconName?: string;
    variant?: "normal" | "outline";
    caret?: boolean;
    style?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    triggerStyle?: StyleProp<ViewStyle>;
    children?: ReactNode;
}) {
    const { showContents: initialShowContents = true } = props;
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();
    const [showContents, setShowContents] =
        useState<boolean>(initialShowContents);

    let displayIcon;
    if (showContents) {
        displayIcon = "dropup";
    } else {
        displayIcon = "dropdown";
    }

    // Default to normal
    let containerStyle: StyleProp<ViewStyle> = styles.containerNormal;
    let openContainerStyle: StyleProp<ViewStyle> = styles.containerNormal;
    let triggerStyle: StyleProp<ViewStyle> = styles.triggerNormal;

    switch (props.variant) {
        case "outline":
            containerStyle = styles.containerOutlined;
            openContainerStyle = styles.openContainerOutlined;
            triggerStyle = styles.triggerOutlined;
            break;
    }

    if (props.triggerStyle) {
        triggerStyle = [triggerStyle, props.triggerStyle];
    }

    const fadeAnim = useRef(new Animated.Value(showContents ? 1 : 0)).current;

    const toggleExpand = () => {
        setShowContents(!showContents);
        Animated.timing(fadeAnim, {
            useNativeDriver: Platform.OS !== "web",
            toValue: showContents ? 0 : 1,
            duration: 500,
        }).start();
    };

    return (
        <View style={props.style}>
            <TouchableOpacity
                onPress={toggleExpand}
                style={triggerStyle}
                accessibilityState={{ expanded: showContents }}
            >
                <View style={styles.titleBox} accessibilityRole="header">
                    {typeof props.title === "string" ? (
                        <>
                            {props.iconName && (
                                <Icon
                                    name={props.iconName}
                                    color={theme.colors.grey500}
                                />
                            )}
                            <Text style={[styles.titleText, props.textStyle]}>
                                {props.title}
                            </Text>
                        </>
                    ) : (
                        props.title
                    )}
                    {props.caret !== false && (
                        <Icon
                            style={styles.caret}
                            name={displayIcon}
                            color={theme.colors.grey500}
                        />
                    )}
                </View>
            </TouchableOpacity>

            <Animated.View
                style={[
                    containerStyle,
                    showContents ? openContainerStyle : styles.closedContainer,
                    {
                        opacity: fadeAnim,
                    },
                ]}
            >
                {props.children}
            </Animated.View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    containerOutlined: {
        borderColor: theme.colors.grey500,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        padding: 10,
    },
    containerNormal: {
        paddingVertical: 10,
    },
    openContainerOutlined: {
        padding: 10,
    },
    openContainerNormal: {
        paddingVertical: 10,
    },
    closedContainer: {
        height: 0,
        overflow: "hidden",
        maxHeight: 0,
        padding: 0,
    },
    triggerOutlined: {
        backgroundColor: theme.colors.white,
        borderWidth: 1,
        borderColor: theme.colors.grey500,
        padding: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    triggerNormal: {
        backgroundColor: theme.colors.white,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingVertical: 10,
    },
    titleBox: {
        flexDirection: "row",
        flex: 1,
    },
    titleText: {
        flex: 1,
    },
    caret: {
        flex: 0,
    },
});
