import { useTheme, ITheme } from "./provider";
import type {
    ScaledSize,
    ViewStyle,
    ImageStyle,
    TextStyle,
    StyleSheet,
} from "react-native";
import { useMemo } from "react";
import { useDimensions } from "../hooks";

// Allows us to do performant, but themed styles with optional dimensions
// Usage: useThemedStyle(styleFunc)
// Note: Please put the styleFunc in the bottom of the component file, like you would with other styles.

type ThemedStyleProps<T> = {
    [P in keyof T]: ImageStyle & TextStyle & ViewStyle;
};

export type StyleData = StyleSheet.NamedStyles<any>;

export type StyleFunction = (
    theme: ITheme,
    dimensions: ScaledSize
) => StyleSheet.NamedStyles<any>;

export function useThemedStyle<T>(
    styleFunc: (theme: ITheme, dimensions: ScaledSize) => T
): ThemedStyleProps<T> {
    const theme = useTheme();
    const dimensions = useDimensions();
    return useMemo(() => {
        return styleFunc(theme, dimensions) as unknown as ThemedStyleProps<T>;
    }, [styleFunc, theme, dimensions]);
}
