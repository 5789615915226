import { useCallback, useEffect } from "react";
import { AuthRefreshDocument, GQAuthRefreshQuery } from "graphql-sdk";
import type { AuthToken } from "lib";
import { useAuthSession } from "./session";
import { useMeLazy } from "./use-me";
import { useApolloClient } from "@apollo/client";
import { useErrorLogger } from "../error-logger";
import { localizeAuthToken } from "./localize-auth-token";

// To avoid overlap in expires timestamp between the client and server
// we use an overlap offset, to let the client see the token as expired
// before it actually are. This will help prevent the awkward timing issue
// where the client sends a request thinking it's access token hasn't expired,
// but the server sees it as expired.
const EXPIRE_OVERLAP_OFFSET = 10 * 60; // 10 minutes

let authTokenCallback: () => Promise<AuthToken | undefined> | undefined;
export async function getAuthToken(): Promise<AuthToken | undefined> {
    if (authTokenCallback) {
        return authTokenCallback();
    }
    return undefined;
}

export function AuthTokenRefresher() {
    const [{ authToken }, setSessionValue] = useAuthSession(["authToken"]);
    const [{ set: setMe }] = useMeLazy();
    const gqlClient = useApolloClient();
    const errorLogger = useErrorLogger();

    const refreshToken = useCallback(async () => {
        try {
            const refreshResult = await gqlClient.query<GQAuthRefreshQuery>({
                query: AuthRefreshDocument,
                variables: {
                    refreshToken: authToken!.refresh,
                },
            });

            // Handle new token
            if (authToken && refreshResult.data) {
                if (!refreshResult.data.authRefresh) {
                    // If authRefreshQuery returned null, it means it could not refresh the token
                    // and therefore we should reset the token and then go to the authentication screen
                    errorLogger.captureMessage("Failed to refresh auth-token");
                    console.warn("Auth token refresh failed");
                    setSessionValue("authToken", undefined);
                    setMe(undefined);

                    // We will resolve without any token
                    return;
                } else {
                    const newToken = localizeAuthToken(
                        refreshResult.data.authRefresh
                    );
                    setSessionValue("authToken", newToken);
                    return newToken;
                }
            }
        } catch {
            return;
        }
    }, [authToken, gqlClient, setMe, setSessionValue, errorLogger]);

    const authTokenUseCallback = useCallback(async () => {
        // If no token is present, we resolve without auth token
        if (!authToken) {
            return;
        }

        // Check if the token has expired, if not we should just resolve with current auth token
        if (
            authToken.accessExpire - EXPIRE_OVERLAP_OFFSET >
            Math.floor(Date.now() / 1000)
        ) {
            return authToken;
        }

        // Token has expired, so we should refresh
        const refreshed = refreshToken();

        // If the token could not be refreshed, then we just keep going
        // with the current token. This is the best we can do.
        if (!refreshed) {
            return authToken;
        }

        return refreshed;
    }, [authToken, refreshToken]);

    useEffect(() => {
        authTokenCallback = authTokenUseCallback;
    }, [authTokenUseCallback]);

    return null;
}
