import { MeDocument } from "graphql-sdk";
import type { GQMeQuery } from "graphql-sdk";
import { useCallback } from "react";
import { useAuthSession } from "./session";
import { useApolloClient, ApolloQueryResult } from "@apollo/client";

type PropType<T, P extends keyof T> = T[P];
export type MeType = Exclude<PropType<GQMeQuery, "me">, undefined | null>;

export function useMe(): MeType {
    const [{ me }] = useAuthSession(["me"]);
    if (!me) {
        throw new Error("Don't use `useMe` before client has been signed in.");
    }

    return me;
}

export function useMeLazy(): [
    {
        load: () => Promise<void>;
        forget: () => void;
        set: (newMe: MeType | undefined) => void;
    },
    MeType | undefined
] {
    const [{ me }, setSessionValue] = useAuthSession(["me"]);
    const gqlClient = useApolloClient();

    const load = useCallback(
        () =>
            new Promise<void>((resolve, reject) => {
                try {
                    gqlClient
                        .query({
                            fetchPolicy: "no-cache",
                            query: MeDocument,
                        })
                        .then((data: ApolloQueryResult<GQMeQuery>) => {
                            if (data.data) {
                                setSessionValue("me", { ...data.data.me });
                                resolve();
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                } catch (err) {
                    reject(err);
                }
            }),
        [gqlClient, setSessionValue]
    );

    const forget = useCallback(() => {
        setSessionValue("me", undefined);
    }, [setSessionValue]);

    const set = useCallback(
        (newMe: MeType | undefined) => setSessionValue("me", newMe),
        [setSessionValue]
    );

    return [{ load, forget, set }, me];
}
