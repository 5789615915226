import { Link } from "@react-navigation/native";
import {
    Alert,
    DataTable,
    Headline,
    Loading,
    SearchInput,
    Spacer,
    StyleFunction,
    Surface,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import { useTagsByAccountLazyQuery } from "graphql-sdk";
import { IAccount } from "lib";
import { useTranslation } from "locales";
import React, { useCallback, useEffect, useState } from "react";

export function AccountTags({ account }: { account: IAccount["id"] }) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const { page, pageSize, sortBy, sortDirection, onPageChange } =
        usePagination({
            initialPageSize: 10,
            initialSortBy: "name",
            initialSortDirection: "ASC",
        });

    const [getTagsByAccount, { error, loading, data }] =
        useTagsByAccountLazyQuery();

    const [search, setSearch] = useState<string>("");
    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list
            onPageChange(0);
        },
        [onPageChange]
    );

    useEffect(() => {
        if (!account) {
            return;
        }

        getTagsByAccount({
            variables: {
                accountId: account,
                pagination: {
                    page,
                    pageSize,
                    sort: sortBy,
                    sortDirection: sortDirection,
                },
                search: {
                    query: search,
                },
            },
        });
    }, [
        getTagsByAccount,
        account,
        page,
        pageSize,
        search,
        sortBy,
        sortDirection,
    ]);

    return (
        <Surface>
            <Headline size="h5">
                {t("backoffice.tags.related", "Related tags")}
            </Headline>
            <SearchInput
                onChange={handleSearchTextChange}
                testID="tagSearch"
                onClear={() => setSearch("")}
            />
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title>
                        {t("common.name", "Name")}
                    </DataTable.Title>
                </DataTable.Header>
                {error ? (
                    <Alert type="error">
                        {t(
                            "backoffice.error.from_server",
                            "There was an error: {{errorText}}",
                            {
                                errorText: error.message,
                            }
                        )}
                    </Alert>
                ) : loading ? (
                    <>
                        <Spacer />
                        <Loading />
                        <Spacer />
                    </>
                ) : (
                    data?.tagsByAccount.data.map((item, index) => (
                        <DataTable.Row
                            style={index % 2 === 0 ? styles.oddRow : undefined}
                            key={item.id}
                        >
                            <DataTable.Cell>
                                <Link
                                    to={{
                                        screen: "TAG_EDIT",
                                        params: { id: item.id },
                                    }}
                                    style={styles.textLink}
                                >
                                    {item.name || item.identifier}
                                </Link>
                            </DataTable.Cell>
                        </DataTable.Row>
                    ))
                )}
                <DataTable.Pagination
                    onPageChange={onPageChange}
                    pageSize={pageSize}
                    page={page}
                    numberOfPages={data?.tagsByAccount.pagination.pages}
                    itemCount={data?.tagsByAccount.pagination.resultCount}
                    disablePageSizeSelector
                />
            </DataTable>
        </Surface>
    );
}

const styleFunc: StyleFunction = theme => ({
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
    textLink: {
        ...theme.styles.link,
    },
});
