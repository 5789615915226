import React from "react";
import { View } from "react-native";
import { StyleFunction, Text, useThemedStyle } from "@venuepos/react-common";
import type { GQCashRegisterFragment } from "graphql-sdk";
import { useTranslation } from "react-i18next";

export function DeviceInfo({
    device,
}: {
    device: Exclude<GQCashRegisterFragment["device"], undefined | null>;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    return (
        <>
            <View style={styles.row}>
                <Text style={styles.rowHeader}>
                    {t("backoffice.device_info.serial_number", "Serial number")}
                </Text>
                <Text>{device.serialNumber}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.rowHeader}>
                    {t("backoffice.device_info.os_name", "OS name")}
                </Text>
                <Text>{device.osName}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.rowHeader}>
                    {t("backoffice.device_info.os_version", "OS version")}
                </Text>
                <Text>{device.osVersion}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.rowHeader}>
                    {t("backoffice.device_info.app_version", "App version")}
                </Text>
                <Text>{device.appVersion}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.rowHeader}>
                    {t("backoffice.device_info.app_build", "App build")}
                </Text>
                <Text>{device.appBuild}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.rowHeader}>
                    {t(
                        "backoffice.device_info.hardware_model",
                        "Hardware model"
                    )}
                </Text>
                <Text>{device.hardwareModel}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.rowHeader}>
                    {t(
                        "backoffice.device_info.hardware_manufacturer",
                        "Hardware manufacturer"
                    )}
                </Text>
                <Text>{device.hardwareProducer}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.rowHeader}>
                    {t("backoffice.device_info.mac_address", "Mac address")}
                </Text>
                <Text>{device.macAddress}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.rowHeader}>
                    {t("backoffice.device_info.language", "Language")}
                </Text>
                <Text>{device.language}</Text>
            </View>
        </>
    );
}

const styleFunc: StyleFunction = () => {
    return {
        row: {
            width: "100%",
            flexDirection: "row",
        },
        rowHeader: {
            width: "40%",
            maxWidth: 250,
            fontWeight: "bold",
        },
    };
};
