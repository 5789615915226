import { ReportDefinition, ReportTypes } from "./types";

export const accountsDefinition: ReportDefinition = {
    translationKey: "report.account.name",
    reportType: ReportTypes.ACCOUNTS,
    primaryTable: "rm_account",
    joins: {
        account_transaction: {
            sql: "JOIN rm_account_transaction ON rm_account_transaction.account_id = rm_account.id",
        },
        user: {
            sql: 'LEFT JOIN "user" ON "user".id = rm_account_transaction.user_id',
            dependOn: ["account_transaction"],
        },
        customer: {
            sql: "LEFT JOIN customer on customer.id = rm_account.customer_id",
        },
        customer_group: {
            sql: "LEFT JOIN customer_group on customer_group.id = customer.group_id",
            dependOn: ["customer"],
        },
    },
    fields: {
        account_name: {
            translationKey: "report.columns.account_name",
            type: "SIMPLE",
            field: { table: "rm_account", column: "name" },
            selectedByDefault: true,
        },
        report_date: {
            translationKey: "report.columns.report_date",
            type: "CUSTOM",
            content:
                "TO_CHAR(rm_account_transaction.transaction_at::TIMESTAMPTZ AT TIME ZONE {{time_zone}}, 'YYYY-MM-DD HH24:MI:SS')",
            selectedByDefault: true,
            formatAs: "DATETIME",
        },
        customer: {
            translationKey: "report.columns.customer",
            type: "SIMPLE",
            field: { table: "customer", column: "name" },
            dependOnJoin: ["customer"],
            selectedByDefault: true,
        },
        customer_group: {
            translationKey: "report.columns.customer_group",
            type: "SIMPLE",
            field: { table: "customer_group", column: "name" },
            dependOnJoin: ["customer_group"],
            selectedByDefault: false,
        },
        user_name: {
            translationKey: "report.columns.username",
            type: "SIMPLE",
            field: { table: "user", column: "username" },
            selectedByDefault: true,
            dependOnJoin: ["user"],
        },
        amount: {
            translationKey: "report.columns.amount",
            type: "SUM_AMOUNT",
            field: {
                table: "rm_account_transaction",
                column: "amount",
            },
            selectedByDefault: true,
            dependOnJoin: ["account_transaction"],
            formatAs: "AMOUNT",
        },
    },
    filters: {
        merchant_id: {
            field: { table: "rm_account", column: "merchant_id" },
            translationKey: "report.columns.merchant_id",
            type: "EQUALS",
            internalUse: true,
        },
        report_date: {
            field: {
                table: "rm_account_transaction",
                column: "transaction_at",
            },
            translationKey: "report.columns.report_date",
            type: "BETWEEN",
        },
        type: {
            translationKey: "report.columns.type",
            type: "CUSTOM",
            content: "rm_account.type = 'CUSTOMER'",
            internalUse: true,
        },
        gdpr: {
            translationKey: "report.columns.gdpr",
            type: "CUSTOM",
            content:
                "rm_account_transaction.transaction_at > NOW() - INTERVAL '5 YEARS'",
            dependOnJoin: ["account_transaction"],
            internalUse: true,
        },
    },
    grouping: {
        year: {
            translationKey: "report.columns.year",
            type: "YEAR",
            field: {
                table: "rm_account_transaction",
                column: "transaction_at",
            },
        },
        month: {
            translationKey: "report.columns.month",
            type: "MONTH",
            field: {
                table: "rm_account_transaction",
                column: "transaction_at",
            },
        },
        day: {
            translationKey: "report.columns.day",
            type: "DAY",
            field: {
                table: "rm_account_transaction",
                column: "transaction_at",
            },
        },
    },
    ordering: {
        report_date: {
            translationKey: "report.columns.report_date",
            type: "CUSTOM",
            content: "report_date",
        },
        account_name: {
            translationKey: "report.columns.account_name",
            type: "FIELD",
            field: "rm_account.name",
        },
        customer: {
            translationKey: "report.columns.customer",
            type: "FIELD",
            field: "customer.name",
        },
        customer_group: {
            translationKey: "report.columns.customer_group",
            type: "FIELD",
            field: "customer_group.name",
        },
        amount: {
            translationKey: "report.columns.amount",
            type: "FIELD",
            field: "amount",
        },
    },
};
