import type { SessionGenericData } from "@peteck/react-context-session/dist/types";
import { ProvideSession } from "@peteck/react-context-session";
import React, { ReactElement, useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

export type PersistedKeys<SessionData extends SessionGenericData> =
    (keyof SessionData)[];

type ProvideSessionProps<SessionData extends SessionGenericData> = {
    children: ReactElement;
    persistKeys: PersistedKeys<SessionData>;
    defaultData: SessionData;
    sessionContext: string;
};

export function ProvidePersistedSession<
    SessionData extends SessionGenericData
>({
    children,
    persistKeys,
    defaultData,
    sessionContext,
}: ProvideSessionProps<SessionData>) {
    const [data, setData] = useState<SessionData>();

    useEffect(() => {
        getPersistedSessionData<SessionData>(sessionContext, defaultData).then(
            persistedData => {
                setData(persistedData);
            }
        );
    }, [defaultData, sessionContext]);

    if (data) {
        return (
            <ProvideSession<SessionData>
                data={data}
                name="pos"
                keepOnUnmount={true}
                onChange={async changedData => {
                    await persistSessionData<SessionData>(
                        sessionContext,
                        changedData,
                        persistKeys
                    );
                }}
            >
                {children}
            </ProvideSession>
        );
    } else {
        return null;
    }
}

async function persistSessionData<SessionData extends SessionGenericData>(
    contextName: string,
    sessionData: SessionData,
    persistKeys: PersistedKeys<SessionData>
) {
    let data: SessionData = {} as SessionData;

    for (let i = 0; i < persistKeys.length; i++) {
        data[persistKeys[i]] = sessionData[persistKeys[i]];
    }

    await AsyncStorage.setItem(contextName, JSON.stringify(data));
}

async function getPersistedSessionData<SessionData extends SessionGenericData>(
    contextName: string,
    defaultValues: SessionData
): Promise<SessionData> {
    const serialized = await AsyncStorage.getItem(contextName);
    if (!serialized) {
        return defaultValues;
    }

    try {
        return { ...defaultValues, ...JSON.parse(serialized) };
    } catch (err) {
        return defaultValues;
    }
}
