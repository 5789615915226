import { initErrorLogger } from "@venuepos/react-common";

import { AppRegistry } from "react-native";
import appInfo from "../app.json";
import App from "./app";
import "../public/index.css";

initErrorLogger(appInfo.sentryDSN);
AppRegistry.registerComponent(appInfo.name, () => App);
AppRegistry.runApplication(appInfo.name, {
    rootTag: document.getElementById("root"),
});
