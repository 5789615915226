import React, { ReactNode } from "react";
import {
    StyleProp,
    ViewStyle,
    TouchableOpacity,
    TextStyle,
} from "react-native";
import { StyleFunction, useThemedStyle } from "../../theme";
import { Text } from "../text";

export function DataGridCell({
    children = "",
    numeric = false,
    style,
    textStyle,
    onPress,
    testID,
}: {
    children?: string | ReactNode;
    numeric?: boolean;
    onPress?: () => void;
    style?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);
    const child =
        typeof children === "string" || typeof children === "number" ? (
            <Text numberOfLines={1} style={textStyle}>
                {children}
            </Text>
        ) : (
            children
        );

    return (
        <TouchableOpacity
            disabled={!onPress}
            onPress={onPress}
            style={[styles.container, numeric && styles.right, style]}
            testID={testID}
        >
            {child}
        </TouchableOpacity>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        paddingRight: theme.spacingScale,
    },
    right: { justifyContent: "flex-end" },
});
