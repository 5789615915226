import React, { useState } from "react";
import { SketchPicker } from "react-color";

export function ColorPicker({
    color: initialColor = "#58c",
    onChange,
}: {
    color?: string;
    onChange?: (value: string) => void;
}) {
    const [color, setColor] = useState<string>(initialColor);

    return (
        <SketchPicker
            disableAlpha={true}
            color={color}
            onChangeComplete={newColor => {
                setColor(newColor.hex);
                if (onChange) {
                    onChange(newColor.hex);
                }
            }}
        />
    );
}
