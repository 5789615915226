import {
    StyleFunction,
    Surface,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQLayoutCreateMutation,
    useLayoutCreateMutation,
    useTableLayoutFormQuery,
} from "graphql-sdk";
import { ILayoutCreate, LayoutSection, schemaLayout } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { defaultLayout } from "../forms";
import { LayoutCreateForm } from "../layout-create-form";
import { LayoutScreen, productLayoutSizes } from "../layout-screen";

import type { FetchResult } from "@apollo/client";
import { produceNewLayoutSection } from "../functions";
type ScreenProps = RootStackScreenProps<"LAYOUT_CREATE">;

export function LayoutCreateScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.layout.write");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const { handleMutationError } = useHandleMutationError();
    const [layoutCreate] = useLayoutCreateMutation();

    const form = useForm<ILayoutCreate>(schemaLayout, defaultLayout);
    const [{ values }] = form;
    const { data: formData } = useTableLayoutFormQuery();

    const create = useCallback(async () => {
        if (
            !values ||
            !values.dimensions ||
            !values.dimensions.columns ||
            !values.dimensions.rows
        ) {
            return;
        }

        const emptyLayoutSection: LayoutSection = produceNewLayoutSection(
            t,
            "1"
        );

        let createResult: FetchResult<GQLayoutCreateMutation>;
        await handleMutationError(
            async () => {
                createResult = await layoutCreate({
                    variables: {
                        layout: {
                            name: values.name,
                            data: JSON.stringify([emptyLayoutSection]),
                            dimensions: values.dimensions,
                            layoutType: "PRODUCT",
                        },
                    },
                });
            },
            t("common.created", "Created"),
            () => {
                if (!createResult.data || !createResult.data?.layoutCreate.id) {
                    return;
                }

                if (!route.params || !route.params.referrer) {
                    navigate("LAYOUT_EDIT", {
                        id: createResult.data.layoutCreate.id,
                    });
                    return;
                }

                navigate("LAYOUT_EDIT", {
                    id: createResult.data.layoutCreate.id,
                    referrer: {
                        route: route.params.referrer.route,
                        id: route.params.referrer.id,
                    },
                });
            }
        );
    }, [values, t, handleMutationError, layoutCreate, route.params, navigate]);

    return (
        <LayoutScreen>
            <Surface style={styles.container}>
                <LayoutCreateForm
                    form={form}
                    data={formData!}
                    onSubmit={create}
                    submitButton={["common.create", "Create"]}
                    layoutSizes={productLayoutSizes}
                />
            </Surface>
        </LayoutScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: { maxWidth: theme.dimensions.maxFormWidth },
});
