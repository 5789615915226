import {
    DataTable,
    Icon,
    Loading,
    SearchInput,
    StyleFunction,
    Surface,
    useAuth,
    useMerchantConfig,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQCardTransactionsQuery, useCardTransactionsQuery } from "graphql-sdk";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";
import { CardTransactionRow } from "./card-transaction-row";

type ScreenProps = RootStackScreenProps<"CARD_TRANSACTIONS">;

export function CardTransactionsScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.invoice");

    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "transaction_at",
        initialSortDirection: "DESC",
    });

    const [search, setSearch] = useState<string>("");

    // GraphQL
    const { data, loading, refetch } = useCardTransactionsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const renderTransactionRow = useCallback(
        (
            item: GQCardTransactionsQuery["cardTransactions"]["data"][0],
            rowIndex
        ) => (
            <CardTransactionRow
                key={item.id}
                item={item}
                currency={currency}
                locale={locale}
                onSelect={() =>
                    !!item.receipt &&
                    navigate("CARD_TRANSACTION_VIEW", { id: item.id })
                }
                style={rowIndex % 2 === 1 ? styles.oddRow : undefined}
            />
        ),
        [currency, locale, navigate, styles.oddRow]
    );

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list
            onPageChange(0);
        },
        [onPageChange]
    );

    return (
        <AdminContainer>
            <SearchInput onChange={handleSearchTextChange} />
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "cash_register" && sortDirection
                            }
                            onPress={() => onSortChange("cash_register")}
                        >
                            {t(
                                "backoffice.card_transaction.cash_register.header",
                                "Cash register"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "status" && sortDirection}
                            onPress={() => onSortChange("status")}
                        >
                            {t(
                                "backoffice.card_transaction.status.header",
                                "Status"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            sortDirection={sortBy === "amount" && sortDirection}
                            onPress={() => onSortChange("amount")}
                        >
                            {t(
                                "backoffice.card_transaction.amount.header",
                                "Amount"
                            )}
                        </DataTable.Title>

                        <DataTable.Title
                            numeric
                            sortDirection={
                                sortBy === "transaction_at" && sortDirection
                            }
                            onPress={() => onSortChange("transaction_at")}
                        >
                            {t(
                                "backoffice.card_transaction.transaction_at.header",
                                "Transaction at"
                            )}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={styles.iconColumn.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        data?.cardTransactions.data.map(renderTransactionRow)
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.cardTransactions.pagination.pages}
                        itemCount={
                            data?.cardTransactions.pagination.resultCount
                        }
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: theme.spacingScale * 6,
        color: theme.colors.black,
    },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});
