import React, { useCallback } from "react";
import {
    StyleFunction,
    useThemedStyle,
    DataTable,
    CheckBox,
} from "@venuepos/react-common";
import { TagsType } from "./tag-type";
import type { GQTagScreenFieldsFragment } from "graphql-sdk";

export function TagItem({
    item,
    selectedTag,
    onMultiSelectChange,
}: {
    item: GQTagScreenFieldsFragment;
    selectedTag: boolean;
    onMultiSelectChange: (selected: boolean, itemIds: string[]) => void;
}) {
    const styles = useThemedStyle(styleFunc);

    const handleMultiSelectChange = useCallback(
        value => onMultiSelectChange(value, [item.id]),
        [item.id, onMultiSelectChange]
    );

    return (
        <DataTable.Row>
            <DataTable.Cell style={styles.iconColumn}>
                <CheckBox
                    value={selectedTag}
                    onValueChange={handleMultiSelectChange}
                    style={styles.checkbox}
                    testID={"checked:" + item.name}
                />
            </DataTable.Cell>
            <DataTable.Cell>{item.name}</DataTable.Cell>
            <DataTable.Cell>{item.group?.name}</DataTable.Cell>
            <DataTable.Cell>
                <TagsType type={item.tagType || ""} />
            </DataTable.Cell>
            <DataTable.Cell>{item.identifier}</DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = () => ({
    checkbox: {
        marginVertical: 6,
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
    },
});
