import React, { useMemo } from "react";
import { useTheme } from "../../theme";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import { Icon } from "../icon";

export function IconIndicator({
    name,
    color,
    loading = false,
}: {
    name: string;
    color: string;
    loading?: boolean;
}) {
    const theme = useTheme();
    const colorStyle = useMemo(() => ({ backgroundColor: color }), [color]);
    return (
        <View style={[styles.container, colorStyle]}>
            {loading ? (
                <ActivityIndicator size="small" />
            ) : (
                <Icon size="tiny" name={name} color={theme.colors.white} />
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        height: 26,
        width: 26,
        borderRadius: 13,
        justifyContent: "center",
        alignItems: "center",
    },
    icon: {
        textAlign: "center",
    },
});
