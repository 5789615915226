import { useState, useCallback } from "react";

export function useMultiSelect<T>() {
    const [selectedItems, setSelectedItems] = useState<T[]>([]);
    const [allItemsSelected, setAllItemsSelected] = useState<boolean>(false);

    const handleMultiSelect = useCallback(
        (selected: boolean, itemIds: T[]) => {
            let clonedSelectedItems = [...selectedItems];
            if (selected) {
                clonedSelectedItems = [...clonedSelectedItems, ...itemIds];
            } else {
                clonedSelectedItems = clonedSelectedItems.filter(
                    i => !itemIds.includes(i)
                );
            }
            setSelectedItems(clonedSelectedItems);
        },
        [selectedItems]
    );

    return {
        selectedItems,
        setSelectedItems,
        handleMultiSelect,
        allItemsSelected,
        setAllItemsSelected,
    };
}
