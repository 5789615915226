import React, { useCallback } from "react";
import type { OrderConditions } from "lib/src/entity-condition";
import { produce } from "immer";
import type { GQDiscountFormQuery } from "graphql-sdk";
import type { ConditionAssignment, Conditions } from "lib";
import { Picker, StyleFunction, useThemedStyle } from "@venuepos/react-common";
import { useTranslation } from "react-i18next";

export function CustomerConditionInput({
    condition,
    conditions,
    onConditionChange,
    formData,
}: {
    condition: ConditionAssignment;
    conditions: Conditions;
    onConditionChange: (condition: ConditionAssignment) => void;
    formData: GQDiscountFormQuery;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const conditionType = condition.condition as keyof Pick<
        OrderConditions,
        "hasCustomer" | "hasCustomerGroup"
    >;
    const args = condition.args as any;
    const selectedValue =
        conditionType === "hasCustomer"
            ? args.customerId
            : args.customerGroupId;

    const unusedCustomers = formData.customers.data.filter(customerItr => {
        // If the customer is the same as the current condition, then it's allowed
        if (customerItr.id === selectedValue) {
            return true;
        }

        return (
            conditions.find(
                itr =>
                    itr.condition === "hasCustomer" &&
                    (itr.args as OrderConditions["hasCustomer"]).customerId ===
                        customerItr.id
            ) === undefined
        );
    });

    const unusedCustomerGroups = formData.customerGroups.data.filter(
        groupItr => {
            // If the group is the same as the current condition, then it's allowed
            if (groupItr.id === selectedValue) {
                return true;
            }

            // Filter out groups used by other conditions
            return (
                conditions.find(
                    itr =>
                        itr.condition === "hasCustomerGroup" &&
                        (itr.args as OrderConditions["hasCustomerGroup"])
                            .customerGroupId === groupItr.id
                ) === undefined
            );
        }
    );

    const onTypeChange = useCallback(
        (
            type: Extract<
                ConditionAssignment["condition"],
                "hasCustomer" | "hasCustomerGroup"
            >
        ) => {
            onConditionChange(
                produce(condition, draft => {
                    draft.condition = type;
                    switch (type) {
                        case "hasCustomer":
                            if (unusedCustomers[0]) {
                                (draft.args as OrderConditions["hasCustomer"]) =
                                    {
                                        customerId: unusedCustomers[0].id,
                                    };
                            }
                            break;

                        case "hasCustomerGroup":
                            if (unusedCustomerGroups[0]) {
                                (draft.args as OrderConditions["hasCustomerGroup"]) =
                                    {
                                        customerGroupId:
                                            unusedCustomerGroups[0].id,
                                    };
                            }
                            break;
                    }
                })
            );
        },
        [condition, onConditionChange, unusedCustomerGroups, unusedCustomers]
    );

    const onIdChange = useCallback(
        (id: string) => {
            onConditionChange(
                produce(condition, draft => {
                    switch (conditionType) {
                        case "hasCustomer":
                            (draft.args as any).customerId = id;
                            break;
                        case "hasCustomerGroup":
                            (draft.args as any).customerGroupId = id;
                            break;
                    }
                })
            );
        },
        [condition, onConditionChange, conditionType]
    );

    let input;
    switch (conditionType) {
        case "hasCustomer":
            input = (
                <Picker
                    selectedValue={selectedValue}
                    onValueChange={onIdChange}
                >
                    {unusedCustomers.map(customer => {
                        return (
                            <Picker.Item
                                key={customer.id}
                                value={customer.id}
                                label={customer.name}
                            />
                        );
                    })}
                </Picker>
            );
            break;

        case "hasCustomerGroup":
            input = (
                <Picker
                    selectedValue={selectedValue}
                    onValueChange={onIdChange}
                >
                    {unusedCustomerGroups.map(group => {
                        return (
                            <Picker.Item
                                key={group.id}
                                value={group.id}
                                label={group.name}
                            />
                        );
                    })}
                </Picker>
            );
            break;
    }

    return (
        <>
            <Picker
                selectedValue={conditionType}
                onValueChange={onTypeChange as (type: string) => void}
                style={styles.typePicker}
            >
                <Picker.Item
                    key={"hasCustomer"}
                    value={"hasCustomer"}
                    label={t("backoffice.discount_form.customer", "Customer")}
                    disabled={unusedCustomers.length === 0}
                />
                <Picker.Item
                    key={"hasCustomerGroup"}
                    value={"hasCustomerGroup"}
                    label={t(
                        "backoffice.discount_form.customer_group",
                        "Group"
                    )}
                    disabled={unusedCustomerGroups.length === 0}
                />
            </Picker>
            {input}
        </>
    );
}

const styleFunc: StyleFunction = _ => ({
    typePicker: {
        width: "auto",
        marginRight: 10,
    },
});
