import React from "react";
import {
    InputControl,
    TextInput,
    useThemedStyle,
    StyleFunction,
} from "@venuepos/react-common";
import { View } from "react-native";
import { ValidationError, ValidationErrors } from "lib/src/validation/types";
import type { TFunction } from "i18next";
import { IUserPassword } from "lib/src/user";

export function PasswordInput({
    errors,
    setValue,
    t,
    values,
}: {
    errors: ValidationErrors<{
        password: ValidationError;
        confirmPassword: ValidationError;
    }>;
    setValue: <Field extends keyof IUserPassword>(
        field: Field,
        value: string
    ) => void;
    t: TFunction;
    values: any;
}) {
    const styles = useThemedStyle(styleFunc);
    return (
        <View>
            <InputControl
                error={errors.password}
                style={styles.passwordInput}
                description={t(
                    "backoffice.password_form.password_rules",
                    "Must be at least 8 characters"
                )}
            >
                <TextInput
                    label={t("backoffice.user_form.password", "Password")}
                    placeholder={t(
                        "backoffice.user_form.enter_password",
                        "Enter password"
                    )}
                    secureTextEntry={true}
                    defaultValue={values.password}
                    onChangeText={text => setValue("password", text)}
                    required={true}
                />
            </InputControl>
            <InputControl error={errors.confirmPassword}>
                <TextInput
                    label={t(
                        "backoffice.user_form.confirm_password",
                        "Confirm password"
                    )}
                    placeholder={t(
                        "backoffice.user_form.enter_confirm_password",
                        "Repeat the chosen password"
                    )}
                    secureTextEntry={true}
                    onChangeText={text => setValue("confirmPassword", text)}
                    required={true}
                />
            </InputControl>
        </View>
    );
}

const styleFunc: StyleFunction = () => ({
    passwordInput: {
        marginBottom: 0,
    },
});
