import type { ILayoutProduct, ProductButton } from "lib";
import { useForm } from "@venuepos/react-common";
import { schemaProductButton } from "../../../../forms";
import React, { useCallback } from "react";
import { ProductButtonForm } from "./product-button-form";
import { AddButtonModalOutput } from "..";

export function ProductButtonMainForm({
    button,
    buttonProduct,
    buttonColor,
    buttonLabel,
    onClose,
}: {
    button: ProductButton;
    buttonProduct: ILayoutProduct;
    buttonColor: ProductButton["color"];
    buttonLabel: ProductButton["label"];
    onClose: (values?: AddButtonModalOutput) => void;
}) {
    const productButtonForm = useForm<ProductButton>(
        {
            ...schemaProductButton,
            ...{
                width: {
                    maxValue: {
                        value: button.metaData?.maxWidthValue || 1,
                    },
                },
                height: {
                    maxValue: {
                        value: button.metaData?.maxHeightValue || 1,
                    },
                },
            },
        },
        null
    );

    const [{ values }, { setDefaultValues }] = productButtonForm;

    const editProductButton = useCallback(() => {
        if (!values || !buttonProduct) {
            return;
        }

        return onClose({
            buttonType: "PRODUCT",
            formValues: values,
            productData: buttonProduct,
        });
    }, [buttonProduct, onClose, values]);

    // This is product button specific. It should not be. Wrap it in a function to determine the right button type first?
    setDefaultValues({
        buttonType: "PRODUCT",
        id: (button as ProductButton).id || "",
        amount: (button as ProductButton).amount || 0,
        productId: buttonProduct.id,
        x: button.x || 0,
        y: button.y || 0,
        width: button.width || 1,
        height: button.height || 1,
        color: buttonColor,
        label: buttonLabel,
        metaData: {
            maxWidthValue: button.metaData?.maxWidthValue || 1,
            maxHeightValue: button.metaData?.maxHeightValue || 1,
        },
    });

    return (
        <ProductButtonForm
            form={productButtonForm}
            onSubmit={editProductButton}
            submitButton={["common.save", "Save"]}
            product={buttonProduct}
        />
    );
}
