// Inspiration: https://codesandbox.io/s/o9q8vy70l5?file=/src/Hoverable.js
import React, { useState, ReactElement } from "react";
import { Platform } from "react-native";

export function Hoverable({
    children,
}: {
    children: (isHovered: boolean) => ReactElement;
}) {
    const [isHovered, setIsHovered] = useState(false);
    const [showHovered, setShowHovered] = useState(true);

    function handleMouseEnter() {
        setIsHovered(true);
    }

    function handleMouseLeave() {
        setIsHovered(false);
    }

    function handleGrant() {
        setShowHovered(false);
    }

    function handleRelease() {
        setShowHovered(true);
    }

    if (Platform.OS === "web") {
        const child = children(showHovered && isHovered);
        return React.cloneElement(React.Children.only(child), {
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
            onResponderGrant: handleGrant,
            onResponderRelease: handleRelease,
        });
    }

    return children(false);
}
