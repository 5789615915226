import React from "react";
import { StyleSheet } from "react-native";
import { useTheme } from "../../theme";
import { Headline } from "../headline";

export function PageTitle({ children }: { children: string }) {
    const theme = useTheme();
    return (
        <Headline style={[styles.headline, { color: theme.colors.secondary }]}>
            {children}
        </Headline>
    );
}

const styles = StyleSheet.create({
    headline: {
        marginBottom: 16,
    },
});
