import type { ValidationSchema } from "../validation";
import type { GQPrinterSave } from "graphql-sdk";
import type { IPrinterSave } from "./iprinter";

export const schemaPrinter: ValidationSchema<GQPrinterSave> = {
    name: { required: true },
};

export const defaultPrinter: IPrinterSave = {
    name: "",
};
