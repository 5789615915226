import type { IMerchantInput, IMerchantCreate } from "lib";

export const defaultMerchantCreate: IMerchantCreate = {
    name: "",
    email: "",
    currency: "DKK",
    vat: 0,
    giftcardVatBookkeepingMethod: "AT_ISSUE",
};

export const defaultMerchant: IMerchantInput = {
    name: "",
};
