import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../textinput";
import { debounce, trim } from "lodash";

export const SearchInput = (props: {
    onChange: (searchText: string) => void;
    value?: string;
    onClear?: () => void;
    searchTimeout?: number;
    testID?: string;
}) => {
    const [t] = useTranslation();
    const [text, setText] = useState<string>(props.value || "");
    const [lastText, setLastText] = useState<string>(props.value || "");

    // Use a ref, so the connection is not lost on every state change
    const debounceOnSearch = useRef(
        debounce(searchPhrase => {
            if (!props.onChange) {
                return;
            }

            props.onChange(searchPhrase);
        }, props.searchTimeout || 400)
    );

    const handleClear = useCallback(() => {
        setText("");
        if (props.onClear) {
            props.onClear();
        }
    }, [props]);

    useEffect(() => {
        if (!props || !props.onChange) {
            return;
        }
        if (text === lastText) {
            return;
        }
        setLastText(trim(text));

        // Remove whitespace from the query text - this might cause issues if the user tries to search for "blanks".
        debounceOnSearch.current(trim(text));
    }, [debounceOnSearch, lastText, props, text]);

    return (
        <TextInput
            onChangeText={setText}
            onClear={props.onClear && !!text ? handleClear : undefined}
            placeholder={`${t("common.search", "Search")}...`}
            value={text}
            testID={props.testID}
        />
    );
};
