import type { IMiscButtons } from "./types";

export const defaultMiscButtons: IMiscButtons["buttons"] = {
    pay: true,
    park: true,
    discount: true,
    print: true,
    scan: true,
    openDrawer: true,
    delete: true,
    parkedReceipts: true,
    closedReceipts: true,
    return: true,
    refund: true,
    tags: true,
    accountBalance: true,
    splitPayment: true,
};
