import { MerchantConfig, merchantConfigDefault } from "lib";
import { useMe } from "@venuepos/react-common";
import { useMemo } from "react";

export function useMerchantConfig(): MerchantConfig {
    const me = useMe();
    return useMemo(() => {
        if (!me.merchant?.entityConfig?.data) {
            return merchantConfigDefault;
        }

        return {
            ...merchantConfigDefault,
            ...JSON.parse(me.merchant.entityConfig.data),
        };
    }, [me.merchant]);
}
