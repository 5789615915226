import { useAccessToken } from "./use-access-token";

// Nothing fancy. Just wraps a fetch with our access token
export function useFetch() {
    const accessToken = useAccessToken();
    const headers: HeadersInit = accessToken
        ? {
              authorization: `Bearer ${accessToken}`,
          }
        : {};
    function execFetch(
        input: RequestInfo,
        init?: RequestInit
    ): Promise<Response> {
        let opts: RequestInit = {
            headers: {
                ...headers,
                ...init?.headers,
            },
        };
        if (init) {
            // The headers are already merged in - remove them before merging the rest
            if (init.headers) {
                delete init.headers;
            }
            opts = {
                ...opts,
                ...init,
            };
        }
        return fetch(input, opts);
    }
    return execFetch;
}
