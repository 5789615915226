import {
    LoadingScreen,
    StyleFunction,
    Surface,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    usePaymentMethodFormQuery,
    usePaymentMethodLazyQuery,
    usePaymentMethodSaveMutation,
} from "graphql-sdk";
import { PaymentMethodInputForm, schemaPaymentMethod } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../navigation";
import { PaymentMethodForm } from "./payment-method-form";
import { PaymentMethodScreen } from "./payment-method-screen";

type ScreenProps = RootStackScreenProps<"PAYMENT_METHOD_EDIT">;

export function PaymentMethodEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.payment_method.write");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [paymentMethodEdit] = usePaymentMethodSaveMutation();
    const { handleMutationError } = useHandleMutationError();

    const form = useForm<PaymentMethodInputForm>(schemaPaymentMethod, null);
    const formData = usePaymentMethodFormQuery();
    const [{ values }, { setDefaultValues }] = form;
    const paymentMethodId = route.params.id;

    const [
        loadPaymentMethod,
        { data, loading, called: paymentMethodLoadCalled },
    ] = usePaymentMethodLazyQuery({
        variables: { id: paymentMethodId },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (formData.data && !paymentMethodLoadCalled) {
            loadPaymentMethod();
        }
    }, [formData.data, loadPaymentMethod, paymentMethodLoadCalled]);

    useEffect(() => {
        if (data && data.paymentMethod) {
            const p = data.paymentMethod;
            setDefaultValues({
                name: p.name,
                account: p.account,
                diffAccount: p.diffAccount,
                paymentType: p.paymentType,
                enabled: p.enabled,
                currencyId: p.currency.id,
            });
        }
    }, [data, setDefaultValues]);

    const edit = useCallback(async () => {
        if (!data || !data.paymentMethod || !values) {
            return;
        }

        await handleMutationError(
            async () =>
                await paymentMethodEdit({
                    variables: {
                        id: paymentMethodId,
                        paymentMethod: {
                            name: values.name,
                            paymentType: values.paymentType as string,
                            enabled: values.enabled,
                            account: values.account,
                            diffAccount: values.diffAccount,
                            currencyId: values.currencyId,
                        },
                    },
                }),
            t("backoffice.payment_method.saved", "Payment method saved"),
            () => {
                // If there's no referrer, redirect to list screen
                if (!route.params || !route.params.referrer) {
                    navigate("PAYMENT_METHODS", { refetch: true });
                    return;
                }

                // If the referrer is Payment Config. Edit, then redirect there
                if (
                    route.params.referrer.route ===
                        "PAYMENT_CONFIGURATION_EDIT" &&
                    route.params.referrer.id
                ) {
                    navigate("PAYMENT_CONFIGURATION_EDIT", {
                        id: route.params.referrer.id,
                    });
                }
            }
        );
    }, [
        data,
        handleMutationError,
        navigate,
        paymentMethodEdit,
        paymentMethodId,
        route.params,
        t,
        values,
    ]);

    if (!formData.data || !data || loading) {
        return <LoadingScreen style="light" />;
    }

    return (
        <PaymentMethodScreen>
            <Surface>
                <View style={styles.box}>
                    <View style={styles.container}>
                        <PaymentMethodForm
                            form={form}
                            formData={formData.data}
                            onSubmit={edit}
                            submitButton={["common.save", "Save"]}
                        />
                    </View>
                </View>
            </Surface>
        </PaymentMethodScreen>
    );
}
const styleFunc: StyleFunction = theme => {
    return {
        box: {
            width: "100%",
            backgroundColor: theme.colors.white,
        },
        container: {
            width: "100%",
            maxWidth: theme.dimensions.maxFormWidth,
            alignSelf: "flex-start",
        },
    };
};
