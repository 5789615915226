import React from "react";
import { InputControl, Picker, TextInput } from "@venuepos/react-common";
import { useTranslation } from "react-i18next";
import type { GQDepartment } from "graphql-sdk";
import type { ICashRegisterInput } from "lib";
import type { Form } from "@venuepos/react-common";

export function CashRegisterCommonForm({
    form,
    departments,
}: {
    form: Form<ICashRegisterInput>;
    departments: Array<
        { __typename?: "Department" } & Pick<GQDepartment, "id" | "name">
    >;
}) {
    const [t] = useTranslation();
    const [{ values, errors }, { setValue }] = form;

    if (!values) {
        return null;
    }

    return (
        <>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    onChangeText={test => setValue("name", test)}
                    defaultValue={values.name}
                    testID="name"
                    required={true}
                />
            </InputControl>
            <InputControl error={errors.departmentId}>
                <Picker
                    label={t("backoffice.common.department", "Department")}
                    onValueChange={value =>
                        setValue(
                            "departmentId",
                            value !== "none" ? value : null
                        )
                    }
                    selectedValue={values.departmentId || "none"}
                    testID="department"
                >
                    <Picker.Item
                        label={t(
                            "backoffice.cash_register_edit.choose_department",
                            "Choose a department (optional)"
                        )}
                        value={"none"}
                    />
                    {departments.map(department => (
                        <Picker.Item
                            key={department.id}
                            value={department.id}
                            label={department.name}
                            testID={"department:" + department.name}
                        />
                    ))}
                </Picker>
            </InputControl>
        </>
    );
}
