import { TimeType } from "lib";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { InputControl } from "../input-control";
import { TextInput } from "../textinput";
import { TimeString } from "./common";

export function TimeInput({
    value,
    onTimeChange,
    label,
    placeholder,
    disabled,
    testID,
}: {
    value?: TimeString;
    onTimeChange?: (time: TimeType) => void;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    testID?: string;
}) {
    const [t] = useTranslation();
    const [time, setTime] = useState(value);
    const [inputValidated, setInputValidated] = useState(true);

    const handleValueChange = useCallback(
        _ => {
            if (!onTimeChange) {
                return;
            }

            if (!inputValidated) {
                return;
            }

            if (!time?.trim()) {
                return;
            }

            const parts = time.split(":");
            const returnValue = {
                hour: Number(parts[0]),
                minute: Number(parts[1]),
            };
            onTimeChange(returnValue);
        },
        [inputValidated, onTimeChange, time]
    );

    return (
        <InputControl
            error={
                !inputValidated
                    ? t("input.time.not_validated", "Not a valid time")
                    : undefined
            }
        >
            <TextInput
                label={label}
                disabled={disabled}
                placeholder={placeholder}
                value={time}
                maxLength={5}
                onChangeText={text => {
                    setInputValidated(validateInput(text));
                    setTime(text as TimeString);
                }}
                onBlur={handleValueChange}
                accessibilityRole="search"
                testID={testID}
            />
        </InputControl>
    );
}

const validateInput = (inputTime: string) => {
    const parts = inputTime.split(":");

    if (!inputTime || parts.length <= 1) {
        return false;
    }

    if (!parts[0] || !parts[1]) {
        return false;
    }

    const hourPart = Number(parts[0]);
    const minutePart = Number(parts[1]);

    if (isNaN(hourPart) || isNaN(minutePart)) {
        return false;
    }

    if (hourPart < 0 || hourPart > 23) {
        return false;
    }

    if (minutePart < 0 || minutePart > 59) {
        return false;
    }

    return true;
};
