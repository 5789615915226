import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { useTranslation } from "react-i18next";
import { FlatList } from "react-native-gesture-handler";
import { formatAmount, ILayoutProduct } from "lib";
import { GQProductsQuery, useProductsQuery } from "graphql-sdk";
import {
    useThemedStyle,
    usePagination,
    useMerchantConfig,
    DataTable,
    Alert,
    Button,
    SearchInput,
    StyleFunction,
    Text,
} from "@venuepos/react-common";
import { ProductRow } from "./product-table-row";
import { ProductTableHeader } from "./product-table-header";
import { useAdminSession } from "../../../../../../session";
import type { AvailableLocale } from "locales";

export function ProductSelector({
    addedProducts,
    onSelectProduct,
    onAddProductsToLayout,
}: {
    addedProducts: string[];
    onSelectProduct: ({
        selectedProduct,
        selectedButtonColor,
        selectedButtonLabel,
    }: {
        selectedProduct: ILayoutProduct;
        selectedButtonColor: string;
        selectedButtonLabel: string;
    }) => void;
    onAddProductsToLayout: (products: ILayoutProduct[]) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ locale }] = useAdminSession(["locale"]);
    const merchantConfig = useMerchantConfig();

    const { page, pageSize, sortBy, sortDirection, onSortChange } =
        usePagination({
            initialPageSize: 250,
            initialSortBy: "name",
        });

    const [search, setSearch] = useState<string>("");

    const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);
    const [selectedProducts, setSelectedProducts] = useState<ILayoutProduct[]>(
        []
    );

    const { data, error } = useProductsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
            includeChildren: true,
        },
        fetchPolicy: "no-cache",
    });

    const handleSelectProduct = useCallback(
        (productId: string) => {
            if (!data || !data.products.data) {
                return;
            }
            onSelectProduct({
                selectedProduct: data.products.data.find(
                    itr => itr.id === productId
                )! as ILayoutProduct,
                selectedButtonColor: "",
                selectedButtonLabel: "",
            });
        },
        [data, onSelectProduct]
    );

    const handleAddProducts = useCallback(() => {
        if (!data || !data.products.data) {
            return;
        }

        return onAddProductsToLayout(selectedProducts);
    }, [data, onAddProductsToLayout, selectedProducts]);

    const handleSelectAll = useCallback(
        (value: boolean) => {
            if (!data || !data.products.data) {
                return;
            }

            setAllRowsSelected(value);

            const productIdsFromThisSearch = data.products.data.map(p => p.id);
            const selectedProductIds = selectedProducts.map(p => p.id);

            // If the "select all" checkbox is switched off, then remove all products
            // from the current data set in the list of selected products.
            if (!value) {
                const filteredLists = selectedProducts.filter(
                    p => !productIdsFromThisSearch.includes(p.id)
                );
                setSelectedProducts(filteredLists);
                return;
            }

            const mappedProducts = mapToLayoutProducts(
                productIdsFromThisSearch,
                data.products.data
            );

            const mappedProductsMinusAlreadySelected = mappedProducts.filter(
                p => !selectedProductIds.includes(p.id)
            );

            setSelectedProducts([
                ...selectedProducts,
                ...mappedProductsMinusAlreadySelected,
            ]);
        },
        [data, selectedProducts]
    );

    const handleSelectNotAddedProducts = useCallback(() => {
        if (!data || !data.products.data) {
            return;
        }

        // Find the list of all the products in the current result set, that have NOT been added to a product.
        const allNotAddedProducts = data.products.data.filter(
            p => !addedProducts.includes(p.id)
        );

        if (allNotAddedProducts.length <= 0) {
            return;
        }

        setAllRowsSelected(
            allNotAddedProducts.length === data.products.data.length
        );

        setSelectedProducts([
            ...mapToLayoutProducts(
                allNotAddedProducts.map(p => p.id),
                data.products.data
            ),
        ]);
    }, [addedProducts, data]);

    const handleAddSingleProductToSelection = useCallback(
        (booleanValue: boolean, productId: ILayoutProduct["id"]) => {
            if (!data || !data.products || !data?.products.data) {
                return;
            }

            if (!booleanValue) {
                // remove the element from the list of selected products
                setSelectedProducts(
                    selectedProducts.filter(p => p.id !== productId)
                );
                return;
            }

            if (!selectedProducts.find(p => p.id === productId)) {
                setSelectedProducts([
                    ...selectedProducts,
                    ...mapToLayoutProducts([productId], data.products.data).map(
                        productItr => productItr
                    ),
                ]);
                return;
            }
        },
        [data, selectedProducts]
    );

    const renderItem = ({
        item,
    }: {
        item: GQProductsQuery["products"]["data"][0];
    }) => (
        <ProductRow
            item={item}
            formattedAmount={formatAmount(
                item.amount,
                merchantConfig.currency,
                {
                    locale: locale as AvailableLocale,
                }
            )}
            onSelect={handleSelectProduct}
            selectedProduct={selectedProducts
                .map(productItr => productItr.id)
                .includes(item.id)}
            alreadyAdded={addedProducts.includes(item.id)}
            onMultiSelectChange={handleAddSingleProductToSelection}
        />
    );

    const handleSearchTextChange = (text: React.SetStateAction<string>) => {
        setSearch(text);
    };

    if (error) {
        return (
            <Alert type="error">
                {t("common.error", "There was an error: {{errorText}}", {
                    errorText: error.message,
                })}
            </Alert>
        );
    }

    return (
        <>
            <SearchInput
                onChange={handleSearchTextChange}
                value={search}
                testID="layout:productSearch"
            />
            <View style={[styles.rowContainer, styles.buttonContainer]}>
                <Button
                    size="small"
                    onPress={handleSelectNotAddedProducts}
                    disabled={!data?.products.data.length}
                    style={styles.button}
                    testID="layout:addNotAddedProducts"
                >
                    {t(
                        "backoffice.layout.select_unadded_products",
                        "Select un-added"
                    )}
                </Button>
                <Button
                    size="small"
                    onPress={handleAddProducts}
                    disabled={!selectedProducts.length}
                    testID="layout:addSelectedProducts"
                >
                    {t(
                        "backoffice.layout.add_multiple_products",
                        "Add selected to layout"
                    )}
                </Button>
            </View>
            <View style={styles.rowContainer}>
                <Text
                    style={styles.topListMessageText}
                    testID="layout:text:selectedProducts"
                >
                    {t(
                        "backoffice.layout.selected_product",
                        "{{ count }} selected product",
                        { count: selectedProducts.length }
                    )}
                </Text>
                {!!data && data.products.pagination.pages > 1 ? (
                    <Text style={styles.topListMessageText}>
                        {t(
                            "backoffice.layout.showing_first_elements_of_list",
                            "Showing the first {{ count }} results",
                            {
                                count: pageSize,
                            }
                        )}
                    </Text>
                ) : null}
            </View>
            <View style={styles.dataTableContainer}>
                <DataTable>
                    <ProductTableHeader
                        sortByColumn={sortBy}
                        sortDirection={sortDirection}
                        onSortChange={onSortChange}
                        showSelectAll={!!data && data.products.data.length > 0}
                        onSelectAll={handleSelectAll}
                        allSelected={allRowsSelected}
                    />
                    <FlatList
                        data={data?.products.data}
                        windowSize={5}
                        initialNumToRender={25}
                        maxToRenderPerBatch={25}
                        updateCellsBatchingPeriod={200}
                        renderItem={renderItem}
                        keyExtractor={item => item.id}
                        ListEmptyComponent={
                            search !== "" &&
                            data?.products.data.length === 0 ? (
                                <EmptyList />
                            ) : null
                        }
                        ListFooterComponent={
                            data && data.products.pagination.pages > 1 ? (
                                <ListFooter count={pageSize} />
                            ) : null
                        }
                        style={styles.dataTable}
                    />
                </DataTable>
            </View>
            <View style={styles.alreadyAddedContainer}>
                <Text style={styles.alreadyAddedNotice}>
                    {t(
                        "backoffice.layout.notice_already_added",
                        "Notice: Products in a row with a darker background has already been added to this layout."
                    )}
                </Text>
            </View>
        </>
    );
}

function ListMessage({ message }: { message: string }) {
    const styles = useThemedStyle(styleFunc);
    return (
        <View style={styles.notice}>
            <Text>{message}</Text>
        </View>
    );
}

function EmptyList() {
    const [t] = useTranslation();

    return (
        <ListMessage
            message={t(
                "backoffice.layout.product_not_found",
                "No product was found, that matches the search."
            )}
        />
    );
}

function ListFooter({ count }: { count?: number }) {
    const [t] = useTranslation();

    return (
        <ListMessage
            message={t(
                "backoffice.layout.incomplete_product_list",
                "Showing {{ count }} products. Did you not find, what you were looking for in this incomplete list? Try searching in the text field.",
                { count }
            )}
        />
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    rowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: theme.spacingScale,
    },
    buttonContainer: {
        justifyContent: "flex-start",
    },
    button: {
        marginRight: theme.spacingScale,
    },
    dataTableContainer: {
        flex: 1,
    },
    dataTable: {
        maxHeight: dimensions.height - 400,
    },
    topListMessageText: {
        fontSize: 14,
    },
    notice: {
        margin: theme.spacingScale,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: theme.spacingScale,
        paddingVertical: theme.spacingScale / 2,

        borderRadius: theme.borderRadiusSmall,
        backgroundColor: theme.colors.toastDefault,
    },
    alreadyAddedNotice: {
        fontSize: 14,
    },
    alreadyAddedContainer: {
        marginTop: theme.spacingScale * 2,
    },
});

function mapToLayoutProducts(
    selectedProducts: GQProductsQuery["products"]["data"][0]["id"][],
    availableProducts: GQProductsQuery["products"]["data"]
): ILayoutProduct[] {
    if (selectedProducts.length === 0) {
        return [];
    }

    return selectedProducts.map((productId: string): ILayoutProduct => {
        let returnValue = availableProducts.find(itr => itr.id === productId);

        if (returnValue) {
            return {
                ...returnValue,
                buttonText: returnValue.buttonText ?? "",
            };
        }

        return {
            id: productId,
            amount: 0,
            buttonText: "UNKNOWN",
            costAmount: 0,
            minimumAmount: 0,
            name: "UNKNOWN",
        };
    });
}
