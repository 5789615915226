import { Link } from "@react-navigation/native";
import {
    Headline,
    StyleFunction,
    Surface,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import { Currency, formatAmount } from "lib";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import type { GQProductChildrenListFieldsFragment } from "graphql-sdk";
import type { AvailableLocale } from "locales";

export function ProductChildren({
    childProducts,
    currency,
    locale,
}: {
    childProducts: GQProductChildrenListFieldsFragment["data"];
    currency: Currency;
    locale: AvailableLocale;
}) {
    const [t] = useTranslation();
    const data = childProducts;
    const styles = useThemedStyle(styleFunc);

    if (childProducts.length === 0) {
        return null;
    }

    return (
        <Surface style={styles.container}>
            <Headline size="h5" style={styles.headline}>
                {t("backoffice.product.children", "Child products")}
            </Headline>
            <View>
                {data.map((item, index) => (
                    <View
                        style={[
                            styles.itemRow,
                            index % 2 === 0 ? styles.oddItemRow : null,
                        ]}
                    >
                        <View style={styles.itemColumn1}>
                            <Link
                                to={{
                                    screen: "PRODUCT_EDIT",
                                    params: { id: item.id },
                                }}
                                style={styles.childLink}
                            >
                                {item.name}
                            </Link>
                        </View>
                        <View>
                            <Text>{`${formatAmount(item.amount, currency, {
                                locale,
                            })}`}</Text>
                        </View>
                    </View>
                ))}
            </View>
        </Surface>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        marginBottom: theme.spacingScale * 2,
    },
    headline: {
        marginBottom: 0,
    },
    itemRow: {
        flexDirection: "row",
        paddingHorizontal: theme.spacingScale,
        paddingVertical: theme.spacingScale / 2,
    },
    oddItemRow: {
        backgroundColor: theme.colors.grey100,
    },
    itemColumn1: {
        flex: 1,
    },
    childLink: {
        ...theme.fonts.regular,
        fontSize: 16,
        textDecorationLine: "underline",
        color: theme.colors.secondary,
        lineHeight: 24,
    },
});
