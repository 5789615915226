import type { StyleFunction } from "../../theme";
import { StyleSheet } from "react-native";

export const pickerStyleFunc: StyleFunction = theme => ({
    picker: {
        paddingLeft: 6,
        paddingRight: 6,
        borderRadius: theme.borderRadiusSmall,
        backgroundColor: theme.colors.white,
        width: "100%",
        borderWidth: StyleSheet.hairlineWidth,
        marginBottom: theme.spacingScale,
        minHeight: 40,
        borderColor: theme.colors.secondary,
    },
    text: {
        ...theme.styles.input,
    },
    disabled: {
        backgroundColor: theme.colors.grey50,
        borderColor: theme.colors.grey500,
        color: theme.colors.grey800,
    },
});
