import {
    StyleFunction,
    Surface,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import { useMiscButtonsCreateMutation } from "graphql-sdk";
import { schemaMiscButton } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { defaultMiscButtonsForm, MiscButtonsForm } from "../forms";
import { MiscButtonForm } from "../misc-button-form";
import { MiscButtonScreen } from "../misc-button-screen";

type ScreenProps = RootStackScreenProps<"MISC_BUTTON_CREATE">;

export function MiscButtonCreateScreen({
    navigation: { navigate },
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.misc_button.write");

    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [miscButtonCreate] = useMiscButtonsCreateMutation();
    const { handleMutationError } = useHandleMutationError();

    const form = useForm<MiscButtonsForm>(
        schemaMiscButton,
        defaultMiscButtonsForm
    );
    const [{ values }, { reset }] = form;

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await handleMutationError(
            async () =>
                await miscButtonCreate({
                    variables: {
                        miscButtons: {
                            name: values.name,
                            buttons: JSON.stringify(values.buttons),
                        },
                    },
                }),
            t(
                "backoffice.misc_button.created",
                "Function Key Configuration Created"
            ),
            () => {
                reset();
                navigate("MISC_BUTTONS", { refetch: true });
            }
        );
    }, [values, handleMutationError, t, miscButtonCreate, reset, navigate]);

    return (
        <MiscButtonScreen>
            <Surface>
                <View style={styles.box}>
                    <View style={styles.container}>
                        <MiscButtonForm
                            form={form}
                            onSubmit={create}
                            submitButton={["common.create", "Create"]}
                        />
                    </View>
                </View>
            </Surface>
        </MiscButtonScreen>
    );
}
const styleFunc: StyleFunction = theme => {
    return {
        box: {
            width: "100%",
            backgroundColor: theme.colors.white,
        },
        container: {
            width: "100%",
            alignSelf: "flex-start",
        },
    };
};
