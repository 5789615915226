import React, { useCallback, useState } from "react";
import { SubFormBox, SubFormBoxInactive } from "./sub-form-box";
import {
    assignOrderCondition,
    ConditionAssignment,
    ConditionLogicOperator,
    Conditions,
    OrderConditions,
} from "lib";
import type { GQDiscountFormQuery } from "graphql-sdk";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { produce } from "immer";
import { Loading, RemovableRow } from "@venuepos/react-common";
import { TagConditionInput } from "./tag-condition-input";

export function TagConditionsForm({
    conditionsLogicOperator,
    onConditionsLogicOperatorChange,
    conditions,
    onConditionsChange,
    formData,
}: {
    conditionsLogicOperator: ConditionLogicOperator;
    onConditionsLogicOperatorChange: (
        logicOperator: ConditionLogicOperator
    ) => void;
    conditions: Conditions;
    onConditionsChange: (conditions: Conditions) => void;
    formData: GQDiscountFormQuery;
}) {
    const [keyPrefix, setKeyPrefix] = useState(uuidv4());
    const [t] = useTranslation();

    const handleConditionChange = useCallback(
        (key: number, condition: ConditionAssignment) => {
            onConditionsChange(
                produce(conditions, draft => {
                    draft[key] = condition;
                    return draft;
                })
            );
        },
        [conditions, onConditionsChange]
    );

    const addCondition = useCallback(() => {
        onConditionsChange(
            produce(conditions, draft => {
                // First try and add default tag group
                const defaultTagGroup = formData.tagGroups.data.find(
                    tagGroupItr => {
                        return (
                            conditions.find(
                                itr =>
                                    (itr.args as OrderConditions["hasTagGroup"])
                                        .tagGroupId === tagGroupItr.id
                            ) === undefined
                        );
                    }
                );

                if (defaultTagGroup !== undefined) {
                    draft.push(
                        assignOrderCondition("hasTagGroup", {
                            tagGroupId: defaultTagGroup.id,
                        })
                    );
                    return;
                }

                // If there is no default tag group then add default tag
                const defaultTag = formData.tags.data.find(tagItr => {
                    return (
                        conditions.find(
                            itr =>
                                (itr.args as OrderConditions["hasTag"])
                                    .tagId === tagItr.id
                        ) === undefined
                    );
                });

                if (defaultTag !== undefined) {
                    draft.push(
                        assignOrderCondition("hasTag", {
                            tagId: defaultTag.id,
                        })
                    );
                    return;
                }
            })
        );
    }, [conditions, formData, onConditionsChange]);

    const removeCondition = useCallback(
        (conditionKey: number) => {
            setKeyPrefix(uuidv4());
            onConditionsChange(
                produce(conditions, draft => {
                    draft.splice(conditionKey, 1);
                })
            );
        },
        [conditions, onConditionsChange]
    );

    if (!formData || !formData) {
        return <Loading />;
    }

    const tagConditions = conditions.filter(
        itr => itr.condition === "hasTag" || itr.condition === "hasTagGroup"
    );

    if (tagConditions.length === 0) {
        return (
            <SubFormBoxInactive
                text={t(
                    "backoffice.discount_form.add_tag_condition",
                    "Add tag condition"
                )}
                onPress={() => {
                    addCondition();
                }}
            />
        );
    }

    const hasUnusedTagConditions = !!formData.tagGroups.data.find(
        groupItr =>
            !tagConditions.find(
                conditionItr =>
                    conditionItr.condition === "hasTagGroup" &&
                    (conditionItr.args as OrderConditions["hasTagGroup"])
                        .tagGroupId === groupItr.id
            )
    );

    const hasUnusedTagGroupConditions = !!formData.tags.data.find(
        tagItr =>
            !tagConditions.find(
                conditionItr =>
                    conditionItr.condition === "hasTag" &&
                    (conditionItr.args as OrderConditions["hasTag"]).tagId ===
                        tagItr.id
            )
    );

    const hasUnusedConditions =
        hasUnusedTagConditions || hasUnusedTagGroupConditions;

    return (
        <SubFormBox
            headline={t(
                "backoffice.discount_form.tag_conditions",
                "Tag conditions"
            )}
            onAddCondition={addCondition}
            hasUnusedConditions={hasUnusedConditions}
            conditionsLogicOperator={conditionsLogicOperator}
            onConditionsLogicOperatorChange={onConditionsLogicOperatorChange}
        >
            {tagConditions.map((condition, key) => (
                <RemovableRow
                    key={key + keyPrefix}
                    onRemove={() => {
                        removeCondition(key);
                    }}
                >
                    <TagConditionInput
                        condition={condition}
                        conditions={tagConditions}
                        onConditionChange={newCondition =>
                            handleConditionChange(key, newCondition)
                        }
                        formData={formData}
                    />
                </RemovableRow>
            ))}
        </SubFormBox>
    );
}
