import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { RequiredText, StyleFunction } from "@venuepos/react-common";
import {
    Button,
    Form,
    InputControl,
    NumberInput,
    Picker,
    Surface,
    TextInput,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQAccountCreate, useVatsQuery } from "graphql-sdk";
import type { AssignTagsModalProps } from "../../../tags/assign-tags-modal/assign-tags-modal";
import { parseAmount } from "lib";

export function CreateForm(props: {
    id: string | undefined;
    form: Form<GQAccountCreate>;
    onSubmit: () => void;
    submitButton: [string, string];
    isGiftcard: boolean;
    enabledTags?: AssignTagsModalProps["enabledTags"];
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;

    const merchantConfig = useMerchantConfig();
    const { data: vatsData, loading: vatsLoading } = useVatsQuery();

    if (vatsLoading || !values) {
        return null;
    }

    return (
        <View style={styles.formBox}>
            <Surface>
                <InputControl error={errors.name}>
                    <TextInput
                        label={t("common.name", "Name")}
                        placeholder={t(
                            "backoffice.common.enter_name",
                            "Enter name"
                        )}
                        defaultValue={values.name}
                        onChangeText={text => setValue("name", text)}
                        testID="account:name"
                        required={true}
                    />
                </InputControl>

                <InputControl error={errors.status}>
                    <Picker
                        label={t("common.status", "Status")}
                        onValueChange={value => {
                            setValue("status", value);
                        }}
                        selectedValue={values.status || ""}
                        testID="account:status"
                        required={true}
                    >
                        <Picker.Item
                            label={t(
                                "backoffice.account.choose_status",
                                "Choose status"
                            )}
                            value=""
                        />
                        <Picker.Item
                            key="OPEN"
                            value="OPEN"
                            label={t("backoffice.account.status.open", "Open")}
                            testID="open"
                        />
                        <Picker.Item
                            key="CLOSED"
                            value="CLOSED"
                            label={t(
                                "backoffice.account.status.closed",
                                "Closed"
                            )}
                            testID="closed"
                        />
                    </Picker>
                </InputControl>

                <InputControl
                    error={errors.amount}
                    description={
                        props.isGiftcard
                            ? t(
                                  "backoffice.account.account_amount_description",
                                  "The balance should be at least {{minAmount}}.",
                                  { replace: { minAmount: 0 } }
                              )
                            : undefined
                    }
                >
                    <NumberInput
                        label={t("backoffice.account.amount", "Amount")}
                        placeholder={t(
                            "backoffice.account.enter_amount",
                            "Enter amount"
                        )}
                        defaultValue={values.amount / 100}
                        onChange={value =>
                            setValue("amount", parseAmount(value || 0))
                        }
                        decimals={2}
                        unit={merchantConfig.currency}
                        min={props.isGiftcard ? 0 : undefined}
                        testID="account:amount"
                    />
                </InputControl>

                <InputControl
                    error={errors.externalId}
                    description={t(
                        "backoffice.account.external_id_description",
                        "Number or ID that is used by another system. Used for reference."
                    )}
                >
                    <TextInput
                        label={t("common.external_id", "External ID")}
                        placeholder={t(
                            "backoffice.common.enter_external_id",
                            "Enter external identifier"
                        )}
                        defaultValue={values.externalId ?? ""}
                        onChangeText={text => setValue("externalId", text)}
                        testID="account:externalId"
                    />
                </InputControl>

                {props.isGiftcard && (
                    <InputControl error={errors.vatId}>
                        <Picker
                            label={t(
                                "backoffice.account.vat",
                                "VAT percentage"
                            )}
                            onValueChange={value => {
                                setValue("vatId", value);
                            }}
                            selectedValue={values.vatId || undefined}
                            testID="account:vat"
                            required={true}
                        >
                            <Picker.Item
                                label={t(
                                    "backoffice.account.choose_vat",
                                    "Choose VAT"
                                )}
                                value=""
                            />
                            {vatsData!.vats.data.map(vat => {
                                return (
                                    <Picker.Item
                                        key={vat.id}
                                        value={vat.id}
                                        label={vat.name}
                                        testID={"vat:" + vat.name}
                                    />
                                );
                            })}
                        </Picker>
                    </InputControl>
                )}
                <Button
                    onPress={handleSubmit(props.onSubmit)}
                    testID="account:create"
                >
                    {t(props.submitButton[0], props.submitButton[1])}
                </Button>
                <RequiredText />
            </Surface>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    formBox: {
        maxWidth: theme.dimensions.maxFormWidth,
    },
    assignTagsButton: {
        marginBottom: 20,
    },
});
