import {
    AuthLoginDocument,
    AuthSelectUserRoleDocument,
    AuthUpdateLoginDocument,
    GQAuthLoginMutation,
    GQAuthSelectUserRoleMutation,
    GQAuthUpdateLoginMutation,
} from "graphql-sdk";
import { useMemo } from "react";
import { useAuthSession } from "./session";
import { useMeLazy } from "./use-me";
import { IRole, roleInScope, RolePermissions } from "lib";
import { useApolloClient } from "@apollo/client";
import { localizeAuthToken } from "./localize-auth-token";

export type AuthSignInAction = (
    username: string,
    password: string
) => Promise<boolean>;

export type AuthSelectUserRoleAction = (
    roleId: string,
    merchantId?: string
) => void;

export type AuthActions = {
    signIn: AuthSignInAction;
    signOut: () => void;
    resignIn: () => Promise<boolean>;
    selectUserRole: (roleId: string, merchantId?: string) => Promise<void>;
    isOfRoleType: (type: IRole["type"]) => boolean;

    /**
     * Returns true if the selected role for the current user
     * is authorized to do the action given by `key`.
     *
     * `key` can both be used as a string or an array
     *  - if used as a string, only that permission will be checked
     *  - if used as an array, the outcome then depends on the chosen logical operator, which by default is `AND`
     *      - `AND`, all permissions needed
     *      - `OR`, only one permission needed
     *
     * Usage example
     *  context.may("user.write"); # Will return true if user has `user.write` enabled
     *  context.may(["user.write", "user.delete"]); # Will return true if user has both `user.write` and `user.delete` are enabled
     *  context.may(["user.write", "user.delete"], "OR"); # Will return true if user has either `user.write` or `user.delete` are enabled
     */
    may: (
        key: keyof RolePermissions | (keyof RolePermissions)[],
        logicOperator?: "AND" | "OR"
    ) => boolean;

    /**
     * Throws an authorization error if the selected role for
     * the current user is not authorized to do the action given by `key`
     *
     * Usage example
     *  context.enforce("user.write"); # Will throw if user has `user.write` isn't enabled
     *  context.enforce(["user.write", "user.delete"]); # Will throw if not both `user.write` and `user.delete` are enabled for the user
     *  context.enforce(["user.write", "user.delete"], "OR"); # Will throw if neither `user.write` or `user.delete` are enabled for the user
     */
    enforce: (
        key: keyof RolePermissions | (keyof RolePermissions)[],
        logicOperator?: "AND" | "OR"
    ) => void;
};

export function useAuth(): AuthActions {
    const [{ load: loadMe, set: setMe }, me] = useMeLazy();
    const [, setSessionValue] = useAuthSession();
    const gqlClient = useApolloClient();

    return useMemo<AuthActions>(() => {
        function may(
            key: keyof RolePermissions | (keyof RolePermissions)[],
            logicOperator?: "AND" | "OR"
        ) {
            if (!me || !me.role) {
                return false;
            }

            if (typeof key === "string") {
                const permission = me.role.permissions.find(p => p.key === key);
                return (
                    permission !== undefined &&
                    permission.value === "enabled" &&
                    roleInScope(key, me.role)
                );
            } else {
                switch (logicOperator) {
                    case undefined:
                    case "AND":
                        for (let i = 0; i < key.length; i++) {
                            const permission = me.role.permissions.find(
                                p => p.key === key[i]
                            );
                            if (
                                !permission ||
                                permission.value === "disabled" ||
                                (permission.value === "enabled" &&
                                    !roleInScope(key[i], me.role))
                            ) {
                                return false;
                            }
                        }
                        return true;

                    case "OR":
                        for (let i = 0; i < key.length; i++) {
                            const permission = me.role.permissions.find(
                                p => p.key === key[i]
                            );
                            if (
                                !!permission &&
                                permission.value === "enabled" &&
                                roleInScope(key[i], me.role)
                            ) {
                                return true;
                            }
                        }
                        return false;
                }
            }
        }

        function enforce(
            key: keyof RolePermissions | (keyof RolePermissions)[],
            logicOperator?: "AND" | "OR"
        ) {
            if (!may(key, logicOperator)) {
                throw new Error("You're not authorized to do this action");
            }
        }

        return {
            signIn: async (username: string, password: string) => {
                try {
                    const result = await gqlClient.mutate<GQAuthLoginMutation>({
                        mutation: AuthLoginDocument,
                        variables: {
                            username: username,
                            password: password,
                        },
                    });

                    if (
                        !!result.data &&
                        result.data.authLogin.successful &&
                        result.data.authLogin.token
                    ) {
                        setSessionValue(
                            "authToken",
                            localizeAuthToken(result.data.authLogin.token)
                        );

                        return true;
                    }
                } catch {
                    // we should ignore any errors and just return false,
                    // as we just want this method to act as an attempt to sign in
                }
                return false;
            },
            signOut: () => {
                setSessionValue("authToken", undefined);
                setMe(undefined);
            },
            resignIn: async () => {
                const result =
                    await gqlClient.mutate<GQAuthUpdateLoginMutation>({
                        mutation: AuthUpdateLoginDocument,
                    });

                if (
                    result.data &&
                    result.data.authUpdateLogin.successful &&
                    result.data.authUpdateLogin.token
                ) {
                    setSessionValue(
                        "authToken",
                        localizeAuthToken(result.data.authUpdateLogin.token)
                    );

                    try {
                        await loadMe();
                    } catch {
                        // do nothing
                    }

                    return true;
                } else {
                    return false;
                }
            },
            selectUserRole: async (roleId: string, merchantId?: string) => {
                const result =
                    await gqlClient.mutate<GQAuthSelectUserRoleMutation>({
                        mutation: AuthSelectUserRoleDocument,
                        variables: {
                            roleId,
                            merchantId,
                        },
                    });

                // If we already have a me-context, we should sign the user out
                if (me) {
                    setSessionValue("authToken", undefined);
                    setMe(undefined);
                }

                if (result.data) {
                    // `ProvideAuth` will load `me` when auth token has been set
                    setSessionValue(
                        "authToken",
                        result.data.authSelectUserRole.token
                    );
                }
            },
            may,
            enforce,
            isOfRoleType: (type: IRole["type"]) => {
                return me?.role?.type === type;
            },
        };
    }, [gqlClient, loadMe, me, setMe, setSessionValue]);
}
