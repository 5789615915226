import { defaultMiscButtons, IMiscButtons } from "lib";
import type { GQMiscButtonsInput } from "graphql-sdk";

export type MiscButtonsForm = {
    name: IMiscButtons["name"];
    buttons: IMiscButtons["buttons"];
};

export const defaultMiscButtonsForm: GQMiscButtonsInput = {
    name: "",
    buttons: defaultMiscButtons as any,
};
