import {
    StyleFunction,
    Surface,
    useAuth,
    useForm,
    useMe,
    useThemedStyle,
} from "@venuepos/react-common";
import { useIntegrationCreateMutation } from "graphql-sdk";
import { IIntegrationInput, schemaIntegrationInput } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { defaultIntegration } from "../forms";
import { GeneralForm } from "../general-form";
import { IntegrationScreen } from "../integration-screen";

type ScreenProps = RootStackScreenProps<"INTEGRATION_EDIT">;

export function CreateIntegrationScreen({
    navigation: { navigate },
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.merchant_settings");

    const [t] = useTranslation();
    const [integrationCreate] = useIntegrationCreateMutation();
    const { handleMutationError } = useHandleMutationError();

    const styles = useThemedStyle(styleFunc);
    const form = useForm<IIntegrationInput>(
        schemaIntegrationInput,
        defaultIntegration
    );
    const [{ values }] = form;

    const me = useMe();

    const create = useCallback(async () => {
        if (
            !values ||
            !me ||
            !me.merchant ||
            !me.merchant.id ||
            values.type === undefined
        ) {
            return;
        }
        await handleMutationError(
            async () => {
                await integrationCreate({
                    variables: {
                        integration: {
                            merchantId: me.merchant!.id,
                            type: values.type!,
                            active: values.active,
                            name: values.name,
                            apiKey: values.apiKey,
                        },
                    },
                });
            },
            t("common.created", "Created"),
            () => {
                navigate("INTEGRATIONS");
            }
        );
    }, [values, handleMutationError, t, integrationCreate, navigate, me]);

    return (
        <IntegrationScreen>
            <Surface>
                <View style={styles.container}>
                    <GeneralForm
                        form={form}
                        onSubmit={create}
                        submitButton={["common.create", "Create"]}
                        pickerDisabled={false}
                    />
                </View>
            </Surface>
        </IntegrationScreen>
    );
}

const styleFunc: StyleFunction = theme => {
    return {
        container: {
            width: "100%",
            maxWidth: theme.dimensions.maxFormWidth,
            alignSelf: "flex-start",
        },
    };
};
