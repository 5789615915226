import React, {
    createContext,
    useContext,
    ReactNode,
    useEffect,
    useState,
    useCallback,
} from "react";
import type { IToast } from ".";
import { produce } from "immer";
import { Animated, Platform } from "react-native";
import { useTheme } from "../../theme";

const DEFAULT_EXPIRE_SEC = 5;

let toastIdCounter: number = 0;
const ToastContext = createContext<IToastContext | null>(null);

type ToastFunc = (message: string, duration?: number) => void;

export interface IToastContext {
    info: ToastFunc;
    warning: ToastFunc;
    error: ToastFunc;
    success: ToastFunc;
    onRemove: (toastId: string) => void;
    stack: IToast[];
}

const defaultFunc = () => {
    // deliberately empty
};

export function useToast(): IToastContext {
    const context = useContext(ToastContext);

    // We want these callbacks to be available regardless of if context is ready
    return {
        stack: context ? context.stack : [],
        info: context ? context.info : defaultFunc,
        warning: context ? context.warning : defaultFunc,
        error: context ? context.error : defaultFunc,
        success: context ? context.success : defaultFunc,
        onRemove: context ? context.onRemove : defaultFunc,
    };
}

export function ProvideToast({ children }: { children: ReactNode }) {
    const [stack, setStack] = useState<IToast[]>([]);
    const { colors } = useTheme();

    const remove = useCallback(
        (toastId: string) => {
            setStack(
                produce((draft: IToast[]) => {
                    const idx = stack.findIndex(i => i.id === toastId);
                    if (idx > -1) {
                        draft.splice(idx, 1);
                    }
                })
            );
        },
        [stack]
    );
    // Cleanup mechanism
    useEffect(() => {
        if (stack.length === 0) {
            // No need for a timer if we're empty
            return;
        }
        const interval = setInterval(() => {
            const currentTime = Math.round(new Date().getTime() / 1000);
            const expiredItems = stack.filter(
                item => item.expires && currentTime > item.expires
            );
            expiredItems.forEach(item => {
                if (item) {
                    Animated.timing(item.animated, {
                        toValue: 0,
                        duration: 100,
                        useNativeDriver: Platform.OS !== "web",
                    }).start(() => {
                        remove(item.id);
                    });
                }
            });
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [stack, remove]);

    function add(toast: Partial<IToast>) {
        if (!toast) {
            throw new Error("toast object cannot be empty");
        }

        // If we already have the same message at the bottom of the stack do not add another.
        if (
            stack.length > 0 &&
            stack.slice(0, 1)[0].message === toast.message
        ) {
            return;
        }

        const expireTime =
            Math.round(new Date().getTime() / 1000) +
            (toast.expires || DEFAULT_EXPIRE_SEC);
        const ani = new Animated.Value(0);
        const nextToastId = toastIdCounter++;
        setStack(
            produce(draft => {
                draft.unshift({
                    ...toast,
                    id: nextToastId.toString(),
                    expires: expireTime,
                    animated: ani,
                } as IToast);
            })
        );
        Animated.timing(ani, {
            toValue: 1,
            duration: 200,
            useNativeDriver: Platform.OS !== "web",
        }).start();
    }

    function info(message: string, duration?: number) {
        add({
            message,
            type: "default",
            iconName: "info",
            iconColor: colors.black,
            expires: duration,
        });
    }

    function warning(message: string, duration?: number) {
        add({
            message,
            type: "warning",
            iconName: "warning",
            iconColor: colors.white,
            expires: duration,
        });
    }

    function success(message: string, duration?: number) {
        add({
            message,
            type: "success",
            iconName: "success",
            iconColor: colors.white,
            expires: duration,
        });
    }

    function error(message: string, duration?: number) {
        add({
            message,
            type: "error",
            iconName: "unknown",
            iconColor: colors.white,
            expires: duration,
        });
    }

    return (
        <ToastContext.Provider
            value={{ info, warning, success, error, onRemove: remove, stack }}
        >
            {children}
        </ToastContext.Provider>
    );
}
