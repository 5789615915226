import { ApolloClient, useApolloClient } from "@apollo/client";
import {
    HeaderBackButton,
    HeaderBackButtonProps,
} from "@react-navigation/elements";
import { DrawerActions, useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import {
    AuthActions,
    Icon,
    IconButton,
    MeType,
    PERMANENT_DRAWER_WIDTH,
    StyleFunction,
    Text,
    useAuth,
    useDimensions,
    useMe,
    useRoutePropsFromRouteConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import React, { useCallback, useMemo, useState } from "react";
import { View } from "react-native";
import { Button as PaperButton, Divider, Menu } from "react-native-paper";
import { lighten } from "color2k";

import { RootStackParamList as RootStackParamList } from ".";
import { routeConfig } from "../screens/navigator-routes";

const Stack = createStackNavigator<RootStackParamList>();

type RightHeaderProps = {
    loggedInUser: MeType;
    client: ApolloClient<object>;
    auth: AuthActions;
};

export function StackScreen() {
    const me = useMe();
    const auth = useAuth();
    const client = useApolloClient();
    const screens = useRoutePropsFromRouteConfig(routeConfig);
    const styles = useThemedStyle(styleFunc);
    const envName: string = process.env.VP_ENV || "development";

    const headerLeft = useCallback(
        props => <LeftHeader buttonProps={props} />,
        []
    );

    const headerRight = useCallback(
        () => <RightHeader loggedInUser={me} client={client} auth={auth} />,
        [auth, client, me]
    );

    // Color the topbar differently on development and test
    let headerStyle = {};
    if (envName === "development") {
        headerStyle = styles.developmentHeader;
    } else if (envName === "test.env.venuepos.net") {
        headerStyle = styles.testHeader;
    }

    return (
        <Stack.Navigator
            screenOptions={{
                headerLeft,
                headerRight,
                headerStyle,
            }}
        >
            {screens.map(screen => (
                <Stack.Screen key={screen.name} {...screen} />
            ))}
        </Stack.Navigator>
    );
}

function RightHeader({ loggedInUser, client, auth }: RightHeaderProps) {
    const styles = useThemedStyle(styleFunc);
    const [visible, setVisible] = useState(false);
    const [t] = useTranslation();
    const { navigate } = useNavigation();

    const closeMenu = () => {
        setVisible(false);
    };

    const openMenu = () => {
        setVisible(true);
    };

    const signOut = useCallback(async () => {
        client.stop();
        await client.clearStore();
        auth.signOut();

        // We need to reset the URL, or else the next signed user might
        // end up on a page that they don't have access to.
        window.open("/", "_self");
    }, [client, auth]);

    const userIcon = useCallback(
        () => <Icon style={styles.icon} name="user" />,
        [styles.icon]
    );

    const signOutIcon = useCallback(
        () => <Icon style={styles.icon} name="signOut" />,
        [styles.icon]
    );

    return (
        <View style={styles.rightHeader}>
            <Text style={styles.merchantName}>
                {loggedInUser.merchant?.name}
            </Text>

            <Menu
                visible={visible}
                onDismiss={closeMenu}
                contentStyle={styles.menuDropDown}
                anchor={
                    <PaperButton
                        onPress={openMenu}
                        style={styles.userDropDownButton}
                        testID="menu:user"
                    >
                        <Icon style={styles.icon} name="user" />
                        <Icon style={styles.icon} name="dropdown" />
                    </PaperButton>
                }
            >
                <Menu.Item
                    onPress={() => {
                        if (!loggedInUser.user) {
                            return;
                        }

                        navigate("USER_EDIT", {
                            id: loggedInUser.user.id,
                        });
                    }}
                    icon={userIcon}
                    title={`${loggedInUser.user?.username} / ${loggedInUser.role?.name}`}
                />
                <Divider />
                <Menu.Item
                    onPress={signOut}
                    icon={signOutIcon}
                    title={t("menu.sign_out", "Sign out")}
                    testID="menu:signOut"
                />
            </Menu>
        </View>
    );
}

function LeftHeader({
    buttonProps,
}: {
    buttonProps: HeaderBackButtonProps;
}): JSX.Element {
    const { dispatch } = useNavigation();
    const styles = useThemedStyle(styleFunc);
    const { width } = useDimensions();

    const backImage = useCallback(
        () => <Icon name="arrow-left" color={styles.icon.color} />,
        [styles.icon.color]
    );

    const menuButton = useMemo(() => {
        if (width > PERMANENT_DRAWER_WIDTH) {
            return null;
        }

        return (
            <IconButton
                name="menu"
                color={styles.icon.color}
                onPress={() => dispatch(DrawerActions.toggleDrawer())}
            />
        );
    }, [dispatch, styles.icon.color, width]);

    return (
        <View style={styles.leftHeader}>
            {menuButton}
            {buttonProps.canGoBack ? (
                <HeaderBackButton {...buttonProps} backImage={backImage} />
            ) : null}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    leftHeader: {
        flexDirection: "row",
        marginLeft: theme.spacingScale,
    },
    rightHeader: {
        marginRight: theme.spacingScale * 2,
        flexDirection: "row",
        alignItems: "baseline",
    },
    merchantName: {
        marginRight: theme.spacingScale,
        color: theme.colors.textDark,
    },
    userDropDownButton: {
        flexDirection: "row",
    },
    icon: {
        color: theme.colors.black,
    },
    developmentHeader: { backgroundColor: theme.colors.success },
    testHeader: { backgroundColor: lighten(theme.colors.success, 0.1) },
});
