import { useCallback } from "react";
import { parseError } from "lib";
import { useTranslation } from "react-i18next";
import { useToast } from "@venuepos/react-common";
import type { ApolloError } from "@apollo/client";

export function useHandleMutationError() {
    const [t] = useTranslation();
    const toast = useToast();

    const handleMutationError = useCallback(
        async (
            mutatorFunction: () => any,
            successMessage?: string,
            successCallback?: (args?: any) => void
        ) => {
            try {
                const result = await mutatorFunction();
                if (successMessage) {
                    toast.success(successMessage);
                }
                if (successCallback) {
                    successCallback(result);
                }
            } catch (mutatorError) {
                let response: string = "";
                const parsedError = parseError(mutatorError as ApolloError);

                switch (parsedError.type) {
                    case "VALIDATION":
                        response = t(
                            "backoffice.error.validation",
                            "An error occurred: Validation failed."
                        );
                        break;
                    case "NOT_UNIQUE":
                        response = t(
                            "backoffice.error.not_unique",
                            "An error occurred: These data are not unique: {{fields}}",
                            {
                                fields: parsedError.data.join(", "),
                            }
                        );
                        break;
                    case "IN_USE":
                        response = t(
                            "backoffice.error.in_use",
                            "An error occurred: Item is in use."
                        );
                        break;
                    case "NOT_FOUND":
                        response = t(
                            "backoffice.error.not_found",
                            "An error occurred: Item is not found."
                        );
                        break;
                    default:
                        response = t(
                            "backoffice.error.unknown",
                            "An unknown error occurred. Please contact the system administrator."
                        );
                }

                toast.error(response);
            }
        },
        [t, toast]
    );

    return { handleMutationError };
}
