import { Link } from "@react-navigation/native";
import {
    DataTable,
    IconButton,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import { formatAmount, formatDateTime, SupportedCurrenciesType } from "lib";
import React, { useCallback } from "react";

import type { GQAccountQuery } from "graphql-sdk";
import type { AvailableLocale } from "locales";
export const TransactionTableRow = React.memo(function TransactionTableRowFunc({
    item,
    locale,
    currency,
    onShowInvoice,
}: {
    item: GQAccountQuery["account"]["transactions"]["data"][0];
    locale: AvailableLocale;
    currency: SupportedCurrenciesType;
    onShowInvoice: (invoiceId: string) => void;
}) {
    const styles = useThemedStyle(styleFunc);

    const handleShowInvoice = useCallback(() => {
        if (!item.invoiceId) {
            return;
        }
        return onShowInvoice(item.invoiceId);
    }, [item.invoiceId, onShowInvoice]);

    return (
        <DataTable.Row>
            <DataTable.Cell>
                {formatDateTime(item.transactionAt, locale as AvailableLocale)}
            </DataTable.Cell>
            <DataTable.Cell>{item.user?.username}</DataTable.Cell>
            <DataTable.Cell>
                {item.tag && (
                    <Link
                        key={item.tag.id}
                        to={{ screen: "TAG_EDIT", params: { id: item.tag.id } }}
                        style={styles.textLink}
                    >
                        {item.tag.name || item.tag.identifier}
                    </Link>
                )}
            </DataTable.Cell>
            <DataTable.Cell numeric>
                {formatAmount(item.amount, currency, {
                    printCurrency: true,
                    locale: locale,
                })}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.iconColumn}>
                {item.invoiceId !== null && (
                    <IconButton
                        color={styles.textLink.color}
                        name="order"
                        onPress={handleShowInvoice}
                    />
                )}
            </DataTable.Cell>
        </DataTable.Row>
    );
});

const styleFunc: StyleFunction = theme => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
    },
    textLink: {
        ...theme.styles.link,
    },
});
