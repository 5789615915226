import { v4 as uuidv4 } from "uuid";
import type { TFunction } from "i18next";
import { LayoutSection } from "lib";

/**
 * Returns a new empty LayoutSection
 */
export function produceNewLayoutSection(
    t?: TFunction | string,
    postFix?: string
): LayoutSection {
    if (!t) {
        return {
            id: uuidv4(),
            label: "__NEW__",
            buttons: [],
        };
    }

    if (typeof t === "function") {
        return {
            id: uuidv4(),
            label: postFix
                ? `${t(
                      "backoffice.layout.unnamed_label",
                      "Unnamed"
                  )} ${postFix}`
                : t("backoffice.layout.unnamed_label", "Unnamed"),
            buttons: [],
        };
    }

    return {
        id: uuidv4(),
        label: postFix ? `${t} ${postFix}` : `${t}`,
        buttons: [],
    };
}
