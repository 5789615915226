import React from "react";
import { useTranslation } from "react-i18next";
import type { IMerchantInput } from "lib";
import {
    Form,
    Button,
    InputControl,
    TextInput,
    RequiredText,
} from "@venuepos/react-common";

export function EditForm(props: {
    form: Form<IMerchantInput>;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;

    if (!values) {
        return null;
    }

    return (
        <>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name}
                    onChangeText={text => setValue("name", text)}
                    required={true}
                />
            </InputControl>

            <Button onPress={handleSubmit(props.onSubmit)}>
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>

            <RequiredText />
        </>
    );
}
