import React, { ReactNode } from "react";
import { View, StyleSheet } from "react-native";

export function Row({ children }: { children: ReactNode }) {
    return <View style={styles.row}>{children}</View>;
}

const styles = StyleSheet.create({
    row: {
        flex: 1,
        flexDirection: "row",
    },
});
