import { useApolloClient } from "@apollo/client";
import {
    Alert,
    Button,
    Card,
    CheckBox,
    DateTimePickerInput,
    Headline,
    IconButton,
    InputControl,
    InputLabel,
    Loading,
    StyleFunction,
    useMe,
    useThemedStyle,
} from "@venuepos/react-common";
import { add, set } from "date-fns";
import {
    DashboardDocument,
    GQDashboard,
    GQDashboardQuery,
    useDepartmentsQuery,
    useSubscribeToDashboardSubscription,
    useUserRoleConfigurationQuery,
    useUserRoleConfigurationSaveMutation,
} from "graphql-sdk";
import produce from "immer";
import { dateToDateTime } from "lib";
import React, { useCallback, useEffect, useState } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { Divider, Menu } from "react-native-paper";
import Select, { MultiValue } from "react-select";
import { withSize } from "react-sizeme";
import { useHandleMutationError } from "../../hooks";
import { AdminContainer } from "../container";
import { dashboardStyleFunc } from "./styles";
import { Bookkeeping } from "./widgets/bookkeeping";
import { DepartmentSalesTop10 } from "./widgets/department-sales-top10";
import { InvoiceAverageTotal } from "./widgets/invoice-average-total";
import { InvoiceTotal } from "./widgets/invoice-total";
import { ProductGroupTop10 } from "./widgets/product-group-top10";
import { ProductSalesTop10 } from "./widgets/product-sales-top10";
import { TurnoverTotal } from "./widgets/turnover-total";

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(
    MerchantDashboardScreen
);

const allWidgets = [
    {
        id: "InvoiceTotal",
        component: (data: GQDashboard) => <InvoiceTotal data={data} />,
    },
    {
        id: "TurnoverTotal",
        component: (data: GQDashboard) => <TurnoverTotal data={data} />,
    },
    {
        id: "InvoiceAverageTotal",
        component: (data: GQDashboard) => <InvoiceAverageTotal data={data} />,
    },
    {
        id: "ProductSalesTop10",
        component: (data: GQDashboard) => <ProductSalesTop10 data={data} />,
    },
    {
        id: "DepartmentSalesTop10",
        component: (data: GQDashboard) => <DepartmentSalesTop10 data={data} />,
    },
    {
        id: "ProductGroupTop10",
        component: (data: GQDashboard) => <ProductGroupTop10 data={data} />,
    },
    {
        id: "Bookkeeping",
        component: (data: GQDashboard) => <Bookkeeping data={data} />,
    },
];

// Initial layout of the dashboard
const initialLayouts: ReactGridLayout.Layouts = {
    lg: [
        { i: "InvoiceTotal", x: 0, y: 0, w: 4, h: 6, minW: 2, minH: 5 },
        { i: "TurnoverTotal", x: 4, y: 0, w: 4, h: 6, minW: 2, minH: 6 },
        { i: "InvoiceAverageTotal", x: 8, y: 0, w: 4, h: 6, minW: 2, minH: 6 },
        { i: "ProductSalesTop10", x: 0, y: 3, w: 6, h: 13, minW: 2, minH: 7 },
        {
            i: "DepartmentSalesTop10",
            x: 6,
            y: 3,
            w: 6,
            h: 13,
            minW: 2,
            minH: 7,
        },
        { i: "ProductGroupTop10", x: 0, y: 8, w: 6, h: 13, minW: 2, minH: 7 },
        { i: "Bookkeeping", x: 6, y: 8, w: 6, h: 13, minW: 2, minH: 7 },
    ],
};

export function MerchantDashboardScreen() {
    const graphQlClient = useApolloClient();
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const sharedStyles = useThemedStyle(dashboardStyleFunc);
    const me = useMe();
    const { handleMutationError } = useHandleMutationError();

    const [dashboardData, setDashboardData] = useState<GQDashboard>();
    const [fromDate, setFromDate] = useState<Date>(today());
    const [toDate, setToDate] = useState<Date>(nextDay(fromDate));

    // Contains the widgets shown on the dashboard
    const [widgets, setWidgets] = useState(allWidgets);
    // Contains the layout of the dashboard
    const [layouts, setLayouts] =
        useState<ReactGridLayout.Layouts>(initialLayouts);
    // Used for storing the widgets from before editing. If the user cancels editing this will be used as widgets again
    const [originalWidgets, setOriginalWidgets] = useState(allWidgets);
    // Used for storing the layout from before editing. If the user cancels editing this will be used as layout again
    const [originalLayouts, setOriginalLayouts] =
        useState<ReactGridLayout.Layouts>();
    const [isEditing, setIsEditing] = useState(false);
    const [width, setWidth] = useState(0);

    const [widgetMenuVisible, setWidgetMenuVisible] = useState(false);
    const [departmentIds, setDepartmentIds] = useState<string[]>([]);
    const [configurationLoaded, setConfigurationLoaded] = useState(false);

    const {
        loading: liveLoading,
        data: liveData,
        error: liveError,
    } = useSubscribeToDashboardSubscription({
        variables: {
            from: fromDate,
            to: toDate,
            departmentIds,
        },
        shouldResubscribe: true,
        // Skip subscription if query is loading. Apollo becomes cranky if we run this at the same time
        skip: !dashboardData,
    });

    const { data: departmentsData } = useDepartmentsQuery({
        variables: {
            pagination: {
                page: 0,
                pageSize: 999999,
                sort: "name",
                sortDirection: "ASC",
            },
        },
        fetchPolicy: "network-only",
    });

    const { data: userRoleData } = useUserRoleConfigurationQuery({
        variables: {
            userId: me.user!.id,
            merchantId: me.merchant!.id,
            roleId: me.role!.id,
            type: "merchantDashboard",
        },
    });
    const [userRoleConfigurationSave] = useUserRoleConfigurationSaveMutation();

    // When configuration has been loaded, update the widgets, layout and departments
    useEffect(() => {
        if (
            !configurationLoaded &&
            userRoleData &&
            userRoleData.userRoleConfiguration
        ) {
            const json = JSON.parse(userRoleData.userRoleConfiguration.data);
            setLayouts(json.layout);
            setDepartmentIds(json.departmentIds);
            const loadedWidgets: {
                id: string;
                component: (data: GQDashboard) => JSX.Element;
            }[] = [];

            for (let i = 0; i < json.layout.lg.length; i++) {
                const widget = allWidgets.find(
                    itr => itr.id === json.layout.lg[i].i
                );
                if (widget) {
                    loadedWidgets.push(widget);
                }
            }
            if (loadedWidgets && loadedWidgets.length > 0) {
                setWidgets(loadedWidgets);
            }
            setConfigurationLoaded(true);
        }
    }, [configurationLoaded, userRoleData, widgets]);

    useEffect(() => {
        if (!liveLoading && liveData?.dashboardUpdated) {
            if (toDate < new Date()) {
                return; // It's the past man, no need to update live data
            }
            setDashboardData(liveData.dashboardUpdated as GQDashboard);
            return;
            // comes first
        }
    }, [liveData, toDate, liveLoading]);

    const fetchDashboardData = useCallback(
        (from: Date, to: Date, depIds: string[]) => {
            graphQlClient
                .query<GQDashboardQuery>({
                    query: DashboardDocument,
                    fetchPolicy: "no-cache",
                    variables: {
                        from,
                        to,
                        departmentIds: depIds,
                    },
                })
                .then(result => {
                    if (result.data.dashboard) {
                        setDashboardData(result.data.dashboard);
                    }
                });
        },
        [graphQlClient]
    );

    useEffect(() => {
        if (!dashboardData) {
            fetchDashboardData(fromDate, toDate, departmentIds);
        }
    }, [dashboardData, departmentIds, fetchDashboardData, fromDate, toDate]);

    useEffect(() => {
        if (!isEditing) {
            fetchDashboardData(fromDate, toDate, departmentIds);
        }
    }, [departmentIds, fetchDashboardData, fromDate, isEditing, toDate]);

    const handleDateChange = useCallback(
        dateObj => {
            if (dateObj === undefined) {
                return;
            }

            const from = today(dateObj);
            const to = nextDay(dateObj);

            setFromDate(from);
            setToDate(to);

            fetchDashboardData(from, to, departmentIds);
        },
        [departmentIds, fetchDashboardData]
    );

    const handleEditing = useCallback(() => {
        setOriginalWidgets(widgets);
        setOriginalLayouts(layouts);
        setIsEditing(!isEditing);
    }, [isEditing, layouts, widgets]);

    const handleDepartmentChange = useCallback(
        (
            newValue: MultiValue<{
                value: string;
                label: string;
            }>
        ) => {
            setDepartmentIds(newValue.map(itr => itr.value));
        },
        []
    );

    const handleLayoutChange = (
        _currentLayout: ReactGridLayout.Layout[],
        allLayouts: ReactGridLayout.Layouts
    ) => {
        setLayouts(allLayouts);
    };

    const handleLayoutSave = useCallback(async () => {
        setIsEditing(false);

        await handleMutationError(
            async () =>
                await userRoleConfigurationSave({
                    variables: {
                        userId: me.user!.id,
                        merchantId: me.merchant!.id,
                        roleId: me.role!.id,
                        type: "merchantDashboard",
                        configuration: JSON.stringify({
                            departmentIds: departmentIds,
                            layout: layouts,
                        }),
                    },
                }),
            t("common.saved", "Saved")
        );
    }, [
        departmentIds,
        handleMutationError,
        layouts,
        me.merchant,
        me.role,
        me.user,
        t,
        userRoleConfigurationSave,
    ]);

    const handleLayoutCancel = () => {
        setIsEditing(false);
        setWidgets(originalWidgets);
        if (originalLayouts) {
            setLayouts(originalLayouts);
        }
    };

    const handleRemoveWidget = (itemId: string) => {
        setWidgets(widgets.filter(i => i.id !== itemId));
    };

    const handleAddWidget = (itemId: string) => {
        setWidgets([...widgets, allWidgets.find(itr => itr.id === itemId)!]);
        const addLayout = initialLayouts.lg.find(itr => itr.i === itemId);
        setLayouts(
            produce(layouts, draft => {
                draft.lg.push(addLayout!);
            })
        );
    };

    if (liveError) {
        return <Alert type="error">{`Error: ${liveError.message}`}</Alert>;
    }

    if (!dashboardData) {
        return (
            <AdminContainer>
                <Loading message={t("common.loading", "Loading")} />
            </AdminContainer>
        );
    }

    return (
        <AdminContainer>
            <View
                style={sharedStyles.topRow}
                testID="merchantDashboard"
                onLayout={event => {
                    setWidth(event.nativeEvent.layout.width);
                }}
            >
                <View>
                    <DateTimePickerInput
                        dateTimeType="date"
                        label={t("common.date", "Date")}
                        onChangeDate={handleDateChange}
                        value={dateToDateTime(fromDate)}
                        style={sharedStyles.dateTimePicker}
                    />
                </View>
                <View>
                    <Button onPress={handleEditing} disabled={isEditing}>
                        {t("common.edit", "Edit")}
                    </Button>
                </View>
            </View>
            {isEditing && (
                <Card style={styles.topBar}>
                    <InputControl
                        description={t(
                            "dashboard.widgets.department_description",
                            "Select department(s) to show. If no department is selected all departments is shown"
                        )}
                    >
                        <InputLabel>
                            {t("common.department", "Department")}
                        </InputLabel>
                        <Select
                            isMulti={true}
                            hideSelectedOptions={false}
                            options={
                                // Map the departments to the format for the select
                                departmentsData?.departments.data.map(item => {
                                    return {
                                        value: item.id,
                                        label: item.name,
                                    };
                                })
                            }
                            defaultValue={
                                // Filter for only the selected departments from configuration
                                departmentsData?.departments.data
                                    .filter(itr =>
                                        departmentIds.includes(itr.id)
                                    )
                                    // Map the departments to the format for the select
                                    .map(item => {
                                        return {
                                            value: item.id,
                                            label: item.name,
                                        };
                                    }) || []
                            }
                            onChange={handleDepartmentChange}
                            theme={theme => ({
                                ...theme,
                                ...styles.select,
                            })}
                        />
                    </InputControl>
                    <Menu
                        visible={widgetMenuVisible}
                        onDismiss={() => setWidgetMenuVisible(false)}
                        anchor={
                            <Button
                                style={styles.button}
                                onPress={() => setWidgetMenuVisible(true)}
                            >
                                {t("dashboard.widgets.header", "Widgets")}
                            </Button>
                        }
                    >
                        <Headline size="h5" style={styles.layoutHeader}>
                            {t("dashboard.widgets.header", "Widgets")}
                        </Headline>

                        <Divider />
                        {allWidgets.map(({ id }) => {
                            return (
                                <CheckBox
                                    key={`checkbox:${id}`}
                                    style={styles.checkboxItem}
                                    label={t("dashboard.names." + id, id)}
                                    value={
                                        widgets.find(itr => itr.id === id) !==
                                        undefined
                                    }
                                    onValueChange={value => {
                                        if (value) {
                                            handleAddWidget(id);
                                        } else {
                                            handleRemoveWidget(id);
                                        }
                                    }}
                                />
                            );
                        })}
                    </Menu>
                    <Button style={styles.button} onPress={handleLayoutSave}>
                        {t("common.save", "Save")}
                    </Button>
                    <IconButton
                        name="close"
                        style={styles.closeIcon}
                        onPress={handleLayoutCancel}
                    />
                </Card>
            )}
            <ResponsiveGridLayout
                className="layout"
                layouts={layouts}
                rowHeight={10}
                width={width}
                autoSize={true}
                onLayoutChange={handleLayoutChange}
                margin={[20, 20]}
                isDraggable={isEditing}
                isResizable={isEditing}
                isDroppable={isEditing}
            >
                {widgets.map(({ id, component }) => {
                    return (
                        <div key={id} className="widget">
                            <Card style={sharedStyles.widgetContent}>
                                {component(dashboardData)}
                            </Card>
                            {isEditing && (
                                <div className="gridHandle">
                                    <IconButton
                                        onPress={() => {
                                            handleRemoveWidget(id);
                                        }}
                                        name="close"
                                        style={styles.closeIcon}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            </ResponsiveGridLayout>
        </AdminContainer>
    );
}

function today(date = new Date()) {
    return set(date, {
        hours: 6,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}

function nextDay(date = new Date()) {
    return set(add(date, { days: 1 }), {
        hours: 6,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });
}

const styleFunc: StyleFunction = (theme, _dimensions) => ({
    closeIcon: {
        position: "absolute",
        color: theme.colors.primary,
        top: 0,
        right: 0,
        elevation: 1,
    },
    button: {
        marginLeft: theme.spacingScale,
    },
    checkboxItem: {
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
    },
    layoutHeader: {
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
        textAlign: "center",
    },
    // Style the select to be more like our normal styling (Copied from Picker in our react-common)
    select: {
        paddingLeft: 6,
        paddingRight: 6,
        borderRadius: theme.borderRadiusSmall,
        backgroundColor: theme.colors.white,
        width: "100%",
        borderWidth: StyleSheet.hairlineWidth,
        marginBottom: theme.spacingScale,
        minHeight: 40,
        borderColor: theme.colors.secondary,
    },
    topBar: {
        marginLeft: 20,
        marginRight: 20,
        flexDirection: "row",
        justifyContent: "flex-end",
        zIndex: 9999,
    },
});
