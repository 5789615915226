import React, { ReactNode, useCallback, useState } from "react";
import { ModalContext } from "./context";
import { ModalContainer } from "./container";
import type { Modal, ModalStack, RenderContent } from "./index";

let idCounter: number = 0;

export function ProvideModal<ReturnType = any>({
    children,
}: {
    children: ReactNode;
}) {
    const [stack, setStack] = useState<ModalStack>([]);
    const render = useCallback(
        async (
            renderContent: RenderContent<ReturnType>,
            closeOnOutside: boolean = false
        ) => {
            const id = idCounter++;
            return new Promise<ReturnType>(resolve => {
                // Add modal to stack
                setStack(prev =>
                    prev.concat({
                        id,
                        renderContent,
                        resolve,
                        closeOnOutside,
                    } as Modal)
                );
            }).finally(() => {
                // Remove modal from stack
                setStack(prev => prev.filter(modal => modal.id !== id));
            });
        },
        [setStack]
    );

    return (
        <ModalContext.Provider value={{ render, stack }}>
            <>
                {children}
                <ModalContainer />
            </>
        </ModalContext.Provider>
    );
}
