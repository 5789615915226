import type { ValidationSchema } from "../validation";
import type { IVatInput } from ".";

export const schemaVat: ValidationSchema<IVatInput> = {
    name: { required: true },
    percentage: {
        required: true,
        number: true,
        minValue: { value: 0 },
        maxValue: { value: 100 },
    },
};

export const defaultVat: IVatInput = {
    name: "",
    percentage: 0,
    isExternal: false,
};
