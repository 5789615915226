import type { TFunction } from "i18next";
import { v4 as uuidv4 } from "uuid";
import {
    ProductSection,
    ProductButton,
    FunctionButton,
    ILayoutProduct,
    LayoutSection,
    LayoutButton,
    GridDimensions,
    ButtonDimensions,
    ButtonPosition,
} from "lib";
import { produceNewLayoutSection } from "../functions";
import {
    LayoutActionType,
    LayoutAction,
    LayoutReducerState,
} from "../layout-operation-reducer";
import {
    positionProductButtonsInSections,
    getButtonPositionsForSection,
    checkButtonCollision,
    getMaxDimensionsForCoordinate,
    removeButtonPosition,
    setButtonPosition,
    positionButtonInFirstAvailableSpot,
} from "../functions";

export type ProductLayoutReducerState = Omit<LayoutReducerState, "sections"> & {
    sections: (ProductSection & Partial<{ renaming: boolean }>)[];
    knownProducts: ILayoutProduct[];
    dimensions: GridDimensions;
};

export enum ProductLayoutAction {
    ADD_BUTTON_TO_SECTION = "add_button_to_section",
    ADD_PRODUCTS_TO_SECTION = "add_products_to_section",
    MOVE_BUTTON_IN_SECTION = "move_button_in_section",
    MOVE_BUTTON_TO_SECTION = "move_button_to_section",
    REMOVE_BUTTON_FROM_SECTION = "remove_button_from_section",
    UPDATE_BUTTON_IN_SECTION = "update_button_in_section",
}

export type ProductLayoutActionType =
    | {
          type: ProductLayoutAction.ADD_BUTTON_TO_SECTION;
          newButton: ProductButton | FunctionButton;
          productData?: ILayoutProduct;
      }
    | {
          type: ProductLayoutAction.ADD_PRODUCTS_TO_SECTION;
          products: ILayoutProduct[];
          startingPosition: ButtonPosition;
          translationFunction: TFunction;
      }
    | {
          type: ProductLayoutAction.MOVE_BUTTON_IN_SECTION;
          position: ButtonPosition;
          buttonId: ProductButton["id"];
      }
    | {
          type: ProductLayoutAction.MOVE_BUTTON_TO_SECTION;
          buttonId: LayoutButton["id"];
          toSection: LayoutSection["id"] | "NEW";
          translationFunction: TFunction;
      }
    | {
          type: ProductLayoutAction.REMOVE_BUTTON_FROM_SECTION;
          buttonId: (ProductButton | FunctionButton)["id"];
      }
    | {
          type: ProductLayoutAction.UPDATE_BUTTON_IN_SECTION;
          updatedButton: ProductButton | FunctionButton;
          productData?: ILayoutProduct;
      };

export const productLayoutReducer = (
    state: ProductLayoutReducerState,
    action: LayoutActionType | ProductLayoutActionType
) => {
    let newState: typeof state;
    let foundSectionIndex: number;
    let foundSection: typeof newState.sections[0] | undefined;
    let foundButtonIndex: number | undefined;
    let foundButton: ProductButton | FunctionButton | undefined;

    switch (action.type) {
        case LayoutAction.ADD_SECTION: {
            if (!action.translationFunction) {
                return state;
            }

            newState = { ...state };

            newState.sections.push(
                produceNewLayoutSection(
                    action.translationFunction
                ) as typeof newState.sections[0]
            );

            break;
        }

        case LayoutAction.DELETE_SECTION: {
            foundSectionIndex = state.sections.findIndex(
                itr => itr.id === action.sectionId
            );

            if (foundSectionIndex === -1) {
                return state;
            }

            newState = { ...state };
            // Find the section and remove it.
            newState.sections.splice(foundSectionIndex, 1);

            let newCurrentSection = state.currentSectionIndex;

            // TODO Remove the products from the cache of used products. How to efficiently check if they are used in more than one layout?

            // If this was the last section, then add a new one.
            if (newState.sections.length === 0) {
                newState.sections.push(
                    produceNewLayoutSection(
                        action.translationFunction
                    ) as typeof newState.sections[0]
                );
            }

            // If the currently deleted section was before the currently active section,
            // then adjust the index of the active section
            if (state.currentSectionIndex >= foundSectionIndex) {
                state.currentSectionIndex = state.currentSectionIndex - 1;
            }

            // Make sure the index never gets faulty
            if (newCurrentSection < 0) {
                state.currentSectionIndex = 0;
            }

            break;
        }

        case LayoutAction.MOVE_SECTION: {
            const layoutIndex = state.sections.findIndex(
                section => section.id === action.sectionId
            );

            if (layoutIndex === -1) {
                return state;
            }

            if (!["BEFORE", "AFTER"].includes(action.direction)) {
                return state;
            }

            const newPosition =
                action.direction === "BEFORE"
                    ? layoutIndex - 1
                    : layoutIndex + 1;

            // if newPosition is out of bounds: back off.
            if (newPosition < 0) {
                return state;
            } else if (newPosition > state.sections.length) {
                return state;
            }

            newState = { ...state };

            // Move section from layoutIndex to newPosition
            newState.sections.splice(
                newPosition,
                0,
                newState.sections.splice(layoutIndex, 1)[0]
            );

            // Make sure the selected section makes sense.
            if (layoutIndex === state.currentSectionIndex) {
                newState.currentSectionIndex = newPosition;
            } else if (newPosition === state.currentSectionIndex) {
                newState.currentSectionIndex = layoutIndex;
            }
            break;
        }

        case LayoutAction.RENAME_SECTION: {
            foundSection = state.sections.find(
                itr => itr.id === action.sectionId
            );

            if (!foundSection) {
                return state;
            }

            newState = { ...state };

            // replace section
            newState.sections.map(sectionItr => {
                if (sectionItr.id === action.sectionId) {
                    sectionItr.label = action.newLabel.trim();
                }

                return sectionItr;
            });

            break;
        }

        case LayoutAction.SELECT_SECTION: {
            foundSectionIndex = state.sections.findIndex(
                itr => itr.id === action.sectionId
            );

            if (foundSectionIndex === -1) {
                return state;
            }

            newState = {
                ...state,
                currentSectionIndex: foundSectionIndex,
            };

            break;
        }

        case LayoutAction.TOGGLE_RENAME_SECTION: {
            foundSection = state.sections.find(
                itr => itr.id === action.sectionId
            );

            if (!foundSection) {
                return state;
            }

            newState = { ...state };
            newState.sections.map(sectionItr => {
                if (sectionItr.id === action.sectionId) {
                    // toggle the renaming property for this section
                    sectionItr.renaming = !sectionItr.renaming;
                }
                return sectionItr;
            });
            break;
        }

        case ProductLayoutAction.ADD_BUTTON_TO_SECTION: {
            if (action.newButton.id) {
                return state;
            }

            newState = { ...state };

            // Set an id for this button
            action.newButton.id = uuidv4();

            newState.sections[newState.currentSectionIndex].buttons.push(
                action.newButton
            );

            if (
                action.newButton.buttonType === "PRODUCT" &&
                action.productData &&
                !newState.knownProducts.find(
                    productItr =>
                        productItr.id ===
                        (action.productData as ILayoutProduct).id
                )
            ) {
                newState.knownProducts.push(action.productData);
            }

            break;
        }

        case ProductLayoutAction.ADD_PRODUCTS_TO_SECTION: {
            if (
                state.currentSectionIndex < 0 ||
                state.currentSectionIndex > state.sections.length - 1
            ) {
                return state;
            }

            foundSection = state.sections[state.currentSectionIndex];

            // The requested section does not exist. Back off.
            if (foundSection === undefined) {
                return state;
            }

            if (!action.products || !action.products.length) {
                return state;
            }

            newState = { ...state };

            // Convert product data to product button data
            const layoutProductButtons: ProductButton[] = action.products.map(
                (layoutProduct: ILayoutProduct) => ({
                    id: `button_${layoutProduct.id}_${new Date().getTime()}`,
                    x: -1,
                    y: -1,
                    width: 1,
                    height: 1,
                    buttonType: "PRODUCT",
                    productId: layoutProduct.id,
                    amount: layoutProduct.amount,
                    color: "",
                    label: layoutProduct.buttonText ?? layoutProduct.name,
                })
            );

            const { updatedSections, updatedSectionIndex } =
                positionProductButtonsInSections(
                    layoutProductButtons,
                    newState.sections,
                    newState.currentSectionIndex,
                    getButtonPositionsForSection(
                        newState.sections[newState.currentSectionIndex].buttons,
                        newState.dimensions
                    ),
                    action.startingPosition,
                    newState.dimensions,
                    action.translationFunction
                );

            newState.knownProducts = [
                ...newState.knownProducts,
                ...action.products,
            ];

            newState.sections = updatedSections;
            if (updatedSectionIndex !== state.currentSectionIndex) {
                newState.currentSectionIndex = updatedSectionIndex;
            }

            break;
        }

        case ProductLayoutAction.MOVE_BUTTON_IN_SECTION: {
            // If, for some odd reason, the button does not have an id or is dropped in the same location, then back off.
            if (!action.buttonId) {
                return state;
            }

            foundSection = state.sections[state.currentSectionIndex];

            if (!foundSection) {
                return state;
            }

            foundButtonIndex = state.sections[
                state.currentSectionIndex
            ].buttons.findIndex(buttonItr => buttonItr.id === action.buttonId);

            if (foundButtonIndex === -1) {
                return state;
            }

            newState = { ...state };
            foundSection = newState.sections[newState.currentSectionIndex];

            let updatedButton: ProductButton | FunctionButton =
                foundSection.buttons[foundButtonIndex];
            let buttonPositions: (ProductButton | FunctionButton)["id"][] =
                getButtonPositionsForSection(
                    foundSection.buttons,
                    newState.dimensions
                );

            // Check if the button is dropped "on top of" another button
            let collisionWithButtonId = checkButtonCollision(
                action.buttonId,
                { width: 1, height: 1 }, // Only check the drop position (meaning: use a "single cell" button)
                { x: action.position.x, y: action.position.y }, // Check the drop position
                buttonPositions,
                newState.dimensions
            );

            const oldPosition = { x: updatedButton.x, y: updatedButton.y };
            const oldDimensions = {
                width: updatedButton.width,
                height: updatedButton.height,
            };
            const newPosition = { x: action.position.x, y: action.position.y };
            const maxDimensions = getMaxDimensionsForCoordinate(
                newPosition,
                newState.dimensions,
                foundSection.buttons,
                [action.buttonId!]
            );

            const newWidth = Math.min(updatedButton.width, maxDimensions.width);
            const newHeight = Math.min(
                updatedButton.height,
                maxDimensions.height
            );

            const newDimensions: ButtonDimensions = {
                width: newWidth,
                height: newHeight,
            };

            // Check if the buttons new position makes it collide with other buttons (overlap)
            if (
                checkButtonCollision(
                    action.buttonId,
                    newDimensions,
                    newPosition,
                    buttonPositions,
                    newState.dimensions
                )
            ) {
                // set new button space
                updatedButton.x = action.position.x;
                updatedButton.y = action.position.y;
                updatedButton.width = 1;
                updatedButton.height = 1;
            } else {
                updatedButton.x = action.position.x;
                updatedButton.y = action.position.y;
                updatedButton.width = newWidth;
                updatedButton.height = newHeight;
            }

            // reset caching space for old button position
            let updatedButtonPositions = removeButtonPosition(
                buttonPositions,
                newState.dimensions,
                oldPosition,
                oldDimensions
            );

            // set caching for new button position
            setButtonPosition(
                updatedButtonPositions,
                newState.dimensions,
                newPosition,
                newDimensions,
                action.buttonId
            );

            let updatedButtons: (ProductButton | FunctionButton)[] =
                foundSection.buttons;
            if (collisionWithButtonId) {
                const swapButton = foundSection.buttons.find(
                    btn => btn.id === collisionWithButtonId
                );

                if (swapButton) {
                    updatedButtons = updatedButtons.map(button => {
                        if (button.id === swapButton.id) {
                            return {
                                ...swapButton,
                                x: oldPosition.x,
                                y: oldPosition.y,
                                width: oldDimensions.width,
                                height: oldDimensions.height,
                            };
                        }

                        return button;
                    });
                }
            }

            updatedButtons = updatedButtons.map(button => {
                if (button.id === action.buttonId) {
                    return updatedButton;
                }

                return button;
            });

            newState.sections[newState.currentSectionIndex].buttons =
                updatedButtons;

            break;
        }

        /**
         * 1. Remove the button from the currently selected section
         * 2: Find the known button positions for the new section
         * 3: Found an available position for the moved button in the new section
         * 4: Update the moved button to the new position
         * 5: Update the collection of layout sections
         */
        case ProductLayoutAction.MOVE_BUTTON_TO_SECTION: {
            if (!action.buttonId || !action.toSection) {
                return state;
            }
            // The "from" section
            foundSection = state.sections[state.currentSectionIndex];

            if (!foundSection) {
                return state;
            }

            foundButtonIndex = foundSection.buttons.findIndex(
                itr => itr.id === action.buttonId
            );

            if (foundButtonIndex === -1) {
                return state;
            }

            foundButton = foundSection.buttons[foundButtonIndex];

            if (!foundButton) {
                return state;
            }

            if (
                action.toSection !== "NEW" &&
                state.sections.findIndex(
                    sectionItr => sectionItr.id === action.toSection
                ) === -1
            ) {
                return state;
            }

            // remove button from currently selected section
            newState = { ...state };
            newState.sections.map(sectionItr => {
                if (sectionItr.id === foundSection!.id) {
                    sectionItr.buttons.splice(foundButtonIndex!, 1);
                }
            });

            // and add to "To" section (possibly a new section)
            if (action.toSection === "NEW") {
                let newSection = produceNewLayoutSection(
                    action.translationFunction
                ) as typeof newState.sections[0];

                // Reposition the button to the top left (0,0) position
                foundButton.x = 0;
                foundButton.y = 0;

                newSection.buttons.push(foundButton);

                newState.sections.push(newSection);
            } else {
                newState.sections.map(sectionItr => {
                    const toSectionIndex = newState.sections.findIndex(
                        toSectionItr => toSectionItr.id === action.toSection
                    );

                    if (sectionItr.id === action.toSection) {
                        sectionItr.buttons.push(
                            positionButtonInFirstAvailableSpot(
                                foundButton!,
                                getButtonPositionsForSection(
                                    newState.sections[toSectionIndex].buttons,
                                    newState.dimensions
                                ),
                                0,
                                newState.dimensions.columns
                            )
                        );
                    }
                    return sectionItr;
                });
            }

            break;
        }

        case ProductLayoutAction.REMOVE_BUTTON_FROM_SECTION: {
            if (!action.buttonId) {
                return state;
            }

            foundSection = state.sections[state.currentSectionIndex];

            if (!foundSection) {
                return state;
            }

            foundButton = state.sections[
                state.currentSectionIndex
            ].buttons.find(itr => itr.id === action.buttonId);

            if (!foundButton) {
                return state;
            }

            newState = { ...state };

            if (foundButton.buttonType === "PRODUCT") {
                // Update the list of known products. First, figure out if more than one button uses this product

                const startingPoint: Record<ILayoutProduct["id"], number> = {};
                const productCount = newState.sections.reduce(
                    (accumulator, sectionElement) => {
                        sectionElement.buttons.forEach(eachButton => {
                            if (eachButton.buttonType !== "PRODUCT") {
                                return accumulator;
                            }

                            if (eachButton.productId in accumulator) {
                                accumulator[eachButton.productId]++;
                            } else {
                                accumulator[eachButton.productId] = 1;
                            }
                        });
                        return accumulator;
                    },
                    startingPoint
                );

                if (
                    productCount[foundButton.productId] &&
                    productCount[foundButton.productId] === 1
                ) {
                    // There is only one instance of this product in this layout.
                    // Remove the button from the list of known products
                    newState.knownProducts = newState.knownProducts.filter(
                        productItr =>
                            productItr.id !==
                            (foundButton as ProductButton).productId
                    );
                }
            }

            // Remove the button
            newState.sections[newState.currentSectionIndex].buttons =
                newState.sections[newState.currentSectionIndex].buttons.filter(
                    itr => itr.id !== action.buttonId
                );

            break;
        }

        case ProductLayoutAction.UPDATE_BUTTON_IN_SECTION: {
            if (!action.updatedButton.id) {
                return state;
            }

            const foundButtons =
                state.sections[state.currentSectionIndex].buttons;

            if (foundButtons === undefined) {
                return state;
            }

            newState = { ...state };

            foundButtonIndex = foundButtons.findIndex(
                buttonItr => buttonItr?.id === action.updatedButton.id
            );

            if (foundButtonIndex === -1) {
                return state;
            }

            newState.sections[newState.currentSectionIndex].buttons[
                foundButtonIndex
            ] = action.updatedButton;

            if (action.updatedButton.buttonType === "PRODUCT") {
                if (
                    action.productData &&
                    newState.knownProducts.find(
                        productItr =>
                            productItr.id ===
                            (action.updatedButton as ProductButton).productId
                    ) === undefined
                ) {
                    newState.knownProducts.push(action.productData);
                }
            }

            break;
        }

        default:
            console.error("Illegal layout operation:");
            console.error(action);
            throw new Error(
                `Illegal layout operation: ${JSON.stringify(action)}`
            );
    }
    return newState;
};
