import type {
    IRoleSave,
    IUserCreate,
    IUserPassword,
    IUserSave,
    ValidationSchema,
} from "lib";

export const defaultSaveUser: IUserSave = {
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    requireLoginPin: false,
    requireLogoutPin: false,
    active: true,
    roles: [],
    requirePasswordChange: false,
    doNotSendPassword: false,
    password: "",
};

export const schemaSaveUser: ValidationSchema<IUserSave> = {
    username: { required: true, username: true },
    email: { required: true, email: true },
    firstName: { required: true },
    lastName: { required: true },
    pin: { pattern: { pattern: /^\d{0,10}$/ } },
    confirmPassword: {
        sameAs: {
            field: "password",
            message: ["validation.password_not_the_same"],
        },
    },
};

export const schemaSaveUserPassword: ValidationSchema<IUserPassword> = {
    password: {
        required: true,
        minLength: {
            length: 8,
            message: ["validation.min_password_length"],
        },
    },
    confirmPassword: {
        sameAs: {
            field: "password",
            message: ["validation.password_not_the_same"],
        },
    },
};

export const defaultCreateUser: IUserCreate = {
    ...defaultSaveUser,
    requirePasswordChange: true,
};

export const schemaCreateUser: ValidationSchema<IUserCreate> = {
    ...schemaSaveUser,
};

export const defaultRole: IRoleSave = {
    name: "",
    type: "MERCHANT",
    shared: false,
    permissions: [],
};

export const schemaRole: ValidationSchema<IRoleSave> = {
    name: {
        required: true,
        minLength: { length: 2 },
        maxLength: { length: 32 },
    },
};

export const defaultUserPassword: IUserPassword = {
    password: "",
    confirmPassword: "",
};
