import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { AdminContainer } from "../container";
import {
    layoutE700,
    layoutE800,
    layoutRestaurantLarge,
    layoutRestaurantMedium,
    layoutRestaurantSmall,
    layoutA920,
} from "lib";

export const productLayoutSizes = [layoutE700, layoutE800, layoutA920];
export const tableLayoutSizes = [
    layoutRestaurantSmall,
    layoutRestaurantMedium,
    layoutRestaurantLarge,
];

export function LayoutScreen({
    children,
    maxWidth,
}: {
    children: React.ReactNode;
    maxWidth?: number;
}) {
    const styles: StyleProp<ViewStyle> = maxWidth
        ? {
              width: "100%",
              maxWidth,
              alignSelf: "center",
          }
        : {};

    return (
        <AdminContainer testID="layoutsListScreen">
            <View style={styles}>{children}</View>
        </AdminContainer>
    );
}
