import React from "react";
import { StyleSheet, View } from "react-native";
import { Switch } from "@venuepos/react-common";

// Handles type: boolean
export function EntityFormSwitchInput({
    higherOrder,
    value,
    onValueChange,
    disabled,
    testID,
}: {
    higherOrder: any;
    value: any;
    onValueChange: (v: any) => void;
    disabled: boolean;
    testID?: string;
}) {
    if (!disabled) {
        return (
            <View style={styles.switch}>
                <Switch
                    value={value}
                    onValueChange={onValueChange}
                    testID={testID}
                />
            </View>
        );
    } else {
        return (
            <View style={styles.switch}>
                <Switch
                    value={higherOrder}
                    key={"disabled"}
                    disabled={true}
                    testID={testID}
                />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    switch: {
        paddingTop: 7,
        paddingBottom: 17,
    },
});
