export function formatQuantity(
    quantity: number,
    options?: {
        locale?: string;
        maximumDecimals?: number;
    }
) {
    if (options) {
        if (options.locale) {
            const numberFormatter = new Intl.NumberFormat(options.locale, {
                style: "decimal",
                maximumFractionDigits: options.maximumDecimals || 20,
            });
            return numberFormatter.format(quantity);
        } else if (options.maximumDecimals) {
            return (
                Math.round(quantity * 10 * options.maximumDecimals) /
                (10 * options.maximumDecimals)
            ).toString();
        }
    } else if (quantity !== undefined) {
        return quantity.toString();
    }
    return "0";
}
