import {
    OrderConditionContext,
    OrderConditions,
    orderConditions,
} from "./order-condition";
import type {
    ConditionAssignment,
    ConditionLogicOperator,
    Conditions,
    ConditionTypes,
    SuccessfulConditions,
} from "./types";
import type { AllConditions } from "./index";

type EntityConditionContext = {
    order: OrderConditionContext;
};

export function checkConditions(
    context: EntityConditionContext,
    conditions: Conditions,
    logicOperator: ConditionLogicOperator = "AND"
): SuccessfulConditions | false {
    if (!Array.isArray(conditions)) {
        throw new Error(
            `Given conditions could not be validated.\n${JSON.stringify(
                conditions
            )}`
        );
    }

    if (conditions.length === 0) {
        return {};
    }

    let successfulConditions: SuccessfulConditions = {};
    for (let i = 0; i < conditions.length; i++) {
        if (conditions[i].type === "logic") {
            const check = checkConditions(
                context,
                conditions[i].args as Conditions,
                conditions[i].condition as ConditionLogicOperator
            );

            if (
                logicOperator === "OR" &&
                check === false &&
                i === conditions.length - 1
            ) {
                // If it's last condition in OR expression and condition failed, we'll return false
                return false;
            } else if (
                logicOperator === "OR" &&
                typeof check === "boolean" &&
                check
            ) {
                // If condition succeeded with `true`, return empty object
                return {};
            } else if (typeof check !== "boolean") {
                // If conditions succeeded with a none boolean, then we need to combine
                // the result of the successful conditions from the condition with the
                // successful condition of this logic operation
                for (const ii in check) {
                    const condition = ii as keyof SuccessfulConditions;
                    if (successfulConditions[condition]) {
                        successfulConditions[condition] = successfulConditions[
                            condition
                        ]!.concat(check[condition]!);
                    } else {
                        successfulConditions[condition] = check[condition];
                    }
                }

                if (logicOperator === "OR") {
                    // If any logic operator is OR, then we can jump out early without
                    // checking any further conditions
                    break;
                }
            } else if (logicOperator === "AND" && !check) {
                // If logic operator is AND and condition failed, we return false
                return false;
            }
        } else {
            const check = checkCondition(context, conditions[i]);

            if (
                logicOperator === "OR" &&
                check === false &&
                i === conditions.length - 1
            ) {
                // If it's last condition in OR expression and condition failed, we'll return false
                return false;
            } else if (logicOperator === "OR" && check === true) {
                // If condition succeeded with `true`, return empty object
                return {};
            } else if (check !== true && check !== false) {
                // If condition is not of type LOGIC and the condition succeeded,
                // then we need to add the result of the condition to the result
                // array of successful conditions
                const type = conditions[i].type as keyof ConditionTypes;
                if (successfulConditions[type] === undefined) {
                    successfulConditions[type] = [];
                }

                successfulConditions[type]!.push({
                    condition: conditions[i].condition as keyof AllConditions,
                    output: check,
                });

                if (logicOperator === "OR") {
                    // If any logic operator is OR, then we can jump out early without
                    // checking any further conditions
                    break;
                }
            } else if (logicOperator === "AND" && !check) {
                // If logic operator is AND and condition failed, we return false
                return false;
            }
        }
    }

    return successfulConditions;
}

export function checkCondition(
    context: EntityConditionContext,
    condition: ConditionAssignment
): any {
    if (!isOfTypeOrderConditions(condition.condition)) {
        console.log("unknown Ordercondition", condition);
        return false;
    }
    switch (condition.type) {
        case "order":
            return orderConditions[
                condition.condition as keyof OrderConditions
            ](context.order, condition.args as any);
    }

    return false;
}

export function assignOrderCondition<C extends keyof OrderConditions>(
    condition: C,
    args: OrderConditions[C]
): ConditionAssignment {
    return {
        type: "order",
        condition,
        args,
    };
}

function isOfTypeOrderConditions(
    condition: string
): condition is keyof OrderConditions {
    return [
        "hasProductQuantity",
        "hasReturnQuantity",
        "hasGroupQuantity",
        "hasTag",
        "hasTagGroup",
        "hasCustomer",
        "hasCustomerGroup",
        "hasDepartment",
        "dateTime",
        "date",
        "time",
        "week",
        "weekDay",
    ].includes(condition);
}
