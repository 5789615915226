import type {
    GraphQLResolveInfo,
    GraphQLScalarType,
    GraphQLScalarTypeConfig,
} from "graphql";
import gql from "graphql-tag";
import type * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type RequireFields<T, K extends keyof T> = {
    [X in Exclude<keyof T, K>]?: T[X];
} & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: any;
    JSON: any;
};

export type GQAccount = {
    __typename?: "Account";
    id: Scalars["ID"];
    name: Scalars["String"];
    merchant: GQMerchant;
    type: Scalars["String"];
    status: Scalars["String"];
    amount: Scalars["Float"];
    createdAt: Scalars["Date"];
    transactions: GQAccountTransactionPagination;
    tags: GQTagPagination;
    customer?: Maybe<GQCustomer>;
    balance: GQBalance;
    systemLabel?: Maybe<Scalars["String"]>;
    vat?: Maybe<GQVat>;
    externalId?: Maybe<Scalars["String"]>;
};

export type GQAccountTransactionsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQAccountTagsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQAccountBalanceArgs = {
    numberOfTransactions?: Maybe<Scalars["Int"]>;
};

export type GQAccountAssignTagsRelationResponse = {
    __typename?: "AccountAssignTagsRelationResponse";
    status: Scalars["Boolean"];
};

export type GQAccountBookkeepingPagination = GQIPagination & {
    __typename?: "AccountBookkeepingPagination";
    data: Array<GQBookkeepingAccount>;
    pagination: GQPagination;
};

export type GQAccountBookkeepingTransaction = {
    __typename?: "AccountBookkeepingTransaction";
    id: Scalars["ID"];
    type: Scalars["String"];
    transactionAt: Scalars["Date"];
    amount: Scalars["Float"];
    invoiceId?: Maybe<Scalars["String"]>;
};

export type GQAccountBookkeepingTransactionPagination = GQIPagination & {
    __typename?: "AccountBookkeepingTransactionPagination";
    data: Array<GQAccountBookkeepingTransaction>;
    pagination: GQPagination;
};

export type GQAccountBuyFundsResponse = {
    __typename?: "AccountBuyFundsResponse";
    status: GQAccountBuyFundsStatus;
    transactionId?: Maybe<Scalars["ID"]>;
};

export enum GQAccountBuyFundsStatus {
    Successful = "SUCCESSFUL",
    Error = "ERROR",
    AccountNotFound = "ACCOUNT_NOT_FOUND",
    AccountClosed = "ACCOUNT_CLOSED",
    InvalidAmount = "INVALID_AMOUNT",
}

export type GQAccountCloseResponse = {
    __typename?: "AccountCloseResponse";
    status: Scalars["Boolean"];
};

export type GQAccountCreate = {
    name: Scalars["String"];
    customerId?: Maybe<Scalars["String"]>;
    type: Scalars["String"];
    status: Scalars["String"];
    amount: Scalars["Float"];
    vatId: Scalars["String"];
    systemLabel?: Maybe<Scalars["String"]>;
    externalId?: Maybe<Scalars["String"]>;
};

export type GQAccountInput = {
    name: Scalars["String"];
    type: Scalars["String"];
    status: Scalars["String"];
    amount: Scalars["Float"];
    externalId?: Maybe<Scalars["String"]>;
    vatId?: Maybe<Scalars["String"]>;
};

export type GQAccountOpenResponse = {
    __typename?: "AccountOpenResponse";
    status: Scalars["Boolean"];
};

export type GQAccountPagination = GQIPagination & {
    __typename?: "AccountPagination";
    data: Array<GQAccount>;
    pagination: GQPagination;
};

export type GQAccountPayAmountResponse = {
    __typename?: "AccountPayAmountResponse";
    transactionId: Scalars["ID"];
    status: GQAccountTransactionStatus;
    type?: Maybe<Scalars["String"]>;
    receipt?: Maybe<Scalars["String"]>;
};

export type GQAccountRefundAmountResponse = {
    __typename?: "AccountRefundAmountResponse";
    transactionId: Scalars["ID"];
    status: GQAccountTransactionStatus;
    type?: Maybe<Scalars["String"]>;
    receipt?: Maybe<Scalars["String"]>;
};

export type GQAccountReturnFundsResponse = {
    __typename?: "AccountReturnFundsResponse";
    status: GQAccountReturnFundsStatus;
    transactionId?: Maybe<Scalars["ID"]>;
};

export enum GQAccountReturnFundsStatus {
    Successful = "SUCCESSFUL",
    Error = "ERROR",
    AccountNotFound = "ACCOUNT_NOT_FOUND",
    AccountClosed = "ACCOUNT_CLOSED",
    InvalidAmount = "INVALID_AMOUNT",
}

export type GQAccountRevertTransactionResponse = {
    __typename?: "AccountRevertTransactionResponse";
    status: GQAccountRevertTransactionStatus;
};

export enum GQAccountRevertTransactionStatus {
    Successful = "SUCCESSFUL",
    Error = "ERROR",
    AccountNotFound = "ACCOUNT_NOT_FOUND",
    AccountClosed = "ACCOUNT_CLOSED",
    TransactionNotFound = "TRANSACTION_NOT_FOUND",
    AlreadyReverted = "ALREADY_REVERTED",
}

export type GQAccountTransaction = {
    __typename?: "AccountTransaction";
    id: Scalars["ID"];
    userId?: Maybe<Scalars["String"]>;
    transactionAt: Scalars["Date"];
    amount: Scalars["Float"];
    user?: Maybe<GQUser>;
    tag?: Maybe<GQTag>;
    invoiceId?: Maybe<Scalars["String"]>;
};

export type GQAccountTransactionPagination = GQIPagination & {
    __typename?: "AccountTransactionPagination";
    data: Array<GQAccountTransaction>;
    pagination: GQPagination;
};

export enum GQAccountTransactionStatus {
    Successful = "SUCCESSFUL",
    Error = "ERROR",
    AccountNotFound = "ACCOUNT_NOT_FOUND",
    AccountClosed = "ACCOUNT_CLOSED",
    NotEnoughFunds = "NOT_ENOUGH_FUNDS",
    InvalidAmount = "INVALID_AMOUNT",
}

export type GQAddEventResponse = {
    __typename?: "AddEventResponse";
    success: Scalars["Boolean"];
};

export type GQAppData = {
    __typename?: "AppData";
    structVersion: Scalars["Int"];
    minimumStructVersion: Scalars["Int"];
    cashRegister: GQCashRegister;
    merchant: GQMerchant;
    deviceConfig: Scalars["JSON"];
    vats: Array<GQVat>;
    productGroups: Array<GQProductGroup>;
    products: Array<GQProduct>;
    discounts: Array<GQDiscount>;
    layout?: Maybe<GQLayout>;
    tableLayout?: Maybe<GQLayout>;
    paymentConfiguration?: Maybe<GQPaymentConfiguration>;
    miscButtons?: Maybe<GQMiscButtons>;
    tags: Array<GQTag>;
    printers: Array<GQPrinter>;
    bookkeepingAccounts: Array<GQBookkeepingAccount>;
};

export type GQAuthAttempt = {
    __typename?: "AuthAttempt";
    successful: Scalars["Boolean"];
    token?: Maybe<GQAuthToken>;
};

export type GQAuthToken = {
    __typename?: "AuthToken";
    access: Scalars["String"];
    accessExpire: Scalars["Int"];
    now: Scalars["String"];
    refresh: Scalars["String"];
};

export type GQBalance = {
    __typename?: "Balance";
    receipt: Scalars["String"];
};

export type GQBookkeepingAccount = {
    __typename?: "BookkeepingAccount";
    id: Scalars["ID"];
    name: Scalars["String"];
    merchant: GQMerchant;
    createdAt: Scalars["Date"];
    systemLabel?: Maybe<Scalars["String"]>;
    vat?: Maybe<GQVat>;
    bookkeepingTransactions: GQAccountBookkeepingTransactionPagination;
};

export type GQBookkeepingAccountBookkeepingTransactionsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQBooleanResponse = {
    __typename?: "BooleanResponse";
    status: Scalars["Boolean"];
};

export type GQCardTransaction = {
    __typename?: "CardTransaction";
    id: Scalars["ID"];
    status: GQCardTransactionType;
    orderId?: Maybe<Scalars["ID"]>;
    amount: Scalars["Float"];
    receipt: Scalars["String"];
    transactionAt: Scalars["Date"];
    cashRegister: GQCashRegister;
    merchant: GQMerchant;
};

export type GQCardTransactionPagination = GQIPagination & {
    __typename?: "CardTransactionPagination";
    data: Array<GQCardTransaction>;
    pagination: GQPagination;
};

export enum GQCardTransactionType {
    Successful = "SUCCESSFUL",
    Failed = "FAILED",
}

export type GQCashRegister = {
    __typename?: "CashRegister";
    id: Scalars["ID"];
    serialId: Scalars["ID"];
    name: Scalars["String"];
    device?: Maybe<GQDevice>;
    merchant: GQMerchant;
    department?: Maybe<GQDepartment>;
    appData: GQAppData;
    createdAt: Scalars["Date"];
    deletedAt?: Maybe<Scalars["Date"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
    lastAppDataSentAt?: Maybe<Scalars["Date"]>;
};

export type GQCashRegisterCreateInput = {
    name: Scalars["String"];
    departmentId?: Maybe<Scalars["ID"]>;
    deviceId?: Maybe<Scalars["ID"]>;
};

export type GQCashRegisterDeleteResponse = {
    __typename?: "CashRegisterDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQCashRegisterEditInput = {
    name: Scalars["String"];
    departmentId?: Maybe<Scalars["ID"]>;
};

export type GQCashRegisterPagination = GQIPagination & {
    __typename?: "CashRegisterPagination";
    data: Array<GQCashRegister>;
    pagination: GQPagination;
};

export type GQCashRegisterStatus = {
    __typename?: "CashRegisterStatus";
    id: Scalars["ID"];
    serialId: Scalars["ID"];
    name: Scalars["String"];
    device?: Maybe<GQDevice>;
    merchant: GQMerchant;
    department?: Maybe<GQDepartment>;
    appData: GQAppData;
    createdAt: Scalars["Date"];
    deletedAt?: Maybe<Scalars["Date"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
    lastAppDataSentAt?: Maybe<Scalars["Date"]>;
    status: Scalars["String"];
};

export type GQCashRegisterStatusPagination = GQIPagination & {
    __typename?: "CashRegisterStatusPagination";
    data: Array<GQCashRegisterStatus>;
    pagination: GQPagination;
};

export type GQCashRegisterVersion = {
    __typename?: "CashRegisterVersion";
    label: Scalars["String"];
    count: Scalars["Int"];
};

export type GQChartOfAccountNo = {
    __typename?: "ChartOfAccountNo";
    merchantAccountNo: Scalars["String"];
};

export type GQConfiguration = {
    __typename?: "Configuration";
    paymentTerminalType?: Maybe<Scalars["String"]>;
};

export type GQCurrency = {
    __typename?: "Currency";
    id: Scalars["ID"];
    merchant: GQMerchant;
    code: Scalars["String"];
    rate: Scalars["Float"];
    createdAt: Scalars["Date"];
    deletedAt?: Maybe<Scalars["Date"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
};

export type GQCurrencyDeleteResponse = {
    __typename?: "CurrencyDeleteResponse";
    status: GQDeleteResponseEnum;
};

export type GQCurrencyInput = {
    code: Scalars["String"];
    rate: Scalars["Float"];
};

export type GQCurrencyPagination = GQIPagination & {
    __typename?: "CurrencyPagination";
    data: Array<GQCurrency>;
    pagination: GQPagination;
};

export type GQCustomer = {
    __typename?: "Customer";
    id: Scalars["ID"];
    merchantId: GQMerchant;
    name: Scalars["String"];
    group?: Maybe<GQCustomerGroup>;
    address: Scalars["String"];
    zip: Scalars["String"];
    city: Scalars["String"];
    phoneNumber?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    cvrNumber?: Maybe<Scalars["String"]>;
    contactName?: Maybe<Scalars["String"]>;
    comment?: Maybe<Scalars["String"]>;
    accounts: Array<GQAccount>;
    createdAt: Scalars["Date"];
    deletedAt?: Maybe<Scalars["Date"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
    tags: GQTagPagination;
};

export type GQCustomerTagsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQCustomerAssignTagsRelationResponse = {
    __typename?: "CustomerAssignTagsRelationResponse";
    status: Scalars["Boolean"];
};

export type GQCustomerCreate = {
    name: Scalars["String"];
    merchantId: Scalars["ID"];
    groupId?: Maybe<Scalars["ID"]>;
    address: Scalars["String"];
    zip: Scalars["String"];
    city: Scalars["String"];
    phoneNumber?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    cvrNumber?: Maybe<Scalars["String"]>;
    contactName?: Maybe<Scalars["String"]>;
    comment?: Maybe<Scalars["String"]>;
};

export type GQCustomerDeleteResponse = {
    __typename?: "CustomerDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQCustomerGroup = {
    __typename?: "CustomerGroup";
    id: Scalars["ID"];
    merchant: GQMerchant;
    name: Scalars["String"];
    createdAt: Scalars["Date"];
    deletedAt?: Maybe<Scalars["Date"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
};

export type GQCustomerGroupCreate = {
    name: Scalars["String"];
    merchantId: Scalars["ID"];
};

export type GQCustomerGroupDeleteResponse = {
    __typename?: "CustomerGroupDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQCustomerGroupInput = {
    name: Scalars["String"];
};

export type GQCustomerGroupPagination = GQIPagination & {
    __typename?: "CustomerGroupPagination";
    data: Array<GQCustomerGroup>;
    pagination: GQPagination;
};

export type GQCustomerInput = {
    name: Scalars["String"];
    groupId?: Maybe<Scalars["ID"]>;
    address: Scalars["String"];
    zip: Scalars["String"];
    city: Scalars["String"];
    phoneNumber?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    cvrNumber?: Maybe<Scalars["String"]>;
    contactName?: Maybe<Scalars["String"]>;
    comment?: Maybe<Scalars["String"]>;
};

export type GQCustomerPagination = GQIPagination & {
    __typename?: "CustomerPagination";
    data: Array<GQCustomer>;
    pagination: GQPagination;
};

export type GQDashboard = {
    __typename?: "Dashboard";
    totals: GQDashboardTotals;
    products: Array<GQDashboardProductItem>;
    productGroups: Array<GQDashboardProductGroupItem>;
    departments: Array<GQDashboardDepartmentItem>;
    accounts: Array<GQDashboardAccountItem>;
};

export type GQDashboardAccountItem = {
    __typename?: "DashboardAccountItem";
    id: Scalars["ID"];
    name: Scalars["String"];
    amount: Scalars["Float"];
};

export type GQDashboardCashRegister = {
    __typename?: "DashboardCashRegister";
    id: Scalars["ID"];
    name: Scalars["String"];
    device?: Maybe<GQDevice>;
    merchant?: Maybe<GQMerchant>;
    createdAt: Scalars["Date"];
    deletedAt?: Maybe<Scalars["Date"]>;
};

export type GQDashboardCashRegisterPagination = GQIPagination & {
    __typename?: "DashboardCashRegisterPagination";
    data: Array<GQDashboardCashRegister>;
    pagination: GQPagination;
};

export type GQDashboardDepartmentItem = {
    __typename?: "DashboardDepartmentItem";
    id?: Maybe<Scalars["ID"]>;
    name?: Maybe<Scalars["String"]>;
    invoices: Scalars["Float"];
    avgTransaction: Scalars["Float"];
    turnoverAmount: Scalars["Float"];
};

export type GQDashboardProductGroupItem = {
    __typename?: "DashboardProductGroupItem";
    id: Scalars["ID"];
    name: Scalars["String"];
    quantity: Scalars["Float"];
    turnoverAmount: Scalars["Float"];
};

export type GQDashboardProductItem = {
    __typename?: "DashboardProductItem";
    id?: Maybe<Scalars["ID"]>;
    name: Scalars["String"];
    quantity: Scalars["Float"];
    turnoverAmount: Scalars["Float"];
};

export type GQDashboardTotals = {
    __typename?: "DashboardTotals";
    invoices: Scalars["Float"];
    turnoverAmount: Scalars["Float"];
    avgTransaction: Scalars["Float"];
};

export enum GQDeleteResponseEnum {
    Successful = "SUCCESSFUL",
    InUse = "IN_USE",
    NotFound = "NOT_FOUND",
}

export type GQDepartment = {
    __typename?: "Department";
    id: Scalars["ID"];
    merchant: GQMerchant;
    name: Scalars["String"];
    externalId?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["String"]>;
    deletedAt?: Maybe<Scalars["Date"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
};

export type GQDepartmentDeleteResponse = {
    __typename?: "DepartmentDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQDepartmentInput = {
    name: Scalars["String"];
    externalId?: Maybe<Scalars["String"]>;
};

export type GQDepartmentPagination = GQIPagination & {
    __typename?: "DepartmentPagination";
    data: Array<GQDepartment>;
    pagination: GQPagination;
};

export type GQDevice = {
    __typename?: "Device";
    id: Scalars["ID"];
    pinCode?: Maybe<Scalars["String"]>;
    gps?: Maybe<GQGpsPoint>;
    osName?: Maybe<Scalars["String"]>;
    osVersion?: Maybe<Scalars["String"]>;
    appVersion?: Maybe<Scalars["String"]>;
    appBuild?: Maybe<Scalars["String"]>;
    serialNumber?: Maybe<Scalars["String"]>;
    hardwareModel?: Maybe<Scalars["String"]>;
    hardwareProducer?: Maybe<Scalars["String"]>;
    macAddress?: Maybe<Scalars["String"]>;
    language?: Maybe<Scalars["String"]>;
    lastSeen: Scalars["Date"];
    createdAt: Scalars["Date"];
};

export type GQDeviceCapabilitiesInput = {
    camera?: Maybe<Scalars["Boolean"]>;
    cameraFront?: Maybe<Scalars["Boolean"]>;
    cameraRear?: Maybe<Scalars["Boolean"]>;
    cashdrawer?: Maybe<Scalars["Boolean"]>;
};

export type GQDeviceInfoInput = {
    id?: Maybe<Scalars["String"]>;
    gps?: Maybe<GQGpsPointInput>;
    osName?: Maybe<Scalars["String"]>;
    osVersion?: Maybe<Scalars["String"]>;
    appVersion?: Maybe<Scalars["String"]>;
    appBuild?: Maybe<Scalars["String"]>;
    serialNumber?: Maybe<Scalars["String"]>;
    hardwareModel?: Maybe<Scalars["String"]>;
    hardwareProducer?: Maybe<Scalars["String"]>;
    macAddress?: Maybe<Scalars["String"]>;
    language?: Maybe<Scalars["String"]>;
    capabilities?: Maybe<GQDeviceCapabilitiesInput>;
    printer?: Maybe<GQDevicePrinterInput>;
};

export type GQDevicePagination = GQIPagination & {
    __typename?: "DevicePagination";
    data: Array<Maybe<GQDevice>>;
    pagination: GQPagination;
};

export type GQDevicePinCodeValidation = {
    __typename?: "DevicePinCodeValidation";
    valid: Scalars["Boolean"];
};

export type GQDevicePrinterInput = {
    feedPost?: Maybe<Scalars["Int"]>;
    charWidth?: Maybe<Scalars["Int"]>;
};

export type GQDiscount = {
    __typename?: "Discount";
    id: Scalars["ID"];
    merchant: GQMerchant;
    name: Scalars["String"];
    lineDescription: Scalars["String"];
    type: GQDiscountType;
    valueType: GQDiscountValueType;
    value: Scalars["Float"];
    maxPerOrder?: Maybe<Scalars["Int"]>;
    itemQuery: Scalars["JSON"];
    conditions: Scalars["JSON"];
    createdAt: Scalars["Date"];
    deletedAt?: Maybe<Scalars["Date"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
    active: Scalars["Boolean"];
};

export type GQDiscountInput = {
    name: Scalars["String"];
    lineDescription: Scalars["String"];
    type: GQDiscountType;
    valueType: GQDiscountValueType;
    value: Scalars["Float"];
    maxPerOrder?: Maybe<Scalars["Int"]>;
    itemQuery: Scalars["JSON"];
    conditions: Scalars["JSON"];
    active: Scalars["Boolean"];
};

export type GQDiscountPagination = GQIPagination & {
    __typename?: "DiscountPagination";
    data: Array<GQDiscount>;
    pagination: GQPagination;
};

export enum GQDiscountType {
    Order = "ORDER",
    Product = "PRODUCT",
    Group = "GROUP",
}

export enum GQDiscountValueType {
    Percentage = "PERCENTAGE",
    Relative = "RELATIVE",
    Absolute = "ABSOLUTE",
}

export type GQEntity = {
    id: Scalars["ID"];
    name?: Maybe<Scalars["String"]>;
};

export type GQEntityConfig = {
    __typename?: "EntityConfig";
    entityId: Scalars["ID"];
    data: Scalars["JSON"];
};

export type GQEntityConfigDeleteResponse = {
    __typename?: "EntityConfigDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQEvent = {
    __typename?: "Event";
    aggregateName: Scalars["String"];
    aggregateId: Scalars["ID"];
    eventName: Scalars["String"];
    data: Scalars["JSON"];
    version: Scalars["Int"];
    eventDate: Scalars["Date"];
    meta: Scalars["JSON"];
    owner: Scalars["ID"];
    ownerSequence: Scalars["Int"];
};

export type GQEventInput = {
    aggregateName: Scalars["String"];
    aggregateId: Scalars["ID"];
    eventName: Scalars["String"];
    data: Scalars["JSON"];
    version: Scalars["Int"];
    eventDate: Scalars["Date"];
    meta: Scalars["JSON"];
    owner: Scalars["ID"];
    ownerSequence: Scalars["Int"];
};

export type GQEventQueryInput = {
    seqStart?: Maybe<Scalars["Int"]>;
    seqEnd?: Maybe<Scalars["Int"]>;
    aggregateName?: Maybe<Array<Scalars["String"]>>;
    aggregateId?: Maybe<Array<Scalars["ID"]>>;
    eventName?: Maybe<Array<Scalars["String"]>>;
    owner?: Maybe<Array<Scalars["ID"]>>;
};

export type GQGpsPoint = {
    __typename?: "GpsPoint";
    latitude: Scalars["Float"];
    longitude: Scalars["Float"];
};

export type GQGpsPointInput = {
    latitude: Scalars["Float"];
    longitude: Scalars["Float"];
};

export type GQIPagination = {
    pagination: GQPagination;
};

export type GQIntegration = {
    __typename?: "Integration";
    id: Scalars["ID"];
    merchant: GQMerchant;
    type: Scalars["String"];
    active: Scalars["Boolean"];
    name: Scalars["String"];
    apiKey: Scalars["String"];
    createdAt?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
    merchantChartOfAccounts: Array<GQMerchantChartOfAccount>;
};

export type GQIntegrationAccount = {
    __typename?: "IntegrationAccount";
    id: Scalars["ID"];
    name: Scalars["String"];
    accountNo: Scalars["Int"];
    integrationType: Scalars["String"];
};

export type GQIntegrationAccounts = {
    __typename?: "IntegrationAccounts";
    data: Array<GQIntegrationAccount>;
};

export type GQIntegrationCreate = {
    type: Scalars["String"];
    active: Scalars["Boolean"];
    name: Scalars["String"];
    apiKey: Scalars["String"];
    merchantId: Scalars["String"];
    merchantChartOfAccounts?: Maybe<
        Array<Maybe<GQMerchantChartOfAccountInput>>
    >;
};

export type GQIntegrationDeleteResponse = {
    __typename?: "IntegrationDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQIntegrationInput = {
    type: Scalars["String"];
    active: Scalars["Boolean"];
    name: Scalars["String"];
    apiKey: Scalars["String"];
    merchantId: Scalars["String"];
    merchantChartOfAccounts?: Maybe<
        Array<Maybe<GQMerchantChartOfAccountInput>>
    >;
};

export type GQIntegrationPagination = GQIPagination & {
    __typename?: "IntegrationPagination";
    data: Array<GQIntegration>;
    pagination: GQPagination;
};

export type GQInventoryProduct = {
    __typename?: "InventoryProduct";
    id: Scalars["ID"];
    name: Scalars["String"];
    merchant: GQMerchant;
    quantity: Scalars["Int"];
    products: Array<GQProduct>;
};

export type GQInventoryProductCreate = {
    name: Scalars["String"];
    quantity: Scalars["Int"];
    productIds: Array<GQProductRelationInput>;
};

export type GQInventoryProductInput = {
    name: Scalars["String"];
    quantity: Scalars["Int"];
    productIds: Array<GQProductRelationInput>;
};

export type GQInventoryProductNoteTemplate = {
    __typename?: "InventoryProductNoteTemplate";
    id: Scalars["ID"];
    note: Scalars["String"];
    merchant: GQMerchant;
};

export type GQInventoryProductNoteTemplateCreate = {
    note: Scalars["String"];
};

export type GQInventoryProductNoteTemplateInput = {
    id: Scalars["ID"];
    note: Scalars["String"];
};

export type GQInventoryProductNoteTemplatePagination = GQIPagination & {
    __typename?: "InventoryProductNoteTemplatePagination";
    data: Array<GQInventoryProductNoteTemplate>;
    pagination: GQPagination;
};

export type GQInventoryProductPagination = GQIPagination & {
    __typename?: "InventoryProductPagination";
    data: Array<GQInventoryProduct>;
    pagination: GQPagination;
};

export type GQInvoiceAmount = {
    __typename?: "InvoiceAmount";
    datetime: Scalars["Date"];
    sum: Scalars["Float"];
};

export type GQInvoiceAmountPerMonth = {
    __typename?: "InvoiceAmountPerMonth";
    year: Scalars["String"];
    monthNumber: Scalars["String"];
    sum: Scalars["Float"];
};

export type GQInvoiceItemExternalRm = {
    __typename?: "InvoiceItemExternalRM";
    itemId: Scalars["String"];
    integration: Scalars["String"];
    checkoutRef?: Maybe<Scalars["String"]>;
    parameters?: Maybe<Scalars["JSON"]>;
    receipt?: Maybe<Scalars["String"]>;
};

export type GQInvoiceItemRm = {
    __typename?: "InvoiceItemRM";
    productId?: Maybe<Scalars["ID"]>;
    name: Scalars["String"];
    quantity: Scalars["Float"];
    amount: Scalars["Float"];
    discountAmount: Scalars["Float"];
    costAmount?: Maybe<Scalars["Float"]>;
    note?: Maybe<Scalars["String"]>;
    external?: Maybe<Array<GQInvoiceItemExternalRm>>;
};

export type GQInvoicePaymentRm = {
    __typename?: "InvoicePaymentRM";
    paymentType: Scalars["String"];
    currency: Scalars["String"];
    amount: Scalars["Float"];
    cardReceipt?: Maybe<Scalars["String"]>;
};

export type GQInvoiceRm = {
    __typename?: "InvoiceRM";
    id: Scalars["ID"];
    shift: GQShiftRm;
    shiftId: Scalars["ID"];
    departmentId?: Maybe<Scalars["ID"]>;
    items?: Maybe<Array<GQInvoiceItemRm>>;
    payments?: Maybe<Array<GQInvoicePaymentRm>>;
    receiptNumber?: Maybe<Scalars["String"]>;
    receipt: Scalars["String"];
    discountAmount: Scalars["Float"];
    amount: Scalars["Float"];
    name?: Maybe<Scalars["String"]>;
    createdAt: Scalars["Date"];
};

export type GQInvoiceRmPagination = GQIPagination & {
    __typename?: "InvoiceRMPagination";
    data: Array<GQInvoiceRm>;
    pagination: GQPagination;
};

export type GQLayout = {
    __typename?: "Layout";
    id: Scalars["ID"];
    name: Scalars["String"];
    columns: Scalars["Int"];
    rows: Scalars["Int"];
    layoutType: Scalars["String"];
    data: Scalars["JSON"];
    products: Array<GQProduct>;
    productGroups: Array<GQProductGroup>;
    createdAt: Scalars["Date"];
    updatedAt?: Maybe<Scalars["Date"]>;
};

export type GQLayoutCreate = {
    name: Scalars["String"];
    dimensions: GQLayoutDimension;
    layoutType: Scalars["String"];
    data: Scalars["JSON"];
};

export type GQLayoutDeleteResponse = {
    __typename?: "LayoutDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQLayoutDimension = {
    columns: Scalars["Int"];
    rows: Scalars["Int"];
};

export type GQLayoutPagination = GQIPagination & {
    __typename?: "LayoutPagination";
    data: Array<GQLayout>;
    pagination: GQPagination;
};

export type GQLayoutSave = {
    name: Scalars["String"];
    data: Scalars["JSON"];
};

export type GQMe = {
    __typename?: "Me";
    cashRegister?: Maybe<GQCashRegister>;
    user?: Maybe<GQUser>;
    merchant?: Maybe<GQMerchant>;
    department?: Maybe<GQDepartment>;
    role?: Maybe<GQRole>;
};

export type GQMerchant = {
    __typename?: "Merchant";
    id: Scalars["ID"];
    serialId: Scalars["Int"];
    name: Scalars["String"];
    giftcardVatBookkeepingMethod: Scalars["String"];
    createdAt?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
    users: Array<GQUser>;
    entityConfig?: Maybe<GQEntityConfig>;
    lastNotifySentAt?: Maybe<Scalars["Date"]>;
};

export type GQMerchantChartOfAccount = {
    __typename?: "MerchantChartOfAccount";
    integrationId: Scalars["String"];
    chartOfAccountsId: Scalars["String"];
    merchantAccountNo: Scalars["String"];
    name: Scalars["String"];
};

export type GQMerchantChartOfAccountInput = {
    chartOfAccountsId: Scalars["String"];
    merchantAccountNo: Scalars["String"];
    name: Scalars["String"];
};

export type GQMerchantCreate = {
    name: Scalars["String"];
    email: Scalars["String"];
    currency: Scalars["String"];
    vat: Scalars["Int"];
    giftcardVatBookkeepingMethod: Scalars["String"];
};

export type GQMerchantDeleteResponse = {
    __typename?: "MerchantDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQMerchantInput = {
    name: Scalars["String"];
};

export type GQMerchantPagination = GQIPagination & {
    __typename?: "MerchantPagination";
    data: Array<GQMerchant>;
    pagination: GQPagination;
};

export type GQMiscButtons = {
    __typename?: "MiscButtons";
    id: Scalars["ID"];
    merchant: GQMerchant;
    name: Scalars["String"];
    buttons: Scalars["JSON"];
    createdAt: Scalars["Date"];
    deletedAt?: Maybe<Scalars["Date"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
};

export type GQMiscButtonsDeleteResponse = {
    __typename?: "MiscButtonsDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQMiscButtonsInput = {
    name: Scalars["String"];
    buttons: Scalars["JSON"];
};

export type GQMiscButtonsPagination = GQIPagination & {
    __typename?: "MiscButtonsPagination";
    data: Array<GQMiscButtons>;
    pagination: GQPagination;
};

export type GQMutation = {
    __typename?: "Mutation";
    accountAssignTags: GQAccountAssignTagsRelationResponse;
    accountBuyFunds: GQAccountBuyFundsResponse;
    accountClose: GQAccountCloseResponse;
    accountCreate: GQAccount;
    accountOpen: GQAccountOpenResponse;
    accountPayAmount: GQAccountPayAmountResponse;
    accountRefundAmount: GQAccountRefundAmountResponse;
    accountReturnFunds: GQAccountReturnFundsResponse;
    accountRevertTransaction: GQAccountRevertTransactionResponse;
    accountSave: GQAccount;
    authLogin: GQAuthAttempt;
    authSelectUserRole: GQSelectUserRoleType;
    authUpdateLogin: GQAuthAttempt;
    cashRegisterClaim?: Maybe<GQCashRegister>;
    cashRegisterCreate: GQCashRegister;
    cashRegisterDelete: GQCashRegisterDeleteResponse;
    cashRegisterEdit: GQCashRegister;
    cashRegistersDelete: GQCashRegisterDeleteResponse;
    currencyCreate: GQCurrency;
    currencyDelete: GQCurrencyDeleteResponse;
    currencySave: GQCurrency;
    customerAssignTags: GQCustomerAssignTagsRelationResponse;
    customerCreate: GQCustomer;
    customerDelete: GQCustomerDeleteResponse;
    customerGroupCreate: GQCustomerGroup;
    customerGroupDelete: GQCustomerGroupDeleteResponse;
    customerGroupSave: GQCustomerGroup;
    customerSave: GQCustomer;
    departmentCreate: GQDepartment;
    departmentDelete: GQDepartmentDeleteResponse;
    departmentSave: GQDepartment;
    deviceHeartbeat: Scalars["Boolean"];
    deviceRegister: GQDevice;
    discountCreate: GQDiscount;
    discountDelete: GQBooleanResponse;
    discountSave: GQDiscount;
    discountsActiveToggle: GQBooleanResponse;
    entityConfigDelete: GQEntityConfigDeleteResponse;
    entityConfigSave: GQEntityConfig;
    eventStoreSyncDispatch: GQAddEventResponse;
    integrationCreate: GQIntegration;
    integrationDelete: GQIntegrationDeleteResponse;
    integrationSave: GQIntegration;
    inventoryProductNoteTemplateCreate: GQInventoryProductNoteTemplate;
    inventoryProductNoteTemplateDelete: GQBooleanResponse;
    inventoryProductNoteTemplateSave: GQInventoryProductNoteTemplate;
    inventoryProductUpdate: GQInventoryProduct;
    layoutCopy: GQLayout;
    layoutCreate: GQLayout;
    layoutDelete: GQLayoutDeleteResponse;
    layoutSave: GQLayout;
    merchantCreate: GQMerchant;
    merchantDelete: GQMerchantDeleteResponse;
    merchantSave: GQMerchant;
    miscButtonsCreate: GQMiscButtons;
    miscButtonsDelete: GQMiscButtonsDeleteResponse;
    miscButtonsSave: GQMiscButtons;
    paymentConfigurationCreate: GQPaymentConfiguration;
    paymentConfigurationDelete: GQPaymentConfigurationDeleteResponse;
    paymentConfigurationSave: GQPaymentConfiguration;
    paymentMethodCreate: GQPaymentMethod;
    paymentMethodDelete: GQPaymentMethodDeleteResponse;
    paymentMethodSave: GQPaymentMethod;
    printerDelete: GQPrinterDeleteResponse;
    printerRegister: GQPrinter;
    printerSave: GQPrinter;
    productCreate: GQProduct;
    productDelete: GQProductDeleteResponse;
    productGroupCreate: GQProductGroup;
    productGroupDelete: GQProductGroupDeleteResponse;
    productGroupSave: GQProductGroup;
    productSave: GQProduct;
    productsDelete: GQProductDeleteResponse;
    roleCreate: GQRole;
    roleDelete: GQRoleDeleteResponse;
    roleSave: GQRole;
    setInventoryProductQuantity: GQInventoryProduct;
    tagCreate: GQTag;
    tagDelete: GQTagDeleteResponse;
    tagGroupCreate: GQTagGroup;
    tagGroupDelete: GQTagGroupDeleteResponse;
    tagGroupSave: GQTagGroup;
    tagSave: GQTag;
    userCreate: GQUser;
    userDelete: GQUserDeleteResponse;
    userRoleConfigurationSave: GQBooleanResponse;
    userSave: GQUser;
    userUpdatePassword: GQBooleanResponse;
    vatCreate: GQVat;
    vatDelete: GQVatDeleteResponse;
    venueAccessCancelAccessTicket: GQVenueAccessTicketCancelled;
    venueAccessCreateAccessTicket: GQVenueAccessTicketCreated;
};

export type GQMutationAccountAssignTagsArgs = {
    id: Scalars["ID"];
    tagIds?: Maybe<Array<Scalars["ID"]>>;
};

export type GQMutationAccountBuyFundsArgs = {
    id: Scalars["ID"];
    amount: Scalars["Float"];
    orderId: Scalars["ID"];
    userId: Scalars["ID"];
    tagId: Scalars["ID"];
    tagIdentifier: Scalars["String"];
};

export type GQMutationAccountCloseArgs = {
    id: Scalars["ID"];
};

export type GQMutationAccountCreateArgs = {
    account: GQAccountCreate;
};

export type GQMutationAccountOpenArgs = {
    id: Scalars["ID"];
};

export type GQMutationAccountPayAmountArgs = {
    id: Scalars["ID"];
    orderId: Scalars["ID"];
    amount: Scalars["Float"];
    userId: Scalars["ID"];
    tagId?: Maybe<Scalars["ID"]>;
    tagIdentifier?: Maybe<Scalars["String"]>;
};

export type GQMutationAccountRefundAmountArgs = {
    id: Scalars["ID"];
    orderId: Scalars["ID"];
    amount: Scalars["Float"];
    userId: Scalars["ID"];
    tagId?: Maybe<Scalars["ID"]>;
    tagIdentifier?: Maybe<Scalars["String"]>;
};

export type GQMutationAccountReturnFundsArgs = {
    id: Scalars["ID"];
    amount: Scalars["Float"];
    orderId: Scalars["ID"];
    userId: Scalars["ID"];
    tagId: Scalars["ID"];
    tagIdentifier: Scalars["String"];
};

export type GQMutationAccountRevertTransactionArgs = {
    id: Scalars["ID"];
    transactionId: Scalars["ID"];
    userId: Scalars["ID"];
};

export type GQMutationAccountSaveArgs = {
    id: Scalars["ID"];
    account: GQAccountInput;
};

export type GQMutationAuthLoginArgs = {
    username: Scalars["String"];
    password: Scalars["String"];
};

export type GQMutationAuthSelectUserRoleArgs = {
    roleId: Scalars["ID"];
    merchantId?: Maybe<Scalars["ID"]>;
};

export type GQMutationCashRegisterClaimArgs = {
    id: Scalars["ID"];
    devicePinCode: Scalars["String"];
};

export type GQMutationCashRegisterCreateArgs = {
    cashRegister: GQCashRegisterCreateInput;
};

export type GQMutationCashRegisterDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationCashRegisterEditArgs = {
    id: Scalars["ID"];
    cashRegister: GQCashRegisterEditInput;
};

export type GQMutationCashRegistersDeleteArgs = {
    ids: Array<Scalars["ID"]>;
};

export type GQMutationCurrencyCreateArgs = {
    currency: GQCurrencyInput;
};

export type GQMutationCurrencyDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationCurrencySaveArgs = {
    id: Scalars["ID"];
    currency: GQCurrencyInput;
};

export type GQMutationCustomerAssignTagsArgs = {
    id: Scalars["ID"];
    tagIds?: Maybe<Array<Scalars["ID"]>>;
};

export type GQMutationCustomerCreateArgs = {
    customer: GQCustomerInput;
};

export type GQMutationCustomerDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationCustomerGroupCreateArgs = {
    customerGroup: GQCustomerGroupInput;
};

export type GQMutationCustomerGroupDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationCustomerGroupSaveArgs = {
    id: Scalars["ID"];
    customerGroup: GQCustomerGroupInput;
};

export type GQMutationCustomerSaveArgs = {
    id: Scalars["ID"];
    customer: GQCustomerInput;
};

export type GQMutationDepartmentCreateArgs = {
    department: GQDepartmentInput;
};

export type GQMutationDepartmentDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationDepartmentSaveArgs = {
    id: Scalars["ID"];
    department: GQDepartmentInput;
};

export type GQMutationDeviceHeartbeatArgs = {
    deviceInfo: GQDeviceInfoInput;
};

export type GQMutationDeviceRegisterArgs = {
    deviceInfo: GQDeviceInfoInput;
    newPinCode?: Maybe<Scalars["Boolean"]>;
};

export type GQMutationDiscountCreateArgs = {
    discount: GQDiscountInput;
};

export type GQMutationDiscountDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationDiscountSaveArgs = {
    id: Scalars["ID"];
    discount: GQDiscountInput;
};

export type GQMutationDiscountsActiveToggleArgs = {
    ids: Array<Scalars["ID"]>;
};

export type GQMutationEntityConfigDeleteArgs = {
    entityId: Scalars["ID"];
};

export type GQMutationEntityConfigSaveArgs = {
    entityId: Scalars["ID"];
    partialData: Scalars["JSON"];
    disabledData: Array<Scalars["String"]>;
};

export type GQMutationEventStoreSyncDispatchArgs = {
    events: Array<GQEventInput>;
};

export type GQMutationIntegrationCreateArgs = {
    integration: GQIntegrationCreate;
};

export type GQMutationIntegrationDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationIntegrationSaveArgs = {
    id: Scalars["ID"];
    integration: GQIntegrationInput;
};

export type GQMutationInventoryProductNoteTemplateCreateArgs = {
    inventoryProductNoteTemplate: GQInventoryProductNoteTemplateCreate;
};

export type GQMutationInventoryProductNoteTemplateDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationInventoryProductNoteTemplateSaveArgs = {
    id: Scalars["ID"];
    inventoryProductNoteTemplate: GQInventoryProductNoteTemplateInput;
};

export type GQMutationInventoryProductUpdateArgs = {
    id: Scalars["ID"];
    inventoryProduct: GQInventoryProductInput;
};

export type GQMutationLayoutCopyArgs = {
    id: Scalars["ID"];
    newTitle: Scalars["String"];
};

export type GQMutationLayoutCreateArgs = {
    layout: GQLayoutCreate;
};

export type GQMutationLayoutDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationLayoutSaveArgs = {
    id: Scalars["ID"];
    layout: GQLayoutSave;
};

export type GQMutationMerchantCreateArgs = {
    merchant: GQMerchantCreate;
};

export type GQMutationMerchantDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationMerchantSaveArgs = {
    id: Scalars["ID"];
    merchant: GQMerchantInput;
};

export type GQMutationMiscButtonsCreateArgs = {
    miscButtons: GQMiscButtonsInput;
};

export type GQMutationMiscButtonsDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationMiscButtonsSaveArgs = {
    id: Scalars["ID"];
    miscButtons: GQMiscButtonsInput;
};

export type GQMutationPaymentConfigurationCreateArgs = {
    paymentConfiguration: GQPaymentConfigurationInput;
};

export type GQMutationPaymentConfigurationDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationPaymentConfigurationSaveArgs = {
    id: Scalars["ID"];
    paymentConfiguration: GQPaymentConfigurationInput;
};

export type GQMutationPaymentMethodCreateArgs = {
    paymentMethod: GQPaymentMethodInput;
};

export type GQMutationPaymentMethodDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationPaymentMethodSaveArgs = {
    id: Scalars["ID"];
    paymentMethod: GQPaymentMethodInput;
};

export type GQMutationPrinterDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationPrinterRegisterArgs = {
    printer: GQPrinterRegister;
};

export type GQMutationPrinterSaveArgs = {
    id: Scalars["ID"];
    printer: GQPrinterSave;
};

export type GQMutationProductCreateArgs = {
    product: GQProductInput;
};

export type GQMutationProductDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationProductGroupCreateArgs = {
    productGroup: GQProductGroupInput;
};

export type GQMutationProductGroupDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationProductGroupSaveArgs = {
    id: Scalars["ID"];
    productGroup: GQProductGroupInput;
};

export type GQMutationProductSaveArgs = {
    id: Scalars["ID"];
    product: GQProductInput;
};

export type GQMutationProductsDeleteArgs = {
    ids: Array<Scalars["ID"]>;
};

export type GQMutationRoleCreateArgs = {
    role: GQRoleInput;
};

export type GQMutationRoleDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationRoleSaveArgs = {
    id: Scalars["ID"];
    role: GQRoleInput;
};

export type GQMutationSetInventoryProductQuantityArgs = {
    id?: Maybe<Scalars["ID"]>;
    productId: Scalars["ID"];
    quantity: Scalars["Int"];
    note?: Maybe<Scalars["String"]>;
};

export type GQMutationTagCreateArgs = {
    tag: GQTagInput;
};

export type GQMutationTagDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationTagGroupCreateArgs = {
    tagGroup: GQTagGroupInput;
};

export type GQMutationTagGroupDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationTagGroupSaveArgs = {
    id: Scalars["ID"];
    tagGroup: GQTagGroupInput;
};

export type GQMutationTagSaveArgs = {
    id: Scalars["ID"];
    tag: GQTagInput;
};

export type GQMutationUserCreateArgs = {
    user: GQUserInput;
};

export type GQMutationUserDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationUserRoleConfigurationSaveArgs = {
    userId: Scalars["ID"];
    merchantId?: Maybe<Scalars["ID"]>;
    roleId: Scalars["ID"];
    type: Scalars["String"];
    configuration: Scalars["JSON"];
};

export type GQMutationUserSaveArgs = {
    id: Scalars["ID"];
    user: GQUserInput;
};

export type GQMutationUserUpdatePasswordArgs = {
    id: Scalars["ID"];
    password: Scalars["String"];
};

export type GQMutationVatCreateArgs = {
    vat: GQVatCreateInput;
};

export type GQMutationVatDeleteArgs = {
    id: Scalars["ID"];
};

export type GQMutationVenueAccessCancelAccessTicketArgs = {
    ticketId: Scalars["Int"];
};

export type GQMutationVenueAccessCreateAccessTicketArgs = {
    eventId: Scalars["Int"];
    accessTypes: Array<Scalars["Int"]>;
    validFrom: Scalars["Date"];
    validTo: Scalars["Date"];
};

export type GQPos = GQEntity & {
    __typename?: "POS";
    id: Scalars["ID"];
    name?: Maybe<Scalars["String"]>;
    device: GQDevice;
    configuration?: Maybe<GQConfiguration>;
    partner?: Maybe<GQPartner>;
    merchant?: Maybe<GQMerchant>;
    tags: Array<Scalars["String"]>;
    department?: Maybe<GQDepartment>;
    firstActive?: Maybe<Scalars["Date"]>;
    lastActive?: Maybe<Scalars["Date"]>;
    firstLocation?: Maybe<GQGpsPoint>;
    lastLocation?: Maybe<GQGpsPoint>;
    firstUser?: Maybe<GQUser>;
    lastUser?: Maybe<GQUser>;
};

export type GQPagination = {
    __typename?: "Pagination";
    pageSize: Scalars["Int"];
    page: Scalars["Int"];
    pages: Scalars["Int"];
    sort: Scalars["String"];
    sortDirection: Scalars["String"];
    resultCount: Scalars["Int"];
};

export type GQPaginationInput = {
    pageSize?: Maybe<Scalars["Int"]>;
    page?: Maybe<Scalars["Int"]>;
    sort?: Maybe<Scalars["String"]>;
    sortDirection?: Maybe<Scalars["String"]>;
};

export type GQPartner = GQEntity & {
    __typename?: "Partner";
    id: Scalars["ID"];
    name?: Maybe<Scalars["String"]>;
};

export type GQPaymentConfiguration = {
    __typename?: "PaymentConfiguration";
    id: Scalars["ID"];
    name: Scalars["String"];
    paymentMethods: Array<GQPaymentConfigurationMethod>;
    createdAt: Scalars["String"];
    updatedAt?: Maybe<Scalars["Date"]>;
};

export type GQPaymentConfigurationDeleteResponse = {
    __typename?: "PaymentConfigurationDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQPaymentConfigurationInput = {
    name: Scalars["String"];
    paymentMethods: Array<GQPaymentConfigurationMethodInput>;
};

export type GQPaymentConfigurationMethod = {
    __typename?: "PaymentConfigurationMethod";
    paymentMethod: GQPaymentMethod;
    showAsQuickButton: Scalars["Boolean"];
    showInPaymentWindow: Scalars["Boolean"];
};

export type GQPaymentConfigurationMethodInput = {
    methodId: Scalars["String"];
    showAsQuickButton: Scalars["Boolean"];
    showInPaymentWindow: Scalars["Boolean"];
};

export type GQPaymentConfigurationPagination = GQIPagination & {
    __typename?: "PaymentConfigurationPagination";
    data: Array<GQPaymentConfiguration>;
    pagination: GQPagination;
};

export type GQPaymentMethod = {
    __typename?: "PaymentMethod";
    id: Scalars["ID"];
    name: Scalars["String"];
    paymentType: Scalars["String"];
    enabled: Scalars["Boolean"];
    account: Scalars["String"];
    diffAccount: Scalars["String"];
    currency: GQCurrency;
    createdAt: Scalars["String"];
    updatedAt?: Maybe<Scalars["Date"]>;
};

export type GQPaymentMethodDeleteResponse = {
    __typename?: "PaymentMethodDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQPaymentMethodInput = {
    name: Scalars["String"];
    paymentType: Scalars["String"];
    enabled: Scalars["Boolean"];
    account: Scalars["String"];
    diffAccount: Scalars["String"];
    currencyId: Scalars["String"];
};

export type GQPaymentMethodPagination = GQIPagination & {
    __typename?: "PaymentMethodPagination";
    data: Array<GQPaymentMethod>;
    pagination: GQPagination;
};

export type GQPrinter = {
    __typename?: "Printer";
    id: Scalars["ID"];
    merchant: GQMerchant;
    cashRegister?: Maybe<GQCashRegister>;
    name: Scalars["String"];
    serial: Scalars["String"];
    type: GQPrinterType;
    class: GQPrinterClass;
    manufacturer: GQPrinterManufacturer;
    createdAt: Scalars["Date"];
    updatedAt?: Maybe<Scalars["Date"]>;
};

export enum GQPrinterClass {
    Ticket = "Ticket",
    Generic = "Generic",
}

export type GQPrinterDeleteResponse = {
    __typename?: "PrinterDeleteResponse";
    status: GQDeleteResponseEnum;
};

export enum GQPrinterManufacturer {
    Epson = "Epson",
    Boca = "Boca",
    Pax = "Pax",
}

export type GQPrinterPagination = GQIPagination & {
    __typename?: "PrinterPagination";
    data: Array<GQPrinter>;
    pagination: GQPagination;
};

export type GQPrinterRegister = {
    name: Scalars["String"];
    serial: Scalars["String"];
    type: GQPrinterType;
    manufacturer: GQPrinterManufacturer;
    class?: Maybe<GQPrinterClass>;
};

export type GQPrinterSave = {
    name: Scalars["String"];
};

export enum GQPrinterType {
    Bt = "BT",
    Mac = "MAC",
    Ip = "IP",
    Usb = "USB",
    Internal = "INTERNAL",
}

export type GQProduct = {
    __typename?: "Product";
    id: Scalars["ID"];
    parent?: Maybe<GQProduct>;
    children: GQProductPagination;
    group: GQProductGroup;
    externalId?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    amount: Scalars["Int"];
    minimumAmount: Scalars["Int"];
    costAmount: Scalars["Int"];
    vat: GQVat;
    ownVat?: Maybe<GQVat>;
    onlineRequired: Scalars["Boolean"];
    active: Scalars["Boolean"];
    isExternal: Scalars["Boolean"];
    excludeFromDiscounts: Scalars["Boolean"];
    ownExcludeFromDiscounts: Scalars["Boolean"];
    barcode?: Maybe<Scalars["String"]>;
    barcodes: Array<GQProductBarcode>;
    buttonText?: Maybe<Scalars["String"]>;
    printer?: Maybe<GQPrinter>;
    inheritPrinter: Scalars["Boolean"];
    inventoryProduct?: Maybe<GQInventoryProduct>;
    createdAt: Scalars["Date"];
    deletedAt?: Maybe<Scalars["Date"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
};

export type GQProductChildrenArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQProductBarcode = {
    __typename?: "ProductBarcode";
    id: Scalars["ID"];
    barcode: Scalars["String"];
    createdAt: Scalars["Date"];
    deletedAt?: Maybe<Scalars["Date"]>;
    updatedAt: Scalars["Date"];
};

export type GQProductBarcodeInput = {
    id?: Maybe<Scalars["ID"]>;
    barcode: Scalars["String"];
};

export type GQProductDeleteResponse = {
    __typename?: "ProductDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQProductGroup = {
    __typename?: "ProductGroup";
    id: Scalars["ID"];
    externalId?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    vat: GQVat;
    color: Scalars["String"];
    excludeFromDiscounts: Scalars["Boolean"];
    isExternal: Scalars["Boolean"];
    printer?: Maybe<GQPrinter>;
    createdAt: Scalars["Date"];
    deletedAt?: Maybe<Scalars["Date"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
};

export type GQProductGroupDeleteResponse = {
    __typename?: "ProductGroupDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQProductGroupInput = {
    externalId?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    vatId: Scalars["ID"];
    color: Scalars["String"];
    excludeFromDiscounts: Scalars["Boolean"];
    isExternal: Scalars["Boolean"];
    printerId?: Maybe<Scalars["ID"]>;
};

export type GQProductGroupPagination = GQIPagination & {
    __typename?: "ProductGroupPagination";
    data: Array<GQProductGroup>;
    pagination: GQPagination;
};

export type GQProductInput = {
    parentId?: Maybe<Scalars["ID"]>;
    groupId?: Maybe<Scalars["ID"]>;
    externalId?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    amount: Scalars["Int"];
    minimumAmount: Scalars["Int"];
    costAmount: Scalars["Int"];
    ownVatId?: Maybe<Scalars["ID"]>;
    onlineRequired: Scalars["Boolean"];
    active: Scalars["Boolean"];
    isExternal: Scalars["Boolean"];
    ownExcludeFromDiscounts: Scalars["Boolean"];
    barcode?: Maybe<Scalars["String"]>;
    barcodes: Array<GQProductBarcodeInput>;
    buttonText?: Maybe<Scalars["String"]>;
    printerId?: Maybe<Scalars["ID"]>;
    inheritPrinter: Scalars["Boolean"];
};

export type GQProductPagination = GQIPagination & {
    __typename?: "ProductPagination";
    data: Array<GQProduct>;
    pagination: GQPagination;
};

export type GQProductRelationInput = {
    productId: Scalars["String"];
};

export type GQQuery = {
    __typename?: "Query";
    account: GQAccount;
    accounts: GQAccountPagination;
    accountsByCustomerSearch: GQAccountPagination;
    allMiscButtons: GQMiscButtonsPagination;
    authDevicePoll?: Maybe<GQAuthToken>;
    authRefresh?: Maybe<GQAuthToken>;
    bookkeepingAccount: GQBookkeepingAccount;
    bookkeepingAccounts: GQAccountBookkeepingPagination;
    bookkeepingTransactions: GQAccountBookkeepingTransactionPagination;
    cardTransaction: GQCardTransaction;
    cardTransactions: GQCardTransactionPagination;
    cashRegister?: Maybe<GQCashRegister>;
    cashRegisters: GQCashRegisterPagination;
    cashRegistersStatus: GQCashRegisterStatusPagination;
    currencies: GQCurrencyPagination;
    currency?: Maybe<GQCurrency>;
    customer: GQCustomer;
    customerAccounts: GQAccountPagination;
    customerGroup: GQCustomerGroup;
    customerGroups: GQCustomerGroupPagination;
    customers: GQCustomerPagination;
    customersInGroup: GQCustomerPagination;
    dashboard?: Maybe<GQDashboard>;
    dashboardCashRegisterStats: Array<GQCashRegisterVersion>;
    dashboardCashRegisters: GQDashboardCashRegisterPagination;
    dashboardInvoiceAmountPerMonthStats?: Maybe<Array<GQInvoiceAmountPerMonth>>;
    dashboardInvoiceAmountStats?: Maybe<Array<GQInvoiceAmount>>;
    department?: Maybe<GQDepartment>;
    departments: GQDepartmentPagination;
    device?: Maybe<GQDevice>;
    deviceByPinCode?: Maybe<GQDevice>;
    devices: GQDevicePagination;
    discount?: Maybe<GQDiscount>;
    discounts: GQDiscountPagination;
    entityConfig: GQEntityConfig;
    entityConfigs: Array<Maybe<GQEntityConfig>>;
    eventStoreSyncGet: Array<GQEvent>;
    eventStoreSyncLatestSequence: Scalars["Int"];
    giftCards: GQAccountPagination;
    integration?: Maybe<GQIntegration>;
    integrationAccounts?: Maybe<Array<Maybe<GQIntegrationAccount>>>;
    integrations: GQIntegrationPagination;
    inventoryForIds: Array<GQInventoryProduct>;
    inventoryProduct?: Maybe<GQInventoryProduct>;
    inventoryProductNoteTemplate?: Maybe<GQInventoryProductNoteTemplate>;
    inventoryProductNoteTemplates: GQInventoryProductNoteTemplatePagination;
    inventoryProducts: GQInventoryProductPagination;
    invoice?: Maybe<GQInvoiceRm>;
    invoices: GQInvoiceRmPagination;
    layout?: Maybe<GQLayout>;
    me: GQMe;
    merchant?: Maybe<GQMerchant>;
    merchantAccountNo: GQChartOfAccountNo;
    merchants: GQMerchantPagination;
    miscButtons?: Maybe<GQMiscButtons>;
    paymentConfiguration: GQPaymentConfiguration;
    paymentConfigurations: GQPaymentConfigurationPagination;
    paymentMethod: GQPaymentMethod;
    paymentMethods: GQPaymentMethodPagination;
    printer: GQPrinter;
    printers: GQPrinterPagination;
    printersForCashRegister: GQPrinterPagination;
    product?: Maybe<GQProduct>;
    productGroup?: Maybe<GQProductGroup>;
    productGroups: GQProductGroupPagination;
    productLayouts: GQLayoutPagination;
    products: GQProductPagination;
    reportParameters: GQReportParameters;
    role?: Maybe<GQRole>;
    roles: GQRolePagination;
    shift?: Maybe<GQShiftRm>;
    shifts: GQShiftRmPagination;
    shiftsByCashRegister: GQShiftRmPagination;
    shiftsByOpenedByUser: GQShiftRmPagination;
    tableLayouts: GQLayoutPagination;
    tag: GQTag;
    tagGroup: GQTagGroup;
    tagGroups: GQTagGroupPagination;
    tags: GQTagPagination;
    tagsByAccount: GQTagPagination;
    tagsByCustomer: GQTagPagination;
    tagsByGroup: GQTagPagination;
    tagsWithoutAccount: GQTagPagination;
    tagsWithoutCustomer: GQTagPagination;
    transactions: GQAccountTransactionPagination;
    user?: Maybe<GQUser>;
    userAvailability: Scalars["Boolean"];
    userRoleConfiguration?: Maybe<GQUserRoleConfiguration>;
    users: GQUserPagination;
    vat?: Maybe<GQVat>;
    vats: GQVatPagination;
    venueAccessTicketParameters: GQVenueAccessParameters;
};

export type GQQueryAccountArgs = {
    id: Scalars["ID"];
};

export type GQQueryAccountsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryAccountsByCustomerSearchArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryAllMiscButtonsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryAuthDevicePollArgs = {
    deviceId: Scalars["ID"];
    devicePinCode: Scalars["String"];
};

export type GQQueryAuthRefreshArgs = {
    refreshToken: Scalars["String"];
};

export type GQQueryBookkeepingAccountArgs = {
    id: Scalars["ID"];
};

export type GQQueryBookkeepingAccountsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryBookkeepingTransactionsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    accountId?: Maybe<Scalars["String"]>;
};

export type GQQueryCardTransactionArgs = {
    id: Scalars["ID"];
};

export type GQQueryCardTransactionsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryCashRegisterArgs = {
    id: Scalars["ID"];
};

export type GQQueryCashRegistersArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryCashRegistersStatusArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryCurrenciesArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryCurrencyArgs = {
    id: Scalars["ID"];
};

export type GQQueryCustomerArgs = {
    id: Scalars["ID"];
};

export type GQQueryCustomerAccountsArgs = {
    customerId?: Maybe<Scalars["ID"]>;
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryCustomerGroupArgs = {
    id: Scalars["ID"];
};

export type GQQueryCustomerGroupsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryCustomersArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryCustomersInGroupArgs = {
    groupId: Scalars["ID"];
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryDashboardArgs = {
    from: Scalars["Date"];
    to: Scalars["Date"];
    departmentIds?: Maybe<Array<Scalars["String"]>>;
};

export type GQQueryDashboardCashRegistersArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryDashboardInvoiceAmountStatsArgs = {
    from: Scalars["Date"];
    to: Scalars["Date"];
};

export type GQQueryDepartmentArgs = {
    id: Scalars["ID"];
};

export type GQQueryDepartmentsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryDeviceArgs = {
    id: Scalars["String"];
};

export type GQQueryDeviceByPinCodeArgs = {
    pinCode: Scalars["String"];
};

export type GQQueryDevicesArgs = {
    pagination?: Maybe<GQPaginationInput>;
};

export type GQQueryDiscountArgs = {
    id: Scalars["ID"];
};

export type GQQueryDiscountsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryEntityConfigArgs = {
    entityId: Scalars["ID"];
};

export type GQQueryEntityConfigsArgs = {
    entityIds: Array<Scalars["ID"]>;
};

export type GQQueryEventStoreSyncGetArgs = {
    query: GQEventQueryInput;
};

export type GQQueryEventStoreSyncLatestSequenceArgs = {
    returnSyncFrom?: Maybe<Scalars["Boolean"]>;
};

export type GQQueryGiftCardsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryIntegrationArgs = {
    id: Scalars["ID"];
};

export type GQQueryIntegrationAccountsArgs = {
    integrationType?: Maybe<Scalars["String"]>;
};

export type GQQueryIntegrationsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryInventoryForIdsArgs = {
    ids: Array<Scalars["ID"]>;
};

export type GQQueryInventoryProductArgs = {
    id: Scalars["ID"];
};

export type GQQueryInventoryProductNoteTemplateArgs = {
    id: Scalars["ID"];
};

export type GQQueryInventoryProductNoteTemplatesArgs = {
    pagination?: Maybe<GQPaginationInput>;
};

export type GQQueryInventoryProductsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryInvoiceArgs = {
    id: Scalars["ID"];
};

export type GQQueryInvoicesArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryLayoutArgs = {
    id: Scalars["ID"];
};

export type GQQueryMerchantArgs = {
    id: Scalars["ID"];
};

export type GQQueryMerchantAccountNoArgs = {
    merchantId: Scalars["ID"];
    defaultAccountNo: Scalars["String"];
    integrationType: Scalars["String"];
};

export type GQQueryMerchantsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryMiscButtonsArgs = {
    id: Scalars["ID"];
};

export type GQQueryPaymentConfigurationArgs = {
    id: Scalars["ID"];
};

export type GQQueryPaymentConfigurationsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryPaymentMethodArgs = {
    id: Scalars["ID"];
};

export type GQQueryPaymentMethodsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryPrinterArgs = {
    id: Scalars["ID"];
};

export type GQQueryPrintersArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryPrintersForCashRegisterArgs = {
    pagination?: Maybe<GQPaginationInput>;
    class?: Maybe<Scalars["String"]>;
    entityId?: Maybe<Scalars["String"]>;
};

export type GQQueryProductArgs = {
    id: Scalars["ID"];
};

export type GQQueryProductGroupArgs = {
    id: Scalars["ID"];
};

export type GQQueryProductGroupsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryProductLayoutsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryProductsArgs = {
    includeChildren?: Maybe<Scalars["Boolean"]>;
    includeInactive?: Maybe<Scalars["Boolean"]>;
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryRoleArgs = {
    id: Scalars["ID"];
};

export type GQQueryRolesArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
    userId?: Maybe<Scalars["ID"]>;
    merchantId?: Maybe<Scalars["ID"]>;
};

export type GQQueryShiftArgs = {
    id: Scalars["ID"];
};

export type GQQueryShiftsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryShiftsByCashRegisterArgs = {
    cashRegisterId: Scalars["String"];
    pagination?: Maybe<GQPaginationInput>;
};

export type GQQueryShiftsByOpenedByUserArgs = {
    userId: Scalars["String"];
    pagination?: Maybe<GQPaginationInput>;
};

export type GQQueryTableLayoutsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryTagArgs = {
    id: Scalars["ID"];
};

export type GQQueryTagGroupArgs = {
    id: Scalars["ID"];
};

export type GQQueryTagGroupsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryTagsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
    tagIds?: Maybe<Array<Scalars["ID"]>>;
};

export type GQQueryTagsByAccountArgs = {
    accountId: Scalars["ID"];
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryTagsByCustomerArgs = {
    customerId: Scalars["ID"];
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryTagsByGroupArgs = {
    groupId: Scalars["ID"];
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryTagsWithoutAccountArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryTagsWithoutCustomerArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryTransactionsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    accountId?: Maybe<Scalars["String"]>;
};

export type GQQueryUserArgs = {
    id: Scalars["ID"];
};

export type GQQueryUserAvailabilityArgs = {
    username: Scalars["String"];
};

export type GQQueryUserRoleConfigurationArgs = {
    userId: Scalars["ID"];
    merchantId?: Maybe<Scalars["ID"]>;
    roleId: Scalars["ID"];
    type: Scalars["String"];
};

export type GQQueryUsersArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryVatArgs = {
    id: Scalars["ID"];
};

export type GQQueryVatsArgs = {
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
};

export type GQQueryVenueAccessTicketParametersArgs = {
    eventId?: Maybe<Scalars["Int"]>;
};

export type GQReportCashRegister = {
    __typename?: "ReportCashRegister";
    id: Scalars["ID"];
    name: Scalars["String"];
};

export type GQReportDepartment = {
    __typename?: "ReportDepartment";
    id: Scalars["ID"];
    name: Scalars["String"];
};

export type GQReportParameters = {
    __typename?: "ReportParameters";
    departments: Array<GQReportDepartment>;
    cashRegisters: Array<GQReportCashRegister>;
    users: Array<GQReportUser>;
    productGroups: Array<GQReportProductGroup>;
    products: Array<GQReportProduct>;
};

export type GQReportProduct = {
    __typename?: "ReportProduct";
    id: Scalars["ID"];
    name: Scalars["String"];
};

export type GQReportProductGroup = {
    __typename?: "ReportProductGroup";
    id: Scalars["ID"];
    name: Scalars["String"];
};

export type GQReportUser = {
    __typename?: "ReportUser";
    id: Scalars["ID"];
    username: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
};

export type GQRole = {
    __typename?: "Role";
    id: Scalars["ID"];
    name: Scalars["String"];
    type: GQRoleTypeEnum;
    shared: Scalars["Boolean"];
    merchant?: Maybe<GQMerchant>;
    inUse: Scalars["Boolean"];
    permissions: Array<GQRolePermission>;
};

export type GQRoleDeleteResponse = {
    __typename?: "RoleDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQRoleInput = {
    name: Scalars["String"];
    type: GQRoleTypeEnum;
    shared: Scalars["Boolean"];
    permissions: Array<GQRolePermissionInput>;
};

export type GQRolePagination = GQIPagination & {
    __typename?: "RolePagination";
    data: Array<GQRole>;
    pagination: GQPagination;
};

export type GQRolePermission = {
    __typename?: "RolePermission";
    role: GQRole;
    key: Scalars["String"];
    value: Scalars["String"];
};

export type GQRolePermissionInput = {
    key: Scalars["String"];
    value: Scalars["String"];
};

export enum GQRoleTypeEnum {
    Admin = "ADMIN",
    Reseller = "RESELLER",
    Merchant = "MERCHANT",
}

export type GQSearchInput = {
    query: Scalars["String"];
};

export type GQSelectUserRoleType = {
    __typename?: "SelectUserRoleType";
    userRole: GQUserRole;
    token: GQAuthToken;
};

export type GQShiftPaymentRm = {
    __typename?: "ShiftPaymentRM";
    name: Scalars["String"];
    type: Scalars["String"];
    amount: Scalars["Float"];
};

export type GQShiftRm = {
    __typename?: "ShiftRM";
    id: Scalars["ID"];
    cashRegister: GQCashRegister;
    openedByUser: GQUser;
    closedByUser?: Maybe<GQUser>;
    invoices: GQInvoiceRmPagination;
    payments?: Maybe<Array<GQShiftPaymentRm>>;
    openedAt: Scalars["Date"];
    closedAt?: Maybe<Scalars["Date"]>;
    discount: Scalars["Float"];
    amount: Scalars["Float"];
    xReport?: Maybe<Scalars["String"]>;
    zReport?: Maybe<Scalars["String"]>;
};

export type GQShiftRmInvoicesArgs = {
    pagination?: Maybe<GQPaginationInput>;
};

export type GQShiftRmPagination = GQIPagination & {
    __typename?: "ShiftRMPagination";
    data: Array<GQShiftRm>;
    pagination: GQPagination;
};

export type GQSubscription = {
    __typename?: "Subscription";
    dashboardUpdated?: Maybe<GQDashboard>;
    eventStoreSubscribe: Array<GQEvent>;
    merchantDataUpdated: Scalars["Date"];
};

export type GQSubscriptionDashboardUpdatedArgs = {
    from: Scalars["Date"];
    to: Scalars["Date"];
    departmentIds?: Maybe<Array<Scalars["String"]>>;
};

export type GQSubscriptionEventStoreSubscribeArgs = {
    query: GQEventQueryInput;
};

export type GQTag = {
    __typename?: "Tag";
    id: Scalars["ID"];
    merchantId: GQMerchant;
    identifier: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    tagType: Scalars["String"];
    group?: Maybe<GQTagGroup>;
    account?: Maybe<GQAccount>;
    customer?: Maybe<GQCustomer>;
    createdAt: Scalars["Date"];
    updatedAt?: Maybe<Scalars["Date"]>;
};

export type GQTagCreate = {
    identifier: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    tagType: Scalars["String"];
    merchantId: Scalars["ID"];
    groupId?: Maybe<Scalars["ID"]>;
};

export type GQTagDeleteResponse = {
    __typename?: "TagDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQTagGroup = {
    __typename?: "TagGroup";
    id: Scalars["ID"];
    merchant: GQMerchant;
    name: Scalars["String"];
    createdAt: Scalars["Date"];
    updateddAt?: Maybe<Scalars["Date"]>;
};

export type GQTagGroupCreate = {
    name: Scalars["String"];
    merchantId: Scalars["ID"];
};

export type GQTagGroupDeleteResponse = {
    __typename?: "TagGroupDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQTagGroupInput = {
    name: Scalars["String"];
};

export type GQTagGroupPagination = GQIPagination & {
    __typename?: "TagGroupPagination";
    data: Array<GQTagGroup>;
    pagination: GQPagination;
};

export type GQTagInput = {
    identifier: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
    tagType: Scalars["String"];
    groupId?: Maybe<Scalars["ID"]>;
    accountId?: Maybe<Scalars["ID"]>;
    customerId?: Maybe<Scalars["ID"]>;
};

export type GQTagPagination = GQIPagination & {
    __typename?: "TagPagination";
    data: Array<GQTag>;
    pagination: GQPagination;
};

export type GQUser = {
    __typename?: "User";
    id: Scalars["ID"];
    username: Scalars["String"];
    email: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    active: Scalars["Boolean"];
    pin?: Maybe<Scalars["String"]>;
    requireLoginPin: Scalars["Boolean"];
    requireLogoutPin: Scalars["Boolean"];
    requirePasswordChange: Scalars["Boolean"];
    roles: Array<GQUserRole>;
    createdAt: Scalars["Date"];
    updatedAt: Scalars["Date"];
    lastLoginAt?: Maybe<Scalars["Date"]>;
};

export type GQUserRolesArgs = {
    merchantId?: Maybe<Scalars["ID"]>;
};

export type GQUserDeleteResponse = {
    __typename?: "UserDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQUserInput = {
    username: Scalars["String"];
    password?: Maybe<Scalars["String"]>;
    email: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    active: Scalars["Boolean"];
    pin?: Maybe<Scalars["String"]>;
    requireLoginPin: Scalars["Boolean"];
    requireLogoutPin: Scalars["Boolean"];
    requirePasswordChange: Scalars["Boolean"];
    doNotSendPassword: Scalars["Boolean"];
    roles: Array<GQUserInputRole>;
};

export type GQUserInputRole = {
    type: GQRoleTypeEnum;
    roleId: Scalars["ID"];
    merchantId?: Maybe<Scalars["ID"]>;
};

export type GQUserPagination = GQIPagination & {
    __typename?: "UserPagination";
    data: Array<GQUser>;
    pagination: GQPagination;
};

export type GQUserRole = {
    __typename?: "UserRole";
    role: GQRole;
    merchant?: Maybe<GQMerchant>;
};

export type GQUserRoleConfiguration = {
    __typename?: "UserRoleConfiguration";
    user: GQUser;
    merchant?: Maybe<GQMerchant>;
    role: GQRole;
    type: Scalars["String"];
    data: Scalars["JSON"];
    createdAt: Scalars["Date"];
    updatedAt?: Maybe<Scalars["Date"]>;
};

export type GQUserRoleInput = {
    roleId: Scalars["ID"];
    merchantId: Scalars["ID"];
};

export type GQVat = {
    __typename?: "Vat";
    id: Scalars["ID"];
    merchant: GQMerchant;
    name: Scalars["String"];
    percentage: Scalars["Float"];
    isExternal?: Maybe<Scalars["Boolean"]>;
    externalId?: Maybe<Scalars["String"]>;
    createdAt: Scalars["Date"];
    deletedAt?: Maybe<Scalars["Date"]>;
    updatedAt?: Maybe<Scalars["Date"]>;
};

export type GQVatCreateInput = {
    name: Scalars["String"];
    percentage: Scalars["Float"];
    isExternal?: Maybe<Scalars["Boolean"]>;
    externalId?: Maybe<Scalars["String"]>;
};

export type GQVatDeleteResponse = {
    __typename?: "VatDeleteResponse";
    status: Scalars["Boolean"];
};

export type GQVatPagination = GQIPagination & {
    __typename?: "VatPagination";
    data: Array<GQVat>;
    pagination: GQPagination;
};

export type GQVenueAccessAccessType = {
    __typename?: "VenueAccessAccessType";
    id: Scalars["Int"];
    name: Scalars["String"];
};

export type GQVenueAccessEvent = {
    __typename?: "VenueAccessEvent";
    id: Scalars["Int"];
    name: Scalars["String"];
    isActive: Scalars["Boolean"];
};

export type GQVenueAccessParameters = {
    __typename?: "VenueAccessParameters";
    events?: Maybe<Array<GQVenueAccessEvent>>;
    accessTypes: Array<GQVenueAccessAccessType>;
};

export type GQVenueAccessTicketCancelled = {
    __typename?: "VenueAccessTicketCancelled";
    status: Scalars["Boolean"];
};

export type GQVenueAccessTicketCreated = {
    __typename?: "VenueAccessTicketCreated";
    tokenValue: Scalars["String"];
    ticketId: Scalars["String"];
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
    resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
    fragment: string;
    resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
    selectionSet: string;
    resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> =
    | LegacyStitchingResolver<TResult, TParent, TContext, TArgs>
    | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
    | ResolverFn<TResult, TParent, TContext, TArgs>
    | ResolverWithResolve<TResult, TParent, TContext, TArgs>
    | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
    TResult,
    TKey extends string,
    TParent,
    TContext,
    TArgs
> {
    subscribe: SubscriptionSubscribeFn<
        { [key in TKey]: TResult },
        TParent,
        TContext,
        TArgs
    >;
    resolve?: SubscriptionResolveFn<
        TResult,
        { [key in TKey]: TResult },
        TContext,
        TArgs
    >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
    subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
    resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
    TResult,
    TKey extends string,
    TParent,
    TContext,
    TArgs
> =
    | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
    | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
    TResult,
    TKey extends string,
    TParent = {},
    TContext = {},
    TArgs = {}
> =
    | ((
          ...args: any[]
      ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
    | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
    parent: TParent,
    context: TContext,
    info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
    obj: T,
    context: TContext,
    info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
    TResult = {},
    TParent = {},
    TContext = {},
    TArgs = {}
> = (
    next: NextResolverFn<TResult>,
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type GQResolversTypes = {
    Account: ResolverTypeWrapper<GQAccount>;
    ID: ResolverTypeWrapper<Scalars["ID"]>;
    String: ResolverTypeWrapper<Scalars["String"]>;
    Float: ResolverTypeWrapper<Scalars["Float"]>;
    Int: ResolverTypeWrapper<Scalars["Int"]>;
    AccountAssignTagsRelationResponse: ResolverTypeWrapper<GQAccountAssignTagsRelationResponse>;
    Boolean: ResolverTypeWrapper<Scalars["Boolean"]>;
    AccountBookkeepingPagination: ResolverTypeWrapper<GQAccountBookkeepingPagination>;
    AccountBookkeepingTransaction: ResolverTypeWrapper<GQAccountBookkeepingTransaction>;
    AccountBookkeepingTransactionPagination: ResolverTypeWrapper<GQAccountBookkeepingTransactionPagination>;
    AccountBuyFundsResponse: ResolverTypeWrapper<GQAccountBuyFundsResponse>;
    AccountBuyFundsStatus: GQAccountBuyFundsStatus;
    AccountCloseResponse: ResolverTypeWrapper<GQAccountCloseResponse>;
    AccountCreate: GQAccountCreate;
    AccountInput: GQAccountInput;
    AccountOpenResponse: ResolverTypeWrapper<GQAccountOpenResponse>;
    AccountPagination: ResolverTypeWrapper<GQAccountPagination>;
    AccountPayAmountResponse: ResolverTypeWrapper<GQAccountPayAmountResponse>;
    AccountRefundAmountResponse: ResolverTypeWrapper<GQAccountRefundAmountResponse>;
    AccountReturnFundsResponse: ResolverTypeWrapper<GQAccountReturnFundsResponse>;
    AccountReturnFundsStatus: GQAccountReturnFundsStatus;
    AccountRevertTransactionResponse: ResolverTypeWrapper<GQAccountRevertTransactionResponse>;
    AccountRevertTransactionStatus: GQAccountRevertTransactionStatus;
    AccountTransaction: ResolverTypeWrapper<GQAccountTransaction>;
    AccountTransactionPagination: ResolverTypeWrapper<GQAccountTransactionPagination>;
    AccountTransactionStatus: GQAccountTransactionStatus;
    AddEventResponse: ResolverTypeWrapper<GQAddEventResponse>;
    AppData: ResolverTypeWrapper<GQAppData>;
    AuthAttempt: ResolverTypeWrapper<GQAuthAttempt>;
    AuthToken: ResolverTypeWrapper<GQAuthToken>;
    Balance: ResolverTypeWrapper<GQBalance>;
    BookkeepingAccount: ResolverTypeWrapper<GQBookkeepingAccount>;
    BooleanResponse: ResolverTypeWrapper<GQBooleanResponse>;
    CardTransaction: ResolverTypeWrapper<GQCardTransaction>;
    CardTransactionPagination: ResolverTypeWrapper<GQCardTransactionPagination>;
    CardTransactionType: GQCardTransactionType;
    CashRegister: ResolverTypeWrapper<GQCashRegister>;
    CashRegisterCreateInput: GQCashRegisterCreateInput;
    CashRegisterDeleteResponse: ResolverTypeWrapper<GQCashRegisterDeleteResponse>;
    CashRegisterEditInput: GQCashRegisterEditInput;
    CashRegisterPagination: ResolverTypeWrapper<GQCashRegisterPagination>;
    CashRegisterStatus: ResolverTypeWrapper<GQCashRegisterStatus>;
    CashRegisterStatusPagination: ResolverTypeWrapper<GQCashRegisterStatusPagination>;
    CashRegisterVersion: ResolverTypeWrapper<GQCashRegisterVersion>;
    ChartOfAccountNo: ResolverTypeWrapper<GQChartOfAccountNo>;
    Configuration: ResolverTypeWrapper<GQConfiguration>;
    Currency: ResolverTypeWrapper<GQCurrency>;
    CurrencyDeleteResponse: ResolverTypeWrapper<GQCurrencyDeleteResponse>;
    CurrencyInput: GQCurrencyInput;
    CurrencyPagination: ResolverTypeWrapper<GQCurrencyPagination>;
    Customer: ResolverTypeWrapper<GQCustomer>;
    CustomerAssignTagsRelationResponse: ResolverTypeWrapper<GQCustomerAssignTagsRelationResponse>;
    CustomerCreate: GQCustomerCreate;
    CustomerDeleteResponse: ResolverTypeWrapper<GQCustomerDeleteResponse>;
    CustomerGroup: ResolverTypeWrapper<GQCustomerGroup>;
    CustomerGroupCreate: GQCustomerGroupCreate;
    CustomerGroupDeleteResponse: ResolverTypeWrapper<GQCustomerGroupDeleteResponse>;
    CustomerGroupInput: GQCustomerGroupInput;
    CustomerGroupPagination: ResolverTypeWrapper<GQCustomerGroupPagination>;
    CustomerInput: GQCustomerInput;
    CustomerPagination: ResolverTypeWrapper<GQCustomerPagination>;
    Dashboard: ResolverTypeWrapper<GQDashboard>;
    DashboardAccountItem: ResolverTypeWrapper<GQDashboardAccountItem>;
    DashboardCashRegister: ResolverTypeWrapper<GQDashboardCashRegister>;
    DashboardCashRegisterPagination: ResolverTypeWrapper<GQDashboardCashRegisterPagination>;
    DashboardDepartmentItem: ResolverTypeWrapper<GQDashboardDepartmentItem>;
    DashboardProductGroupItem: ResolverTypeWrapper<GQDashboardProductGroupItem>;
    DashboardProductItem: ResolverTypeWrapper<GQDashboardProductItem>;
    DashboardTotals: ResolverTypeWrapper<GQDashboardTotals>;
    Date: ResolverTypeWrapper<Scalars["Date"]>;
    DeleteResponseEnum: GQDeleteResponseEnum;
    Department: ResolverTypeWrapper<GQDepartment>;
    DepartmentDeleteResponse: ResolverTypeWrapper<GQDepartmentDeleteResponse>;
    DepartmentInput: GQDepartmentInput;
    DepartmentPagination: ResolverTypeWrapper<GQDepartmentPagination>;
    Device: ResolverTypeWrapper<GQDevice>;
    DeviceCapabilitiesInput: GQDeviceCapabilitiesInput;
    DeviceInfoInput: GQDeviceInfoInput;
    DevicePagination: ResolverTypeWrapper<GQDevicePagination>;
    DevicePinCodeValidation: ResolverTypeWrapper<GQDevicePinCodeValidation>;
    DevicePrinterInput: GQDevicePrinterInput;
    Discount: ResolverTypeWrapper<GQDiscount>;
    DiscountInput: GQDiscountInput;
    DiscountPagination: ResolverTypeWrapper<GQDiscountPagination>;
    DiscountType: GQDiscountType;
    DiscountValueType: GQDiscountValueType;
    Entity: GQResolversTypes["POS"] | GQResolversTypes["Partner"];
    EntityConfig: ResolverTypeWrapper<GQEntityConfig>;
    EntityConfigDeleteResponse: ResolverTypeWrapper<GQEntityConfigDeleteResponse>;
    Event: ResolverTypeWrapper<GQEvent>;
    EventInput: GQEventInput;
    EventQueryInput: GQEventQueryInput;
    GpsPoint: ResolverTypeWrapper<GQGpsPoint>;
    GpsPointInput: GQGpsPointInput;
    IPagination:
        | GQResolversTypes["AccountBookkeepingPagination"]
        | GQResolversTypes["AccountBookkeepingTransactionPagination"]
        | GQResolversTypes["AccountPagination"]
        | GQResolversTypes["AccountTransactionPagination"]
        | GQResolversTypes["CardTransactionPagination"]
        | GQResolversTypes["CashRegisterPagination"]
        | GQResolversTypes["CashRegisterStatusPagination"]
        | GQResolversTypes["CurrencyPagination"]
        | GQResolversTypes["CustomerGroupPagination"]
        | GQResolversTypes["CustomerPagination"]
        | GQResolversTypes["DashboardCashRegisterPagination"]
        | GQResolversTypes["DepartmentPagination"]
        | GQResolversTypes["DevicePagination"]
        | GQResolversTypes["DiscountPagination"]
        | GQResolversTypes["IntegrationPagination"]
        | GQResolversTypes["InventoryProductNoteTemplatePagination"]
        | GQResolversTypes["InventoryProductPagination"]
        | GQResolversTypes["InvoiceRMPagination"]
        | GQResolversTypes["LayoutPagination"]
        | GQResolversTypes["MerchantPagination"]
        | GQResolversTypes["MiscButtonsPagination"]
        | GQResolversTypes["PaymentConfigurationPagination"]
        | GQResolversTypes["PaymentMethodPagination"]
        | GQResolversTypes["PrinterPagination"]
        | GQResolversTypes["ProductGroupPagination"]
        | GQResolversTypes["ProductPagination"]
        | GQResolversTypes["RolePagination"]
        | GQResolversTypes["ShiftRMPagination"]
        | GQResolversTypes["TagGroupPagination"]
        | GQResolversTypes["TagPagination"]
        | GQResolversTypes["UserPagination"]
        | GQResolversTypes["VatPagination"];
    Integration: ResolverTypeWrapper<GQIntegration>;
    IntegrationAccount: ResolverTypeWrapper<GQIntegrationAccount>;
    IntegrationAccounts: ResolverTypeWrapper<GQIntegrationAccounts>;
    IntegrationCreate: GQIntegrationCreate;
    IntegrationDeleteResponse: ResolverTypeWrapper<GQIntegrationDeleteResponse>;
    IntegrationInput: GQIntegrationInput;
    IntegrationPagination: ResolverTypeWrapper<GQIntegrationPagination>;
    InventoryProduct: ResolverTypeWrapper<GQInventoryProduct>;
    InventoryProductCreate: GQInventoryProductCreate;
    InventoryProductInput: GQInventoryProductInput;
    InventoryProductNoteTemplate: ResolverTypeWrapper<GQInventoryProductNoteTemplate>;
    InventoryProductNoteTemplateCreate: GQInventoryProductNoteTemplateCreate;
    InventoryProductNoteTemplateInput: GQInventoryProductNoteTemplateInput;
    InventoryProductNoteTemplatePagination: ResolverTypeWrapper<GQInventoryProductNoteTemplatePagination>;
    InventoryProductPagination: ResolverTypeWrapper<GQInventoryProductPagination>;
    InvoiceAmount: ResolverTypeWrapper<GQInvoiceAmount>;
    InvoiceAmountPerMonth: ResolverTypeWrapper<GQInvoiceAmountPerMonth>;
    InvoiceItemExternalRM: ResolverTypeWrapper<GQInvoiceItemExternalRm>;
    InvoiceItemRM: ResolverTypeWrapper<GQInvoiceItemRm>;
    InvoicePaymentRM: ResolverTypeWrapper<GQInvoicePaymentRm>;
    InvoiceRM: ResolverTypeWrapper<GQInvoiceRm>;
    InvoiceRMPagination: ResolverTypeWrapper<GQInvoiceRmPagination>;
    JSON: ResolverTypeWrapper<Scalars["JSON"]>;
    Layout: ResolverTypeWrapper<GQLayout>;
    LayoutCreate: GQLayoutCreate;
    LayoutDeleteResponse: ResolverTypeWrapper<GQLayoutDeleteResponse>;
    LayoutDimension: GQLayoutDimension;
    LayoutPagination: ResolverTypeWrapper<GQLayoutPagination>;
    LayoutSave: GQLayoutSave;
    Me: ResolverTypeWrapper<GQMe>;
    Merchant: ResolverTypeWrapper<GQMerchant>;
    MerchantChartOfAccount: ResolverTypeWrapper<GQMerchantChartOfAccount>;
    MerchantChartOfAccountInput: GQMerchantChartOfAccountInput;
    MerchantCreate: GQMerchantCreate;
    MerchantDeleteResponse: ResolverTypeWrapper<GQMerchantDeleteResponse>;
    MerchantInput: GQMerchantInput;
    MerchantPagination: ResolverTypeWrapper<GQMerchantPagination>;
    MiscButtons: ResolverTypeWrapper<GQMiscButtons>;
    MiscButtonsDeleteResponse: ResolverTypeWrapper<GQMiscButtonsDeleteResponse>;
    MiscButtonsInput: GQMiscButtonsInput;
    MiscButtonsPagination: ResolverTypeWrapper<GQMiscButtonsPagination>;
    Mutation: ResolverTypeWrapper<{}>;
    POS: ResolverTypeWrapper<GQPos>;
    Pagination: ResolverTypeWrapper<GQPagination>;
    PaginationInput: GQPaginationInput;
    Partner: ResolverTypeWrapper<GQPartner>;
    PaymentConfiguration: ResolverTypeWrapper<GQPaymentConfiguration>;
    PaymentConfigurationDeleteResponse: ResolverTypeWrapper<GQPaymentConfigurationDeleteResponse>;
    PaymentConfigurationInput: GQPaymentConfigurationInput;
    PaymentConfigurationMethod: ResolverTypeWrapper<GQPaymentConfigurationMethod>;
    PaymentConfigurationMethodInput: GQPaymentConfigurationMethodInput;
    PaymentConfigurationPagination: ResolverTypeWrapper<GQPaymentConfigurationPagination>;
    PaymentMethod: ResolverTypeWrapper<GQPaymentMethod>;
    PaymentMethodDeleteResponse: ResolverTypeWrapper<GQPaymentMethodDeleteResponse>;
    PaymentMethodInput: GQPaymentMethodInput;
    PaymentMethodPagination: ResolverTypeWrapper<GQPaymentMethodPagination>;
    Printer: ResolverTypeWrapper<GQPrinter>;
    PrinterClass: GQPrinterClass;
    PrinterDeleteResponse: ResolverTypeWrapper<GQPrinterDeleteResponse>;
    PrinterManufacturer: GQPrinterManufacturer;
    PrinterPagination: ResolverTypeWrapper<GQPrinterPagination>;
    PrinterRegister: GQPrinterRegister;
    PrinterSave: GQPrinterSave;
    PrinterType: GQPrinterType;
    Product: ResolverTypeWrapper<GQProduct>;
    ProductBarcode: ResolverTypeWrapper<GQProductBarcode>;
    ProductBarcodeInput: GQProductBarcodeInput;
    ProductDeleteResponse: ResolverTypeWrapper<GQProductDeleteResponse>;
    ProductGroup: ResolverTypeWrapper<GQProductGroup>;
    ProductGroupDeleteResponse: ResolverTypeWrapper<GQProductGroupDeleteResponse>;
    ProductGroupInput: GQProductGroupInput;
    ProductGroupPagination: ResolverTypeWrapper<GQProductGroupPagination>;
    ProductInput: GQProductInput;
    ProductPagination: ResolverTypeWrapper<GQProductPagination>;
    ProductRelationInput: GQProductRelationInput;
    Query: ResolverTypeWrapper<{}>;
    ReportCashRegister: ResolverTypeWrapper<GQReportCashRegister>;
    ReportDepartment: ResolverTypeWrapper<GQReportDepartment>;
    ReportParameters: ResolverTypeWrapper<GQReportParameters>;
    ReportProduct: ResolverTypeWrapper<GQReportProduct>;
    ReportProductGroup: ResolverTypeWrapper<GQReportProductGroup>;
    ReportUser: ResolverTypeWrapper<GQReportUser>;
    Role: ResolverTypeWrapper<GQRole>;
    RoleDeleteResponse: ResolverTypeWrapper<GQRoleDeleteResponse>;
    RoleInput: GQRoleInput;
    RolePagination: ResolverTypeWrapper<GQRolePagination>;
    RolePermission: ResolverTypeWrapper<GQRolePermission>;
    RolePermissionInput: GQRolePermissionInput;
    RoleTypeEnum: GQRoleTypeEnum;
    SearchInput: GQSearchInput;
    SelectUserRoleType: ResolverTypeWrapper<GQSelectUserRoleType>;
    ShiftPaymentRM: ResolverTypeWrapper<GQShiftPaymentRm>;
    ShiftRM: ResolverTypeWrapper<GQShiftRm>;
    ShiftRMPagination: ResolverTypeWrapper<GQShiftRmPagination>;
    Subscription: ResolverTypeWrapper<{}>;
    Tag: ResolverTypeWrapper<GQTag>;
    TagCreate: GQTagCreate;
    TagDeleteResponse: ResolverTypeWrapper<GQTagDeleteResponse>;
    TagGroup: ResolverTypeWrapper<GQTagGroup>;
    TagGroupCreate: GQTagGroupCreate;
    TagGroupDeleteResponse: ResolverTypeWrapper<GQTagGroupDeleteResponse>;
    TagGroupInput: GQTagGroupInput;
    TagGroupPagination: ResolverTypeWrapper<GQTagGroupPagination>;
    TagInput: GQTagInput;
    TagPagination: ResolverTypeWrapper<GQTagPagination>;
    User: ResolverTypeWrapper<GQUser>;
    UserDeleteResponse: ResolverTypeWrapper<GQUserDeleteResponse>;
    UserInput: GQUserInput;
    UserInputRole: GQUserInputRole;
    UserPagination: ResolverTypeWrapper<GQUserPagination>;
    UserRole: ResolverTypeWrapper<GQUserRole>;
    UserRoleConfiguration: ResolverTypeWrapper<GQUserRoleConfiguration>;
    UserRoleInput: GQUserRoleInput;
    Vat: ResolverTypeWrapper<GQVat>;
    VatCreateInput: GQVatCreateInput;
    VatDeleteResponse: ResolverTypeWrapper<GQVatDeleteResponse>;
    VatPagination: ResolverTypeWrapper<GQVatPagination>;
    VenueAccessAccessType: ResolverTypeWrapper<GQVenueAccessAccessType>;
    VenueAccessEvent: ResolverTypeWrapper<GQVenueAccessEvent>;
    VenueAccessParameters: ResolverTypeWrapper<GQVenueAccessParameters>;
    VenueAccessTicketCancelled: ResolverTypeWrapper<GQVenueAccessTicketCancelled>;
    VenueAccessTicketCreated: ResolverTypeWrapper<GQVenueAccessTicketCreated>;
};

/** Mapping between all available schema types and the resolvers parents */
export type GQResolversParentTypes = {
    Account: GQAccount;
    ID: Scalars["ID"];
    String: Scalars["String"];
    Float: Scalars["Float"];
    Int: Scalars["Int"];
    AccountAssignTagsRelationResponse: GQAccountAssignTagsRelationResponse;
    Boolean: Scalars["Boolean"];
    AccountBookkeepingPagination: GQAccountBookkeepingPagination;
    AccountBookkeepingTransaction: GQAccountBookkeepingTransaction;
    AccountBookkeepingTransactionPagination: GQAccountBookkeepingTransactionPagination;
    AccountBuyFundsResponse: GQAccountBuyFundsResponse;
    AccountCloseResponse: GQAccountCloseResponse;
    AccountCreate: GQAccountCreate;
    AccountInput: GQAccountInput;
    AccountOpenResponse: GQAccountOpenResponse;
    AccountPagination: GQAccountPagination;
    AccountPayAmountResponse: GQAccountPayAmountResponse;
    AccountRefundAmountResponse: GQAccountRefundAmountResponse;
    AccountReturnFundsResponse: GQAccountReturnFundsResponse;
    AccountRevertTransactionResponse: GQAccountRevertTransactionResponse;
    AccountTransaction: GQAccountTransaction;
    AccountTransactionPagination: GQAccountTransactionPagination;
    AddEventResponse: GQAddEventResponse;
    AppData: GQAppData;
    AuthAttempt: GQAuthAttempt;
    AuthToken: GQAuthToken;
    Balance: GQBalance;
    BookkeepingAccount: GQBookkeepingAccount;
    BooleanResponse: GQBooleanResponse;
    CardTransaction: GQCardTransaction;
    CardTransactionPagination: GQCardTransactionPagination;
    CashRegister: GQCashRegister;
    CashRegisterCreateInput: GQCashRegisterCreateInput;
    CashRegisterDeleteResponse: GQCashRegisterDeleteResponse;
    CashRegisterEditInput: GQCashRegisterEditInput;
    CashRegisterPagination: GQCashRegisterPagination;
    CashRegisterStatus: GQCashRegisterStatus;
    CashRegisterStatusPagination: GQCashRegisterStatusPagination;
    CashRegisterVersion: GQCashRegisterVersion;
    ChartOfAccountNo: GQChartOfAccountNo;
    Configuration: GQConfiguration;
    Currency: GQCurrency;
    CurrencyDeleteResponse: GQCurrencyDeleteResponse;
    CurrencyInput: GQCurrencyInput;
    CurrencyPagination: GQCurrencyPagination;
    Customer: GQCustomer;
    CustomerAssignTagsRelationResponse: GQCustomerAssignTagsRelationResponse;
    CustomerCreate: GQCustomerCreate;
    CustomerDeleteResponse: GQCustomerDeleteResponse;
    CustomerGroup: GQCustomerGroup;
    CustomerGroupCreate: GQCustomerGroupCreate;
    CustomerGroupDeleteResponse: GQCustomerGroupDeleteResponse;
    CustomerGroupInput: GQCustomerGroupInput;
    CustomerGroupPagination: GQCustomerGroupPagination;
    CustomerInput: GQCustomerInput;
    CustomerPagination: GQCustomerPagination;
    Dashboard: GQDashboard;
    DashboardAccountItem: GQDashboardAccountItem;
    DashboardCashRegister: GQDashboardCashRegister;
    DashboardCashRegisterPagination: GQDashboardCashRegisterPagination;
    DashboardDepartmentItem: GQDashboardDepartmentItem;
    DashboardProductGroupItem: GQDashboardProductGroupItem;
    DashboardProductItem: GQDashboardProductItem;
    DashboardTotals: GQDashboardTotals;
    Date: Scalars["Date"];
    Department: GQDepartment;
    DepartmentDeleteResponse: GQDepartmentDeleteResponse;
    DepartmentInput: GQDepartmentInput;
    DepartmentPagination: GQDepartmentPagination;
    Device: GQDevice;
    DeviceCapabilitiesInput: GQDeviceCapabilitiesInput;
    DeviceInfoInput: GQDeviceInfoInput;
    DevicePagination: GQDevicePagination;
    DevicePinCodeValidation: GQDevicePinCodeValidation;
    DevicePrinterInput: GQDevicePrinterInput;
    Discount: GQDiscount;
    DiscountInput: GQDiscountInput;
    DiscountPagination: GQDiscountPagination;
    Entity: GQResolversParentTypes["POS"] | GQResolversParentTypes["Partner"];
    EntityConfig: GQEntityConfig;
    EntityConfigDeleteResponse: GQEntityConfigDeleteResponse;
    Event: GQEvent;
    EventInput: GQEventInput;
    EventQueryInput: GQEventQueryInput;
    GpsPoint: GQGpsPoint;
    GpsPointInput: GQGpsPointInput;
    IPagination:
        | GQResolversParentTypes["AccountBookkeepingPagination"]
        | GQResolversParentTypes["AccountBookkeepingTransactionPagination"]
        | GQResolversParentTypes["AccountPagination"]
        | GQResolversParentTypes["AccountTransactionPagination"]
        | GQResolversParentTypes["CardTransactionPagination"]
        | GQResolversParentTypes["CashRegisterPagination"]
        | GQResolversParentTypes["CashRegisterStatusPagination"]
        | GQResolversParentTypes["CurrencyPagination"]
        | GQResolversParentTypes["CustomerGroupPagination"]
        | GQResolversParentTypes["CustomerPagination"]
        | GQResolversParentTypes["DashboardCashRegisterPagination"]
        | GQResolversParentTypes["DepartmentPagination"]
        | GQResolversParentTypes["DevicePagination"]
        | GQResolversParentTypes["DiscountPagination"]
        | GQResolversParentTypes["IntegrationPagination"]
        | GQResolversParentTypes["InventoryProductNoteTemplatePagination"]
        | GQResolversParentTypes["InventoryProductPagination"]
        | GQResolversParentTypes["InvoiceRMPagination"]
        | GQResolversParentTypes["LayoutPagination"]
        | GQResolversParentTypes["MerchantPagination"]
        | GQResolversParentTypes["MiscButtonsPagination"]
        | GQResolversParentTypes["PaymentConfigurationPagination"]
        | GQResolversParentTypes["PaymentMethodPagination"]
        | GQResolversParentTypes["PrinterPagination"]
        | GQResolversParentTypes["ProductGroupPagination"]
        | GQResolversParentTypes["ProductPagination"]
        | GQResolversParentTypes["RolePagination"]
        | GQResolversParentTypes["ShiftRMPagination"]
        | GQResolversParentTypes["TagGroupPagination"]
        | GQResolversParentTypes["TagPagination"]
        | GQResolversParentTypes["UserPagination"]
        | GQResolversParentTypes["VatPagination"];
    Integration: GQIntegration;
    IntegrationAccount: GQIntegrationAccount;
    IntegrationAccounts: GQIntegrationAccounts;
    IntegrationCreate: GQIntegrationCreate;
    IntegrationDeleteResponse: GQIntegrationDeleteResponse;
    IntegrationInput: GQIntegrationInput;
    IntegrationPagination: GQIntegrationPagination;
    InventoryProduct: GQInventoryProduct;
    InventoryProductCreate: GQInventoryProductCreate;
    InventoryProductInput: GQInventoryProductInput;
    InventoryProductNoteTemplate: GQInventoryProductNoteTemplate;
    InventoryProductNoteTemplateCreate: GQInventoryProductNoteTemplateCreate;
    InventoryProductNoteTemplateInput: GQInventoryProductNoteTemplateInput;
    InventoryProductNoteTemplatePagination: GQInventoryProductNoteTemplatePagination;
    InventoryProductPagination: GQInventoryProductPagination;
    InvoiceAmount: GQInvoiceAmount;
    InvoiceAmountPerMonth: GQInvoiceAmountPerMonth;
    InvoiceItemExternalRM: GQInvoiceItemExternalRm;
    InvoiceItemRM: GQInvoiceItemRm;
    InvoicePaymentRM: GQInvoicePaymentRm;
    InvoiceRM: GQInvoiceRm;
    InvoiceRMPagination: GQInvoiceRmPagination;
    JSON: Scalars["JSON"];
    Layout: GQLayout;
    LayoutCreate: GQLayoutCreate;
    LayoutDeleteResponse: GQLayoutDeleteResponse;
    LayoutDimension: GQLayoutDimension;
    LayoutPagination: GQLayoutPagination;
    LayoutSave: GQLayoutSave;
    Me: GQMe;
    Merchant: GQMerchant;
    MerchantChartOfAccount: GQMerchantChartOfAccount;
    MerchantChartOfAccountInput: GQMerchantChartOfAccountInput;
    MerchantCreate: GQMerchantCreate;
    MerchantDeleteResponse: GQMerchantDeleteResponse;
    MerchantInput: GQMerchantInput;
    MerchantPagination: GQMerchantPagination;
    MiscButtons: GQMiscButtons;
    MiscButtonsDeleteResponse: GQMiscButtonsDeleteResponse;
    MiscButtonsInput: GQMiscButtonsInput;
    MiscButtonsPagination: GQMiscButtonsPagination;
    Mutation: {};
    POS: GQPos;
    Pagination: GQPagination;
    PaginationInput: GQPaginationInput;
    Partner: GQPartner;
    PaymentConfiguration: GQPaymentConfiguration;
    PaymentConfigurationDeleteResponse: GQPaymentConfigurationDeleteResponse;
    PaymentConfigurationInput: GQPaymentConfigurationInput;
    PaymentConfigurationMethod: GQPaymentConfigurationMethod;
    PaymentConfigurationMethodInput: GQPaymentConfigurationMethodInput;
    PaymentConfigurationPagination: GQPaymentConfigurationPagination;
    PaymentMethod: GQPaymentMethod;
    PaymentMethodDeleteResponse: GQPaymentMethodDeleteResponse;
    PaymentMethodInput: GQPaymentMethodInput;
    PaymentMethodPagination: GQPaymentMethodPagination;
    Printer: GQPrinter;
    PrinterDeleteResponse: GQPrinterDeleteResponse;
    PrinterPagination: GQPrinterPagination;
    PrinterRegister: GQPrinterRegister;
    PrinterSave: GQPrinterSave;
    Product: GQProduct;
    ProductBarcode: GQProductBarcode;
    ProductBarcodeInput: GQProductBarcodeInput;
    ProductDeleteResponse: GQProductDeleteResponse;
    ProductGroup: GQProductGroup;
    ProductGroupDeleteResponse: GQProductGroupDeleteResponse;
    ProductGroupInput: GQProductGroupInput;
    ProductGroupPagination: GQProductGroupPagination;
    ProductInput: GQProductInput;
    ProductPagination: GQProductPagination;
    ProductRelationInput: GQProductRelationInput;
    Query: {};
    ReportCashRegister: GQReportCashRegister;
    ReportDepartment: GQReportDepartment;
    ReportParameters: GQReportParameters;
    ReportProduct: GQReportProduct;
    ReportProductGroup: GQReportProductGroup;
    ReportUser: GQReportUser;
    Role: GQRole;
    RoleDeleteResponse: GQRoleDeleteResponse;
    RoleInput: GQRoleInput;
    RolePagination: GQRolePagination;
    RolePermission: GQRolePermission;
    RolePermissionInput: GQRolePermissionInput;
    SearchInput: GQSearchInput;
    SelectUserRoleType: GQSelectUserRoleType;
    ShiftPaymentRM: GQShiftPaymentRm;
    ShiftRM: GQShiftRm;
    ShiftRMPagination: GQShiftRmPagination;
    Subscription: {};
    Tag: GQTag;
    TagCreate: GQTagCreate;
    TagDeleteResponse: GQTagDeleteResponse;
    TagGroup: GQTagGroup;
    TagGroupCreate: GQTagGroupCreate;
    TagGroupDeleteResponse: GQTagGroupDeleteResponse;
    TagGroupInput: GQTagGroupInput;
    TagGroupPagination: GQTagGroupPagination;
    TagInput: GQTagInput;
    TagPagination: GQTagPagination;
    User: GQUser;
    UserDeleteResponse: GQUserDeleteResponse;
    UserInput: GQUserInput;
    UserInputRole: GQUserInputRole;
    UserPagination: GQUserPagination;
    UserRole: GQUserRole;
    UserRoleConfiguration: GQUserRoleConfiguration;
    UserRoleInput: GQUserRoleInput;
    Vat: GQVat;
    VatCreateInput: GQVatCreateInput;
    VatDeleteResponse: GQVatDeleteResponse;
    VatPagination: GQVatPagination;
    VenueAccessAccessType: GQVenueAccessAccessType;
    VenueAccessEvent: GQVenueAccessEvent;
    VenueAccessParameters: GQVenueAccessParameters;
    VenueAccessTicketCancelled: GQVenueAccessTicketCancelled;
    VenueAccessTicketCreated: GQVenueAccessTicketCreated;
};

export type GQAccountResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Account"] = GQResolversParentTypes["Account"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    type?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    status?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    amount?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    transactions?: Resolver<
        GQResolversTypes["AccountTransactionPagination"],
        ParentType,
        ContextType,
        RequireFields<GQAccountTransactionsArgs, never>
    >;
    tags?: Resolver<
        GQResolversTypes["TagPagination"],
        ParentType,
        ContextType,
        RequireFields<GQAccountTagsArgs, never>
    >;
    customer?: Resolver<
        Maybe<GQResolversTypes["Customer"]>,
        ParentType,
        ContextType
    >;
    balance?: Resolver<
        GQResolversTypes["Balance"],
        ParentType,
        ContextType,
        RequireFields<GQAccountBalanceArgs, never>
    >;
    systemLabel?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    vat?: Resolver<Maybe<GQResolversTypes["Vat"]>, ParentType, ContextType>;
    externalId?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountAssignTagsRelationResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountAssignTagsRelationResponse"] = GQResolversParentTypes["AccountAssignTagsRelationResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountBookkeepingPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountBookkeepingPagination"] = GQResolversParentTypes["AccountBookkeepingPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["BookkeepingAccount"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountBookkeepingTransactionResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountBookkeepingTransaction"] = GQResolversParentTypes["AccountBookkeepingTransaction"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    type?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    transactionAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    amount?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    invoiceId?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountBookkeepingTransactionPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountBookkeepingTransactionPagination"] = GQResolversParentTypes["AccountBookkeepingTransactionPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["AccountBookkeepingTransaction"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountBuyFundsResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountBuyFundsResponse"] = GQResolversParentTypes["AccountBuyFundsResponse"]
> = {
    status?: Resolver<
        GQResolversTypes["AccountBuyFundsStatus"],
        ParentType,
        ContextType
    >;
    transactionId?: Resolver<
        Maybe<GQResolversTypes["ID"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountCloseResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountCloseResponse"] = GQResolversParentTypes["AccountCloseResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountOpenResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountOpenResponse"] = GQResolversParentTypes["AccountOpenResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountPagination"] = GQResolversParentTypes["AccountPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["Account"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountPayAmountResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountPayAmountResponse"] = GQResolversParentTypes["AccountPayAmountResponse"]
> = {
    transactionId?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    status?: Resolver<
        GQResolversTypes["AccountTransactionStatus"],
        ParentType,
        ContextType
    >;
    type?: Resolver<Maybe<GQResolversTypes["String"]>, ParentType, ContextType>;
    receipt?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountRefundAmountResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountRefundAmountResponse"] = GQResolversParentTypes["AccountRefundAmountResponse"]
> = {
    transactionId?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    status?: Resolver<
        GQResolversTypes["AccountTransactionStatus"],
        ParentType,
        ContextType
    >;
    type?: Resolver<Maybe<GQResolversTypes["String"]>, ParentType, ContextType>;
    receipt?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountReturnFundsResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountReturnFundsResponse"] = GQResolversParentTypes["AccountReturnFundsResponse"]
> = {
    status?: Resolver<
        GQResolversTypes["AccountReturnFundsStatus"],
        ParentType,
        ContextType
    >;
    transactionId?: Resolver<
        Maybe<GQResolversTypes["ID"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountRevertTransactionResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountRevertTransactionResponse"] = GQResolversParentTypes["AccountRevertTransactionResponse"]
> = {
    status?: Resolver<
        GQResolversTypes["AccountRevertTransactionStatus"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountTransactionResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountTransaction"] = GQResolversParentTypes["AccountTransaction"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    userId?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    transactionAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    amount?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    user?: Resolver<Maybe<GQResolversTypes["User"]>, ParentType, ContextType>;
    tag?: Resolver<Maybe<GQResolversTypes["Tag"]>, ParentType, ContextType>;
    invoiceId?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAccountTransactionPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AccountTransactionPagination"] = GQResolversParentTypes["AccountTransactionPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["AccountTransaction"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAddEventResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AddEventResponse"] = GQResolversParentTypes["AddEventResponse"]
> = {
    success?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAppDataResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AppData"] = GQResolversParentTypes["AppData"]
> = {
    structVersion?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    minimumStructVersion?: Resolver<
        GQResolversTypes["Int"],
        ParentType,
        ContextType
    >;
    cashRegister?: Resolver<
        GQResolversTypes["CashRegister"],
        ParentType,
        ContextType
    >;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    deviceConfig?: Resolver<GQResolversTypes["JSON"], ParentType, ContextType>;
    vats?: Resolver<Array<GQResolversTypes["Vat"]>, ParentType, ContextType>;
    productGroups?: Resolver<
        Array<GQResolversTypes["ProductGroup"]>,
        ParentType,
        ContextType
    >;
    products?: Resolver<
        Array<GQResolversTypes["Product"]>,
        ParentType,
        ContextType
    >;
    discounts?: Resolver<
        Array<GQResolversTypes["Discount"]>,
        ParentType,
        ContextType
    >;
    layout?: Resolver<
        Maybe<GQResolversTypes["Layout"]>,
        ParentType,
        ContextType
    >;
    tableLayout?: Resolver<
        Maybe<GQResolversTypes["Layout"]>,
        ParentType,
        ContextType
    >;
    paymentConfiguration?: Resolver<
        Maybe<GQResolversTypes["PaymentConfiguration"]>,
        ParentType,
        ContextType
    >;
    miscButtons?: Resolver<
        Maybe<GQResolversTypes["MiscButtons"]>,
        ParentType,
        ContextType
    >;
    tags?: Resolver<Array<GQResolversTypes["Tag"]>, ParentType, ContextType>;
    printers?: Resolver<
        Array<GQResolversTypes["Printer"]>,
        ParentType,
        ContextType
    >;
    bookkeepingAccounts?: Resolver<
        Array<GQResolversTypes["BookkeepingAccount"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAuthAttemptResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AuthAttempt"] = GQResolversParentTypes["AuthAttempt"]
> = {
    successful?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    token?: Resolver<
        Maybe<GQResolversTypes["AuthToken"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQAuthTokenResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["AuthToken"] = GQResolversParentTypes["AuthToken"]
> = {
    access?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    accessExpire?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    now?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    refresh?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQBalanceResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Balance"] = GQResolversParentTypes["Balance"]
> = {
    receipt?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQBookkeepingAccountResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["BookkeepingAccount"] = GQResolversParentTypes["BookkeepingAccount"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    systemLabel?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    vat?: Resolver<Maybe<GQResolversTypes["Vat"]>, ParentType, ContextType>;
    bookkeepingTransactions?: Resolver<
        GQResolversTypes["AccountBookkeepingTransactionPagination"],
        ParentType,
        ContextType,
        RequireFields<GQBookkeepingAccountBookkeepingTransactionsArgs, never>
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQBooleanResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["BooleanResponse"] = GQResolversParentTypes["BooleanResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCardTransactionResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CardTransaction"] = GQResolversParentTypes["CardTransaction"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    status?: Resolver<
        GQResolversTypes["CardTransactionType"],
        ParentType,
        ContextType
    >;
    orderId?: Resolver<Maybe<GQResolversTypes["ID"]>, ParentType, ContextType>;
    amount?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    receipt?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    transactionAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    cashRegister?: Resolver<
        GQResolversTypes["CashRegister"],
        ParentType,
        ContextType
    >;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCardTransactionPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CardTransactionPagination"] = GQResolversParentTypes["CardTransactionPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["CardTransaction"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCashRegisterResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CashRegister"] = GQResolversParentTypes["CashRegister"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    serialId?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    device?: Resolver<
        Maybe<GQResolversTypes["Device"]>,
        ParentType,
        ContextType
    >;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    department?: Resolver<
        Maybe<GQResolversTypes["Department"]>,
        ParentType,
        ContextType
    >;
    appData?: Resolver<GQResolversTypes["AppData"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    lastAppDataSentAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCashRegisterDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CashRegisterDeleteResponse"] = GQResolversParentTypes["CashRegisterDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCashRegisterPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CashRegisterPagination"] = GQResolversParentTypes["CashRegisterPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["CashRegister"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCashRegisterStatusResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CashRegisterStatus"] = GQResolversParentTypes["CashRegisterStatus"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    serialId?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    device?: Resolver<
        Maybe<GQResolversTypes["Device"]>,
        ParentType,
        ContextType
    >;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    department?: Resolver<
        Maybe<GQResolversTypes["Department"]>,
        ParentType,
        ContextType
    >;
    appData?: Resolver<GQResolversTypes["AppData"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    lastAppDataSentAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    status?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCashRegisterStatusPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CashRegisterStatusPagination"] = GQResolversParentTypes["CashRegisterStatusPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["CashRegisterStatus"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCashRegisterVersionResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CashRegisterVersion"] = GQResolversParentTypes["CashRegisterVersion"]
> = {
    label?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    count?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQChartOfAccountNoResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ChartOfAccountNo"] = GQResolversParentTypes["ChartOfAccountNo"]
> = {
    merchantAccountNo?: Resolver<
        GQResolversTypes["String"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQConfigurationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Configuration"] = GQResolversParentTypes["Configuration"]
> = {
    paymentTerminalType?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCurrencyResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Currency"] = GQResolversParentTypes["Currency"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    code?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    rate?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCurrencyDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CurrencyDeleteResponse"] = GQResolversParentTypes["CurrencyDeleteResponse"]
> = {
    status?: Resolver<
        GQResolversTypes["DeleteResponseEnum"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCurrencyPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CurrencyPagination"] = GQResolversParentTypes["CurrencyPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["Currency"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCustomerResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Customer"] = GQResolversParentTypes["Customer"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    merchantId?: Resolver<
        GQResolversTypes["Merchant"],
        ParentType,
        ContextType
    >;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    group?: Resolver<
        Maybe<GQResolversTypes["CustomerGroup"]>,
        ParentType,
        ContextType
    >;
    address?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    zip?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    city?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    phoneNumber?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    email?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    cvrNumber?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    contactName?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    comment?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    accounts?: Resolver<
        Array<GQResolversTypes["Account"]>,
        ParentType,
        ContextType
    >;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    tags?: Resolver<
        GQResolversTypes["TagPagination"],
        ParentType,
        ContextType,
        RequireFields<GQCustomerTagsArgs, never>
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCustomerAssignTagsRelationResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CustomerAssignTagsRelationResponse"] = GQResolversParentTypes["CustomerAssignTagsRelationResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCustomerDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CustomerDeleteResponse"] = GQResolversParentTypes["CustomerDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCustomerGroupResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CustomerGroup"] = GQResolversParentTypes["CustomerGroup"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCustomerGroupDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CustomerGroupDeleteResponse"] = GQResolversParentTypes["CustomerGroupDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCustomerGroupPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CustomerGroupPagination"] = GQResolversParentTypes["CustomerGroupPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["CustomerGroup"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQCustomerPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["CustomerPagination"] = GQResolversParentTypes["CustomerPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["Customer"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDashboardResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Dashboard"] = GQResolversParentTypes["Dashboard"]
> = {
    totals?: Resolver<
        GQResolversTypes["DashboardTotals"],
        ParentType,
        ContextType
    >;
    products?: Resolver<
        Array<GQResolversTypes["DashboardProductItem"]>,
        ParentType,
        ContextType
    >;
    productGroups?: Resolver<
        Array<GQResolversTypes["DashboardProductGroupItem"]>,
        ParentType,
        ContextType
    >;
    departments?: Resolver<
        Array<GQResolversTypes["DashboardDepartmentItem"]>,
        ParentType,
        ContextType
    >;
    accounts?: Resolver<
        Array<GQResolversTypes["DashboardAccountItem"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDashboardAccountItemResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["DashboardAccountItem"] = GQResolversParentTypes["DashboardAccountItem"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    amount?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDashboardCashRegisterResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["DashboardCashRegister"] = GQResolversParentTypes["DashboardCashRegister"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    device?: Resolver<
        Maybe<GQResolversTypes["Device"]>,
        ParentType,
        ContextType
    >;
    merchant?: Resolver<
        Maybe<GQResolversTypes["Merchant"]>,
        ParentType,
        ContextType
    >;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDashboardCashRegisterPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["DashboardCashRegisterPagination"] = GQResolversParentTypes["DashboardCashRegisterPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["DashboardCashRegister"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDashboardDepartmentItemResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["DashboardDepartmentItem"] = GQResolversParentTypes["DashboardDepartmentItem"]
> = {
    id?: Resolver<Maybe<GQResolversTypes["ID"]>, ParentType, ContextType>;
    name?: Resolver<Maybe<GQResolversTypes["String"]>, ParentType, ContextType>;
    invoices?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    avgTransaction?: Resolver<
        GQResolversTypes["Float"],
        ParentType,
        ContextType
    >;
    turnoverAmount?: Resolver<
        GQResolversTypes["Float"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDashboardProductGroupItemResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["DashboardProductGroupItem"] = GQResolversParentTypes["DashboardProductGroupItem"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    quantity?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    turnoverAmount?: Resolver<
        GQResolversTypes["Float"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDashboardProductItemResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["DashboardProductItem"] = GQResolversParentTypes["DashboardProductItem"]
> = {
    id?: Resolver<Maybe<GQResolversTypes["ID"]>, ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    quantity?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    turnoverAmount?: Resolver<
        GQResolversTypes["Float"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDashboardTotalsResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["DashboardTotals"] = GQResolversParentTypes["DashboardTotals"]
> = {
    invoices?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    turnoverAmount?: Resolver<
        GQResolversTypes["Float"],
        ParentType,
        ContextType
    >;
    avgTransaction?: Resolver<
        GQResolversTypes["Float"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface GQDateScalarConfig
    extends GraphQLScalarTypeConfig<GQResolversTypes["Date"], any> {
    name: "Date";
}

export type GQDepartmentResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Department"] = GQResolversParentTypes["Department"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    externalId?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    createdAt?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDepartmentDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["DepartmentDeleteResponse"] = GQResolversParentTypes["DepartmentDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDepartmentPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["DepartmentPagination"] = GQResolversParentTypes["DepartmentPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["Department"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDeviceResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Device"] = GQResolversParentTypes["Device"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    pinCode?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    gps?: Resolver<
        Maybe<GQResolversTypes["GpsPoint"]>,
        ParentType,
        ContextType
    >;
    osName?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    osVersion?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    appVersion?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    appBuild?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    serialNumber?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    hardwareModel?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    hardwareProducer?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    macAddress?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    language?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    lastSeen?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDevicePaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["DevicePagination"] = GQResolversParentTypes["DevicePagination"]
> = {
    data?: Resolver<
        Array<Maybe<GQResolversTypes["Device"]>>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDevicePinCodeValidationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["DevicePinCodeValidation"] = GQResolversParentTypes["DevicePinCodeValidation"]
> = {
    valid?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDiscountResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Discount"] = GQResolversParentTypes["Discount"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    lineDescription?: Resolver<
        GQResolversTypes["String"],
        ParentType,
        ContextType
    >;
    type?: Resolver<GQResolversTypes["DiscountType"], ParentType, ContextType>;
    valueType?: Resolver<
        GQResolversTypes["DiscountValueType"],
        ParentType,
        ContextType
    >;
    value?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    maxPerOrder?: Resolver<
        Maybe<GQResolversTypes["Int"]>,
        ParentType,
        ContextType
    >;
    itemQuery?: Resolver<GQResolversTypes["JSON"], ParentType, ContextType>;
    conditions?: Resolver<GQResolversTypes["JSON"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    active?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQDiscountPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["DiscountPagination"] = GQResolversParentTypes["DiscountPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["Discount"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQEntityResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Entity"] = GQResolversParentTypes["Entity"]
> = {
    __resolveType: TypeResolveFn<"POS" | "Partner", ParentType, ContextType>;
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<Maybe<GQResolversTypes["String"]>, ParentType, ContextType>;
};

export type GQEntityConfigResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["EntityConfig"] = GQResolversParentTypes["EntityConfig"]
> = {
    entityId?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    data?: Resolver<GQResolversTypes["JSON"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQEntityConfigDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["EntityConfigDeleteResponse"] = GQResolversParentTypes["EntityConfigDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQEventResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Event"] = GQResolversParentTypes["Event"]
> = {
    aggregateName?: Resolver<
        GQResolversTypes["String"],
        ParentType,
        ContextType
    >;
    aggregateId?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    eventName?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    data?: Resolver<GQResolversTypes["JSON"], ParentType, ContextType>;
    version?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    eventDate?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    meta?: Resolver<GQResolversTypes["JSON"], ParentType, ContextType>;
    owner?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    ownerSequence?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQGpsPointResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["GpsPoint"] = GQResolversParentTypes["GpsPoint"]
> = {
    latitude?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    longitude?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQIPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["IPagination"] = GQResolversParentTypes["IPagination"]
> = {
    __resolveType: TypeResolveFn<
        | "AccountBookkeepingPagination"
        | "AccountBookkeepingTransactionPagination"
        | "AccountPagination"
        | "AccountTransactionPagination"
        | "CardTransactionPagination"
        | "CashRegisterPagination"
        | "CashRegisterStatusPagination"
        | "CurrencyPagination"
        | "CustomerGroupPagination"
        | "CustomerPagination"
        | "DashboardCashRegisterPagination"
        | "DepartmentPagination"
        | "DevicePagination"
        | "DiscountPagination"
        | "IntegrationPagination"
        | "InventoryProductNoteTemplatePagination"
        | "InventoryProductPagination"
        | "InvoiceRMPagination"
        | "LayoutPagination"
        | "MerchantPagination"
        | "MiscButtonsPagination"
        | "PaymentConfigurationPagination"
        | "PaymentMethodPagination"
        | "PrinterPagination"
        | "ProductGroupPagination"
        | "ProductPagination"
        | "RolePagination"
        | "ShiftRMPagination"
        | "TagGroupPagination"
        | "TagPagination"
        | "UserPagination"
        | "VatPagination",
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
};

export type GQIntegrationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Integration"] = GQResolversParentTypes["Integration"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    type?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    active?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    apiKey?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    createdAt?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    merchantChartOfAccounts?: Resolver<
        Array<GQResolversTypes["MerchantChartOfAccount"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQIntegrationAccountResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["IntegrationAccount"] = GQResolversParentTypes["IntegrationAccount"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    accountNo?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    integrationType?: Resolver<
        GQResolversTypes["String"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQIntegrationAccountsResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["IntegrationAccounts"] = GQResolversParentTypes["IntegrationAccounts"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["IntegrationAccount"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQIntegrationDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["IntegrationDeleteResponse"] = GQResolversParentTypes["IntegrationDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQIntegrationPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["IntegrationPagination"] = GQResolversParentTypes["IntegrationPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["Integration"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQInventoryProductResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["InventoryProduct"] = GQResolversParentTypes["InventoryProduct"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    quantity?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    products?: Resolver<
        Array<GQResolversTypes["Product"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQInventoryProductNoteTemplateResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["InventoryProductNoteTemplate"] = GQResolversParentTypes["InventoryProductNoteTemplate"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    note?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQInventoryProductNoteTemplatePaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["InventoryProductNoteTemplatePagination"] = GQResolversParentTypes["InventoryProductNoteTemplatePagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["InventoryProductNoteTemplate"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQInventoryProductPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["InventoryProductPagination"] = GQResolversParentTypes["InventoryProductPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["InventoryProduct"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQInvoiceAmountResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["InvoiceAmount"] = GQResolversParentTypes["InvoiceAmount"]
> = {
    datetime?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    sum?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQInvoiceAmountPerMonthResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["InvoiceAmountPerMonth"] = GQResolversParentTypes["InvoiceAmountPerMonth"]
> = {
    year?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    monthNumber?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    sum?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQInvoiceItemExternalRmResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["InvoiceItemExternalRM"] = GQResolversParentTypes["InvoiceItemExternalRM"]
> = {
    itemId?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    integration?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    checkoutRef?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    parameters?: Resolver<
        Maybe<GQResolversTypes["JSON"]>,
        ParentType,
        ContextType
    >;
    receipt?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQInvoiceItemRmResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["InvoiceItemRM"] = GQResolversParentTypes["InvoiceItemRM"]
> = {
    productId?: Resolver<
        Maybe<GQResolversTypes["ID"]>,
        ParentType,
        ContextType
    >;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    quantity?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    amount?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    discountAmount?: Resolver<
        GQResolversTypes["Float"],
        ParentType,
        ContextType
    >;
    costAmount?: Resolver<
        Maybe<GQResolversTypes["Float"]>,
        ParentType,
        ContextType
    >;
    note?: Resolver<Maybe<GQResolversTypes["String"]>, ParentType, ContextType>;
    external?: Resolver<
        Maybe<Array<GQResolversTypes["InvoiceItemExternalRM"]>>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQInvoicePaymentRmResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["InvoicePaymentRM"] = GQResolversParentTypes["InvoicePaymentRM"]
> = {
    paymentType?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    currency?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    amount?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    cardReceipt?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQInvoiceRmResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["InvoiceRM"] = GQResolversParentTypes["InvoiceRM"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    shift?: Resolver<GQResolversTypes["ShiftRM"], ParentType, ContextType>;
    shiftId?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    departmentId?: Resolver<
        Maybe<GQResolversTypes["ID"]>,
        ParentType,
        ContextType
    >;
    items?: Resolver<
        Maybe<Array<GQResolversTypes["InvoiceItemRM"]>>,
        ParentType,
        ContextType
    >;
    payments?: Resolver<
        Maybe<Array<GQResolversTypes["InvoicePaymentRM"]>>,
        ParentType,
        ContextType
    >;
    receiptNumber?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    receipt?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    discountAmount?: Resolver<
        GQResolversTypes["Float"],
        ParentType,
        ContextType
    >;
    amount?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    name?: Resolver<Maybe<GQResolversTypes["String"]>, ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQInvoiceRmPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["InvoiceRMPagination"] = GQResolversParentTypes["InvoiceRMPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["InvoiceRM"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface GQJsonScalarConfig
    extends GraphQLScalarTypeConfig<GQResolversTypes["JSON"], any> {
    name: "JSON";
}

export type GQLayoutResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Layout"] = GQResolversParentTypes["Layout"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    columns?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    rows?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    layoutType?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    data?: Resolver<GQResolversTypes["JSON"], ParentType, ContextType>;
    products?: Resolver<
        Array<GQResolversTypes["Product"]>,
        ParentType,
        ContextType
    >;
    productGroups?: Resolver<
        Array<GQResolversTypes["ProductGroup"]>,
        ParentType,
        ContextType
    >;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQLayoutDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["LayoutDeleteResponse"] = GQResolversParentTypes["LayoutDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQLayoutPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["LayoutPagination"] = GQResolversParentTypes["LayoutPagination"]
> = {
    data?: Resolver<Array<GQResolversTypes["Layout"]>, ParentType, ContextType>;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQMeResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Me"] = GQResolversParentTypes["Me"]
> = {
    cashRegister?: Resolver<
        Maybe<GQResolversTypes["CashRegister"]>,
        ParentType,
        ContextType
    >;
    user?: Resolver<Maybe<GQResolversTypes["User"]>, ParentType, ContextType>;
    merchant?: Resolver<
        Maybe<GQResolversTypes["Merchant"]>,
        ParentType,
        ContextType
    >;
    department?: Resolver<
        Maybe<GQResolversTypes["Department"]>,
        ParentType,
        ContextType
    >;
    role?: Resolver<Maybe<GQResolversTypes["Role"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQMerchantResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Merchant"] = GQResolversParentTypes["Merchant"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    serialId?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    giftcardVatBookkeepingMethod?: Resolver<
        GQResolversTypes["String"],
        ParentType,
        ContextType
    >;
    createdAt?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    users?: Resolver<Array<GQResolversTypes["User"]>, ParentType, ContextType>;
    entityConfig?: Resolver<
        Maybe<GQResolversTypes["EntityConfig"]>,
        ParentType,
        ContextType
    >;
    lastNotifySentAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQMerchantChartOfAccountResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["MerchantChartOfAccount"] = GQResolversParentTypes["MerchantChartOfAccount"]
> = {
    integrationId?: Resolver<
        GQResolversTypes["String"],
        ParentType,
        ContextType
    >;
    chartOfAccountsId?: Resolver<
        GQResolversTypes["String"],
        ParentType,
        ContextType
    >;
    merchantAccountNo?: Resolver<
        GQResolversTypes["String"],
        ParentType,
        ContextType
    >;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQMerchantDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["MerchantDeleteResponse"] = GQResolversParentTypes["MerchantDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQMerchantPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["MerchantPagination"] = GQResolversParentTypes["MerchantPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["Merchant"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQMiscButtonsResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["MiscButtons"] = GQResolversParentTypes["MiscButtons"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    buttons?: Resolver<GQResolversTypes["JSON"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQMiscButtonsDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["MiscButtonsDeleteResponse"] = GQResolversParentTypes["MiscButtonsDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQMiscButtonsPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["MiscButtonsPagination"] = GQResolversParentTypes["MiscButtonsPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["MiscButtons"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQMutationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Mutation"] = GQResolversParentTypes["Mutation"]
> = {
    accountAssignTags?: Resolver<
        GQResolversTypes["AccountAssignTagsRelationResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationAccountAssignTagsArgs, "id">
    >;
    accountBuyFunds?: Resolver<
        GQResolversTypes["AccountBuyFundsResponse"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationAccountBuyFundsArgs,
            "id" | "amount" | "orderId" | "userId" | "tagId" | "tagIdentifier"
        >
    >;
    accountClose?: Resolver<
        GQResolversTypes["AccountCloseResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationAccountCloseArgs, "id">
    >;
    accountCreate?: Resolver<
        GQResolversTypes["Account"],
        ParentType,
        ContextType,
        RequireFields<GQMutationAccountCreateArgs, "account">
    >;
    accountOpen?: Resolver<
        GQResolversTypes["AccountOpenResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationAccountOpenArgs, "id">
    >;
    accountPayAmount?: Resolver<
        GQResolversTypes["AccountPayAmountResponse"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationAccountPayAmountArgs,
            "id" | "orderId" | "amount" | "userId"
        >
    >;
    accountRefundAmount?: Resolver<
        GQResolversTypes["AccountRefundAmountResponse"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationAccountRefundAmountArgs,
            "id" | "orderId" | "amount" | "userId"
        >
    >;
    accountReturnFunds?: Resolver<
        GQResolversTypes["AccountReturnFundsResponse"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationAccountReturnFundsArgs,
            "id" | "amount" | "orderId" | "userId" | "tagId" | "tagIdentifier"
        >
    >;
    accountRevertTransaction?: Resolver<
        GQResolversTypes["AccountRevertTransactionResponse"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationAccountRevertTransactionArgs,
            "id" | "transactionId" | "userId"
        >
    >;
    accountSave?: Resolver<
        GQResolversTypes["Account"],
        ParentType,
        ContextType,
        RequireFields<GQMutationAccountSaveArgs, "id" | "account">
    >;
    authLogin?: Resolver<
        GQResolversTypes["AuthAttempt"],
        ParentType,
        ContextType,
        RequireFields<GQMutationAuthLoginArgs, "username" | "password">
    >;
    authSelectUserRole?: Resolver<
        GQResolversTypes["SelectUserRoleType"],
        ParentType,
        ContextType,
        RequireFields<GQMutationAuthSelectUserRoleArgs, "roleId">
    >;
    authUpdateLogin?: Resolver<
        GQResolversTypes["AuthAttempt"],
        ParentType,
        ContextType
    >;
    cashRegisterClaim?: Resolver<
        Maybe<GQResolversTypes["CashRegister"]>,
        ParentType,
        ContextType,
        RequireFields<GQMutationCashRegisterClaimArgs, "id" | "devicePinCode">
    >;
    cashRegisterCreate?: Resolver<
        GQResolversTypes["CashRegister"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCashRegisterCreateArgs, "cashRegister">
    >;
    cashRegisterDelete?: Resolver<
        GQResolversTypes["CashRegisterDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCashRegisterDeleteArgs, "id">
    >;
    cashRegisterEdit?: Resolver<
        GQResolversTypes["CashRegister"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCashRegisterEditArgs, "id" | "cashRegister">
    >;
    cashRegistersDelete?: Resolver<
        GQResolversTypes["CashRegisterDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCashRegistersDeleteArgs, "ids">
    >;
    currencyCreate?: Resolver<
        GQResolversTypes["Currency"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCurrencyCreateArgs, "currency">
    >;
    currencyDelete?: Resolver<
        GQResolversTypes["CurrencyDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCurrencyDeleteArgs, "id">
    >;
    currencySave?: Resolver<
        GQResolversTypes["Currency"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCurrencySaveArgs, "id" | "currency">
    >;
    customerAssignTags?: Resolver<
        GQResolversTypes["CustomerAssignTagsRelationResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCustomerAssignTagsArgs, "id">
    >;
    customerCreate?: Resolver<
        GQResolversTypes["Customer"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCustomerCreateArgs, "customer">
    >;
    customerDelete?: Resolver<
        GQResolversTypes["CustomerDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCustomerDeleteArgs, "id">
    >;
    customerGroupCreate?: Resolver<
        GQResolversTypes["CustomerGroup"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCustomerGroupCreateArgs, "customerGroup">
    >;
    customerGroupDelete?: Resolver<
        GQResolversTypes["CustomerGroupDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCustomerGroupDeleteArgs, "id">
    >;
    customerGroupSave?: Resolver<
        GQResolversTypes["CustomerGroup"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCustomerGroupSaveArgs, "id" | "customerGroup">
    >;
    customerSave?: Resolver<
        GQResolversTypes["Customer"],
        ParentType,
        ContextType,
        RequireFields<GQMutationCustomerSaveArgs, "id" | "customer">
    >;
    departmentCreate?: Resolver<
        GQResolversTypes["Department"],
        ParentType,
        ContextType,
        RequireFields<GQMutationDepartmentCreateArgs, "department">
    >;
    departmentDelete?: Resolver<
        GQResolversTypes["DepartmentDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationDepartmentDeleteArgs, "id">
    >;
    departmentSave?: Resolver<
        GQResolversTypes["Department"],
        ParentType,
        ContextType,
        RequireFields<GQMutationDepartmentSaveArgs, "id" | "department">
    >;
    deviceHeartbeat?: Resolver<
        GQResolversTypes["Boolean"],
        ParentType,
        ContextType,
        RequireFields<GQMutationDeviceHeartbeatArgs, "deviceInfo">
    >;
    deviceRegister?: Resolver<
        GQResolversTypes["Device"],
        ParentType,
        ContextType,
        RequireFields<GQMutationDeviceRegisterArgs, "deviceInfo">
    >;
    discountCreate?: Resolver<
        GQResolversTypes["Discount"],
        ParentType,
        ContextType,
        RequireFields<GQMutationDiscountCreateArgs, "discount">
    >;
    discountDelete?: Resolver<
        GQResolversTypes["BooleanResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationDiscountDeleteArgs, "id">
    >;
    discountSave?: Resolver<
        GQResolversTypes["Discount"],
        ParentType,
        ContextType,
        RequireFields<GQMutationDiscountSaveArgs, "id" | "discount">
    >;
    discountsActiveToggle?: Resolver<
        GQResolversTypes["BooleanResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationDiscountsActiveToggleArgs, "ids">
    >;
    entityConfigDelete?: Resolver<
        GQResolversTypes["EntityConfigDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationEntityConfigDeleteArgs, "entityId">
    >;
    entityConfigSave?: Resolver<
        GQResolversTypes["EntityConfig"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationEntityConfigSaveArgs,
            "entityId" | "partialData" | "disabledData"
        >
    >;
    eventStoreSyncDispatch?: Resolver<
        GQResolversTypes["AddEventResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationEventStoreSyncDispatchArgs, "events">
    >;
    integrationCreate?: Resolver<
        GQResolversTypes["Integration"],
        ParentType,
        ContextType,
        RequireFields<GQMutationIntegrationCreateArgs, "integration">
    >;
    integrationDelete?: Resolver<
        GQResolversTypes["IntegrationDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationIntegrationDeleteArgs, "id">
    >;
    integrationSave?: Resolver<
        GQResolversTypes["Integration"],
        ParentType,
        ContextType,
        RequireFields<GQMutationIntegrationSaveArgs, "id" | "integration">
    >;
    inventoryProductNoteTemplateCreate?: Resolver<
        GQResolversTypes["InventoryProductNoteTemplate"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationInventoryProductNoteTemplateCreateArgs,
            "inventoryProductNoteTemplate"
        >
    >;
    inventoryProductNoteTemplateDelete?: Resolver<
        GQResolversTypes["BooleanResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationInventoryProductNoteTemplateDeleteArgs, "id">
    >;
    inventoryProductNoteTemplateSave?: Resolver<
        GQResolversTypes["InventoryProductNoteTemplate"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationInventoryProductNoteTemplateSaveArgs,
            "id" | "inventoryProductNoteTemplate"
        >
    >;
    inventoryProductUpdate?: Resolver<
        GQResolversTypes["InventoryProduct"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationInventoryProductUpdateArgs,
            "id" | "inventoryProduct"
        >
    >;
    layoutCopy?: Resolver<
        GQResolversTypes["Layout"],
        ParentType,
        ContextType,
        RequireFields<GQMutationLayoutCopyArgs, "id" | "newTitle">
    >;
    layoutCreate?: Resolver<
        GQResolversTypes["Layout"],
        ParentType,
        ContextType,
        RequireFields<GQMutationLayoutCreateArgs, "layout">
    >;
    layoutDelete?: Resolver<
        GQResolversTypes["LayoutDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationLayoutDeleteArgs, "id">
    >;
    layoutSave?: Resolver<
        GQResolversTypes["Layout"],
        ParentType,
        ContextType,
        RequireFields<GQMutationLayoutSaveArgs, "id" | "layout">
    >;
    merchantCreate?: Resolver<
        GQResolversTypes["Merchant"],
        ParentType,
        ContextType,
        RequireFields<GQMutationMerchantCreateArgs, "merchant">
    >;
    merchantDelete?: Resolver<
        GQResolversTypes["MerchantDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationMerchantDeleteArgs, "id">
    >;
    merchantSave?: Resolver<
        GQResolversTypes["Merchant"],
        ParentType,
        ContextType,
        RequireFields<GQMutationMerchantSaveArgs, "id" | "merchant">
    >;
    miscButtonsCreate?: Resolver<
        GQResolversTypes["MiscButtons"],
        ParentType,
        ContextType,
        RequireFields<GQMutationMiscButtonsCreateArgs, "miscButtons">
    >;
    miscButtonsDelete?: Resolver<
        GQResolversTypes["MiscButtonsDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationMiscButtonsDeleteArgs, "id">
    >;
    miscButtonsSave?: Resolver<
        GQResolversTypes["MiscButtons"],
        ParentType,
        ContextType,
        RequireFields<GQMutationMiscButtonsSaveArgs, "id" | "miscButtons">
    >;
    paymentConfigurationCreate?: Resolver<
        GQResolversTypes["PaymentConfiguration"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationPaymentConfigurationCreateArgs,
            "paymentConfiguration"
        >
    >;
    paymentConfigurationDelete?: Resolver<
        GQResolversTypes["PaymentConfigurationDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationPaymentConfigurationDeleteArgs, "id">
    >;
    paymentConfigurationSave?: Resolver<
        GQResolversTypes["PaymentConfiguration"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationPaymentConfigurationSaveArgs,
            "id" | "paymentConfiguration"
        >
    >;
    paymentMethodCreate?: Resolver<
        GQResolversTypes["PaymentMethod"],
        ParentType,
        ContextType,
        RequireFields<GQMutationPaymentMethodCreateArgs, "paymentMethod">
    >;
    paymentMethodDelete?: Resolver<
        GQResolversTypes["PaymentMethodDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationPaymentMethodDeleteArgs, "id">
    >;
    paymentMethodSave?: Resolver<
        GQResolversTypes["PaymentMethod"],
        ParentType,
        ContextType,
        RequireFields<GQMutationPaymentMethodSaveArgs, "id" | "paymentMethod">
    >;
    printerDelete?: Resolver<
        GQResolversTypes["PrinterDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationPrinterDeleteArgs, "id">
    >;
    printerRegister?: Resolver<
        GQResolversTypes["Printer"],
        ParentType,
        ContextType,
        RequireFields<GQMutationPrinterRegisterArgs, "printer">
    >;
    printerSave?: Resolver<
        GQResolversTypes["Printer"],
        ParentType,
        ContextType,
        RequireFields<GQMutationPrinterSaveArgs, "id" | "printer">
    >;
    productCreate?: Resolver<
        GQResolversTypes["Product"],
        ParentType,
        ContextType,
        RequireFields<GQMutationProductCreateArgs, "product">
    >;
    productDelete?: Resolver<
        GQResolversTypes["ProductDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationProductDeleteArgs, "id">
    >;
    productGroupCreate?: Resolver<
        GQResolversTypes["ProductGroup"],
        ParentType,
        ContextType,
        RequireFields<GQMutationProductGroupCreateArgs, "productGroup">
    >;
    productGroupDelete?: Resolver<
        GQResolversTypes["ProductGroupDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationProductGroupDeleteArgs, "id">
    >;
    productGroupSave?: Resolver<
        GQResolversTypes["ProductGroup"],
        ParentType,
        ContextType,
        RequireFields<GQMutationProductGroupSaveArgs, "id" | "productGroup">
    >;
    productSave?: Resolver<
        GQResolversTypes["Product"],
        ParentType,
        ContextType,
        RequireFields<GQMutationProductSaveArgs, "id" | "product">
    >;
    productsDelete?: Resolver<
        GQResolversTypes["ProductDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationProductsDeleteArgs, "ids">
    >;
    roleCreate?: Resolver<
        GQResolversTypes["Role"],
        ParentType,
        ContextType,
        RequireFields<GQMutationRoleCreateArgs, "role">
    >;
    roleDelete?: Resolver<
        GQResolversTypes["RoleDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationRoleDeleteArgs, "id">
    >;
    roleSave?: Resolver<
        GQResolversTypes["Role"],
        ParentType,
        ContextType,
        RequireFields<GQMutationRoleSaveArgs, "id" | "role">
    >;
    setInventoryProductQuantity?: Resolver<
        GQResolversTypes["InventoryProduct"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationSetInventoryProductQuantityArgs,
            "productId" | "quantity"
        >
    >;
    tagCreate?: Resolver<
        GQResolversTypes["Tag"],
        ParentType,
        ContextType,
        RequireFields<GQMutationTagCreateArgs, "tag">
    >;
    tagDelete?: Resolver<
        GQResolversTypes["TagDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationTagDeleteArgs, "id">
    >;
    tagGroupCreate?: Resolver<
        GQResolversTypes["TagGroup"],
        ParentType,
        ContextType,
        RequireFields<GQMutationTagGroupCreateArgs, "tagGroup">
    >;
    tagGroupDelete?: Resolver<
        GQResolversTypes["TagGroupDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationTagGroupDeleteArgs, "id">
    >;
    tagGroupSave?: Resolver<
        GQResolversTypes["TagGroup"],
        ParentType,
        ContextType,
        RequireFields<GQMutationTagGroupSaveArgs, "id" | "tagGroup">
    >;
    tagSave?: Resolver<
        GQResolversTypes["Tag"],
        ParentType,
        ContextType,
        RequireFields<GQMutationTagSaveArgs, "id" | "tag">
    >;
    userCreate?: Resolver<
        GQResolversTypes["User"],
        ParentType,
        ContextType,
        RequireFields<GQMutationUserCreateArgs, "user">
    >;
    userDelete?: Resolver<
        GQResolversTypes["UserDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationUserDeleteArgs, "id">
    >;
    userRoleConfigurationSave?: Resolver<
        GQResolversTypes["BooleanResponse"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationUserRoleConfigurationSaveArgs,
            "userId" | "roleId" | "type" | "configuration"
        >
    >;
    userSave?: Resolver<
        GQResolversTypes["User"],
        ParentType,
        ContextType,
        RequireFields<GQMutationUserSaveArgs, "id" | "user">
    >;
    userUpdatePassword?: Resolver<
        GQResolversTypes["BooleanResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationUserUpdatePasswordArgs, "id" | "password">
    >;
    vatCreate?: Resolver<
        GQResolversTypes["Vat"],
        ParentType,
        ContextType,
        RequireFields<GQMutationVatCreateArgs, "vat">
    >;
    vatDelete?: Resolver<
        GQResolversTypes["VatDeleteResponse"],
        ParentType,
        ContextType,
        RequireFields<GQMutationVatDeleteArgs, "id">
    >;
    venueAccessCancelAccessTicket?: Resolver<
        GQResolversTypes["VenueAccessTicketCancelled"],
        ParentType,
        ContextType,
        RequireFields<GQMutationVenueAccessCancelAccessTicketArgs, "ticketId">
    >;
    venueAccessCreateAccessTicket?: Resolver<
        GQResolversTypes["VenueAccessTicketCreated"],
        ParentType,
        ContextType,
        RequireFields<
            GQMutationVenueAccessCreateAccessTicketArgs,
            "eventId" | "accessTypes" | "validFrom" | "validTo"
        >
    >;
};

export type GQPosResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["POS"] = GQResolversParentTypes["POS"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<Maybe<GQResolversTypes["String"]>, ParentType, ContextType>;
    device?: Resolver<GQResolversTypes["Device"], ParentType, ContextType>;
    configuration?: Resolver<
        Maybe<GQResolversTypes["Configuration"]>,
        ParentType,
        ContextType
    >;
    partner?: Resolver<
        Maybe<GQResolversTypes["Partner"]>,
        ParentType,
        ContextType
    >;
    merchant?: Resolver<
        Maybe<GQResolversTypes["Merchant"]>,
        ParentType,
        ContextType
    >;
    tags?: Resolver<Array<GQResolversTypes["String"]>, ParentType, ContextType>;
    department?: Resolver<
        Maybe<GQResolversTypes["Department"]>,
        ParentType,
        ContextType
    >;
    firstActive?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    lastActive?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    firstLocation?: Resolver<
        Maybe<GQResolversTypes["GpsPoint"]>,
        ParentType,
        ContextType
    >;
    lastLocation?: Resolver<
        Maybe<GQResolversTypes["GpsPoint"]>,
        ParentType,
        ContextType
    >;
    firstUser?: Resolver<
        Maybe<GQResolversTypes["User"]>,
        ParentType,
        ContextType
    >;
    lastUser?: Resolver<
        Maybe<GQResolversTypes["User"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Pagination"] = GQResolversParentTypes["Pagination"]
> = {
    pageSize?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    page?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    pages?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    sort?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    sortDirection?: Resolver<
        GQResolversTypes["String"],
        ParentType,
        ContextType
    >;
    resultCount?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQPartnerResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Partner"] = GQResolversParentTypes["Partner"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<Maybe<GQResolversTypes["String"]>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQPaymentConfigurationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["PaymentConfiguration"] = GQResolversParentTypes["PaymentConfiguration"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    paymentMethods?: Resolver<
        Array<GQResolversTypes["PaymentConfigurationMethod"]>,
        ParentType,
        ContextType
    >;
    createdAt?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQPaymentConfigurationDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["PaymentConfigurationDeleteResponse"] = GQResolversParentTypes["PaymentConfigurationDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQPaymentConfigurationMethodResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["PaymentConfigurationMethod"] = GQResolversParentTypes["PaymentConfigurationMethod"]
> = {
    paymentMethod?: Resolver<
        GQResolversTypes["PaymentMethod"],
        ParentType,
        ContextType
    >;
    showAsQuickButton?: Resolver<
        GQResolversTypes["Boolean"],
        ParentType,
        ContextType
    >;
    showInPaymentWindow?: Resolver<
        GQResolversTypes["Boolean"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQPaymentConfigurationPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["PaymentConfigurationPagination"] = GQResolversParentTypes["PaymentConfigurationPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["PaymentConfiguration"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQPaymentMethodResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["PaymentMethod"] = GQResolversParentTypes["PaymentMethod"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    paymentType?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    enabled?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    account?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    diffAccount?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    currency?: Resolver<GQResolversTypes["Currency"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQPaymentMethodDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["PaymentMethodDeleteResponse"] = GQResolversParentTypes["PaymentMethodDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQPaymentMethodPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["PaymentMethodPagination"] = GQResolversParentTypes["PaymentMethodPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["PaymentMethod"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQPrinterResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Printer"] = GQResolversParentTypes["Printer"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    cashRegister?: Resolver<
        Maybe<GQResolversTypes["CashRegister"]>,
        ParentType,
        ContextType
    >;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    serial?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    type?: Resolver<GQResolversTypes["PrinterType"], ParentType, ContextType>;
    class?: Resolver<GQResolversTypes["PrinterClass"], ParentType, ContextType>;
    manufacturer?: Resolver<
        GQResolversTypes["PrinterManufacturer"],
        ParentType,
        ContextType
    >;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQPrinterDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["PrinterDeleteResponse"] = GQResolversParentTypes["PrinterDeleteResponse"]
> = {
    status?: Resolver<
        GQResolversTypes["DeleteResponseEnum"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQPrinterPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["PrinterPagination"] = GQResolversParentTypes["PrinterPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["Printer"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQProductResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Product"] = GQResolversParentTypes["Product"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    parent?: Resolver<
        Maybe<GQResolversTypes["Product"]>,
        ParentType,
        ContextType
    >;
    children?: Resolver<
        GQResolversTypes["ProductPagination"],
        ParentType,
        ContextType,
        RequireFields<GQProductChildrenArgs, never>
    >;
    group?: Resolver<GQResolversTypes["ProductGroup"], ParentType, ContextType>;
    externalId?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    amount?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    minimumAmount?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    costAmount?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    vat?: Resolver<GQResolversTypes["Vat"], ParentType, ContextType>;
    ownVat?: Resolver<Maybe<GQResolversTypes["Vat"]>, ParentType, ContextType>;
    onlineRequired?: Resolver<
        GQResolversTypes["Boolean"],
        ParentType,
        ContextType
    >;
    active?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    isExternal?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    excludeFromDiscounts?: Resolver<
        GQResolversTypes["Boolean"],
        ParentType,
        ContextType
    >;
    ownExcludeFromDiscounts?: Resolver<
        GQResolversTypes["Boolean"],
        ParentType,
        ContextType
    >;
    barcode?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    barcodes?: Resolver<
        Array<GQResolversTypes["ProductBarcode"]>,
        ParentType,
        ContextType
    >;
    buttonText?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    printer?: Resolver<
        Maybe<GQResolversTypes["Printer"]>,
        ParentType,
        ContextType
    >;
    inheritPrinter?: Resolver<
        GQResolversTypes["Boolean"],
        ParentType,
        ContextType
    >;
    inventoryProduct?: Resolver<
        Maybe<GQResolversTypes["InventoryProduct"]>,
        ParentType,
        ContextType
    >;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQProductBarcodeResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ProductBarcode"] = GQResolversParentTypes["ProductBarcode"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    barcode?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQProductDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ProductDeleteResponse"] = GQResolversParentTypes["ProductDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQProductGroupResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ProductGroup"] = GQResolversParentTypes["ProductGroup"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    externalId?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    vat?: Resolver<GQResolversTypes["Vat"], ParentType, ContextType>;
    color?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    excludeFromDiscounts?: Resolver<
        GQResolversTypes["Boolean"],
        ParentType,
        ContextType
    >;
    isExternal?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    printer?: Resolver<
        Maybe<GQResolversTypes["Printer"]>,
        ParentType,
        ContextType
    >;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQProductGroupDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ProductGroupDeleteResponse"] = GQResolversParentTypes["ProductGroupDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQProductGroupPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ProductGroupPagination"] = GQResolversParentTypes["ProductGroupPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["ProductGroup"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQProductPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ProductPagination"] = GQResolversParentTypes["ProductPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["Product"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQQueryResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Query"] = GQResolversParentTypes["Query"]
> = {
    account?: Resolver<
        GQResolversTypes["Account"],
        ParentType,
        ContextType,
        RequireFields<GQQueryAccountArgs, "id">
    >;
    accounts?: Resolver<
        GQResolversTypes["AccountPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryAccountsArgs, never>
    >;
    accountsByCustomerSearch?: Resolver<
        GQResolversTypes["AccountPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryAccountsByCustomerSearchArgs, never>
    >;
    allMiscButtons?: Resolver<
        GQResolversTypes["MiscButtonsPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryAllMiscButtonsArgs, never>
    >;
    authDevicePoll?: Resolver<
        Maybe<GQResolversTypes["AuthToken"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryAuthDevicePollArgs, "deviceId" | "devicePinCode">
    >;
    authRefresh?: Resolver<
        Maybe<GQResolversTypes["AuthToken"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryAuthRefreshArgs, "refreshToken">
    >;
    bookkeepingAccount?: Resolver<
        GQResolversTypes["BookkeepingAccount"],
        ParentType,
        ContextType,
        RequireFields<GQQueryBookkeepingAccountArgs, "id">
    >;
    bookkeepingAccounts?: Resolver<
        GQResolversTypes["AccountBookkeepingPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryBookkeepingAccountsArgs, never>
    >;
    bookkeepingTransactions?: Resolver<
        GQResolversTypes["AccountBookkeepingTransactionPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryBookkeepingTransactionsArgs, never>
    >;
    cardTransaction?: Resolver<
        GQResolversTypes["CardTransaction"],
        ParentType,
        ContextType,
        RequireFields<GQQueryCardTransactionArgs, "id">
    >;
    cardTransactions?: Resolver<
        GQResolversTypes["CardTransactionPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryCardTransactionsArgs, never>
    >;
    cashRegister?: Resolver<
        Maybe<GQResolversTypes["CashRegister"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryCashRegisterArgs, "id">
    >;
    cashRegisters?: Resolver<
        GQResolversTypes["CashRegisterPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryCashRegistersArgs, never>
    >;
    cashRegistersStatus?: Resolver<
        GQResolversTypes["CashRegisterStatusPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryCashRegistersStatusArgs, never>
    >;
    currencies?: Resolver<
        GQResolversTypes["CurrencyPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryCurrenciesArgs, never>
    >;
    currency?: Resolver<
        Maybe<GQResolversTypes["Currency"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryCurrencyArgs, "id">
    >;
    customer?: Resolver<
        GQResolversTypes["Customer"],
        ParentType,
        ContextType,
        RequireFields<GQQueryCustomerArgs, "id">
    >;
    customerAccounts?: Resolver<
        GQResolversTypes["AccountPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryCustomerAccountsArgs, never>
    >;
    customerGroup?: Resolver<
        GQResolversTypes["CustomerGroup"],
        ParentType,
        ContextType,
        RequireFields<GQQueryCustomerGroupArgs, "id">
    >;
    customerGroups?: Resolver<
        GQResolversTypes["CustomerGroupPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryCustomerGroupsArgs, never>
    >;
    customers?: Resolver<
        GQResolversTypes["CustomerPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryCustomersArgs, never>
    >;
    customersInGroup?: Resolver<
        GQResolversTypes["CustomerPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryCustomersInGroupArgs, "groupId">
    >;
    dashboard?: Resolver<
        Maybe<GQResolversTypes["Dashboard"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryDashboardArgs, "from" | "to">
    >;
    dashboardCashRegisterStats?: Resolver<
        Array<GQResolversTypes["CashRegisterVersion"]>,
        ParentType,
        ContextType
    >;
    dashboardCashRegisters?: Resolver<
        GQResolversTypes["DashboardCashRegisterPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryDashboardCashRegistersArgs, never>
    >;
    dashboardInvoiceAmountPerMonthStats?: Resolver<
        Maybe<Array<GQResolversTypes["InvoiceAmountPerMonth"]>>,
        ParentType,
        ContextType
    >;
    dashboardInvoiceAmountStats?: Resolver<
        Maybe<Array<GQResolversTypes["InvoiceAmount"]>>,
        ParentType,
        ContextType,
        RequireFields<GQQueryDashboardInvoiceAmountStatsArgs, "from" | "to">
    >;
    department?: Resolver<
        Maybe<GQResolversTypes["Department"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryDepartmentArgs, "id">
    >;
    departments?: Resolver<
        GQResolversTypes["DepartmentPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryDepartmentsArgs, never>
    >;
    device?: Resolver<
        Maybe<GQResolversTypes["Device"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryDeviceArgs, "id">
    >;
    deviceByPinCode?: Resolver<
        Maybe<GQResolversTypes["Device"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryDeviceByPinCodeArgs, "pinCode">
    >;
    devices?: Resolver<
        GQResolversTypes["DevicePagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryDevicesArgs, never>
    >;
    discount?: Resolver<
        Maybe<GQResolversTypes["Discount"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryDiscountArgs, "id">
    >;
    discounts?: Resolver<
        GQResolversTypes["DiscountPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryDiscountsArgs, never>
    >;
    entityConfig?: Resolver<
        GQResolversTypes["EntityConfig"],
        ParentType,
        ContextType,
        RequireFields<GQQueryEntityConfigArgs, "entityId">
    >;
    entityConfigs?: Resolver<
        Array<Maybe<GQResolversTypes["EntityConfig"]>>,
        ParentType,
        ContextType,
        RequireFields<GQQueryEntityConfigsArgs, "entityIds">
    >;
    eventStoreSyncGet?: Resolver<
        Array<GQResolversTypes["Event"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryEventStoreSyncGetArgs, "query">
    >;
    eventStoreSyncLatestSequence?: Resolver<
        GQResolversTypes["Int"],
        ParentType,
        ContextType,
        RequireFields<GQQueryEventStoreSyncLatestSequenceArgs, never>
    >;
    giftCards?: Resolver<
        GQResolversTypes["AccountPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryGiftCardsArgs, never>
    >;
    integration?: Resolver<
        Maybe<GQResolversTypes["Integration"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryIntegrationArgs, "id">
    >;
    integrationAccounts?: Resolver<
        Maybe<Array<Maybe<GQResolversTypes["IntegrationAccount"]>>>,
        ParentType,
        ContextType,
        RequireFields<GQQueryIntegrationAccountsArgs, never>
    >;
    integrations?: Resolver<
        GQResolversTypes["IntegrationPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryIntegrationsArgs, never>
    >;
    inventoryForIds?: Resolver<
        Array<GQResolversTypes["InventoryProduct"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryInventoryForIdsArgs, "ids">
    >;
    inventoryProduct?: Resolver<
        Maybe<GQResolversTypes["InventoryProduct"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryInventoryProductArgs, "id">
    >;
    inventoryProductNoteTemplate?: Resolver<
        Maybe<GQResolversTypes["InventoryProductNoteTemplate"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryInventoryProductNoteTemplateArgs, "id">
    >;
    inventoryProductNoteTemplates?: Resolver<
        GQResolversTypes["InventoryProductNoteTemplatePagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryInventoryProductNoteTemplatesArgs, never>
    >;
    inventoryProducts?: Resolver<
        GQResolversTypes["InventoryProductPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryInventoryProductsArgs, never>
    >;
    invoice?: Resolver<
        Maybe<GQResolversTypes["InvoiceRM"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryInvoiceArgs, "id">
    >;
    invoices?: Resolver<
        GQResolversTypes["InvoiceRMPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryInvoicesArgs, never>
    >;
    layout?: Resolver<
        Maybe<GQResolversTypes["Layout"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryLayoutArgs, "id">
    >;
    me?: Resolver<GQResolversTypes["Me"], ParentType, ContextType>;
    merchant?: Resolver<
        Maybe<GQResolversTypes["Merchant"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryMerchantArgs, "id">
    >;
    merchantAccountNo?: Resolver<
        GQResolversTypes["ChartOfAccountNo"],
        ParentType,
        ContextType,
        RequireFields<
            GQQueryMerchantAccountNoArgs,
            "merchantId" | "defaultAccountNo" | "integrationType"
        >
    >;
    merchants?: Resolver<
        GQResolversTypes["MerchantPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryMerchantsArgs, never>
    >;
    miscButtons?: Resolver<
        Maybe<GQResolversTypes["MiscButtons"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryMiscButtonsArgs, "id">
    >;
    paymentConfiguration?: Resolver<
        GQResolversTypes["PaymentConfiguration"],
        ParentType,
        ContextType,
        RequireFields<GQQueryPaymentConfigurationArgs, "id">
    >;
    paymentConfigurations?: Resolver<
        GQResolversTypes["PaymentConfigurationPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryPaymentConfigurationsArgs, never>
    >;
    paymentMethod?: Resolver<
        GQResolversTypes["PaymentMethod"],
        ParentType,
        ContextType,
        RequireFields<GQQueryPaymentMethodArgs, "id">
    >;
    paymentMethods?: Resolver<
        GQResolversTypes["PaymentMethodPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryPaymentMethodsArgs, never>
    >;
    printer?: Resolver<
        GQResolversTypes["Printer"],
        ParentType,
        ContextType,
        RequireFields<GQQueryPrinterArgs, "id">
    >;
    printers?: Resolver<
        GQResolversTypes["PrinterPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryPrintersArgs, never>
    >;
    printersForCashRegister?: Resolver<
        GQResolversTypes["PrinterPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryPrintersForCashRegisterArgs, never>
    >;
    product?: Resolver<
        Maybe<GQResolversTypes["Product"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryProductArgs, "id">
    >;
    productGroup?: Resolver<
        Maybe<GQResolversTypes["ProductGroup"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryProductGroupArgs, "id">
    >;
    productGroups?: Resolver<
        GQResolversTypes["ProductGroupPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryProductGroupsArgs, never>
    >;
    productLayouts?: Resolver<
        GQResolversTypes["LayoutPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryProductLayoutsArgs, never>
    >;
    products?: Resolver<
        GQResolversTypes["ProductPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryProductsArgs, never>
    >;
    reportParameters?: Resolver<
        GQResolversTypes["ReportParameters"],
        ParentType,
        ContextType
    >;
    role?: Resolver<
        Maybe<GQResolversTypes["Role"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryRoleArgs, "id">
    >;
    roles?: Resolver<
        GQResolversTypes["RolePagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryRolesArgs, never>
    >;
    shift?: Resolver<
        Maybe<GQResolversTypes["ShiftRM"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryShiftArgs, "id">
    >;
    shifts?: Resolver<
        GQResolversTypes["ShiftRMPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryShiftsArgs, never>
    >;
    shiftsByCashRegister?: Resolver<
        GQResolversTypes["ShiftRMPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryShiftsByCashRegisterArgs, "cashRegisterId">
    >;
    shiftsByOpenedByUser?: Resolver<
        GQResolversTypes["ShiftRMPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryShiftsByOpenedByUserArgs, "userId">
    >;
    tableLayouts?: Resolver<
        GQResolversTypes["LayoutPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryTableLayoutsArgs, never>
    >;
    tag?: Resolver<
        GQResolversTypes["Tag"],
        ParentType,
        ContextType,
        RequireFields<GQQueryTagArgs, "id">
    >;
    tagGroup?: Resolver<
        GQResolversTypes["TagGroup"],
        ParentType,
        ContextType,
        RequireFields<GQQueryTagGroupArgs, "id">
    >;
    tagGroups?: Resolver<
        GQResolversTypes["TagGroupPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryTagGroupsArgs, never>
    >;
    tags?: Resolver<
        GQResolversTypes["TagPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryTagsArgs, never>
    >;
    tagsByAccount?: Resolver<
        GQResolversTypes["TagPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryTagsByAccountArgs, "accountId">
    >;
    tagsByCustomer?: Resolver<
        GQResolversTypes["TagPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryTagsByCustomerArgs, "customerId">
    >;
    tagsByGroup?: Resolver<
        GQResolversTypes["TagPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryTagsByGroupArgs, "groupId">
    >;
    tagsWithoutAccount?: Resolver<
        GQResolversTypes["TagPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryTagsWithoutAccountArgs, never>
    >;
    tagsWithoutCustomer?: Resolver<
        GQResolversTypes["TagPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryTagsWithoutCustomerArgs, never>
    >;
    transactions?: Resolver<
        GQResolversTypes["AccountTransactionPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryTransactionsArgs, never>
    >;
    user?: Resolver<
        Maybe<GQResolversTypes["User"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryUserArgs, "id">
    >;
    userAvailability?: Resolver<
        GQResolversTypes["Boolean"],
        ParentType,
        ContextType,
        RequireFields<GQQueryUserAvailabilityArgs, "username">
    >;
    userRoleConfiguration?: Resolver<
        Maybe<GQResolversTypes["UserRoleConfiguration"]>,
        ParentType,
        ContextType,
        RequireFields<
            GQQueryUserRoleConfigurationArgs,
            "userId" | "roleId" | "type"
        >
    >;
    users?: Resolver<
        GQResolversTypes["UserPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryUsersArgs, never>
    >;
    vat?: Resolver<
        Maybe<GQResolversTypes["Vat"]>,
        ParentType,
        ContextType,
        RequireFields<GQQueryVatArgs, "id">
    >;
    vats?: Resolver<
        GQResolversTypes["VatPagination"],
        ParentType,
        ContextType,
        RequireFields<GQQueryVatsArgs, never>
    >;
    venueAccessTicketParameters?: Resolver<
        GQResolversTypes["VenueAccessParameters"],
        ParentType,
        ContextType,
        RequireFields<GQQueryVenueAccessTicketParametersArgs, never>
    >;
};

export type GQReportCashRegisterResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ReportCashRegister"] = GQResolversParentTypes["ReportCashRegister"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQReportDepartmentResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ReportDepartment"] = GQResolversParentTypes["ReportDepartment"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQReportParametersResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ReportParameters"] = GQResolversParentTypes["ReportParameters"]
> = {
    departments?: Resolver<
        Array<GQResolversTypes["ReportDepartment"]>,
        ParentType,
        ContextType
    >;
    cashRegisters?: Resolver<
        Array<GQResolversTypes["ReportCashRegister"]>,
        ParentType,
        ContextType
    >;
    users?: Resolver<
        Array<GQResolversTypes["ReportUser"]>,
        ParentType,
        ContextType
    >;
    productGroups?: Resolver<
        Array<GQResolversTypes["ReportProductGroup"]>,
        ParentType,
        ContextType
    >;
    products?: Resolver<
        Array<GQResolversTypes["ReportProduct"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQReportProductResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ReportProduct"] = GQResolversParentTypes["ReportProduct"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQReportProductGroupResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ReportProductGroup"] = GQResolversParentTypes["ReportProductGroup"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQReportUserResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ReportUser"] = GQResolversParentTypes["ReportUser"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    username?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    firstName?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    lastName?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQRoleResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Role"] = GQResolversParentTypes["Role"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    type?: Resolver<GQResolversTypes["RoleTypeEnum"], ParentType, ContextType>;
    shared?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    merchant?: Resolver<
        Maybe<GQResolversTypes["Merchant"]>,
        ParentType,
        ContextType
    >;
    inUse?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    permissions?: Resolver<
        Array<GQResolversTypes["RolePermission"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQRoleDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["RoleDeleteResponse"] = GQResolversParentTypes["RoleDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQRolePaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["RolePagination"] = GQResolversParentTypes["RolePagination"]
> = {
    data?: Resolver<Array<GQResolversTypes["Role"]>, ParentType, ContextType>;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQRolePermissionResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["RolePermission"] = GQResolversParentTypes["RolePermission"]
> = {
    role?: Resolver<GQResolversTypes["Role"], ParentType, ContextType>;
    key?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    value?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQSelectUserRoleTypeResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["SelectUserRoleType"] = GQResolversParentTypes["SelectUserRoleType"]
> = {
    userRole?: Resolver<GQResolversTypes["UserRole"], ParentType, ContextType>;
    token?: Resolver<GQResolversTypes["AuthToken"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQShiftPaymentRmResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ShiftPaymentRM"] = GQResolversParentTypes["ShiftPaymentRM"]
> = {
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    type?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    amount?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQShiftRmResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ShiftRM"] = GQResolversParentTypes["ShiftRM"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    cashRegister?: Resolver<
        GQResolversTypes["CashRegister"],
        ParentType,
        ContextType
    >;
    openedByUser?: Resolver<GQResolversTypes["User"], ParentType, ContextType>;
    closedByUser?: Resolver<
        Maybe<GQResolversTypes["User"]>,
        ParentType,
        ContextType
    >;
    invoices?: Resolver<
        GQResolversTypes["InvoiceRMPagination"],
        ParentType,
        ContextType,
        RequireFields<GQShiftRmInvoicesArgs, never>
    >;
    payments?: Resolver<
        Maybe<Array<GQResolversTypes["ShiftPaymentRM"]>>,
        ParentType,
        ContextType
    >;
    openedAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    closedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    discount?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    amount?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    xReport?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    zReport?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQShiftRmPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["ShiftRMPagination"] = GQResolversParentTypes["ShiftRMPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["ShiftRM"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQSubscriptionResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Subscription"] = GQResolversParentTypes["Subscription"]
> = {
    dashboardUpdated?: SubscriptionResolver<
        Maybe<GQResolversTypes["Dashboard"]>,
        "dashboardUpdated",
        ParentType,
        ContextType,
        RequireFields<GQSubscriptionDashboardUpdatedArgs, "from" | "to">
    >;
    eventStoreSubscribe?: SubscriptionResolver<
        Array<GQResolversTypes["Event"]>,
        "eventStoreSubscribe",
        ParentType,
        ContextType,
        RequireFields<GQSubscriptionEventStoreSubscribeArgs, "query">
    >;
    merchantDataUpdated?: SubscriptionResolver<
        GQResolversTypes["Date"],
        "merchantDataUpdated",
        ParentType,
        ContextType
    >;
};

export type GQTagResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Tag"] = GQResolversParentTypes["Tag"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    merchantId?: Resolver<
        GQResolversTypes["Merchant"],
        ParentType,
        ContextType
    >;
    identifier?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    name?: Resolver<Maybe<GQResolversTypes["String"]>, ParentType, ContextType>;
    tagType?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    group?: Resolver<
        Maybe<GQResolversTypes["TagGroup"]>,
        ParentType,
        ContextType
    >;
    account?: Resolver<
        Maybe<GQResolversTypes["Account"]>,
        ParentType,
        ContextType
    >;
    customer?: Resolver<
        Maybe<GQResolversTypes["Customer"]>,
        ParentType,
        ContextType
    >;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQTagDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["TagDeleteResponse"] = GQResolversParentTypes["TagDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQTagGroupResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["TagGroup"] = GQResolversParentTypes["TagGroup"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    updateddAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQTagGroupDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["TagGroupDeleteResponse"] = GQResolversParentTypes["TagGroupDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQTagGroupPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["TagGroupPagination"] = GQResolversParentTypes["TagGroupPagination"]
> = {
    data?: Resolver<
        Array<GQResolversTypes["TagGroup"]>,
        ParentType,
        ContextType
    >;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQTagPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["TagPagination"] = GQResolversParentTypes["TagPagination"]
> = {
    data?: Resolver<Array<GQResolversTypes["Tag"]>, ParentType, ContextType>;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQUserResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["User"] = GQResolversParentTypes["User"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    username?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    email?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    firstName?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    lastName?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    active?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    pin?: Resolver<Maybe<GQResolversTypes["String"]>, ParentType, ContextType>;
    requireLoginPin?: Resolver<
        GQResolversTypes["Boolean"],
        ParentType,
        ContextType
    >;
    requireLogoutPin?: Resolver<
        GQResolversTypes["Boolean"],
        ParentType,
        ContextType
    >;
    requirePasswordChange?: Resolver<
        GQResolversTypes["Boolean"],
        ParentType,
        ContextType
    >;
    roles?: Resolver<
        Array<GQResolversTypes["UserRole"]>,
        ParentType,
        ContextType,
        RequireFields<GQUserRolesArgs, never>
    >;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    updatedAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    lastLoginAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQUserDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["UserDeleteResponse"] = GQResolversParentTypes["UserDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQUserPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["UserPagination"] = GQResolversParentTypes["UserPagination"]
> = {
    data?: Resolver<Array<GQResolversTypes["User"]>, ParentType, ContextType>;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQUserRoleResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["UserRole"] = GQResolversParentTypes["UserRole"]
> = {
    role?: Resolver<GQResolversTypes["Role"], ParentType, ContextType>;
    merchant?: Resolver<
        Maybe<GQResolversTypes["Merchant"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQUserRoleConfigurationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["UserRoleConfiguration"] = GQResolversParentTypes["UserRoleConfiguration"]
> = {
    user?: Resolver<GQResolversTypes["User"], ParentType, ContextType>;
    merchant?: Resolver<
        Maybe<GQResolversTypes["Merchant"]>,
        ParentType,
        ContextType
    >;
    role?: Resolver<GQResolversTypes["Role"], ParentType, ContextType>;
    type?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    data?: Resolver<GQResolversTypes["JSON"], ParentType, ContextType>;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQVatResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["Vat"] = GQResolversParentTypes["Vat"]
> = {
    id?: Resolver<GQResolversTypes["ID"], ParentType, ContextType>;
    merchant?: Resolver<GQResolversTypes["Merchant"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    percentage?: Resolver<GQResolversTypes["Float"], ParentType, ContextType>;
    isExternal?: Resolver<
        Maybe<GQResolversTypes["Boolean"]>,
        ParentType,
        ContextType
    >;
    externalId?: Resolver<
        Maybe<GQResolversTypes["String"]>,
        ParentType,
        ContextType
    >;
    createdAt?: Resolver<GQResolversTypes["Date"], ParentType, ContextType>;
    deletedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    updatedAt?: Resolver<
        Maybe<GQResolversTypes["Date"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQVatDeleteResponseResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["VatDeleteResponse"] = GQResolversParentTypes["VatDeleteResponse"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQVatPaginationResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["VatPagination"] = GQResolversParentTypes["VatPagination"]
> = {
    data?: Resolver<Array<GQResolversTypes["Vat"]>, ParentType, ContextType>;
    pagination?: Resolver<
        GQResolversTypes["Pagination"],
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQVenueAccessAccessTypeResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["VenueAccessAccessType"] = GQResolversParentTypes["VenueAccessAccessType"]
> = {
    id?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQVenueAccessEventResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["VenueAccessEvent"] = GQResolversParentTypes["VenueAccessEvent"]
> = {
    id?: Resolver<GQResolversTypes["Int"], ParentType, ContextType>;
    name?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    isActive?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQVenueAccessParametersResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["VenueAccessParameters"] = GQResolversParentTypes["VenueAccessParameters"]
> = {
    events?: Resolver<
        Maybe<Array<GQResolversTypes["VenueAccessEvent"]>>,
        ParentType,
        ContextType
    >;
    accessTypes?: Resolver<
        Array<GQResolversTypes["VenueAccessAccessType"]>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQVenueAccessTicketCancelledResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["VenueAccessTicketCancelled"] = GQResolversParentTypes["VenueAccessTicketCancelled"]
> = {
    status?: Resolver<GQResolversTypes["Boolean"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQVenueAccessTicketCreatedResolvers<
    ContextType = any,
    ParentType extends GQResolversParentTypes["VenueAccessTicketCreated"] = GQResolversParentTypes["VenueAccessTicketCreated"]
> = {
    tokenValue?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    ticketId?: Resolver<GQResolversTypes["String"], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GQResolvers<ContextType = any> = {
    Account?: GQAccountResolvers<ContextType>;
    AccountAssignTagsRelationResponse?: GQAccountAssignTagsRelationResponseResolvers<ContextType>;
    AccountBookkeepingPagination?: GQAccountBookkeepingPaginationResolvers<ContextType>;
    AccountBookkeepingTransaction?: GQAccountBookkeepingTransactionResolvers<ContextType>;
    AccountBookkeepingTransactionPagination?: GQAccountBookkeepingTransactionPaginationResolvers<ContextType>;
    AccountBuyFundsResponse?: GQAccountBuyFundsResponseResolvers<ContextType>;
    AccountCloseResponse?: GQAccountCloseResponseResolvers<ContextType>;
    AccountOpenResponse?: GQAccountOpenResponseResolvers<ContextType>;
    AccountPagination?: GQAccountPaginationResolvers<ContextType>;
    AccountPayAmountResponse?: GQAccountPayAmountResponseResolvers<ContextType>;
    AccountRefundAmountResponse?: GQAccountRefundAmountResponseResolvers<ContextType>;
    AccountReturnFundsResponse?: GQAccountReturnFundsResponseResolvers<ContextType>;
    AccountRevertTransactionResponse?: GQAccountRevertTransactionResponseResolvers<ContextType>;
    AccountTransaction?: GQAccountTransactionResolvers<ContextType>;
    AccountTransactionPagination?: GQAccountTransactionPaginationResolvers<ContextType>;
    AddEventResponse?: GQAddEventResponseResolvers<ContextType>;
    AppData?: GQAppDataResolvers<ContextType>;
    AuthAttempt?: GQAuthAttemptResolvers<ContextType>;
    AuthToken?: GQAuthTokenResolvers<ContextType>;
    Balance?: GQBalanceResolvers<ContextType>;
    BookkeepingAccount?: GQBookkeepingAccountResolvers<ContextType>;
    BooleanResponse?: GQBooleanResponseResolvers<ContextType>;
    CardTransaction?: GQCardTransactionResolvers<ContextType>;
    CardTransactionPagination?: GQCardTransactionPaginationResolvers<ContextType>;
    CashRegister?: GQCashRegisterResolvers<ContextType>;
    CashRegisterDeleteResponse?: GQCashRegisterDeleteResponseResolvers<ContextType>;
    CashRegisterPagination?: GQCashRegisterPaginationResolvers<ContextType>;
    CashRegisterStatus?: GQCashRegisterStatusResolvers<ContextType>;
    CashRegisterStatusPagination?: GQCashRegisterStatusPaginationResolvers<ContextType>;
    CashRegisterVersion?: GQCashRegisterVersionResolvers<ContextType>;
    ChartOfAccountNo?: GQChartOfAccountNoResolvers<ContextType>;
    Configuration?: GQConfigurationResolvers<ContextType>;
    Currency?: GQCurrencyResolvers<ContextType>;
    CurrencyDeleteResponse?: GQCurrencyDeleteResponseResolvers<ContextType>;
    CurrencyPagination?: GQCurrencyPaginationResolvers<ContextType>;
    Customer?: GQCustomerResolvers<ContextType>;
    CustomerAssignTagsRelationResponse?: GQCustomerAssignTagsRelationResponseResolvers<ContextType>;
    CustomerDeleteResponse?: GQCustomerDeleteResponseResolvers<ContextType>;
    CustomerGroup?: GQCustomerGroupResolvers<ContextType>;
    CustomerGroupDeleteResponse?: GQCustomerGroupDeleteResponseResolvers<ContextType>;
    CustomerGroupPagination?: GQCustomerGroupPaginationResolvers<ContextType>;
    CustomerPagination?: GQCustomerPaginationResolvers<ContextType>;
    Dashboard?: GQDashboardResolvers<ContextType>;
    DashboardAccountItem?: GQDashboardAccountItemResolvers<ContextType>;
    DashboardCashRegister?: GQDashboardCashRegisterResolvers<ContextType>;
    DashboardCashRegisterPagination?: GQDashboardCashRegisterPaginationResolvers<ContextType>;
    DashboardDepartmentItem?: GQDashboardDepartmentItemResolvers<ContextType>;
    DashboardProductGroupItem?: GQDashboardProductGroupItemResolvers<ContextType>;
    DashboardProductItem?: GQDashboardProductItemResolvers<ContextType>;
    DashboardTotals?: GQDashboardTotalsResolvers<ContextType>;
    Date?: GraphQLScalarType;
    Department?: GQDepartmentResolvers<ContextType>;
    DepartmentDeleteResponse?: GQDepartmentDeleteResponseResolvers<ContextType>;
    DepartmentPagination?: GQDepartmentPaginationResolvers<ContextType>;
    Device?: GQDeviceResolvers<ContextType>;
    DevicePagination?: GQDevicePaginationResolvers<ContextType>;
    DevicePinCodeValidation?: GQDevicePinCodeValidationResolvers<ContextType>;
    Discount?: GQDiscountResolvers<ContextType>;
    DiscountPagination?: GQDiscountPaginationResolvers<ContextType>;
    Entity?: GQEntityResolvers<ContextType>;
    EntityConfig?: GQEntityConfigResolvers<ContextType>;
    EntityConfigDeleteResponse?: GQEntityConfigDeleteResponseResolvers<ContextType>;
    Event?: GQEventResolvers<ContextType>;
    GpsPoint?: GQGpsPointResolvers<ContextType>;
    IPagination?: GQIPaginationResolvers<ContextType>;
    Integration?: GQIntegrationResolvers<ContextType>;
    IntegrationAccount?: GQIntegrationAccountResolvers<ContextType>;
    IntegrationAccounts?: GQIntegrationAccountsResolvers<ContextType>;
    IntegrationDeleteResponse?: GQIntegrationDeleteResponseResolvers<ContextType>;
    IntegrationPagination?: GQIntegrationPaginationResolvers<ContextType>;
    InventoryProduct?: GQInventoryProductResolvers<ContextType>;
    InventoryProductNoteTemplate?: GQInventoryProductNoteTemplateResolvers<ContextType>;
    InventoryProductNoteTemplatePagination?: GQInventoryProductNoteTemplatePaginationResolvers<ContextType>;
    InventoryProductPagination?: GQInventoryProductPaginationResolvers<ContextType>;
    InvoiceAmount?: GQInvoiceAmountResolvers<ContextType>;
    InvoiceAmountPerMonth?: GQInvoiceAmountPerMonthResolvers<ContextType>;
    InvoiceItemExternalRM?: GQInvoiceItemExternalRmResolvers<ContextType>;
    InvoiceItemRM?: GQInvoiceItemRmResolvers<ContextType>;
    InvoicePaymentRM?: GQInvoicePaymentRmResolvers<ContextType>;
    InvoiceRM?: GQInvoiceRmResolvers<ContextType>;
    InvoiceRMPagination?: GQInvoiceRmPaginationResolvers<ContextType>;
    JSON?: GraphQLScalarType;
    Layout?: GQLayoutResolvers<ContextType>;
    LayoutDeleteResponse?: GQLayoutDeleteResponseResolvers<ContextType>;
    LayoutPagination?: GQLayoutPaginationResolvers<ContextType>;
    Me?: GQMeResolvers<ContextType>;
    Merchant?: GQMerchantResolvers<ContextType>;
    MerchantChartOfAccount?: GQMerchantChartOfAccountResolvers<ContextType>;
    MerchantDeleteResponse?: GQMerchantDeleteResponseResolvers<ContextType>;
    MerchantPagination?: GQMerchantPaginationResolvers<ContextType>;
    MiscButtons?: GQMiscButtonsResolvers<ContextType>;
    MiscButtonsDeleteResponse?: GQMiscButtonsDeleteResponseResolvers<ContextType>;
    MiscButtonsPagination?: GQMiscButtonsPaginationResolvers<ContextType>;
    Mutation?: GQMutationResolvers<ContextType>;
    POS?: GQPosResolvers<ContextType>;
    Pagination?: GQPaginationResolvers<ContextType>;
    Partner?: GQPartnerResolvers<ContextType>;
    PaymentConfiguration?: GQPaymentConfigurationResolvers<ContextType>;
    PaymentConfigurationDeleteResponse?: GQPaymentConfigurationDeleteResponseResolvers<ContextType>;
    PaymentConfigurationMethod?: GQPaymentConfigurationMethodResolvers<ContextType>;
    PaymentConfigurationPagination?: GQPaymentConfigurationPaginationResolvers<ContextType>;
    PaymentMethod?: GQPaymentMethodResolvers<ContextType>;
    PaymentMethodDeleteResponse?: GQPaymentMethodDeleteResponseResolvers<ContextType>;
    PaymentMethodPagination?: GQPaymentMethodPaginationResolvers<ContextType>;
    Printer?: GQPrinterResolvers<ContextType>;
    PrinterDeleteResponse?: GQPrinterDeleteResponseResolvers<ContextType>;
    PrinterPagination?: GQPrinterPaginationResolvers<ContextType>;
    Product?: GQProductResolvers<ContextType>;
    ProductBarcode?: GQProductBarcodeResolvers<ContextType>;
    ProductDeleteResponse?: GQProductDeleteResponseResolvers<ContextType>;
    ProductGroup?: GQProductGroupResolvers<ContextType>;
    ProductGroupDeleteResponse?: GQProductGroupDeleteResponseResolvers<ContextType>;
    ProductGroupPagination?: GQProductGroupPaginationResolvers<ContextType>;
    ProductPagination?: GQProductPaginationResolvers<ContextType>;
    Query?: GQQueryResolvers<ContextType>;
    ReportCashRegister?: GQReportCashRegisterResolvers<ContextType>;
    ReportDepartment?: GQReportDepartmentResolvers<ContextType>;
    ReportParameters?: GQReportParametersResolvers<ContextType>;
    ReportProduct?: GQReportProductResolvers<ContextType>;
    ReportProductGroup?: GQReportProductGroupResolvers<ContextType>;
    ReportUser?: GQReportUserResolvers<ContextType>;
    Role?: GQRoleResolvers<ContextType>;
    RoleDeleteResponse?: GQRoleDeleteResponseResolvers<ContextType>;
    RolePagination?: GQRolePaginationResolvers<ContextType>;
    RolePermission?: GQRolePermissionResolvers<ContextType>;
    SelectUserRoleType?: GQSelectUserRoleTypeResolvers<ContextType>;
    ShiftPaymentRM?: GQShiftPaymentRmResolvers<ContextType>;
    ShiftRM?: GQShiftRmResolvers<ContextType>;
    ShiftRMPagination?: GQShiftRmPaginationResolvers<ContextType>;
    Subscription?: GQSubscriptionResolvers<ContextType>;
    Tag?: GQTagResolvers<ContextType>;
    TagDeleteResponse?: GQTagDeleteResponseResolvers<ContextType>;
    TagGroup?: GQTagGroupResolvers<ContextType>;
    TagGroupDeleteResponse?: GQTagGroupDeleteResponseResolvers<ContextType>;
    TagGroupPagination?: GQTagGroupPaginationResolvers<ContextType>;
    TagPagination?: GQTagPaginationResolvers<ContextType>;
    User?: GQUserResolvers<ContextType>;
    UserDeleteResponse?: GQUserDeleteResponseResolvers<ContextType>;
    UserPagination?: GQUserPaginationResolvers<ContextType>;
    UserRole?: GQUserRoleResolvers<ContextType>;
    UserRoleConfiguration?: GQUserRoleConfigurationResolvers<ContextType>;
    Vat?: GQVatResolvers<ContextType>;
    VatDeleteResponse?: GQVatDeleteResponseResolvers<ContextType>;
    VatPagination?: GQVatPaginationResolvers<ContextType>;
    VenueAccessAccessType?: GQVenueAccessAccessTypeResolvers<ContextType>;
    VenueAccessEvent?: GQVenueAccessEventResolvers<ContextType>;
    VenueAccessParameters?: GQVenueAccessParametersResolvers<ContextType>;
    VenueAccessTicketCancelled?: GQVenueAccessTicketCancelledResolvers<ContextType>;
    VenueAccessTicketCreated?: GQVenueAccessTicketCreatedResolvers<ContextType>;
};

export type GQEntityConfigsQueryVariables = Exact<{
    entityIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type GQEntityConfigsQuery = { __typename?: "Query" } & {
    entityConfigs: Array<
        Maybe<
            { __typename?: "EntityConfig" } & Pick<
                GQEntityConfig,
                "entityId" | "data"
            >
        >
    >;
};

export type GQEntityConfigSaveMutationVariables = Exact<{
    entityId: Scalars["ID"];
    partialData: Scalars["JSON"];
    disabledData: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GQEntityConfigSaveMutation = { __typename?: "Mutation" } & {
    entityConfigSave: { __typename?: "EntityConfig" } & Pick<
        GQEntityConfig,
        "entityId" | "data"
    >;
};

export type GQEntityConfigDeleteMutationVariables = Exact<{
    entityId: Scalars["ID"];
}>;

export type GQEntityConfigDeleteMutation = { __typename?: "Mutation" } & {
    entityConfigDelete: { __typename?: "EntityConfigDeleteResponse" } & Pick<
        GQEntityConfigDeleteResponse,
        "status"
    >;
};

export type GQDefaultPaginationFieldsFragment = {
    __typename?: "Pagination";
} & Pick<GQPagination, "pages" | "sort" | "sortDirection" | "resultCount">;

export type GQAllAccountFieldsFragment = { __typename?: "Account" } & Pick<
    GQAccount,
    | "id"
    | "name"
    | "type"
    | "status"
    | "amount"
    | "systemLabel"
    | "createdAt"
    | "externalId"
> & {
        merchant: { __typename?: "Merchant" } & Pick<GQMerchant, "id" | "name">;
        customer?: Maybe<
            { __typename?: "Customer" } & Pick<GQCustomer, "id" | "name"> & {
                    group?: Maybe<
                        { __typename?: "CustomerGroup" } & Pick<
                            GQCustomerGroup,
                            "id" | "name"
                        >
                    >;
                }
        >;
        vat?: Maybe<{ __typename?: "Vat" } & Pick<GQVat, "id">>;
    };

export type GQAllBookkeepingAccountFieldsFragment = {
    __typename?: "BookkeepingAccount";
} & Pick<GQBookkeepingAccount, "id" | "name" | "systemLabel" | "createdAt"> & {
        merchant: { __typename?: "Merchant" } & Pick<GQMerchant, "id" | "name">;
    };

export type GQAllAccountTransactionFieldsFragment = {
    __typename?: "AccountTransaction";
} & Pick<
    GQAccountTransaction,
    "id" | "userId" | "transactionAt" | "amount" | "invoiceId"
> & {
        user?: Maybe<{ __typename?: "User" } & Pick<GQUser, "username">>;
        tag?: Maybe<
            { __typename?: "Tag" } & Pick<GQTag, "id" | "identifier" | "name">
        >;
    };

export type GQAllAccountBookkeepingTransactionFieldsFragment = {
    __typename?: "AccountBookkeepingTransaction";
} & Pick<
    GQAccountBookkeepingTransaction,
    "id" | "transactionAt" | "amount" | "type" | "invoiceId"
>;

export type GQAccountsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQAccountsQuery = { __typename?: "Query" } & {
    accounts: { __typename?: "AccountPagination" } & {
        data: Array<{ __typename?: "Account" } & GQAllAccountFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQGiftCardsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQGiftCardsQuery = { __typename?: "Query" } & {
    giftCards: { __typename?: "AccountPagination" } & {
        data: Array<{ __typename?: "Account" } & GQAllAccountFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQCustomerAccountsQueryVariables = Exact<{
    customerId?: Maybe<Scalars["ID"]>;
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQCustomerAccountsQuery = { __typename?: "Query" } & {
    customerAccounts: { __typename?: "AccountPagination" } & {
        data: Array<{ __typename?: "Account" } & GQAllAccountFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQBookkeepingAccountsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQBookkeepingAccountsQuery = { __typename?: "Query" } & {
    bookkeepingAccounts: { __typename?: "AccountBookkeepingPagination" } & {
        data: Array<
            {
                __typename?: "BookkeepingAccount";
            } & GQAllBookkeepingAccountFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQAccountQueryVariables = Exact<{
    id: Scalars["ID"];
    transactionPagination?: Maybe<GQPaginationInput>;
    transactionSearch?: Maybe<GQSearchInput>;
    tagPagination?: Maybe<GQPaginationInput>;
    tagSearch?: Maybe<GQSearchInput>;
    numberOfTransactions?: Maybe<Scalars["Int"]>;
}>;

export type GQAccountQuery = { __typename?: "Query" } & {
    account: { __typename?: "Account" } & {
        transactions: { __typename?: "AccountTransactionPagination" } & {
            data: Array<
                {
                    __typename?: "AccountTransaction";
                } & GQAllAccountTransactionFieldsFragment
            >;
            pagination: {
                __typename?: "Pagination";
            } & GQDefaultPaginationFieldsFragment;
        };
        tags: { __typename?: "TagPagination" } & {
            data: Array<{ __typename?: "Tag" } & GQTagScreenFieldsFragment>;
            pagination: {
                __typename?: "Pagination";
            } & GQDefaultPaginationFieldsFragment;
        };
        balance: { __typename?: "Balance" } & Pick<GQBalance, "receipt">;
    } & GQAllAccountFieldsFragment;
};

export type GQBookkeepingAccountQueryVariables = Exact<{
    id: Scalars["ID"];
    transactionPagination?: Maybe<GQPaginationInput>;
    transactionSearch?: Maybe<GQSearchInput>;
}>;

export type GQBookkeepingAccountQuery = { __typename?: "Query" } & {
    bookkeepingAccount: { __typename?: "BookkeepingAccount" } & {
        bookkeepingTransactions: {
            __typename?: "AccountBookkeepingTransactionPagination";
        } & {
            data: Array<
                {
                    __typename?: "AccountBookkeepingTransaction";
                } & GQAllAccountBookkeepingTransactionFieldsFragment
            >;
            pagination: {
                __typename?: "Pagination";
            } & GQDefaultPaginationFieldsFragment;
        };
    } & GQAllBookkeepingAccountFieldsFragment;
};

export type GQAccountEnabledTagsQueryVariables = Exact<{
    id: Scalars["ID"];
    tagPagination?: Maybe<GQPaginationInput>;
    tagSearch?: Maybe<GQSearchInput>;
}>;

export type GQAccountEnabledTagsQuery = { __typename?: "Query" } & {
    account: { __typename?: "Account" } & {
        tags: { __typename?: "TagPagination" } & {
            data: Array<{ __typename?: "Tag" } & GQTagScreenFieldsFragment>;
            pagination: {
                __typename?: "Pagination";
            } & GQDefaultPaginationFieldsFragment;
        };
    };
};

export type GQAccountTagsViewQueryVariables = Exact<{
    id: Scalars["ID"];
    tagPagination?: Maybe<GQPaginationInput>;
    tagSearch?: Maybe<GQSearchInput>;
}>;

export type GQAccountTagsViewQuery = { __typename?: "Query" } & {
    account: { __typename?: "Account" } & {
        tags: { __typename?: "TagPagination" } & {
            data: Array<{ __typename?: "Tag" } & GQTagScreenFieldsFragment>;
            pagination: {
                __typename?: "Pagination";
            } & GQDefaultPaginationFieldsFragment;
        };
    } & GQAllAccountFieldsFragment;
};

export type GQAllTransactionsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    accountId?: Maybe<Scalars["String"]>;
}>;

export type GQAllTransactionsQuery = { __typename?: "Query" } & {
    transactions: { __typename?: "AccountTransactionPagination" } & {
        data: Array<
            {
                __typename?: "AccountTransaction";
            } & GQAllAccountTransactionFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQAccountCreateMutationVariables = Exact<{
    account: GQAccountCreate;
}>;

export type GQAccountCreateMutation = { __typename?: "Mutation" } & {
    accountCreate: { __typename?: "Account" } & GQAllAccountFieldsFragment;
};

export type GQAccountSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    account: GQAccountInput;
}>;

export type GQAccountSaveMutation = { __typename?: "Mutation" } & {
    accountSave: { __typename?: "Account" } & GQAllAccountFieldsFragment;
};

export type GQAccountCloseMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQAccountCloseMutation = { __typename?: "Mutation" } & {
    accountClose: { __typename?: "AccountCloseResponse" } & Pick<
        GQAccountCloseResponse,
        "status"
    >;
};

export type GQAccountOpenMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQAccountOpenMutation = { __typename?: "Mutation" } & {
    accountOpen: { __typename?: "AccountOpenResponse" } & Pick<
        GQAccountOpenResponse,
        "status"
    >;
};

export type GQAccountAssignTagsMutationVariables = Exact<{
    id: Scalars["ID"];
    tagIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type GQAccountAssignTagsMutation = { __typename?: "Mutation" } & {
    accountAssignTags: {
        __typename?: "AccountAssignTagsRelationResponse";
    } & Pick<GQAccountAssignTagsRelationResponse, "status">;
};

export type GQAccountPayAmountMutationVariables = Exact<{
    id: Scalars["ID"];
    orderId: Scalars["ID"];
    amount: Scalars["Float"];
    userId: Scalars["ID"];
    tagId?: Maybe<Scalars["ID"]>;
    tagIdentifier?: Maybe<Scalars["String"]>;
}>;

export type GQAccountPayAmountMutation = { __typename?: "Mutation" } & {
    accountPayAmount: { __typename?: "AccountPayAmountResponse" } & Pick<
        GQAccountPayAmountResponse,
        "transactionId" | "status" | "type" | "receipt"
    >;
};

export type GQAccountRefundAmountMutationVariables = Exact<{
    id: Scalars["ID"];
    orderId: Scalars["ID"];
    amount: Scalars["Float"];
    userId: Scalars["ID"];
    tagId?: Maybe<Scalars["ID"]>;
    tagIdentifier?: Maybe<Scalars["String"]>;
}>;

export type GQAccountRefundAmountMutation = { __typename?: "Mutation" } & {
    accountRefundAmount: { __typename?: "AccountRefundAmountResponse" } & Pick<
        GQAccountRefundAmountResponse,
        "transactionId" | "status" | "type" | "receipt"
    >;
};

export type GQCustomerScreenFieldsFragment = { __typename?: "Customer" } & Pick<
    GQCustomer,
    | "id"
    | "name"
    | "address"
    | "zip"
    | "city"
    | "email"
    | "phoneNumber"
    | "comment"
    | "cvrNumber"
    | "contactName"
    | "createdAt"
> & {
        group?: Maybe<
            { __typename?: "CustomerGroup" } & Pick<
                GQCustomerGroup,
                "id" | "name"
            >
        >;
        accounts: Array<{ __typename?: "Account" } & Pick<GQAccount, "amount">>;
    };

export type GQCustomersQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQCustomersQuery = { __typename?: "Query" } & {
    customers: { __typename?: "CustomerPagination" } & {
        data: Array<
            { __typename?: "Customer" } & GQCustomerScreenFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQCustomerQueryVariables = Exact<{
    id: Scalars["ID"];
    tagPagination?: Maybe<GQPaginationInput>;
    tagSearch?: Maybe<GQSearchInput>;
}>;

export type GQCustomerQuery = { __typename?: "Query" } & {
    customer: { __typename?: "Customer" } & {
        tags: { __typename?: "TagPagination" } & {
            data: Array<{ __typename?: "Tag" } & GQTagScreenFieldsFragment>;
            pagination: {
                __typename?: "Pagination";
            } & GQDefaultPaginationFieldsFragment;
        };
    } & GQCustomerScreenFieldsFragment;
};

export type GQCustomersInGroupQueryVariables = Exact<{
    groupId: Scalars["ID"];
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQCustomersInGroupQuery = { __typename?: "Query" } & {
    customersInGroup: { __typename?: "CustomerPagination" } & {
        data: Array<
            { __typename?: "Customer" } & GQCustomerScreenFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQCustomerFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQCustomerFormQuery = { __typename?: "Query" } & {
    customers: { __typename?: "CustomerPagination" } & {
        data: Array<
            { __typename?: "Customer" } & Pick<GQCustomer, "id" | "name">
        >;
    };
    customerGroups: { __typename?: "CustomerGroupPagination" } & {
        data: Array<
            { __typename?: "CustomerGroup" } & Pick<
                GQCustomerGroup,
                "id" | "name"
            >
        >;
    };
};

export type GQCustomerCreateMutationVariables = Exact<{
    customer: GQCustomerInput;
}>;

export type GQCustomerCreateMutation = { __typename?: "Mutation" } & {
    customerCreate: {
        __typename?: "Customer";
    } & GQCustomerScreenFieldsFragment;
};

export type GQCustomerSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    customer: GQCustomerInput;
}>;

export type GQCustomerSaveMutation = { __typename?: "Mutation" } & {
    customerSave: { __typename?: "Customer" } & GQCustomerScreenFieldsFragment;
};

export type GQCustomerDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQCustomerDeleteMutation = { __typename?: "Mutation" } & {
    customerDelete: { __typename?: "CustomerDeleteResponse" } & Pick<
        GQCustomerDeleteResponse,
        "status"
    >;
};

export type GQCustomerAssignTagsMutationVariables = Exact<{
    id: Scalars["ID"];
    tagIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type GQCustomerAssignTagsMutation = { __typename?: "Mutation" } & {
    customerAssignTags: {
        __typename?: "CustomerAssignTagsRelationResponse";
    } & Pick<GQCustomerAssignTagsRelationResponse, "status">;
};

export type GQCustomerGroupScreenFieldsFragment = {
    __typename?: "CustomerGroup";
} & Pick<GQCustomerGroup, "id" | "name">;

export type GQCustomerGroupsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQCustomerGroupsQuery = { __typename?: "Query" } & {
    customerGroups: { __typename?: "CustomerGroupPagination" } & {
        data: Array<
            {
                __typename?: "CustomerGroup";
            } & GQCustomerGroupScreenFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQCustomerGroupQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQCustomerGroupQuery = { __typename?: "Query" } & {
    customerGroup: {
        __typename?: "CustomerGroup";
    } & GQCustomerGroupScreenFieldsFragment;
};

export type GQCustomerGroupCreateMutationVariables = Exact<{
    customerGroup: GQCustomerGroupInput;
}>;

export type GQCustomerGroupCreateMutation = { __typename?: "Mutation" } & {
    customerGroupCreate: {
        __typename?: "CustomerGroup";
    } & GQCustomerGroupScreenFieldsFragment;
};

export type GQCustomerGroupSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    customerGroup: GQCustomerGroupInput;
}>;

export type GQCustomerGroupSaveMutation = { __typename?: "Mutation" } & {
    customerGroupSave: {
        __typename?: "CustomerGroup";
    } & GQCustomerGroupScreenFieldsFragment;
};

export type GQCustomerGroupDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQCustomerGroupDeleteMutation = { __typename?: "Mutation" } & {
    customerGroupDelete: { __typename?: "CustomerGroupDeleteResponse" } & Pick<
        GQCustomerGroupDeleteResponse,
        "status"
    >;
};

export type GQCardTransactionScreenFieldsFragment = {
    __typename?: "CardTransaction";
} & Pick<
    GQCardTransaction,
    "id" | "status" | "orderId" | "amount" | "receipt" | "transactionAt"
> & {
        cashRegister: { __typename?: "CashRegister" } & Pick<
            GQCashRegister,
            "name"
        >;
    };

export type GQCardTransactionQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQCardTransactionQuery = { __typename?: "Query" } & {
    cardTransaction: {
        __typename?: "CardTransaction";
    } & GQCardTransactionScreenFieldsFragment;
};

export type GQCardTransactionsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQCardTransactionsQuery = { __typename?: "Query" } & {
    cardTransactions: { __typename?: "CardTransactionPagination" } & {
        data: Array<
            {
                __typename?: "CardTransaction";
            } & GQCardTransactionScreenFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQCashRegisterFragment = { __typename?: "CashRegister" } & Pick<
    GQCashRegister,
    "id" | "name" | "createdAt" | "deletedAt" | "lastAppDataSentAt"
> & {
        device?: Maybe<
            { __typename?: "Device" } & Pick<
                GQDevice,
                | "id"
                | "osName"
                | "osVersion"
                | "appVersion"
                | "appBuild"
                | "serialNumber"
                | "hardwareModel"
                | "hardwareProducer"
                | "macAddress"
                | "language"
                | "lastSeen"
            >
        >;
        department?: Maybe<
            { __typename?: "Department" } & Pick<GQDepartment, "id" | "name">
        >;
        merchant: { __typename?: "Merchant" } & Pick<
            GQMerchant,
            "id" | "name" | "lastNotifySentAt"
        >;
    };

export type GQCashRegistersQueryVariables = Exact<{
    pagination: GQPaginationInput;
    search: GQSearchInput;
}>;

export type GQCashRegistersQuery = { __typename?: "Query" } & {
    cashRegisters: { __typename?: "CashRegisterPagination" } & {
        data: Array<{ __typename?: "CashRegister" } & GQCashRegisterFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQCashRegistersStatusQueryVariables = Exact<{
    pagination: GQPaginationInput;
    search: GQSearchInput;
}>;

export type GQCashRegistersStatusQuery = { __typename?: "Query" } & {
    cashRegistersStatus: { __typename?: "CashRegisterStatusPagination" } & {
        data: Array<
            { __typename?: "CashRegisterStatus" } & Pick<
                GQCashRegisterStatus,
                "id" | "name" | "lastAppDataSentAt" | "status"
            > & {
                    device?: Maybe<
                        { __typename?: "Device" } & Pick<
                            GQDevice,
                            "id" | "lastSeen"
                        >
                    >;
                    department?: Maybe<
                        { __typename?: "Department" } & Pick<
                            GQDepartment,
                            "id" | "name"
                        >
                    >;
                    merchant: { __typename?: "Merchant" } & Pick<
                        GQMerchant,
                        "id" | "name" | "lastNotifySentAt"
                    >;
                }
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQCashRegisterEditFormQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQCashRegisterEditFormQuery = { __typename?: "Query" } & {
    cashRegister?: Maybe<
        { __typename?: "CashRegister" } & GQCashRegisterFragment
    >;
    departments: { __typename?: "DepartmentPagination" } & {
        data: Array<
            { __typename?: "Department" } & Pick<GQDepartment, "id" | "name">
        >;
    };
};

export type GQCashRegisterCreateMutationVariables = Exact<{
    cashRegister: GQCashRegisterCreateInput;
}>;

export type GQCashRegisterCreateMutation = { __typename?: "Mutation" } & {
    cashRegisterCreate: {
        __typename?: "CashRegister";
    } & GQCashRegisterFragment;
};

export type GQCashRegisterEditMutationVariables = Exact<{
    id: Scalars["ID"];
    cashRegister: GQCashRegisterEditInput;
}>;

export type GQCashRegisterEditMutation = { __typename?: "Mutation" } & {
    cashRegisterEdit: { __typename?: "CashRegister" } & GQCashRegisterFragment;
};

export type GQCashRegisterDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQCashRegisterDeleteMutation = { __typename?: "Mutation" } & {
    cashRegisterDelete: { __typename?: "CashRegisterDeleteResponse" } & Pick<
        GQCashRegisterDeleteResponse,
        "status"
    >;
};

export type GQCashRegistersDeleteMutationVariables = Exact<{
    ids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type GQCashRegistersDeleteMutation = { __typename?: "Mutation" } & {
    cashRegistersDelete: { __typename?: "CashRegisterDeleteResponse" } & Pick<
        GQCashRegisterDeleteResponse,
        "status"
    >;
};

export type GQCashRegisterClaimMutationVariables = Exact<{
    id: Scalars["ID"];
    devicePinCode: Scalars["String"];
}>;

export type GQCashRegisterClaimMutation = { __typename?: "Mutation" } & {
    cashRegisterClaim?: Maybe<
        { __typename?: "CashRegister" } & GQCashRegisterFragment
    >;
};

export type GQDeviceByPinCodeQueryVariables = Exact<{
    pinCode: Scalars["String"];
}>;

export type GQDeviceByPinCodeQuery = { __typename?: "Query" } & {
    deviceByPinCode?: Maybe<{ __typename?: "Device" } & Pick<GQDevice, "id">>;
};

export type GQAllCurrencyFieldsFragment = { __typename?: "Currency" } & Pick<
    GQCurrency,
    "id" | "code" | "rate" | "createdAt" | "deletedAt"
> & { merchant: { __typename?: "Merchant" } & Pick<GQMerchant, "id" | "name"> };

export type GQCurrenciesQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQCurrenciesQuery = { __typename?: "Query" } & {
    currencies: { __typename?: "CurrencyPagination" } & {
        data: Array<{ __typename?: "Currency" } & GQAllCurrencyFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQCurrencyQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQCurrencyQuery = { __typename?: "Query" } & {
    currency?: Maybe<{ __typename?: "Currency" } & GQAllCurrencyFieldsFragment>;
};

export type GQCurrencyFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQCurrencyFormQuery = { __typename?: "Query" } & {
    products: { __typename?: "ProductPagination" } & {
        data: Array<
            { __typename?: "Product" } & Pick<GQProduct, "id" | "name">
        >;
    };
};

export type GQCurrencyCreateMutationVariables = Exact<{
    currency: GQCurrencyInput;
}>;

export type GQCurrencyCreateMutation = { __typename?: "Mutation" } & {
    currencyCreate: { __typename?: "Currency" } & GQAllCurrencyFieldsFragment;
};

export type GQCurrencySaveMutationVariables = Exact<{
    id: Scalars["ID"];
    currency: GQCurrencyInput;
}>;

export type GQCurrencySaveMutation = { __typename?: "Mutation" } & {
    currencySave: { __typename?: "Currency" } & GQAllCurrencyFieldsFragment;
};

export type GQCurrencyDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQCurrencyDeleteMutation = { __typename?: "Mutation" } & {
    currencyDelete: { __typename?: "CurrencyDeleteResponse" } & Pick<
        GQCurrencyDeleteResponse,
        "status"
    >;
};

export type GQDashboardCashRegistersQueryVariables = Exact<{
    pagination: GQPaginationInput;
    search: GQSearchInput;
}>;

export type GQDashboardCashRegistersQuery = { __typename?: "Query" } & {
    dashboardCashRegisters: {
        __typename?: "DashboardCashRegisterPagination";
    } & {
        data: Array<
            { __typename?: "DashboardCashRegister" } & Pick<
                GQDashboardCashRegister,
                "id" | "name"
            > & {
                    device?: Maybe<
                        { __typename?: "Device" } & Pick<
                            GQDevice,
                            | "id"
                            | "osName"
                            | "osVersion"
                            | "appVersion"
                            | "appBuild"
                            | "serialNumber"
                            | "hardwareModel"
                            | "hardwareProducer"
                            | "macAddress"
                            | "language"
                            | "lastSeen"
                        >
                    >;
                    merchant?: Maybe<
                        { __typename?: "Merchant" } & Pick<
                            GQMerchant,
                            "id" | "name"
                        >
                    >;
                }
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQDashboardCashRegisterStatsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GQDashboardCashRegisterStatsQuery = { __typename?: "Query" } & {
    dashboardCashRegisterStats: Array<
        { __typename?: "CashRegisterVersion" } & Pick<
            GQCashRegisterVersion,
            "label" | "count"
        >
    >;
};

export type GQDashboardInvoiceAmountStatsQueryVariables = Exact<{
    from: Scalars["Date"];
    to: Scalars["Date"];
}>;

export type GQDashboardInvoiceAmountStatsQuery = { __typename?: "Query" } & {
    dashboardInvoiceAmountStats?: Maybe<
        Array<
            { __typename?: "InvoiceAmount" } & Pick<
                GQInvoiceAmount,
                "datetime" | "sum"
            >
        >
    >;
};

export type GQDashboardInvoiceAmountPerMonthStatsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GQDashboardInvoiceAmountPerMonthStatsQuery = {
    __typename?: "Query";
} & {
    dashboardInvoiceAmountPerMonthStats?: Maybe<
        Array<
            { __typename?: "InvoiceAmountPerMonth" } & Pick<
                GQInvoiceAmountPerMonth,
                "year" | "monthNumber" | "sum"
            >
        >
    >;
};

export type GQAllDashboardFragment = { __typename?: "Dashboard" } & {
    totals: { __typename?: "DashboardTotals" } & Pick<
        GQDashboardTotals,
        "invoices" | "turnoverAmount" | "avgTransaction"
    >;
    products: Array<
        { __typename?: "DashboardProductItem" } & Pick<
            GQDashboardProductItem,
            "id" | "name" | "quantity" | "turnoverAmount"
        >
    >;
    productGroups: Array<
        { __typename?: "DashboardProductGroupItem" } & Pick<
            GQDashboardProductGroupItem,
            "id" | "name" | "quantity" | "turnoverAmount"
        >
    >;
    departments: Array<
        { __typename?: "DashboardDepartmentItem" } & Pick<
            GQDashboardDepartmentItem,
            "id" | "name" | "invoices" | "avgTransaction" | "turnoverAmount"
        >
    >;
    accounts: Array<
        { __typename?: "DashboardAccountItem" } & Pick<
            GQDashboardAccountItem,
            "id" | "name" | "amount"
        >
    >;
};

export type GQSubscribeToDashboardSubscriptionVariables = Exact<{
    from: Scalars["Date"];
    to: Scalars["Date"];
    departmentIds?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type GQSubscribeToDashboardSubscription = {
    __typename?: "Subscription";
} & {
    dashboardUpdated?: Maybe<
        { __typename?: "Dashboard" } & GQAllDashboardFragment
    >;
};

export type GQDashboardQueryVariables = Exact<{
    from: Scalars["Date"];
    to: Scalars["Date"];
    departmentIds?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type GQDashboardQuery = { __typename?: "Query" } & {
    dashboard?: Maybe<{ __typename?: "Dashboard" } & GQAllDashboardFragment>;
};

export type GQUserRoleConfigurationQueryVariables = Exact<{
    userId: Scalars["ID"];
    merchantId?: Maybe<Scalars["ID"]>;
    roleId: Scalars["ID"];
    type: Scalars["String"];
}>;

export type GQUserRoleConfigurationQuery = { __typename?: "Query" } & {
    userRoleConfiguration?: Maybe<
        { __typename?: "UserRoleConfiguration" } & Pick<
            GQUserRoleConfiguration,
            "type" | "data"
        > & {
                user: { __typename?: "User" } & Pick<GQUser, "id">;
                merchant?: Maybe<
                    { __typename?: "Merchant" } & Pick<GQMerchant, "id">
                >;
                role: { __typename?: "Role" } & Pick<GQRole, "id">;
            }
    >;
};

export type GQUserRoleConfigurationSaveMutationVariables = Exact<{
    userId: Scalars["ID"];
    merchantId?: Maybe<Scalars["ID"]>;
    roleId: Scalars["ID"];
    type: Scalars["String"];
    configuration: Scalars["JSON"];
}>;

export type GQUserRoleConfigurationSaveMutation = {
    __typename?: "Mutation";
} & {
    userRoleConfigurationSave: { __typename?: "BooleanResponse" } & Pick<
        GQBooleanResponse,
        "status"
    >;
};

export type GQAllDepartmentFieldsFragment = {
    __typename?: "Department";
} & Pick<
    GQDepartment,
    "id" | "name" | "externalId" | "createdAt" | "deletedAt"
> & { merchant: { __typename?: "Merchant" } & Pick<GQMerchant, "id" | "name"> };

export type GQDepartmentsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQDepartmentsQuery = { __typename?: "Query" } & {
    departments: { __typename?: "DepartmentPagination" } & {
        data: Array<
            { __typename?: "Department" } & GQAllDepartmentFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQDepartmentQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQDepartmentQuery = { __typename?: "Query" } & {
    department?: Maybe<
        { __typename?: "Department" } & GQAllDepartmentFieldsFragment
    >;
};

export type GQDepartmentCreateMutationVariables = Exact<{
    department: GQDepartmentInput;
}>;

export type GQDepartmentCreateMutation = { __typename?: "Mutation" } & {
    departmentCreate: {
        __typename?: "Department";
    } & GQAllDepartmentFieldsFragment;
};

export type GQDepartmentSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    department: GQDepartmentInput;
}>;

export type GQDepartmentSaveMutation = { __typename?: "Mutation" } & {
    departmentSave: {
        __typename?: "Department";
    } & GQAllDepartmentFieldsFragment;
};

export type GQDepartmentDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQDepartmentDeleteMutation = { __typename?: "Mutation" } & {
    departmentDelete: { __typename?: "DepartmentDeleteResponse" } & Pick<
        GQDepartmentDeleteResponse,
        "status"
    >;
};

export type GQAllDiscountFieldsFragment = { __typename?: "Discount" } & Pick<
    GQDiscount,
    | "id"
    | "name"
    | "lineDescription"
    | "type"
    | "valueType"
    | "value"
    | "maxPerOrder"
    | "itemQuery"
    | "conditions"
    | "createdAt"
    | "deletedAt"
    | "active"
> & { merchant: { __typename?: "Merchant" } & Pick<GQMerchant, "id" | "name"> };

export type GQDiscountsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQDiscountsQuery = { __typename?: "Query" } & {
    discounts: { __typename?: "DiscountPagination" } & {
        data: Array<{ __typename?: "Discount" } & GQAllDiscountFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQDiscountQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQDiscountQuery = { __typename?: "Query" } & {
    discount?: Maybe<{ __typename?: "Discount" } & GQAllDiscountFieldsFragment>;
};

export type GQDiscountFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQDiscountFormQuery = { __typename?: "Query" } & {
    products: { __typename?: "ProductPagination" } & {
        data: Array<
            { __typename?: "Product" } & Pick<
                GQProduct,
                "id" | "name" | "active"
            >
        >;
    };
    tags: { __typename?: "TagPagination" } & {
        data: Array<
            { __typename?: "Tag" } & Pick<GQTag, "id" | "identifier" | "name">
        >;
    };
    tagGroups: { __typename?: "TagGroupPagination" } & {
        data: Array<
            { __typename?: "TagGroup" } & Pick<GQTagGroup, "id" | "name">
        >;
    };
    customers: { __typename?: "CustomerPagination" } & {
        data: Array<
            { __typename?: "Customer" } & Pick<GQCustomer, "id" | "name">
        >;
    };
    customerGroups: { __typename?: "CustomerGroupPagination" } & {
        data: Array<
            { __typename?: "CustomerGroup" } & Pick<
                GQCustomerGroup,
                "id" | "name"
            >
        >;
    };
    departments: { __typename?: "DepartmentPagination" } & {
        data: Array<
            { __typename?: "Department" } & Pick<GQDepartment, "id" | "name">
        >;
    };
};

export type GQDiscountCreateMutationVariables = Exact<{
    discount: GQDiscountInput;
}>;

export type GQDiscountCreateMutation = { __typename?: "Mutation" } & {
    discountCreate: { __typename?: "Discount" } & GQAllDiscountFieldsFragment;
};

export type GQDiscountSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    discount: GQDiscountInput;
}>;

export type GQDiscountSaveMutation = { __typename?: "Mutation" } & {
    discountSave: { __typename?: "Discount" } & GQAllDiscountFieldsFragment;
};

export type GQDiscountDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQDiscountDeleteMutation = { __typename?: "Mutation" } & {
    discountDelete: { __typename?: "BooleanResponse" } & Pick<
        GQBooleanResponse,
        "status"
    >;
};

export type GQDiscountsActiveToggleMutationVariables = Exact<{
    ids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type GQDiscountsActiveToggleMutation = { __typename?: "Mutation" } & {
    discountsActiveToggle: { __typename?: "BooleanResponse" } & Pick<
        GQBooleanResponse,
        "status"
    >;
};

export type GQAllIntegrationAccountFieldsFragment = {
    __typename?: "IntegrationAccount";
} & Pick<GQIntegrationAccount, "id" | "name" | "accountNo" | "integrationType">;

export type GQIntegrationAccountsQueryVariables = Exact<{
    integrationType: Scalars["String"];
}>;

export type GQIntegrationAccountsQuery = { __typename?: "Query" } & {
    integrationAccounts?: Maybe<
        Array<
            Maybe<
                {
                    __typename?: "IntegrationAccount";
                } & GQAllIntegrationAccountFieldsFragment
            >
        >
    >;
};

export type GQAllIntegrationFieldsFragment = {
    __typename?: "Integration";
} & Pick<
    GQIntegration,
    "id" | "type" | "active" | "name" | "apiKey" | "createdAt"
> & {
        merchant: { __typename?: "Merchant" } & Pick<GQMerchant, "id" | "name">;
        merchantChartOfAccounts: Array<
            { __typename?: "MerchantChartOfAccount" } & Pick<
                GQMerchantChartOfAccount,
                "chartOfAccountsId" | "merchantAccountNo" | "name"
            >
        >;
    };

export type GQIntegrationsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQIntegrationsQuery = { __typename?: "Query" } & {
    integrations: { __typename?: "IntegrationPagination" } & {
        data: Array<
            { __typename?: "Integration" } & GQAllIntegrationFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQIntegrationQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQIntegrationQuery = { __typename?: "Query" } & {
    integration?: Maybe<
        { __typename?: "Integration" } & GQAllIntegrationFieldsFragment
    >;
};

export type GQIntegrationCreateMutationVariables = Exact<{
    integration: GQIntegrationCreate;
}>;

export type GQIntegrationCreateMutation = { __typename?: "Mutation" } & {
    integrationCreate: {
        __typename?: "Integration";
    } & GQAllIntegrationFieldsFragment;
};

export type GQIntegrationSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    integration: GQIntegrationInput;
}>;

export type GQIntegrationSaveMutation = { __typename?: "Mutation" } & {
    integrationSave: {
        __typename?: "Integration";
    } & GQAllIntegrationFieldsFragment;
};

export type GQIntegrationDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQIntegrationDeleteMutation = { __typename?: "Mutation" } & {
    integrationDelete: { __typename?: "IntegrationDeleteResponse" } & Pick<
        GQIntegrationDeleteResponse,
        "status"
    >;
};

export type GQIntegrationFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQIntegrationFormQuery = { __typename?: "Query" } & {
    integrations: { __typename?: "IntegrationPagination" } & {
        data: Array<
            { __typename?: "Integration" } & Pick<
                GQIntegration,
                "id" | "type" | "active" | "name" | "apiKey"
            >
        >;
    };
};

export type GQAllMerchantChartOfAccountFieldsFragment = {
    __typename?: "MerchantChartOfAccount";
} & Pick<
    GQMerchantChartOfAccount,
    "integrationId" | "name" | "chartOfAccountsId" | "merchantAccountNo"
>;

export type GQAllInvoiceFieldsFragment = { __typename?: "InvoiceRM" } & Pick<
    GQInvoiceRm,
    | "id"
    | "receiptNumber"
    | "receipt"
    | "discountAmount"
    | "amount"
    | "createdAt"
> & {
        shift: { __typename?: "ShiftRM" } & Pick<GQShiftRm, "id"> & {
                cashRegister: { __typename?: "CashRegister" } & Pick<
                    GQCashRegister,
                    "name"
                >;
            };
        items?: Maybe<
            Array<
                { __typename?: "InvoiceItemRM" } & Pick<
                    GQInvoiceItemRm,
                    "quantity"
                > & {
                        external?: Maybe<
                            Array<
                                { __typename?: "InvoiceItemExternalRM" } & Pick<
                                    GQInvoiceItemExternalRm,
                                    "integration" | "receipt"
                                >
                            >
                        >;
                    }
            >
        >;
        payments?: Maybe<
            Array<
                { __typename?: "InvoicePaymentRM" } & Pick<
                    GQInvoicePaymentRm,
                    "cardReceipt"
                >
            >
        >;
    };

export type GQInvoicesQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQInvoicesQuery = { __typename?: "Query" } & {
    invoices: { __typename?: "InvoiceRMPagination" } & {
        data: Array<{ __typename?: "InvoiceRM" } & GQAllInvoiceFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQInvoiceQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQInvoiceQuery = { __typename?: "Query" } & {
    invoice?: Maybe<{ __typename?: "InvoiceRM" } & GQAllInvoiceFieldsFragment>;
};

export type GQAllLayoutFieldsFragment = { __typename?: "Layout" } & Pick<
    GQLayout,
    "id" | "name" | "data" | "columns" | "rows" | "layoutType" | "createdAt"
> & {
        products: Array<
            { __typename?: "Product" } & Pick<
                GQProduct,
                | "id"
                | "name"
                | "amount"
                | "minimumAmount"
                | "costAmount"
                | "excludeFromDiscounts"
                | "buttonText"
            > & {
                    vat: { __typename?: "Vat" } & Pick<
                        GQVat,
                        "id" | "name" | "percentage"
                    >;
                    group: { __typename?: "ProductGroup" } & Pick<
                        GQProductGroup,
                        "id" | "name" | "color"
                    >;
                }
        >;
        productGroups: Array<
            { __typename?: "ProductGroup" } & Pick<
                GQProductGroup,
                "id" | "name" | "color"
            > & {
                    vat: { __typename?: "Vat" } & Pick<
                        GQVat,
                        "id" | "name" | "percentage"
                    >;
                }
        >;
    };

export type GQAllProductLayoutFieldsFragment = { __typename?: "Layout" } & Pick<
    GQLayout,
    "id" | "name" | "data" | "columns" | "rows" | "layoutType" | "createdAt"
> & {
        products: Array<
            { __typename?: "Product" } & Pick<
                GQProduct,
                | "id"
                | "name"
                | "amount"
                | "minimumAmount"
                | "costAmount"
                | "excludeFromDiscounts"
                | "buttonText"
            > & {
                    vat: { __typename?: "Vat" } & Pick<
                        GQVat,
                        "id" | "name" | "percentage"
                    >;
                    group: { __typename?: "ProductGroup" } & Pick<
                        GQProductGroup,
                        "id" | "name" | "color"
                    >;
                }
        >;
        productGroups: Array<
            { __typename?: "ProductGroup" } & Pick<
                GQProductGroup,
                "id" | "name" | "color"
            > & {
                    vat: { __typename?: "Vat" } & Pick<
                        GQVat,
                        "id" | "name" | "percentage"
                    >;
                }
        >;
    };

export type GQAllPosProductLayoutFieldsFragment = {
    __typename?: "Layout";
} & Pick<
    GQLayout,
    "id" | "name" | "data" | "columns" | "rows" | "layoutType"
> & {
        products: Array<
            { __typename?: "Product" } & Pick<
                GQProduct,
                | "id"
                | "name"
                | "amount"
                | "minimumAmount"
                | "costAmount"
                | "excludeFromDiscounts"
                | "buttonText"
            > & {
                    vat: { __typename?: "Vat" } & Pick<
                        GQVat,
                        "id" | "name" | "percentage"
                    >;
                    group: { __typename?: "ProductGroup" } & Pick<
                        GQProductGroup,
                        "id" | "name" | "color"
                    >;
                }
        >;
        productGroups: Array<
            { __typename?: "ProductGroup" } & Pick<
                GQProductGroup,
                "id" | "name" | "color"
            > & {
                    vat: { __typename?: "Vat" } & Pick<
                        GQVat,
                        "id" | "name" | "percentage"
                    >;
                }
        >;
    };

export type GQAllTableLayoutFieldsFragment = { __typename?: "Layout" } & Pick<
    GQLayout,
    "id" | "name" | "data" | "columns" | "rows" | "layoutType" | "createdAt"
>;

export type GQAllPosTableLayoutFieldsFragment = {
    __typename?: "Layout";
} & Pick<GQLayout, "id" | "name" | "data" | "columns" | "rows" | "layoutType">;

export type GQLayoutQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQLayoutQuery = { __typename?: "Query" } & {
    layout?: Maybe<{ __typename?: "Layout" } & GQAllLayoutFieldsFragment>;
};

export type GQProductLayoutsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQProductLayoutsQuery = { __typename?: "Query" } & {
    productLayouts: { __typename?: "LayoutPagination" } & {
        data: Array<
            { __typename?: "Layout" } & GQAllProductLayoutFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQTableLayoutsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQTableLayoutsQuery = { __typename?: "Query" } & {
    tableLayouts: { __typename?: "LayoutPagination" } & {
        data: Array<{ __typename?: "Layout" } & GQAllTableLayoutFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQLayoutCreateMutationVariables = Exact<{
    layout: GQLayoutCreate;
}>;

export type GQLayoutCreateMutation = { __typename?: "Mutation" } & {
    layoutCreate: { __typename?: "Layout" } & GQAllLayoutFieldsFragment;
};

export type GQLayoutSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    layout: GQLayoutSave;
}>;

export type GQLayoutSaveMutation = { __typename?: "Mutation" } & {
    layoutSave: { __typename?: "Layout" } & GQAllLayoutFieldsFragment;
};

export type GQLayoutDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQLayoutDeleteMutation = { __typename?: "Mutation" } & {
    layoutDelete: { __typename?: "LayoutDeleteResponse" } & Pick<
        GQLayoutDeleteResponse,
        "status"
    >;
};

export type GQLayoutCopyMutationVariables = Exact<{
    id: Scalars["ID"];
    newTitle: Scalars["String"];
}>;

export type GQLayoutCopyMutation = { __typename?: "Mutation" } & {
    layoutCopy: { __typename?: "Layout" } & GQAllLayoutFieldsFragment;
};

export type GQTableLayoutFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQTableLayoutFormQuery = { __typename?: "Query" } & {
    tableLayouts: { __typename?: "LayoutPagination" } & {
        data: Array<{ __typename?: "Layout" } & Pick<GQLayout, "id" | "name">>;
    };
};

export type GQProductLayoutFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQProductLayoutFormQuery = { __typename?: "Query" } & {
    productLayouts: { __typename?: "LayoutPagination" } & {
        data: Array<{ __typename?: "Layout" } & Pick<GQLayout, "id" | "name">>;
    };
};

export type GQAllAccessTicketFieldsFragment = {
    __typename?: "VenueAccessParameters";
} & {
    events?: Maybe<
        Array<
            { __typename?: "VenueAccessEvent" } & Pick<
                GQVenueAccessEvent,
                "id" | "name" | "isActive"
            >
        >
    >;
    accessTypes: Array<
        { __typename?: "VenueAccessAccessType" } & Pick<
            GQVenueAccessAccessType,
            "id" | "name"
        >
    >;
};

export type GQVenueAccessTicketParametersQueryVariables = Exact<{
    eventId?: Maybe<Scalars["Int"]>;
}>;

export type GQVenueAccessTicketParametersQuery = { __typename?: "Query" } & {
    venueAccessTicketParameters: {
        __typename?: "VenueAccessParameters";
    } & GQAllAccessTicketFieldsFragment;
};

export type GQAllMerchantFieldsFragment = { __typename?: "Merchant" } & Pick<
    GQMerchant,
    "id" | "name" | "createdAt"
>;

export type GQMerchantsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQMerchantsQuery = { __typename?: "Query" } & {
    merchants: { __typename?: "MerchantPagination" } & {
        data: Array<{ __typename?: "Merchant" } & GQAllMerchantFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQMerchantQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQMerchantQuery = { __typename?: "Query" } & {
    merchant?: Maybe<{ __typename?: "Merchant" } & GQAllMerchantFieldsFragment>;
};

export type GQMerchantCreateMutationVariables = Exact<{
    merchant: GQMerchantCreate;
}>;

export type GQMerchantCreateMutation = { __typename?: "Mutation" } & {
    merchantCreate: { __typename?: "Merchant" } & GQAllMerchantFieldsFragment;
};

export type GQMerchantSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    merchant: GQMerchantInput;
}>;

export type GQMerchantSaveMutation = { __typename?: "Mutation" } & {
    merchantSave: { __typename?: "Merchant" } & GQAllMerchantFieldsFragment;
};

export type GQMerchantDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQMerchantDeleteMutation = { __typename?: "Mutation" } & {
    merchantDelete: { __typename?: "MerchantDeleteResponse" } & Pick<
        GQMerchantDeleteResponse,
        "status"
    >;
};

export type GQMerchantFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQMerchantFormQuery = { __typename?: "Query" } & {
    merchants: { __typename?: "MerchantPagination" } & {
        data: Array<
            { __typename?: "Merchant" } & Pick<GQMerchant, "id" | "name">
        >;
    };
};

export type GQAvailableLoginAsRolesQueryVariables = Exact<{
    merchantId: Scalars["ID"];
}>;

export type GQAvailableLoginAsRolesQuery = { __typename?: "Query" } & {
    roles: { __typename?: "RolePagination" } & {
        data: Array<{ __typename?: "Role" } & Pick<GQRole, "id" | "name">>;
    };
};

export type GQAllMiscButtonsFieldsFragment = {
    __typename?: "MiscButtons";
} & Pick<
    GQMiscButtons,
    "id" | "name" | "buttons" | "createdAt" | "deletedAt"
> & { merchant: { __typename?: "Merchant" } & Pick<GQMerchant, "id" | "name"> };

export type GQScreenMiscButtonFieldsFragment = {
    __typename?: "MiscButtons";
} & Pick<GQMiscButtons, "id" | "name" | "buttons" | "createdAt"> & {
        merchant: { __typename?: "Merchant" } & Pick<GQMerchant, "id" | "name">;
    };

export type GQAllMiscButtonsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQAllMiscButtonsQuery = { __typename?: "Query" } & {
    allMiscButtons: { __typename?: "MiscButtonsPagination" } & {
        data: Array<
            { __typename?: "MiscButtons" } & GQScreenMiscButtonFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQMiscButtonsQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQMiscButtonsQuery = { __typename?: "Query" } & {
    miscButtons?: Maybe<
        { __typename?: "MiscButtons" } & GQScreenMiscButtonFieldsFragment
    >;
};

export type GQMiscButtonsCreateMutationVariables = Exact<{
    miscButtons: GQMiscButtonsInput;
}>;

export type GQMiscButtonsCreateMutation = { __typename?: "Mutation" } & {
    miscButtonsCreate: {
        __typename?: "MiscButtons";
    } & GQScreenMiscButtonFieldsFragment;
};

export type GQMiscButtonsSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    miscButtons: GQMiscButtonsInput;
}>;

export type GQMiscButtonsSaveMutation = { __typename?: "Mutation" } & {
    miscButtonsSave: {
        __typename?: "MiscButtons";
    } & GQScreenMiscButtonFieldsFragment;
};

export type GQMiscButtonsDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQMiscButtonsDeleteMutation = { __typename?: "Mutation" } & {
    miscButtonsDelete: { __typename?: "MiscButtonsDeleteResponse" } & Pick<
        GQMiscButtonsDeleteResponse,
        "status"
    >;
};

export type GQAllPaymentConfigurationFieldsFragment = {
    __typename?: "PaymentConfiguration";
} & Pick<GQPaymentConfiguration, "id" | "name" | "createdAt"> & {
        paymentMethods: Array<
            { __typename?: "PaymentConfigurationMethod" } & Pick<
                GQPaymentConfigurationMethod,
                "showAsQuickButton" | "showInPaymentWindow"
            > & {
                    paymentMethod: { __typename?: "PaymentMethod" } & Pick<
                        GQPaymentMethod,
                        "id" | "name" | "paymentType" | "enabled"
                    > & {
                            currency: { __typename?: "Currency" } & Pick<
                                GQCurrency,
                                "code" | "rate"
                            >;
                        };
                }
        >;
    };

export type GQPaymentConfigurationsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQPaymentConfigurationsQuery = { __typename?: "Query" } & {
    paymentConfigurations: { __typename?: "PaymentConfigurationPagination" } & {
        data: Array<
            {
                __typename?: "PaymentConfiguration";
            } & GQAllPaymentConfigurationFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQPaymentConfigurationQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQPaymentConfigurationQuery = { __typename?: "Query" } & {
    paymentConfiguration: {
        __typename?: "PaymentConfiguration";
    } & GQAllPaymentConfigurationFieldsFragment;
};

export type GQPaymentConfigurationCreateMutationVariables = Exact<{
    paymentConfiguration: GQPaymentConfigurationInput;
}>;

export type GQPaymentConfigurationCreateMutation = {
    __typename?: "Mutation";
} & {
    paymentConfigurationCreate: {
        __typename?: "PaymentConfiguration";
    } & GQAllPaymentConfigurationFieldsFragment;
};

export type GQPaymentConfigurationSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    paymentConfiguration: GQPaymentConfigurationInput;
}>;

export type GQPaymentConfigurationSaveMutation = { __typename?: "Mutation" } & {
    paymentConfigurationSave: {
        __typename?: "PaymentConfiguration";
    } & GQAllPaymentConfigurationFieldsFragment;
};

export type GQPaymentConfigurationDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQPaymentConfigurationDeleteMutation = {
    __typename?: "Mutation";
} & {
    paymentConfigurationDelete: {
        __typename?: "PaymentConfigurationDeleteResponse";
    } & Pick<GQPaymentConfigurationDeleteResponse, "status">;
};

export type GQPaymentConfigurationFormQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GQPaymentConfigurationFormQuery = { __typename?: "Query" } & {
    paymentMethods: { __typename?: "PaymentMethodPagination" } & {
        data: Array<
            { __typename?: "PaymentMethod" } & Pick<
                GQPaymentMethod,
                "id" | "name" | "enabled"
            >
        >;
    };
};

export type GQAllPaymentMethodFieldsFragment = {
    __typename?: "PaymentMethod";
} & Pick<
    GQPaymentMethod,
    | "id"
    | "name"
    | "paymentType"
    | "enabled"
    | "account"
    | "diffAccount"
    | "createdAt"
> & { currency: { __typename?: "Currency" } & Pick<GQCurrency, "id" | "code"> };

export type GQPaymentMethodsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQPaymentMethodsQuery = { __typename?: "Query" } & {
    paymentMethods: { __typename?: "PaymentMethodPagination" } & {
        data: Array<
            { __typename?: "PaymentMethod" } & GQAllPaymentMethodFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQPaymentMethodQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQPaymentMethodQuery = { __typename?: "Query" } & {
    paymentMethod: {
        __typename?: "PaymentMethod";
    } & GQAllPaymentMethodFieldsFragment;
};

export type GQPaymentMethodCreateMutationVariables = Exact<{
    paymentMethod: GQPaymentMethodInput;
}>;

export type GQPaymentMethodCreateMutation = { __typename?: "Mutation" } & {
    paymentMethodCreate: {
        __typename?: "PaymentMethod";
    } & GQAllPaymentMethodFieldsFragment;
};

export type GQPaymentMethodSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    paymentMethod: GQPaymentMethodInput;
}>;

export type GQPaymentMethodSaveMutation = { __typename?: "Mutation" } & {
    paymentMethodSave: {
        __typename?: "PaymentMethod";
    } & GQAllPaymentMethodFieldsFragment;
};

export type GQPaymentMethodDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQPaymentMethodDeleteMutation = { __typename?: "Mutation" } & {
    paymentMethodDelete: { __typename?: "PaymentMethodDeleteResponse" } & Pick<
        GQPaymentMethodDeleteResponse,
        "status"
    >;
};

export type GQPaymentMethodFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQPaymentMethodFormQuery = { __typename?: "Query" } & {
    currencies: { __typename?: "CurrencyPagination" } & {
        data: Array<
            { __typename?: "Currency" } & Pick<GQCurrency, "id" | "code">
        >;
    };
};

export type GQPrinterScreenFieldsFragment = { __typename?: "Printer" } & Pick<
    GQPrinter,
    "id" | "name" | "serial" | "type" | "manufacturer" | "class" | "createdAt"
> & {
        cashRegister?: Maybe<
            { __typename?: "CashRegister" } & Pick<
                GQCashRegister,
                "id" | "name"
            >
        >;
    };

export type GQPrinterQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQPrinterQuery = { __typename?: "Query" } & {
    printer: { __typename?: "Printer" } & GQPrinterScreenFieldsFragment;
};

export type GQPrintersQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQPrintersQuery = { __typename?: "Query" } & {
    printers: { __typename?: "PrinterPagination" } & {
        data: Array<{ __typename?: "Printer" } & GQPrinterScreenFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQPrintersForCashRegisterQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    class?: Maybe<Scalars["String"]>;
    entityId?: Maybe<Scalars["String"]>;
}>;

export type GQPrintersForCashRegisterQuery = { __typename?: "Query" } & {
    printersForCashRegister: { __typename?: "PrinterPagination" } & {
        data: Array<{ __typename?: "Printer" } & GQPrinterScreenFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQPrinterRegisterMutationVariables = Exact<{
    printer: GQPrinterRegister;
}>;

export type GQPrinterRegisterMutation = { __typename?: "Mutation" } & {
    printerRegister: { __typename?: "Printer" } & Pick<
        GQPrinter,
        "name" | "serial" | "type" | "manufacturer" | "class"
    >;
};

export type GQPrinterSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    printer: GQPrinterSave;
}>;

export type GQPrinterSaveMutation = { __typename?: "Mutation" } & {
    printerSave: { __typename?: "Printer" } & Pick<GQPrinter, "id" | "name">;
};

export type GQPrinterDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQPrinterDeleteMutation = { __typename?: "Mutation" } & {
    printerDelete: { __typename?: "PrinterDeleteResponse" } & Pick<
        GQPrinterDeleteResponse,
        "status"
    >;
};

export type GQPrinterFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQPrinterFormQuery = { __typename?: "Query" } & {
    printers: { __typename?: "PrinterPagination" } & {
        data: Array<
            { __typename?: "Printer" } & Pick<GQPrinter, "id" | "name">
        >;
    };
};

export type GQAllProductGroupFieldsFragment = {
    __typename?: "ProductGroup";
} & Pick<
    GQProductGroup,
    | "id"
    | "externalId"
    | "name"
    | "color"
    | "excludeFromDiscounts"
    | "isExternal"
    | "createdAt"
    | "deletedAt"
> & {
        vat: { __typename?: "Vat" } & Pick<GQVat, "id" | "name" | "percentage">;
        printer?: Maybe<
            { __typename?: "Printer" } & Pick<GQPrinter, "id" | "name">
        >;
    };

export type GQProductGroupsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQProductGroupsQuery = { __typename?: "Query" } & {
    productGroups: { __typename?: "ProductGroupPagination" } & {
        data: Array<
            { __typename?: "ProductGroup" } & GQAllProductGroupFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQProductGroupQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQProductGroupQuery = { __typename?: "Query" } & {
    productGroup?: Maybe<
        { __typename?: "ProductGroup" } & GQAllProductGroupFieldsFragment
    >;
};

export type GQProductGroupCreateMutationVariables = Exact<{
    productGroup: GQProductGroupInput;
}>;

export type GQProductGroupCreateMutation = { __typename?: "Mutation" } & {
    productGroupCreate: {
        __typename?: "ProductGroup";
    } & GQAllProductGroupFieldsFragment;
};

export type GQProductGroupSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    productGroup: GQProductGroupInput;
}>;

export type GQProductGroupSaveMutation = { __typename?: "Mutation" } & {
    productGroupSave: {
        __typename?: "ProductGroup";
    } & GQAllProductGroupFieldsFragment;
};

export type GQProductGroupDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQProductGroupDeleteMutation = { __typename?: "Mutation" } & {
    productGroupDelete: { __typename?: "ProductGroupDeleteResponse" } & Pick<
        GQProductGroupDeleteResponse,
        "status"
    >;
};

export type GQSetInventoryProductQuantityMutationVariables = Exact<{
    id?: Maybe<Scalars["ID"]>;
    productId: Scalars["ID"];
    quantity: Scalars["Int"];
    note?: Maybe<Scalars["String"]>;
}>;

export type GQSetInventoryProductQuantityMutation = {
    __typename?: "Mutation";
} & {
    setInventoryProductQuantity: { __typename?: "InventoryProduct" } & Pick<
        GQInventoryProduct,
        "id" | "quantity"
    >;
};

export type GQAllInventoryProductNoteTemplateFieldsFragment = {
    __typename?: "InventoryProductNoteTemplate";
} & Pick<GQInventoryProductNoteTemplate, "id" | "note">;

export type GQInventoryProductNoteTemplatesQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
}>;

export type GQInventoryProductNoteTemplatesQuery = { __typename?: "Query" } & {
    inventoryProductNoteTemplates: {
        __typename?: "InventoryProductNoteTemplatePagination";
    } & {
        data: Array<
            {
                __typename?: "InventoryProductNoteTemplate";
            } & GQAllInventoryProductNoteTemplateFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQInventoryProductNoteTemplateQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQInventoryProductNoteTemplateQuery = { __typename?: "Query" } & {
    inventoryProductNoteTemplate?: Maybe<
        {
            __typename?: "InventoryProductNoteTemplate";
        } & GQAllInventoryProductNoteTemplateFieldsFragment
    >;
};

export type GQInventoryProductNoteTemplateCreateMutationVariables = Exact<{
    inventoryProductNoteTemplate: GQInventoryProductNoteTemplateCreate;
}>;

export type GQInventoryProductNoteTemplateCreateMutation = {
    __typename?: "Mutation";
} & {
    inventoryProductNoteTemplateCreate: {
        __typename?: "InventoryProductNoteTemplate";
    } & GQAllInventoryProductNoteTemplateFieldsFragment;
};

export type GQInventoryProductNoteTemplateSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    inventoryProductNoteTemplate: GQInventoryProductNoteTemplateInput;
}>;

export type GQInventoryProductNoteTemplateSaveMutation = {
    __typename?: "Mutation";
} & {
    inventoryProductNoteTemplateSave: {
        __typename?: "InventoryProductNoteTemplate";
    } & GQAllInventoryProductNoteTemplateFieldsFragment;
};

export type GQInventoryProductNoteTemplateDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQInventoryProductNoteTemplateDeleteMutation = {
    __typename?: "Mutation";
} & {
    inventoryProductNoteTemplateDelete: {
        __typename?: "BooleanResponse";
    } & Pick<GQBooleanResponse, "status">;
};

export type GQAllProductFieldsFragment = { __typename?: "Product" } & Pick<
    GQProduct,
    | "id"
    | "externalId"
    | "name"
    | "amount"
    | "minimumAmount"
    | "costAmount"
    | "onlineRequired"
    | "active"
    | "isExternal"
    | "excludeFromDiscounts"
    | "ownExcludeFromDiscounts"
    | "barcode"
    | "buttonText"
    | "inheritPrinter"
    | "createdAt"
    | "deletedAt"
> & {
        parent?: Maybe<
            { __typename?: "Product" } & Pick<GQProduct, "id" | "name">
        >;
        group: { __typename?: "ProductGroup" } & Pick<
            GQProductGroup,
            "id" | "name" | "color"
        > & {
                vat: { __typename?: "Vat" } & Pick<
                    GQVat,
                    "id" | "name" | "percentage"
                >;
            };
        vat: { __typename?: "Vat" } & Pick<GQVat, "id" | "name" | "percentage">;
        ownVat?: Maybe<
            { __typename?: "Vat" } & Pick<GQVat, "id" | "name" | "percentage">
        >;
        barcodes: Array<
            { __typename?: "ProductBarcode" } & Pick<
                GQProductBarcode,
                "id" | "barcode"
            >
        >;
        inventoryProduct?: Maybe<
            { __typename?: "InventoryProduct" } & Pick<
                GQInventoryProduct,
                "id" | "quantity"
            >
        >;
        printer?: Maybe<
            { __typename?: "Printer" } & Pick<GQPrinter, "id" | "name">
        >;
    };

export type GQProductChildrenListFieldsFragment = {
    __typename?: "ProductPagination";
} & {
    data: Array<
        { __typename?: "Product" } & Pick<GQProduct, "id" | "name" | "amount">
    >;
};

export type GQProductsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
    includeChildren?: Maybe<Scalars["Boolean"]>;
    includeInactive?: Maybe<Scalars["Boolean"]>;
}>;

export type GQProductsQuery = { __typename?: "Query" } & {
    products: { __typename?: "ProductPagination" } & {
        data: Array<{ __typename?: "Product" } & GQAllProductFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQProductQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQProductQuery = { __typename?: "Query" } & {
    product?: Maybe<
        { __typename?: "Product" } & {
            children: {
                __typename?: "ProductPagination";
            } & GQProductChildrenListFieldsFragment;
        } & GQAllProductFieldsFragment
    >;
};

export type GQProductCreateMutationVariables = Exact<{
    product: GQProductInput;
}>;

export type GQProductCreateMutation = { __typename?: "Mutation" } & {
    productCreate: { __typename?: "Product" } & GQAllProductFieldsFragment;
};

export type GQProductSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    product: GQProductInput;
}>;

export type GQProductSaveMutation = { __typename?: "Mutation" } & {
    productSave: { __typename?: "Product" } & GQAllProductFieldsFragment;
};

export type GQProductDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQProductDeleteMutation = { __typename?: "Mutation" } & {
    productDelete: { __typename?: "ProductDeleteResponse" } & Pick<
        GQProductDeleteResponse,
        "status"
    >;
};

export type GQProductsDeleteMutationVariables = Exact<{
    ids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type GQProductsDeleteMutation = { __typename?: "Mutation" } & {
    productsDelete: { __typename?: "ProductDeleteResponse" } & Pick<
        GQProductDeleteResponse,
        "status"
    >;
};

export type GQProductFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQProductFormQuery = { __typename?: "Query" } & {
    products: { __typename?: "ProductPagination" } & {
        data: Array<
            { __typename?: "Product" } & Pick<GQProduct, "id" | "name">
        >;
    };
    productGroups: { __typename?: "ProductGroupPagination" } & {
        data: Array<
            { __typename?: "ProductGroup" } & Pick<
                GQProductGroup,
                "id" | "name"
            > & { vat: { __typename?: "Vat" } & Pick<GQVat, "id"> }
        >;
    };
};

export type GQReportFieldsFragment = { __typename?: "ReportParameters" } & {
    users: Array<
        { __typename?: "ReportUser" } & Pick<
            GQReportUser,
            "id" | "username" | "firstName" | "lastName"
        >
    >;
    departments: Array<
        { __typename?: "ReportDepartment" } & Pick<
            GQReportDepartment,
            "id" | "name"
        >
    >;
    cashRegisters: Array<
        { __typename?: "ReportCashRegister" } & Pick<
            GQReportCashRegister,
            "id" | "name"
        >
    >;
    productGroups: Array<
        { __typename?: "ReportProductGroup" } & Pick<
            GQReportProductGroup,
            "id" | "name"
        >
    >;
    products: Array<
        { __typename?: "ReportProduct" } & Pick<GQReportProduct, "id" | "name">
    >;
};

export type GQReportFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQReportFormQuery = { __typename?: "Query" } & {
    reportParameters: {
        __typename?: "ReportParameters";
    } & GQReportFieldsFragment;
};

export type GQAllShiftFieldsFragment = { __typename?: "ShiftRM" } & Pick<
    GQShiftRm,
    "id" | "openedAt" | "closedAt" | "discount" | "amount" | "zReport"
> & {
        cashRegister: { __typename?: "CashRegister" } & Pick<
            GQCashRegister,
            "name"
        > & {
                department?: Maybe<
                    { __typename?: "Department" } & Pick<GQDepartment, "name">
                >;
            };
        openedByUser: { __typename?: "User" } & Pick<GQUser, "username">;
        closedByUser?: Maybe<
            { __typename?: "User" } & Pick<GQUser, "username">
        >;
    };

export type GQShiftsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQShiftsQuery = { __typename?: "Query" } & {
    shifts: { __typename?: "ShiftRMPagination" } & {
        data: Array<{ __typename?: "ShiftRM" } & GQAllShiftFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQShiftQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQShiftQuery = { __typename?: "Query" } & {
    shift?: Maybe<{ __typename?: "ShiftRM" } & GQAllShiftFieldsFragment>;
};

export type GQTagGroupScreenFieldsFragment = { __typename?: "TagGroup" } & Pick<
    GQTagGroup,
    "id" | "name"
>;

export type GQTagGroupsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQTagGroupsQuery = { __typename?: "Query" } & {
    tagGroups: { __typename?: "TagGroupPagination" } & {
        data: Array<
            { __typename?: "TagGroup" } & GQTagGroupScreenFieldsFragment
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQTagGroupQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQTagGroupQuery = { __typename?: "Query" } & {
    tagGroup: { __typename?: "TagGroup" } & GQTagGroupScreenFieldsFragment;
};

export type GQTagGroupCreateMutationVariables = Exact<{
    tagGroup: GQTagGroupInput;
}>;

export type GQTagGroupCreateMutation = { __typename?: "Mutation" } & {
    tagGroupCreate: {
        __typename?: "TagGroup";
    } & GQTagGroupScreenFieldsFragment;
};

export type GQTagGroupSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    tagGroup: GQTagGroupInput;
}>;

export type GQTagGroupSaveMutation = { __typename?: "Mutation" } & {
    tagGroupSave: { __typename?: "TagGroup" } & GQTagGroupScreenFieldsFragment;
};

export type GQTagGroupDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQTagGroupDeleteMutation = { __typename?: "Mutation" } & {
    tagGroupDelete: { __typename?: "TagGroupDeleteResponse" } & Pick<
        GQTagGroupDeleteResponse,
        "status"
    >;
};

export type GQTagScreenFieldsFragment = { __typename?: "Tag" } & Pick<
    GQTag,
    "id" | "identifier" | "name" | "tagType" | "createdAt"
> & {
        group?: Maybe<
            { __typename?: "TagGroup" } & Pick<GQTagGroup, "id" | "name">
        >;
        account?: Maybe<
            { __typename?: "Account" } & Pick<GQAccount, "id" | "name">
        >;
        customer?: Maybe<
            { __typename?: "Customer" } & Pick<GQCustomer, "id" | "name">
        >;
    };

export type GQTagNameFieldsFragment = { __typename?: "Tag" } & Pick<
    GQTag,
    "id" | "identifier" | "name"
>;

export type GQTagsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
    tagIds?: Maybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type GQTagsQuery = { __typename?: "Query" } & {
    tags: { __typename?: "TagPagination" } & {
        data: Array<{ __typename?: "Tag" } & GQTagScreenFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQTagsWithoutAccountQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQTagsWithoutAccountQuery = { __typename?: "Query" } & {
    tagsWithoutAccount: { __typename?: "TagPagination" } & {
        data: Array<{ __typename?: "Tag" } & GQTagScreenFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQTagsWithoutCustomerQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQTagsWithoutCustomerQuery = { __typename?: "Query" } & {
    tagsWithoutCustomer: { __typename?: "TagPagination" } & {
        data: Array<{ __typename?: "Tag" } & GQTagScreenFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQTagsByGroupQueryVariables = Exact<{
    groupId: Scalars["ID"];
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQTagsByGroupQuery = { __typename?: "Query" } & {
    tagsByGroup: { __typename?: "TagPagination" } & {
        data: Array<{ __typename?: "Tag" } & GQTagScreenFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQTagsByCustomerQueryVariables = Exact<{
    customerId: Scalars["ID"];
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQTagsByCustomerQuery = { __typename?: "Query" } & {
    tagsByCustomer: { __typename?: "TagPagination" } & {
        data: Array<{ __typename?: "Tag" } & GQTagScreenFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQTagsByAccountQueryVariables = Exact<{
    accountId: Scalars["ID"];
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQTagsByAccountQuery = { __typename?: "Query" } & {
    tagsByAccount: { __typename?: "TagPagination" } & {
        data: Array<{ __typename?: "Tag" } & GQTagScreenFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQTagQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQTagQuery = { __typename?: "Query" } & {
    tag: { __typename?: "Tag" } & GQTagScreenFieldsFragment;
};

export type GQTagCreateMutationVariables = Exact<{
    tag: GQTagInput;
}>;

export type GQTagCreateMutation = { __typename?: "Mutation" } & {
    tagCreate: { __typename?: "Tag" } & GQTagScreenFieldsFragment;
};

export type GQTagSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    tag: GQTagInput;
}>;

export type GQTagSaveMutation = { __typename?: "Mutation" } & {
    tagSave: { __typename?: "Tag" } & GQTagScreenFieldsFragment;
};

export type GQTagDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQTagDeleteMutation = { __typename?: "Mutation" } & {
    tagDelete: { __typename?: "TagDeleteResponse" } & Pick<
        GQTagDeleteResponse,
        "status"
    >;
};

export type GQTagFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQTagFormQuery = { __typename?: "Query" } & {
    tags: { __typename?: "TagPagination" } & {
        data: Array<{ __typename?: "Tag" } & GQTagNameFieldsFragment>;
    };
    tagGroups: { __typename?: "TagGroupPagination" } & {
        data: Array<
            { __typename?: "TagGroup" } & GQTagGroupScreenFieldsFragment
        >;
    };
};

export type GQAllRoleFieldsFragment = { __typename?: "Role" } & Pick<
    GQRole,
    "id" | "name" | "shared"
> & {
        merchant?: Maybe<
            { __typename?: "Merchant" } & Pick<GQMerchant, "id" | "name">
        >;
    };

export type GQRolesQueryVariables = Exact<{
    pagination: GQPaginationInput;
    search: GQSearchInput;
}>;

export type GQRolesQuery = { __typename?: "Query" } & {
    roles: { __typename?: "RolePagination" } & {
        data: Array<{ __typename?: "Role" } & GQAllRoleFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQRoleEditFormQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQRoleEditFormQuery = { __typename?: "Query" } & {
    role?: Maybe<
        { __typename?: "Role" } & Pick<
            GQRole,
            "id" | "name" | "type" | "shared" | "inUse"
        > & {
                merchant?: Maybe<
                    { __typename?: "Merchant" } & Pick<
                        GQMerchant,
                        "id" | "name"
                    >
                >;
                permissions: Array<
                    { __typename?: "RolePermission" } & Pick<
                        GQRolePermission,
                        "key" | "value"
                    >
                >;
            }
    >;
};

export type GQRoleCreateMutationVariables = Exact<{
    role: GQRoleInput;
}>;

export type GQRoleCreateMutation = { __typename?: "Mutation" } & {
    roleCreate: { __typename?: "Role" } & GQAllRoleFieldsFragment;
};

export type GQRoleSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    role: GQRoleInput;
}>;

export type GQRoleSaveMutation = { __typename?: "Mutation" } & {
    roleSave: { __typename?: "Role" } & GQAllRoleFieldsFragment;
};

export type GQRoleDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQRoleDeleteMutation = { __typename?: "Mutation" } & {
    roleDelete: { __typename?: "RoleDeleteResponse" } & Pick<
        GQRoleDeleteResponse,
        "status"
    >;
};

export type GQUserFragment = { __typename?: "User" } & Pick<
    GQUser,
    | "id"
    | "username"
    | "email"
    | "firstName"
    | "lastName"
    | "active"
    | "pin"
    | "requireLoginPin"
    | "requireLogoutPin"
    | "requirePasswordChange"
    | "createdAt"
> & {
        roles: Array<
            { __typename?: "UserRole" } & {
                role: { __typename?: "Role" } & Pick<
                    GQRole,
                    "id" | "name" | "type"
                >;
                merchant?: Maybe<
                    { __typename?: "Merchant" } & Pick<
                        GQMerchant,
                        "id" | "name"
                    >
                >;
            }
        >;
    };

export type GQUsersQueryVariables = Exact<{
    pagination: GQPaginationInput;
    search: GQSearchInput;
}>;

export type GQUsersQuery = { __typename?: "Query" } & {
    users: { __typename?: "UserPagination" } & {
        data: Array<{ __typename?: "User" } & GQUserFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQUserQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQUserQuery = { __typename?: "Query" } & {
    user?: Maybe<{ __typename?: "User" } & GQUserFragment>;
};

export type GQUserFormRolesFieldsFragment = { __typename?: "Role" } & Pick<
    GQRole,
    "id" | "name" | "type"
> & {
        merchant?: Maybe<
            { __typename?: "Merchant" } & Pick<GQMerchant, "id" | "name">
        >;
    };

export type GQUserCreateFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQUserCreateFormQuery = { __typename?: "Query" } & {
    roles: { __typename?: "RolePagination" } & {
        data: Array<{ __typename?: "Role" } & GQUserFormRolesFieldsFragment>;
    };
};

export type GQUserEditFormQueryVariables = Exact<{
    userId: Scalars["ID"];
}>;

export type GQUserEditFormQuery = { __typename?: "Query" } & {
    user?: Maybe<{ __typename?: "User" } & GQUserFragment>;
    roles: { __typename?: "RolePagination" } & {
        data: Array<{ __typename?: "Role" } & GQUserFormRolesFieldsFragment>;
    };
};

export type GQUsernameAvailabilityQueryVariables = Exact<{
    username: Scalars["String"];
}>;

export type GQUsernameAvailabilityQuery = { __typename?: "Query" } & Pick<
    GQQuery,
    "userAvailability"
>;

export type GQUserCreateMutationVariables = Exact<{
    user: GQUserInput;
}>;

export type GQUserCreateMutation = { __typename?: "Mutation" } & {
    userCreate: { __typename?: "User" } & GQUserFragment;
};

export type GQUserSaveMutationVariables = Exact<{
    id: Scalars["ID"];
    user: GQUserInput;
}>;

export type GQUserSaveMutation = { __typename?: "Mutation" } & {
    userSave: { __typename?: "User" } & GQUserFragment;
};

export type GQUserDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQUserDeleteMutation = { __typename?: "Mutation" } & {
    userDelete: { __typename?: "UserDeleteResponse" } & Pick<
        GQUserDeleteResponse,
        "status"
    >;
};

export type GQUserUpdatePasswordMutationVariables = Exact<{
    id: Scalars["ID"];
    password: Scalars["String"];
}>;

export type GQUserUpdatePasswordMutation = { __typename?: "Mutation" } & {
    userUpdatePassword: { __typename?: "BooleanResponse" } & Pick<
        GQBooleanResponse,
        "status"
    >;
};

export type GQVatScreenFieldsFragment = { __typename?: "Vat" } & Pick<
    GQVat,
    "id" | "name" | "percentage" | "isExternal" | "externalId" | "createdAt"
> & { merchant: { __typename?: "Merchant" } & Pick<GQMerchant, "id" | "name"> };

export type GQVatQueryVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQVatQuery = { __typename?: "Query" } & {
    vat?: Maybe<{ __typename?: "Vat" } & GQVatScreenFieldsFragment>;
};

export type GQVatsQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQVatsQuery = { __typename?: "Query" } & {
    vats: { __typename?: "VatPagination" } & {
        data: Array<{ __typename?: "Vat" } & GQVatScreenFieldsFragment>;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQVatCreateMutationVariables = Exact<{
    vat: GQVatCreateInput;
}>;

export type GQVatCreateMutation = { __typename?: "Mutation" } & {
    vatCreate: { __typename?: "Vat" } & GQVatScreenFieldsFragment;
};

export type GQVatDeleteMutationVariables = Exact<{
    id: Scalars["ID"];
}>;

export type GQVatDeleteMutation = { __typename?: "Mutation" } & {
    vatDelete: { __typename?: "VatDeleteResponse" } & Pick<
        GQVatDeleteResponse,
        "status"
    >;
};

export type GQVatFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQVatFormQuery = { __typename?: "Query" } & {
    vats: { __typename?: "VatPagination" } & {
        data: Array<{ __typename?: "Vat" } & Pick<GQVat, "id" | "percentage">>;
    };
};

export type GQAccountBuyFundsMutationVariables = Exact<{
    accountId: Scalars["ID"];
    amount: Scalars["Float"];
    orderId: Scalars["ID"];
    userId: Scalars["ID"];
    tagId: Scalars["ID"];
    tagIdentifier: Scalars["String"];
}>;

export type GQAccountBuyFundsMutation = { __typename?: "Mutation" } & {
    accountBuyFunds: { __typename?: "AccountBuyFundsResponse" } & Pick<
        GQAccountBuyFundsResponse,
        "status" | "transactionId"
    >;
};

export type GQAccountReturnFundsMutationVariables = Exact<{
    accountId: Scalars["ID"];
    amount: Scalars["Float"];
    orderId: Scalars["ID"];
    userId: Scalars["ID"];
    tagId: Scalars["ID"];
    tagIdentifier: Scalars["String"];
}>;

export type GQAccountReturnFundsMutation = { __typename?: "Mutation" } & {
    accountReturnFunds: { __typename?: "AccountReturnFundsResponse" } & Pick<
        GQAccountReturnFundsResponse,
        "status" | "transactionId"
    >;
};

export type GQAccountRevertTransactionMutationVariables = Exact<{
    accountId: Scalars["ID"];
    transactionId: Scalars["ID"];
    userId: Scalars["ID"];
}>;

export type GQAccountRevertTransactionMutation = { __typename?: "Mutation" } & {
    accountRevertTransaction: {
        __typename?: "AccountRevertTransactionResponse";
    } & Pick<GQAccountRevertTransactionResponse, "status">;
};

export type GQAccountBalanceQueryVariables = Exact<{
    id: Scalars["ID"];
    numberOfTransactions?: Maybe<Scalars["Int"]>;
}>;

export type GQAccountBalanceQuery = { __typename?: "Query" } & {
    account: { __typename?: "Account" } & {
        balance: { __typename?: "Balance" } & Pick<GQBalance, "receipt">;
    };
};

export type GQAppDataQueryVariables = Exact<{ [key: string]: never }>;

export type GQAppDataQuery = { __typename?: "Query" } & {
    me: { __typename?: "Me" } & {
        cashRegister?: Maybe<
            { __typename?: "CashRegister" } & {
                appData: { __typename?: "AppData" } & Pick<
                    GQAppData,
                    "structVersion" | "minimumStructVersion" | "deviceConfig"
                > & {
                        cashRegister: { __typename?: "CashRegister" } & Pick<
                            GQCashRegister,
                            "id" | "serialId" | "name"
                        > & {
                                department?: Maybe<
                                    { __typename?: "Department" } & Pick<
                                        GQDepartment,
                                        "id" | "name"
                                    >
                                >;
                            };
                        merchant: { __typename?: "Merchant" } & Pick<
                            GQMerchant,
                            | "id"
                            | "serialId"
                            | "name"
                            | "giftcardVatBookkeepingMethod"
                        >;
                        productGroups: Array<
                            { __typename?: "ProductGroup" } & Pick<
                                GQProductGroup,
                                | "id"
                                | "name"
                                | "color"
                                | "excludeFromDiscounts"
                                | "createdAt"
                            > & {
                                    vat: { __typename?: "Vat" } & Pick<
                                        GQVat,
                                        "id" | "name" | "percentage"
                                    >;
                                    printer?: Maybe<
                                        { __typename?: "Printer" } & Pick<
                                            GQPrinter,
                                            "id" | "name" | "serial"
                                        >
                                    >;
                                }
                        >;
                        vats: Array<
                            { __typename?: "Vat" } & Pick<
                                GQVat,
                                "id" | "name" | "percentage"
                            >
                        >;
                        products: Array<
                            { __typename?: "Product" } & Pick<
                                GQProduct,
                                | "id"
                                | "name"
                                | "externalId"
                                | "amount"
                                | "minimumAmount"
                                | "costAmount"
                                | "excludeFromDiscounts"
                                | "inheritPrinter"
                            > & {
                                    vat: { __typename?: "Vat" } & Pick<
                                        GQVat,
                                        "id" | "name" | "percentage"
                                    >;
                                    barcodes: Array<
                                        {
                                            __typename?: "ProductBarcode";
                                        } & Pick<GQProductBarcode, "barcode">
                                    >;
                                    group: {
                                        __typename?: "ProductGroup";
                                    } & Pick<GQProductGroup, "id" | "name"> & {
                                            printer?: Maybe<
                                                {
                                                    __typename?: "Printer";
                                                } & Pick<
                                                    GQPrinter,
                                                    "id" | "name" | "serial"
                                                >
                                            >;
                                        };
                                    inventoryProduct?: Maybe<
                                        {
                                            __typename?: "InventoryProduct";
                                        } & Pick<GQInventoryProduct, "id">
                                    >;
                                    printer?: Maybe<
                                        { __typename?: "Printer" } & Pick<
                                            GQPrinter,
                                            "id" | "name" | "serial"
                                        >
                                    >;
                                }
                        >;
                        discounts: Array<
                            { __typename?: "Discount" } & Pick<
                                GQDiscount,
                                | "lineDescription"
                                | "type"
                                | "valueType"
                                | "value"
                                | "maxPerOrder"
                                | "itemQuery"
                                | "conditions"
                            >
                        >;
                        layout?: Maybe<
                            {
                                __typename?: "Layout";
                            } & GQAllPosProductLayoutFieldsFragment
                        >;
                        tableLayout?: Maybe<
                            {
                                __typename?: "Layout";
                            } & GQAllPosTableLayoutFieldsFragment
                        >;
                        paymentConfiguration?: Maybe<
                            {
                                __typename?: "PaymentConfiguration";
                            } & GQAllPaymentConfigurationFieldsFragment
                        >;
                        miscButtons?: Maybe<
                            {
                                __typename?: "MiscButtons";
                            } & GQAllMiscButtonsFieldsFragment
                        >;
                        tags: Array<
                            { __typename?: "Tag" } & Pick<
                                GQTag,
                                "id" | "identifier" | "tagType" | "name"
                            > & {
                                    group?: Maybe<
                                        { __typename?: "TagGroup" } & Pick<
                                            GQTagGroup,
                                            "id" | "name"
                                        >
                                    >;
                                    account?: Maybe<
                                        { __typename?: "Account" } & Pick<
                                            GQAccount,
                                            | "id"
                                            | "name"
                                            | "amount"
                                            | "status"
                                            | "type"
                                        > & {
                                                vat?: Maybe<
                                                    {
                                                        __typename?: "Vat";
                                                    } & Pick<
                                                        GQVat,
                                                        "id" | "percentage"
                                                    >
                                                >;
                                            }
                                    >;
                                    customer?: Maybe<
                                        { __typename?: "Customer" } & Pick<
                                            GQCustomer,
                                            "id" | "name"
                                        > & {
                                                group?: Maybe<
                                                    {
                                                        __typename?: "CustomerGroup";
                                                    } & Pick<
                                                        GQCustomerGroup,
                                                        "id" | "name"
                                                    >
                                                >;
                                            }
                                    >;
                                }
                        >;
                        printers: Array<
                            { __typename?: "Printer" } & Pick<
                                GQPrinter,
                                | "id"
                                | "name"
                                | "serial"
                                | "type"
                                | "class"
                                | "manufacturer"
                            >
                        >;
                        bookkeepingAccounts: Array<
                            { __typename?: "BookkeepingAccount" } & Pick<
                                GQBookkeepingAccount,
                                "id" | "name" | "systemLabel"
                            >
                        >;
                    };
            }
        >;
    };
};

export type GQAccountsByCustomerSearchQueryVariables = Exact<{
    pagination?: Maybe<GQPaginationInput>;
    search?: Maybe<GQSearchInput>;
}>;

export type GQAccountsByCustomerSearchQuery = { __typename?: "Query" } & {
    accountsByCustomerSearch: { __typename?: "AccountPagination" } & {
        data: Array<
            { __typename?: "Account" } & Pick<GQAccount, "id" | "name"> & {
                    customer?: Maybe<
                        { __typename?: "Customer" } & Pick<
                            GQCustomer,
                            "id" | "name" | "phoneNumber"
                        >
                    >;
                }
        >;
        pagination: {
            __typename?: "Pagination";
        } & GQDefaultPaginationFieldsFragment;
    };
};

export type GQVenueAccessCheckoutTicketMutationVariables = Exact<{
    eventId: Scalars["Int"];
    accessTypes: Array<Scalars["Int"]> | Scalars["Int"];
    validFrom: Scalars["Date"];
    validTo: Scalars["Date"];
}>;

export type GQVenueAccessCheckoutTicketMutation = {
    __typename?: "Mutation";
} & {
    venueAccessCreateAccessTicket: {
        __typename?: "VenueAccessTicketCreated";
    } & Pick<GQVenueAccessTicketCreated, "tokenValue" | "ticketId">;
};

export type GQVenueAccessCancelTicketMutationVariables = Exact<{
    ticketId: Scalars["Int"];
}>;

export type GQVenueAccessCancelTicketMutation = { __typename?: "Mutation" } & {
    venueAccessCancelAccessTicket: {
        __typename?: "VenueAccessTicketCancelled";
    } & Pick<GQVenueAccessTicketCancelled, "status">;
};

export type GQMerchantDataUpdatedSubscriptionVariables = Exact<{
    [key: string]: never;
}>;

export type GQMerchantDataUpdatedSubscription = {
    __typename?: "Subscription";
} & Pick<GQSubscription, "merchantDataUpdated">;

export type GQInventoryForIdsQueryVariables = Exact<{
    ids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type GQInventoryForIdsQuery = { __typename?: "Query" } & {
    inventoryForIds: Array<
        { __typename?: "InventoryProduct" } & Pick<
            GQInventoryProduct,
            "id" | "quantity"
        > & {
                products: Array<
                    { __typename?: "Product" } & Pick<GQProduct, "id">
                >;
            }
    >;
};

export type GQSelectMerchantPosUserQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GQSelectMerchantPosUserQuery = { __typename?: "Query" } & {
    me: { __typename?: "Me" } & {
        cashRegister?: Maybe<
            { __typename?: "CashRegister" } & Pick<GQCashRegister, "name"> & {
                    department?: Maybe<
                        { __typename?: "Department" } & Pick<
                            GQDepartment,
                            "name"
                        >
                    >;
                    merchant: { __typename?: "Merchant" } & Pick<
                        GQMerchant,
                        "name"
                    >;
                }
        >;
        merchant?: Maybe<
            { __typename?: "Merchant" } & Pick<GQMerchant, "id"> & {
                    users: Array<
                        { __typename?: "User" } & GQAllAuthUserFieldsFragment
                    >;
                }
        >;
    };
};

export type GQAllAuthTokenFieldsFragment = { __typename?: "AuthToken" } & Pick<
    GQAuthToken,
    "access" | "accessExpire" | "now" | "refresh"
>;

export type GQAllAuthRoleFieldsFragment = { __typename?: "Role" } & Pick<
    GQRole,
    "id" | "type" | "name"
> & {
        permissions: Array<
            { __typename?: "RolePermission" } & Pick<
                GQRolePermission,
                "key" | "value"
            >
        >;
    };

export type GQAllAuthUserFieldsFragment = { __typename?: "User" } & Pick<
    GQUser,
    | "id"
    | "username"
    | "email"
    | "firstName"
    | "lastName"
    | "pin"
    | "requireLoginPin"
    | "requireLogoutPin"
    | "requirePasswordChange"
    | "active"
> & {
        roles: Array<
            { __typename?: "UserRole" } & {
                role: { __typename?: "Role" } & GQAllAuthRoleFieldsFragment;
                merchant?: Maybe<
                    { __typename?: "Merchant" } & Pick<
                        GQMerchant,
                        "id" | "name"
                    >
                >;
            }
        >;
    };

export type GQAllAuthMerchantFieldsFragment = {
    __typename?: "Merchant";
} & Pick<GQMerchant, "id" | "serialId" | "name"> & {
        entityConfig?: Maybe<
            { __typename?: "EntityConfig" } & Pick<GQEntityConfig, "data">
        >;
    };

export type GQAuthDevicePollQueryVariables = Exact<{
    deviceId: Scalars["ID"];
    devicePinCode: Scalars["String"];
}>;

export type GQAuthDevicePollQuery = { __typename?: "Query" } & {
    authDevicePoll?: Maybe<
        { __typename?: "AuthToken" } & GQAllAuthTokenFieldsFragment
    >;
};

export type GQAuthRefreshQueryVariables = Exact<{
    refreshToken: Scalars["String"];
}>;

export type GQAuthRefreshQuery = { __typename?: "Query" } & {
    authRefresh?: Maybe<
        { __typename?: "AuthToken" } & GQAllAuthTokenFieldsFragment
    >;
};

export type GQAuthLoginMutationVariables = Exact<{
    username: Scalars["String"];
    password: Scalars["String"];
}>;

export type GQAuthLoginMutation = { __typename?: "Mutation" } & {
    authLogin: { __typename?: "AuthAttempt" } & Pick<
        GQAuthAttempt,
        "successful"
    > & {
            token?: Maybe<
                { __typename?: "AuthToken" } & GQAllAuthTokenFieldsFragment
            >;
        };
};

export type GQAuthUpdateLoginMutationVariables = Exact<{
    [key: string]: never;
}>;

export type GQAuthUpdateLoginMutation = { __typename?: "Mutation" } & {
    authUpdateLogin: { __typename?: "AuthAttempt" } & Pick<
        GQAuthAttempt,
        "successful"
    > & {
            token?: Maybe<
                { __typename?: "AuthToken" } & GQAllAuthTokenFieldsFragment
            >;
        };
};

export type GQAuthSelectUserRoleMutationVariables = Exact<{
    roleId: Scalars["ID"];
    merchantId?: Maybe<Scalars["ID"]>;
}>;

export type GQAuthSelectUserRoleMutation = { __typename?: "Mutation" } & {
    authSelectUserRole: { __typename?: "SelectUserRoleType" } & {
        token: { __typename?: "AuthToken" } & GQAllAuthTokenFieldsFragment;
    };
};

export type GQMeQueryVariables = Exact<{ [key: string]: never }>;

export type GQMeQuery = { __typename?: "Query" } & {
    me: { __typename?: "Me" } & {
        cashRegister?: Maybe<
            { __typename?: "CashRegister" } & Pick<
                GQCashRegister,
                "id" | "serialId" | "name"
            >
        >;
        user?: Maybe<{ __typename?: "User" } & GQAllAuthUserFieldsFragment>;
        department?: Maybe<
            { __typename?: "Department" } & GQAllDepartmentFieldsFragment
        >;
        merchant?: Maybe<
            { __typename?: "Merchant" } & GQAllAuthMerchantFieldsFragment
        >;
        role?: Maybe<{ __typename?: "Role" } & GQAllAuthRoleFieldsFragment>;
    };
};

export type GQRegisterDeviceMutationVariables = Exact<{
    deviceInfo: GQDeviceInfoInput;
    newPinCode?: Maybe<Scalars["Boolean"]>;
}>;

export type GQRegisterDeviceMutation = { __typename?: "Mutation" } & {
    deviceRegister: { __typename?: "Device" } & Pick<
        GQDevice,
        "id" | "pinCode"
    >;
};

export type GQDeviceHeartbeatMutationVariables = Exact<{
    deviceInfo: GQDeviceInfoInput;
}>;

export type GQDeviceHeartbeatMutation = { __typename?: "Mutation" } & Pick<
    GQMutation,
    "deviceHeartbeat"
>;

export const DefaultPaginationFieldsFragmentDoc = gql`
    fragment defaultPaginationFields on Pagination {
        pages
        sort
        sortDirection
        resultCount
    }
`;
export const AllAccountFieldsFragmentDoc = gql`
    fragment allAccountFields on Account {
        id
        name
        merchant {
            id
            name
        }
        type
        status
        amount
        systemLabel
        customer {
            id
            name
            group {
                id
                name
            }
        }
        createdAt
        externalId
        vat {
            id
        }
    }
`;
export const AllBookkeepingAccountFieldsFragmentDoc = gql`
    fragment allBookkeepingAccountFields on BookkeepingAccount {
        id
        name
        merchant {
            id
            name
        }
        systemLabel
        createdAt
    }
`;
export const AllAccountTransactionFieldsFragmentDoc = gql`
    fragment allAccountTransactionFields on AccountTransaction {
        id
        userId
        transactionAt
        amount
        user {
            username
        }
        tag {
            id
            identifier
            name
        }
        invoiceId
    }
`;
export const AllAccountBookkeepingTransactionFieldsFragmentDoc = gql`
    fragment allAccountBookkeepingTransactionFields on AccountBookkeepingTransaction {
        id
        transactionAt
        amount
        type
        invoiceId
    }
`;
export const CustomerScreenFieldsFragmentDoc = gql`
    fragment customerScreenFields on Customer {
        id
        name
        group {
            id
            name
        }
        address
        zip
        city
        email
        phoneNumber
        comment
        cvrNumber
        contactName
        accounts {
            amount
        }
        createdAt
    }
`;
export const CustomerGroupScreenFieldsFragmentDoc = gql`
    fragment customerGroupScreenFields on CustomerGroup {
        id
        name
    }
`;
export const CardTransactionScreenFieldsFragmentDoc = gql`
    fragment cardTransactionScreenFields on CardTransaction {
        id
        status
        orderId
        amount
        receipt
        transactionAt
        cashRegister {
            name
        }
    }
`;
export const CashRegisterFragmentDoc = gql`
    fragment cashRegister on CashRegister {
        id
        name
        device {
            id
            osName
            osVersion
            appVersion
            appBuild
            serialNumber
            hardwareModel
            hardwareProducer
            macAddress
            language
            lastSeen
        }
        department {
            id
            name
        }
        merchant {
            id
            name
            lastNotifySentAt
        }
        createdAt
        deletedAt
        lastAppDataSentAt
    }
`;
export const AllCurrencyFieldsFragmentDoc = gql`
    fragment allCurrencyFields on Currency {
        id
        merchant {
            id
            name
        }
        code
        rate
        createdAt
        deletedAt
    }
`;
export const AllDashboardFragmentDoc = gql`
    fragment allDashboard on Dashboard {
        totals {
            invoices
            turnoverAmount
            avgTransaction
        }
        products {
            id
            name
            quantity
            turnoverAmount
        }
        productGroups {
            id
            name
            quantity
            turnoverAmount
        }
        departments {
            id
            name
            invoices
            avgTransaction
            turnoverAmount
        }
        accounts {
            id
            name
            amount
        }
    }
`;
export const AllDepartmentFieldsFragmentDoc = gql`
    fragment allDepartmentFields on Department {
        id
        name
        externalId
        createdAt
        deletedAt
        merchant {
            id
            name
        }
    }
`;
export const AllDiscountFieldsFragmentDoc = gql`
    fragment allDiscountFields on Discount {
        id
        merchant {
            id
            name
        }
        name
        lineDescription
        type
        valueType
        value
        maxPerOrder
        itemQuery
        conditions
        createdAt
        deletedAt
        active
    }
`;
export const AllIntegrationAccountFieldsFragmentDoc = gql`
    fragment allIntegrationAccountFields on IntegrationAccount {
        id
        name
        accountNo
        integrationType
    }
`;
export const AllIntegrationFieldsFragmentDoc = gql`
    fragment allIntegrationFields on Integration {
        id
        type
        active
        name
        apiKey
        createdAt
        merchant {
            id
            name
        }
        merchantChartOfAccounts {
            chartOfAccountsId
            merchantAccountNo
            name
        }
    }
`;
export const AllMerchantChartOfAccountFieldsFragmentDoc = gql`
    fragment allMerchantChartOfAccountFields on MerchantChartOfAccount {
        integrationId
        name
        chartOfAccountsId
        merchantAccountNo
    }
`;
export const AllInvoiceFieldsFragmentDoc = gql`
    fragment allInvoiceFields on InvoiceRM {
        id
        shift {
            id
            cashRegister {
                name
            }
        }
        items {
            quantity
            external {
                integration
                receipt
            }
        }
        payments {
            cardReceipt
        }
        receiptNumber
        receipt
        discountAmount
        amount
        createdAt
    }
`;
export const AllLayoutFieldsFragmentDoc = gql`
    fragment allLayoutFields on Layout {
        id
        name
        data
        columns
        rows
        layoutType
        products {
            id
            name
            amount
            minimumAmount
            costAmount
            vat {
                id
                name
                percentage
            }
            excludeFromDiscounts
            group {
                id
                name
                color
            }
            buttonText
        }
        productGroups {
            id
            name
            color
            vat {
                id
                name
                percentage
            }
        }
        createdAt
    }
`;
export const AllProductLayoutFieldsFragmentDoc = gql`
    fragment allProductLayoutFields on Layout {
        id
        name
        data
        columns
        rows
        layoutType
        products {
            id
            name
            amount
            minimumAmount
            costAmount
            vat {
                id
                name
                percentage
            }
            excludeFromDiscounts
            group {
                id
                name
                color
            }
            buttonText
        }
        productGroups {
            id
            name
            color
            vat {
                id
                name
                percentage
            }
        }
        createdAt
    }
`;
export const AllPosProductLayoutFieldsFragmentDoc = gql`
    fragment allPosProductLayoutFields on Layout {
        id
        name
        data
        columns
        rows
        layoutType
        products {
            id
            name
            amount
            minimumAmount
            costAmount
            vat {
                id
                name
                percentage
            }
            excludeFromDiscounts
            group {
                id
                name
                color
            }
            buttonText
        }
        productGroups {
            id
            name
            color
            vat {
                id
                name
                percentage
            }
        }
    }
`;
export const AllTableLayoutFieldsFragmentDoc = gql`
    fragment allTableLayoutFields on Layout {
        id
        name
        data
        columns
        rows
        layoutType
        createdAt
    }
`;
export const AllPosTableLayoutFieldsFragmentDoc = gql`
    fragment allPosTableLayoutFields on Layout {
        id
        name
        data
        columns
        rows
        layoutType
    }
`;
export const AllAccessTicketFieldsFragmentDoc = gql`
    fragment allAccessTicketFields on VenueAccessParameters {
        events {
            id
            name
            isActive
        }
        accessTypes {
            id
            name
        }
    }
`;
export const AllMerchantFieldsFragmentDoc = gql`
    fragment allMerchantFields on Merchant {
        id
        name
        createdAt
    }
`;
export const AllMiscButtonsFieldsFragmentDoc = gql`
    fragment allMiscButtonsFields on MiscButtons {
        id
        merchant {
            id
            name
        }
        name
        buttons
        createdAt
        deletedAt
    }
`;
export const ScreenMiscButtonFieldsFragmentDoc = gql`
    fragment screenMiscButtonFields on MiscButtons {
        id
        merchant {
            id
            name
        }
        name
        buttons
        createdAt
    }
`;
export const AllPaymentConfigurationFieldsFragmentDoc = gql`
    fragment allPaymentConfigurationFields on PaymentConfiguration {
        id
        name
        paymentMethods {
            paymentMethod {
                id
                name
                paymentType
                enabled
                currency {
                    code
                    rate
                }
            }
            showAsQuickButton
            showInPaymentWindow
        }
        createdAt
    }
`;
export const AllPaymentMethodFieldsFragmentDoc = gql`
    fragment allPaymentMethodFields on PaymentMethod {
        id
        name
        paymentType
        enabled
        account
        diffAccount
        currency {
            id
            code
        }
        createdAt
    }
`;
export const PrinterScreenFieldsFragmentDoc = gql`
    fragment printerScreenFields on Printer {
        id
        name
        serial
        type
        manufacturer
        class
        createdAt
        cashRegister {
            id
            name
        }
    }
`;
export const AllProductGroupFieldsFragmentDoc = gql`
    fragment allProductGroupFields on ProductGroup {
        id
        externalId
        name
        vat {
            id
            name
            percentage
        }
        color
        excludeFromDiscounts
        isExternal
        printer {
            id
            name
        }
        createdAt
        deletedAt
    }
`;
export const AllInventoryProductNoteTemplateFieldsFragmentDoc = gql`
    fragment allInventoryProductNoteTemplateFields on InventoryProductNoteTemplate {
        id
        note
    }
`;
export const AllProductFieldsFragmentDoc = gql`
    fragment allProductFields on Product {
        id
        parent {
            id
            name
        }
        group {
            id
            name
            color
            vat {
                id
                name
                percentage
            }
        }
        externalId
        name
        amount
        minimumAmount
        costAmount
        vat {
            id
            name
            percentage
        }
        ownVat {
            id
            name
            percentage
        }
        onlineRequired
        active
        isExternal
        excludeFromDiscounts
        ownExcludeFromDiscounts
        barcode
        barcodes {
            id
            barcode
        }
        buttonText
        inventoryProduct {
            id
            quantity
        }
        buttonText
        printer {
            id
            name
        }
        inheritPrinter
        createdAt
        deletedAt
    }
`;
export const ProductChildrenListFieldsFragmentDoc = gql`
    fragment productChildrenListFields on ProductPagination {
        data {
            id
            name
            amount
        }
    }
`;
export const ReportFieldsFragmentDoc = gql`
    fragment reportFields on ReportParameters {
        users {
            id
            username
            firstName
            lastName
        }
        departments {
            id
            name
        }
        cashRegisters {
            id
            name
        }
        productGroups {
            id
            name
        }
        products {
            id
            name
        }
    }
`;
export const AllShiftFieldsFragmentDoc = gql`
    fragment allShiftFields on ShiftRM {
        id
        cashRegister {
            name
            department {
                name
            }
        }
        openedByUser {
            username
        }
        closedByUser {
            username
        }
        openedAt
        closedAt
        discount
        amount
        zReport
    }
`;
export const TagGroupScreenFieldsFragmentDoc = gql`
    fragment tagGroupScreenFields on TagGroup {
        id
        name
    }
`;
export const TagScreenFieldsFragmentDoc = gql`
    fragment tagScreenFields on Tag {
        id
        identifier
        name
        tagType
        group {
            id
            name
        }
        account {
            id
            name
        }
        customer {
            id
            name
        }
        createdAt
    }
`;
export const TagNameFieldsFragmentDoc = gql`
    fragment tagNameFields on Tag {
        id
        identifier
        name
    }
`;
export const AllRoleFieldsFragmentDoc = gql`
    fragment allRoleFields on Role {
        id
        name
        shared
        merchant {
            id
            name
        }
    }
`;
export const UserFragmentDoc = gql`
    fragment user on User {
        id
        username
        email
        firstName
        lastName
        active
        pin
        requireLoginPin
        requireLogoutPin
        requirePasswordChange
        createdAt
        roles {
            role {
                id
                name
                type
            }
            merchant {
                id
                name
            }
        }
    }
`;
export const UserFormRolesFieldsFragmentDoc = gql`
    fragment userFormRolesFields on Role {
        id
        name
        type
        merchant {
            id
            name
        }
    }
`;
export const VatScreenFieldsFragmentDoc = gql`
    fragment vatScreenFields on Vat {
        id
        name
        merchant {
            id
            name
        }
        percentage
        isExternal
        externalId
        createdAt
    }
`;
export const AllAuthTokenFieldsFragmentDoc = gql`
    fragment allAuthTokenFields on AuthToken {
        access
        accessExpire
        now
        refresh
    }
`;
export const AllAuthRoleFieldsFragmentDoc = gql`
    fragment allAuthRoleFields on Role {
        id
        type
        name
        permissions {
            key
            value
        }
    }
`;
export const AllAuthUserFieldsFragmentDoc = gql`
    fragment allAuthUserFields on User {
        id
        username
        email
        firstName
        lastName
        pin
        requireLoginPin
        requireLogoutPin
        requirePasswordChange
        active
        roles {
            role {
                ...allAuthRoleFields
            }
            merchant {
                id
                name
            }
        }
    }
    ${AllAuthRoleFieldsFragmentDoc}
`;
export const AllAuthMerchantFieldsFragmentDoc = gql`
    fragment allAuthMerchantFields on Merchant {
        id
        serialId
        name
        entityConfig {
            data
        }
    }
`;
export const EntityConfigsDocument = gql`
    query entityConfigs($entityIds: [ID!]!) {
        entityConfigs(entityIds: $entityIds) {
            entityId
            data
        }
    }
`;

/**
 * __useEntityConfigsQuery__
 *
 * To run a query within a React component, call `useEntityConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityConfigsQuery({
 *   variables: {
 *      entityIds: // value for 'entityIds'
 *   },
 * });
 */
export function useEntityConfigsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQEntityConfigsQuery,
        GQEntityConfigsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQEntityConfigsQuery,
        GQEntityConfigsQueryVariables
    >(EntityConfigsDocument, baseOptions);
}
export function useEntityConfigsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQEntityConfigsQuery,
        GQEntityConfigsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQEntityConfigsQuery,
        GQEntityConfigsQueryVariables
    >(EntityConfigsDocument, baseOptions);
}
export type EntityConfigsQueryHookResult = ReturnType<
    typeof useEntityConfigsQuery
>;
export type EntityConfigsLazyQueryHookResult = ReturnType<
    typeof useEntityConfigsLazyQuery
>;
export type EntityConfigsQueryResult = ApolloReactCommon.QueryResult<
    GQEntityConfigsQuery,
    GQEntityConfigsQueryVariables
>;
export const EntityConfigSaveDocument = gql`
    mutation entityConfigSave(
        $entityId: ID!
        $partialData: JSON!
        $disabledData: [String!]!
    ) {
        entityConfigSave(
            entityId: $entityId
            partialData: $partialData
            disabledData: $disabledData
        ) {
            entityId
            data
        }
    }
`;
export type GQEntityConfigSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQEntityConfigSaveMutation,
    GQEntityConfigSaveMutationVariables
>;

/**
 * __useEntityConfigSaveMutation__
 *
 * To run a mutation, you first call `useEntityConfigSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEntityConfigSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [entityConfigSaveMutation, { data, loading, error }] = useEntityConfigSaveMutation({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      partialData: // value for 'partialData'
 *      disabledData: // value for 'disabledData'
 *   },
 * });
 */
export function useEntityConfigSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQEntityConfigSaveMutation,
        GQEntityConfigSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQEntityConfigSaveMutation,
        GQEntityConfigSaveMutationVariables
    >(EntityConfigSaveDocument, baseOptions);
}
export type EntityConfigSaveMutationHookResult = ReturnType<
    typeof useEntityConfigSaveMutation
>;
export type EntityConfigSaveMutationResult =
    ApolloReactCommon.MutationResult<GQEntityConfigSaveMutation>;
export type EntityConfigSaveMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQEntityConfigSaveMutation,
        GQEntityConfigSaveMutationVariables
    >;
export const EntityConfigDeleteDocument = gql`
    mutation entityConfigDelete($entityId: ID!) {
        entityConfigDelete(entityId: $entityId) {
            status
        }
    }
`;
export type GQEntityConfigDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQEntityConfigDeleteMutation,
    GQEntityConfigDeleteMutationVariables
>;

/**
 * __useEntityConfigDeleteMutation__
 *
 * To run a mutation, you first call `useEntityConfigDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEntityConfigDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [entityConfigDeleteMutation, { data, loading, error }] = useEntityConfigDeleteMutation({
 *   variables: {
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useEntityConfigDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQEntityConfigDeleteMutation,
        GQEntityConfigDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQEntityConfigDeleteMutation,
        GQEntityConfigDeleteMutationVariables
    >(EntityConfigDeleteDocument, baseOptions);
}
export type EntityConfigDeleteMutationHookResult = ReturnType<
    typeof useEntityConfigDeleteMutation
>;
export type EntityConfigDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQEntityConfigDeleteMutation>;
export type EntityConfigDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQEntityConfigDeleteMutation,
        GQEntityConfigDeleteMutationVariables
    >;
export const AccountsDocument = gql`
    query accounts($pagination: PaginationInput, $search: SearchInput) {
        accounts(pagination: $pagination, search: $search) {
            data {
                ...allAccountFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllAccountFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAccountsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQAccountsQuery,
        GQAccountsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQAccountsQuery, GQAccountsQueryVariables>(
        AccountsDocument,
        baseOptions
    );
}
export function useAccountsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQAccountsQuery,
        GQAccountsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQAccountsQuery,
        GQAccountsQueryVariables
    >(AccountsDocument, baseOptions);
}
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<
    typeof useAccountsLazyQuery
>;
export type AccountsQueryResult = ApolloReactCommon.QueryResult<
    GQAccountsQuery,
    GQAccountsQueryVariables
>;
export const GiftCardsDocument = gql`
    query giftCards($pagination: PaginationInput, $search: SearchInput) {
        giftCards(pagination: $pagination, search: $search) {
            data {
                ...allAccountFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllAccountFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useGiftCardsQuery__
 *
 * To run a query within a React component, call `useGiftCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftCardsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGiftCardsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQGiftCardsQuery,
        GQGiftCardsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQGiftCardsQuery,
        GQGiftCardsQueryVariables
    >(GiftCardsDocument, baseOptions);
}
export function useGiftCardsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQGiftCardsQuery,
        GQGiftCardsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQGiftCardsQuery,
        GQGiftCardsQueryVariables
    >(GiftCardsDocument, baseOptions);
}
export type GiftCardsQueryHookResult = ReturnType<typeof useGiftCardsQuery>;
export type GiftCardsLazyQueryHookResult = ReturnType<
    typeof useGiftCardsLazyQuery
>;
export type GiftCardsQueryResult = ApolloReactCommon.QueryResult<
    GQGiftCardsQuery,
    GQGiftCardsQueryVariables
>;
export const CustomerAccountsDocument = gql`
    query customerAccounts(
        $customerId: ID
        $pagination: PaginationInput
        $search: SearchInput
    ) {
        customerAccounts(
            customerId: $customerId
            pagination: $pagination
            search: $search
        ) {
            data {
                ...allAccountFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllAccountFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useCustomerAccountsQuery__
 *
 * To run a query within a React component, call `useCustomerAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAccountsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCustomerAccountsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCustomerAccountsQuery,
        GQCustomerAccountsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCustomerAccountsQuery,
        GQCustomerAccountsQueryVariables
    >(CustomerAccountsDocument, baseOptions);
}
export function useCustomerAccountsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCustomerAccountsQuery,
        GQCustomerAccountsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCustomerAccountsQuery,
        GQCustomerAccountsQueryVariables
    >(CustomerAccountsDocument, baseOptions);
}
export type CustomerAccountsQueryHookResult = ReturnType<
    typeof useCustomerAccountsQuery
>;
export type CustomerAccountsLazyQueryHookResult = ReturnType<
    typeof useCustomerAccountsLazyQuery
>;
export type CustomerAccountsQueryResult = ApolloReactCommon.QueryResult<
    GQCustomerAccountsQuery,
    GQCustomerAccountsQueryVariables
>;
export const BookkeepingAccountsDocument = gql`
    query bookkeepingAccounts(
        $pagination: PaginationInput
        $search: SearchInput
    ) {
        bookkeepingAccounts(pagination: $pagination, search: $search) {
            data {
                ...allBookkeepingAccountFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllBookkeepingAccountFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useBookkeepingAccountsQuery__
 *
 * To run a query within a React component, call `useBookkeepingAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookkeepingAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookkeepingAccountsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBookkeepingAccountsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQBookkeepingAccountsQuery,
        GQBookkeepingAccountsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQBookkeepingAccountsQuery,
        GQBookkeepingAccountsQueryVariables
    >(BookkeepingAccountsDocument, baseOptions);
}
export function useBookkeepingAccountsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQBookkeepingAccountsQuery,
        GQBookkeepingAccountsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQBookkeepingAccountsQuery,
        GQBookkeepingAccountsQueryVariables
    >(BookkeepingAccountsDocument, baseOptions);
}
export type BookkeepingAccountsQueryHookResult = ReturnType<
    typeof useBookkeepingAccountsQuery
>;
export type BookkeepingAccountsLazyQueryHookResult = ReturnType<
    typeof useBookkeepingAccountsLazyQuery
>;
export type BookkeepingAccountsQueryResult = ApolloReactCommon.QueryResult<
    GQBookkeepingAccountsQuery,
    GQBookkeepingAccountsQueryVariables
>;
export const AccountDocument = gql`
    query account(
        $id: ID!
        $transactionPagination: PaginationInput
        $transactionSearch: SearchInput
        $tagPagination: PaginationInput
        $tagSearch: SearchInput
        $numberOfTransactions: Int
    ) {
        account(id: $id) {
            ...allAccountFields
            transactions(
                pagination: $transactionPagination
                search: $transactionSearch
            ) {
                data {
                    ...allAccountTransactionFields
                }
                pagination {
                    ...defaultPaginationFields
                }
            }
            tags(pagination: $tagPagination, search: $tagSearch) {
                data {
                    ...tagScreenFields
                }
                pagination {
                    ...defaultPaginationFields
                }
            }
            balance(numberOfTransactions: $numberOfTransactions) {
                receipt
            }
        }
    }
    ${AllAccountFieldsFragmentDoc}
    ${AllAccountTransactionFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
    ${TagScreenFieldsFragmentDoc}
`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *      transactionPagination: // value for 'transactionPagination'
 *      transactionSearch: // value for 'transactionSearch'
 *      tagPagination: // value for 'tagPagination'
 *      tagSearch: // value for 'tagSearch'
 *      numberOfTransactions: // value for 'numberOfTransactions'
 *   },
 * });
 */
export function useAccountQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQAccountQuery,
        GQAccountQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQAccountQuery, GQAccountQueryVariables>(
        AccountDocument,
        baseOptions
    );
}
export function useAccountLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQAccountQuery,
        GQAccountQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQAccountQuery,
        GQAccountQueryVariables
    >(AccountDocument, baseOptions);
}
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = ApolloReactCommon.QueryResult<
    GQAccountQuery,
    GQAccountQueryVariables
>;
export const BookkeepingAccountDocument = gql`
    query bookkeepingAccount(
        $id: ID!
        $transactionPagination: PaginationInput
        $transactionSearch: SearchInput
    ) {
        bookkeepingAccount(id: $id) {
            ...allBookkeepingAccountFields
            bookkeepingTransactions(
                pagination: $transactionPagination
                search: $transactionSearch
            ) {
                data {
                    ...allAccountBookkeepingTransactionFields
                }
                pagination {
                    ...defaultPaginationFields
                }
            }
        }
    }
    ${AllBookkeepingAccountFieldsFragmentDoc}
    ${AllAccountBookkeepingTransactionFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useBookkeepingAccountQuery__
 *
 * To run a query within a React component, call `useBookkeepingAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookkeepingAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookkeepingAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *      transactionPagination: // value for 'transactionPagination'
 *      transactionSearch: // value for 'transactionSearch'
 *   },
 * });
 */
export function useBookkeepingAccountQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQBookkeepingAccountQuery,
        GQBookkeepingAccountQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQBookkeepingAccountQuery,
        GQBookkeepingAccountQueryVariables
    >(BookkeepingAccountDocument, baseOptions);
}
export function useBookkeepingAccountLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQBookkeepingAccountQuery,
        GQBookkeepingAccountQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQBookkeepingAccountQuery,
        GQBookkeepingAccountQueryVariables
    >(BookkeepingAccountDocument, baseOptions);
}
export type BookkeepingAccountQueryHookResult = ReturnType<
    typeof useBookkeepingAccountQuery
>;
export type BookkeepingAccountLazyQueryHookResult = ReturnType<
    typeof useBookkeepingAccountLazyQuery
>;
export type BookkeepingAccountQueryResult = ApolloReactCommon.QueryResult<
    GQBookkeepingAccountQuery,
    GQBookkeepingAccountQueryVariables
>;
export const AccountEnabledTagsDocument = gql`
    query accountEnabledTags(
        $id: ID!
        $tagPagination: PaginationInput
        $tagSearch: SearchInput
    ) {
        account(id: $id) {
            tags(pagination: $tagPagination, search: $tagSearch) {
                data {
                    ...tagScreenFields
                }
                pagination {
                    ...defaultPaginationFields
                }
            }
        }
    }
    ${TagScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useAccountEnabledTagsQuery__
 *
 * To run a query within a React component, call `useAccountEnabledTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountEnabledTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountEnabledTagsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tagPagination: // value for 'tagPagination'
 *      tagSearch: // value for 'tagSearch'
 *   },
 * });
 */
export function useAccountEnabledTagsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQAccountEnabledTagsQuery,
        GQAccountEnabledTagsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQAccountEnabledTagsQuery,
        GQAccountEnabledTagsQueryVariables
    >(AccountEnabledTagsDocument, baseOptions);
}
export function useAccountEnabledTagsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQAccountEnabledTagsQuery,
        GQAccountEnabledTagsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQAccountEnabledTagsQuery,
        GQAccountEnabledTagsQueryVariables
    >(AccountEnabledTagsDocument, baseOptions);
}
export type AccountEnabledTagsQueryHookResult = ReturnType<
    typeof useAccountEnabledTagsQuery
>;
export type AccountEnabledTagsLazyQueryHookResult = ReturnType<
    typeof useAccountEnabledTagsLazyQuery
>;
export type AccountEnabledTagsQueryResult = ApolloReactCommon.QueryResult<
    GQAccountEnabledTagsQuery,
    GQAccountEnabledTagsQueryVariables
>;
export const AccountTagsViewDocument = gql`
    query accountTagsView(
        $id: ID!
        $tagPagination: PaginationInput
        $tagSearch: SearchInput
    ) {
        account(id: $id) {
            ...allAccountFields
            tags(pagination: $tagPagination, search: $tagSearch) {
                data {
                    ...tagScreenFields
                }
                pagination {
                    ...defaultPaginationFields
                }
            }
        }
    }
    ${AllAccountFieldsFragmentDoc}
    ${TagScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useAccountTagsViewQuery__
 *
 * To run a query within a React component, call `useAccountTagsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTagsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTagsViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tagPagination: // value for 'tagPagination'
 *      tagSearch: // value for 'tagSearch'
 *   },
 * });
 */
export function useAccountTagsViewQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQAccountTagsViewQuery,
        GQAccountTagsViewQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQAccountTagsViewQuery,
        GQAccountTagsViewQueryVariables
    >(AccountTagsViewDocument, baseOptions);
}
export function useAccountTagsViewLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQAccountTagsViewQuery,
        GQAccountTagsViewQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQAccountTagsViewQuery,
        GQAccountTagsViewQueryVariables
    >(AccountTagsViewDocument, baseOptions);
}
export type AccountTagsViewQueryHookResult = ReturnType<
    typeof useAccountTagsViewQuery
>;
export type AccountTagsViewLazyQueryHookResult = ReturnType<
    typeof useAccountTagsViewLazyQuery
>;
export type AccountTagsViewQueryResult = ApolloReactCommon.QueryResult<
    GQAccountTagsViewQuery,
    GQAccountTagsViewQueryVariables
>;
export const AllTransactionsDocument = gql`
    query allTransactions($pagination: PaginationInput, $accountId: String) {
        transactions(pagination: $pagination, accountId: $accountId) {
            data {
                ...allAccountTransactionFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllAccountTransactionFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useAllTransactionsQuery__
 *
 * To run a query within a React component, call `useAllTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTransactionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAllTransactionsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQAllTransactionsQuery,
        GQAllTransactionsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQAllTransactionsQuery,
        GQAllTransactionsQueryVariables
    >(AllTransactionsDocument, baseOptions);
}
export function useAllTransactionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQAllTransactionsQuery,
        GQAllTransactionsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQAllTransactionsQuery,
        GQAllTransactionsQueryVariables
    >(AllTransactionsDocument, baseOptions);
}
export type AllTransactionsQueryHookResult = ReturnType<
    typeof useAllTransactionsQuery
>;
export type AllTransactionsLazyQueryHookResult = ReturnType<
    typeof useAllTransactionsLazyQuery
>;
export type AllTransactionsQueryResult = ApolloReactCommon.QueryResult<
    GQAllTransactionsQuery,
    GQAllTransactionsQueryVariables
>;
export const AccountCreateDocument = gql`
    mutation accountCreate($account: AccountCreate!) {
        accountCreate(account: $account) {
            ...allAccountFields
        }
    }
    ${AllAccountFieldsFragmentDoc}
`;
export type GQAccountCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQAccountCreateMutation,
    GQAccountCreateMutationVariables
>;

/**
 * __useAccountCreateMutation__
 *
 * To run a mutation, you first call `useAccountCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountCreateMutation, { data, loading, error }] = useAccountCreateMutation({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useAccountCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAccountCreateMutation,
        GQAccountCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAccountCreateMutation,
        GQAccountCreateMutationVariables
    >(AccountCreateDocument, baseOptions);
}
export type AccountCreateMutationHookResult = ReturnType<
    typeof useAccountCreateMutation
>;
export type AccountCreateMutationResult =
    ApolloReactCommon.MutationResult<GQAccountCreateMutation>;
export type AccountCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQAccountCreateMutation,
        GQAccountCreateMutationVariables
    >;
export const AccountSaveDocument = gql`
    mutation accountSave($id: ID!, $account: AccountInput!) {
        accountSave(id: $id, account: $account) {
            ...allAccountFields
        }
    }
    ${AllAccountFieldsFragmentDoc}
`;
export type GQAccountSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQAccountSaveMutation,
    GQAccountSaveMutationVariables
>;

/**
 * __useAccountSaveMutation__
 *
 * To run a mutation, you first call `useAccountSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountSaveMutation, { data, loading, error }] = useAccountSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useAccountSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAccountSaveMutation,
        GQAccountSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAccountSaveMutation,
        GQAccountSaveMutationVariables
    >(AccountSaveDocument, baseOptions);
}
export type AccountSaveMutationHookResult = ReturnType<
    typeof useAccountSaveMutation
>;
export type AccountSaveMutationResult =
    ApolloReactCommon.MutationResult<GQAccountSaveMutation>;
export type AccountSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQAccountSaveMutation,
    GQAccountSaveMutationVariables
>;
export const AccountCloseDocument = gql`
    mutation accountClose($id: ID!) {
        accountClose(id: $id) {
            status
        }
    }
`;
export type GQAccountCloseMutationFn = ApolloReactCommon.MutationFunction<
    GQAccountCloseMutation,
    GQAccountCloseMutationVariables
>;

/**
 * __useAccountCloseMutation__
 *
 * To run a mutation, you first call `useAccountCloseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountCloseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountCloseMutation, { data, loading, error }] = useAccountCloseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountCloseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAccountCloseMutation,
        GQAccountCloseMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAccountCloseMutation,
        GQAccountCloseMutationVariables
    >(AccountCloseDocument, baseOptions);
}
export type AccountCloseMutationHookResult = ReturnType<
    typeof useAccountCloseMutation
>;
export type AccountCloseMutationResult =
    ApolloReactCommon.MutationResult<GQAccountCloseMutation>;
export type AccountCloseMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQAccountCloseMutation,
    GQAccountCloseMutationVariables
>;
export const AccountOpenDocument = gql`
    mutation accountOpen($id: ID!) {
        accountOpen(id: $id) {
            status
        }
    }
`;
export type GQAccountOpenMutationFn = ApolloReactCommon.MutationFunction<
    GQAccountOpenMutation,
    GQAccountOpenMutationVariables
>;

/**
 * __useAccountOpenMutation__
 *
 * To run a mutation, you first call `useAccountOpenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountOpenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountOpenMutation, { data, loading, error }] = useAccountOpenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountOpenMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAccountOpenMutation,
        GQAccountOpenMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAccountOpenMutation,
        GQAccountOpenMutationVariables
    >(AccountOpenDocument, baseOptions);
}
export type AccountOpenMutationHookResult = ReturnType<
    typeof useAccountOpenMutation
>;
export type AccountOpenMutationResult =
    ApolloReactCommon.MutationResult<GQAccountOpenMutation>;
export type AccountOpenMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQAccountOpenMutation,
    GQAccountOpenMutationVariables
>;
export const AccountAssignTagsDocument = gql`
    mutation accountAssignTags($id: ID!, $tagIds: [ID!]!) {
        accountAssignTags(id: $id, tagIds: $tagIds) {
            status
        }
    }
`;
export type GQAccountAssignTagsMutationFn = ApolloReactCommon.MutationFunction<
    GQAccountAssignTagsMutation,
    GQAccountAssignTagsMutationVariables
>;

/**
 * __useAccountAssignTagsMutation__
 *
 * To run a mutation, you first call `useAccountAssignTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountAssignTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountAssignTagsMutation, { data, loading, error }] = useAccountAssignTagsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useAccountAssignTagsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAccountAssignTagsMutation,
        GQAccountAssignTagsMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAccountAssignTagsMutation,
        GQAccountAssignTagsMutationVariables
    >(AccountAssignTagsDocument, baseOptions);
}
export type AccountAssignTagsMutationHookResult = ReturnType<
    typeof useAccountAssignTagsMutation
>;
export type AccountAssignTagsMutationResult =
    ApolloReactCommon.MutationResult<GQAccountAssignTagsMutation>;
export type AccountAssignTagsMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQAccountAssignTagsMutation,
        GQAccountAssignTagsMutationVariables
    >;
export const AccountPayAmountDocument = gql`
    mutation accountPayAmount(
        $id: ID!
        $orderId: ID!
        $amount: Float!
        $userId: ID!
        $tagId: ID
        $tagIdentifier: String
    ) {
        accountPayAmount(
            id: $id
            orderId: $orderId
            amount: $amount
            userId: $userId
            tagId: $tagId
            tagIdentifier: $tagIdentifier
        ) {
            transactionId
            status
            type
            receipt
        }
    }
`;
export type GQAccountPayAmountMutationFn = ApolloReactCommon.MutationFunction<
    GQAccountPayAmountMutation,
    GQAccountPayAmountMutationVariables
>;

/**
 * __useAccountPayAmountMutation__
 *
 * To run a mutation, you first call `useAccountPayAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountPayAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountPayAmountMutation, { data, loading, error }] = useAccountPayAmountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orderId: // value for 'orderId'
 *      amount: // value for 'amount'
 *      userId: // value for 'userId'
 *      tagId: // value for 'tagId'
 *      tagIdentifier: // value for 'tagIdentifier'
 *   },
 * });
 */
export function useAccountPayAmountMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAccountPayAmountMutation,
        GQAccountPayAmountMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAccountPayAmountMutation,
        GQAccountPayAmountMutationVariables
    >(AccountPayAmountDocument, baseOptions);
}
export type AccountPayAmountMutationHookResult = ReturnType<
    typeof useAccountPayAmountMutation
>;
export type AccountPayAmountMutationResult =
    ApolloReactCommon.MutationResult<GQAccountPayAmountMutation>;
export type AccountPayAmountMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQAccountPayAmountMutation,
        GQAccountPayAmountMutationVariables
    >;
export const AccountRefundAmountDocument = gql`
    mutation accountRefundAmount(
        $id: ID!
        $orderId: ID!
        $amount: Float!
        $userId: ID!
        $tagId: ID
        $tagIdentifier: String
    ) {
        accountRefundAmount(
            id: $id
            orderId: $orderId
            amount: $amount
            userId: $userId
            tagId: $tagId
            tagIdentifier: $tagIdentifier
        ) {
            transactionId
            status
            type
            receipt
        }
    }
`;
export type GQAccountRefundAmountMutationFn =
    ApolloReactCommon.MutationFunction<
        GQAccountRefundAmountMutation,
        GQAccountRefundAmountMutationVariables
    >;

/**
 * __useAccountRefundAmountMutation__
 *
 * To run a mutation, you first call `useAccountRefundAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountRefundAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountRefundAmountMutation, { data, loading, error }] = useAccountRefundAmountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orderId: // value for 'orderId'
 *      amount: // value for 'amount'
 *      userId: // value for 'userId'
 *      tagId: // value for 'tagId'
 *      tagIdentifier: // value for 'tagIdentifier'
 *   },
 * });
 */
export function useAccountRefundAmountMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAccountRefundAmountMutation,
        GQAccountRefundAmountMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAccountRefundAmountMutation,
        GQAccountRefundAmountMutationVariables
    >(AccountRefundAmountDocument, baseOptions);
}
export type AccountRefundAmountMutationHookResult = ReturnType<
    typeof useAccountRefundAmountMutation
>;
export type AccountRefundAmountMutationResult =
    ApolloReactCommon.MutationResult<GQAccountRefundAmountMutation>;
export type AccountRefundAmountMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQAccountRefundAmountMutation,
        GQAccountRefundAmountMutationVariables
    >;
export const CustomersDocument = gql`
    query customers($pagination: PaginationInput, $search: SearchInput) {
        customers(pagination: $pagination, search: $search) {
            data {
                ...customerScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${CustomerScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCustomersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCustomersQuery,
        GQCustomersQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCustomersQuery,
        GQCustomersQueryVariables
    >(CustomersDocument, baseOptions);
}
export function useCustomersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCustomersQuery,
        GQCustomersQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCustomersQuery,
        GQCustomersQueryVariables
    >(CustomersDocument, baseOptions);
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<
    typeof useCustomersLazyQuery
>;
export type CustomersQueryResult = ApolloReactCommon.QueryResult<
    GQCustomersQuery,
    GQCustomersQueryVariables
>;
export const CustomerDocument = gql`
    query customer(
        $id: ID!
        $tagPagination: PaginationInput
        $tagSearch: SearchInput
    ) {
        customer(id: $id) {
            ...customerScreenFields
            tags(pagination: $tagPagination, search: $tagSearch) {
                data {
                    ...tagScreenFields
                }
                pagination {
                    ...defaultPaginationFields
                }
            }
        }
    }
    ${CustomerScreenFieldsFragmentDoc}
    ${TagScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tagPagination: // value for 'tagPagination'
 *      tagSearch: // value for 'tagSearch'
 *   },
 * });
 */
export function useCustomerQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCustomerQuery,
        GQCustomerQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQCustomerQuery, GQCustomerQueryVariables>(
        CustomerDocument,
        baseOptions
    );
}
export function useCustomerLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCustomerQuery,
        GQCustomerQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCustomerQuery,
        GQCustomerQueryVariables
    >(CustomerDocument, baseOptions);
}
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<
    typeof useCustomerLazyQuery
>;
export type CustomerQueryResult = ApolloReactCommon.QueryResult<
    GQCustomerQuery,
    GQCustomerQueryVariables
>;
export const CustomersInGroupDocument = gql`
    query customersInGroup(
        $groupId: ID!
        $pagination: PaginationInput
        $search: SearchInput
    ) {
        customersInGroup(
            groupId: $groupId
            pagination: $pagination
            search: $search
        ) {
            data {
                ...customerScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${CustomerScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useCustomersInGroupQuery__
 *
 * To run a query within a React component, call `useCustomersInGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersInGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersInGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCustomersInGroupQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCustomersInGroupQuery,
        GQCustomersInGroupQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCustomersInGroupQuery,
        GQCustomersInGroupQueryVariables
    >(CustomersInGroupDocument, baseOptions);
}
export function useCustomersInGroupLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCustomersInGroupQuery,
        GQCustomersInGroupQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCustomersInGroupQuery,
        GQCustomersInGroupQueryVariables
    >(CustomersInGroupDocument, baseOptions);
}
export type CustomersInGroupQueryHookResult = ReturnType<
    typeof useCustomersInGroupQuery
>;
export type CustomersInGroupLazyQueryHookResult = ReturnType<
    typeof useCustomersInGroupLazyQuery
>;
export type CustomersInGroupQueryResult = ApolloReactCommon.QueryResult<
    GQCustomersInGroupQuery,
    GQCustomersInGroupQueryVariables
>;
export const CustomerFormDocument = gql`
    query customerForm {
        customers(pagination: { pageSize: 99999 }) {
            data {
                id
                name
            }
        }
        customerGroups(pagination: { pageSize: 99999 }) {
            data {
                id
                name
            }
        }
    }
`;

/**
 * __useCustomerFormQuery__
 *
 * To run a query within a React component, call `useCustomerFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCustomerFormQuery,
        GQCustomerFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCustomerFormQuery,
        GQCustomerFormQueryVariables
    >(CustomerFormDocument, baseOptions);
}
export function useCustomerFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCustomerFormQuery,
        GQCustomerFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCustomerFormQuery,
        GQCustomerFormQueryVariables
    >(CustomerFormDocument, baseOptions);
}
export type CustomerFormQueryHookResult = ReturnType<
    typeof useCustomerFormQuery
>;
export type CustomerFormLazyQueryHookResult = ReturnType<
    typeof useCustomerFormLazyQuery
>;
export type CustomerFormQueryResult = ApolloReactCommon.QueryResult<
    GQCustomerFormQuery,
    GQCustomerFormQueryVariables
>;
export const CustomerCreateDocument = gql`
    mutation customerCreate($customer: CustomerInput!) {
        customerCreate(customer: $customer) {
            ...customerScreenFields
        }
    }
    ${CustomerScreenFieldsFragmentDoc}
`;
export type GQCustomerCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQCustomerCreateMutation,
    GQCustomerCreateMutationVariables
>;

/**
 * __useCustomerCreateMutation__
 *
 * To run a mutation, you first call `useCustomerCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerCreateMutation, { data, loading, error }] = useCustomerCreateMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useCustomerCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCustomerCreateMutation,
        GQCustomerCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCustomerCreateMutation,
        GQCustomerCreateMutationVariables
    >(CustomerCreateDocument, baseOptions);
}
export type CustomerCreateMutationHookResult = ReturnType<
    typeof useCustomerCreateMutation
>;
export type CustomerCreateMutationResult =
    ApolloReactCommon.MutationResult<GQCustomerCreateMutation>;
export type CustomerCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCustomerCreateMutation,
        GQCustomerCreateMutationVariables
    >;
export const CustomerSaveDocument = gql`
    mutation customerSave($id: ID!, $customer: CustomerInput!) {
        customerSave(id: $id, customer: $customer) {
            ...customerScreenFields
        }
    }
    ${CustomerScreenFieldsFragmentDoc}
`;
export type GQCustomerSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQCustomerSaveMutation,
    GQCustomerSaveMutationVariables
>;

/**
 * __useCustomerSaveMutation__
 *
 * To run a mutation, you first call `useCustomerSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerSaveMutation, { data, loading, error }] = useCustomerSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useCustomerSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCustomerSaveMutation,
        GQCustomerSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCustomerSaveMutation,
        GQCustomerSaveMutationVariables
    >(CustomerSaveDocument, baseOptions);
}
export type CustomerSaveMutationHookResult = ReturnType<
    typeof useCustomerSaveMutation
>;
export type CustomerSaveMutationResult =
    ApolloReactCommon.MutationResult<GQCustomerSaveMutation>;
export type CustomerSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQCustomerSaveMutation,
    GQCustomerSaveMutationVariables
>;
export const CustomerDeleteDocument = gql`
    mutation customerDelete($id: ID!) {
        customerDelete(id: $id) {
            status
        }
    }
`;
export type GQCustomerDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQCustomerDeleteMutation,
    GQCustomerDeleteMutationVariables
>;

/**
 * __useCustomerDeleteMutation__
 *
 * To run a mutation, you first call `useCustomerDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerDeleteMutation, { data, loading, error }] = useCustomerDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCustomerDeleteMutation,
        GQCustomerDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCustomerDeleteMutation,
        GQCustomerDeleteMutationVariables
    >(CustomerDeleteDocument, baseOptions);
}
export type CustomerDeleteMutationHookResult = ReturnType<
    typeof useCustomerDeleteMutation
>;
export type CustomerDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQCustomerDeleteMutation>;
export type CustomerDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCustomerDeleteMutation,
        GQCustomerDeleteMutationVariables
    >;
export const CustomerAssignTagsDocument = gql`
    mutation customerAssignTags($id: ID!, $tagIds: [ID!]!) {
        customerAssignTags(id: $id, tagIds: $tagIds) {
            status
        }
    }
`;
export type GQCustomerAssignTagsMutationFn = ApolloReactCommon.MutationFunction<
    GQCustomerAssignTagsMutation,
    GQCustomerAssignTagsMutationVariables
>;

/**
 * __useCustomerAssignTagsMutation__
 *
 * To run a mutation, you first call `useCustomerAssignTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAssignTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAssignTagsMutation, { data, loading, error }] = useCustomerAssignTagsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useCustomerAssignTagsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCustomerAssignTagsMutation,
        GQCustomerAssignTagsMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCustomerAssignTagsMutation,
        GQCustomerAssignTagsMutationVariables
    >(CustomerAssignTagsDocument, baseOptions);
}
export type CustomerAssignTagsMutationHookResult = ReturnType<
    typeof useCustomerAssignTagsMutation
>;
export type CustomerAssignTagsMutationResult =
    ApolloReactCommon.MutationResult<GQCustomerAssignTagsMutation>;
export type CustomerAssignTagsMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCustomerAssignTagsMutation,
        GQCustomerAssignTagsMutationVariables
    >;
export const CustomerGroupsDocument = gql`
    query customerGroups($pagination: PaginationInput, $search: SearchInput) {
        customerGroups(pagination: $pagination, search: $search) {
            data {
                ...customerGroupScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${CustomerGroupScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useCustomerGroupsQuery__
 *
 * To run a query within a React component, call `useCustomerGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerGroupsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCustomerGroupsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCustomerGroupsQuery,
        GQCustomerGroupsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCustomerGroupsQuery,
        GQCustomerGroupsQueryVariables
    >(CustomerGroupsDocument, baseOptions);
}
export function useCustomerGroupsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCustomerGroupsQuery,
        GQCustomerGroupsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCustomerGroupsQuery,
        GQCustomerGroupsQueryVariables
    >(CustomerGroupsDocument, baseOptions);
}
export type CustomerGroupsQueryHookResult = ReturnType<
    typeof useCustomerGroupsQuery
>;
export type CustomerGroupsLazyQueryHookResult = ReturnType<
    typeof useCustomerGroupsLazyQuery
>;
export type CustomerGroupsQueryResult = ApolloReactCommon.QueryResult<
    GQCustomerGroupsQuery,
    GQCustomerGroupsQueryVariables
>;
export const CustomerGroupDocument = gql`
    query customerGroup($id: ID!) {
        customerGroup(id: $id) {
            ...customerGroupScreenFields
        }
    }
    ${CustomerGroupScreenFieldsFragmentDoc}
`;

/**
 * __useCustomerGroupQuery__
 *
 * To run a query within a React component, call `useCustomerGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerGroupQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCustomerGroupQuery,
        GQCustomerGroupQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCustomerGroupQuery,
        GQCustomerGroupQueryVariables
    >(CustomerGroupDocument, baseOptions);
}
export function useCustomerGroupLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCustomerGroupQuery,
        GQCustomerGroupQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCustomerGroupQuery,
        GQCustomerGroupQueryVariables
    >(CustomerGroupDocument, baseOptions);
}
export type CustomerGroupQueryHookResult = ReturnType<
    typeof useCustomerGroupQuery
>;
export type CustomerGroupLazyQueryHookResult = ReturnType<
    typeof useCustomerGroupLazyQuery
>;
export type CustomerGroupQueryResult = ApolloReactCommon.QueryResult<
    GQCustomerGroupQuery,
    GQCustomerGroupQueryVariables
>;
export const CustomerGroupCreateDocument = gql`
    mutation customerGroupCreate($customerGroup: CustomerGroupInput!) {
        customerGroupCreate(customerGroup: $customerGroup) {
            ...customerGroupScreenFields
        }
    }
    ${CustomerGroupScreenFieldsFragmentDoc}
`;
export type GQCustomerGroupCreateMutationFn =
    ApolloReactCommon.MutationFunction<
        GQCustomerGroupCreateMutation,
        GQCustomerGroupCreateMutationVariables
    >;

/**
 * __useCustomerGroupCreateMutation__
 *
 * To run a mutation, you first call `useCustomerGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerGroupCreateMutation, { data, loading, error }] = useCustomerGroupCreateMutation({
 *   variables: {
 *      customerGroup: // value for 'customerGroup'
 *   },
 * });
 */
export function useCustomerGroupCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCustomerGroupCreateMutation,
        GQCustomerGroupCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCustomerGroupCreateMutation,
        GQCustomerGroupCreateMutationVariables
    >(CustomerGroupCreateDocument, baseOptions);
}
export type CustomerGroupCreateMutationHookResult = ReturnType<
    typeof useCustomerGroupCreateMutation
>;
export type CustomerGroupCreateMutationResult =
    ApolloReactCommon.MutationResult<GQCustomerGroupCreateMutation>;
export type CustomerGroupCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCustomerGroupCreateMutation,
        GQCustomerGroupCreateMutationVariables
    >;
export const CustomerGroupSaveDocument = gql`
    mutation customerGroupSave($id: ID!, $customerGroup: CustomerGroupInput!) {
        customerGroupSave(id: $id, customerGroup: $customerGroup) {
            ...customerGroupScreenFields
        }
    }
    ${CustomerGroupScreenFieldsFragmentDoc}
`;
export type GQCustomerGroupSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQCustomerGroupSaveMutation,
    GQCustomerGroupSaveMutationVariables
>;

/**
 * __useCustomerGroupSaveMutation__
 *
 * To run a mutation, you first call `useCustomerGroupSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerGroupSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerGroupSaveMutation, { data, loading, error }] = useCustomerGroupSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      customerGroup: // value for 'customerGroup'
 *   },
 * });
 */
export function useCustomerGroupSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCustomerGroupSaveMutation,
        GQCustomerGroupSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCustomerGroupSaveMutation,
        GQCustomerGroupSaveMutationVariables
    >(CustomerGroupSaveDocument, baseOptions);
}
export type CustomerGroupSaveMutationHookResult = ReturnType<
    typeof useCustomerGroupSaveMutation
>;
export type CustomerGroupSaveMutationResult =
    ApolloReactCommon.MutationResult<GQCustomerGroupSaveMutation>;
export type CustomerGroupSaveMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCustomerGroupSaveMutation,
        GQCustomerGroupSaveMutationVariables
    >;
export const CustomerGroupDeleteDocument = gql`
    mutation customerGroupDelete($id: ID!) {
        customerGroupDelete(id: $id) {
            status
        }
    }
`;
export type GQCustomerGroupDeleteMutationFn =
    ApolloReactCommon.MutationFunction<
        GQCustomerGroupDeleteMutation,
        GQCustomerGroupDeleteMutationVariables
    >;

/**
 * __useCustomerGroupDeleteMutation__
 *
 * To run a mutation, you first call `useCustomerGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerGroupDeleteMutation, { data, loading, error }] = useCustomerGroupDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerGroupDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCustomerGroupDeleteMutation,
        GQCustomerGroupDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCustomerGroupDeleteMutation,
        GQCustomerGroupDeleteMutationVariables
    >(CustomerGroupDeleteDocument, baseOptions);
}
export type CustomerGroupDeleteMutationHookResult = ReturnType<
    typeof useCustomerGroupDeleteMutation
>;
export type CustomerGroupDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQCustomerGroupDeleteMutation>;
export type CustomerGroupDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCustomerGroupDeleteMutation,
        GQCustomerGroupDeleteMutationVariables
    >;
export const CardTransactionDocument = gql`
    query cardTransaction($id: ID!) {
        cardTransaction(id: $id) {
            ...cardTransactionScreenFields
        }
    }
    ${CardTransactionScreenFieldsFragmentDoc}
`;

/**
 * __useCardTransactionQuery__
 *
 * To run a query within a React component, call `useCardTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCardTransactionQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCardTransactionQuery,
        GQCardTransactionQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCardTransactionQuery,
        GQCardTransactionQueryVariables
    >(CardTransactionDocument, baseOptions);
}
export function useCardTransactionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCardTransactionQuery,
        GQCardTransactionQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCardTransactionQuery,
        GQCardTransactionQueryVariables
    >(CardTransactionDocument, baseOptions);
}
export type CardTransactionQueryHookResult = ReturnType<
    typeof useCardTransactionQuery
>;
export type CardTransactionLazyQueryHookResult = ReturnType<
    typeof useCardTransactionLazyQuery
>;
export type CardTransactionQueryResult = ApolloReactCommon.QueryResult<
    GQCardTransactionQuery,
    GQCardTransactionQueryVariables
>;
export const CardTransactionsDocument = gql`
    query cardTransactions($pagination: PaginationInput, $search: SearchInput) {
        cardTransactions(pagination: $pagination, search: $search) {
            data {
                ...cardTransactionScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${CardTransactionScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useCardTransactionsQuery__
 *
 * To run a query within a React component, call `useCardTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardTransactionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCardTransactionsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCardTransactionsQuery,
        GQCardTransactionsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCardTransactionsQuery,
        GQCardTransactionsQueryVariables
    >(CardTransactionsDocument, baseOptions);
}
export function useCardTransactionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCardTransactionsQuery,
        GQCardTransactionsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCardTransactionsQuery,
        GQCardTransactionsQueryVariables
    >(CardTransactionsDocument, baseOptions);
}
export type CardTransactionsQueryHookResult = ReturnType<
    typeof useCardTransactionsQuery
>;
export type CardTransactionsLazyQueryHookResult = ReturnType<
    typeof useCardTransactionsLazyQuery
>;
export type CardTransactionsQueryResult = ApolloReactCommon.QueryResult<
    GQCardTransactionsQuery,
    GQCardTransactionsQueryVariables
>;
export const CashRegistersDocument = gql`
    query cashRegisters($pagination: PaginationInput!, $search: SearchInput!) {
        cashRegisters(pagination: $pagination, search: $search) {
            data {
                ...cashRegister
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${CashRegisterFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useCashRegistersQuery__
 *
 * To run a query within a React component, call `useCashRegistersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashRegistersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashRegistersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCashRegistersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCashRegistersQuery,
        GQCashRegistersQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCashRegistersQuery,
        GQCashRegistersQueryVariables
    >(CashRegistersDocument, baseOptions);
}
export function useCashRegistersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCashRegistersQuery,
        GQCashRegistersQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCashRegistersQuery,
        GQCashRegistersQueryVariables
    >(CashRegistersDocument, baseOptions);
}
export type CashRegistersQueryHookResult = ReturnType<
    typeof useCashRegistersQuery
>;
export type CashRegistersLazyQueryHookResult = ReturnType<
    typeof useCashRegistersLazyQuery
>;
export type CashRegistersQueryResult = ApolloReactCommon.QueryResult<
    GQCashRegistersQuery,
    GQCashRegistersQueryVariables
>;
export const CashRegistersStatusDocument = gql`
    query cashRegistersStatus(
        $pagination: PaginationInput!
        $search: SearchInput!
    ) {
        cashRegistersStatus(pagination: $pagination, search: $search) {
            data {
                id
                name
                device {
                    id
                    lastSeen
                }
                department {
                    id
                    name
                }
                merchant {
                    id
                    name
                    lastNotifySentAt
                }
                lastAppDataSentAt
                status
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useCashRegistersStatusQuery__
 *
 * To run a query within a React component, call `useCashRegistersStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashRegistersStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashRegistersStatusQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCashRegistersStatusQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCashRegistersStatusQuery,
        GQCashRegistersStatusQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCashRegistersStatusQuery,
        GQCashRegistersStatusQueryVariables
    >(CashRegistersStatusDocument, baseOptions);
}
export function useCashRegistersStatusLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCashRegistersStatusQuery,
        GQCashRegistersStatusQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCashRegistersStatusQuery,
        GQCashRegistersStatusQueryVariables
    >(CashRegistersStatusDocument, baseOptions);
}
export type CashRegistersStatusQueryHookResult = ReturnType<
    typeof useCashRegistersStatusQuery
>;
export type CashRegistersStatusLazyQueryHookResult = ReturnType<
    typeof useCashRegistersStatusLazyQuery
>;
export type CashRegistersStatusQueryResult = ApolloReactCommon.QueryResult<
    GQCashRegistersStatusQuery,
    GQCashRegistersStatusQueryVariables
>;
export const CashRegisterEditFormDocument = gql`
    query cashRegisterEditForm($id: ID!) {
        cashRegister(id: $id) {
            ...cashRegister
        }
        departments(pagination: { pageSize: 999999 }) {
            data {
                id
                name
            }
        }
    }
    ${CashRegisterFragmentDoc}
`;

/**
 * __useCashRegisterEditFormQuery__
 *
 * To run a query within a React component, call `useCashRegisterEditFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashRegisterEditFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashRegisterEditFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCashRegisterEditFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCashRegisterEditFormQuery,
        GQCashRegisterEditFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCashRegisterEditFormQuery,
        GQCashRegisterEditFormQueryVariables
    >(CashRegisterEditFormDocument, baseOptions);
}
export function useCashRegisterEditFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCashRegisterEditFormQuery,
        GQCashRegisterEditFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCashRegisterEditFormQuery,
        GQCashRegisterEditFormQueryVariables
    >(CashRegisterEditFormDocument, baseOptions);
}
export type CashRegisterEditFormQueryHookResult = ReturnType<
    typeof useCashRegisterEditFormQuery
>;
export type CashRegisterEditFormLazyQueryHookResult = ReturnType<
    typeof useCashRegisterEditFormLazyQuery
>;
export type CashRegisterEditFormQueryResult = ApolloReactCommon.QueryResult<
    GQCashRegisterEditFormQuery,
    GQCashRegisterEditFormQueryVariables
>;
export const CashRegisterCreateDocument = gql`
    mutation cashRegisterCreate($cashRegister: CashRegisterCreateInput!) {
        cashRegisterCreate(cashRegister: $cashRegister) {
            ...cashRegister
        }
    }
    ${CashRegisterFragmentDoc}
`;
export type GQCashRegisterCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQCashRegisterCreateMutation,
    GQCashRegisterCreateMutationVariables
>;

/**
 * __useCashRegisterCreateMutation__
 *
 * To run a mutation, you first call `useCashRegisterCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCashRegisterCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cashRegisterCreateMutation, { data, loading, error }] = useCashRegisterCreateMutation({
 *   variables: {
 *      cashRegister: // value for 'cashRegister'
 *   },
 * });
 */
export function useCashRegisterCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCashRegisterCreateMutation,
        GQCashRegisterCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCashRegisterCreateMutation,
        GQCashRegisterCreateMutationVariables
    >(CashRegisterCreateDocument, baseOptions);
}
export type CashRegisterCreateMutationHookResult = ReturnType<
    typeof useCashRegisterCreateMutation
>;
export type CashRegisterCreateMutationResult =
    ApolloReactCommon.MutationResult<GQCashRegisterCreateMutation>;
export type CashRegisterCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCashRegisterCreateMutation,
        GQCashRegisterCreateMutationVariables
    >;
export const CashRegisterEditDocument = gql`
    mutation cashRegisterEdit($id: ID!, $cashRegister: CashRegisterEditInput!) {
        cashRegisterEdit(id: $id, cashRegister: $cashRegister) {
            ...cashRegister
        }
    }
    ${CashRegisterFragmentDoc}
`;
export type GQCashRegisterEditMutationFn = ApolloReactCommon.MutationFunction<
    GQCashRegisterEditMutation,
    GQCashRegisterEditMutationVariables
>;

/**
 * __useCashRegisterEditMutation__
 *
 * To run a mutation, you first call `useCashRegisterEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCashRegisterEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cashRegisterEditMutation, { data, loading, error }] = useCashRegisterEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      cashRegister: // value for 'cashRegister'
 *   },
 * });
 */
export function useCashRegisterEditMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCashRegisterEditMutation,
        GQCashRegisterEditMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCashRegisterEditMutation,
        GQCashRegisterEditMutationVariables
    >(CashRegisterEditDocument, baseOptions);
}
export type CashRegisterEditMutationHookResult = ReturnType<
    typeof useCashRegisterEditMutation
>;
export type CashRegisterEditMutationResult =
    ApolloReactCommon.MutationResult<GQCashRegisterEditMutation>;
export type CashRegisterEditMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCashRegisterEditMutation,
        GQCashRegisterEditMutationVariables
    >;
export const CashRegisterDeleteDocument = gql`
    mutation cashRegisterDelete($id: ID!) {
        cashRegisterDelete(id: $id) {
            status
        }
    }
`;
export type GQCashRegisterDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQCashRegisterDeleteMutation,
    GQCashRegisterDeleteMutationVariables
>;

/**
 * __useCashRegisterDeleteMutation__
 *
 * To run a mutation, you first call `useCashRegisterDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCashRegisterDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cashRegisterDeleteMutation, { data, loading, error }] = useCashRegisterDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCashRegisterDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCashRegisterDeleteMutation,
        GQCashRegisterDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCashRegisterDeleteMutation,
        GQCashRegisterDeleteMutationVariables
    >(CashRegisterDeleteDocument, baseOptions);
}
export type CashRegisterDeleteMutationHookResult = ReturnType<
    typeof useCashRegisterDeleteMutation
>;
export type CashRegisterDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQCashRegisterDeleteMutation>;
export type CashRegisterDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCashRegisterDeleteMutation,
        GQCashRegisterDeleteMutationVariables
    >;
export const CashRegistersDeleteDocument = gql`
    mutation cashRegistersDelete($ids: [ID!]!) {
        cashRegistersDelete(ids: $ids) {
            status
        }
    }
`;
export type GQCashRegistersDeleteMutationFn =
    ApolloReactCommon.MutationFunction<
        GQCashRegistersDeleteMutation,
        GQCashRegistersDeleteMutationVariables
    >;

/**
 * __useCashRegistersDeleteMutation__
 *
 * To run a mutation, you first call `useCashRegistersDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCashRegistersDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cashRegistersDeleteMutation, { data, loading, error }] = useCashRegistersDeleteMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCashRegistersDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCashRegistersDeleteMutation,
        GQCashRegistersDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCashRegistersDeleteMutation,
        GQCashRegistersDeleteMutationVariables
    >(CashRegistersDeleteDocument, baseOptions);
}
export type CashRegistersDeleteMutationHookResult = ReturnType<
    typeof useCashRegistersDeleteMutation
>;
export type CashRegistersDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQCashRegistersDeleteMutation>;
export type CashRegistersDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCashRegistersDeleteMutation,
        GQCashRegistersDeleteMutationVariables
    >;
export const CashRegisterClaimDocument = gql`
    mutation cashRegisterClaim($id: ID!, $devicePinCode: String!) {
        cashRegisterClaim(id: $id, devicePinCode: $devicePinCode) {
            ...cashRegister
        }
    }
    ${CashRegisterFragmentDoc}
`;
export type GQCashRegisterClaimMutationFn = ApolloReactCommon.MutationFunction<
    GQCashRegisterClaimMutation,
    GQCashRegisterClaimMutationVariables
>;

/**
 * __useCashRegisterClaimMutation__
 *
 * To run a mutation, you first call `useCashRegisterClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCashRegisterClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cashRegisterClaimMutation, { data, loading, error }] = useCashRegisterClaimMutation({
 *   variables: {
 *      id: // value for 'id'
 *      devicePinCode: // value for 'devicePinCode'
 *   },
 * });
 */
export function useCashRegisterClaimMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCashRegisterClaimMutation,
        GQCashRegisterClaimMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCashRegisterClaimMutation,
        GQCashRegisterClaimMutationVariables
    >(CashRegisterClaimDocument, baseOptions);
}
export type CashRegisterClaimMutationHookResult = ReturnType<
    typeof useCashRegisterClaimMutation
>;
export type CashRegisterClaimMutationResult =
    ApolloReactCommon.MutationResult<GQCashRegisterClaimMutation>;
export type CashRegisterClaimMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCashRegisterClaimMutation,
        GQCashRegisterClaimMutationVariables
    >;
export const DeviceByPinCodeDocument = gql`
    query deviceByPinCode($pinCode: String!) {
        deviceByPinCode(pinCode: $pinCode) {
            id
        }
    }
`;

/**
 * __useDeviceByPinCodeQuery__
 *
 * To run a query within a React component, call `useDeviceByPinCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceByPinCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceByPinCodeQuery({
 *   variables: {
 *      pinCode: // value for 'pinCode'
 *   },
 * });
 */
export function useDeviceByPinCodeQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQDeviceByPinCodeQuery,
        GQDeviceByPinCodeQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQDeviceByPinCodeQuery,
        GQDeviceByPinCodeQueryVariables
    >(DeviceByPinCodeDocument, baseOptions);
}
export function useDeviceByPinCodeLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQDeviceByPinCodeQuery,
        GQDeviceByPinCodeQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQDeviceByPinCodeQuery,
        GQDeviceByPinCodeQueryVariables
    >(DeviceByPinCodeDocument, baseOptions);
}
export type DeviceByPinCodeQueryHookResult = ReturnType<
    typeof useDeviceByPinCodeQuery
>;
export type DeviceByPinCodeLazyQueryHookResult = ReturnType<
    typeof useDeviceByPinCodeLazyQuery
>;
export type DeviceByPinCodeQueryResult = ApolloReactCommon.QueryResult<
    GQDeviceByPinCodeQuery,
    GQDeviceByPinCodeQueryVariables
>;
export const CurrenciesDocument = gql`
    query currencies($pagination: PaginationInput, $search: SearchInput) {
        currencies(pagination: $pagination, search: $search) {
            data {
                ...allCurrencyFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllCurrencyFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCurrenciesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCurrenciesQuery,
        GQCurrenciesQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCurrenciesQuery,
        GQCurrenciesQueryVariables
    >(CurrenciesDocument, baseOptions);
}
export function useCurrenciesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCurrenciesQuery,
        GQCurrenciesQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCurrenciesQuery,
        GQCurrenciesQueryVariables
    >(CurrenciesDocument, baseOptions);
}
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<
    typeof useCurrenciesLazyQuery
>;
export type CurrenciesQueryResult = ApolloReactCommon.QueryResult<
    GQCurrenciesQuery,
    GQCurrenciesQueryVariables
>;
export const CurrencyDocument = gql`
    query currency($id: ID!) {
        currency(id: $id) {
            ...allCurrencyFields
        }
    }
    ${AllCurrencyFieldsFragmentDoc}
`;

/**
 * __useCurrencyQuery__
 *
 * To run a query within a React component, call `useCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCurrencyQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCurrencyQuery,
        GQCurrencyQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQCurrencyQuery, GQCurrencyQueryVariables>(
        CurrencyDocument,
        baseOptions
    );
}
export function useCurrencyLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCurrencyQuery,
        GQCurrencyQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCurrencyQuery,
        GQCurrencyQueryVariables
    >(CurrencyDocument, baseOptions);
}
export type CurrencyQueryHookResult = ReturnType<typeof useCurrencyQuery>;
export type CurrencyLazyQueryHookResult = ReturnType<
    typeof useCurrencyLazyQuery
>;
export type CurrencyQueryResult = ApolloReactCommon.QueryResult<
    GQCurrencyQuery,
    GQCurrencyQueryVariables
>;
export const CurrencyFormDocument = gql`
    query currencyForm {
        products(pagination: { pageSize: 999999 }, includeChildren: true) {
            data {
                id
                name
            }
        }
    }
`;

/**
 * __useCurrencyFormQuery__
 *
 * To run a query within a React component, call `useCurrencyFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrencyFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrencyFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrencyFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQCurrencyFormQuery,
        GQCurrencyFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQCurrencyFormQuery,
        GQCurrencyFormQueryVariables
    >(CurrencyFormDocument, baseOptions);
}
export function useCurrencyFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQCurrencyFormQuery,
        GQCurrencyFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQCurrencyFormQuery,
        GQCurrencyFormQueryVariables
    >(CurrencyFormDocument, baseOptions);
}
export type CurrencyFormQueryHookResult = ReturnType<
    typeof useCurrencyFormQuery
>;
export type CurrencyFormLazyQueryHookResult = ReturnType<
    typeof useCurrencyFormLazyQuery
>;
export type CurrencyFormQueryResult = ApolloReactCommon.QueryResult<
    GQCurrencyFormQuery,
    GQCurrencyFormQueryVariables
>;
export const CurrencyCreateDocument = gql`
    mutation currencyCreate($currency: CurrencyInput!) {
        currencyCreate(currency: $currency) {
            ...allCurrencyFields
        }
    }
    ${AllCurrencyFieldsFragmentDoc}
`;
export type GQCurrencyCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQCurrencyCreateMutation,
    GQCurrencyCreateMutationVariables
>;

/**
 * __useCurrencyCreateMutation__
 *
 * To run a mutation, you first call `useCurrencyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCurrencyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [currencyCreateMutation, { data, loading, error }] = useCurrencyCreateMutation({
 *   variables: {
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useCurrencyCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCurrencyCreateMutation,
        GQCurrencyCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCurrencyCreateMutation,
        GQCurrencyCreateMutationVariables
    >(CurrencyCreateDocument, baseOptions);
}
export type CurrencyCreateMutationHookResult = ReturnType<
    typeof useCurrencyCreateMutation
>;
export type CurrencyCreateMutationResult =
    ApolloReactCommon.MutationResult<GQCurrencyCreateMutation>;
export type CurrencyCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCurrencyCreateMutation,
        GQCurrencyCreateMutationVariables
    >;
export const CurrencySaveDocument = gql`
    mutation currencySave($id: ID!, $currency: CurrencyInput!) {
        currencySave(id: $id, currency: $currency) {
            ...allCurrencyFields
        }
    }
    ${AllCurrencyFieldsFragmentDoc}
`;
export type GQCurrencySaveMutationFn = ApolloReactCommon.MutationFunction<
    GQCurrencySaveMutation,
    GQCurrencySaveMutationVariables
>;

/**
 * __useCurrencySaveMutation__
 *
 * To run a mutation, you first call `useCurrencySaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCurrencySaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [currencySaveMutation, { data, loading, error }] = useCurrencySaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useCurrencySaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCurrencySaveMutation,
        GQCurrencySaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCurrencySaveMutation,
        GQCurrencySaveMutationVariables
    >(CurrencySaveDocument, baseOptions);
}
export type CurrencySaveMutationHookResult = ReturnType<
    typeof useCurrencySaveMutation
>;
export type CurrencySaveMutationResult =
    ApolloReactCommon.MutationResult<GQCurrencySaveMutation>;
export type CurrencySaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQCurrencySaveMutation,
    GQCurrencySaveMutationVariables
>;
export const CurrencyDeleteDocument = gql`
    mutation currencyDelete($id: ID!) {
        currencyDelete(id: $id) {
            status
        }
    }
`;
export type GQCurrencyDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQCurrencyDeleteMutation,
    GQCurrencyDeleteMutationVariables
>;

/**
 * __useCurrencyDeleteMutation__
 *
 * To run a mutation, you first call `useCurrencyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCurrencyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [currencyDeleteMutation, { data, loading, error }] = useCurrencyDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCurrencyDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQCurrencyDeleteMutation,
        GQCurrencyDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQCurrencyDeleteMutation,
        GQCurrencyDeleteMutationVariables
    >(CurrencyDeleteDocument, baseOptions);
}
export type CurrencyDeleteMutationHookResult = ReturnType<
    typeof useCurrencyDeleteMutation
>;
export type CurrencyDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQCurrencyDeleteMutation>;
export type CurrencyDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQCurrencyDeleteMutation,
        GQCurrencyDeleteMutationVariables
    >;
export const DashboardCashRegistersDocument = gql`
    query dashboardCashRegisters(
        $pagination: PaginationInput!
        $search: SearchInput!
    ) {
        dashboardCashRegisters(pagination: $pagination, search: $search) {
            data {
                id
                name
                device {
                    id
                    osName
                    osVersion
                    appVersion
                    appBuild
                    serialNumber
                    hardwareModel
                    hardwareProducer
                    macAddress
                    language
                    lastSeen
                }
                merchant {
                    id
                    name
                }
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useDashboardCashRegistersQuery__
 *
 * To run a query within a React component, call `useDashboardCashRegistersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCashRegistersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCashRegistersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDashboardCashRegistersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQDashboardCashRegistersQuery,
        GQDashboardCashRegistersQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQDashboardCashRegistersQuery,
        GQDashboardCashRegistersQueryVariables
    >(DashboardCashRegistersDocument, baseOptions);
}
export function useDashboardCashRegistersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQDashboardCashRegistersQuery,
        GQDashboardCashRegistersQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQDashboardCashRegistersQuery,
        GQDashboardCashRegistersQueryVariables
    >(DashboardCashRegistersDocument, baseOptions);
}
export type DashboardCashRegistersQueryHookResult = ReturnType<
    typeof useDashboardCashRegistersQuery
>;
export type DashboardCashRegistersLazyQueryHookResult = ReturnType<
    typeof useDashboardCashRegistersLazyQuery
>;
export type DashboardCashRegistersQueryResult = ApolloReactCommon.QueryResult<
    GQDashboardCashRegistersQuery,
    GQDashboardCashRegistersQueryVariables
>;
export const DashboardCashRegisterStatsDocument = gql`
    query dashboardCashRegisterStats {
        dashboardCashRegisterStats {
            label
            count
        }
    }
`;

/**
 * __useDashboardCashRegisterStatsQuery__
 *
 * To run a query within a React component, call `useDashboardCashRegisterStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCashRegisterStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCashRegisterStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardCashRegisterStatsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQDashboardCashRegisterStatsQuery,
        GQDashboardCashRegisterStatsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQDashboardCashRegisterStatsQuery,
        GQDashboardCashRegisterStatsQueryVariables
    >(DashboardCashRegisterStatsDocument, baseOptions);
}
export function useDashboardCashRegisterStatsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQDashboardCashRegisterStatsQuery,
        GQDashboardCashRegisterStatsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQDashboardCashRegisterStatsQuery,
        GQDashboardCashRegisterStatsQueryVariables
    >(DashboardCashRegisterStatsDocument, baseOptions);
}
export type DashboardCashRegisterStatsQueryHookResult = ReturnType<
    typeof useDashboardCashRegisterStatsQuery
>;
export type DashboardCashRegisterStatsLazyQueryHookResult = ReturnType<
    typeof useDashboardCashRegisterStatsLazyQuery
>;
export type DashboardCashRegisterStatsQueryResult =
    ApolloReactCommon.QueryResult<
        GQDashboardCashRegisterStatsQuery,
        GQDashboardCashRegisterStatsQueryVariables
    >;
export const DashboardInvoiceAmountStatsDocument = gql`
    query dashboardInvoiceAmountStats($from: Date!, $to: Date!) {
        dashboardInvoiceAmountStats(from: $from, to: $to) {
            datetime
            sum
        }
    }
`;

/**
 * __useDashboardInvoiceAmountStatsQuery__
 *
 * To run a query within a React component, call `useDashboardInvoiceAmountStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardInvoiceAmountStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardInvoiceAmountStatsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useDashboardInvoiceAmountStatsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQDashboardInvoiceAmountStatsQuery,
        GQDashboardInvoiceAmountStatsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQDashboardInvoiceAmountStatsQuery,
        GQDashboardInvoiceAmountStatsQueryVariables
    >(DashboardInvoiceAmountStatsDocument, baseOptions);
}
export function useDashboardInvoiceAmountStatsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQDashboardInvoiceAmountStatsQuery,
        GQDashboardInvoiceAmountStatsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQDashboardInvoiceAmountStatsQuery,
        GQDashboardInvoiceAmountStatsQueryVariables
    >(DashboardInvoiceAmountStatsDocument, baseOptions);
}
export type DashboardInvoiceAmountStatsQueryHookResult = ReturnType<
    typeof useDashboardInvoiceAmountStatsQuery
>;
export type DashboardInvoiceAmountStatsLazyQueryHookResult = ReturnType<
    typeof useDashboardInvoiceAmountStatsLazyQuery
>;
export type DashboardInvoiceAmountStatsQueryResult =
    ApolloReactCommon.QueryResult<
        GQDashboardInvoiceAmountStatsQuery,
        GQDashboardInvoiceAmountStatsQueryVariables
    >;
export const DashboardInvoiceAmountPerMonthStatsDocument = gql`
    query dashboardInvoiceAmountPerMonthStats {
        dashboardInvoiceAmountPerMonthStats {
            year
            monthNumber
            sum
        }
    }
`;

/**
 * __useDashboardInvoiceAmountPerMonthStatsQuery__
 *
 * To run a query within a React component, call `useDashboardInvoiceAmountPerMonthStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardInvoiceAmountPerMonthStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardInvoiceAmountPerMonthStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardInvoiceAmountPerMonthStatsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQDashboardInvoiceAmountPerMonthStatsQuery,
        GQDashboardInvoiceAmountPerMonthStatsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQDashboardInvoiceAmountPerMonthStatsQuery,
        GQDashboardInvoiceAmountPerMonthStatsQueryVariables
    >(DashboardInvoiceAmountPerMonthStatsDocument, baseOptions);
}
export function useDashboardInvoiceAmountPerMonthStatsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQDashboardInvoiceAmountPerMonthStatsQuery,
        GQDashboardInvoiceAmountPerMonthStatsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQDashboardInvoiceAmountPerMonthStatsQuery,
        GQDashboardInvoiceAmountPerMonthStatsQueryVariables
    >(DashboardInvoiceAmountPerMonthStatsDocument, baseOptions);
}
export type DashboardInvoiceAmountPerMonthStatsQueryHookResult = ReturnType<
    typeof useDashboardInvoiceAmountPerMonthStatsQuery
>;
export type DashboardInvoiceAmountPerMonthStatsLazyQueryHookResult = ReturnType<
    typeof useDashboardInvoiceAmountPerMonthStatsLazyQuery
>;
export type DashboardInvoiceAmountPerMonthStatsQueryResult =
    ApolloReactCommon.QueryResult<
        GQDashboardInvoiceAmountPerMonthStatsQuery,
        GQDashboardInvoiceAmountPerMonthStatsQueryVariables
    >;
export const SubscribeToDashboardDocument = gql`
    subscription subscribeToDashboard(
        $from: Date!
        $to: Date!
        $departmentIds: [String!]
    ) {
        dashboardUpdated(from: $from, to: $to, departmentIds: $departmentIds) {
            ...allDashboard
        }
    }
    ${AllDashboardFragmentDoc}
`;

/**
 * __useSubscribeToDashboardSubscription__
 *
 * To run a query within a React component, call `useSubscribeToDashboardSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToDashboardSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToDashboardSubscription({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      departmentIds: // value for 'departmentIds'
 *   },
 * });
 */
export function useSubscribeToDashboardSubscription(
    baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
        GQSubscribeToDashboardSubscription,
        GQSubscribeToDashboardSubscriptionVariables
    >
) {
    return ApolloReactHooks.useSubscription<
        GQSubscribeToDashboardSubscription,
        GQSubscribeToDashboardSubscriptionVariables
    >(SubscribeToDashboardDocument, baseOptions);
}
export type SubscribeToDashboardSubscriptionHookResult = ReturnType<
    typeof useSubscribeToDashboardSubscription
>;
export type SubscribeToDashboardSubscriptionResult =
    ApolloReactCommon.SubscriptionResult<GQSubscribeToDashboardSubscription>;
export const DashboardDocument = gql`
    query dashboard($from: Date!, $to: Date!, $departmentIds: [String!]) {
        dashboard(from: $from, to: $to, departmentIds: $departmentIds) {
            ...allDashboard
        }
    }
    ${AllDashboardFragmentDoc}
`;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      departmentIds: // value for 'departmentIds'
 *   },
 * });
 */
export function useDashboardQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQDashboardQuery,
        GQDashboardQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQDashboardQuery,
        GQDashboardQueryVariables
    >(DashboardDocument, baseOptions);
}
export function useDashboardLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQDashboardQuery,
        GQDashboardQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQDashboardQuery,
        GQDashboardQueryVariables
    >(DashboardDocument, baseOptions);
}
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<
    typeof useDashboardLazyQuery
>;
export type DashboardQueryResult = ApolloReactCommon.QueryResult<
    GQDashboardQuery,
    GQDashboardQueryVariables
>;
export const UserRoleConfigurationDocument = gql`
    query userRoleConfiguration(
        $userId: ID!
        $merchantId: ID
        $roleId: ID!
        $type: String!
    ) {
        userRoleConfiguration(
            userId: $userId
            merchantId: $merchantId
            roleId: $roleId
            type: $type
        ) {
            user {
                id
            }
            merchant {
                id
            }
            role {
                id
            }
            type
            data
        }
    }
`;

/**
 * __useUserRoleConfigurationQuery__
 *
 * To run a query within a React component, call `useUserRoleConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleConfigurationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      merchantId: // value for 'merchantId'
 *      roleId: // value for 'roleId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUserRoleConfigurationQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQUserRoleConfigurationQuery,
        GQUserRoleConfigurationQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQUserRoleConfigurationQuery,
        GQUserRoleConfigurationQueryVariables
    >(UserRoleConfigurationDocument, baseOptions);
}
export function useUserRoleConfigurationLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQUserRoleConfigurationQuery,
        GQUserRoleConfigurationQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQUserRoleConfigurationQuery,
        GQUserRoleConfigurationQueryVariables
    >(UserRoleConfigurationDocument, baseOptions);
}
export type UserRoleConfigurationQueryHookResult = ReturnType<
    typeof useUserRoleConfigurationQuery
>;
export type UserRoleConfigurationLazyQueryHookResult = ReturnType<
    typeof useUserRoleConfigurationLazyQuery
>;
export type UserRoleConfigurationQueryResult = ApolloReactCommon.QueryResult<
    GQUserRoleConfigurationQuery,
    GQUserRoleConfigurationQueryVariables
>;
export const UserRoleConfigurationSaveDocument = gql`
    mutation userRoleConfigurationSave(
        $userId: ID!
        $merchantId: ID
        $roleId: ID!
        $type: String!
        $configuration: JSON!
    ) {
        userRoleConfigurationSave(
            userId: $userId
            merchantId: $merchantId
            roleId: $roleId
            type: $type
            configuration: $configuration
        ) {
            status
        }
    }
`;
export type GQUserRoleConfigurationSaveMutationFn =
    ApolloReactCommon.MutationFunction<
        GQUserRoleConfigurationSaveMutation,
        GQUserRoleConfigurationSaveMutationVariables
    >;

/**
 * __useUserRoleConfigurationSaveMutation__
 *
 * To run a mutation, you first call `useUserRoleConfigurationSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRoleConfigurationSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRoleConfigurationSaveMutation, { data, loading, error }] = useUserRoleConfigurationSaveMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      merchantId: // value for 'merchantId'
 *      roleId: // value for 'roleId'
 *      type: // value for 'type'
 *      configuration: // value for 'configuration'
 *   },
 * });
 */
export function useUserRoleConfigurationSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQUserRoleConfigurationSaveMutation,
        GQUserRoleConfigurationSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQUserRoleConfigurationSaveMutation,
        GQUserRoleConfigurationSaveMutationVariables
    >(UserRoleConfigurationSaveDocument, baseOptions);
}
export type UserRoleConfigurationSaveMutationHookResult = ReturnType<
    typeof useUserRoleConfigurationSaveMutation
>;
export type UserRoleConfigurationSaveMutationResult =
    ApolloReactCommon.MutationResult<GQUserRoleConfigurationSaveMutation>;
export type UserRoleConfigurationSaveMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQUserRoleConfigurationSaveMutation,
        GQUserRoleConfigurationSaveMutationVariables
    >;
export const DepartmentsDocument = gql`
    query departments($pagination: PaginationInput, $search: SearchInput) {
        departments(pagination: $pagination, search: $search) {
            data {
                ...allDepartmentFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllDepartmentFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useDepartmentsQuery__
 *
 * To run a query within a React component, call `useDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDepartmentsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQDepartmentsQuery,
        GQDepartmentsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQDepartmentsQuery,
        GQDepartmentsQueryVariables
    >(DepartmentsDocument, baseOptions);
}
export function useDepartmentsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQDepartmentsQuery,
        GQDepartmentsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQDepartmentsQuery,
        GQDepartmentsQueryVariables
    >(DepartmentsDocument, baseOptions);
}
export type DepartmentsQueryHookResult = ReturnType<typeof useDepartmentsQuery>;
export type DepartmentsLazyQueryHookResult = ReturnType<
    typeof useDepartmentsLazyQuery
>;
export type DepartmentsQueryResult = ApolloReactCommon.QueryResult<
    GQDepartmentsQuery,
    GQDepartmentsQueryVariables
>;
export const DepartmentDocument = gql`
    query department($id: ID!) {
        department(id: $id) {
            ...allDepartmentFields
        }
    }
    ${AllDepartmentFieldsFragmentDoc}
`;

/**
 * __useDepartmentQuery__
 *
 * To run a query within a React component, call `useDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDepartmentQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQDepartmentQuery,
        GQDepartmentQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQDepartmentQuery,
        GQDepartmentQueryVariables
    >(DepartmentDocument, baseOptions);
}
export function useDepartmentLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQDepartmentQuery,
        GQDepartmentQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQDepartmentQuery,
        GQDepartmentQueryVariables
    >(DepartmentDocument, baseOptions);
}
export type DepartmentQueryHookResult = ReturnType<typeof useDepartmentQuery>;
export type DepartmentLazyQueryHookResult = ReturnType<
    typeof useDepartmentLazyQuery
>;
export type DepartmentQueryResult = ApolloReactCommon.QueryResult<
    GQDepartmentQuery,
    GQDepartmentQueryVariables
>;
export const DepartmentCreateDocument = gql`
    mutation departmentCreate($department: DepartmentInput!) {
        departmentCreate(department: $department) {
            ...allDepartmentFields
        }
    }
    ${AllDepartmentFieldsFragmentDoc}
`;
export type GQDepartmentCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQDepartmentCreateMutation,
    GQDepartmentCreateMutationVariables
>;

/**
 * __useDepartmentCreateMutation__
 *
 * To run a mutation, you first call `useDepartmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentCreateMutation, { data, loading, error }] = useDepartmentCreateMutation({
 *   variables: {
 *      department: // value for 'department'
 *   },
 * });
 */
export function useDepartmentCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQDepartmentCreateMutation,
        GQDepartmentCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQDepartmentCreateMutation,
        GQDepartmentCreateMutationVariables
    >(DepartmentCreateDocument, baseOptions);
}
export type DepartmentCreateMutationHookResult = ReturnType<
    typeof useDepartmentCreateMutation
>;
export type DepartmentCreateMutationResult =
    ApolloReactCommon.MutationResult<GQDepartmentCreateMutation>;
export type DepartmentCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQDepartmentCreateMutation,
        GQDepartmentCreateMutationVariables
    >;
export const DepartmentSaveDocument = gql`
    mutation departmentSave($id: ID!, $department: DepartmentInput!) {
        departmentSave(id: $id, department: $department) {
            ...allDepartmentFields
        }
    }
    ${AllDepartmentFieldsFragmentDoc}
`;
export type GQDepartmentSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQDepartmentSaveMutation,
    GQDepartmentSaveMutationVariables
>;

/**
 * __useDepartmentSaveMutation__
 *
 * To run a mutation, you first call `useDepartmentSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentSaveMutation, { data, loading, error }] = useDepartmentSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      department: // value for 'department'
 *   },
 * });
 */
export function useDepartmentSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQDepartmentSaveMutation,
        GQDepartmentSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQDepartmentSaveMutation,
        GQDepartmentSaveMutationVariables
    >(DepartmentSaveDocument, baseOptions);
}
export type DepartmentSaveMutationHookResult = ReturnType<
    typeof useDepartmentSaveMutation
>;
export type DepartmentSaveMutationResult =
    ApolloReactCommon.MutationResult<GQDepartmentSaveMutation>;
export type DepartmentSaveMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQDepartmentSaveMutation,
        GQDepartmentSaveMutationVariables
    >;
export const DepartmentDeleteDocument = gql`
    mutation departmentDelete($id: ID!) {
        departmentDelete(id: $id) {
            status
        }
    }
`;
export type GQDepartmentDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQDepartmentDeleteMutation,
    GQDepartmentDeleteMutationVariables
>;

/**
 * __useDepartmentDeleteMutation__
 *
 * To run a mutation, you first call `useDepartmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentDeleteMutation, { data, loading, error }] = useDepartmentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDepartmentDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQDepartmentDeleteMutation,
        GQDepartmentDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQDepartmentDeleteMutation,
        GQDepartmentDeleteMutationVariables
    >(DepartmentDeleteDocument, baseOptions);
}
export type DepartmentDeleteMutationHookResult = ReturnType<
    typeof useDepartmentDeleteMutation
>;
export type DepartmentDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQDepartmentDeleteMutation>;
export type DepartmentDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQDepartmentDeleteMutation,
        GQDepartmentDeleteMutationVariables
    >;
export const DiscountsDocument = gql`
    query discounts($pagination: PaginationInput, $search: SearchInput) {
        discounts(pagination: $pagination, search: $search) {
            data {
                ...allDiscountFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllDiscountFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useDiscountsQuery__
 *
 * To run a query within a React component, call `useDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDiscountsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQDiscountsQuery,
        GQDiscountsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQDiscountsQuery,
        GQDiscountsQueryVariables
    >(DiscountsDocument, baseOptions);
}
export function useDiscountsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQDiscountsQuery,
        GQDiscountsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQDiscountsQuery,
        GQDiscountsQueryVariables
    >(DiscountsDocument, baseOptions);
}
export type DiscountsQueryHookResult = ReturnType<typeof useDiscountsQuery>;
export type DiscountsLazyQueryHookResult = ReturnType<
    typeof useDiscountsLazyQuery
>;
export type DiscountsQueryResult = ApolloReactCommon.QueryResult<
    GQDiscountsQuery,
    GQDiscountsQueryVariables
>;
export const DiscountDocument = gql`
    query discount($id: ID!) {
        discount(id: $id) {
            ...allDiscountFields
        }
    }
    ${AllDiscountFieldsFragmentDoc}
`;

/**
 * __useDiscountQuery__
 *
 * To run a query within a React component, call `useDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscountQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQDiscountQuery,
        GQDiscountQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQDiscountQuery, GQDiscountQueryVariables>(
        DiscountDocument,
        baseOptions
    );
}
export function useDiscountLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQDiscountQuery,
        GQDiscountQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQDiscountQuery,
        GQDiscountQueryVariables
    >(DiscountDocument, baseOptions);
}
export type DiscountQueryHookResult = ReturnType<typeof useDiscountQuery>;
export type DiscountLazyQueryHookResult = ReturnType<
    typeof useDiscountLazyQuery
>;
export type DiscountQueryResult = ApolloReactCommon.QueryResult<
    GQDiscountQuery,
    GQDiscountQueryVariables
>;
export const DiscountFormDocument = gql`
    query discountForm {
        products(
            includeInactive: true
            pagination: { pageSize: 999999, sort: "name", sortDirection: "ASC" }
            includeChildren: true
        ) {
            data {
                id
                name
                active
            }
        }
        tags(
            pagination: { pageSize: 999999, sort: "name", sortDirection: "ASC" }
        ) {
            data {
                id
                identifier
                name
            }
        }
        tagGroups(
            pagination: { pageSize: 999999, sort: "name", sortDirection: "ASC" }
        ) {
            data {
                id
                name
            }
        }
        customers(
            pagination: { pageSize: 999999, sort: "name", sortDirection: "ASC" }
        ) {
            data {
                id
                name
            }
        }
        customerGroups(
            pagination: { pageSize: 999999, sort: "name", sortDirection: "ASC" }
        ) {
            data {
                id
                name
            }
        }
        departments(
            pagination: { pageSize: 999999, sort: "name", sortDirection: "ASC" }
        ) {
            data {
                id
                name
            }
        }
    }
`;

/**
 * __useDiscountFormQuery__
 *
 * To run a query within a React component, call `useDiscountFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useDiscountFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQDiscountFormQuery,
        GQDiscountFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQDiscountFormQuery,
        GQDiscountFormQueryVariables
    >(DiscountFormDocument, baseOptions);
}
export function useDiscountFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQDiscountFormQuery,
        GQDiscountFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQDiscountFormQuery,
        GQDiscountFormQueryVariables
    >(DiscountFormDocument, baseOptions);
}
export type DiscountFormQueryHookResult = ReturnType<
    typeof useDiscountFormQuery
>;
export type DiscountFormLazyQueryHookResult = ReturnType<
    typeof useDiscountFormLazyQuery
>;
export type DiscountFormQueryResult = ApolloReactCommon.QueryResult<
    GQDiscountFormQuery,
    GQDiscountFormQueryVariables
>;
export const DiscountCreateDocument = gql`
    mutation discountCreate($discount: DiscountInput!) {
        discountCreate(discount: $discount) {
            ...allDiscountFields
        }
    }
    ${AllDiscountFieldsFragmentDoc}
`;
export type GQDiscountCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQDiscountCreateMutation,
    GQDiscountCreateMutationVariables
>;

/**
 * __useDiscountCreateMutation__
 *
 * To run a mutation, you first call `useDiscountCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscountCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discountCreateMutation, { data, loading, error }] = useDiscountCreateMutation({
 *   variables: {
 *      discount: // value for 'discount'
 *   },
 * });
 */
export function useDiscountCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQDiscountCreateMutation,
        GQDiscountCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQDiscountCreateMutation,
        GQDiscountCreateMutationVariables
    >(DiscountCreateDocument, baseOptions);
}
export type DiscountCreateMutationHookResult = ReturnType<
    typeof useDiscountCreateMutation
>;
export type DiscountCreateMutationResult =
    ApolloReactCommon.MutationResult<GQDiscountCreateMutation>;
export type DiscountCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQDiscountCreateMutation,
        GQDiscountCreateMutationVariables
    >;
export const DiscountSaveDocument = gql`
    mutation discountSave($id: ID!, $discount: DiscountInput!) {
        discountSave(id: $id, discount: $discount) {
            ...allDiscountFields
        }
    }
    ${AllDiscountFieldsFragmentDoc}
`;
export type GQDiscountSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQDiscountSaveMutation,
    GQDiscountSaveMutationVariables
>;

/**
 * __useDiscountSaveMutation__
 *
 * To run a mutation, you first call `useDiscountSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscountSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discountSaveMutation, { data, loading, error }] = useDiscountSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      discount: // value for 'discount'
 *   },
 * });
 */
export function useDiscountSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQDiscountSaveMutation,
        GQDiscountSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQDiscountSaveMutation,
        GQDiscountSaveMutationVariables
    >(DiscountSaveDocument, baseOptions);
}
export type DiscountSaveMutationHookResult = ReturnType<
    typeof useDiscountSaveMutation
>;
export type DiscountSaveMutationResult =
    ApolloReactCommon.MutationResult<GQDiscountSaveMutation>;
export type DiscountSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQDiscountSaveMutation,
    GQDiscountSaveMutationVariables
>;
export const DiscountDeleteDocument = gql`
    mutation discountDelete($id: ID!) {
        discountDelete(id: $id) {
            status
        }
    }
`;
export type GQDiscountDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQDiscountDeleteMutation,
    GQDiscountDeleteMutationVariables
>;

/**
 * __useDiscountDeleteMutation__
 *
 * To run a mutation, you first call `useDiscountDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscountDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discountDeleteMutation, { data, loading, error }] = useDiscountDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscountDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQDiscountDeleteMutation,
        GQDiscountDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQDiscountDeleteMutation,
        GQDiscountDeleteMutationVariables
    >(DiscountDeleteDocument, baseOptions);
}
export type DiscountDeleteMutationHookResult = ReturnType<
    typeof useDiscountDeleteMutation
>;
export type DiscountDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQDiscountDeleteMutation>;
export type DiscountDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQDiscountDeleteMutation,
        GQDiscountDeleteMutationVariables
    >;
export const DiscountsActiveToggleDocument = gql`
    mutation discountsActiveToggle($ids: [ID!]!) {
        discountsActiveToggle(ids: $ids) {
            status
        }
    }
`;
export type GQDiscountsActiveToggleMutationFn =
    ApolloReactCommon.MutationFunction<
        GQDiscountsActiveToggleMutation,
        GQDiscountsActiveToggleMutationVariables
    >;

/**
 * __useDiscountsActiveToggleMutation__
 *
 * To run a mutation, you first call `useDiscountsActiveToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscountsActiveToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discountsActiveToggleMutation, { data, loading, error }] = useDiscountsActiveToggleMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDiscountsActiveToggleMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQDiscountsActiveToggleMutation,
        GQDiscountsActiveToggleMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQDiscountsActiveToggleMutation,
        GQDiscountsActiveToggleMutationVariables
    >(DiscountsActiveToggleDocument, baseOptions);
}
export type DiscountsActiveToggleMutationHookResult = ReturnType<
    typeof useDiscountsActiveToggleMutation
>;
export type DiscountsActiveToggleMutationResult =
    ApolloReactCommon.MutationResult<GQDiscountsActiveToggleMutation>;
export type DiscountsActiveToggleMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQDiscountsActiveToggleMutation,
        GQDiscountsActiveToggleMutationVariables
    >;
export const IntegrationAccountsDocument = gql`
    query integrationAccounts($integrationType: String!) {
        integrationAccounts(integrationType: $integrationType) {
            ...allIntegrationAccountFields
        }
    }
    ${AllIntegrationAccountFieldsFragmentDoc}
`;

/**
 * __useIntegrationAccountsQuery__
 *
 * To run a query within a React component, call `useIntegrationAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationAccountsQuery({
 *   variables: {
 *      integrationType: // value for 'integrationType'
 *   },
 * });
 */
export function useIntegrationAccountsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQIntegrationAccountsQuery,
        GQIntegrationAccountsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQIntegrationAccountsQuery,
        GQIntegrationAccountsQueryVariables
    >(IntegrationAccountsDocument, baseOptions);
}
export function useIntegrationAccountsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQIntegrationAccountsQuery,
        GQIntegrationAccountsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQIntegrationAccountsQuery,
        GQIntegrationAccountsQueryVariables
    >(IntegrationAccountsDocument, baseOptions);
}
export type IntegrationAccountsQueryHookResult = ReturnType<
    typeof useIntegrationAccountsQuery
>;
export type IntegrationAccountsLazyQueryHookResult = ReturnType<
    typeof useIntegrationAccountsLazyQuery
>;
export type IntegrationAccountsQueryResult = ApolloReactCommon.QueryResult<
    GQIntegrationAccountsQuery,
    GQIntegrationAccountsQueryVariables
>;
export const IntegrationsDocument = gql`
    query integrations($pagination: PaginationInput, $search: SearchInput) {
        integrations(pagination: $pagination, search: $search) {
            data {
                ...allIntegrationFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllIntegrationFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useIntegrationsQuery__
 *
 * To run a query within a React component, call `useIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useIntegrationsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQIntegrationsQuery,
        GQIntegrationsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQIntegrationsQuery,
        GQIntegrationsQueryVariables
    >(IntegrationsDocument, baseOptions);
}
export function useIntegrationsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQIntegrationsQuery,
        GQIntegrationsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQIntegrationsQuery,
        GQIntegrationsQueryVariables
    >(IntegrationsDocument, baseOptions);
}
export type IntegrationsQueryHookResult = ReturnType<
    typeof useIntegrationsQuery
>;
export type IntegrationsLazyQueryHookResult = ReturnType<
    typeof useIntegrationsLazyQuery
>;
export type IntegrationsQueryResult = ApolloReactCommon.QueryResult<
    GQIntegrationsQuery,
    GQIntegrationsQueryVariables
>;
export const IntegrationDocument = gql`
    query integration($id: ID!) {
        integration(id: $id) {
            ...allIntegrationFields
        }
    }
    ${AllIntegrationFieldsFragmentDoc}
`;

/**
 * __useIntegrationQuery__
 *
 * To run a query within a React component, call `useIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntegrationQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQIntegrationQuery,
        GQIntegrationQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQIntegrationQuery,
        GQIntegrationQueryVariables
    >(IntegrationDocument, baseOptions);
}
export function useIntegrationLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQIntegrationQuery,
        GQIntegrationQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQIntegrationQuery,
        GQIntegrationQueryVariables
    >(IntegrationDocument, baseOptions);
}
export type IntegrationQueryHookResult = ReturnType<typeof useIntegrationQuery>;
export type IntegrationLazyQueryHookResult = ReturnType<
    typeof useIntegrationLazyQuery
>;
export type IntegrationQueryResult = ApolloReactCommon.QueryResult<
    GQIntegrationQuery,
    GQIntegrationQueryVariables
>;
export const IntegrationCreateDocument = gql`
    mutation integrationCreate($integration: IntegrationCreate!) {
        integrationCreate(integration: $integration) {
            ...allIntegrationFields
        }
    }
    ${AllIntegrationFieldsFragmentDoc}
`;
export type GQIntegrationCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQIntegrationCreateMutation,
    GQIntegrationCreateMutationVariables
>;

/**
 * __useIntegrationCreateMutation__
 *
 * To run a mutation, you first call `useIntegrationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrationCreateMutation, { data, loading, error }] = useIntegrationCreateMutation({
 *   variables: {
 *      integration: // value for 'integration'
 *   },
 * });
 */
export function useIntegrationCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQIntegrationCreateMutation,
        GQIntegrationCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQIntegrationCreateMutation,
        GQIntegrationCreateMutationVariables
    >(IntegrationCreateDocument, baseOptions);
}
export type IntegrationCreateMutationHookResult = ReturnType<
    typeof useIntegrationCreateMutation
>;
export type IntegrationCreateMutationResult =
    ApolloReactCommon.MutationResult<GQIntegrationCreateMutation>;
export type IntegrationCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQIntegrationCreateMutation,
        GQIntegrationCreateMutationVariables
    >;
export const IntegrationSaveDocument = gql`
    mutation integrationSave($id: ID!, $integration: IntegrationInput!) {
        integrationSave(id: $id, integration: $integration) {
            ...allIntegrationFields
        }
    }
    ${AllIntegrationFieldsFragmentDoc}
`;
export type GQIntegrationSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQIntegrationSaveMutation,
    GQIntegrationSaveMutationVariables
>;

/**
 * __useIntegrationSaveMutation__
 *
 * To run a mutation, you first call `useIntegrationSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrationSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrationSaveMutation, { data, loading, error }] = useIntegrationSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      integration: // value for 'integration'
 *   },
 * });
 */
export function useIntegrationSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQIntegrationSaveMutation,
        GQIntegrationSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQIntegrationSaveMutation,
        GQIntegrationSaveMutationVariables
    >(IntegrationSaveDocument, baseOptions);
}
export type IntegrationSaveMutationHookResult = ReturnType<
    typeof useIntegrationSaveMutation
>;
export type IntegrationSaveMutationResult =
    ApolloReactCommon.MutationResult<GQIntegrationSaveMutation>;
export type IntegrationSaveMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQIntegrationSaveMutation,
        GQIntegrationSaveMutationVariables
    >;
export const IntegrationDeleteDocument = gql`
    mutation integrationDelete($id: ID!) {
        integrationDelete(id: $id) {
            status
        }
    }
`;
export type GQIntegrationDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQIntegrationDeleteMutation,
    GQIntegrationDeleteMutationVariables
>;

/**
 * __useIntegrationDeleteMutation__
 *
 * To run a mutation, you first call `useIntegrationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrationDeleteMutation, { data, loading, error }] = useIntegrationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntegrationDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQIntegrationDeleteMutation,
        GQIntegrationDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQIntegrationDeleteMutation,
        GQIntegrationDeleteMutationVariables
    >(IntegrationDeleteDocument, baseOptions);
}
export type IntegrationDeleteMutationHookResult = ReturnType<
    typeof useIntegrationDeleteMutation
>;
export type IntegrationDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQIntegrationDeleteMutation>;
export type IntegrationDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQIntegrationDeleteMutation,
        GQIntegrationDeleteMutationVariables
    >;
export const IntegrationFormDocument = gql`
    query integrationForm {
        integrations(pagination: { pageSize: 999999 }) {
            data {
                id
                type
                active
                name
                apiKey
            }
        }
    }
`;

/**
 * __useIntegrationFormQuery__
 *
 * To run a query within a React component, call `useIntegrationFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQIntegrationFormQuery,
        GQIntegrationFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQIntegrationFormQuery,
        GQIntegrationFormQueryVariables
    >(IntegrationFormDocument, baseOptions);
}
export function useIntegrationFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQIntegrationFormQuery,
        GQIntegrationFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQIntegrationFormQuery,
        GQIntegrationFormQueryVariables
    >(IntegrationFormDocument, baseOptions);
}
export type IntegrationFormQueryHookResult = ReturnType<
    typeof useIntegrationFormQuery
>;
export type IntegrationFormLazyQueryHookResult = ReturnType<
    typeof useIntegrationFormLazyQuery
>;
export type IntegrationFormQueryResult = ApolloReactCommon.QueryResult<
    GQIntegrationFormQuery,
    GQIntegrationFormQueryVariables
>;
export const InvoicesDocument = gql`
    query invoices($pagination: PaginationInput, $search: SearchInput) {
        invoices(pagination: $pagination, search: $search) {
            data {
                ...allInvoiceFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllInvoiceFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useInvoicesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQInvoicesQuery,
        GQInvoicesQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQInvoicesQuery, GQInvoicesQueryVariables>(
        InvoicesDocument,
        baseOptions
    );
}
export function useInvoicesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQInvoicesQuery,
        GQInvoicesQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQInvoicesQuery,
        GQInvoicesQueryVariables
    >(InvoicesDocument, baseOptions);
}
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<
    typeof useInvoicesLazyQuery
>;
export type InvoicesQueryResult = ApolloReactCommon.QueryResult<
    GQInvoicesQuery,
    GQInvoicesQueryVariables
>;
export const InvoiceDocument = gql`
    query invoice($id: ID!) {
        invoice(id: $id) {
            ...allInvoiceFields
        }
    }
    ${AllInvoiceFieldsFragmentDoc}
`;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQInvoiceQuery,
        GQInvoiceQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQInvoiceQuery, GQInvoiceQueryVariables>(
        InvoiceDocument,
        baseOptions
    );
}
export function useInvoiceLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQInvoiceQuery,
        GQInvoiceQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQInvoiceQuery,
        GQInvoiceQueryVariables
    >(InvoiceDocument, baseOptions);
}
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceQueryResult = ApolloReactCommon.QueryResult<
    GQInvoiceQuery,
    GQInvoiceQueryVariables
>;
export const LayoutDocument = gql`
    query layout($id: ID!) {
        layout(id: $id) {
            ...allLayoutFields
        }
    }
    ${AllLayoutFieldsFragmentDoc}
`;

/**
 * __useLayoutQuery__
 *
 * To run a query within a React component, call `useLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLayoutQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQLayoutQuery,
        GQLayoutQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQLayoutQuery, GQLayoutQueryVariables>(
        LayoutDocument,
        baseOptions
    );
}
export function useLayoutLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQLayoutQuery,
        GQLayoutQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<GQLayoutQuery, GQLayoutQueryVariables>(
        LayoutDocument,
        baseOptions
    );
}
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = ApolloReactCommon.QueryResult<
    GQLayoutQuery,
    GQLayoutQueryVariables
>;
export const ProductLayoutsDocument = gql`
    query productLayouts($pagination: PaginationInput, $search: SearchInput) {
        productLayouts(pagination: $pagination, search: $search) {
            data {
                ...allProductLayoutFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllProductLayoutFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useProductLayoutsQuery__
 *
 * To run a query within a React component, call `useProductLayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductLayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductLayoutsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useProductLayoutsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQProductLayoutsQuery,
        GQProductLayoutsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQProductLayoutsQuery,
        GQProductLayoutsQueryVariables
    >(ProductLayoutsDocument, baseOptions);
}
export function useProductLayoutsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQProductLayoutsQuery,
        GQProductLayoutsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQProductLayoutsQuery,
        GQProductLayoutsQueryVariables
    >(ProductLayoutsDocument, baseOptions);
}
export type ProductLayoutsQueryHookResult = ReturnType<
    typeof useProductLayoutsQuery
>;
export type ProductLayoutsLazyQueryHookResult = ReturnType<
    typeof useProductLayoutsLazyQuery
>;
export type ProductLayoutsQueryResult = ApolloReactCommon.QueryResult<
    GQProductLayoutsQuery,
    GQProductLayoutsQueryVariables
>;
export const TableLayoutsDocument = gql`
    query tableLayouts($pagination: PaginationInput, $search: SearchInput) {
        tableLayouts(pagination: $pagination, search: $search) {
            data {
                ...allTableLayoutFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllTableLayoutFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useTableLayoutsQuery__
 *
 * To run a query within a React component, call `useTableLayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableLayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableLayoutsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTableLayoutsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQTableLayoutsQuery,
        GQTableLayoutsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQTableLayoutsQuery,
        GQTableLayoutsQueryVariables
    >(TableLayoutsDocument, baseOptions);
}
export function useTableLayoutsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQTableLayoutsQuery,
        GQTableLayoutsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQTableLayoutsQuery,
        GQTableLayoutsQueryVariables
    >(TableLayoutsDocument, baseOptions);
}
export type TableLayoutsQueryHookResult = ReturnType<
    typeof useTableLayoutsQuery
>;
export type TableLayoutsLazyQueryHookResult = ReturnType<
    typeof useTableLayoutsLazyQuery
>;
export type TableLayoutsQueryResult = ApolloReactCommon.QueryResult<
    GQTableLayoutsQuery,
    GQTableLayoutsQueryVariables
>;
export const LayoutCreateDocument = gql`
    mutation layoutCreate($layout: LayoutCreate!) {
        layoutCreate(layout: $layout) {
            ...allLayoutFields
        }
    }
    ${AllLayoutFieldsFragmentDoc}
`;
export type GQLayoutCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQLayoutCreateMutation,
    GQLayoutCreateMutationVariables
>;

/**
 * __useLayoutCreateMutation__
 *
 * To run a mutation, you first call `useLayoutCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLayoutCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [layoutCreateMutation, { data, loading, error }] = useLayoutCreateMutation({
 *   variables: {
 *      layout: // value for 'layout'
 *   },
 * });
 */
export function useLayoutCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQLayoutCreateMutation,
        GQLayoutCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQLayoutCreateMutation,
        GQLayoutCreateMutationVariables
    >(LayoutCreateDocument, baseOptions);
}
export type LayoutCreateMutationHookResult = ReturnType<
    typeof useLayoutCreateMutation
>;
export type LayoutCreateMutationResult =
    ApolloReactCommon.MutationResult<GQLayoutCreateMutation>;
export type LayoutCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQLayoutCreateMutation,
    GQLayoutCreateMutationVariables
>;
export const LayoutSaveDocument = gql`
    mutation layoutSave($id: ID!, $layout: LayoutSave!) {
        layoutSave(id: $id, layout: $layout) {
            ...allLayoutFields
        }
    }
    ${AllLayoutFieldsFragmentDoc}
`;
export type GQLayoutSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQLayoutSaveMutation,
    GQLayoutSaveMutationVariables
>;

/**
 * __useLayoutSaveMutation__
 *
 * To run a mutation, you first call `useLayoutSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLayoutSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [layoutSaveMutation, { data, loading, error }] = useLayoutSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      layout: // value for 'layout'
 *   },
 * });
 */
export function useLayoutSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQLayoutSaveMutation,
        GQLayoutSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQLayoutSaveMutation,
        GQLayoutSaveMutationVariables
    >(LayoutSaveDocument, baseOptions);
}
export type LayoutSaveMutationHookResult = ReturnType<
    typeof useLayoutSaveMutation
>;
export type LayoutSaveMutationResult =
    ApolloReactCommon.MutationResult<GQLayoutSaveMutation>;
export type LayoutSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQLayoutSaveMutation,
    GQLayoutSaveMutationVariables
>;
export const LayoutDeleteDocument = gql`
    mutation layoutDelete($id: ID!) {
        layoutDelete(id: $id) {
            status
        }
    }
`;
export type GQLayoutDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQLayoutDeleteMutation,
    GQLayoutDeleteMutationVariables
>;

/**
 * __useLayoutDeleteMutation__
 *
 * To run a mutation, you first call `useLayoutDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLayoutDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [layoutDeleteMutation, { data, loading, error }] = useLayoutDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLayoutDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQLayoutDeleteMutation,
        GQLayoutDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQLayoutDeleteMutation,
        GQLayoutDeleteMutationVariables
    >(LayoutDeleteDocument, baseOptions);
}
export type LayoutDeleteMutationHookResult = ReturnType<
    typeof useLayoutDeleteMutation
>;
export type LayoutDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQLayoutDeleteMutation>;
export type LayoutDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQLayoutDeleteMutation,
    GQLayoutDeleteMutationVariables
>;
export const LayoutCopyDocument = gql`
    mutation layoutCopy($id: ID!, $newTitle: String!) {
        layoutCopy(id: $id, newTitle: $newTitle) {
            ...allLayoutFields
        }
    }
    ${AllLayoutFieldsFragmentDoc}
`;
export type GQLayoutCopyMutationFn = ApolloReactCommon.MutationFunction<
    GQLayoutCopyMutation,
    GQLayoutCopyMutationVariables
>;

/**
 * __useLayoutCopyMutation__
 *
 * To run a mutation, you first call `useLayoutCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLayoutCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [layoutCopyMutation, { data, loading, error }] = useLayoutCopyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newTitle: // value for 'newTitle'
 *   },
 * });
 */
export function useLayoutCopyMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQLayoutCopyMutation,
        GQLayoutCopyMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQLayoutCopyMutation,
        GQLayoutCopyMutationVariables
    >(LayoutCopyDocument, baseOptions);
}
export type LayoutCopyMutationHookResult = ReturnType<
    typeof useLayoutCopyMutation
>;
export type LayoutCopyMutationResult =
    ApolloReactCommon.MutationResult<GQLayoutCopyMutation>;
export type LayoutCopyMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQLayoutCopyMutation,
    GQLayoutCopyMutationVariables
>;
export const TableLayoutFormDocument = gql`
    query tableLayoutForm {
        tableLayouts(pagination: { pageSize: 999999 }) {
            data {
                id
                name
            }
        }
    }
`;

/**
 * __useTableLayoutFormQuery__
 *
 * To run a query within a React component, call `useTableLayoutFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableLayoutFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableLayoutFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useTableLayoutFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQTableLayoutFormQuery,
        GQTableLayoutFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQTableLayoutFormQuery,
        GQTableLayoutFormQueryVariables
    >(TableLayoutFormDocument, baseOptions);
}
export function useTableLayoutFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQTableLayoutFormQuery,
        GQTableLayoutFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQTableLayoutFormQuery,
        GQTableLayoutFormQueryVariables
    >(TableLayoutFormDocument, baseOptions);
}
export type TableLayoutFormQueryHookResult = ReturnType<
    typeof useTableLayoutFormQuery
>;
export type TableLayoutFormLazyQueryHookResult = ReturnType<
    typeof useTableLayoutFormLazyQuery
>;
export type TableLayoutFormQueryResult = ApolloReactCommon.QueryResult<
    GQTableLayoutFormQuery,
    GQTableLayoutFormQueryVariables
>;
export const ProductLayoutFormDocument = gql`
    query productLayoutForm {
        productLayouts(pagination: { pageSize: 999999 }) {
            data {
                id
                name
            }
        }
    }
`;

/**
 * __useProductLayoutFormQuery__
 *
 * To run a query within a React component, call `useProductLayoutFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductLayoutFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductLayoutFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductLayoutFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQProductLayoutFormQuery,
        GQProductLayoutFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQProductLayoutFormQuery,
        GQProductLayoutFormQueryVariables
    >(ProductLayoutFormDocument, baseOptions);
}
export function useProductLayoutFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQProductLayoutFormQuery,
        GQProductLayoutFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQProductLayoutFormQuery,
        GQProductLayoutFormQueryVariables
    >(ProductLayoutFormDocument, baseOptions);
}
export type ProductLayoutFormQueryHookResult = ReturnType<
    typeof useProductLayoutFormQuery
>;
export type ProductLayoutFormLazyQueryHookResult = ReturnType<
    typeof useProductLayoutFormLazyQuery
>;
export type ProductLayoutFormQueryResult = ApolloReactCommon.QueryResult<
    GQProductLayoutFormQuery,
    GQProductLayoutFormQueryVariables
>;
export const VenueAccessTicketParametersDocument = gql`
    query venueAccessTicketParameters($eventId: Int) {
        venueAccessTicketParameters(eventId: $eventId) {
            ...allAccessTicketFields
        }
    }
    ${AllAccessTicketFieldsFragmentDoc}
`;

/**
 * __useVenueAccessTicketParametersQuery__
 *
 * To run a query within a React component, call `useVenueAccessTicketParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueAccessTicketParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenueAccessTicketParametersQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useVenueAccessTicketParametersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQVenueAccessTicketParametersQuery,
        GQVenueAccessTicketParametersQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQVenueAccessTicketParametersQuery,
        GQVenueAccessTicketParametersQueryVariables
    >(VenueAccessTicketParametersDocument, baseOptions);
}
export function useVenueAccessTicketParametersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQVenueAccessTicketParametersQuery,
        GQVenueAccessTicketParametersQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQVenueAccessTicketParametersQuery,
        GQVenueAccessTicketParametersQueryVariables
    >(VenueAccessTicketParametersDocument, baseOptions);
}
export type VenueAccessTicketParametersQueryHookResult = ReturnType<
    typeof useVenueAccessTicketParametersQuery
>;
export type VenueAccessTicketParametersLazyQueryHookResult = ReturnType<
    typeof useVenueAccessTicketParametersLazyQuery
>;
export type VenueAccessTicketParametersQueryResult =
    ApolloReactCommon.QueryResult<
        GQVenueAccessTicketParametersQuery,
        GQVenueAccessTicketParametersQueryVariables
    >;
export const MerchantsDocument = gql`
    query merchants($pagination: PaginationInput, $search: SearchInput) {
        merchants(pagination: $pagination, search: $search) {
            data {
                ...allMerchantFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllMerchantFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useMerchantsQuery__
 *
 * To run a query within a React component, call `useMerchantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useMerchantsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQMerchantsQuery,
        GQMerchantsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQMerchantsQuery,
        GQMerchantsQueryVariables
    >(MerchantsDocument, baseOptions);
}
export function useMerchantsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQMerchantsQuery,
        GQMerchantsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQMerchantsQuery,
        GQMerchantsQueryVariables
    >(MerchantsDocument, baseOptions);
}
export type MerchantsQueryHookResult = ReturnType<typeof useMerchantsQuery>;
export type MerchantsLazyQueryHookResult = ReturnType<
    typeof useMerchantsLazyQuery
>;
export type MerchantsQueryResult = ApolloReactCommon.QueryResult<
    GQMerchantsQuery,
    GQMerchantsQueryVariables
>;
export const MerchantDocument = gql`
    query merchant($id: ID!) {
        merchant(id: $id) {
            ...allMerchantFields
        }
    }
    ${AllMerchantFieldsFragmentDoc}
`;

/**
 * __useMerchantQuery__
 *
 * To run a query within a React component, call `useMerchantQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMerchantQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQMerchantQuery,
        GQMerchantQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQMerchantQuery, GQMerchantQueryVariables>(
        MerchantDocument,
        baseOptions
    );
}
export function useMerchantLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQMerchantQuery,
        GQMerchantQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQMerchantQuery,
        GQMerchantQueryVariables
    >(MerchantDocument, baseOptions);
}
export type MerchantQueryHookResult = ReturnType<typeof useMerchantQuery>;
export type MerchantLazyQueryHookResult = ReturnType<
    typeof useMerchantLazyQuery
>;
export type MerchantQueryResult = ApolloReactCommon.QueryResult<
    GQMerchantQuery,
    GQMerchantQueryVariables
>;
export const MerchantCreateDocument = gql`
    mutation merchantCreate($merchant: MerchantCreate!) {
        merchantCreate(merchant: $merchant) {
            ...allMerchantFields
        }
    }
    ${AllMerchantFieldsFragmentDoc}
`;
export type GQMerchantCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQMerchantCreateMutation,
    GQMerchantCreateMutationVariables
>;

/**
 * __useMerchantCreateMutation__
 *
 * To run a mutation, you first call `useMerchantCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMerchantCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [merchantCreateMutation, { data, loading, error }] = useMerchantCreateMutation({
 *   variables: {
 *      merchant: // value for 'merchant'
 *   },
 * });
 */
export function useMerchantCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQMerchantCreateMutation,
        GQMerchantCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQMerchantCreateMutation,
        GQMerchantCreateMutationVariables
    >(MerchantCreateDocument, baseOptions);
}
export type MerchantCreateMutationHookResult = ReturnType<
    typeof useMerchantCreateMutation
>;
export type MerchantCreateMutationResult =
    ApolloReactCommon.MutationResult<GQMerchantCreateMutation>;
export type MerchantCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQMerchantCreateMutation,
        GQMerchantCreateMutationVariables
    >;
export const MerchantSaveDocument = gql`
    mutation merchantSave($id: ID!, $merchant: MerchantInput!) {
        merchantSave(id: $id, merchant: $merchant) {
            ...allMerchantFields
        }
    }
    ${AllMerchantFieldsFragmentDoc}
`;
export type GQMerchantSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQMerchantSaveMutation,
    GQMerchantSaveMutationVariables
>;

/**
 * __useMerchantSaveMutation__
 *
 * To run a mutation, you first call `useMerchantSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMerchantSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [merchantSaveMutation, { data, loading, error }] = useMerchantSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      merchant: // value for 'merchant'
 *   },
 * });
 */
export function useMerchantSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQMerchantSaveMutation,
        GQMerchantSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQMerchantSaveMutation,
        GQMerchantSaveMutationVariables
    >(MerchantSaveDocument, baseOptions);
}
export type MerchantSaveMutationHookResult = ReturnType<
    typeof useMerchantSaveMutation
>;
export type MerchantSaveMutationResult =
    ApolloReactCommon.MutationResult<GQMerchantSaveMutation>;
export type MerchantSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQMerchantSaveMutation,
    GQMerchantSaveMutationVariables
>;
export const MerchantDeleteDocument = gql`
    mutation merchantDelete($id: ID!) {
        merchantDelete(id: $id) {
            status
        }
    }
`;
export type GQMerchantDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQMerchantDeleteMutation,
    GQMerchantDeleteMutationVariables
>;

/**
 * __useMerchantDeleteMutation__
 *
 * To run a mutation, you first call `useMerchantDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMerchantDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [merchantDeleteMutation, { data, loading, error }] = useMerchantDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMerchantDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQMerchantDeleteMutation,
        GQMerchantDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQMerchantDeleteMutation,
        GQMerchantDeleteMutationVariables
    >(MerchantDeleteDocument, baseOptions);
}
export type MerchantDeleteMutationHookResult = ReturnType<
    typeof useMerchantDeleteMutation
>;
export type MerchantDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQMerchantDeleteMutation>;
export type MerchantDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQMerchantDeleteMutation,
        GQMerchantDeleteMutationVariables
    >;
export const MerchantFormDocument = gql`
    query merchantForm {
        merchants(pagination: { pageSize: 999999 }) {
            data {
                id
                name
            }
        }
    }
`;

/**
 * __useMerchantFormQuery__
 *
 * To run a query within a React component, call `useMerchantFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useMerchantFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQMerchantFormQuery,
        GQMerchantFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQMerchantFormQuery,
        GQMerchantFormQueryVariables
    >(MerchantFormDocument, baseOptions);
}
export function useMerchantFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQMerchantFormQuery,
        GQMerchantFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQMerchantFormQuery,
        GQMerchantFormQueryVariables
    >(MerchantFormDocument, baseOptions);
}
export type MerchantFormQueryHookResult = ReturnType<
    typeof useMerchantFormQuery
>;
export type MerchantFormLazyQueryHookResult = ReturnType<
    typeof useMerchantFormLazyQuery
>;
export type MerchantFormQueryResult = ApolloReactCommon.QueryResult<
    GQMerchantFormQuery,
    GQMerchantFormQueryVariables
>;
export const AvailableLoginAsRolesDocument = gql`
    query availableLoginAsRoles($merchantId: ID!) {
        roles(
            pagination: { pageSize: 999999, sort: "name", sortDirection: "ASC" }
            merchantId: $merchantId
        ) {
            data {
                id
                name
            }
        }
    }
`;

/**
 * __useAvailableLoginAsRolesQuery__
 *
 * To run a query within a React component, call `useAvailableLoginAsRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableLoginAsRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableLoginAsRolesQuery({
 *   variables: {
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useAvailableLoginAsRolesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQAvailableLoginAsRolesQuery,
        GQAvailableLoginAsRolesQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQAvailableLoginAsRolesQuery,
        GQAvailableLoginAsRolesQueryVariables
    >(AvailableLoginAsRolesDocument, baseOptions);
}
export function useAvailableLoginAsRolesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQAvailableLoginAsRolesQuery,
        GQAvailableLoginAsRolesQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQAvailableLoginAsRolesQuery,
        GQAvailableLoginAsRolesQueryVariables
    >(AvailableLoginAsRolesDocument, baseOptions);
}
export type AvailableLoginAsRolesQueryHookResult = ReturnType<
    typeof useAvailableLoginAsRolesQuery
>;
export type AvailableLoginAsRolesLazyQueryHookResult = ReturnType<
    typeof useAvailableLoginAsRolesLazyQuery
>;
export type AvailableLoginAsRolesQueryResult = ApolloReactCommon.QueryResult<
    GQAvailableLoginAsRolesQuery,
    GQAvailableLoginAsRolesQueryVariables
>;
export const AllMiscButtonsDocument = gql`
    query allMiscButtons($pagination: PaginationInput, $search: SearchInput) {
        allMiscButtons(pagination: $pagination, search: $search) {
            data {
                ...screenMiscButtonFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${ScreenMiscButtonFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useAllMiscButtonsQuery__
 *
 * To run a query within a React component, call `useAllMiscButtonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMiscButtonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMiscButtonsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAllMiscButtonsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQAllMiscButtonsQuery,
        GQAllMiscButtonsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQAllMiscButtonsQuery,
        GQAllMiscButtonsQueryVariables
    >(AllMiscButtonsDocument, baseOptions);
}
export function useAllMiscButtonsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQAllMiscButtonsQuery,
        GQAllMiscButtonsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQAllMiscButtonsQuery,
        GQAllMiscButtonsQueryVariables
    >(AllMiscButtonsDocument, baseOptions);
}
export type AllMiscButtonsQueryHookResult = ReturnType<
    typeof useAllMiscButtonsQuery
>;
export type AllMiscButtonsLazyQueryHookResult = ReturnType<
    typeof useAllMiscButtonsLazyQuery
>;
export type AllMiscButtonsQueryResult = ApolloReactCommon.QueryResult<
    GQAllMiscButtonsQuery,
    GQAllMiscButtonsQueryVariables
>;
export const MiscButtonsDocument = gql`
    query miscButtons($id: ID!) {
        miscButtons(id: $id) {
            ...screenMiscButtonFields
        }
    }
    ${ScreenMiscButtonFieldsFragmentDoc}
`;

/**
 * __useMiscButtonsQuery__
 *
 * To run a query within a React component, call `useMiscButtonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMiscButtonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMiscButtonsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMiscButtonsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQMiscButtonsQuery,
        GQMiscButtonsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQMiscButtonsQuery,
        GQMiscButtonsQueryVariables
    >(MiscButtonsDocument, baseOptions);
}
export function useMiscButtonsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQMiscButtonsQuery,
        GQMiscButtonsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQMiscButtonsQuery,
        GQMiscButtonsQueryVariables
    >(MiscButtonsDocument, baseOptions);
}
export type MiscButtonsQueryHookResult = ReturnType<typeof useMiscButtonsQuery>;
export type MiscButtonsLazyQueryHookResult = ReturnType<
    typeof useMiscButtonsLazyQuery
>;
export type MiscButtonsQueryResult = ApolloReactCommon.QueryResult<
    GQMiscButtonsQuery,
    GQMiscButtonsQueryVariables
>;
export const MiscButtonsCreateDocument = gql`
    mutation miscButtonsCreate($miscButtons: MiscButtonsInput!) {
        miscButtonsCreate(miscButtons: $miscButtons) {
            ...screenMiscButtonFields
        }
    }
    ${ScreenMiscButtonFieldsFragmentDoc}
`;
export type GQMiscButtonsCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQMiscButtonsCreateMutation,
    GQMiscButtonsCreateMutationVariables
>;

/**
 * __useMiscButtonsCreateMutation__
 *
 * To run a mutation, you first call `useMiscButtonsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMiscButtonsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [miscButtonsCreateMutation, { data, loading, error }] = useMiscButtonsCreateMutation({
 *   variables: {
 *      miscButtons: // value for 'miscButtons'
 *   },
 * });
 */
export function useMiscButtonsCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQMiscButtonsCreateMutation,
        GQMiscButtonsCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQMiscButtonsCreateMutation,
        GQMiscButtonsCreateMutationVariables
    >(MiscButtonsCreateDocument, baseOptions);
}
export type MiscButtonsCreateMutationHookResult = ReturnType<
    typeof useMiscButtonsCreateMutation
>;
export type MiscButtonsCreateMutationResult =
    ApolloReactCommon.MutationResult<GQMiscButtonsCreateMutation>;
export type MiscButtonsCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQMiscButtonsCreateMutation,
        GQMiscButtonsCreateMutationVariables
    >;
export const MiscButtonsSaveDocument = gql`
    mutation miscButtonsSave($id: ID!, $miscButtons: MiscButtonsInput!) {
        miscButtonsSave(id: $id, miscButtons: $miscButtons) {
            ...screenMiscButtonFields
        }
    }
    ${ScreenMiscButtonFieldsFragmentDoc}
`;
export type GQMiscButtonsSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQMiscButtonsSaveMutation,
    GQMiscButtonsSaveMutationVariables
>;

/**
 * __useMiscButtonsSaveMutation__
 *
 * To run a mutation, you first call `useMiscButtonsSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMiscButtonsSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [miscButtonsSaveMutation, { data, loading, error }] = useMiscButtonsSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      miscButtons: // value for 'miscButtons'
 *   },
 * });
 */
export function useMiscButtonsSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQMiscButtonsSaveMutation,
        GQMiscButtonsSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQMiscButtonsSaveMutation,
        GQMiscButtonsSaveMutationVariables
    >(MiscButtonsSaveDocument, baseOptions);
}
export type MiscButtonsSaveMutationHookResult = ReturnType<
    typeof useMiscButtonsSaveMutation
>;
export type MiscButtonsSaveMutationResult =
    ApolloReactCommon.MutationResult<GQMiscButtonsSaveMutation>;
export type MiscButtonsSaveMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQMiscButtonsSaveMutation,
        GQMiscButtonsSaveMutationVariables
    >;
export const MiscButtonsDeleteDocument = gql`
    mutation miscButtonsDelete($id: ID!) {
        miscButtonsDelete(id: $id) {
            status
        }
    }
`;
export type GQMiscButtonsDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQMiscButtonsDeleteMutation,
    GQMiscButtonsDeleteMutationVariables
>;

/**
 * __useMiscButtonsDeleteMutation__
 *
 * To run a mutation, you first call `useMiscButtonsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMiscButtonsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [miscButtonsDeleteMutation, { data, loading, error }] = useMiscButtonsDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMiscButtonsDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQMiscButtonsDeleteMutation,
        GQMiscButtonsDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQMiscButtonsDeleteMutation,
        GQMiscButtonsDeleteMutationVariables
    >(MiscButtonsDeleteDocument, baseOptions);
}
export type MiscButtonsDeleteMutationHookResult = ReturnType<
    typeof useMiscButtonsDeleteMutation
>;
export type MiscButtonsDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQMiscButtonsDeleteMutation>;
export type MiscButtonsDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQMiscButtonsDeleteMutation,
        GQMiscButtonsDeleteMutationVariables
    >;
export const PaymentConfigurationsDocument = gql`
    query paymentConfigurations(
        $pagination: PaginationInput
        $search: SearchInput
    ) {
        paymentConfigurations(pagination: $pagination, search: $search) {
            data {
                ...allPaymentConfigurationFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllPaymentConfigurationFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __usePaymentConfigurationsQuery__
 *
 * To run a query within a React component, call `usePaymentConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentConfigurationsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePaymentConfigurationsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQPaymentConfigurationsQuery,
        GQPaymentConfigurationsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQPaymentConfigurationsQuery,
        GQPaymentConfigurationsQueryVariables
    >(PaymentConfigurationsDocument, baseOptions);
}
export function usePaymentConfigurationsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQPaymentConfigurationsQuery,
        GQPaymentConfigurationsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQPaymentConfigurationsQuery,
        GQPaymentConfigurationsQueryVariables
    >(PaymentConfigurationsDocument, baseOptions);
}
export type PaymentConfigurationsQueryHookResult = ReturnType<
    typeof usePaymentConfigurationsQuery
>;
export type PaymentConfigurationsLazyQueryHookResult = ReturnType<
    typeof usePaymentConfigurationsLazyQuery
>;
export type PaymentConfigurationsQueryResult = ApolloReactCommon.QueryResult<
    GQPaymentConfigurationsQuery,
    GQPaymentConfigurationsQueryVariables
>;
export const PaymentConfigurationDocument = gql`
    query paymentConfiguration($id: ID!) {
        paymentConfiguration(id: $id) {
            ...allPaymentConfigurationFields
        }
    }
    ${AllPaymentConfigurationFieldsFragmentDoc}
`;

/**
 * __usePaymentConfigurationQuery__
 *
 * To run a query within a React component, call `usePaymentConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentConfigurationQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQPaymentConfigurationQuery,
        GQPaymentConfigurationQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQPaymentConfigurationQuery,
        GQPaymentConfigurationQueryVariables
    >(PaymentConfigurationDocument, baseOptions);
}
export function usePaymentConfigurationLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQPaymentConfigurationQuery,
        GQPaymentConfigurationQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQPaymentConfigurationQuery,
        GQPaymentConfigurationQueryVariables
    >(PaymentConfigurationDocument, baseOptions);
}
export type PaymentConfigurationQueryHookResult = ReturnType<
    typeof usePaymentConfigurationQuery
>;
export type PaymentConfigurationLazyQueryHookResult = ReturnType<
    typeof usePaymentConfigurationLazyQuery
>;
export type PaymentConfigurationQueryResult = ApolloReactCommon.QueryResult<
    GQPaymentConfigurationQuery,
    GQPaymentConfigurationQueryVariables
>;
export const PaymentConfigurationCreateDocument = gql`
    mutation paymentConfigurationCreate(
        $paymentConfiguration: PaymentConfigurationInput!
    ) {
        paymentConfigurationCreate(
            paymentConfiguration: $paymentConfiguration
        ) {
            ...allPaymentConfigurationFields
        }
    }
    ${AllPaymentConfigurationFieldsFragmentDoc}
`;
export type GQPaymentConfigurationCreateMutationFn =
    ApolloReactCommon.MutationFunction<
        GQPaymentConfigurationCreateMutation,
        GQPaymentConfigurationCreateMutationVariables
    >;

/**
 * __usePaymentConfigurationCreateMutation__
 *
 * To run a mutation, you first call `usePaymentConfigurationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentConfigurationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentConfigurationCreateMutation, { data, loading, error }] = usePaymentConfigurationCreateMutation({
 *   variables: {
 *      paymentConfiguration: // value for 'paymentConfiguration'
 *   },
 * });
 */
export function usePaymentConfigurationCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQPaymentConfigurationCreateMutation,
        GQPaymentConfigurationCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQPaymentConfigurationCreateMutation,
        GQPaymentConfigurationCreateMutationVariables
    >(PaymentConfigurationCreateDocument, baseOptions);
}
export type PaymentConfigurationCreateMutationHookResult = ReturnType<
    typeof usePaymentConfigurationCreateMutation
>;
export type PaymentConfigurationCreateMutationResult =
    ApolloReactCommon.MutationResult<GQPaymentConfigurationCreateMutation>;
export type PaymentConfigurationCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQPaymentConfigurationCreateMutation,
        GQPaymentConfigurationCreateMutationVariables
    >;
export const PaymentConfigurationSaveDocument = gql`
    mutation paymentConfigurationSave(
        $id: ID!
        $paymentConfiguration: PaymentConfigurationInput!
    ) {
        paymentConfigurationSave(
            id: $id
            paymentConfiguration: $paymentConfiguration
        ) {
            ...allPaymentConfigurationFields
        }
    }
    ${AllPaymentConfigurationFieldsFragmentDoc}
`;
export type GQPaymentConfigurationSaveMutationFn =
    ApolloReactCommon.MutationFunction<
        GQPaymentConfigurationSaveMutation,
        GQPaymentConfigurationSaveMutationVariables
    >;

/**
 * __usePaymentConfigurationSaveMutation__
 *
 * To run a mutation, you first call `usePaymentConfigurationSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentConfigurationSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentConfigurationSaveMutation, { data, loading, error }] = usePaymentConfigurationSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      paymentConfiguration: // value for 'paymentConfiguration'
 *   },
 * });
 */
export function usePaymentConfigurationSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQPaymentConfigurationSaveMutation,
        GQPaymentConfigurationSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQPaymentConfigurationSaveMutation,
        GQPaymentConfigurationSaveMutationVariables
    >(PaymentConfigurationSaveDocument, baseOptions);
}
export type PaymentConfigurationSaveMutationHookResult = ReturnType<
    typeof usePaymentConfigurationSaveMutation
>;
export type PaymentConfigurationSaveMutationResult =
    ApolloReactCommon.MutationResult<GQPaymentConfigurationSaveMutation>;
export type PaymentConfigurationSaveMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQPaymentConfigurationSaveMutation,
        GQPaymentConfigurationSaveMutationVariables
    >;
export const PaymentConfigurationDeleteDocument = gql`
    mutation paymentConfigurationDelete($id: ID!) {
        paymentConfigurationDelete(id: $id) {
            status
        }
    }
`;
export type GQPaymentConfigurationDeleteMutationFn =
    ApolloReactCommon.MutationFunction<
        GQPaymentConfigurationDeleteMutation,
        GQPaymentConfigurationDeleteMutationVariables
    >;

/**
 * __usePaymentConfigurationDeleteMutation__
 *
 * To run a mutation, you first call `usePaymentConfigurationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentConfigurationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentConfigurationDeleteMutation, { data, loading, error }] = usePaymentConfigurationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentConfigurationDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQPaymentConfigurationDeleteMutation,
        GQPaymentConfigurationDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQPaymentConfigurationDeleteMutation,
        GQPaymentConfigurationDeleteMutationVariables
    >(PaymentConfigurationDeleteDocument, baseOptions);
}
export type PaymentConfigurationDeleteMutationHookResult = ReturnType<
    typeof usePaymentConfigurationDeleteMutation
>;
export type PaymentConfigurationDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQPaymentConfigurationDeleteMutation>;
export type PaymentConfigurationDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQPaymentConfigurationDeleteMutation,
        GQPaymentConfigurationDeleteMutationVariables
    >;
export const PaymentConfigurationFormDocument = gql`
    query paymentConfigurationForm {
        paymentMethods(pagination: { pageSize: 999999, sortDirection: "ASC" }) {
            data {
                id
                name
                enabled
            }
        }
    }
`;

/**
 * __usePaymentConfigurationFormQuery__
 *
 * To run a query within a React component, call `usePaymentConfigurationFormQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentConfigurationFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentConfigurationFormQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentConfigurationFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQPaymentConfigurationFormQuery,
        GQPaymentConfigurationFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQPaymentConfigurationFormQuery,
        GQPaymentConfigurationFormQueryVariables
    >(PaymentConfigurationFormDocument, baseOptions);
}
export function usePaymentConfigurationFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQPaymentConfigurationFormQuery,
        GQPaymentConfigurationFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQPaymentConfigurationFormQuery,
        GQPaymentConfigurationFormQueryVariables
    >(PaymentConfigurationFormDocument, baseOptions);
}
export type PaymentConfigurationFormQueryHookResult = ReturnType<
    typeof usePaymentConfigurationFormQuery
>;
export type PaymentConfigurationFormLazyQueryHookResult = ReturnType<
    typeof usePaymentConfigurationFormLazyQuery
>;
export type PaymentConfigurationFormQueryResult = ApolloReactCommon.QueryResult<
    GQPaymentConfigurationFormQuery,
    GQPaymentConfigurationFormQueryVariables
>;
export const PaymentMethodsDocument = gql`
    query paymentMethods($pagination: PaginationInput, $search: SearchInput) {
        paymentMethods(pagination: $pagination, search: $search) {
            data {
                ...allPaymentMethodFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllPaymentMethodFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePaymentMethodsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQPaymentMethodsQuery,
        GQPaymentMethodsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQPaymentMethodsQuery,
        GQPaymentMethodsQueryVariables
    >(PaymentMethodsDocument, baseOptions);
}
export function usePaymentMethodsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQPaymentMethodsQuery,
        GQPaymentMethodsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQPaymentMethodsQuery,
        GQPaymentMethodsQueryVariables
    >(PaymentMethodsDocument, baseOptions);
}
export type PaymentMethodsQueryHookResult = ReturnType<
    typeof usePaymentMethodsQuery
>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<
    typeof usePaymentMethodsLazyQuery
>;
export type PaymentMethodsQueryResult = ApolloReactCommon.QueryResult<
    GQPaymentMethodsQuery,
    GQPaymentMethodsQueryVariables
>;
export const PaymentMethodDocument = gql`
    query paymentMethod($id: ID!) {
        paymentMethod(id: $id) {
            ...allPaymentMethodFields
        }
    }
    ${AllPaymentMethodFieldsFragmentDoc}
`;

/**
 * __usePaymentMethodQuery__
 *
 * To run a query within a React component, call `usePaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentMethodQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQPaymentMethodQuery,
        GQPaymentMethodQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQPaymentMethodQuery,
        GQPaymentMethodQueryVariables
    >(PaymentMethodDocument, baseOptions);
}
export function usePaymentMethodLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQPaymentMethodQuery,
        GQPaymentMethodQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQPaymentMethodQuery,
        GQPaymentMethodQueryVariables
    >(PaymentMethodDocument, baseOptions);
}
export type PaymentMethodQueryHookResult = ReturnType<
    typeof usePaymentMethodQuery
>;
export type PaymentMethodLazyQueryHookResult = ReturnType<
    typeof usePaymentMethodLazyQuery
>;
export type PaymentMethodQueryResult = ApolloReactCommon.QueryResult<
    GQPaymentMethodQuery,
    GQPaymentMethodQueryVariables
>;
export const PaymentMethodCreateDocument = gql`
    mutation paymentMethodCreate($paymentMethod: PaymentMethodInput!) {
        paymentMethodCreate(paymentMethod: $paymentMethod) {
            ...allPaymentMethodFields
        }
    }
    ${AllPaymentMethodFieldsFragmentDoc}
`;
export type GQPaymentMethodCreateMutationFn =
    ApolloReactCommon.MutationFunction<
        GQPaymentMethodCreateMutation,
        GQPaymentMethodCreateMutationVariables
    >;

/**
 * __usePaymentMethodCreateMutation__
 *
 * To run a mutation, you first call `usePaymentMethodCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentMethodCreateMutation, { data, loading, error }] = usePaymentMethodCreateMutation({
 *   variables: {
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function usePaymentMethodCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQPaymentMethodCreateMutation,
        GQPaymentMethodCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQPaymentMethodCreateMutation,
        GQPaymentMethodCreateMutationVariables
    >(PaymentMethodCreateDocument, baseOptions);
}
export type PaymentMethodCreateMutationHookResult = ReturnType<
    typeof usePaymentMethodCreateMutation
>;
export type PaymentMethodCreateMutationResult =
    ApolloReactCommon.MutationResult<GQPaymentMethodCreateMutation>;
export type PaymentMethodCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQPaymentMethodCreateMutation,
        GQPaymentMethodCreateMutationVariables
    >;
export const PaymentMethodSaveDocument = gql`
    mutation paymentMethodSave($id: ID!, $paymentMethod: PaymentMethodInput!) {
        paymentMethodSave(id: $id, paymentMethod: $paymentMethod) {
            ...allPaymentMethodFields
        }
    }
    ${AllPaymentMethodFieldsFragmentDoc}
`;
export type GQPaymentMethodSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQPaymentMethodSaveMutation,
    GQPaymentMethodSaveMutationVariables
>;

/**
 * __usePaymentMethodSaveMutation__
 *
 * To run a mutation, you first call `usePaymentMethodSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentMethodSaveMutation, { data, loading, error }] = usePaymentMethodSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function usePaymentMethodSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQPaymentMethodSaveMutation,
        GQPaymentMethodSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQPaymentMethodSaveMutation,
        GQPaymentMethodSaveMutationVariables
    >(PaymentMethodSaveDocument, baseOptions);
}
export type PaymentMethodSaveMutationHookResult = ReturnType<
    typeof usePaymentMethodSaveMutation
>;
export type PaymentMethodSaveMutationResult =
    ApolloReactCommon.MutationResult<GQPaymentMethodSaveMutation>;
export type PaymentMethodSaveMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQPaymentMethodSaveMutation,
        GQPaymentMethodSaveMutationVariables
    >;
export const PaymentMethodDeleteDocument = gql`
    mutation paymentMethodDelete($id: ID!) {
        paymentMethodDelete(id: $id) {
            status
        }
    }
`;
export type GQPaymentMethodDeleteMutationFn =
    ApolloReactCommon.MutationFunction<
        GQPaymentMethodDeleteMutation,
        GQPaymentMethodDeleteMutationVariables
    >;

/**
 * __usePaymentMethodDeleteMutation__
 *
 * To run a mutation, you first call `usePaymentMethodDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentMethodDeleteMutation, { data, loading, error }] = usePaymentMethodDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentMethodDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQPaymentMethodDeleteMutation,
        GQPaymentMethodDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQPaymentMethodDeleteMutation,
        GQPaymentMethodDeleteMutationVariables
    >(PaymentMethodDeleteDocument, baseOptions);
}
export type PaymentMethodDeleteMutationHookResult = ReturnType<
    typeof usePaymentMethodDeleteMutation
>;
export type PaymentMethodDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQPaymentMethodDeleteMutation>;
export type PaymentMethodDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQPaymentMethodDeleteMutation,
        GQPaymentMethodDeleteMutationVariables
    >;
export const PaymentMethodFormDocument = gql`
    query paymentMethodForm {
        currencies(pagination: { pageSize: 999999 }) {
            data {
                id
                code
            }
        }
    }
`;

/**
 * __usePaymentMethodFormQuery__
 *
 * To run a query within a React component, call `usePaymentMethodFormQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodFormQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentMethodFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQPaymentMethodFormQuery,
        GQPaymentMethodFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQPaymentMethodFormQuery,
        GQPaymentMethodFormQueryVariables
    >(PaymentMethodFormDocument, baseOptions);
}
export function usePaymentMethodFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQPaymentMethodFormQuery,
        GQPaymentMethodFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQPaymentMethodFormQuery,
        GQPaymentMethodFormQueryVariables
    >(PaymentMethodFormDocument, baseOptions);
}
export type PaymentMethodFormQueryHookResult = ReturnType<
    typeof usePaymentMethodFormQuery
>;
export type PaymentMethodFormLazyQueryHookResult = ReturnType<
    typeof usePaymentMethodFormLazyQuery
>;
export type PaymentMethodFormQueryResult = ApolloReactCommon.QueryResult<
    GQPaymentMethodFormQuery,
    GQPaymentMethodFormQueryVariables
>;
export const PrinterDocument = gql`
    query printer($id: ID!) {
        printer(id: $id) {
            ...printerScreenFields
        }
    }
    ${PrinterScreenFieldsFragmentDoc}
`;

/**
 * __usePrinterQuery__
 *
 * To run a query within a React component, call `usePrinterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrinterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrinterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePrinterQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQPrinterQuery,
        GQPrinterQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQPrinterQuery, GQPrinterQueryVariables>(
        PrinterDocument,
        baseOptions
    );
}
export function usePrinterLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQPrinterQuery,
        GQPrinterQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQPrinterQuery,
        GQPrinterQueryVariables
    >(PrinterDocument, baseOptions);
}
export type PrinterQueryHookResult = ReturnType<typeof usePrinterQuery>;
export type PrinterLazyQueryHookResult = ReturnType<typeof usePrinterLazyQuery>;
export type PrinterQueryResult = ApolloReactCommon.QueryResult<
    GQPrinterQuery,
    GQPrinterQueryVariables
>;
export const PrintersDocument = gql`
    query printers($pagination: PaginationInput, $search: SearchInput) {
        printers(pagination: $pagination, search: $search) {
            data {
                ...printerScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${PrinterScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __usePrintersQuery__
 *
 * To run a query within a React component, call `usePrintersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePrintersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQPrintersQuery,
        GQPrintersQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQPrintersQuery, GQPrintersQueryVariables>(
        PrintersDocument,
        baseOptions
    );
}
export function usePrintersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQPrintersQuery,
        GQPrintersQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQPrintersQuery,
        GQPrintersQueryVariables
    >(PrintersDocument, baseOptions);
}
export type PrintersQueryHookResult = ReturnType<typeof usePrintersQuery>;
export type PrintersLazyQueryHookResult = ReturnType<
    typeof usePrintersLazyQuery
>;
export type PrintersQueryResult = ApolloReactCommon.QueryResult<
    GQPrintersQuery,
    GQPrintersQueryVariables
>;
export const PrintersForCashRegisterDocument = gql`
    query printersForCashRegister(
        $pagination: PaginationInput
        $class: String
        $entityId: String
    ) {
        printersForCashRegister(
            pagination: $pagination
            class: $class
            entityId: $entityId
        ) {
            data {
                ...printerScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${PrinterScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __usePrintersForCashRegisterQuery__
 *
 * To run a query within a React component, call `usePrintersForCashRegisterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintersForCashRegisterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintersForCashRegisterQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      class: // value for 'class'
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function usePrintersForCashRegisterQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQPrintersForCashRegisterQuery,
        GQPrintersForCashRegisterQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQPrintersForCashRegisterQuery,
        GQPrintersForCashRegisterQueryVariables
    >(PrintersForCashRegisterDocument, baseOptions);
}
export function usePrintersForCashRegisterLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQPrintersForCashRegisterQuery,
        GQPrintersForCashRegisterQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQPrintersForCashRegisterQuery,
        GQPrintersForCashRegisterQueryVariables
    >(PrintersForCashRegisterDocument, baseOptions);
}
export type PrintersForCashRegisterQueryHookResult = ReturnType<
    typeof usePrintersForCashRegisterQuery
>;
export type PrintersForCashRegisterLazyQueryHookResult = ReturnType<
    typeof usePrintersForCashRegisterLazyQuery
>;
export type PrintersForCashRegisterQueryResult = ApolloReactCommon.QueryResult<
    GQPrintersForCashRegisterQuery,
    GQPrintersForCashRegisterQueryVariables
>;
export const PrinterRegisterDocument = gql`
    mutation printerRegister($printer: PrinterRegister!) {
        printerRegister(printer: $printer) {
            name
            serial
            type
            manufacturer
            class
        }
    }
`;
export type GQPrinterRegisterMutationFn = ApolloReactCommon.MutationFunction<
    GQPrinterRegisterMutation,
    GQPrinterRegisterMutationVariables
>;

/**
 * __usePrinterRegisterMutation__
 *
 * To run a mutation, you first call `usePrinterRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrinterRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printerRegisterMutation, { data, loading, error }] = usePrinterRegisterMutation({
 *   variables: {
 *      printer: // value for 'printer'
 *   },
 * });
 */
export function usePrinterRegisterMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQPrinterRegisterMutation,
        GQPrinterRegisterMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQPrinterRegisterMutation,
        GQPrinterRegisterMutationVariables
    >(PrinterRegisterDocument, baseOptions);
}
export type PrinterRegisterMutationHookResult = ReturnType<
    typeof usePrinterRegisterMutation
>;
export type PrinterRegisterMutationResult =
    ApolloReactCommon.MutationResult<GQPrinterRegisterMutation>;
export type PrinterRegisterMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQPrinterRegisterMutation,
        GQPrinterRegisterMutationVariables
    >;
export const PrinterSaveDocument = gql`
    mutation printerSave($id: ID!, $printer: PrinterSave!) {
        printerSave(id: $id, printer: $printer) {
            id
            name
        }
    }
`;
export type GQPrinterSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQPrinterSaveMutation,
    GQPrinterSaveMutationVariables
>;

/**
 * __usePrinterSaveMutation__
 *
 * To run a mutation, you first call `usePrinterSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrinterSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printerSaveMutation, { data, loading, error }] = usePrinterSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      printer: // value for 'printer'
 *   },
 * });
 */
export function usePrinterSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQPrinterSaveMutation,
        GQPrinterSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQPrinterSaveMutation,
        GQPrinterSaveMutationVariables
    >(PrinterSaveDocument, baseOptions);
}
export type PrinterSaveMutationHookResult = ReturnType<
    typeof usePrinterSaveMutation
>;
export type PrinterSaveMutationResult =
    ApolloReactCommon.MutationResult<GQPrinterSaveMutation>;
export type PrinterSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQPrinterSaveMutation,
    GQPrinterSaveMutationVariables
>;
export const PrinterDeleteDocument = gql`
    mutation printerDelete($id: ID!) {
        printerDelete(id: $id) {
            status
        }
    }
`;
export type GQPrinterDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQPrinterDeleteMutation,
    GQPrinterDeleteMutationVariables
>;

/**
 * __usePrinterDeleteMutation__
 *
 * To run a mutation, you first call `usePrinterDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrinterDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printerDeleteMutation, { data, loading, error }] = usePrinterDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePrinterDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQPrinterDeleteMutation,
        GQPrinterDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQPrinterDeleteMutation,
        GQPrinterDeleteMutationVariables
    >(PrinterDeleteDocument, baseOptions);
}
export type PrinterDeleteMutationHookResult = ReturnType<
    typeof usePrinterDeleteMutation
>;
export type PrinterDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQPrinterDeleteMutation>;
export type PrinterDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQPrinterDeleteMutation,
        GQPrinterDeleteMutationVariables
    >;
export const PrinterFormDocument = gql`
    query printerForm {
        printers(pagination: { pageSize: 99999 }) {
            data {
                id
                name
            }
        }
    }
`;

/**
 * __usePrinterFormQuery__
 *
 * To run a query within a React component, call `usePrinterFormQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrinterFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrinterFormQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrinterFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQPrinterFormQuery,
        GQPrinterFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQPrinterFormQuery,
        GQPrinterFormQueryVariables
    >(PrinterFormDocument, baseOptions);
}
export function usePrinterFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQPrinterFormQuery,
        GQPrinterFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQPrinterFormQuery,
        GQPrinterFormQueryVariables
    >(PrinterFormDocument, baseOptions);
}
export type PrinterFormQueryHookResult = ReturnType<typeof usePrinterFormQuery>;
export type PrinterFormLazyQueryHookResult = ReturnType<
    typeof usePrinterFormLazyQuery
>;
export type PrinterFormQueryResult = ApolloReactCommon.QueryResult<
    GQPrinterFormQuery,
    GQPrinterFormQueryVariables
>;
export const ProductGroupsDocument = gql`
    query productGroups($pagination: PaginationInput, $search: SearchInput) {
        productGroups(pagination: $pagination, search: $search) {
            data {
                ...allProductGroupFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllProductGroupFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useProductGroupsQuery__
 *
 * To run a query within a React component, call `useProductGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductGroupsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useProductGroupsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQProductGroupsQuery,
        GQProductGroupsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQProductGroupsQuery,
        GQProductGroupsQueryVariables
    >(ProductGroupsDocument, baseOptions);
}
export function useProductGroupsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQProductGroupsQuery,
        GQProductGroupsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQProductGroupsQuery,
        GQProductGroupsQueryVariables
    >(ProductGroupsDocument, baseOptions);
}
export type ProductGroupsQueryHookResult = ReturnType<
    typeof useProductGroupsQuery
>;
export type ProductGroupsLazyQueryHookResult = ReturnType<
    typeof useProductGroupsLazyQuery
>;
export type ProductGroupsQueryResult = ApolloReactCommon.QueryResult<
    GQProductGroupsQuery,
    GQProductGroupsQueryVariables
>;
export const ProductGroupDocument = gql`
    query productGroup($id: ID!) {
        productGroup(id: $id) {
            ...allProductGroupFields
        }
    }
    ${AllProductGroupFieldsFragmentDoc}
`;

/**
 * __useProductGroupQuery__
 *
 * To run a query within a React component, call `useProductGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductGroupQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQProductGroupQuery,
        GQProductGroupQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQProductGroupQuery,
        GQProductGroupQueryVariables
    >(ProductGroupDocument, baseOptions);
}
export function useProductGroupLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQProductGroupQuery,
        GQProductGroupQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQProductGroupQuery,
        GQProductGroupQueryVariables
    >(ProductGroupDocument, baseOptions);
}
export type ProductGroupQueryHookResult = ReturnType<
    typeof useProductGroupQuery
>;
export type ProductGroupLazyQueryHookResult = ReturnType<
    typeof useProductGroupLazyQuery
>;
export type ProductGroupQueryResult = ApolloReactCommon.QueryResult<
    GQProductGroupQuery,
    GQProductGroupQueryVariables
>;
export const ProductGroupCreateDocument = gql`
    mutation productGroupCreate($productGroup: ProductGroupInput!) {
        productGroupCreate(productGroup: $productGroup) {
            ...allProductGroupFields
        }
    }
    ${AllProductGroupFieldsFragmentDoc}
`;
export type GQProductGroupCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQProductGroupCreateMutation,
    GQProductGroupCreateMutationVariables
>;

/**
 * __useProductGroupCreateMutation__
 *
 * To run a mutation, you first call `useProductGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productGroupCreateMutation, { data, loading, error }] = useProductGroupCreateMutation({
 *   variables: {
 *      productGroup: // value for 'productGroup'
 *   },
 * });
 */
export function useProductGroupCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQProductGroupCreateMutation,
        GQProductGroupCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQProductGroupCreateMutation,
        GQProductGroupCreateMutationVariables
    >(ProductGroupCreateDocument, baseOptions);
}
export type ProductGroupCreateMutationHookResult = ReturnType<
    typeof useProductGroupCreateMutation
>;
export type ProductGroupCreateMutationResult =
    ApolloReactCommon.MutationResult<GQProductGroupCreateMutation>;
export type ProductGroupCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQProductGroupCreateMutation,
        GQProductGroupCreateMutationVariables
    >;
export const ProductGroupSaveDocument = gql`
    mutation productGroupSave($id: ID!, $productGroup: ProductGroupInput!) {
        productGroupSave(id: $id, productGroup: $productGroup) {
            ...allProductGroupFields
        }
    }
    ${AllProductGroupFieldsFragmentDoc}
`;
export type GQProductGroupSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQProductGroupSaveMutation,
    GQProductGroupSaveMutationVariables
>;

/**
 * __useProductGroupSaveMutation__
 *
 * To run a mutation, you first call `useProductGroupSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductGroupSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productGroupSaveMutation, { data, loading, error }] = useProductGroupSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productGroup: // value for 'productGroup'
 *   },
 * });
 */
export function useProductGroupSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQProductGroupSaveMutation,
        GQProductGroupSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQProductGroupSaveMutation,
        GQProductGroupSaveMutationVariables
    >(ProductGroupSaveDocument, baseOptions);
}
export type ProductGroupSaveMutationHookResult = ReturnType<
    typeof useProductGroupSaveMutation
>;
export type ProductGroupSaveMutationResult =
    ApolloReactCommon.MutationResult<GQProductGroupSaveMutation>;
export type ProductGroupSaveMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQProductGroupSaveMutation,
        GQProductGroupSaveMutationVariables
    >;
export const ProductGroupDeleteDocument = gql`
    mutation productGroupDelete($id: ID!) {
        productGroupDelete(id: $id) {
            status
        }
    }
`;
export type GQProductGroupDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQProductGroupDeleteMutation,
    GQProductGroupDeleteMutationVariables
>;

/**
 * __useProductGroupDeleteMutation__
 *
 * To run a mutation, you first call `useProductGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productGroupDeleteMutation, { data, loading, error }] = useProductGroupDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductGroupDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQProductGroupDeleteMutation,
        GQProductGroupDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQProductGroupDeleteMutation,
        GQProductGroupDeleteMutationVariables
    >(ProductGroupDeleteDocument, baseOptions);
}
export type ProductGroupDeleteMutationHookResult = ReturnType<
    typeof useProductGroupDeleteMutation
>;
export type ProductGroupDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQProductGroupDeleteMutation>;
export type ProductGroupDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQProductGroupDeleteMutation,
        GQProductGroupDeleteMutationVariables
    >;
export const SetInventoryProductQuantityDocument = gql`
    mutation setInventoryProductQuantity(
        $id: ID
        $productId: ID!
        $quantity: Int!
        $note: String
    ) {
        setInventoryProductQuantity(
            id: $id
            productId: $productId
            quantity: $quantity
            note: $note
        ) {
            id
            quantity
        }
    }
`;
export type GQSetInventoryProductQuantityMutationFn =
    ApolloReactCommon.MutationFunction<
        GQSetInventoryProductQuantityMutation,
        GQSetInventoryProductQuantityMutationVariables
    >;

/**
 * __useSetInventoryProductQuantityMutation__
 *
 * To run a mutation, you first call `useSetInventoryProductQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInventoryProductQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInventoryProductQuantityMutation, { data, loading, error }] = useSetInventoryProductQuantityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productId: // value for 'productId'
 *      quantity: // value for 'quantity'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useSetInventoryProductQuantityMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQSetInventoryProductQuantityMutation,
        GQSetInventoryProductQuantityMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQSetInventoryProductQuantityMutation,
        GQSetInventoryProductQuantityMutationVariables
    >(SetInventoryProductQuantityDocument, baseOptions);
}
export type SetInventoryProductQuantityMutationHookResult = ReturnType<
    typeof useSetInventoryProductQuantityMutation
>;
export type SetInventoryProductQuantityMutationResult =
    ApolloReactCommon.MutationResult<GQSetInventoryProductQuantityMutation>;
export type SetInventoryProductQuantityMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQSetInventoryProductQuantityMutation,
        GQSetInventoryProductQuantityMutationVariables
    >;
export const InventoryProductNoteTemplatesDocument = gql`
    query inventoryProductNoteTemplates($pagination: PaginationInput) {
        inventoryProductNoteTemplates(pagination: $pagination) {
            data {
                ...allInventoryProductNoteTemplateFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllInventoryProductNoteTemplateFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useInventoryProductNoteTemplatesQuery__
 *
 * To run a query within a React component, call `useInventoryProductNoteTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryProductNoteTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryProductNoteTemplatesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useInventoryProductNoteTemplatesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQInventoryProductNoteTemplatesQuery,
        GQInventoryProductNoteTemplatesQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQInventoryProductNoteTemplatesQuery,
        GQInventoryProductNoteTemplatesQueryVariables
    >(InventoryProductNoteTemplatesDocument, baseOptions);
}
export function useInventoryProductNoteTemplatesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQInventoryProductNoteTemplatesQuery,
        GQInventoryProductNoteTemplatesQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQInventoryProductNoteTemplatesQuery,
        GQInventoryProductNoteTemplatesQueryVariables
    >(InventoryProductNoteTemplatesDocument, baseOptions);
}
export type InventoryProductNoteTemplatesQueryHookResult = ReturnType<
    typeof useInventoryProductNoteTemplatesQuery
>;
export type InventoryProductNoteTemplatesLazyQueryHookResult = ReturnType<
    typeof useInventoryProductNoteTemplatesLazyQuery
>;
export type InventoryProductNoteTemplatesQueryResult =
    ApolloReactCommon.QueryResult<
        GQInventoryProductNoteTemplatesQuery,
        GQInventoryProductNoteTemplatesQueryVariables
    >;
export const InventoryProductNoteTemplateDocument = gql`
    query inventoryProductNoteTemplate($id: ID!) {
        inventoryProductNoteTemplate(id: $id) {
            ...allInventoryProductNoteTemplateFields
        }
    }
    ${AllInventoryProductNoteTemplateFieldsFragmentDoc}
`;

/**
 * __useInventoryProductNoteTemplateQuery__
 *
 * To run a query within a React component, call `useInventoryProductNoteTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryProductNoteTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryProductNoteTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInventoryProductNoteTemplateQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQInventoryProductNoteTemplateQuery,
        GQInventoryProductNoteTemplateQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQInventoryProductNoteTemplateQuery,
        GQInventoryProductNoteTemplateQueryVariables
    >(InventoryProductNoteTemplateDocument, baseOptions);
}
export function useInventoryProductNoteTemplateLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQInventoryProductNoteTemplateQuery,
        GQInventoryProductNoteTemplateQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQInventoryProductNoteTemplateQuery,
        GQInventoryProductNoteTemplateQueryVariables
    >(InventoryProductNoteTemplateDocument, baseOptions);
}
export type InventoryProductNoteTemplateQueryHookResult = ReturnType<
    typeof useInventoryProductNoteTemplateQuery
>;
export type InventoryProductNoteTemplateLazyQueryHookResult = ReturnType<
    typeof useInventoryProductNoteTemplateLazyQuery
>;
export type InventoryProductNoteTemplateQueryResult =
    ApolloReactCommon.QueryResult<
        GQInventoryProductNoteTemplateQuery,
        GQInventoryProductNoteTemplateQueryVariables
    >;
export const InventoryProductNoteTemplateCreateDocument = gql`
    mutation inventoryProductNoteTemplateCreate(
        $inventoryProductNoteTemplate: InventoryProductNoteTemplateCreate!
    ) {
        inventoryProductNoteTemplateCreate(
            inventoryProductNoteTemplate: $inventoryProductNoteTemplate
        ) {
            ...allInventoryProductNoteTemplateFields
        }
    }
    ${AllInventoryProductNoteTemplateFieldsFragmentDoc}
`;
export type GQInventoryProductNoteTemplateCreateMutationFn =
    ApolloReactCommon.MutationFunction<
        GQInventoryProductNoteTemplateCreateMutation,
        GQInventoryProductNoteTemplateCreateMutationVariables
    >;

/**
 * __useInventoryProductNoteTemplateCreateMutation__
 *
 * To run a mutation, you first call `useInventoryProductNoteTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInventoryProductNoteTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inventoryProductNoteTemplateCreateMutation, { data, loading, error }] = useInventoryProductNoteTemplateCreateMutation({
 *   variables: {
 *      inventoryProductNoteTemplate: // value for 'inventoryProductNoteTemplate'
 *   },
 * });
 */
export function useInventoryProductNoteTemplateCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQInventoryProductNoteTemplateCreateMutation,
        GQInventoryProductNoteTemplateCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQInventoryProductNoteTemplateCreateMutation,
        GQInventoryProductNoteTemplateCreateMutationVariables
    >(InventoryProductNoteTemplateCreateDocument, baseOptions);
}
export type InventoryProductNoteTemplateCreateMutationHookResult = ReturnType<
    typeof useInventoryProductNoteTemplateCreateMutation
>;
export type InventoryProductNoteTemplateCreateMutationResult =
    ApolloReactCommon.MutationResult<GQInventoryProductNoteTemplateCreateMutation>;
export type InventoryProductNoteTemplateCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQInventoryProductNoteTemplateCreateMutation,
        GQInventoryProductNoteTemplateCreateMutationVariables
    >;
export const InventoryProductNoteTemplateSaveDocument = gql`
    mutation inventoryProductNoteTemplateSave(
        $id: ID!
        $inventoryProductNoteTemplate: InventoryProductNoteTemplateInput!
    ) {
        inventoryProductNoteTemplateSave(
            id: $id
            inventoryProductNoteTemplate: $inventoryProductNoteTemplate
        ) {
            ...allInventoryProductNoteTemplateFields
        }
    }
    ${AllInventoryProductNoteTemplateFieldsFragmentDoc}
`;
export type GQInventoryProductNoteTemplateSaveMutationFn =
    ApolloReactCommon.MutationFunction<
        GQInventoryProductNoteTemplateSaveMutation,
        GQInventoryProductNoteTemplateSaveMutationVariables
    >;

/**
 * __useInventoryProductNoteTemplateSaveMutation__
 *
 * To run a mutation, you first call `useInventoryProductNoteTemplateSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInventoryProductNoteTemplateSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inventoryProductNoteTemplateSaveMutation, { data, loading, error }] = useInventoryProductNoteTemplateSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inventoryProductNoteTemplate: // value for 'inventoryProductNoteTemplate'
 *   },
 * });
 */
export function useInventoryProductNoteTemplateSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQInventoryProductNoteTemplateSaveMutation,
        GQInventoryProductNoteTemplateSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQInventoryProductNoteTemplateSaveMutation,
        GQInventoryProductNoteTemplateSaveMutationVariables
    >(InventoryProductNoteTemplateSaveDocument, baseOptions);
}
export type InventoryProductNoteTemplateSaveMutationHookResult = ReturnType<
    typeof useInventoryProductNoteTemplateSaveMutation
>;
export type InventoryProductNoteTemplateSaveMutationResult =
    ApolloReactCommon.MutationResult<GQInventoryProductNoteTemplateSaveMutation>;
export type InventoryProductNoteTemplateSaveMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQInventoryProductNoteTemplateSaveMutation,
        GQInventoryProductNoteTemplateSaveMutationVariables
    >;
export const InventoryProductNoteTemplateDeleteDocument = gql`
    mutation inventoryProductNoteTemplateDelete($id: ID!) {
        inventoryProductNoteTemplateDelete(id: $id) {
            status
        }
    }
`;
export type GQInventoryProductNoteTemplateDeleteMutationFn =
    ApolloReactCommon.MutationFunction<
        GQInventoryProductNoteTemplateDeleteMutation,
        GQInventoryProductNoteTemplateDeleteMutationVariables
    >;

/**
 * __useInventoryProductNoteTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useInventoryProductNoteTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInventoryProductNoteTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inventoryProductNoteTemplateDeleteMutation, { data, loading, error }] = useInventoryProductNoteTemplateDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInventoryProductNoteTemplateDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQInventoryProductNoteTemplateDeleteMutation,
        GQInventoryProductNoteTemplateDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQInventoryProductNoteTemplateDeleteMutation,
        GQInventoryProductNoteTemplateDeleteMutationVariables
    >(InventoryProductNoteTemplateDeleteDocument, baseOptions);
}
export type InventoryProductNoteTemplateDeleteMutationHookResult = ReturnType<
    typeof useInventoryProductNoteTemplateDeleteMutation
>;
export type InventoryProductNoteTemplateDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQInventoryProductNoteTemplateDeleteMutation>;
export type InventoryProductNoteTemplateDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQInventoryProductNoteTemplateDeleteMutation,
        GQInventoryProductNoteTemplateDeleteMutationVariables
    >;
export const ProductsDocument = gql`
    query products(
        $pagination: PaginationInput
        $search: SearchInput
        $includeChildren: Boolean
        $includeInactive: Boolean
    ) {
        products(
            pagination: $pagination
            search: $search
            includeChildren: $includeChildren
            includeInactive: $includeInactive
        ) {
            data {
                ...allProductFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllProductFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *      includeChildren: // value for 'includeChildren'
 *      includeInactive: // value for 'includeInactive'
 *   },
 * });
 */
export function useProductsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQProductsQuery,
        GQProductsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQProductsQuery, GQProductsQueryVariables>(
        ProductsDocument,
        baseOptions
    );
}
export function useProductsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQProductsQuery,
        GQProductsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQProductsQuery,
        GQProductsQueryVariables
    >(ProductsDocument, baseOptions);
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<
    typeof useProductsLazyQuery
>;
export type ProductsQueryResult = ApolloReactCommon.QueryResult<
    GQProductsQuery,
    GQProductsQueryVariables
>;
export const ProductDocument = gql`
    query product($id: ID!) {
        product(id: $id) {
            ...allProductFields
            children(
                pagination: {
                    pageSize: 999999
                    sort: "name"
                    sortDirection: "ASC"
                }
            ) {
                ...productChildrenListFields
            }
        }
    }
    ${AllProductFieldsFragmentDoc}
    ${ProductChildrenListFieldsFragmentDoc}
`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQProductQuery,
        GQProductQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQProductQuery, GQProductQueryVariables>(
        ProductDocument,
        baseOptions
    );
}
export function useProductLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQProductQuery,
        GQProductQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQProductQuery,
        GQProductQueryVariables
    >(ProductDocument, baseOptions);
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = ApolloReactCommon.QueryResult<
    GQProductQuery,
    GQProductQueryVariables
>;
export const ProductCreateDocument = gql`
    mutation productCreate($product: ProductInput!) {
        productCreate(product: $product) {
            ...allProductFields
        }
    }
    ${AllProductFieldsFragmentDoc}
`;
export type GQProductCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQProductCreateMutation,
    GQProductCreateMutationVariables
>;

/**
 * __useProductCreateMutation__
 *
 * To run a mutation, you first call `useProductCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productCreateMutation, { data, loading, error }] = useProductCreateMutation({
 *   variables: {
 *      product: // value for 'product'
 *   },
 * });
 */
export function useProductCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQProductCreateMutation,
        GQProductCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQProductCreateMutation,
        GQProductCreateMutationVariables
    >(ProductCreateDocument, baseOptions);
}
export type ProductCreateMutationHookResult = ReturnType<
    typeof useProductCreateMutation
>;
export type ProductCreateMutationResult =
    ApolloReactCommon.MutationResult<GQProductCreateMutation>;
export type ProductCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQProductCreateMutation,
        GQProductCreateMutationVariables
    >;
export const ProductSaveDocument = gql`
    mutation productSave($id: ID!, $product: ProductInput!) {
        productSave(id: $id, product: $product) {
            ...allProductFields
        }
    }
    ${AllProductFieldsFragmentDoc}
`;
export type GQProductSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQProductSaveMutation,
    GQProductSaveMutationVariables
>;

/**
 * __useProductSaveMutation__
 *
 * To run a mutation, you first call `useProductSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productSaveMutation, { data, loading, error }] = useProductSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useProductSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQProductSaveMutation,
        GQProductSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQProductSaveMutation,
        GQProductSaveMutationVariables
    >(ProductSaveDocument, baseOptions);
}
export type ProductSaveMutationHookResult = ReturnType<
    typeof useProductSaveMutation
>;
export type ProductSaveMutationResult =
    ApolloReactCommon.MutationResult<GQProductSaveMutation>;
export type ProductSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQProductSaveMutation,
    GQProductSaveMutationVariables
>;
export const ProductDeleteDocument = gql`
    mutation productDelete($id: ID!) {
        productDelete(id: $id) {
            status
        }
    }
`;
export type GQProductDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQProductDeleteMutation,
    GQProductDeleteMutationVariables
>;

/**
 * __useProductDeleteMutation__
 *
 * To run a mutation, you first call `useProductDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productDeleteMutation, { data, loading, error }] = useProductDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQProductDeleteMutation,
        GQProductDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQProductDeleteMutation,
        GQProductDeleteMutationVariables
    >(ProductDeleteDocument, baseOptions);
}
export type ProductDeleteMutationHookResult = ReturnType<
    typeof useProductDeleteMutation
>;
export type ProductDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQProductDeleteMutation>;
export type ProductDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQProductDeleteMutation,
        GQProductDeleteMutationVariables
    >;
export const ProductsDeleteDocument = gql`
    mutation productsDelete($ids: [ID!]!) {
        productsDelete(ids: $ids) {
            status
        }
    }
`;
export type GQProductsDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQProductsDeleteMutation,
    GQProductsDeleteMutationVariables
>;

/**
 * __useProductsDeleteMutation__
 *
 * To run a mutation, you first call `useProductsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productsDeleteMutation, { data, loading, error }] = useProductsDeleteMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useProductsDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQProductsDeleteMutation,
        GQProductsDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQProductsDeleteMutation,
        GQProductsDeleteMutationVariables
    >(ProductsDeleteDocument, baseOptions);
}
export type ProductsDeleteMutationHookResult = ReturnType<
    typeof useProductsDeleteMutation
>;
export type ProductsDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQProductsDeleteMutation>;
export type ProductsDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQProductsDeleteMutation,
        GQProductsDeleteMutationVariables
    >;
export const ProductFormDocument = gql`
    query productForm {
        products(includeChildren: true, pagination: { pageSize: 999999 }) {
            data {
                id
                name
            }
        }
        productGroups(pagination: { pageSize: 999999 }) {
            data {
                id
                name
                vat {
                    id
                }
            }
        }
    }
`;

/**
 * __useProductFormQuery__
 *
 * To run a query within a React component, call `useProductFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQProductFormQuery,
        GQProductFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQProductFormQuery,
        GQProductFormQueryVariables
    >(ProductFormDocument, baseOptions);
}
export function useProductFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQProductFormQuery,
        GQProductFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQProductFormQuery,
        GQProductFormQueryVariables
    >(ProductFormDocument, baseOptions);
}
export type ProductFormQueryHookResult = ReturnType<typeof useProductFormQuery>;
export type ProductFormLazyQueryHookResult = ReturnType<
    typeof useProductFormLazyQuery
>;
export type ProductFormQueryResult = ApolloReactCommon.QueryResult<
    GQProductFormQuery,
    GQProductFormQueryVariables
>;
export const ReportFormDocument = gql`
    query reportForm {
        reportParameters {
            ...reportFields
        }
    }
    ${ReportFieldsFragmentDoc}
`;

/**
 * __useReportFormQuery__
 *
 * To run a query within a React component, call `useReportFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQReportFormQuery,
        GQReportFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQReportFormQuery,
        GQReportFormQueryVariables
    >(ReportFormDocument, baseOptions);
}
export function useReportFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQReportFormQuery,
        GQReportFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQReportFormQuery,
        GQReportFormQueryVariables
    >(ReportFormDocument, baseOptions);
}
export type ReportFormQueryHookResult = ReturnType<typeof useReportFormQuery>;
export type ReportFormLazyQueryHookResult = ReturnType<
    typeof useReportFormLazyQuery
>;
export type ReportFormQueryResult = ApolloReactCommon.QueryResult<
    GQReportFormQuery,
    GQReportFormQueryVariables
>;
export const ShiftsDocument = gql`
    query shifts($pagination: PaginationInput, $search: SearchInput) {
        shifts(pagination: $pagination, search: $search) {
            data {
                ...allShiftFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllShiftFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useShiftsQuery__
 *
 * To run a query within a React component, call `useShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useShiftsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQShiftsQuery,
        GQShiftsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQShiftsQuery, GQShiftsQueryVariables>(
        ShiftsDocument,
        baseOptions
    );
}
export function useShiftsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQShiftsQuery,
        GQShiftsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<GQShiftsQuery, GQShiftsQueryVariables>(
        ShiftsDocument,
        baseOptions
    );
}
export type ShiftsQueryHookResult = ReturnType<typeof useShiftsQuery>;
export type ShiftsLazyQueryHookResult = ReturnType<typeof useShiftsLazyQuery>;
export type ShiftsQueryResult = ApolloReactCommon.QueryResult<
    GQShiftsQuery,
    GQShiftsQueryVariables
>;
export const ShiftDocument = gql`
    query shift($id: ID!) {
        shift(id: $id) {
            ...allShiftFields
        }
    }
    ${AllShiftFieldsFragmentDoc}
`;

/**
 * __useShiftQuery__
 *
 * To run a query within a React component, call `useShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShiftQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQShiftQuery,
        GQShiftQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQShiftQuery, GQShiftQueryVariables>(
        ShiftDocument,
        baseOptions
    );
}
export function useShiftLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQShiftQuery,
        GQShiftQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<GQShiftQuery, GQShiftQueryVariables>(
        ShiftDocument,
        baseOptions
    );
}
export type ShiftQueryHookResult = ReturnType<typeof useShiftQuery>;
export type ShiftLazyQueryHookResult = ReturnType<typeof useShiftLazyQuery>;
export type ShiftQueryResult = ApolloReactCommon.QueryResult<
    GQShiftQuery,
    GQShiftQueryVariables
>;
export const TagGroupsDocument = gql`
    query tagGroups($pagination: PaginationInput, $search: SearchInput) {
        tagGroups(pagination: $pagination, search: $search) {
            data {
                ...tagGroupScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${TagGroupScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useTagGroupsQuery__
 *
 * To run a query within a React component, call `useTagGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagGroupsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTagGroupsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQTagGroupsQuery,
        GQTagGroupsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQTagGroupsQuery,
        GQTagGroupsQueryVariables
    >(TagGroupsDocument, baseOptions);
}
export function useTagGroupsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQTagGroupsQuery,
        GQTagGroupsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQTagGroupsQuery,
        GQTagGroupsQueryVariables
    >(TagGroupsDocument, baseOptions);
}
export type TagGroupsQueryHookResult = ReturnType<typeof useTagGroupsQuery>;
export type TagGroupsLazyQueryHookResult = ReturnType<
    typeof useTagGroupsLazyQuery
>;
export type TagGroupsQueryResult = ApolloReactCommon.QueryResult<
    GQTagGroupsQuery,
    GQTagGroupsQueryVariables
>;
export const TagGroupDocument = gql`
    query tagGroup($id: ID!) {
        tagGroup(id: $id) {
            ...tagGroupScreenFields
        }
    }
    ${TagGroupScreenFieldsFragmentDoc}
`;

/**
 * __useTagGroupQuery__
 *
 * To run a query within a React component, call `useTagGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagGroupQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQTagGroupQuery,
        GQTagGroupQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQTagGroupQuery, GQTagGroupQueryVariables>(
        TagGroupDocument,
        baseOptions
    );
}
export function useTagGroupLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQTagGroupQuery,
        GQTagGroupQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQTagGroupQuery,
        GQTagGroupQueryVariables
    >(TagGroupDocument, baseOptions);
}
export type TagGroupQueryHookResult = ReturnType<typeof useTagGroupQuery>;
export type TagGroupLazyQueryHookResult = ReturnType<
    typeof useTagGroupLazyQuery
>;
export type TagGroupQueryResult = ApolloReactCommon.QueryResult<
    GQTagGroupQuery,
    GQTagGroupQueryVariables
>;
export const TagGroupCreateDocument = gql`
    mutation tagGroupCreate($tagGroup: TagGroupInput!) {
        tagGroupCreate(tagGroup: $tagGroup) {
            ...tagGroupScreenFields
        }
    }
    ${TagGroupScreenFieldsFragmentDoc}
`;
export type GQTagGroupCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQTagGroupCreateMutation,
    GQTagGroupCreateMutationVariables
>;

/**
 * __useTagGroupCreateMutation__
 *
 * To run a mutation, you first call `useTagGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagGroupCreateMutation, { data, loading, error }] = useTagGroupCreateMutation({
 *   variables: {
 *      tagGroup: // value for 'tagGroup'
 *   },
 * });
 */
export function useTagGroupCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQTagGroupCreateMutation,
        GQTagGroupCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQTagGroupCreateMutation,
        GQTagGroupCreateMutationVariables
    >(TagGroupCreateDocument, baseOptions);
}
export type TagGroupCreateMutationHookResult = ReturnType<
    typeof useTagGroupCreateMutation
>;
export type TagGroupCreateMutationResult =
    ApolloReactCommon.MutationResult<GQTagGroupCreateMutation>;
export type TagGroupCreateMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQTagGroupCreateMutation,
        GQTagGroupCreateMutationVariables
    >;
export const TagGroupSaveDocument = gql`
    mutation tagGroupSave($id: ID!, $tagGroup: TagGroupInput!) {
        tagGroupSave(id: $id, tagGroup: $tagGroup) {
            ...tagGroupScreenFields
        }
    }
    ${TagGroupScreenFieldsFragmentDoc}
`;
export type GQTagGroupSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQTagGroupSaveMutation,
    GQTagGroupSaveMutationVariables
>;

/**
 * __useTagGroupSaveMutation__
 *
 * To run a mutation, you first call `useTagGroupSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagGroupSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagGroupSaveMutation, { data, loading, error }] = useTagGroupSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tagGroup: // value for 'tagGroup'
 *   },
 * });
 */
export function useTagGroupSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQTagGroupSaveMutation,
        GQTagGroupSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQTagGroupSaveMutation,
        GQTagGroupSaveMutationVariables
    >(TagGroupSaveDocument, baseOptions);
}
export type TagGroupSaveMutationHookResult = ReturnType<
    typeof useTagGroupSaveMutation
>;
export type TagGroupSaveMutationResult =
    ApolloReactCommon.MutationResult<GQTagGroupSaveMutation>;
export type TagGroupSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQTagGroupSaveMutation,
    GQTagGroupSaveMutationVariables
>;
export const TagGroupDeleteDocument = gql`
    mutation tagGroupDelete($id: ID!) {
        tagGroupDelete(id: $id) {
            status
        }
    }
`;
export type GQTagGroupDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQTagGroupDeleteMutation,
    GQTagGroupDeleteMutationVariables
>;

/**
 * __useTagGroupDeleteMutation__
 *
 * To run a mutation, you first call `useTagGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagGroupDeleteMutation, { data, loading, error }] = useTagGroupDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagGroupDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQTagGroupDeleteMutation,
        GQTagGroupDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQTagGroupDeleteMutation,
        GQTagGroupDeleteMutationVariables
    >(TagGroupDeleteDocument, baseOptions);
}
export type TagGroupDeleteMutationHookResult = ReturnType<
    typeof useTagGroupDeleteMutation
>;
export type TagGroupDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQTagGroupDeleteMutation>;
export type TagGroupDeleteMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQTagGroupDeleteMutation,
        GQTagGroupDeleteMutationVariables
    >;
export const TagsDocument = gql`
    query tags(
        $pagination: PaginationInput
        $search: SearchInput
        $tagIds: [ID!]
    ) {
        tags(pagination: $pagination, search: $search, tagIds: $tagIds) {
            data {
                ...tagScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${TagScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useTagsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQTagsQuery,
        GQTagsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQTagsQuery, GQTagsQueryVariables>(
        TagsDocument,
        baseOptions
    );
}
export function useTagsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQTagsQuery,
        GQTagsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<GQTagsQuery, GQTagsQueryVariables>(
        TagsDocument,
        baseOptions
    );
}
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = ApolloReactCommon.QueryResult<
    GQTagsQuery,
    GQTagsQueryVariables
>;
export const TagsWithoutAccountDocument = gql`
    query tagsWithoutAccount(
        $pagination: PaginationInput
        $search: SearchInput
    ) {
        tagsWithoutAccount(pagination: $pagination, search: $search) {
            data {
                ...tagScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${TagScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useTagsWithoutAccountQuery__
 *
 * To run a query within a React component, call `useTagsWithoutAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsWithoutAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsWithoutAccountQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTagsWithoutAccountQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQTagsWithoutAccountQuery,
        GQTagsWithoutAccountQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQTagsWithoutAccountQuery,
        GQTagsWithoutAccountQueryVariables
    >(TagsWithoutAccountDocument, baseOptions);
}
export function useTagsWithoutAccountLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQTagsWithoutAccountQuery,
        GQTagsWithoutAccountQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQTagsWithoutAccountQuery,
        GQTagsWithoutAccountQueryVariables
    >(TagsWithoutAccountDocument, baseOptions);
}
export type TagsWithoutAccountQueryHookResult = ReturnType<
    typeof useTagsWithoutAccountQuery
>;
export type TagsWithoutAccountLazyQueryHookResult = ReturnType<
    typeof useTagsWithoutAccountLazyQuery
>;
export type TagsWithoutAccountQueryResult = ApolloReactCommon.QueryResult<
    GQTagsWithoutAccountQuery,
    GQTagsWithoutAccountQueryVariables
>;
export const TagsWithoutCustomerDocument = gql`
    query tagsWithoutCustomer(
        $pagination: PaginationInput
        $search: SearchInput
    ) {
        tagsWithoutCustomer(pagination: $pagination, search: $search) {
            data {
                ...tagScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${TagScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useTagsWithoutCustomerQuery__
 *
 * To run a query within a React component, call `useTagsWithoutCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsWithoutCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsWithoutCustomerQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTagsWithoutCustomerQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQTagsWithoutCustomerQuery,
        GQTagsWithoutCustomerQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQTagsWithoutCustomerQuery,
        GQTagsWithoutCustomerQueryVariables
    >(TagsWithoutCustomerDocument, baseOptions);
}
export function useTagsWithoutCustomerLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQTagsWithoutCustomerQuery,
        GQTagsWithoutCustomerQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQTagsWithoutCustomerQuery,
        GQTagsWithoutCustomerQueryVariables
    >(TagsWithoutCustomerDocument, baseOptions);
}
export type TagsWithoutCustomerQueryHookResult = ReturnType<
    typeof useTagsWithoutCustomerQuery
>;
export type TagsWithoutCustomerLazyQueryHookResult = ReturnType<
    typeof useTagsWithoutCustomerLazyQuery
>;
export type TagsWithoutCustomerQueryResult = ApolloReactCommon.QueryResult<
    GQTagsWithoutCustomerQuery,
    GQTagsWithoutCustomerQueryVariables
>;
export const TagsByGroupDocument = gql`
    query tagsByGroup(
        $groupId: ID!
        $pagination: PaginationInput
        $search: SearchInput
    ) {
        tagsByGroup(
            groupId: $groupId
            pagination: $pagination
            search: $search
        ) {
            data {
                ...tagScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${TagScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useTagsByGroupQuery__
 *
 * To run a query within a React component, call `useTagsByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsByGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTagsByGroupQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQTagsByGroupQuery,
        GQTagsByGroupQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQTagsByGroupQuery,
        GQTagsByGroupQueryVariables
    >(TagsByGroupDocument, baseOptions);
}
export function useTagsByGroupLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQTagsByGroupQuery,
        GQTagsByGroupQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQTagsByGroupQuery,
        GQTagsByGroupQueryVariables
    >(TagsByGroupDocument, baseOptions);
}
export type TagsByGroupQueryHookResult = ReturnType<typeof useTagsByGroupQuery>;
export type TagsByGroupLazyQueryHookResult = ReturnType<
    typeof useTagsByGroupLazyQuery
>;
export type TagsByGroupQueryResult = ApolloReactCommon.QueryResult<
    GQTagsByGroupQuery,
    GQTagsByGroupQueryVariables
>;
export const TagsByCustomerDocument = gql`
    query tagsByCustomer(
        $customerId: ID!
        $pagination: PaginationInput
        $search: SearchInput
    ) {
        tagsByCustomer(
            customerId: $customerId
            pagination: $pagination
            search: $search
        ) {
            data {
                ...tagScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${TagScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useTagsByCustomerQuery__
 *
 * To run a query within a React component, call `useTagsByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsByCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTagsByCustomerQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQTagsByCustomerQuery,
        GQTagsByCustomerQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQTagsByCustomerQuery,
        GQTagsByCustomerQueryVariables
    >(TagsByCustomerDocument, baseOptions);
}
export function useTagsByCustomerLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQTagsByCustomerQuery,
        GQTagsByCustomerQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQTagsByCustomerQuery,
        GQTagsByCustomerQueryVariables
    >(TagsByCustomerDocument, baseOptions);
}
export type TagsByCustomerQueryHookResult = ReturnType<
    typeof useTagsByCustomerQuery
>;
export type TagsByCustomerLazyQueryHookResult = ReturnType<
    typeof useTagsByCustomerLazyQuery
>;
export type TagsByCustomerQueryResult = ApolloReactCommon.QueryResult<
    GQTagsByCustomerQuery,
    GQTagsByCustomerQueryVariables
>;
export const TagsByAccountDocument = gql`
    query tagsByAccount(
        $accountId: ID!
        $pagination: PaginationInput
        $search: SearchInput
    ) {
        tagsByAccount(
            accountId: $accountId
            pagination: $pagination
            search: $search
        ) {
            data {
                ...tagScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${TagScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useTagsByAccountQuery__
 *
 * To run a query within a React component, call `useTagsByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsByAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTagsByAccountQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQTagsByAccountQuery,
        GQTagsByAccountQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQTagsByAccountQuery,
        GQTagsByAccountQueryVariables
    >(TagsByAccountDocument, baseOptions);
}
export function useTagsByAccountLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQTagsByAccountQuery,
        GQTagsByAccountQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQTagsByAccountQuery,
        GQTagsByAccountQueryVariables
    >(TagsByAccountDocument, baseOptions);
}
export type TagsByAccountQueryHookResult = ReturnType<
    typeof useTagsByAccountQuery
>;
export type TagsByAccountLazyQueryHookResult = ReturnType<
    typeof useTagsByAccountLazyQuery
>;
export type TagsByAccountQueryResult = ApolloReactCommon.QueryResult<
    GQTagsByAccountQuery,
    GQTagsByAccountQueryVariables
>;
export const TagDocument = gql`
    query tag($id: ID!) {
        tag(id: $id) {
            ...tagScreenFields
        }
    }
    ${TagScreenFieldsFragmentDoc}
`;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQTagQuery,
        GQTagQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQTagQuery, GQTagQueryVariables>(
        TagDocument,
        baseOptions
    );
}
export function useTagLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQTagQuery,
        GQTagQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<GQTagQuery, GQTagQueryVariables>(
        TagDocument,
        baseOptions
    );
}
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = ApolloReactCommon.QueryResult<
    GQTagQuery,
    GQTagQueryVariables
>;
export const TagCreateDocument = gql`
    mutation tagCreate($tag: TagInput!) {
        tagCreate(tag: $tag) {
            ...tagScreenFields
        }
    }
    ${TagScreenFieldsFragmentDoc}
`;
export type GQTagCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQTagCreateMutation,
    GQTagCreateMutationVariables
>;

/**
 * __useTagCreateMutation__
 *
 * To run a mutation, you first call `useTagCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagCreateMutation, { data, loading, error }] = useTagCreateMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useTagCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQTagCreateMutation,
        GQTagCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQTagCreateMutation,
        GQTagCreateMutationVariables
    >(TagCreateDocument, baseOptions);
}
export type TagCreateMutationHookResult = ReturnType<
    typeof useTagCreateMutation
>;
export type TagCreateMutationResult =
    ApolloReactCommon.MutationResult<GQTagCreateMutation>;
export type TagCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQTagCreateMutation,
    GQTagCreateMutationVariables
>;
export const TagSaveDocument = gql`
    mutation tagSave($id: ID!, $tag: TagInput!) {
        tagSave(id: $id, tag: $tag) {
            ...tagScreenFields
        }
    }
    ${TagScreenFieldsFragmentDoc}
`;
export type GQTagSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQTagSaveMutation,
    GQTagSaveMutationVariables
>;

/**
 * __useTagSaveMutation__
 *
 * To run a mutation, you first call `useTagSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagSaveMutation, { data, loading, error }] = useTagSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useTagSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQTagSaveMutation,
        GQTagSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQTagSaveMutation,
        GQTagSaveMutationVariables
    >(TagSaveDocument, baseOptions);
}
export type TagSaveMutationHookResult = ReturnType<typeof useTagSaveMutation>;
export type TagSaveMutationResult =
    ApolloReactCommon.MutationResult<GQTagSaveMutation>;
export type TagSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQTagSaveMutation,
    GQTagSaveMutationVariables
>;
export const TagDeleteDocument = gql`
    mutation tagDelete($id: ID!) {
        tagDelete(id: $id) {
            status
        }
    }
`;
export type GQTagDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQTagDeleteMutation,
    GQTagDeleteMutationVariables
>;

/**
 * __useTagDeleteMutation__
 *
 * To run a mutation, you first call `useTagDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagDeleteMutation, { data, loading, error }] = useTagDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQTagDeleteMutation,
        GQTagDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQTagDeleteMutation,
        GQTagDeleteMutationVariables
    >(TagDeleteDocument, baseOptions);
}
export type TagDeleteMutationHookResult = ReturnType<
    typeof useTagDeleteMutation
>;
export type TagDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQTagDeleteMutation>;
export type TagDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQTagDeleteMutation,
    GQTagDeleteMutationVariables
>;
export const TagFormDocument = gql`
    query tagForm {
        tags(
            pagination: { pageSize: 99999, sort: "name", sortDirection: "ASC" }
        ) {
            data {
                ...tagNameFields
            }
        }
        tagGroups(
            pagination: { pageSize: 99999, sort: "name", sortDirection: "ASC" }
        ) {
            data {
                ...tagGroupScreenFields
            }
        }
    }
    ${TagNameFieldsFragmentDoc}
    ${TagGroupScreenFieldsFragmentDoc}
`;

/**
 * __useTagFormQuery__
 *
 * To run a query within a React component, call `useTagFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQTagFormQuery,
        GQTagFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQTagFormQuery, GQTagFormQueryVariables>(
        TagFormDocument,
        baseOptions
    );
}
export function useTagFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQTagFormQuery,
        GQTagFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQTagFormQuery,
        GQTagFormQueryVariables
    >(TagFormDocument, baseOptions);
}
export type TagFormQueryHookResult = ReturnType<typeof useTagFormQuery>;
export type TagFormLazyQueryHookResult = ReturnType<typeof useTagFormLazyQuery>;
export type TagFormQueryResult = ApolloReactCommon.QueryResult<
    GQTagFormQuery,
    GQTagFormQueryVariables
>;
export const RolesDocument = gql`
    query roles($pagination: PaginationInput!, $search: SearchInput!) {
        roles(pagination: $pagination, search: $search) {
            data {
                ...allRoleFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${AllRoleFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useRolesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQRolesQuery,
        GQRolesQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQRolesQuery, GQRolesQueryVariables>(
        RolesDocument,
        baseOptions
    );
}
export function useRolesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQRolesQuery,
        GQRolesQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<GQRolesQuery, GQRolesQueryVariables>(
        RolesDocument,
        baseOptions
    );
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = ApolloReactCommon.QueryResult<
    GQRolesQuery,
    GQRolesQueryVariables
>;
export const RoleEditFormDocument = gql`
    query roleEditForm($id: ID!) {
        role(id: $id) {
            id
            name
            type
            shared
            inUse
            merchant {
                id
                name
            }
            permissions {
                key
                value
            }
        }
    }
`;

/**
 * __useRoleEditFormQuery__
 *
 * To run a query within a React component, call `useRoleEditFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleEditFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleEditFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleEditFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQRoleEditFormQuery,
        GQRoleEditFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQRoleEditFormQuery,
        GQRoleEditFormQueryVariables
    >(RoleEditFormDocument, baseOptions);
}
export function useRoleEditFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQRoleEditFormQuery,
        GQRoleEditFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQRoleEditFormQuery,
        GQRoleEditFormQueryVariables
    >(RoleEditFormDocument, baseOptions);
}
export type RoleEditFormQueryHookResult = ReturnType<
    typeof useRoleEditFormQuery
>;
export type RoleEditFormLazyQueryHookResult = ReturnType<
    typeof useRoleEditFormLazyQuery
>;
export type RoleEditFormQueryResult = ApolloReactCommon.QueryResult<
    GQRoleEditFormQuery,
    GQRoleEditFormQueryVariables
>;
export const RoleCreateDocument = gql`
    mutation roleCreate($role: RoleInput!) {
        roleCreate(role: $role) {
            ...allRoleFields
        }
    }
    ${AllRoleFieldsFragmentDoc}
`;
export type GQRoleCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQRoleCreateMutation,
    GQRoleCreateMutationVariables
>;

/**
 * __useRoleCreateMutation__
 *
 * To run a mutation, you first call `useRoleCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoleCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roleCreateMutation, { data, loading, error }] = useRoleCreateMutation({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useRoleCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQRoleCreateMutation,
        GQRoleCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQRoleCreateMutation,
        GQRoleCreateMutationVariables
    >(RoleCreateDocument, baseOptions);
}
export type RoleCreateMutationHookResult = ReturnType<
    typeof useRoleCreateMutation
>;
export type RoleCreateMutationResult =
    ApolloReactCommon.MutationResult<GQRoleCreateMutation>;
export type RoleCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQRoleCreateMutation,
    GQRoleCreateMutationVariables
>;
export const RoleSaveDocument = gql`
    mutation roleSave($id: ID!, $role: RoleInput!) {
        roleSave(id: $id, role: $role) {
            ...allRoleFields
        }
    }
    ${AllRoleFieldsFragmentDoc}
`;
export type GQRoleSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQRoleSaveMutation,
    GQRoleSaveMutationVariables
>;

/**
 * __useRoleSaveMutation__
 *
 * To run a mutation, you first call `useRoleSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoleSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roleSaveMutation, { data, loading, error }] = useRoleSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useRoleSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQRoleSaveMutation,
        GQRoleSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQRoleSaveMutation,
        GQRoleSaveMutationVariables
    >(RoleSaveDocument, baseOptions);
}
export type RoleSaveMutationHookResult = ReturnType<typeof useRoleSaveMutation>;
export type RoleSaveMutationResult =
    ApolloReactCommon.MutationResult<GQRoleSaveMutation>;
export type RoleSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQRoleSaveMutation,
    GQRoleSaveMutationVariables
>;
export const RoleDeleteDocument = gql`
    mutation roleDelete($id: ID!) {
        roleDelete(id: $id) {
            status
        }
    }
`;
export type GQRoleDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQRoleDeleteMutation,
    GQRoleDeleteMutationVariables
>;

/**
 * __useRoleDeleteMutation__
 *
 * To run a mutation, you first call `useRoleDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoleDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roleDeleteMutation, { data, loading, error }] = useRoleDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQRoleDeleteMutation,
        GQRoleDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQRoleDeleteMutation,
        GQRoleDeleteMutationVariables
    >(RoleDeleteDocument, baseOptions);
}
export type RoleDeleteMutationHookResult = ReturnType<
    typeof useRoleDeleteMutation
>;
export type RoleDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQRoleDeleteMutation>;
export type RoleDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQRoleDeleteMutation,
    GQRoleDeleteMutationVariables
>;
export const UsersDocument = gql`
    query users($pagination: PaginationInput!, $search: SearchInput!) {
        users(pagination: $pagination, search: $search) {
            data {
                ...user
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${UserFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useUsersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQUsersQuery,
        GQUsersQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQUsersQuery, GQUsersQueryVariables>(
        UsersDocument,
        baseOptions
    );
}
export function useUsersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQUsersQuery,
        GQUsersQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<GQUsersQuery, GQUsersQueryVariables>(
        UsersDocument,
        baseOptions
    );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<
    GQUsersQuery,
    GQUsersQueryVariables
>;
export const UserDocument = gql`
    query user($id: ID!) {
        user(id: $id) {
            ...user
        }
    }
    ${UserFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQUserQuery,
        GQUserQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQUserQuery, GQUserQueryVariables>(
        UserDocument,
        baseOptions
    );
}
export function useUserLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQUserQuery,
        GQUserQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<GQUserQuery, GQUserQueryVariables>(
        UserDocument,
        baseOptions
    );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<
    GQUserQuery,
    GQUserQueryVariables
>;
export const UserCreateFormDocument = gql`
    query userCreateForm {
        roles(pagination: { pageSize: 999999 }) {
            data {
                ...userFormRolesFields
            }
        }
    }
    ${UserFormRolesFieldsFragmentDoc}
`;

/**
 * __useUserCreateFormQuery__
 *
 * To run a query within a React component, call `useUserCreateFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreateFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreateFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCreateFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQUserCreateFormQuery,
        GQUserCreateFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQUserCreateFormQuery,
        GQUserCreateFormQueryVariables
    >(UserCreateFormDocument, baseOptions);
}
export function useUserCreateFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQUserCreateFormQuery,
        GQUserCreateFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQUserCreateFormQuery,
        GQUserCreateFormQueryVariables
    >(UserCreateFormDocument, baseOptions);
}
export type UserCreateFormQueryHookResult = ReturnType<
    typeof useUserCreateFormQuery
>;
export type UserCreateFormLazyQueryHookResult = ReturnType<
    typeof useUserCreateFormLazyQuery
>;
export type UserCreateFormQueryResult = ApolloReactCommon.QueryResult<
    GQUserCreateFormQuery,
    GQUserCreateFormQueryVariables
>;
export const UserEditFormDocument = gql`
    query userEditForm($userId: ID!) {
        user(id: $userId) {
            ...user
        }
        roles(pagination: { pageSize: 999999 }, userId: $userId) {
            data {
                ...userFormRolesFields
            }
        }
    }
    ${UserFragmentDoc}
    ${UserFormRolesFieldsFragmentDoc}
`;

/**
 * __useUserEditFormQuery__
 *
 * To run a query within a React component, call `useUserEditFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEditFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEditFormQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserEditFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQUserEditFormQuery,
        GQUserEditFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQUserEditFormQuery,
        GQUserEditFormQueryVariables
    >(UserEditFormDocument, baseOptions);
}
export function useUserEditFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQUserEditFormQuery,
        GQUserEditFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQUserEditFormQuery,
        GQUserEditFormQueryVariables
    >(UserEditFormDocument, baseOptions);
}
export type UserEditFormQueryHookResult = ReturnType<
    typeof useUserEditFormQuery
>;
export type UserEditFormLazyQueryHookResult = ReturnType<
    typeof useUserEditFormLazyQuery
>;
export type UserEditFormQueryResult = ApolloReactCommon.QueryResult<
    GQUserEditFormQuery,
    GQUserEditFormQueryVariables
>;
export const UsernameAvailabilityDocument = gql`
    query usernameAvailability($username: String!) {
        userAvailability(username: $username)
    }
`;

/**
 * __useUsernameAvailabilityQuery__
 *
 * To run a query within a React component, call `useUsernameAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameAvailabilityQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsernameAvailabilityQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQUsernameAvailabilityQuery,
        GQUsernameAvailabilityQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQUsernameAvailabilityQuery,
        GQUsernameAvailabilityQueryVariables
    >(UsernameAvailabilityDocument, baseOptions);
}
export function useUsernameAvailabilityLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQUsernameAvailabilityQuery,
        GQUsernameAvailabilityQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQUsernameAvailabilityQuery,
        GQUsernameAvailabilityQueryVariables
    >(UsernameAvailabilityDocument, baseOptions);
}
export type UsernameAvailabilityQueryHookResult = ReturnType<
    typeof useUsernameAvailabilityQuery
>;
export type UsernameAvailabilityLazyQueryHookResult = ReturnType<
    typeof useUsernameAvailabilityLazyQuery
>;
export type UsernameAvailabilityQueryResult = ApolloReactCommon.QueryResult<
    GQUsernameAvailabilityQuery,
    GQUsernameAvailabilityQueryVariables
>;
export const UserCreateDocument = gql`
    mutation userCreate($user: UserInput!) {
        userCreate(user: $user) {
            ...user
        }
    }
    ${UserFragmentDoc}
`;
export type GQUserCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQUserCreateMutation,
    GQUserCreateMutationVariables
>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUserCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQUserCreateMutation,
        GQUserCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQUserCreateMutation,
        GQUserCreateMutationVariables
    >(UserCreateDocument, baseOptions);
}
export type UserCreateMutationHookResult = ReturnType<
    typeof useUserCreateMutation
>;
export type UserCreateMutationResult =
    ApolloReactCommon.MutationResult<GQUserCreateMutation>;
export type UserCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQUserCreateMutation,
    GQUserCreateMutationVariables
>;
export const UserSaveDocument = gql`
    mutation userSave($id: ID!, $user: UserInput!) {
        userSave(id: $id, user: $user) {
            ...user
        }
    }
    ${UserFragmentDoc}
`;
export type GQUserSaveMutationFn = ApolloReactCommon.MutationFunction<
    GQUserSaveMutation,
    GQUserSaveMutationVariables
>;

/**
 * __useUserSaveMutation__
 *
 * To run a mutation, you first call `useUserSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSaveMutation, { data, loading, error }] = useUserSaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUserSaveMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQUserSaveMutation,
        GQUserSaveMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQUserSaveMutation,
        GQUserSaveMutationVariables
    >(UserSaveDocument, baseOptions);
}
export type UserSaveMutationHookResult = ReturnType<typeof useUserSaveMutation>;
export type UserSaveMutationResult =
    ApolloReactCommon.MutationResult<GQUserSaveMutation>;
export type UserSaveMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQUserSaveMutation,
    GQUserSaveMutationVariables
>;
export const UserDeleteDocument = gql`
    mutation userDelete($id: ID!) {
        userDelete(id: $id) {
            status
        }
    }
`;
export type GQUserDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQUserDeleteMutation,
    GQUserDeleteMutationVariables
>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQUserDeleteMutation,
        GQUserDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQUserDeleteMutation,
        GQUserDeleteMutationVariables
    >(UserDeleteDocument, baseOptions);
}
export type UserDeleteMutationHookResult = ReturnType<
    typeof useUserDeleteMutation
>;
export type UserDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQUserDeleteMutation>;
export type UserDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQUserDeleteMutation,
    GQUserDeleteMutationVariables
>;
export const UserUpdatePasswordDocument = gql`
    mutation userUpdatePassword($id: ID!, $password: String!) {
        userUpdatePassword(id: $id, password: $password) {
            status
        }
    }
`;
export type GQUserUpdatePasswordMutationFn = ApolloReactCommon.MutationFunction<
    GQUserUpdatePasswordMutation,
    GQUserUpdatePasswordMutationVariables
>;

/**
 * __useUserUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUserUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdatePasswordMutation, { data, loading, error }] = useUserUpdatePasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserUpdatePasswordMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQUserUpdatePasswordMutation,
        GQUserUpdatePasswordMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQUserUpdatePasswordMutation,
        GQUserUpdatePasswordMutationVariables
    >(UserUpdatePasswordDocument, baseOptions);
}
export type UserUpdatePasswordMutationHookResult = ReturnType<
    typeof useUserUpdatePasswordMutation
>;
export type UserUpdatePasswordMutationResult =
    ApolloReactCommon.MutationResult<GQUserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQUserUpdatePasswordMutation,
        GQUserUpdatePasswordMutationVariables
    >;
export const VatDocument = gql`
    query vat($id: ID!) {
        vat(id: $id) {
            ...vatScreenFields
        }
    }
    ${VatScreenFieldsFragmentDoc}
`;

/**
 * __useVatQuery__
 *
 * To run a query within a React component, call `useVatQuery` and pass it any options that fit your needs.
 * When your component renders, `useVatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVatQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQVatQuery,
        GQVatQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQVatQuery, GQVatQueryVariables>(
        VatDocument,
        baseOptions
    );
}
export function useVatLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQVatQuery,
        GQVatQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<GQVatQuery, GQVatQueryVariables>(
        VatDocument,
        baseOptions
    );
}
export type VatQueryHookResult = ReturnType<typeof useVatQuery>;
export type VatLazyQueryHookResult = ReturnType<typeof useVatLazyQuery>;
export type VatQueryResult = ApolloReactCommon.QueryResult<
    GQVatQuery,
    GQVatQueryVariables
>;
export const VatsDocument = gql`
    query vats($pagination: PaginationInput, $search: SearchInput) {
        vats(pagination: $pagination, search: $search) {
            data {
                ...vatScreenFields
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${VatScreenFieldsFragmentDoc}
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useVatsQuery__
 *
 * To run a query within a React component, call `useVatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVatsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useVatsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQVatsQuery,
        GQVatsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQVatsQuery, GQVatsQueryVariables>(
        VatsDocument,
        baseOptions
    );
}
export function useVatsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQVatsQuery,
        GQVatsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<GQVatsQuery, GQVatsQueryVariables>(
        VatsDocument,
        baseOptions
    );
}
export type VatsQueryHookResult = ReturnType<typeof useVatsQuery>;
export type VatsLazyQueryHookResult = ReturnType<typeof useVatsLazyQuery>;
export type VatsQueryResult = ApolloReactCommon.QueryResult<
    GQVatsQuery,
    GQVatsQueryVariables
>;
export const VatCreateDocument = gql`
    mutation vatCreate($vat: VatCreateInput!) {
        vatCreate(vat: $vat) {
            ...vatScreenFields
        }
    }
    ${VatScreenFieldsFragmentDoc}
`;
export type GQVatCreateMutationFn = ApolloReactCommon.MutationFunction<
    GQVatCreateMutation,
    GQVatCreateMutationVariables
>;

/**
 * __useVatCreateMutation__
 *
 * To run a mutation, you first call `useVatCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVatCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vatCreateMutation, { data, loading, error }] = useVatCreateMutation({
 *   variables: {
 *      vat: // value for 'vat'
 *   },
 * });
 */
export function useVatCreateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQVatCreateMutation,
        GQVatCreateMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQVatCreateMutation,
        GQVatCreateMutationVariables
    >(VatCreateDocument, baseOptions);
}
export type VatCreateMutationHookResult = ReturnType<
    typeof useVatCreateMutation
>;
export type VatCreateMutationResult =
    ApolloReactCommon.MutationResult<GQVatCreateMutation>;
export type VatCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQVatCreateMutation,
    GQVatCreateMutationVariables
>;
export const VatDeleteDocument = gql`
    mutation vatDelete($id: ID!) {
        vatDelete(id: $id) {
            status
        }
    }
`;
export type GQVatDeleteMutationFn = ApolloReactCommon.MutationFunction<
    GQVatDeleteMutation,
    GQVatDeleteMutationVariables
>;

/**
 * __useVatDeleteMutation__
 *
 * To run a mutation, you first call `useVatDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVatDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vatDeleteMutation, { data, loading, error }] = useVatDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVatDeleteMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQVatDeleteMutation,
        GQVatDeleteMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQVatDeleteMutation,
        GQVatDeleteMutationVariables
    >(VatDeleteDocument, baseOptions);
}
export type VatDeleteMutationHookResult = ReturnType<
    typeof useVatDeleteMutation
>;
export type VatDeleteMutationResult =
    ApolloReactCommon.MutationResult<GQVatDeleteMutation>;
export type VatDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQVatDeleteMutation,
    GQVatDeleteMutationVariables
>;
export const VatFormDocument = gql`
    query vatForm {
        vats(pagination: { pageSize: 99999 }) {
            data {
                id
                percentage
            }
        }
    }
`;

/**
 * __useVatFormQuery__
 *
 * To run a query within a React component, call `useVatFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useVatFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVatFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useVatFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQVatFormQuery,
        GQVatFormQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQVatFormQuery, GQVatFormQueryVariables>(
        VatFormDocument,
        baseOptions
    );
}
export function useVatFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQVatFormQuery,
        GQVatFormQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQVatFormQuery,
        GQVatFormQueryVariables
    >(VatFormDocument, baseOptions);
}
export type VatFormQueryHookResult = ReturnType<typeof useVatFormQuery>;
export type VatFormLazyQueryHookResult = ReturnType<typeof useVatFormLazyQuery>;
export type VatFormQueryResult = ApolloReactCommon.QueryResult<
    GQVatFormQuery,
    GQVatFormQueryVariables
>;
export const AccountBuyFundsDocument = gql`
    mutation accountBuyFunds(
        $accountId: ID!
        $amount: Float!
        $orderId: ID!
        $userId: ID!
        $tagId: ID!
        $tagIdentifier: String!
    ) {
        accountBuyFunds(
            id: $accountId
            amount: $amount
            orderId: $orderId
            userId: $userId
            tagId: $tagId
            tagIdentifier: $tagIdentifier
        ) {
            status
            transactionId
        }
    }
`;
export type GQAccountBuyFundsMutationFn = ApolloReactCommon.MutationFunction<
    GQAccountBuyFundsMutation,
    GQAccountBuyFundsMutationVariables
>;

/**
 * __useAccountBuyFundsMutation__
 *
 * To run a mutation, you first call `useAccountBuyFundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountBuyFundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountBuyFundsMutation, { data, loading, error }] = useAccountBuyFundsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      amount: // value for 'amount'
 *      orderId: // value for 'orderId'
 *      userId: // value for 'userId'
 *      tagId: // value for 'tagId'
 *      tagIdentifier: // value for 'tagIdentifier'
 *   },
 * });
 */
export function useAccountBuyFundsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAccountBuyFundsMutation,
        GQAccountBuyFundsMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAccountBuyFundsMutation,
        GQAccountBuyFundsMutationVariables
    >(AccountBuyFundsDocument, baseOptions);
}
export type AccountBuyFundsMutationHookResult = ReturnType<
    typeof useAccountBuyFundsMutation
>;
export type AccountBuyFundsMutationResult =
    ApolloReactCommon.MutationResult<GQAccountBuyFundsMutation>;
export type AccountBuyFundsMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQAccountBuyFundsMutation,
        GQAccountBuyFundsMutationVariables
    >;
export const AccountReturnFundsDocument = gql`
    mutation accountReturnFunds(
        $accountId: ID!
        $amount: Float!
        $orderId: ID!
        $userId: ID!
        $tagId: ID!
        $tagIdentifier: String!
    ) {
        accountReturnFunds(
            id: $accountId
            amount: $amount
            orderId: $orderId
            userId: $userId
            tagId: $tagId
            tagIdentifier: $tagIdentifier
        ) {
            status
            transactionId
        }
    }
`;
export type GQAccountReturnFundsMutationFn = ApolloReactCommon.MutationFunction<
    GQAccountReturnFundsMutation,
    GQAccountReturnFundsMutationVariables
>;

/**
 * __useAccountReturnFundsMutation__
 *
 * To run a mutation, you first call `useAccountReturnFundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountReturnFundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountReturnFundsMutation, { data, loading, error }] = useAccountReturnFundsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      amount: // value for 'amount'
 *      orderId: // value for 'orderId'
 *      userId: // value for 'userId'
 *      tagId: // value for 'tagId'
 *      tagIdentifier: // value for 'tagIdentifier'
 *   },
 * });
 */
export function useAccountReturnFundsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAccountReturnFundsMutation,
        GQAccountReturnFundsMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAccountReturnFundsMutation,
        GQAccountReturnFundsMutationVariables
    >(AccountReturnFundsDocument, baseOptions);
}
export type AccountReturnFundsMutationHookResult = ReturnType<
    typeof useAccountReturnFundsMutation
>;
export type AccountReturnFundsMutationResult =
    ApolloReactCommon.MutationResult<GQAccountReturnFundsMutation>;
export type AccountReturnFundsMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQAccountReturnFundsMutation,
        GQAccountReturnFundsMutationVariables
    >;
export const AccountRevertTransactionDocument = gql`
    mutation accountRevertTransaction(
        $accountId: ID!
        $transactionId: ID!
        $userId: ID!
    ) {
        accountRevertTransaction(
            id: $accountId
            transactionId: $transactionId
            userId: $userId
        ) {
            status
        }
    }
`;
export type GQAccountRevertTransactionMutationFn =
    ApolloReactCommon.MutationFunction<
        GQAccountRevertTransactionMutation,
        GQAccountRevertTransactionMutationVariables
    >;

/**
 * __useAccountRevertTransactionMutation__
 *
 * To run a mutation, you first call `useAccountRevertTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountRevertTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountRevertTransactionMutation, { data, loading, error }] = useAccountRevertTransactionMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      transactionId: // value for 'transactionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAccountRevertTransactionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAccountRevertTransactionMutation,
        GQAccountRevertTransactionMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAccountRevertTransactionMutation,
        GQAccountRevertTransactionMutationVariables
    >(AccountRevertTransactionDocument, baseOptions);
}
export type AccountRevertTransactionMutationHookResult = ReturnType<
    typeof useAccountRevertTransactionMutation
>;
export type AccountRevertTransactionMutationResult =
    ApolloReactCommon.MutationResult<GQAccountRevertTransactionMutation>;
export type AccountRevertTransactionMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQAccountRevertTransactionMutation,
        GQAccountRevertTransactionMutationVariables
    >;
export const AccountBalanceDocument = gql`
    query accountBalance($id: ID!, $numberOfTransactions: Int) {
        account(id: $id) {
            balance(numberOfTransactions: $numberOfTransactions) {
                receipt
            }
        }
    }
`;

/**
 * __useAccountBalanceQuery__
 *
 * To run a query within a React component, call `useAccountBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountBalanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      numberOfTransactions: // value for 'numberOfTransactions'
 *   },
 * });
 */
export function useAccountBalanceQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQAccountBalanceQuery,
        GQAccountBalanceQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQAccountBalanceQuery,
        GQAccountBalanceQueryVariables
    >(AccountBalanceDocument, baseOptions);
}
export function useAccountBalanceLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQAccountBalanceQuery,
        GQAccountBalanceQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQAccountBalanceQuery,
        GQAccountBalanceQueryVariables
    >(AccountBalanceDocument, baseOptions);
}
export type AccountBalanceQueryHookResult = ReturnType<
    typeof useAccountBalanceQuery
>;
export type AccountBalanceLazyQueryHookResult = ReturnType<
    typeof useAccountBalanceLazyQuery
>;
export type AccountBalanceQueryResult = ApolloReactCommon.QueryResult<
    GQAccountBalanceQuery,
    GQAccountBalanceQueryVariables
>;
export const AppDataDocument = gql`
    query appData {
        me {
            cashRegister {
                appData {
                    structVersion
                    minimumStructVersion
                    cashRegister {
                        id
                        serialId
                        name
                        department {
                            id
                            name
                        }
                    }
                    merchant {
                        id
                        serialId
                        name
                        giftcardVatBookkeepingMethod
                    }
                    deviceConfig
                    productGroups {
                        id
                        name
                        vat {
                            id
                            name
                            percentage
                        }
                        color
                        excludeFromDiscounts
                        printer {
                            id
                            name
                            serial
                        }
                        createdAt
                    }
                    vats {
                        id
                        name
                        percentage
                    }
                    products {
                        id
                        name
                        externalId
                        amount
                        vat {
                            id
                            name
                            percentage
                        }
                        minimumAmount
                        costAmount
                        excludeFromDiscounts
                        barcodes {
                            barcode
                        }
                        group {
                            id
                            name
                            printer {
                                id
                                name
                                serial
                            }
                        }
                        inventoryProduct {
                            id
                        }
                        printer {
                            id
                            name
                            serial
                        }
                        inheritPrinter
                    }
                    discounts {
                        lineDescription
                        type
                        valueType
                        value
                        maxPerOrder
                        itemQuery
                        conditions
                    }
                    layout {
                        ...allPosProductLayoutFields
                    }
                    tableLayout {
                        ...allPosTableLayoutFields
                    }
                    paymentConfiguration {
                        ...allPaymentConfigurationFields
                    }
                    miscButtons {
                        ...allMiscButtonsFields
                    }
                    tags {
                        id
                        identifier
                        tagType
                        name
                        group {
                            id
                            name
                        }
                        account {
                            id
                            name
                            amount
                            status
                            type
                            vat {
                                id
                                percentage
                            }
                        }
                        customer {
                            id
                            name
                            group {
                                id
                                name
                            }
                        }
                    }
                    printers {
                        id
                        name
                        serial
                        type
                        class
                        manufacturer
                    }
                    bookkeepingAccounts {
                        id
                        name
                        systemLabel
                    }
                }
            }
        }
    }
    ${AllPosProductLayoutFieldsFragmentDoc}
    ${AllPosTableLayoutFieldsFragmentDoc}
    ${AllPaymentConfigurationFieldsFragmentDoc}
    ${AllMiscButtonsFieldsFragmentDoc}
`;

/**
 * __useAppDataQuery__
 *
 * To run a query within a React component, call `useAppDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppDataQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQAppDataQuery,
        GQAppDataQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQAppDataQuery, GQAppDataQueryVariables>(
        AppDataDocument,
        baseOptions
    );
}
export function useAppDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQAppDataQuery,
        GQAppDataQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQAppDataQuery,
        GQAppDataQueryVariables
    >(AppDataDocument, baseOptions);
}
export type AppDataQueryHookResult = ReturnType<typeof useAppDataQuery>;
export type AppDataLazyQueryHookResult = ReturnType<typeof useAppDataLazyQuery>;
export type AppDataQueryResult = ApolloReactCommon.QueryResult<
    GQAppDataQuery,
    GQAppDataQueryVariables
>;
export const AccountsByCustomerSearchDocument = gql`
    query accountsByCustomerSearch(
        $pagination: PaginationInput
        $search: SearchInput
    ) {
        accountsByCustomerSearch(pagination: $pagination, search: $search) {
            data {
                id
                name
                customer {
                    id
                    name
                    phoneNumber
                }
            }
            pagination {
                ...defaultPaginationFields
            }
        }
    }
    ${DefaultPaginationFieldsFragmentDoc}
`;

/**
 * __useAccountsByCustomerSearchQuery__
 *
 * To run a query within a React component, call `useAccountsByCustomerSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsByCustomerSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsByCustomerSearchQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAccountsByCustomerSearchQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQAccountsByCustomerSearchQuery,
        GQAccountsByCustomerSearchQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQAccountsByCustomerSearchQuery,
        GQAccountsByCustomerSearchQueryVariables
    >(AccountsByCustomerSearchDocument, baseOptions);
}
export function useAccountsByCustomerSearchLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQAccountsByCustomerSearchQuery,
        GQAccountsByCustomerSearchQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQAccountsByCustomerSearchQuery,
        GQAccountsByCustomerSearchQueryVariables
    >(AccountsByCustomerSearchDocument, baseOptions);
}
export type AccountsByCustomerSearchQueryHookResult = ReturnType<
    typeof useAccountsByCustomerSearchQuery
>;
export type AccountsByCustomerSearchLazyQueryHookResult = ReturnType<
    typeof useAccountsByCustomerSearchLazyQuery
>;
export type AccountsByCustomerSearchQueryResult = ApolloReactCommon.QueryResult<
    GQAccountsByCustomerSearchQuery,
    GQAccountsByCustomerSearchQueryVariables
>;
export const VenueAccessCheckoutTicketDocument = gql`
    mutation venueAccessCheckoutTicket(
        $eventId: Int!
        $accessTypes: [Int!]!
        $validFrom: Date!
        $validTo: Date!
    ) {
        venueAccessCreateAccessTicket(
            eventId: $eventId
            accessTypes: $accessTypes
            validFrom: $validFrom
            validTo: $validTo
        ) {
            tokenValue
            ticketId
        }
    }
`;
export type GQVenueAccessCheckoutTicketMutationFn =
    ApolloReactCommon.MutationFunction<
        GQVenueAccessCheckoutTicketMutation,
        GQVenueAccessCheckoutTicketMutationVariables
    >;

/**
 * __useVenueAccessCheckoutTicketMutation__
 *
 * To run a mutation, you first call `useVenueAccessCheckoutTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVenueAccessCheckoutTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [venueAccessCheckoutTicketMutation, { data, loading, error }] = useVenueAccessCheckoutTicketMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      accessTypes: // value for 'accessTypes'
 *      validFrom: // value for 'validFrom'
 *      validTo: // value for 'validTo'
 *   },
 * });
 */
export function useVenueAccessCheckoutTicketMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQVenueAccessCheckoutTicketMutation,
        GQVenueAccessCheckoutTicketMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQVenueAccessCheckoutTicketMutation,
        GQVenueAccessCheckoutTicketMutationVariables
    >(VenueAccessCheckoutTicketDocument, baseOptions);
}
export type VenueAccessCheckoutTicketMutationHookResult = ReturnType<
    typeof useVenueAccessCheckoutTicketMutation
>;
export type VenueAccessCheckoutTicketMutationResult =
    ApolloReactCommon.MutationResult<GQVenueAccessCheckoutTicketMutation>;
export type VenueAccessCheckoutTicketMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQVenueAccessCheckoutTicketMutation,
        GQVenueAccessCheckoutTicketMutationVariables
    >;
export const VenueAccessCancelTicketDocument = gql`
    mutation venueAccessCancelTicket($ticketId: Int!) {
        venueAccessCancelAccessTicket(ticketId: $ticketId) {
            status
        }
    }
`;
export type GQVenueAccessCancelTicketMutationFn =
    ApolloReactCommon.MutationFunction<
        GQVenueAccessCancelTicketMutation,
        GQVenueAccessCancelTicketMutationVariables
    >;

/**
 * __useVenueAccessCancelTicketMutation__
 *
 * To run a mutation, you first call `useVenueAccessCancelTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVenueAccessCancelTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [venueAccessCancelTicketMutation, { data, loading, error }] = useVenueAccessCancelTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useVenueAccessCancelTicketMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQVenueAccessCancelTicketMutation,
        GQVenueAccessCancelTicketMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQVenueAccessCancelTicketMutation,
        GQVenueAccessCancelTicketMutationVariables
    >(VenueAccessCancelTicketDocument, baseOptions);
}
export type VenueAccessCancelTicketMutationHookResult = ReturnType<
    typeof useVenueAccessCancelTicketMutation
>;
export type VenueAccessCancelTicketMutationResult =
    ApolloReactCommon.MutationResult<GQVenueAccessCancelTicketMutation>;
export type VenueAccessCancelTicketMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQVenueAccessCancelTicketMutation,
        GQVenueAccessCancelTicketMutationVariables
    >;
export const MerchantDataUpdatedDocument = gql`
    subscription merchantDataUpdated {
        merchantDataUpdated
    }
`;

/**
 * __useMerchantDataUpdatedSubscription__
 *
 * To run a query within a React component, call `useMerchantDataUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMerchantDataUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantDataUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMerchantDataUpdatedSubscription(
    baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
        GQMerchantDataUpdatedSubscription,
        GQMerchantDataUpdatedSubscriptionVariables
    >
) {
    return ApolloReactHooks.useSubscription<
        GQMerchantDataUpdatedSubscription,
        GQMerchantDataUpdatedSubscriptionVariables
    >(MerchantDataUpdatedDocument, baseOptions);
}
export type MerchantDataUpdatedSubscriptionHookResult = ReturnType<
    typeof useMerchantDataUpdatedSubscription
>;
export type MerchantDataUpdatedSubscriptionResult =
    ApolloReactCommon.SubscriptionResult<GQMerchantDataUpdatedSubscription>;
export const InventoryForIdsDocument = gql`
    query inventoryForIds($ids: [ID!]!) {
        inventoryForIds(ids: $ids) {
            id
            quantity
            products {
                id
            }
        }
    }
`;

/**
 * __useInventoryForIdsQuery__
 *
 * To run a query within a React component, call `useInventoryForIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryForIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryForIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useInventoryForIdsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQInventoryForIdsQuery,
        GQInventoryForIdsQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQInventoryForIdsQuery,
        GQInventoryForIdsQueryVariables
    >(InventoryForIdsDocument, baseOptions);
}
export function useInventoryForIdsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQInventoryForIdsQuery,
        GQInventoryForIdsQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQInventoryForIdsQuery,
        GQInventoryForIdsQueryVariables
    >(InventoryForIdsDocument, baseOptions);
}
export type InventoryForIdsQueryHookResult = ReturnType<
    typeof useInventoryForIdsQuery
>;
export type InventoryForIdsLazyQueryHookResult = ReturnType<
    typeof useInventoryForIdsLazyQuery
>;
export type InventoryForIdsQueryResult = ApolloReactCommon.QueryResult<
    GQInventoryForIdsQuery,
    GQInventoryForIdsQueryVariables
>;
export const SelectMerchantPosUserDocument = gql`
    query selectMerchantPosUser {
        me {
            cashRegister {
                name
                department {
                    name
                }
                merchant {
                    name
                }
            }
            merchant {
                id
                users {
                    ...allAuthUserFields
                }
            }
        }
    }
    ${AllAuthUserFieldsFragmentDoc}
`;

/**
 * __useSelectMerchantPosUserQuery__
 *
 * To run a query within a React component, call `useSelectMerchantPosUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectMerchantPosUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectMerchantPosUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectMerchantPosUserQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQSelectMerchantPosUserQuery,
        GQSelectMerchantPosUserQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQSelectMerchantPosUserQuery,
        GQSelectMerchantPosUserQueryVariables
    >(SelectMerchantPosUserDocument, baseOptions);
}
export function useSelectMerchantPosUserLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQSelectMerchantPosUserQuery,
        GQSelectMerchantPosUserQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQSelectMerchantPosUserQuery,
        GQSelectMerchantPosUserQueryVariables
    >(SelectMerchantPosUserDocument, baseOptions);
}
export type SelectMerchantPosUserQueryHookResult = ReturnType<
    typeof useSelectMerchantPosUserQuery
>;
export type SelectMerchantPosUserLazyQueryHookResult = ReturnType<
    typeof useSelectMerchantPosUserLazyQuery
>;
export type SelectMerchantPosUserQueryResult = ApolloReactCommon.QueryResult<
    GQSelectMerchantPosUserQuery,
    GQSelectMerchantPosUserQueryVariables
>;
export const AuthDevicePollDocument = gql`
    query authDevicePoll($deviceId: ID!, $devicePinCode: String!) {
        authDevicePoll(deviceId: $deviceId, devicePinCode: $devicePinCode) {
            ...allAuthTokenFields
        }
    }
    ${AllAuthTokenFieldsFragmentDoc}
`;

/**
 * __useAuthDevicePollQuery__
 *
 * To run a query within a React component, call `useAuthDevicePollQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthDevicePollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthDevicePollQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      devicePinCode: // value for 'devicePinCode'
 *   },
 * });
 */
export function useAuthDevicePollQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQAuthDevicePollQuery,
        GQAuthDevicePollQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQAuthDevicePollQuery,
        GQAuthDevicePollQueryVariables
    >(AuthDevicePollDocument, baseOptions);
}
export function useAuthDevicePollLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQAuthDevicePollQuery,
        GQAuthDevicePollQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQAuthDevicePollQuery,
        GQAuthDevicePollQueryVariables
    >(AuthDevicePollDocument, baseOptions);
}
export type AuthDevicePollQueryHookResult = ReturnType<
    typeof useAuthDevicePollQuery
>;
export type AuthDevicePollLazyQueryHookResult = ReturnType<
    typeof useAuthDevicePollLazyQuery
>;
export type AuthDevicePollQueryResult = ApolloReactCommon.QueryResult<
    GQAuthDevicePollQuery,
    GQAuthDevicePollQueryVariables
>;
export const AuthRefreshDocument = gql`
    query authRefresh($refreshToken: String!) {
        authRefresh(refreshToken: $refreshToken) {
            ...allAuthTokenFields
        }
    }
    ${AllAuthTokenFieldsFragmentDoc}
`;

/**
 * __useAuthRefreshQuery__
 *
 * To run a query within a React component, call `useAuthRefreshQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthRefreshQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthRefreshQuery({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useAuthRefreshQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQAuthRefreshQuery,
        GQAuthRefreshQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<
        GQAuthRefreshQuery,
        GQAuthRefreshQueryVariables
    >(AuthRefreshDocument, baseOptions);
}
export function useAuthRefreshLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQAuthRefreshQuery,
        GQAuthRefreshQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<
        GQAuthRefreshQuery,
        GQAuthRefreshQueryVariables
    >(AuthRefreshDocument, baseOptions);
}
export type AuthRefreshQueryHookResult = ReturnType<typeof useAuthRefreshQuery>;
export type AuthRefreshLazyQueryHookResult = ReturnType<
    typeof useAuthRefreshLazyQuery
>;
export type AuthRefreshQueryResult = ApolloReactCommon.QueryResult<
    GQAuthRefreshQuery,
    GQAuthRefreshQueryVariables
>;
export const AuthLoginDocument = gql`
    mutation authLogin($username: String!, $password: String!) {
        authLogin(username: $username, password: $password) {
            successful
            token {
                ...allAuthTokenFields
            }
        }
    }
    ${AllAuthTokenFieldsFragmentDoc}
`;
export type GQAuthLoginMutationFn = ApolloReactCommon.MutationFunction<
    GQAuthLoginMutation,
    GQAuthLoginMutationVariables
>;

/**
 * __useAuthLoginMutation__
 *
 * To run a mutation, you first call `useAuthLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authLoginMutation, { data, loading, error }] = useAuthLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthLoginMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAuthLoginMutation,
        GQAuthLoginMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAuthLoginMutation,
        GQAuthLoginMutationVariables
    >(AuthLoginDocument, baseOptions);
}
export type AuthLoginMutationHookResult = ReturnType<
    typeof useAuthLoginMutation
>;
export type AuthLoginMutationResult =
    ApolloReactCommon.MutationResult<GQAuthLoginMutation>;
export type AuthLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
    GQAuthLoginMutation,
    GQAuthLoginMutationVariables
>;
export const AuthUpdateLoginDocument = gql`
    mutation authUpdateLogin {
        authUpdateLogin {
            successful
            token {
                ...allAuthTokenFields
            }
        }
    }
    ${AllAuthTokenFieldsFragmentDoc}
`;
export type GQAuthUpdateLoginMutationFn = ApolloReactCommon.MutationFunction<
    GQAuthUpdateLoginMutation,
    GQAuthUpdateLoginMutationVariables
>;

/**
 * __useAuthUpdateLoginMutation__
 *
 * To run a mutation, you first call `useAuthUpdateLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthUpdateLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authUpdateLoginMutation, { data, loading, error }] = useAuthUpdateLoginMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthUpdateLoginMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAuthUpdateLoginMutation,
        GQAuthUpdateLoginMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAuthUpdateLoginMutation,
        GQAuthUpdateLoginMutationVariables
    >(AuthUpdateLoginDocument, baseOptions);
}
export type AuthUpdateLoginMutationHookResult = ReturnType<
    typeof useAuthUpdateLoginMutation
>;
export type AuthUpdateLoginMutationResult =
    ApolloReactCommon.MutationResult<GQAuthUpdateLoginMutation>;
export type AuthUpdateLoginMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQAuthUpdateLoginMutation,
        GQAuthUpdateLoginMutationVariables
    >;
export const AuthSelectUserRoleDocument = gql`
    mutation authSelectUserRole($roleId: ID!, $merchantId: ID) {
        authSelectUserRole(roleId: $roleId, merchantId: $merchantId) {
            token {
                ...allAuthTokenFields
            }
        }
    }
    ${AllAuthTokenFieldsFragmentDoc}
`;
export type GQAuthSelectUserRoleMutationFn = ApolloReactCommon.MutationFunction<
    GQAuthSelectUserRoleMutation,
    GQAuthSelectUserRoleMutationVariables
>;

/**
 * __useAuthSelectUserRoleMutation__
 *
 * To run a mutation, you first call `useAuthSelectUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSelectUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSelectUserRoleMutation, { data, loading, error }] = useAuthSelectUserRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      merchantId: // value for 'merchantId'
 *   },
 * });
 */
export function useAuthSelectUserRoleMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQAuthSelectUserRoleMutation,
        GQAuthSelectUserRoleMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQAuthSelectUserRoleMutation,
        GQAuthSelectUserRoleMutationVariables
    >(AuthSelectUserRoleDocument, baseOptions);
}
export type AuthSelectUserRoleMutationHookResult = ReturnType<
    typeof useAuthSelectUserRoleMutation
>;
export type AuthSelectUserRoleMutationResult =
    ApolloReactCommon.MutationResult<GQAuthSelectUserRoleMutation>;
export type AuthSelectUserRoleMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQAuthSelectUserRoleMutation,
        GQAuthSelectUserRoleMutationVariables
    >;
export const MeDocument = gql`
    query me {
        me {
            cashRegister {
                id
                serialId
                name
            }
            user {
                ...allAuthUserFields
            }
            department {
                ...allDepartmentFields
            }
            merchant {
                ...allAuthMerchantFields
            }
            role {
                ...allAuthRoleFields
            }
        }
    }
    ${AllAuthUserFieldsFragmentDoc}
    ${AllDepartmentFieldsFragmentDoc}
    ${AllAuthMerchantFieldsFragmentDoc}
    ${AllAuthRoleFieldsFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GQMeQuery,
        GQMeQueryVariables
    >
) {
    return ApolloReactHooks.useQuery<GQMeQuery, GQMeQueryVariables>(
        MeDocument,
        baseOptions
    );
}
export function useMeLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GQMeQuery,
        GQMeQueryVariables
    >
) {
    return ApolloReactHooks.useLazyQuery<GQMeQuery, GQMeQueryVariables>(
        MeDocument,
        baseOptions
    );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<
    GQMeQuery,
    GQMeQueryVariables
>;
export const RegisterDeviceDocument = gql`
    mutation registerDevice(
        $deviceInfo: DeviceInfoInput!
        $newPinCode: Boolean
    ) {
        deviceRegister(deviceInfo: $deviceInfo, newPinCode: $newPinCode) {
            id
            pinCode
        }
    }
`;
export type GQRegisterDeviceMutationFn = ApolloReactCommon.MutationFunction<
    GQRegisterDeviceMutation,
    GQRegisterDeviceMutationVariables
>;

/**
 * __useRegisterDeviceMutation__
 *
 * To run a mutation, you first call `useRegisterDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerDeviceMutation, { data, loading, error }] = useRegisterDeviceMutation({
 *   variables: {
 *      deviceInfo: // value for 'deviceInfo'
 *      newPinCode: // value for 'newPinCode'
 *   },
 * });
 */
export function useRegisterDeviceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQRegisterDeviceMutation,
        GQRegisterDeviceMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQRegisterDeviceMutation,
        GQRegisterDeviceMutationVariables
    >(RegisterDeviceDocument, baseOptions);
}
export type RegisterDeviceMutationHookResult = ReturnType<
    typeof useRegisterDeviceMutation
>;
export type RegisterDeviceMutationResult =
    ApolloReactCommon.MutationResult<GQRegisterDeviceMutation>;
export type RegisterDeviceMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQRegisterDeviceMutation,
        GQRegisterDeviceMutationVariables
    >;
export const DeviceHeartbeatDocument = gql`
    mutation deviceHeartbeat($deviceInfo: DeviceInfoInput!) {
        deviceHeartbeat(deviceInfo: $deviceInfo)
    }
`;
export type GQDeviceHeartbeatMutationFn = ApolloReactCommon.MutationFunction<
    GQDeviceHeartbeatMutation,
    GQDeviceHeartbeatMutationVariables
>;

/**
 * __useDeviceHeartbeatMutation__
 *
 * To run a mutation, you first call `useDeviceHeartbeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceHeartbeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceHeartbeatMutation, { data, loading, error }] = useDeviceHeartbeatMutation({
 *   variables: {
 *      deviceInfo: // value for 'deviceInfo'
 *   },
 * });
 */
export function useDeviceHeartbeatMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        GQDeviceHeartbeatMutation,
        GQDeviceHeartbeatMutationVariables
    >
) {
    return ApolloReactHooks.useMutation<
        GQDeviceHeartbeatMutation,
        GQDeviceHeartbeatMutationVariables
    >(DeviceHeartbeatDocument, baseOptions);
}
export type DeviceHeartbeatMutationHookResult = ReturnType<
    typeof useDeviceHeartbeatMutation
>;
export type DeviceHeartbeatMutationResult =
    ApolloReactCommon.MutationResult<GQDeviceHeartbeatMutation>;
export type DeviceHeartbeatMutationOptions =
    ApolloReactCommon.BaseMutationOptions<
        GQDeviceHeartbeatMutation,
        GQDeviceHeartbeatMutationVariables
    >;
