import { LayoutSection } from "lib";

/**
 * Removes the LayoutButtonMetaData objects from the layout data.
 * This method iterates the buttons in a LayoutSection,
 * removes the 'metaData' property, and the 'renaming' property for the section.
 */
export function rinseLayoutData(
    section: LayoutSection & Partial<{ renaming: boolean }>
) {
    // remove helper properties for the section: 'renaming'
    if (section.renaming) {
        section.renaming = undefined;
    }
    if (!section.buttons || !section.buttons.length) {
        return section;
    }

    // remove helper properties for the buttons in the section: 'metaData' for the grid
    const updatedButtons = [...section.buttons];
    updatedButtons.map(button => {
        if (button.metaData) {
            button.metaData = undefined;
        }

        return button;
    });

    return { ...section, buttons: updatedButtons };
}
