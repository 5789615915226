import React, { useMemo } from "react";
import {
    TouchableOpacity,
    View,
    Text,
    Platform,
    StyleSheet,
} from "react-native";
import { Icon, useThemedStyle, useDimensions, StyleFunction } from "../..";
import { PERMANENT_DRAWER_WIDTH } from "../navigation";
import { IMenuItem } from "./types";

export function MenuItem({
    item,
    isExpanded,
    onPress,
    onExpand,
}: {
    item: IMenuItem;
    isExpanded: boolean;
    onPress: (item: IMenuItem) => void;
    onExpand: (item: IMenuItem) => void;
}) {
    const groupIcon = isExpanded ? "dropup" : "dropdown";
    const styles = useThemedStyle(styleFunc);
    const { width } = useDimensions();

    const isFloating = width <= PERMANENT_DRAWER_WIDTH;
    const shouldExpand =
        (isFloating || !item.link) && item.items && item.items.length > 0;
    const activeStyle =
        item.isActive && !shouldExpand
            ? styles.menuItemRowActive
            : styles.menuItemRowInactive;

    const linkTextStyle = useMemo(
        () =>
            StyleSheet.flatten([
                styles.textColor,
                Platform.OS === "android"
                    ? styles.menuTextAndroid
                    : styles.menuTextWeb,
            ]),
        [styles.menuTextAndroid, styles.menuTextWeb, styles.textColor]
    );
    const childLinkTextStyle = useMemo(
        () =>
            StyleSheet.flatten([
                styles.textColor,
                Platform.OS === "web"
                    ? styles.menuChildrenTextWeb
                    : styles.menuChildrenTextAndroid,
            ]),
        [
            styles.menuChildrenTextAndroid,
            styles.menuChildrenTextWeb,
            styles.textColor,
        ]
    );

    return (
        <>
            <TouchableOpacity
                onPress={
                    shouldExpand ? () => onExpand(item) : () => onPress(item)
                }
                style={activeStyle}
                testID={`${item.testID}:${
                    isExpanded ? "isExpanded" : "isClosed"
                }`}
            >
                <View style={styles.menuItem} testID={item.testID}>
                    <View style={styles.menuIcon}>
                        <Icon
                            name={item.iconName || "default"}
                            color={styles.menuIcon.color}
                        />
                    </View>
                    <Text style={linkTextStyle}>{item.name}</Text>
                </View>
                {item.items && (
                    <Icon name={groupIcon} color={styles.menuIcon.color} />
                )}
            </TouchableOpacity>
            {isExpanded && isFloating && item.items && item.items.length > 0 ? (
                <TouchableOpacity
                    onPress={() => onPress(item)}
                    key={item.name}
                    style={[
                        styles.menuChildrenWeb,
                        item.isActive
                            ? styles.menuItemRowActive
                            : styles.menuItemRowInactive,
                    ]}
                >
                    <Text style={childLinkTextStyle}>{item.name}</Text>
                </TouchableOpacity>
            ) : null}
            {isExpanded &&
                item.items &&
                item.items.map(childItem => (
                    <TouchableOpacity
                        onPress={() => onPress(childItem)}
                        key={childItem.name}
                        style={[
                            styles.menuChildrenWeb,
                            childItem.isActive
                                ? styles.menuItemRowActive
                                : styles.menuItemRowInactive,
                        ]}
                        testID={childItem.testID}
                    >
                        <Text style={childLinkTextStyle}>{childItem.name}</Text>
                    </TouchableOpacity>
                ))}
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    menuItem: { flexDirection: "row" },

    menuItemRowActive: {
        backgroundColor: theme.colors.primary,
        flexDirection: "row",
        padding: theme.spacingScale,
        justifyContent: "space-between",
        alignItems: "flex-start",
    },

    menuItemRowInactive: {
        flexDirection: "row",
        padding: theme.spacingScale,
        justifyContent: "space-between",
        alignItems: "flex-start",
    },

    menuIcon: {
        width: 30,
        marginRight: theme.spacingScale / 2,
        color: theme.colors.white,
    },

    menuChildrenWeb: {
        paddingLeft: 50,
        backgroundColor: theme.colors.tertiary,
    },

    textColor: {
        color: theme.colors.textLight,
    },

    menuTextAndroid: {
        ...theme.fonts.regular,
        fontSize: 12,
    },

    menuChildrenTextAndroid: {
        ...theme.fonts.regular,
        fontSize: 12,
    },

    menuTextWeb: {
        ...theme.fonts.regular,
        fontSize: 18,
    },

    menuChildrenTextWeb: {
        ...theme.fonts.regular,
        fontSize: 16,
    },
});
