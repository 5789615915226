import React from "react";
import {
    Button,
    Form as FormComponent,
    InputControl,
    RequiredText,
    Surface,
    TextInput,
} from "@venuepos/react-common";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import type { GQCustomerGroupInput } from "graphql-sdk";
import { AdminContainer } from "../../..";

export function Form(props: {
    form: FormComponent<GQCustomerGroupInput>;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;

    if (!values) {
        return null;
    }

    return (
        <AdminContainer>
            <Surface>
                <View style={styles.formBox}>
                    <InputControl error={errors.name}>
                        <TextInput
                            label={t("common.name", "Name")}
                            placeholder={t(
                                "backoffice.common.enter_name",
                                "Enter name"
                            )}
                            defaultValue={values.name}
                            onChangeText={text => setValue("name", text)}
                            required={true}
                        />
                    </InputControl>
                    <Button onPress={handleSubmit(props.onSubmit)}>
                        {t(props.submitButton[0], props.submitButton[1])}
                    </Button>
                    <RequiredText />
                </View>
            </Surface>
        </AdminContainer>
    );
}

const styles = StyleSheet.create({
    formBox: {
        maxWidth: 600,
    },
});
