import { assign, createMachine } from "xstate";
import type { PaymentType } from "./types";
import type { IEvent } from "event-store";
import type {
    FundsUpdatedEventV1,
    ShiftClosedEventV1,
    ShiftOpenEventV1,
} from "./events";
import type { Currency } from "../currencies/currencies";

export type ShiftContext = {
    id: string;
    startedAt: Date;
    fundsAtShiftStart: PaymentType[];
    fundsDuringShift: PaymentType[];
    fundsAtShiftEnd: PaymentType[];
    currency: Currency;
};

export type ShiftStateSchema = {
    states: {
        empty: Record<string, unknown>;
        open: Record<string, unknown>;
        closed: Record<string, unknown>;
    };
};

type ShiftOpenedEvent = IEvent & {
    type: "OPENED";
    data: ShiftOpenEventV1["data"];
};

type ShiftClosedEvent = IEvent & {
    type: "CLOSED";
    data: ShiftClosedEventV1["data"];
};

type FundsUpdatedEvent = IEvent & {
    type: "FUNDS_UPDATED";
    data: FundsUpdatedEventV1["data"];
};

export type MachineShiftEvent =
    | ShiftOpenedEvent
    | ShiftClosedEvent
    | FundsUpdatedEvent;

const openShiftAction = assign<ShiftContext, ShiftOpenedEvent>({
    id: (context, event) => {
        if (event.aggregateId) {
            return event.aggregateId;
        }
        return context.id;
    },
    fundsAtShiftStart: (context, event) => {
        if (
            event.data === undefined ||
            event.data.paymentMethods === undefined
        ) {
            return context.fundsAtShiftStart;
        }
        const paymentMethods: PaymentType[] = event.data.paymentMethods;
        paymentMethods.forEach(item => {
            context.fundsAtShiftStart.push(item as PaymentType);
        });
        return context.fundsAtShiftStart;
    },
    currency: (context, event) => {
        return event.data.currency;
    },
});

const closeShiftAction = assign<ShiftContext, ShiftClosedEvent>({
    fundsAtShiftEnd: (context, event) => {
        if (
            event.data === undefined ||
            event.data.paymentMethods === undefined
        ) {
            return context.fundsAtShiftEnd;
        }
        const paymentTypes: PaymentType[] = event.data.paymentMethods;
        paymentTypes.forEach(item => {
            context.fundsAtShiftEnd.push(item as PaymentType);
        });
        return context.fundsAtShiftEnd;
    },
});

const fundsUpdatedAction = assign<ShiftContext, FundsUpdatedEvent>({
    fundsDuringShift: (context, event) => {
        if (
            event.data === undefined ||
            event.data.paymentMethods === undefined
        ) {
            return context.fundsDuringShift;
        }
        const paymentTypes: PaymentType[] = event.data.paymentMethods;
        paymentTypes.forEach(item => {
            context.fundsDuringShift.push(item as PaymentType);
        });
        return context.fundsDuringShift;
    },
});

const resetContextAction = assign<ShiftContext, ShiftOpenedEvent>({
    startedAt: () => new Date(),
    id: () => "",
    fundsAtShiftStart: () => [],
    fundsDuringShift: () => [],
    fundsAtShiftEnd: () => [],
    currency: (_, event: ShiftOpenedEvent) => event.data.currency,
});

export const createShiftMachine = (currency: Currency) =>
    createMachine<ShiftContext, MachineShiftEvent>({
        id: "shift",
        initial: "empty",
        context: {
            id: "", // use uuid
            startedAt: new Date(),
            fundsAtShiftStart: [],
            fundsDuringShift: [],
            fundsAtShiftEnd: [],
            currency,
        },
        states: {
            empty: {
                on: {
                    OPENED: {
                        actions: [openShiftAction],
                        target: "open",
                    },
                },
            },
            open: {
                on: {
                    CLOSED: {
                        actions: [closeShiftAction],
                        target: "closed",
                    },
                    FUNDS_UPDATED: {
                        actions: [fundsUpdatedAction],
                    },
                },
            },
            closed: {
                on: {
                    OPENED: {
                        actions: [resetContextAction, openShiftAction],
                        target: "open",
                    },
                },
            },
        },
    });
