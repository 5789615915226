// Reference: https://material.io/components/data-tables#specs
// Inspiration: https://github.com/callstack/react-native-paper/blob/master/src/components/DataTable/DataTableCell.tsx

import React, { ReactNode } from "react";
import { View } from "react-native";
import { DataGridCell } from "./cell";
import { DataGridRow } from "./row";
import { DataGridHeader } from "./header";
import { DataGridTitle } from "./title";
import { DataTablePagination } from "./pagination";

export function DataTable({
    children,
    testID,
}: {
    children: ReactNode;
    testID?: string;
}) {
    return <View testID={testID}>{children}</View>;
}

// Attaches the extra components to DataTable in a static way
// - Then we can use <DataTable.Title> and so on.
DataTable.Title = DataGridTitle;
DataTable.Header = DataGridHeader;
DataTable.Cell = DataGridCell;
DataTable.Row = DataGridRow;
DataTable.Pagination = DataTablePagination;
