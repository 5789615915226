import {
    IconButton,
    StyleFunction,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import type { LayoutTableButton } from "lib";
import React from "react";
import { View } from "react-native";

export function VisualLayoutButton({
    label,
    buttonId,
    onPress,
}: {
    label: LayoutTableButton["label"];
    buttonId: LayoutTableButton["id"];
    onPress: (buttonId: LayoutTableButton["id"]) => void;
}) {
    const styles = useThemedStyle(styleFunc);

    return (
        <>
            <View>
                <Text numberOfLines={1}>{label}</Text>
            </View>
            <div className="gridHandle">
                <IconButton
                    onPress={() => onPress(buttonId)}
                    name="settings"
                    size="small"
                    color={styles.handle.color}
                    style={styles.handle}
                />
            </div>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    handle: {
        backgroundColor: theme.colors.grey100,
        padding: 1,
        color: theme.colors.textDark,
        borderRadius: 9,

        position: "absolute",
        left: -7,
        top: -6,
    },
});
