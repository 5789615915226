import {
    StyleFunction,
    Surface,
    useAuth,
    useThemedStyle,
} from "@venuepos/react-common";
import React from "react";
import { View } from "react-native";
import { AdminContainer } from "../container";
import { CashRegisterClaimForm } from "./cash-register-claim-form";

export function CashRegisterClaimScreen() {
    const auth = useAuth();
    auth.enforce("merchant.cash_register.write");

    const styles = useThemedStyle(styleFunc);
    return (
        <AdminContainer>
            <View style={styles.box}>
                <View style={styles.container}>
                    <Surface>
                        <CashRegisterClaimForm />
                    </Surface>
                </View>
            </View>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    box: {
        width: "100%",
        backgroundColor: theme.colors.white,
    },
    container: {
        width: "100%",
        maxWidth: theme.dimensions.maxFormWidth,
        alignSelf: "flex-start",
    },
});
