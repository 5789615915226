import {
    Alert,
    Button,
    CheckBox,
    ConfirmModal,
    DataTable,
    Icon,
    Loading,
    SearchInput,
    StyleFunction,
    Surface,
    useAuth,
    useMerchantConfig,
    useModal,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import { useProductsDeleteMutation, useProductsQuery } from "graphql-sdk";
import { formatAmount } from "lib";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useBarcodeScannerWeb } from "../../hooks/use-barcode-scanner-web";
import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";
import { ImportProductsModal } from "./import-modal";
import { ProductRow } from "./product-table-row";

import type { AvailableLocale } from "locales";
import { useAccessToken } from "../../hooks";
type ScreenProps = RootStackScreenProps<"PRODUCTS">;

export function ProductListScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.product");

    const merchantConfig = useMerchantConfig();
    const [t] = useTranslation();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
    });

    const styles = useThemedStyle(styleFunc);
    const sharedStyles = useThemedStyle(sharedStyleFunc);

    const formRef = useRef<HTMLFormElement>(null);
    const [search, setSearch] = useState<string>("");
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);
    const { render } = useModal();
    const [{ locale }] = useAdminSession(["locale"]);
    const accessToken = useAccessToken();

    // The barcode scanner allows the user to scan for products.
    useBarcodeScannerWeb({
        onDone: (barcode: string) => {
            if (barcode !== "") {
                handleSearchTextChange("barcode=" + barcode);
            }
        },
    });

    // GraphQL
    const { data, loading, error, refetch } = useProductsQuery({
        variables: {
            includeInactive: true,
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
            includeChildren: true,
        },
        fetchPolicy: route.params?.refetch ? "no-cache" : "cache-first",
    });
    const [deleteProducts] = useProductsDeleteMutation();
    const { handleMutationError } = useHandleMutationError();

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list (in case the user changed to a different page)
            onPageChange(0);
        },
        [onPageChange]
    );

    // Actions
    const handleEdit = useCallback(
        (id: string) => {
            navigate("PRODUCT_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleMultiSelect = useCallback(
        (selected: boolean, itemIds: string[]) => {
            let clonedSelectedProducts = [...selectedProducts];
            if (selected) {
                clonedSelectedProducts = [
                    ...clonedSelectedProducts,
                    ...itemIds,
                ];
            } else {
                clonedSelectedProducts = clonedSelectedProducts.filter(
                    i => !itemIds.includes(i)
                );
            }
            setSelectedProducts(clonedSelectedProducts);
        },
        [selectedProducts]
    );

    const deleteSelectedProducts = useCallback(async () => {
        const deleteResponse = await render(onClose => (
            <ConfirmModal
                headerText={t("backoffice.products.delete", "Delete products?")}
                bodyText={t(
                    "backoffice.products.delete_explain",
                    "If you choose to delete these products, any buttons that are added to any of your layouts, which use these products, will be removed.\nThis cannot be undone.\n\nAre you sure, you want to delete these products?"
                )}
                onClose={onClose}
            />
        ));

        if (!deleteResponse) {
            return;
        }

        const ids = selectedProducts.map(productId => productId);

        await handleMutationError(
            async () => await deleteProducts({ variables: { ids } }),
            t("backoffice.products.deleted", "Products deleted"),
            async () => {
                await refetch();
            }
        );

        setSelectedProducts([]);
        setAllRowsSelected(false);
    }, [
        deleteProducts,
        handleMutationError,
        refetch,
        render,
        selectedProducts,
        t,
    ]);

    const handleImportPress = useCallback(async () => {
        await render(onClose => <ImportProductsModal onClose={onClose} />);
    }, [render]);

    function handleDownloadPress() {
        if (!formRef.current) {
            // DOM Form not ready
            return;
        }

        formRef.current.submit();
    }

    if (error) {
        return (
            <Alert type="error">
                {t("common.error", "There was an error: {{errorText}}", {
                    errorText: error.message,
                })}
            </Alert>
        );
    }

    return (
        <AdminContainer testID="productListScreen">
            <View style={styles.container}>
                <View style={styles.actionButtonContainer}>
                    <Button
                        style={styles.button}
                        variant="invert"
                        disabled={!selectedProducts.length}
                        onPress={deleteSelectedProducts}
                        testID="deleteProduct"
                    >
                        {t("common.delete", "Delete")}
                    </Button>
                    <Button
                        style={styles.button}
                        variant="invert"
                        onPress={handleImportPress}
                        testID="importProducts"
                    >
                        {t("backoffice.products.import", "Import")}
                    </Button>
                    <Button
                        style={styles.button}
                        variant="invert"
                        onPress={handleDownloadPress}
                        testID="exportProducts"
                    >
                        {t("backoffice.products.export", "Eksport")}
                    </Button>
                    <Button
                        style={styles.button}
                        type="secondary"
                        onPress={() => {
                            navigate("PRODUCT_CREATE");
                        }}
                        testID="createProduct"
                    >
                        {t("common.create", "Create")}
                    </Button>
                </View>
            </View>
            <SearchInput
                onChange={handleSearchTextChange}
                value={search}
                testID="productSearch"
            />
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title style={sharedStyles.iconColumn}>
                            <CheckBox
                                value={allRowsSelected}
                                onValueChange={value => {
                                    const allItemIds = data!.products.data.map(
                                        p => p.id
                                    );
                                    handleMultiSelect(value, allItemIds);
                                    setAllRowsSelected(value);
                                }}
                            />
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "active" && sortDirection}
                            onPress={() => onSortChange("active")}
                            style={sharedStyles.iconColumn}
                        >
                            {t("backoffice.user.active", "Active")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                            style={sharedStyles.productNameColumn}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "externalId" && sortDirection
                            }
                            onPress={() => onSortChange("externalId")}
                        >
                            {t("backoffice.product.external_id", "EID")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "groupName" && sortDirection
                            }
                            onPress={() => onSortChange("groupName")}
                        >
                            {t("backoffice.product.group", "Group")}
                        </DataTable.Title>
                        <DataTable.Title
                            style={sharedStyles.externalColumn}
                            sortDirection={
                                sortBy === "isExternal" && sortDirection
                            }
                            onPress={() => onSortChange("isExternal")}
                        >
                            {t("backoffice.product.is_external", "External")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "amount" && sortDirection}
                            onPress={() => onSortChange("amount")}
                            numeric
                        >
                            {t(
                                "backoffice.product.price_incl_vat",
                                "Price incl VAT"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            style={sharedStyles.vatColumn}
                            sortDirection={sortBy === "vat_id" && sortDirection}
                            onPress={() => onSortChange("vat_id")}
                            numeric
                        >
                            {t("backoffice.product.vat", "VAT")}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            style={sharedStyles.quantityColumn}
                        >
                            {t(
                                "backoffice.product.inventory_quantity",
                                "Quantity"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            style={sharedStyles.iconColumn}
                        >
                            <Icon name="sort" color={styles.icon.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : (
                        data!.products.data.map((item, index) => (
                            <ProductRow
                                key={item.id}
                                item={item}
                                formattedAmount={formatAmount(
                                    item.amount,
                                    merchantConfig.currency,
                                    {
                                        locale: locale as AvailableLocale,
                                    }
                                )}
                                onSelect={handleEdit}
                                selectedProduct={selectedProducts.includes(
                                    item.id
                                )}
                                onMultiSelectChange={handleMultiSelect}
                                style={
                                    index % 2 === 1 ? styles.oddRow : undefined
                                }
                                locale={locale as AvailableLocale}
                            />
                        ))
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.products.pagination.pages}
                        itemCount={data?.products.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
            <form
                target="_blank"
                ref={formRef}
                action="/api/export/product"
                method="post"
            >
                <input type="hidden" name="language" value={locale} />
                <input
                    type="hidden"
                    name="token"
                    id="token"
                    value={accessToken}
                />
            </form>
        </AdminContainer>
    );
}

export const sharedStyleFunc: StyleFunction = _theme => ({
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 60 },
    productNameColumn: { flexGrow: 1, flexBasis: 150 },
    externalColumn: { flexGrow: 0, flexBasis: 80 },
    vatColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 80 },
    quantityColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 80 },
});

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "space-between",
        marginBottom: theme.spacingScale * 2,
        flexWrap: "wrap",
    },
    actionButtonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    button: {
        alignSelf: "flex-end",
        marginLeft: theme.spacingScale * 2,
    },
    deleteButton: {
        width: "auto",
        minWidth: "auto",
    },
    icon: {
        color: theme.colors.black,
    },
    checkbox: {
        marginVertical: 6,
    },
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
});
