import { ActivityIndicator, View } from "react-native";
import React from "react";
import { useThemedStyle, StyleData, StyleFunction } from "../../theme";
import { Text } from "../text";

export function LoadingScreen({
    style = "light",
    message,
}: {
    style?: "light" | "dark";
    message?: string;
}) {
    const styles = useThemedStyle(
        style === "light" ? lightStyleFunc : darkStyleFunc
    );

    return (
        <View style={styles.container}>
            <ActivityIndicator size="large" color={styles.icon.color} />
            {message && <Text style={styles.message}>{message}</Text>}
        </View>
    );
}

const lightStyleFunc: StyleFunction = (theme, dimensions) => {
    let styling: StyleData = {
        container: {
            backgroundColor: theme.colors.grey50,
            height: "100%",
            justifyContent: "center",
            padding: theme.spacingScale * 4,
        },
        message: {
            marginTop: theme.spacingScale * 4,
            color: theme.colors.textDark,
            textAlign: "center",
        },
        icon: { color: theme.colors.primary },
    };

    if (dimensions.width <= theme.breakpoint.portraitWidth) {
        styling = {
            ...styling,
            container: {
                ...styling.container,
                padding: theme.spacingScale,
            },
        };
    }

    return styling;
};

const darkStyleFunc: StyleFunction = (theme, dimensions) => {
    let styling: StyleData = {
        container: {
            backgroundColor: theme.colors.primary,
            height: "100%",
            justifyContent: "center",
            padding: theme.spacingScale * 4,
        },
        message: {
            marginTop: theme.spacingScale * 4,
            color: theme.colors.white,
            textAlign: "center",
        },
        icon: {
            color: theme.colors.white,
        },
    };

    if (dimensions.width <= theme.breakpoint.portraitWidth) {
        styling = {
            ...styling,
            container: {
                ...styling.container,
                padding: theme.spacingScale,
            },
        };
    }

    return styling;
};
