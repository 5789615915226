import React, { ReactNodeArray, useState, useCallback } from "react";
import { View, TouchableOpacity, StyleProp, ViewStyle } from "react-native";
import { StyleFunction, useThemedStyle } from "../../theme";
import type { ITheme } from "../../theme";
import { Text } from "../text";

export function Tabs({
    labels = [],
    children,
    index: initialIndex = 0,
    headerStyle,
}: {
    labels: string[];
    children: ReactNodeArray;
    index?: number;
    headerStyle?: StyleProp<ViewStyle>;
}) {
    const [index, setIndex] = useState(initialIndex);
    const styles = useThemedStyle(styleFunc);

    const handlePress = useCallback(selectedIndex => {
        setIndex(selectedIndex);
    }, []);

    if (index > children.length) {
        console.warn(`Tab with index ${index} does not exist`);
        return null;
    }

    return (
        <View>
            <View style={[styles.header, headerStyle]}>
                {labels.map((label, idx) => (
                    <Tab
                        key={idx}
                        index={idx}
                        label={label}
                        selected={idx === index}
                        onPress={handlePress}
                        testID={"tab:" + label}
                    />
                ))}
            </View>
            {children[index]}
        </View>
    );
}

function Tab({
    index,
    label,
    selected,
    onPress,
    testID,
}: {
    index: number;
    label: string;
    selected: boolean;
    onPress: (index: number) => void;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);
    const handlePress = useCallback(() => {
        onPress(index);
    }, [index, onPress]);

    return (
        <TouchableOpacity
            onPress={handlePress}
            style={[styles.headerItem, selected && styles.activeTab]}
            testID={testID}
        >
            <Text
                style={[
                    styles.headerItemText,
                    selected && styles.activeTabText,
                ]}
            >
                {label}
            </Text>
        </TouchableOpacity>
    );
}

const styleFunc: StyleFunction = (theme: ITheme) => ({
    header: {
        flexDirection: "row",
        marginBottom: theme.spacingScale * 2,
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.grey250,
    },
    headerItem: {
        paddingHorizontal: theme.spacingScale * 2,
    },
    headerItemText: {
        lineHeight: 32,
        fontFamily: "Montserrat-Bold",
        color: theme.colors.textDark,
    },
    activeTab: {
        borderBottomColor: theme.colors.secondary,
        borderBottomWidth: 2,
    },
    activeTabText: {
        color: theme.colors.secondary,
    },
});
