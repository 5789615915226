import {
    Alert,
    Button,
    CheckBox,
    ColorPickerInput,
    Form,
    InputControl,
    Picker,
    RequiredText,
    StyleFunction,
    Surface,
    TextInput,
    useThemedStyle,
} from "@venuepos/react-common";
import { usePrintersQuery, useVatsQuery } from "graphql-sdk";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Divider } from "react-native-paper";

import type { IProductGroupInput } from "lib";
export function ProductGroupForm(props: {
    form: Form<IProductGroupInput>;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const { data: vatsData } = useVatsQuery();
    const { data: printersData } = usePrintersQuery({
        variables: {
            pagination: {
                pageSize: 999999,
                sort: "name",
                sortDirection: "ASC",
            },
        },
    });

    if (!values || !vatsData || !printersData) {
        return null;
    }

    return (
        <View style={styles.container} testID="productGroup">
            {values.isExternal && (
                <Alert type="info" style={styles.readonlyContainer}>
                    {t(
                        "backoffice.common.readonly_product_group",
                        "This product group is controlled by an external system via the API and most of the properties can only be changed from there."
                    )}
                </Alert>
            )}

            <Surface>
                <InputControl
                    error={errors.name}
                    description={
                        values.isExternal
                            ? t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )
                            : null
                    }
                >
                    <TextInput
                        label={t("common.name", "Name")}
                        placeholder={t(
                            "backoffice.common.enter_name",
                            "Enter name"
                        )}
                        defaultValue={values.name}
                        onChangeText={text => setValue("name", text)}
                        disabled={values.isExternal}
                        testID="name"
                        required={true}
                    />
                </InputControl>
                <InputControl
                    error={errors.externalId}
                    description={
                        values.isExternal
                            ? `${t(
                                  "backoffice.product_group_form.external_id_description",
                                  "Number or ID that is used by another system. Used for reference."
                              )} ${t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )}`
                            : t(
                                  "backoffice.product_group_form.external_id_description",
                                  "Number or ID that is used by another system. Used for reference."
                              )
                    }
                >
                    <TextInput
                        label={t(
                            "backoffice.product_group_form.external_id",
                            "Number"
                        )}
                        placeholder={t(
                            "backoffice.product_group_form.enter_external_id",
                            "Enter number"
                        )}
                        defaultValue={values.externalId || ""}
                        onChangeText={text =>
                            setValue("externalId", text || null)
                        }
                        disabled={values.isExternal}
                        testID="externalId"
                    />
                </InputControl>
                <InputControl
                    error={errors.vatId}
                    description={
                        values.isExternal
                            ? t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )
                            : null
                    }
                >
                    {vatsData && vatsData.vats.data ? (
                        <Picker
                            label={t(
                                "backoffice.product_group_form.vat",
                                "VAT percentage"
                            )}
                            onValueChange={value => {
                                setValue("vatId", value);
                            }}
                            selectedValue={values.vatId || undefined}
                            disabled={values.isExternal}
                            testID="vat"
                            required={true}
                        >
                            <Picker.Item
                                label={t(
                                    "backoffice.account.choose_vat",
                                    "Choose VAT"
                                )}
                                value=""
                            />
                            {vatsData!.vats.data.map(vat => (
                                <Picker.Item
                                    key={vat.id}
                                    value={vat.id}
                                    label={vat.name}
                                    testID={"vat" + vat.name}
                                />
                            ))}
                        </Picker>
                    ) : null}
                </InputControl>
                <InputControl
                    error={errors.excludeFromDiscounts}
                    description={
                        values.isExternal
                            ? t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )
                            : null
                    }
                >
                    <CheckBox
                        label={t(
                            "backoffice.product_group_form.exclude_from_discounts",
                            "Exclude from discounts"
                        )}
                        value={values.excludeFromDiscounts}
                        onValueChange={value =>
                            setValue("excludeFromDiscounts", value)
                        }
                        disabled={values.isExternal}
                        testID="excludeFromDiscounts"
                    />
                </InputControl>

                {values.isExternal ? <Divider style={styles.divider} /> : null}

                {/** The color picker is placed (position: absolute) above the input field, when it is shown,
                         but the submit button overlays the picker, so we have to put a zIndex on
                         the two conflicting elements: the InputControl and the Button.
                         It does not work with a zIndex on the styles in the ColorPickerInput component :sadface: */}
                <InputControl
                    error={errors.color}
                    style={styles.colorPickerElevation}
                    description={t(
                        "backoffice.product_group_form.color_description",
                        "Write a color code or select the color for products from this product group on a product layout. The color can also be changed from an external system."
                    )}
                >
                    <ColorPickerInput
                        label={t(
                            "backoffice.product_group_form.color",
                            "Color"
                        )}
                        defaultColor={values.color}
                        onChangeColor={newColor => {
                            setValue("color", newColor ?? "");
                        }}
                        testID="color"
                    />
                </InputControl>
                <InputControl error={errors.printerId || undefined}>
                    {printersData && printersData.printers.data ? (
                        <Picker
                            label={t(
                                "backoffice.product_form.printer.do_print",
                                "Send to printer for order handling"
                            )}
                            onValueChange={value => {
                                setValue("printerId", value);
                            }}
                            selectedValue={values.printerId || undefined}
                            testID="printer"
                        >
                            <Picker.Item
                                label={t(
                                    "backoffice.product_form.printer.do_not_print",
                                    "Do not print"
                                )}
                                value=""
                                testID="noPrinter"
                            />
                            {printersData.printers.data
                                .filter(itr => itr.class === "Generic")
                                .filter(itr => itr.type !== "INTERNAL")
                                .map(printer => {
                                    return (
                                        <Picker.Item
                                            key={printer.id}
                                            value={printer.id}
                                            label={printer.name}
                                            testID={
                                                "productGroup:" + printer.name
                                            }
                                        />
                                    );
                                })}
                        </Picker>
                    ) : null}
                </InputControl>

                {/** See the comment above regarding the zIndex/elevation. */}
                <Button
                    onPress={handleSubmit(props.onSubmit)}
                    style={styles.submitButtonElevation}
                    testID="save"
                >
                    {t(props.submitButton[0], props.submitButton[1])}
                </Button>

                <RequiredText />
            </Surface>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        width: theme.dimensions.maxFormWidth,
    },
    divider: {
        marginBottom: theme.spacingScale * 2,
    },

    colorPickerElevation: {
        zIndex: 3,
    },
    submitButtonElevation: {
        zIndex: 2,
    },

    readonlyContainer: {
        marginBottom: theme.spacingScale * 2,
    },
});
