import React, { Context, ProviderProps } from "react";
import { draggable } from "./draggable";
import { droppable } from "./droppable";

import type {
    DNDContext,
    DraggableProps,
    DroppableProps,
    Draggable,
    Droppable,
    State,
    DndId,
    Position,
    DNDRegistration,
} from "./types";

interface Props {
    children: ProviderProps<DNDContext>["children"];
}

type DNDContextType = Context<DNDContext>;

export interface DragAndDropContext {
    Consumer: DNDContextType["Consumer"];
    Provider: React.ComponentType<Props>;
    Draggable: React.ForwardRefExoticComponent<DraggableProps>;
    Droppable: React.ForwardRefExoticComponent<DroppableProps>;
}

function createDndContext(): DragAndDropContext {
    const context = React.createContext<DNDContext>({} as any);
    const { Provider, Consumer } = context;

    class DragAndDropProvider
        extends React.Component<Props, State>
        implements DNDRegistration
    {
        state: State = {
            draggables: [],
            droppables: [],
            dragOffset: [0, 0],
        };

        registerDraggable = (id: DndId, data: Partial<Draggable>) => {
            const existing = this.getDraggable(id);
            if (existing) {
                throw new Error(`Draggable has already been registered.`);
            }

            const _draggable: Draggable = {
                id,
                layout: { x: 0, y: 0, width: 0, height: 0 },
                dragging: false,
                ...data,
            };

            this.setState(({ draggables }) => ({
                draggables: [...draggables, _draggable],
            }));
        };

        updateDraggable = (id: DndId, data: Draggable) => {
            this.setState(({ draggables }) => ({
                draggables: draggables.map(_draggable => {
                    if (_draggable.id === id) {
                        return {
                            ..._draggable,
                            ...data,
                        };
                    }

                    return _draggable;
                }),
            }));
        };

        unregisterDraggable = (id: DndId) => {
            this.setState(({ draggables }) => ({
                draggables: draggables.filter(
                    _draggable => _draggable.id !== id
                ),
            }));
        };

        registerDroppable = (id: DndId, data: Partial<Droppable>) => {
            const existing = this.getDroppable(id);
            if (existing) {
                throw new Error(`Droppable has already been registered.`);
            }

            const _droppable: Droppable = {
                id,
                ...data,
                layout: { x: 0, y: 0, width: 0, height: 0 },
            };

            this.setState(({ droppables }) => ({
                droppables: [...droppables, _droppable],
            }));
        };

        unregisterDroppable = (id: DndId) => {
            this.setState(({ droppables }) => ({
                droppables: droppables.filter(
                    _droppable => _droppable.id !== id
                ),
            }));
        };

        updateDroppable = (id: DndId, data: Droppable) => {
            this.setState(({ droppables }) => ({
                droppables: droppables.map(_droppable => {
                    if (_droppable.id === id) {
                        return {
                            ..._droppable,
                            ...data,
                        };
                    }

                    return _droppable;
                }),
            }));
        };

        getDraggable = (id?: DndId) => {
            return this.state.draggables.find(
                _draggable => _draggable.id === id
            );
        };

        getDroppable = (id?: DndId) => {
            return this.state.droppables.find(
                _droppable => _droppable.id === id
            );
        };

        getDroppableInArea = ({ x, y }: Position) => {
            const _x = x - this.state.dragOffset[0];
            const _y = y - this.state.dragOffset[1];

            return this.state.droppables.find(({ layout }) => {
                return (
                    layout &&
                    _x >= layout.x &&
                    _y >= layout.y &&
                    _x <= layout.x + layout.width &&
                    _y <= layout.y + layout.height
                );
            });
        };

        handleDragStart = (id: DndId, position: Position) => {
            const _draggable = this.getDraggable(id);

            if (_draggable) {
                const { layout } = _draggable;
                const center = [
                    layout.x + Math.round(layout.width / 2),
                    layout.y + Math.round(layout.height / 2),
                ];

                const dragOffset = [
                    position.x - center[0],
                    position.y - center[1],
                ];

                this.setState({
                    currentDragging: id,
                    dragOffset,
                });

                if (_draggable.onDragStart) {
                    _draggable.onDragStart();
                }
            }
        };

        handleDragEnd = (draggingId: DndId, position: Position) => {
            const _droppable = this.getDroppableInArea(position);
            const _draggable = this.getDraggable(draggingId);

            if (_draggable && _droppable && _droppable.onDrop) {
                _droppable.onDrop(_draggable, position);
            }

            if (_draggable && _draggable.onDragEnd) {
                _draggable.onDragEnd(_droppable);
            }

            this.setState({ currentDragging: undefined, dragOffset: [0, 0] });
        };

        handleDragMove = (draggingId: DndId, position: Position) => {
            const currentDroppable = this.getDroppableInArea(position);
            const _draggable = this.getDraggable(draggingId)!;
            const prevDroppingId = this.state.currentDropping;

            if (currentDroppable) {
                if (
                    currentDroppable.id !== this.state.currentDropping &&
                    _draggable
                ) {
                    this.setState({ currentDropping: currentDroppable.id });

                    if (currentDroppable.onEnter) {
                        currentDroppable.onEnter(_draggable, position);
                    }
                }
            } else if (this.state.currentDropping) {
                if (prevDroppingId) {
                    const prevDroppable = this.getDroppable(prevDroppingId);
                    if (prevDroppable && prevDroppable.onLeave) {
                        prevDroppable.onLeave(_draggable, position);
                    }
                }

                this.setState({ currentDropping: undefined });
            }
        };

        render() {
            return (
                <Provider
                    value={{
                        currentDragging: this.state.currentDragging,
                        currentDropping: this.state.currentDropping,
                        droppables: this.state.droppables,
                        draggables: this.state.draggables,
                        dragOffset: this.state.dragOffset,
                        registerDraggable: this.registerDraggable,
                        updateDraggable: this.updateDraggable,
                        unregisterDraggable: this.unregisterDraggable,
                        registerDroppable: this.registerDroppable,
                        updateDroppable: this.updateDroppable,
                        unregisterDroppable: this.unregisterDroppable,
                        handleDragStart: this.handleDragStart,
                        handleDragEnd: this.handleDragEnd,
                        handleDragMove: this.handleDragMove,
                        getDraggable: this.getDraggable,
                        getDroppable: this.getDroppable,
                    }}
                >
                    {this.props.children}
                </Provider>
            );
        }
    }

    const draggableRef = draggable(Consumer);
    const droppableRef = droppable(Consumer);

    return {
        Provider: DragAndDropProvider,
        Draggable: draggableRef,
        Droppable: droppableRef,
        Consumer,
    };
}

export { createDndContext };
