import { Link } from "@react-navigation/native";
import {
    Alert,
    DataTable,
    Headline,
    Loading,
    SearchInput,
    Spacer,
    StyleFunction,
    Surface,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import { useTagsByGroupLazyQuery } from "graphql-sdk";
import { ITagGroup } from "lib";
import { useTranslation } from "locales";
import React, { useCallback, useEffect, useState } from "react";

export function GroupTags({ group }: { group: ITagGroup["id"] }) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const { page, pageSize, sortBy, sortDirection, onPageChange } =
        usePagination({
            initialPageSize: 10,
            initialSortBy: "identifier",
            initialSortDirection: "ASC",
        });

    const [getTagsByGroup, { error, loading, data }] =
        useTagsByGroupLazyQuery();

    const [search, setSearch] = useState<string>("");
    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list
            onPageChange(0);
        },
        [onPageChange]
    );

    useEffect(() => {
        if (!group) {
            return;
        }

        getTagsByGroup({
            variables: {
                groupId: group,
                pagination: {
                    page,
                    pageSize,
                    sort: sortBy,
                    sortDirection: sortDirection,
                },
                search: {
                    query: search,
                },
            },
        });
    }, [getTagsByGroup, group, page, pageSize, search, sortBy, sortDirection]);

    return (
        <Surface>
            <Headline size="h5">
                {t("backoffice.tags.in_group", "Tags in this group")}
            </Headline>
            <SearchInput
                onChange={handleSearchTextChange}
                testID="tagSearch"
                onClear={() => setSearch("")}
            />
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title>
                        {t("common.name", "Name")}
                    </DataTable.Title>
                    <DataTable.Title>
                        {t("common.account", "Account")}
                    </DataTable.Title>
                    <DataTable.Title>
                        {t("common.customer", "Customer")}
                    </DataTable.Title>
                </DataTable.Header>
                {error ? (
                    <Alert type="error">
                        {t(
                            "backoffice.error.from_server",
                            "There was an error: {{errorText}}",
                            {
                                errorText: error.message,
                            }
                        )}
                    </Alert>
                ) : loading ? (
                    <>
                        <Spacer />
                        <Loading />
                        <Spacer />
                    </>
                ) : (
                    data?.tagsByGroup.data.map((item, index) => (
                        <DataTable.Row
                            style={index % 2 === 0 ? styles.oddRow : undefined}
                            key={item.id}
                        >
                            <DataTable.Cell>
                                <Link
                                    to={{
                                        screen: "TAG_EDIT",
                                        params: { id: item.id },
                                    }}
                                    style={styles.textLink}
                                >
                                    {item.name || item.identifier}
                                </Link>
                            </DataTable.Cell>
                            <DataTable.Cell>
                                {item.account && (
                                    <Link
                                        to={{
                                            screen: "ACCOUNT_VIEW",
                                            params: { id: item.account.id },
                                        }}
                                        style={styles.textLink}
                                        numberOfLines={2}
                                    >
                                        {item.account.name}
                                    </Link>
                                )}
                            </DataTable.Cell>
                            <DataTable.Cell>
                                {item.customer && (
                                    <Link
                                        to={{
                                            screen: "CUSTOMER_VIEW",
                                            params: { id: item.customer.id },
                                        }}
                                        style={styles.textLink}
                                        numberOfLines={2}
                                    >
                                        {item.customer.name}
                                    </Link>
                                )}
                            </DataTable.Cell>
                        </DataTable.Row>
                    ))
                )}
                <DataTable.Pagination
                    onPageChange={onPageChange}
                    pageSize={pageSize}
                    page={page}
                    numberOfPages={data?.tagsByGroup.pagination.pages}
                    itemCount={data?.tagsByGroup.pagination.resultCount}
                    disablePageSizeSelector
                />
            </DataTable>
        </Surface>
    );
}

const styleFunc: StyleFunction = theme => ({
    oddRow: {
        backgroundColor: theme.colors.grey50,
    },
    textLink: {
        ...theme.styles.link,
    },
});
