import React from "react";
import { useTheme } from "../../theme";
import { View } from "react-native";

export function Spacer({ space = 1 }: { space?: number }) {
    const theme = useTheme();
    return (
        <View
            style={{
                marginTop: space * theme.spacingScale,
                marginRight: space * theme.spacingScale,
            }}
        />
    );
}
