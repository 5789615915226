import {
    ProductButton,
    FunctionButton,
    LayoutButton,
    GridDimensions,
    ButtonPosition,
} from "lib";

const findAvailableButtonPosition = (
    buttonPositions: string[],
    startAtPosition: number,
    columns: GridDimensions["columns"]
): ButtonPosition => {
    let nextAvailableButtonPosition = buttonPositions.findIndex(
        (position, index) => index >= startAtPosition && !position
    );

    // If there is no available spot in this section, then return
    if (nextAvailableButtonPosition === -1) {
        return { x: -1, y: -1 };
    }

    const x = nextAvailableButtonPosition % columns;
    const y = Math.floor(nextAvailableButtonPosition / columns);

    return { x, y };
};

export const positionButtonInFirstAvailableSpot = (
    button: ProductButton | FunctionButton,
    buttonPositions: LayoutButton["id"][],
    startAtPosition: number,
    columns: GridDimensions["columns"]
): ProductButton | FunctionButton => {
    const { x, y } = findAvailableButtonPosition(
        buttonPositions,
        startAtPosition,
        columns
    );

    button.x = x;
    button.y = y;
    return button;
};
