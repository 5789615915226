import React from "react";
import { useTranslation } from "react-i18next";
import { AdminContainer } from "../container";
import { Tabs } from "@venuepos/react-common";
import { useAuth } from "@venuepos/react-common";
import { View } from "react-native";
import { TagsTab } from "./tags-tab";
import { TagGroupsTab } from "./groups/groups-tab";

export function Tags() {
    const auth = useAuth();
    auth.enforce("merchant.tag");

    const [t] = useTranslation();

    return (
        <AdminContainer>
            <View>
                <Tabs
                    labels={[
                        t("common.tags", "Tags"),
                        t("common.tag_groups", "Tag groups"),
                    ]}
                >
                    <TagsTab />
                    <TagGroupsTab />
                </Tabs>
            </View>
        </AdminContainer>
    );
}
