import React from "react";
import { Link } from "@react-navigation/native";
import {
    DataTable,
    Headline,
    Loading,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQDashboard } from "graphql-sdk";
import { formatAmount } from "lib";
import { AvailableLocale } from "locales";
import { useTranslation } from "react-i18next";
import { useAdminSession } from "../../../session";
import { dashboardStyleFunc } from "../styles";
import { ScrollView } from "react-native-gesture-handler";

export function Bookkeeping({ data }: { data?: GQDashboard }) {
    const merchantConfig = useMerchantConfig();
    const [t] = useTranslation();
    const sharedStyles = useThemedStyle(dashboardStyleFunc);
    const [{ locale }] = useAdminSession(["locale"]);

    return (
        <>
            <Headline size="h5">
                {t("dashboard.widgets.bookkeeping", "Bookkeeping")}
            </Headline>
            {data ? (
                <ScrollView>
                    <DataTable>
                        <DataTable.Header>
                            <DataTable.Title>
                                {t("common.name", "Name")}
                            </DataTable.Title>
                            <DataTable.Title numeric>
                                {t("common.amount", "Amount")}
                            </DataTable.Title>
                        </DataTable.Header>
                        {data.accounts.length === 0 ? (
                            <DataTable.Row style={sharedStyles.darkRow}>
                                <DataTable.Cell>
                                    {t(
                                        "dashboard.no_data",
                                        "No data for the selected date."
                                    )}
                                </DataTable.Cell>
                            </DataTable.Row>
                        ) : (
                            data.accounts.map((row, index) => (
                                <DataTable.Row
                                    key={`account-${row.id}`}
                                    style={
                                        index % 2 === 0
                                            ? sharedStyles.darkRow
                                            : undefined
                                    }
                                >
                                    <DataTable.Cell>
                                        <Link
                                            to={{
                                                screen: "BOOKKEEPING_ACCOUNT_VIEW",
                                                params: { id: row.id },
                                            }}
                                            style={sharedStyles.linkText}
                                        >
                                            {row.name}
                                        </Link>
                                    </DataTable.Cell>
                                    <DataTable.Cell numeric>
                                        {formatAmount(
                                            row.amount,
                                            merchantConfig.currency,
                                            {
                                                locale: locale as AvailableLocale,
                                            }
                                        )}
                                    </DataTable.Cell>
                                </DataTable.Row>
                            ))
                        )}
                    </DataTable>
                </ScrollView>
            ) : (
                <Loading message={t("common.loading", "Loading")} />
            )}
        </>
    );
}
