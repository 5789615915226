import type { ValidationSchema } from "../validation";
import type { GQCustomerGroupInput, GQCustomerInput } from "graphql-sdk";
import type { ICustomerGroupInput } from "./icustomer-group";
import type { ICustomerInput } from "./icustomer";

export const schemaCustomer: ValidationSchema<GQCustomerInput> = {
    name: { required: true },
    address: { required: true },
    zip: { required: true, number: true },
    city: { required: true },
    email: { email: true },
};

export const defaultCustomer: ICustomerInput = {
    name: "",
    groupId: "",
    address: "",
    zip: "",
    city: "",
    email: "",
    phoneNumber: "",
    cvrNumber: "",
    contactName: "",
    comment: "",
};

export const schemaCustomerGroup: ValidationSchema<GQCustomerGroupInput> = {
    name: { required: true },
};

export const defaultCustomerGroup: ICustomerGroupInput = {
    name: "",
};
