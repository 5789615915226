import type { ValidationSchema } from "../validation";
import type { IProductGroupInput } from "./iproduct-group";
import type { IInventoryProductInput, IProductInput } from "./iproduct";

export const schemaProduct: ValidationSchema<IProductInput> = {
    groupId: { required: true },
    name: { required: true },
    amount: { required: true, number: true },
    minimumAmount: {
        required: true,
        number: true,
        lessOrEqualToPositive: {
            field: "amount",
            message: [
                "backoffice.product_form.minimum_amount_needs_to_be_lower_than_amount",
                "",
            ],
        },
        minValue: {
            value: 0,
            message: [
                "backoffice.product_form.minimum_amount_can_not_be_a_negative_value",
                "",
            ],
        },
    },
    costAmount: {
        number: true,
    },
};

export const schemaProductGroup: ValidationSchema<IProductGroupInput> = {
    name: { required: true },
    color: { required: true, color: true },
    vatId: { required: true },
};

export const schemaInventoryProduct: ValidationSchema<IInventoryProductInput> =
    {
        quantity: { required: true, number: true },
    };

export const defaultProductGroup: IProductGroupInput = {
    externalId: null,
    name: "",
    vatId: null,
    color: "#73B22E",
    printerId: null,
    excludeFromDiscounts: false,
    isExternal: false,
};

export const defaultProduct: IProductInput = {
    parentId: null,
    groupId: "",
    externalId: null,
    name: "",
    amount: 0,
    minimumAmount: 0,
    costAmount: 0,
    ownVatId: null,
    onlineRequired: false,
    active: true,
    isExternal: false,
    ownExcludeFromDiscounts: false,
    barcode: null,
    barcodes: [],
    buttonText: "",
    printerId: null,
    inheritPrinter: true,
};

export const defaultInventoryProduct: IInventoryProductInput = {
    quantity: 0,
    type: "quantity",
};
