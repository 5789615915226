import type { IIntegrationInput } from "lib";

export const defaultIntegration: IIntegrationInput = {
    merchantId: "",
    name: "",
    apiKey: "",
    type: undefined,
    active: true,
    merchantChartOfAccounts: [],
};
