import {
    Button,
    InputControl,
    RequiredText,
    Surface,
    TextInput,
    useThemedStyle,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import React from "react";

import type {
    Form as FormComponent,
    StyleFunction,
} from "@venuepos/react-common";
import type { GQTagGroupInput } from "graphql-sdk";
export function Form(props: {
    form: FormComponent<GQTagGroupInput>;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;

    if (!values) {
        return null;
    }

    return (
        <Surface style={styles.container}>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name}
                    onChangeText={text => setValue("name", text)}
                    required
                />
            </InputControl>
            <Button onPress={handleSubmit(props.onSubmit)}>
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>
            <RequiredText />
        </Surface>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        minWidth: 400,
        maxWidth: theme.dimensions.maxFormWidth,
    },
});
