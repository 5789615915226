import React, { useMemo } from "react";
import { StyleProp, ViewStyle, View, TextStyle } from "react-native";
import { Currency, formatAmount, FunctionButton, ProductButton } from "lib";
import {
    useThemedStyle,
    Text,
    StyleFunction,
    IconButton,
} from "@venuepos/react-common";
import { useAdminSession } from "../../../session";
import type { AvailableLocale } from "locales";

export const LayoutButtonComponent = React.memo(
    ({
        button,
        onPress,
        onDelete,
        onSendTo,
        textStyle,
        buttonStyle,
        currency,
        testID,
    }: {
        button: ProductButton | FunctionButton;
        onPress: () => void;
        onDelete: () => void;
        onSendTo: () => void;
        textStyle: StyleProp<TextStyle>;
        buttonStyle: StyleProp<ViewStyle>;
        currency: Currency;
        testID?: string;
    }) => {
        const styles = useThemedStyle(styleFunc);
        const [{ locale }] = useAdminSession(["locale"]);

        const formattedAmount = useMemo(() => {
            if (typeof (button as any).amount !== "number") {
                return null;
            }

            return (
                <Text
                    style={[
                        styles.buttonLabel,
                        textStyle,
                        styles.productAmount,
                    ]}
                    numberOfLines={1}
                >
                    {formatAmount((button as any).amount ?? 0, currency, {
                        locale: locale as AvailableLocale,
                    })}
                </Text>
            );
        }, [
            button,
            currency,
            locale,
            styles.buttonLabel,
            styles.productAmount,
            textStyle,
        ]);

        return (
            <View
                style={[styles.buttonStyle, buttonStyle]}
                testID={`layout:button:${button.buttonType}`}
            >
                <View style={styles.helperContainer}>
                    <View style={styles.helperContainerLeft}>
                        <IconButton
                            name="settings"
                            size="small"
                            onPress={onPress}
                            testID="settings"
                        />
                        <IconButton
                            name="send-to"
                            size="small"
                            onPress={onSendTo}
                            testID="sendTo"
                        />
                    </View>
                    <View style={styles.helperContainerRight}>
                        <IconButton
                            name="delete"
                            size="small"
                            onPress={onDelete}
                            testID="delete"
                        />
                    </View>
                </View>
                <View style={styles.contentContainer}>
                    <Text
                        style={[styles.buttonLabel, textStyle]}
                        testID={testID}
                        numberOfLines={2}
                    >
                        {button.label || button.metaData?.buttonText}
                    </Text>
                    {formattedAmount}
                </View>
            </View>
        );
    },
    (newProps, oldProps) => newProps.button === oldProps.button
);

const styleFunc: StyleFunction = theme => ({
    buttonStyle: {
        borderRadius: theme.borderRadiusTiny,
    },
    buttonLabel: {
        ...theme.fonts.medium,
        fontSize: 16,
        textAlign: "center",
        fontWeight: "bold",
    },
    productAmount: {
        ...theme.fonts.regular,
    },
    contentContainer: {
        flex: 1,
        justifyContent: "center",
        padding: theme.spacingScale,
        userSelect: "none",
        borderWidth: 1,
        borderColor: theme.colors.semiTransparentBlack,
        borderTopWidth: 0,
        borderBottomRightRadius: theme.borderRadiusTiny,
        borderBottomLeftRadius: theme.borderRadiusTiny,
    },
    helperContainer: {
        flexDirection: "row",
        backgroundColor: theme.colors.semiTransparentBlack,
        borderTopStartRadius: theme.borderRadiusTiny,
        borderTopEndRadius: theme.borderRadiusTiny,
    },
    helperContainerLeft: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    helperContainerRight: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-end",
        borderTopEndRadius: theme.borderRadiusTiny,
    },
});
