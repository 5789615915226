import React from "react";
import {
    Button,
    Form,
    InputControl,
    NumberInput,
    Picker,
    RequiredText,
    TextInput,
} from "@venuepos/react-common";
import { useTranslation } from "react-i18next";
import { useMerchantFormQuery } from "graphql-sdk";
import type {
    IMerchantCreate,
    SupportedGiftcardVatBookkeepingMethodType,
} from "lib";
import {
    supportedCurrencies,
    SupportedCurrenciesType,
    supportedGiftcardVatBookkeepingMethods,
} from "lib";

export function CreateForm(props: {
    form: Form<IMerchantCreate>;
    onSubmit: () => void;
    submitButton: [string, string];
    usernameAvailable: boolean | undefined;
}) {
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const { data } = useMerchantFormQuery();

    if (!values || !data) {
        return null;
    }

    return (
        <>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name}
                    onChangeText={text => setValue("name", text)}
                    required={true}
                />
            </InputControl>

            <InputControl
                error={
                    props.usernameAvailable === false
                        ? t(
                              "backoffice.user_form.email_username_taken",
                              "Email/username is already taken. Choose another one and try again."
                          )
                        : errors.email
                }
            >
                <TextInput
                    label={t("backoffice.merchant_form.email", "Email")}
                    placeholder={t(
                        "backoffice.merchant_form.enter_email",
                        "Enter an email. This will also be the user name."
                    )}
                    defaultValue={values.email}
                    onChangeText={text => setValue("email", text)}
                    required={true}
                />
            </InputControl>

            <InputControl>
                <Picker
                    label={t("backoffice.merchant_form.currency", "Currency")}
                    onValueChange={value => {
                        setValue("currency", value as SupportedCurrenciesType);
                    }}
                    selectedValue={values.currency}
                >
                    {supportedCurrencies.map(currency => (
                        <Picker.Item
                            key={currency}
                            value={currency}
                            label={currency}
                        />
                    ))}
                </Picker>
            </InputControl>

            <InputControl error={errors.giftcardVatBookkeepingMethod}>
                <Picker
                    label={t(
                        "backoffice.merchant_form.giftcard_vat_bookkeeping_method",
                        "Giftcard VAT bookkeeping method"
                    )}
                    onValueChange={value => {
                        setValue(
                            "giftcardVatBookkeepingMethod",
                            value as SupportedGiftcardVatBookkeepingMethodType
                        );
                    }}
                    selectedValue={
                        values.giftcardVatBookkeepingMethod as string
                    }
                >
                    {supportedGiftcardVatBookkeepingMethods.map(method => (
                        <Picker.Item
                            key={method}
                            value={method}
                            label={t(
                                `entity_config.value.giftcard_vat_bookkeeping_method.${method}`,
                                `${method}`
                            )}
                        />
                    ))}
                </Picker>
            </InputControl>

            <InputControl
                error={errors.vat}
                description={t(
                    "backoffice.merchant_form.vat_description",
                    "A value in percentage. I.e. if the VAT is 25%, write 25."
                )}
            >
                <NumberInput
                    label={t("backoffice.merchant_form.vat", "VAT")}
                    placeholder={t(
                        "backoffice.merchant_form.enter_vat",
                        "Enter VAT percentage"
                    )}
                    defaultValue={values.vat}
                    onChange={value => setValue("vat", Number(value))}
                    decimals={2}
                    min={0}
                    max={100}
                    unit={t("backoffice.merchant_form.unit_percentage", "%")}
                />
            </InputControl>

            <Button onPress={handleSubmit(props.onSubmit)}>
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>

            <RequiredText />
        </>
    );
}
