import { ReportDefinition, ReportTypes } from "./types";

export const productSalesDefinition: ReportDefinition = {
    translationKey: "report.product_sales.name",
    reportType: ReportTypes.PRODUCT_SALES,
    primaryTable: "rm_invoice",
    joins: {
        cash_register: {
            sql: "LEFT JOIN cash_register ON cash_register.id = rm_invoice.cash_register_id",
        },
        department: {
            sql: "LEFT JOIN department ON department.id = rm_invoice.department_id",
        },
        invoice_item: {
            sql: "LEFT JOIN rm_invoice_item ON rm_invoice_item.invoice_id = rm_invoice.id",
        },
        product: {
            sql: "LEFT JOIN product ON product.id = rm_invoice_item.product_id",
            dependOn: ["invoice_item"],
        },
        product_group: {
            sql: "LEFT JOIN product_group ON product_group.id = rm_invoice_item.product_group_id",
            dependOn: ["invoice_item"],
        },
        user: {
            sql: 'JOIN "user" ON "user".id = rm_invoice.user_id',
        },
    },
    fields: {
        report_date: {
            translationKey: "report.columns.report_date",
            type: "CUSTOM",
            content:
                "TO_CHAR(rm_invoice.created_at::TIMESTAMPTZ AT TIME ZONE {{time_zone}}, 'YYYY-MM-DD')",
            selectedByDefault: false,
            formatAs: "DATE",
        },
        department_name: {
            translationKey: "report.columns.department_name",
            type: "SIMPLE",
            field: { table: "department", column: "name" },
            selectedByDefault: true,
            dependOnJoin: ["department"],
            default: "No department",
        },
        cash_register: {
            translationKey: "report.columns.cash_register",
            type: "SIMPLE",
            field: { table: "cash_register", column: "name" },
            selectedByDefault: false,
            dependOnJoin: ["cash_register"],
            default: "No cash register",
        },
        product_group_name: {
            translationKey: "report.columns.product_group_name",
            type: "SIMPLE",
            field: { table: "product_group", column: "name" },
            selectedByDefault: true,
            required: true,
            dependOnJoin: ["product_group"],
            default: "No product group",
        },
        product_name: {
            translationKey: "report.columns.product_name",
            type: "CUSTOM",
            content:
                "COALESCE(rm_invoice_item.name, product.name, 'No product')",
            selectedByDefault: true,
            required: true,
            dependOnJoin: ["product", "invoice_item"],
        },
        quantity: {
            translationKey: "report.columns.quantity",
            type: "SUM_FLOAT",
            field: { table: "rm_invoice_item", column: "quantity" },
            selectedByDefault: true,
            required: true,
            dependOnJoin: ["invoice_item"],
            formatAs: "QUANTITY",
        },
        turnover_amount: {
            translationKey: "report.columns.turnover_amount",
            type: "SUM_AMOUNT",
            field: { table: "rm_invoice_item", column: "amount" },
            selectedByDefault: true,
            dependOnJoin: ["invoice_item"],
            formatAs: "AMOUNT",
        },
        cost_unit_price: {
            translationKey: "report.columns.cost_unit_price",
            type: "CUSTOM",
            content:
                "ROUND(COALESCE(product.cost_amount, 0) / 100::numeric, 2)::real",
            selectedByDefault: true,
            dependOnJoin: [
                "inventory_product",
                "product_to_inventory_product",
                "product",
            ],
            formatAs: "AMOUNT",
        },
        cost_amount: {
            translationKey: "report.columns.cost_amount_total",
            type: "SUM_AMOUNT",
            field: { table: "rm_invoice_item", column: "cost_amount" },
            selectedByDefault: true,
            dependOnJoin: ["invoice_item"],
            formatAs: "AMOUNT",
        },
        user_name: {
            translationKey: "report.columns.user_name",
            type: "SIMPLE",
            field: { table: "user", column: "username" },
            selectedByDefault: false,
            dependOnJoin: ["user"],
        },
    },
    filters: {
        cash_register: {
            field: { table: "cash_register", column: "id" },
            translationKey: "report.columns.cash_register",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["cash_register"],
        },
        department: {
            field: { table: "department", column: "id" },
            translationKey: "report.columns.department",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["department"],
        },
        user: {
            field: { table: "user", column: "id" },
            translationKey: "report.columns.user_name",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["user"],
        },
        merchant_id: {
            field: { table: "rm_invoice", column: "merchant_id" },
            translationKey: "report.columns.merchant_id",
            type: "EQUALS",
            internalUse: true,
        },
        report_date: {
            field: {
                table: "rm_invoice",
                column: "created_at",
            },
            translationKey: "report.columns.created_at",
            type: "BETWEEN",
        },
        product_group: {
            field: { table: "product_group", column: "id" },
            translationKey: "report.columns.product_group_name",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["product_group"],
        },
        product: {
            field: { table: "product", column: "id" },
            translationKey: "report.columns.product_name",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["product"],
        },
    },
    grouping: {
        department: {
            translationKey: "report.columns.department",
            type: "DEPARTMENT",
            dependOnJoin: ["department"],
        },
        report_date: {
            translationKey: "report.columns.report_date",
            type: "LABEL",
        },
        product_name: {
            translationKey: "report.columns.product_name",
            type: "LABEL",
            internalUse: true,
        },
        product_group_name: {
            translationKey: "report.columns.product_group_name",
            type: "LABEL",
            internalUse: true,
        },
        user_name: {
            translationKey: "report.columns.user_name",
            type: "USER",
            dependOnJoin: ["user"],
        },
        year: {
            translationKey: "report.columns.year",
            type: "YEAR",
            field: { table: "rm_invoice", column: "created_at" },
        },
        month: {
            translationKey: "report.columns.month",
            type: "MONTH",
            field: { table: "rm_invoice", column: "created_at" },
        },
        day: {
            translationKey: "report.columns.day",
            type: "DAY",
            field: { table: "rm_invoice", column: "created_at" },
        },
        hour: {
            translationKey: "report.columns.hour",
            type: "HOUR",
            field: { table: "rm_invoice", column: "created_at" },
        },
    },
    ordering: {
        report_date: {
            translationKey: "report.columns.report_date",
            type: "CUSTOM",
            content: "report_date",
        },
        department_name: {
            translationKey: "report.columns.department_name",
            type: "CUSTOM",
            content: "department_name",
        },
        product_name: {
            translationKey: "report.columns.product_name",
            type: "CUSTOM",
            content: "product_name",
        },
        product_group_name: {
            translationKey: "report.columns.product_group_name",
            type: "CUSTOM",
            content: "product_group_name",
        },
    },
};
