import React, { useState } from "react";
import SliderImpl from "@react-native-community/slider";
import { useTheme } from "../../theme";
import {
    LayoutChangeEvent,
    StyleProp,
    TextStyle,
    View,
    ViewStyle,
} from "react-native";
import { StyleFunction, Text, useThemedStyle } from "../..";

interface SliderProps {
    value: number | undefined;
    minimumValue?: number;
    maximumValue?: number;
    minimumTrackTintColor?: string | undefined;
    maximumTrackTintColor?: string | undefined;
    thumbTintColor?: string | undefined;
    step?: number | undefined;
    onValueChange?: (value: number) => void;
    containerStyle?: StyleProp<ViewStyle>;
    markerStyle?: StyleProp<TextStyle>;
    marks?: any[];
}

export function Slider(props: SliderProps, key: string | number) {
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);
    const [sliderWidth, setSliderWidth] = useState(100);

    if (!props.minimumValue || !props.maximumValue) {
        return null;
    }

    const onLayout = (e: LayoutChangeEvent) => {
        setSliderWidth(e.nativeEvent.layout.width);
    };

    function getMarkPosition(value: number) {
        // If there is no minimumValue, then back off
        if (!props.minimumValue) {
            return { left: "auto" };
        }

        // If there is no maximumValue, then back off
        if (!props.maximumValue) {
            return { left: "auto" };
        }

        if (value === props.minimumValue) {
            return {
                left: 5,
            };
        }

        if (value === props.maximumValue) {
            return {
                left: sliderWidth - 5,
            };
        }

        return {
            left:
                (sliderWidth * (value - props.minimumValue)) /
                (props.maximumValue - props.minimumValue),
        };
    }

    function renderMark(
        mark: { name: string; value: number },
        index: number,
        markWidth: number
    ) {
        const markStyle = [
            styles.mark,
            props.markerStyle,
            {
                width: markWidth,
                transform: [{ translateX: -markWidth / 2 }],
                color:
                    props.value === mark.value
                        ? props.minimumTrackTintColor
                        : props.maximumTrackTintColor,
            },
            getMarkPosition(mark.value),
        ];

        return (
            <Text key={`slider_mark_${key}_${index}`} style={markStyle}>
                {mark.name}
            </Text>
        );
    }

    function renderMarks() {
        if (!props.marks || !props.marks.length) {
            return null;
        }

        const markWidth = sliderWidth / props.marks.length;

        return (
            <View style={styles.marks}>
                {props.marks.map((mark, index) =>
                    renderMark(mark, index, markWidth)
                )}
            </View>
        );
    }

    return (
        <View
            style={[
                props.containerStyle,
                props.marks && props.marks.length ? styles.withMarks : null,
            ]}
            onLayout={onLayout}
        >
            {renderMarks()}
            <SliderImpl
                key={key}
                value={props.value}
                minimumValue={props.minimumValue || 0}
                maximumValue={props.maximumValue || 1}
                minimumTrackTintColor={
                    props.minimumTrackTintColor || theme.colors.grey500
                }
                maximumTrackTintColor={
                    props.maximumTrackTintColor || theme.colors.lightGrey
                }
                thumbTintColor={props.thumbTintColor || theme.colors.primary}
                step={props.step || 1}
                onSlidingComplete={props.onValueChange}
            />
        </View>
    );
}

const styleFunc: StyleFunction = () => ({
    withMarks: {
        marginBottom: 22,
    },
    marks: {
        position: "absolute",
        left: 0,
        top: 22,
        right: 0,
        flexDirection: "row",
    },
    mark: {
        position: "absolute",
        fontSize: 12,
        fontWeight: "bold",
        textAlign: "center",
    },
});
