import { ApolloError } from "@apollo/client";
import {
    Alert,
    Button,
    Loading,
    PrinterWrapper,
    StyleFunction,
    Surface,
    Text,
    useAuth,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQShiftQuery, useShiftQuery } from "graphql-sdk";
import { useTranslation } from "locales";
import React from "react";
import { View } from "react-native";

import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";

type ScreenProps = RootStackScreenProps<"SHIFT">;

export function ShiftScreen({ navigation: { navigate }, route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.shift");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const shiftId = route.params.id;
    const { data, loading, error } = useShiftQuery({
        variables: {
            id: shiftId,
        },
        fetchPolicy: "no-cache",
    });

    return (
        <AdminContainer>
            <View>
                <Button
                    style={styles.backButton}
                    size="small"
                    iconName="chevron-left"
                    onPress={() => navigate("SHIFTS")}
                >
                    {t("common.back", "Back")}
                </Button>
            </View>
            <Shift loading={loading} error={error} data={data} />
        </AdminContainer>
    );
}

function Shift({
    loading,
    error,
    data,
}: {
    loading: boolean;
    error?: ApolloError;
    data?: GQShiftQuery;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    if (loading) {
        return <Loading />;
    }

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error.message,
                    }
                )}
            </Alert>
        );
    }

    if (!data?.shift?.zReport) {
        return (
            <Alert type="info">
                {t(
                    "backoffice.shift.not_closed_yet",
                    "There is no Z report yet, since the shift is not closed."
                )}
            </Alert>
        );
    }

    return (
        <PrinterWrapper>
            <Surface>
                <Text style={styles.receipt}>{data.shift.zReport}</Text>
            </Surface>
        </PrinterWrapper>
    );
}

const styleFunc: StyleFunction = theme => ({
    backButton: {
        width: theme.spacingScale * 10,
        marginBottom: theme.spacingScale,
    },
    receipt: {
        ...theme.fonts.monospace,
        fontSize: 14,
    },
});
