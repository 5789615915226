export function numberFormat(
    n: number,
    noOfDecimals: number = 0,
    locale: string
) {
    const allowedLocales = ["en", "da"];

    if (allowedLocales.includes(locale) === false) {
        locale = "da";
    }

    n = (n * 100) / 100;
    return Intl.NumberFormat(locale, {
        minimumFractionDigits: noOfDecimals,
        maximumFractionDigits: noOfDecimals,
    }).format(n);
}
