import React, { ReactNode } from "react";
import { View, StyleSheet, ViewStyle, StyleProp } from "react-native";
import { StyleFunction, useThemedStyle } from "../../theme";

export function DataGridHeader({
    children,
    style,
}: {
    children: ReactNode;
    style?: StyleProp<ViewStyle>;
}) {
    const styles = useThemedStyle(styleFunc);

    return <View style={[styles.header, style]}>{children}</View>;
}

const styleFunc: StyleFunction = theme => ({
    header: {
        flexDirection: "row",
        height: 48,
        paddingRight: theme.spacingScale / 2,
        paddingLeft: theme.spacingScale * 1.5,
        borderBottomWidth: StyleSheet.hairlineWidth * 2,
        borderBottomColor: theme.colors.grey50,
    },
});
