import {
    Accordion,
    DateTimePickerInput,
    Headline,
    InputControl,
    Loading,
    Picker,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import { View } from "react-native";
import {
    dateToDateTime,
    ReportDefinition,
    ReportRequest,
    ValidationErrors,
} from "lib";
import { Fields } from "./fields";
import { Filters } from "./filters";
import { Grouping } from "./grouping";
import { Ordering } from "./ordering";
import React from "react";
import { reportStyleFunc } from "./styles";
import { useTranslation } from "locales";
import type { defaultReportForm } from "./validation";

const hours24: number[] = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
];

const hours12: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export function AdvancedForm({
    use12hours = false,
    reportDefinition,
    reportQuery,
    formValues,
    formErrors,
    onFieldsChange,
    onFiltersChange,
    onGroupingChange,
    onOrderingChange,
    onDateChange,
    onStartOfDayChange,
}: {
    use12hours?: boolean;
    reportDefinition: ReportDefinition;
    reportQuery: ReportRequest<ReportDefinition>;
    formValues: typeof defaultReportForm | null;
    formErrors: ValidationErrors<typeof defaultReportForm>;
    onFieldsChange: (values: ReportRequest<ReportDefinition>["fields"]) => void;
    onFiltersChange: (
        values: ReportRequest<ReportDefinition>["filters"]
    ) => void;
    onGroupingChange: (
        values: ReportRequest<ReportDefinition>["grouping"]
    ) => void;
    onOrderingChange: (
        values: ReportRequest<ReportDefinition>["ordering"]
    ) => void;
    onDateChange: (date: Date, dateField: string) => void;
    onStartOfDayChange: (value: string) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const sharedStyles = useThemedStyle(reportStyleFunc);

    if (!formValues) {
        return <Loading />;
    }

    return (
        <Accordion
            showContents={false}
            title={t("report.advanced", "Advanced")}
            textStyle={styles.accordionText}
            triggerStyle={styles.advancedSettings}
        >
            <View>
                <Headline size="h5">{t("report.dates", "Dates")}</Headline>
                <View style={sharedStyles.row}>
                    <InputControl
                        style={sharedStyles.inputCell}
                        error={formErrors.from}
                        description={t(
                            "report.from_description",
                            "Report period, start date & time. Time is used to determine start-of-day. E.g: If you set time to 06:00, the report-day is from 06:00 until the next day at 06:00."
                        )}
                    >
                        <DateTimePickerInput
                            label={t("common.from", "From")}
                            dateTimeType="dateTime"
                            value={dateToDateTime(formValues.from)}
                            onChangeDate={d => {
                                onDateChange(d, "from");
                            }}
                        />
                    </InputControl>

                    <InputControl
                        style={sharedStyles.inputCell}
                        error={formErrors.to}
                        description={t(
                            "report.to_description",
                            "Report period, end date & time."
                        )}
                    >
                        <DateTimePickerInput
                            label={t("common.to", "To")}
                            dateTimeType="dateTime"
                            value={dateToDateTime(formValues.to)}
                            onChangeDate={d => {
                                onDateChange(d, "to");
                            }}
                        />
                    </InputControl>
                    <InputControl
                        style={sharedStyles.inputCell}
                        description={t(
                            "report.description.start_of_day",
                            "If shift data always crosses midnight (e.g. the shift always closes at 02:00 pm, then set Start of Day to something fitting."
                        )}
                    >
                        <Picker
                            label={t("report.start_of_day", "Start of day")}
                            selectedValue={formValues.startOfDay}
                            onValueChange={value => {
                                onStartOfDayChange(value);
                            }}
                        >
                            <Picker.Item
                                label={t(
                                    "date.relative.USER_DEFINED",
                                    "User defined"
                                )}
                                value="USER_DEFINED"
                            />
                            {(use12hours ? hours12 : hours24).map(hourValue => (
                                <Picker.Item
                                    label={`${hourValue.toString()}:00`}
                                    value={hourValue.toString()}
                                    key={`daystart_${hourValue.toString()}`}
                                />
                            ))}
                        </Picker>
                    </InputControl>
                </View>
            </View>

            <View>
                <Fields
                    fields={reportDefinition.fields}
                    selectedFields={reportQuery.fields}
                    onFieldsChange={onFieldsChange}
                />
                <Filters
                    filters={reportDefinition.filters}
                    selectedFilters={reportQuery.filters}
                    onFiltersChange={onFiltersChange}
                />
                <Grouping
                    grouping={reportDefinition.grouping}
                    selectedGrouping={reportQuery.grouping}
                    onGroupingChange={onGroupingChange}
                />
                <Ordering
                    ordering={reportDefinition.ordering}
                    selectedOrdering={reportQuery.ordering}
                    onOrderingChange={onOrderingChange}
                />
            </View>
        </Accordion>
    );
}

const styleFunc: StyleFunction = theme => ({
    advancedSettings: {
        alignSelf: "center",
    },
    accordionText: {
        fontWeight: "bold",
        textDecorationLine: "underline",
        textDecorationColor: theme.colors.textDark,
    },
});
