import React from "react";
import { t } from "i18next";
import type { ReportData } from "lib";
import { DataTable } from "../data-table";
import { formatAsNumeric } from "./functions";

export function Headers({ data }: { data: ReportData["headers"] }) {
    return (
        <DataTable.Header>
            {data.map((h, idx) => (
                <DataTable.Title
                    numeric={formatAsNumeric(h.formatAs)}
                    key={`h-${idx}`}
                >
                    {t(`report.columns.${h.name}`, `${h.name}`)}
                </DataTable.Title>
            ))}
        </DataTable.Header>
    );
}
