import React from "react";
import { Image, View } from "react-native";
import {
    Form,
    Button,
    Spacer,
    StyleFunction,
    Surface,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import { PasswordInput } from "./password-input";
import { IUserPassword } from "lib";
import { useTranslation } from "locales";

export function RequiredPasswordChangeForm({
    form,
    onSubmit,
}: {
    form: Form<IUserPassword>;
    onSubmit: () => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const logo = require("../../../assets/img/vp-logo.png").default;
    const [{ values, errors }, { setValue, handleSubmit }] = form;

    return (
        <View>
            <Image source={logo} style={styles.logo} />
            <Spacer space={4} />
            <Surface style={styles.box}>
                <View>
                    <Text>
                        <h1>
                            {t(
                                "backoffice.password_form.change_your_password",
                                "Reset password"
                            )}
                        </h1>
                    </Text>
                </View>
                <PasswordInput
                    errors={errors}
                    setValue={setValue}
                    t={t}
                    values={values}
                />
                <Button onPress={handleSubmit(onSubmit)}>
                    <Text>
                        {t(
                            "backoffice.password_form.submit_button",
                            "Save password"
                        )}
                    </Text>
                </Button>
            </Surface>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    logo: {
        width: 252,
        height: 64,
    },
    box: {
        elevation: 4,
        backgroundColor: theme.colors.white,
        minWidth: 400,
        padding: theme.spacingScale * 4,
    },
});
