import React, { ReactNode } from "react";
import { View, Animated, StyleSheet } from "react-native";
import { useToast } from "./context";
import { ToastItem } from "./item";
export { ProvideToast, useToast } from "./context";
export type { IToastContext } from "./context";

/**
 * app.tsx
 *
 * <ToastProvider>
 * ...
 * </ToastProvider>
 *
 * awesomeScreen.tsx
 *
 * Toast.info("Hejsa");
 * Toast.render(() => <View><Text>Custom Toast</Text></View>)
 */

export interface IToast {
    id: string; // uuid (automatically added)
    title?: string;
    message: string;
    type: "success" | "warning" | "error" | "default" | "custom";
    expires: number; // unixtime in seconds
    animated: Animated.Value;
    iconName: string;
    iconColor?: string;
    render: () => ReactNode;
}

export function ToastContainer() {
    const { stack, onRemove } = useToast();

    return (
        <View
            // container should not generate touch events
            pointerEvents="box-none"
            style={[styles.view]}
        >
            {stack.map(item => (
                <View
                    pointerEvents="auto"
                    key={item.id}
                    style={styles.viewItem}
                >
                    <ToastItem item={item} onRemove={onRemove} />
                </View>
            ))}
        </View>
    );
}

const styles = StyleSheet.create({
    view: {
        position: "absolute",
        left: 40,
        bottom: 30,
        zIndex: 4,
        overflow: "hidden",
    },
    viewItem: {
        marginBottom: 10,
    },
});
