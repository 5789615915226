import React, { useCallback, useState } from "react";
import { SubFormBox, SubFormBoxInactive } from "./sub-form-box";
import {
    assignOrderCondition,
    ConditionAssignment,
    ConditionLogicOperator,
    Conditions,
    OrderConditions,
} from "lib";
import type { GQDiscountFormQuery } from "graphql-sdk";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { produce } from "immer";
import { Loading, RemovableRow } from "@venuepos/react-common";
import { CustomerConditionInput } from "./customer-condition-input";

export function CustomerConditionsForm({
    conditionsLogicOperator,
    onConditionsLogicOperatorChange,
    conditions,
    onConditionsChange,
    formData,
}: {
    conditionsLogicOperator: ConditionLogicOperator;
    onConditionsLogicOperatorChange: (
        logicOperator: ConditionLogicOperator
    ) => void;
    conditions: Conditions;
    onConditionsChange: (conditions: Conditions) => void;
    formData: GQDiscountFormQuery;
}) {
    const [keyPrefix, setKeyPrefix] = useState(uuidv4());
    const [t] = useTranslation();

    const handleConditionChange = useCallback(
        (key: number, condition: ConditionAssignment) => {
            onConditionsChange(
                produce(conditions, draft => {
                    draft[key] = condition;
                    return draft;
                })
            );
        },
        [conditions, onConditionsChange]
    );

    const addCondition = useCallback(() => {
        onConditionsChange(
            produce(conditions, draft => {
                // First try and add default customer group
                const defaultCustomerGroup = formData.customerGroups.data.find(
                    customerGroupItr => {
                        return (
                            conditions.find(
                                itr =>
                                    (
                                        itr.args as OrderConditions["hasCustomerGroup"]
                                    ).customerGroupId === customerGroupItr.id
                            ) === undefined
                        );
                    }
                );

                if (defaultCustomerGroup !== undefined) {
                    draft.push(
                        assignOrderCondition("hasCustomerGroup", {
                            customerGroupId: defaultCustomerGroup.id,
                        })
                    );
                    return;
                }

                // If there is no default customer group then add default customer
                const defaultCustomer = formData.customers.data.find(
                    customerItr => {
                        return (
                            conditions.find(
                                itr =>
                                    (itr.args as OrderConditions["hasCustomer"])
                                        .customerId === customerItr.id
                            ) === undefined
                        );
                    }
                );

                if (defaultCustomer !== undefined) {
                    draft.push(
                        assignOrderCondition("hasCustomer", {
                            customerId: defaultCustomer.id,
                        })
                    );
                    return;
                }
            })
        );
    }, [conditions, formData, onConditionsChange]);

    const removeCondition = useCallback(
        (conditionKey: number) => {
            setKeyPrefix(uuidv4());
            onConditionsChange(
                produce(conditions, draft => {
                    draft.splice(conditionKey, 1);
                })
            );
        },
        [conditions, onConditionsChange]
    );

    if (!formData || !formData) {
        return <Loading />;
    }

    const customerConditions = conditions.filter(
        itr =>
            itr.condition === "hasCustomer" ||
            itr.condition === "hasCustomerGroup"
    );

    if (customerConditions.length === 0) {
        return (
            <SubFormBoxInactive
                text={t(
                    "backoffice.discount_form.add_customer_condition",
                    "Add customer condition"
                )}
                onPress={() => {
                    addCondition();
                }}
            />
        );
    }

    const hasUnusedCustomerConditions = !!formData.customers.data.find(
        customerItr =>
            !customerConditions.find(
                conditionItr =>
                    conditionItr.condition === "hasCustomer" &&
                    (conditionItr.args as OrderConditions["hasCustomer"])
                        .customerId === customerItr.id
            )
    );

    const hasUnusedCustomerGroupConditions =
        !!formData.customerGroups.data.find(
            groupItr =>
                !customerConditions.find(
                    conditionItr =>
                        conditionItr.condition === "hasCustomerGroup" &&
                        (
                            conditionItr.args as OrderConditions["hasCustomerGroup"]
                        ).customerGroupId === groupItr.id
                )
        );

    const hasUnusedConditions =
        hasUnusedCustomerConditions || hasUnusedCustomerGroupConditions;

    return (
        <SubFormBox
            headline={t(
                "backoffice.discount_form.customer_conditions",
                "Customer conditions"
            )}
            onAddCondition={addCondition}
            hasUnusedConditions={hasUnusedConditions}
            conditionsLogicOperator={conditionsLogicOperator}
            onConditionsLogicOperatorChange={onConditionsLogicOperatorChange}
        >
            {customerConditions.map((condition, key) => (
                <RemovableRow
                    key={key + keyPrefix}
                    onRemove={() => {
                        removeCondition(key);
                    }}
                >
                    <CustomerConditionInput
                        condition={condition}
                        conditions={customerConditions}
                        onConditionChange={newCondition =>
                            handleConditionChange(key, newCondition)
                        }
                        formData={formData}
                    />
                </RemovableRow>
            ))}
        </SubFormBox>
    );
}
