import React, { useCallback, useEffect } from "react";
import { RequiredPasswordChangeForm } from "./required-password-change-form";
import { View } from "react-native";
import type { FetchResult } from "@apollo/client";
import {
    StyleFunction,
    useForm,
    useMeLazy,
    useThemedStyle,
    useAuth,
} from "@venuepos/react-common";
import { IUserPassword } from "lib";
import { defaultUserPassword, schemaSaveUserPassword } from "../forms";
import {
    GQUserUpdatePasswordMutation,
    useUserUpdatePasswordMutation,
} from "graphql-sdk";
import { useHandleMutationError } from "../../../hooks";
import { useTranslation } from "locales";

export function ChangePassword() {
    const form = useForm<IUserPassword>(
        schemaSaveUserPassword,
        defaultUserPassword
    );
    const styles = useThemedStyle(styleFunc);
    const [{ values: formValues }, { reset: resetForm }] = form;
    const { handleMutationError } = useHandleMutationError();
    const [passwordUpdate] = useUserUpdatePasswordMutation();
    const [t] = useTranslation();
    const [{ load: loadMe }, me] = useMeLazy();
    const auth = useAuth();

    // Load me on mount
    useEffect(() => {
        loadMe().catch(() => {
            // do nothing
        });
    }, [loadMe]);

    const submitPassword = useCallback(async () => {
        if (!formValues || !me || !me.user?.id) {
            return;
        }
        let passwordUpdateResult: FetchResult<GQUserUpdatePasswordMutation>;
        await handleMutationError(
            async () => {
                passwordUpdateResult = await passwordUpdate({
                    variables: {
                        id: me.user!.id,
                        password: formValues.password,
                    },
                });
            },
            t("common.saved", "Saved"),
            () => {
                resetForm();

                if (!passwordUpdateResult.data) {
                    return;
                }

                auth.signOut();

                setTimeout(() => {
                    window.location.href = "/";
                }, 500);
            }
        );
    }, [
        formValues,
        t,
        resetForm,
        passwordUpdate,
        handleMutationError,
        me,
        auth,
    ]);

    return (
        <View style={styles.container}>
            <RequiredPasswordChangeForm form={form} onSubmit={submitPassword} />
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.colors.grey50,
    },
});
