import {
    LoadingScreen,
    StyleFunction,
    useAuth,
    useForm,
    useThemedStyle,
    useToast,
} from "@venuepos/react-common";
import {
    GQUserInputRole,
    useUserCreateFormQuery,
    useUserCreateMutation,
} from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { RootStackScreenProps } from "../../navigation";
import { defaultCreateUser, schemaCreateUser } from "./forms";
import { useUsernameAvailability } from "./use-username-availability";
import { UserForm } from "./user-form";
import { UserScreen } from "./user-screen";

import type { IUserCreate } from "lib";
type ScreenProps = RootStackScreenProps<"USER_CREATE">;

export function UserCreateScreen({ navigation: { navigate } }: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["merchant.user.write", "admin.user.write"], "OR");

    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const toast = useToast();
    const [userCreate] = useUserCreateMutation();
    const createFromQuery = useUserCreateFormQuery();
    const [checkUsernameAvailability, usernameAvailable] =
        useUsernameAvailability();
    const form = useForm<IUserCreate>(schemaCreateUser, null);
    const [{ values }, { reset, setDefaultValues }] = form;

    useEffect(() => {
        if (createFromQuery.data) {
            setDefaultValues({
                ...defaultCreateUser,
                roles: [
                    {
                        roleId: createFromQuery.data.roles.data[0].id,
                        type: createFromQuery.data.roles.data[0].type,
                        merchantId:
                            createFromQuery.data.roles.data[0].merchant?.id,
                    },
                ],
            });
        }
    }, [createFromQuery.data, setDefaultValues]);

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        // On submit we should check if username is already taken
        if (!(await checkUsernameAvailability(values.username))) {
            return;
        }

        await userCreate({
            variables: {
                user: {
                    username: values.username,
                    password: values.password,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    active: values.active,
                    pin: values.pin ?? "",
                    requireLoginPin: values.requireLoginPin,
                    requireLogoutPin: values.requireLogoutPin,
                    requirePasswordChange: values.requirePasswordChange,
                    roles: values.roles as GQUserInputRole[],
                    doNotSendPassword: values.doNotSendPassword,
                },
            },
        });

        reset();
        toast.success(t("backoffice.user.created", "User created"));
        navigate("USERS");
    }, [
        checkUsernameAvailability,
        navigate,
        reset,
        t,
        toast,
        userCreate,
        values,
    ]);

    if (!createFromQuery.data || !values) {
        return <LoadingScreen style="light" />;
    }

    return (
        <UserScreen>
            <View style={styles.box}>
                <View style={styles.container}>
                    <UserForm
                        form={form}
                        onSubmit={create}
                        submitButton={t("common.create", "Create")}
                        roles={createFromQuery.data.roles.data}
                        showPasswordInput={false}
                        pinSet={false}
                        usernameAvailable={usernameAvailable}
                    />
                </View>
            </View>
        </UserScreen>
    );
}
const styleFunc: StyleFunction = theme => {
    return {
        box: {
            width: "100%",
            backgroundColor: theme.colors.white,
        },
        container: {
            width: "100%",
            maxWidth: theme.dimensions.maxFormWidth,
            alignSelf: "flex-start",
        },
    };
};
