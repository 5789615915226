import {
    Hoverable,
    Icon,
    StyleFunction,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import React from "react";
import { StyleSheet, View } from "react-native";
import { RectButton } from "react-native-gesture-handler";

export function GridEditorDroppable({
    onPress,
    testID,
}: {
    onPress?: () => void;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();

    function handlePress() {
        onPress && onPress();
    }

    return (
        <Hoverable>
            {isHovered => (
                <View style={styles.cellContent}>
                    <RectButton
                        style={[
                            styles.button,
                            isHovered ? styles.hoveredButton : null,
                        ]}
                        onPress={handlePress}
                        testID={testID}
                    >
                        <Icon
                            style={[
                                styles.droppable,
                                isHovered ? styles.hoveredCell : null,
                            ]}
                            size="large"
                            name="plus"
                            color={theme.colors.grey50}
                        />
                    </RectButton>
                </View>
            )}
        </Hoverable>
    );
}

const styleFunc: StyleFunction = theme => ({
    cellContent: {
        flex: 1,
        backgroundColor: theme.colors.grey50,
        borderWidth: StyleSheet.hairlineWidth,
        borderStyle: "solid",
        borderColor: theme.colors.grey500,
        borderRadius: theme.borderRadiusTiny,
    },
    hoveredButton: {
        backgroundColor: theme.colors.grey100,
        borderRadius: theme.borderRadiusTiny,
    },
    hoveredCell: {
        color: theme.colors.grey800,
    },
    droppable: {
        color: theme.colors.grey500,
    },
    button: {
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
});
