import type { ReportHeader } from "lib";
import type { AvailableLocale } from "locales";
import React from "react";
import type { ViewStyle } from "react-native";
import { StyleFunction, useThemedStyle } from "../../..";
import { DataTable } from "../data-table";
import { renderValue } from "./functions";

export function Sums({
    data,
    formatAs,
    locale,
    style,
}: {
    data: (number | undefined)[];
    formatAs: ReportHeader["formatAs"][];
    locale: AvailableLocale;
    style?: ViewStyle;
}) {
    const styles = useThemedStyle(styleFunc);

    if (!data.find(s => typeof s !== "undefined")) {
        return null;
    }

    return (
        <DataTable.Row style={style}>
            {data.map((s, cidx) => (
                <DataTable.Cell
                    key={`sumrow-${cidx}`}
                    textStyle={styles.boldCell}
                    numeric={s !== undefined}
                >
                    {renderValue(s, formatAs[cidx] as string, locale)}
                </DataTable.Cell>
            ))}
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = () => ({
    boldCell: {
        fontWeight: "bold",
    },
});
