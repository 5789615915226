import React, { useCallback, useState } from "react";
import { Image, View } from "react-native";
import {
    useForm,
    useAuth,
    useAuthSession,
    Spacer,
    Surface,
    StyleFunction,
    useThemedStyle,
    ValidationSchema,
} from "@venuepos/react-common";
import { LoginForm } from "./login-form";
import { SelectUserRole } from "./select-user-role";

const logo = require("@venuepos/react-common/assets/img/vp-logo.png");

export type LoginFormData = {
    username: string;
    password: string;
};

const loginSchema: ValidationSchema<LoginFormData> = {
    username: { required: true },
    password: { required: true },
};

const defaultLoginData = {
    username: "",
    password: "",
};

export function LoginScreen() {
    const [{ authToken }] = useAuthSession(["authToken"]);
    const styles = useThemedStyle(styleFunc);
    const auth = useAuth();
    const form = useForm<LoginFormData>(loginSchema, defaultLoginData);
    const [authenticated, setAuthenticated] = useState<boolean | undefined>();
    const [{ values }, { reset, handleSubmit }] = form;

    const handleSignIn = useCallback(async () => {
        const success = await auth.signIn(values!.username, values!.password);
        reset();
        setAuthenticated(success);
    }, [auth, values, reset]);

    return (
        <View style={styles.container}>
            <Image source={logo} style={styles.logo} />
            <Spacer space={4} />
            <Surface style={styles.box}>
                {!authToken ? (
                    <LoginForm
                        form={form}
                        authenticated={authenticated}
                        handleSubmit={handleSubmit(handleSignIn)}
                    />
                ) : (
                    <SelectUserRole
                        signOut={auth.signOut}
                        selectUserRole={auth.selectUserRole}
                    />
                )}
            </Surface>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    logo: {
        width: 252,
        height: 64,
    },
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.colors.grey50,
    },
    box: {
        elevation: 4,
        backgroundColor: theme.colors.white,
        minWidth: "25%",
        padding: theme.spacingScale * 4,
    },
});
