import { ButtonDimensions, ButtonPosition, GridDimensions } from "lib";
import { setButtonPosition } from ".";

export const removeButtonPosition = (
    buttonPositions: string[],
    gridDimensions: GridDimensions,
    position: ButtonPosition,
    dimensions: ButtonDimensions
): string[] =>
    setButtonPosition(
        buttonPositions,
        gridDimensions,
        position,
        dimensions,
        ""
    );
