import {
    assignOrderCondition,
    ConditionLogicOperator,
    Conditions,
    DiscountType,
    ItemFilterQuery,
    OrderConditions,
} from "lib";
import type { DateTimeConstraint } from "./date-time-constraint-form";
import type { DiscountFormConditionInputs, DiscountFormInputs } from "./forms";

function convertItemQueryToConstraints(
    itemQuery: ItemFilterQuery
): DateTimeConstraint[] {
    const constraints: DateTimeConstraint[] = [];

    if (itemQuery.length === 0) {
        return constraints;
    }

    for (let i = 0; i < itemQuery[0].length; i++) {
        const filter = itemQuery[0][i];
        if (
            filter.type !== "dateTime" &&
            filter.type !== "date" &&
            filter.type !== "time" &&
            filter.type !== "week" &&
            filter.type !== "weekDay"
        ) {
            continue;
        }

        constraints.push({
            type: filter.type,
            from: (filter[filter.type] as any).from,
            to: (filter[filter.type] as any).to,
        });
    }

    return constraints;
}

function convertConstraintsToItemQuery(
    constraints: DateTimeConstraint[]
): ItemFilterQuery {
    const filterQuery: ItemFilterQuery = [[]];
    const filters = filterQuery[0];
    for (let i = 0; i < constraints.length; i++) {
        const constraint = constraints[i];

        const filter: ItemFilterQuery[0][0] = {
            type: constraint.type,
        };
        filter[constraint.type] = {
            from: constraint.from as any,
            to: constraint.to as any,
        };
        filters.push(filter);
    }
    return filterQuery;
}

function orderConditionsToConstraints(
    conditions: DiscountType["conditions"]
): DiscountFormInputs["dateTimeConstraints"] {
    const constraints: DateTimeConstraint[] = [];

    if (conditions.length === 0) {
        return constraints;
    }

    for (let i = 0; i < conditions.length; i++) {
        const condition = conditions[i];
        if (
            condition.type !== "order" ||
            (condition.condition !== "dateTime" &&
                condition.condition !== "date" &&
                condition.condition !== "time" &&
                condition.condition !== "week" &&
                condition.condition !== "weekDay")
        ) {
            continue;
        }

        constraints.push({
            type: condition.condition,
            from: (condition.args as any).from,
            to: (condition.args as any).to,
        });
    }

    return constraints;
}

function constraintsToOrderConditions(
    constraints: DateTimeConstraint[]
): DiscountType["conditions"] {
    const conditions: DiscountType["conditions"] = [];
    for (let i = 0; i < constraints.length; i++) {
        const constraint = constraints[i];

        conditions.push(
            assignOrderCondition(constraint.type, {
                from: constraint.from as any,
                to: constraint.to as any,
            })
        );
    }
    return conditions;
}

// We filter for product conditions only with required quantity greater than zero.
// Also we're making sure that only one condition is using a product. If for some reason
// the UI did fail and quantity is set to zero or lower, or two or more conditions uses
// the same product, the whole POS would potential dies. So just for good measure, we
// make sure to filter them out.
function productConditionsToDiscountConditions(
    conditions: DiscountFormInputs["productConditions"]
): DiscountType["conditions"] {
    const selectedProducts: string[] = [];
    return conditions.filter(itr => {
        const args = itr.args as OrderConditions["hasProductQuantity"];
        if (selectedProducts.includes(args.productId)) {
            return false;
        } else {
            selectedProducts.push(args.productId);
        }
        return args.requiredQuantity > 0;
    });
}

// We filter product conditions on groups.
// A product id must only be used once in all conditions on a group.
function groupConditionsToDiscountConditions(
    conditions: DiscountFormInputs["groupConditions"]
): DiscountType["conditions"] {
    const selectedProducts: string[] = [];
    return conditions.filter(itr => {
        const args = itr.args as OrderConditions["hasGroupQuantity"];
        for (let idx in args.productIds) {
            if (selectedProducts.includes(args.productIds[idx])) {
                return false;
            } else {
                selectedProducts.push(args.productIds[idx]);
            }
        }
        return args.requiredQuantity > 0;
    });
}

// We filter tag conditions to make sure they have either a tagId or tagGroupId
// and also making sure that only one condition is using that id
function tagConditionsToDiscountConditions(
    conditions: DiscountFormInputs["tagConditions"]
): DiscountType["conditions"] {
    const selectedTagsOrGroups: string[] = [];
    return conditions.filter(itr => {
        let id: string | undefined;
        if (itr.condition === "hasTag") {
            id = (itr.args as OrderConditions["hasTag"]).tagId;
        } else if (itr.condition === "hasTagGroup") {
            id = (itr.args as OrderConditions["hasTagGroup"]).tagGroupId;
        } else {
            return false;
        }

        if (selectedTagsOrGroups.includes(id)) {
            return false;
        } else {
            selectedTagsOrGroups.push(id);
            return true;
        }
    });
}

// We filter customer conditions to make sure they have either a customerId or customerGroupId
// and also making sure that only one condition is using that id
function customerConditionsToDiscountConditions(
    conditions: DiscountFormInputs["customerConditions"]
): DiscountType["conditions"] {
    const selectedCustomersOrGroups: string[] = [];
    return conditions.filter(itr => {
        let id: string | undefined;
        if (itr.condition === "hasCustomer") {
            id = (itr.args as OrderConditions["hasCustomer"]).customerId;
        } else if (itr.condition === "hasCustomerGroup") {
            id = (itr.args as OrderConditions["hasCustomerGroup"])
                .customerGroupId;
        } else {
            return false;
        }

        if (selectedCustomersOrGroups.includes(id)) {
            return false;
        } else {
            selectedCustomersOrGroups.push(id);
            return true;
        }
    });
}

function departmentConditionsToDiscountConditions(
    conditions: DiscountFormInputs["departmentConditions"]
): DiscountType["conditions"] {
    const selectedDepartments: string[] = [];
    return conditions.filter(itr => {
        const args = itr.args as OrderConditions["hasDepartment"];
        if (selectedDepartments.includes(args.departmentId)) {
            return false;
        } else {
            selectedDepartments.push(args.departmentId);
        }
        return true;
    });
}

export function convertToValues(
    type: DiscountFormInputs["type"],
    wrappedConditions: DiscountType["conditions"],
    itemQuery: DiscountType["itemQuery"]
): [DiscountFormConditionInputs, DiscountFormInputs["dateTimeConstraints"]] {
    const {
        conditions,
        customerConditionsLogicOperator,
        tagConditionsLogicOperator,
        productConditionsLogicOperator,
        departmentConditionsLogicOperator,
    } = unwrapLogicOperator(wrappedConditions);

    const conditionInputs: DiscountFormConditionInputs = {
        productConditions: conditions.filter(
            itr => itr.condition === "hasProductQuantity"
        ),
        groupConditions: conditions.filter(
            itr => itr.condition === "hasGroupQuantity"
        ),
        tagConditions: conditions.filter(
            itr => itr.condition === "hasTag" || itr.condition === "hasTagGroup"
        ),
        customerConditions: conditions.filter(
            itr =>
                itr.condition === "hasCustomer" ||
                itr.condition === "hasCustomerGroup"
        ),
        departmentConditions: conditions.filter(
            itr => itr.condition === "hasDepartment"
        ),
        productConditionsLogicOperator,
        tagConditionsLogicOperator,
        customerConditionsLogicOperator,
        departmentConditionsLogicOperator,
    };

    switch (type) {
        case "ORDER":
            return [conditionInputs, orderConditionsToConstraints(conditions)];

        case "PRODUCT":
            return [conditionInputs, convertItemQueryToConstraints(itemQuery)];

        case "GROUP":
            return [conditionInputs, convertItemQueryToConstraints(itemQuery)];
    }

    throw new Error("Invalid discount type");
}

export function convertFromValues(
    values: DiscountFormInputs
): [
    DiscountType["conditions"],
    DiscountType["itemQuery"],
    DiscountType["maxPerOrder"]
] {
    const tagConditions = tagConditionsToDiscountConditions(
        values.tagConditions
    );

    const customerConditions = customerConditionsToDiscountConditions(
        values.customerConditions
    );

    switch (values.type) {
        case "ORDER":
            return [
                [
                    ...constraintsToOrderConditions(values.dateTimeConstraints),
                    ...wrapLogicOperator(
                        values.tagConditionsLogicOperator,
                        tagConditions
                    ),
                    ...wrapLogicOperator(
                        values.customerConditionsLogicOperator,
                        customerConditions
                    ),
                    ...wrapLogicOperator(
                        values.departmentConditionsLogicOperator,
                        departmentConditionsToDiscountConditions(
                            values.departmentConditions
                        )
                    ),
                ],
                [],
                undefined, // Max per order doesn't work, or even make sense, on order discounts, that's why it's set to undefined
            ];

        case "PRODUCT":
            return [
                [
                    ...wrapLogicOperator(
                        values.productConditionsLogicOperator,
                        productConditionsToDiscountConditions(
                            values.productConditions
                        )
                    ),
                    ...wrapLogicOperator(
                        values.tagConditionsLogicOperator,
                        tagConditions
                    ),
                    ...wrapLogicOperator(
                        values.customerConditionsLogicOperator,
                        customerConditions
                    ),
                    ...wrapLogicOperator(
                        values.departmentConditionsLogicOperator,
                        departmentConditionsToDiscountConditions(
                            values.departmentConditions
                        )
                    ),
                ],
                convertConstraintsToItemQuery(values.dateTimeConstraints),
                values.maxPerOrder === null ? undefined : values.maxPerOrder,
            ];

        case "GROUP":
            return [
                [
                    ...groupConditionsToDiscountConditions(
                        values.groupConditions
                    ),
                    ...wrapLogicOperator(
                        values.tagConditionsLogicOperator,
                        tagConditions
                    ),
                    ...wrapLogicOperator(
                        values.customerConditionsLogicOperator,
                        customerConditions
                    ),
                    ...wrapLogicOperator(
                        values.departmentConditionsLogicOperator,
                        departmentConditionsToDiscountConditions(
                            values.departmentConditions
                        )
                    ),
                ],
                convertConstraintsToItemQuery(values.dateTimeConstraints),
                undefined,
            ];
    }

    return [[], [], undefined];
}

function unwrapLogicOperator(conditions: DiscountType["conditions"]) {
    let result: { conditions: DiscountType["conditions"] } & Pick<
        DiscountFormInputs,
        | "productConditionsLogicOperator"
        | "tagConditionsLogicOperator"
        | "customerConditionsLogicOperator"
        | "departmentConditionsLogicOperator"
    > = {
        conditions: [],
        productConditionsLogicOperator: "AND",
        tagConditionsLogicOperator: "AND",
        customerConditionsLogicOperator: "AND",
        departmentConditionsLogicOperator: "OR",
    };

    conditions.forEach(condition => {
        if (condition.type !== "logic" || !Array.isArray(condition.args)) {
            result.conditions.push(condition);
            return;
        }

        if (condition.args.length === 0) {
            return;
        }

        const operator = condition.condition as ConditionLogicOperator;
        const children = condition.args as Conditions;

        // Look inside logic args to determine which type of condition this logic operator belongs to
        if (children[0].type === "order") {
            switch (children[0].condition as keyof OrderConditions) {
                case "hasProductQuantity":
                    result.productConditionsLogicOperator = operator;
                    break;

                case "hasTag":
                case "hasTagGroup":
                    result.tagConditionsLogicOperator = operator;
                    break;

                case "hasCustomer":
                case "hasCustomerGroup":
                    result.customerConditionsLogicOperator = operator;
                    break;

                case "hasDepartment":
                    result.departmentConditionsLogicOperator = operator;
                    break;

                default:
                // do nothing
            }
        }

        result.conditions = [
            ...result.conditions,
            ...(condition.args as Conditions),
        ];
    });

    return result;
}

function wrapLogicOperator(
    logicOperator: ConditionLogicOperator,
    conditions: DiscountType["conditions"]
): DiscountType["conditions"] {
    if (conditions.length === 0 || logicOperator === "AND") {
        return conditions;
    } else {
        return [
            {
                condition: "OR",
                type: "logic",
                args: conditions,
            },
        ];
    }
}
