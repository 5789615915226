import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";
import { theme } from "@venuepos/react-common/src/theme/themes/venue-default";
import { StyleProp, View, ViewStyle } from "react-native";
import { useThemedStyle, StyleFunction } from "../..";

export function PrinterWrapper({
    containerStyle,
    printContainerStyle,
    children,
}: {
    containerStyle?: StyleProp<ViewStyle>;
    printContainerStyle?: StyleProp<ViewStyle>;
    children: React.ReactNode;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const linkToPrint = () => {
        return (
            <button style={cssStyles.button}>
                {t("common.print", "Print")}
            </button>
        );
    };
    const componentRef = useRef<HTMLDivElement>();
    return (
        <View style={styles.container}>
            <View style={[styles.visual, containerStyle]}>
                <div
                    ref={el => {
                        if (el) {
                            componentRef.current = el;
                        }
                    }}
                >
                    {children}
                </div>
            </View>
            <View style={[styles.printButtonContainer, printContainerStyle]}>
                <ReactToPrint
                    trigger={linkToPrint}
                    content={() => {
                        if (!componentRef.current) {
                            return null;
                        }

                        return componentRef.current;
                    }}
                />
            </View>
        </View>
    );
}

// Styles used with the React / HTML elements above
const cssStyles: { [key: string]: React.CSSProperties } = {
    // This (matches / should match) the default look of a button in the Venue theme.
    button: {
        height: 48,
        borderRadius: 24,
        color: theme.colors.textLight,
        backgroundColor: theme.colors.secondary,
        paddingLeft: theme.spacingScale * 3,
        paddingRight: theme.spacingScale * 3,
        fontFamily: "Montserrat-Bold",
        fontSize: 16,
        cursor: "pointer",
    },
};

const styleFunc: StyleFunction = globalTheme => ({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    visual: {
        marginRight: globalTheme.spacingScale,
        maxWidth: globalTheme.dimensions.maxFormWidth,
    },
    printButtonContainer: {
        padding: globalTheme.spacingScale,
        paddingTop: 0,
        alignItems: "center",
        borderRadius: globalTheme.borderRadius,
    },
});
