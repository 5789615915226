import type { ValidationSchema } from "../validation";
import type { GQTagGroupInput, GQTagInput } from "graphql-sdk";
import type { ITagGroupInput } from "./itag-group";
import type { ITagInput } from "./itag";

export const schemaTag: ValidationSchema<GQTagInput> = {
    identifier: {
        required: true,
        // Disallows whitespace in string, only "-" and actual letters
        pattern: {
            pattern: /^[-\w]+$/,
            message: ["validation.whitespace_not_allowed"],
        },
    },
    tagType: { required: true },
};

export const defaultTag: ITagInput = {
    identifier: "",
    name: "",
    tagType: "",
    groupId: "",
    accountId: "",
    customerId: "",
};

export const schemaTagGroup: ValidationSchema<GQTagGroupInput> = {
    name: { required: true },
};

export const defaultTagGroup: ITagGroupInput = {
    name: "",
};
