import { useThemedStyle } from "@venuepos/react-common";
import React from "react";
import { View } from "react-native";
import { dashboardStyleFunc } from "../styles";
import { CashRegisters as CashRegisterList } from "./cash-register-list";
import { CashRegisterStats } from "./cash-register-stats";

export function CashRegisterDashboard() {
    const sharedStyles = useThemedStyle(dashboardStyleFunc);

    return (
        <View style={sharedStyles.row}>
            <CashRegisterStats />
            <CashRegisterList />
        </View>
    );
}
