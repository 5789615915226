// Purpose: Make defaults and configuration parameters available to the application (web, ios, android)
// SECURITY NOTICE: Anything we put into our JS code can be read by the client. That includes configuration variables.

import { Platform } from "react-native";

interface IAppConfig {
    environment: string; // This is for convenience, so we can tell what environment we're using.
    backendBaseURL: string;
    baxiHostIP: string;
    baxiHostPort: number;
}

export function getAppConfig(): IAppConfig {
    const envName: string = process.env.VP_ENV || "development";

    let env: IAppConfig = {
        environment: envName,
        backendBaseURL: "",
        baxiHostIP: "91.102.24.111",
        baxiHostPort: 9670,
    };

    if (Platform.OS === "web") {
        if (window.location) {
            env.backendBaseURL = window.location.origin;
        }

        return env;
    }

    if (envName === "cloud.venuepos.net") {
        env.baxiHostIP = "91.102.24.142";
    }

    // This handles on Device uses for now
    switch (envName) {
        case "development":
            env.backendBaseURL = "http://localhost:4000";
            break;
        default:
            env.backendBaseURL = `https://${envName}`;
    }

    return env;
}
