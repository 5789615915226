import { AvailableLocale, isLocale, DEFAULT_LOCALE } from "locales";

// Currency is required to follow the ISO 4217 standard
export type Currency = SupportedCurrenciesType;

// Supported ISO 4217 currencies
export type SupportedCurrenciesType = "DKK" | "NOK" | "SEK" | "EUR" | "USD";
export const supportedCurrencies: Currency[] = [
    "DKK",
    "NOK",
    "SEK",
    "EUR",
    "USD",
];

export type CurrencySchemes = {
    [key in SupportedCurrenciesType]: {
        print: (formattedAmount: string) => string;
        round: (amount: number) => number;
    };
};

export const currencySchemes: CurrencySchemes = {
    DKK: {
        print: (formattedAmount: string) => `${formattedAmount} DKK`,
        // The smallest coin in DKK is 50 ører, so round to closest 50 ører
        round: (amount: number) =>
            (Math.round(Math.abs(amount) * 0.02) / 0.02) * Math.sign(amount),
    },
    NOK: {
        print: (formattedAmount: string) => `${formattedAmount} NOK`,
        // The smallest coin in NOK is 100 cents, so we need to round of any cents
        round: (amount: number) =>
            (Math.round(Math.abs(amount) * 0.01) / 0.01) * Math.sign(amount),
    },
    SEK: {
        print: (formattedAmount: string) => `${formattedAmount} SEK`,
        // The smallest coin in SEK is 100 cents, so we need to round of any cents
        round: (amount: number) =>
            (Math.round(Math.abs(amount) * 0.01) / 0.01) * Math.sign(amount),
    },
    EUR: {
        print: (formattedAmount: string) => `${formattedAmount} EUR`,
        // The smallest coin in EUR is 1 cent, so therefore we don't need to do any rounding
        round: (amount: number) => amount,
    },
    USD: {
        print: (formattedAmount: string) => `${formattedAmount} USD`,
        // The smallest coin in USD is 1 cent, so therefore we don't need to do any rounding
        round: (amount: number) => amount,
    },
};

/**
 * Formats an internal amount to human readable amount with 2 decimals
 */
export function formatAmount(
    amount: number,
    currency?: Currency,
    options?: {
        locale?: AvailableLocale;
        roundToClosest?: boolean;
        printCurrency?: boolean;
    }
) {
    let amountToFormat = Number(amount);
    let currencySchema;

    if (currency) {
        currencySchema = currencySchemes[currency];
        if (!currencySchema) {
            throw new Error(
                `Trying to use an unsupported currency: ${currency}`
            );
        }

        if (options?.roundToClosest) {
            amountToFormat = currencySchema.round(amountToFormat);
        }
    } else {
        if (options?.printCurrency) {
            console.warn(
                "Trying to print the currency without a currency present."
            );
        }

        if (options?.roundToClosest) {
            console.warn(
                "Trying to round to closest without a currency present."
            );
        }
    }

    let locale = DEFAULT_LOCALE;

    if (options?.locale && isLocale(options.locale)) {
        locale = options.locale;
    }

    // Note that we do not use the style: "currency" as it will always add a currency test or symbol i.e "DKK 1.00"
    const numberFormatter = new Intl.NumberFormat(locale, {
        style: "decimal",
        minimumFractionDigits: 2,
    });

    let formatted = replaceDashWithHyphen(
        numberFormatter.format(amountToFormat / UNIT_MULTIPLIER)
    );

    if (currencySchema && options?.printCurrency) {
        formatted = currencySchema.print(formatted);
    }

    return formatted;
}

export function replaceDashWithHyphen(str: string): string {
    // Replace hyphen "-" with dash "-"
    return str.replace(/−/g, "-");
}

// Constant for formatting the internal amounts to human readable format
const UNIT_MULTIPLIER = 100;

/**
 * Converts a human readable amount to the internal format.
 * TODO: perhaps extend to handle separators and formatting
 * @param amount
 */
export function parseAmount(amount: number) {
    return Math.round(amount * UNIT_MULTIPLIER);
}

/**
 * Returns the converted amount with the given rate.
 * Will always round, as we want the given amount to
 * always be covered by the converted amount.
 * This make sure that a rounding wont cause an order
 * not being completed, when the full converted amount
 * is paid.
 */
export function convertAmountToCurrencyRate(amount: number, rate: number) {
    if (rate <= 0) {
        throw Error(
            "Trying to convert amount to a negative or zero currency rate"
        );
    }

    const converted = Math.abs(
        convertAmountToCurrencyRateNoRounding(amount, rate)
    );
    if (Math.floor(converted) !== converted) {
        return Math.sign(amount) * Math.round(converted + 0.5);
    }
    return Math.sign(amount) * converted;
}

/**
 * Returns the reverted conversion of the given
 * converted amount for the given rate. Rounds to
 * the closest integer.
 */
export function convertAmountFromCurrencyRate(converted: number, rate: number) {
    if (rate <= 0) {
        throw Error(
            "Trying to convert amount from a negative or zero currency rate"
        );
    }

    const amount = Math.abs(
        convertAmountFromCurrencyRateNoRounding(converted, rate)
    );
    if (Math.floor(amount) !== amount) {
        return Math.sign(converted) * Math.round(amount);
    } else {
        return Math.sign(converted) * amount;
    }
}

export function convertAmountToCurrencyRateNoRounding(
    amount: number,
    rate: number
) {
    return amount / rate;
}

export function convertAmountFromCurrencyRateNoRounding(
    converted: number,
    rate: number
) {
    return converted * rate;
}

export type MoneyType = {
    label: string;
    value: number;
    count: number;
};

export const moneyTypes: Record<
    SupportedCurrenciesType | "default",
    Array<MoneyType>
> = {
    default: [{ label: "1 coin", value: 1, count: 0 }],
    DKK: [
        { label: "50 øre", value: 0.5, count: 0 },
        { label: "1 kr.", value: 1, count: 0 },
        { label: "2 kr.", value: 2, count: 0 },
        { label: "5 kr.", value: 5, count: 0 },
        { label: "10 kr.", value: 10, count: 0 },
        { label: "20 kr.", value: 20, count: 0 },
        { label: "50 kr.", value: 50, count: 0 },
        { label: "100 kr.", value: 100, count: 0 },
        { label: "200 kr.", value: 200, count: 0 },
        { label: "500 kr.", value: 500, count: 0 },
        { label: "1000 kr.", value: 1000, count: 0 },
    ],
    EUR: [
        { label: "1 €", value: 1, count: 0 },
        { label: "2 €", value: 2, count: 0 },
        { label: "5 €", value: 5, count: 0 },
        { label: "10 €", value: 10, count: 0 },
        { label: "20 €", value: 20, count: 0 },
        { label: "50 €", value: 50, count: 0 },
        { label: "100 €", value: 100, count: 0 },
        { label: "200 €", value: 200, count: 0 },
        { label: "500 €", value: 500, count: 0 },
    ],
    NOK: [
        { label: "1 kr.", value: 1, count: 0 },
        { label: "5 kr.", value: 5, count: 0 },
        { label: "10 kr.", value: 10, count: 0 },
        { label: "20 kr.", value: 20, count: 0 },
        { label: "50 kr.", value: 50, count: 0 },
        { label: "100 kr.", value: 100, count: 0 },
        { label: "200 kr.", value: 200, count: 0 },
        { label: "500 kr.", value: 500, count: 0 },
        { label: "1000 kr.", value: 1000, count: 0 },
    ],
    SEK: [
        { label: "1 kr.", value: 1, count: 0 },
        { label: "5 kr.", value: 5, count: 0 },
        { label: "10 kr.", value: 10, count: 0 },
        { label: "20 kr.", value: 20, count: 0 },
        { label: "50 kr.", value: 50, count: 0 },
        { label: "100 kr.", value: 100, count: 0 },
        { label: "200 kr.", value: 200, count: 0 },
        { label: "500 kr.", value: 500, count: 0 },
        { label: "1000 kr.", value: 1000, count: 0 },
    ],
    USD: [
        { label: "1 dollar", value: 1, count: 0 },
        { label: "5 dollar", value: 5, count: 0 },
        { label: "10 dollar", value: 10, count: 0 },
        { label: "20 dollar", value: 20, count: 0 },
        { label: "50 dollar", value: 50, count: 0 },
        { label: "100 dollar", value: 100, count: 0 },
    ],
};
