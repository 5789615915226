import { StackScreenProps } from "@react-navigation/stack";
import {
    Alert,
    Button,
    DataTable,
    Headline,
    Loading,
    LoadingScreen,
    SearchInput,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useMerchantConfig,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import { useBookkeepingAccountQuery } from "graphql-sdk";
import type { AvailableLocale } from "locales";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { RootStackParamList } from "../../../../navigation";
import { useAdminSession } from "../../../../session";
import { AdminContainer } from "../../../index";
import { TransactionTableHeader } from "./transaction-table-header";
import { TransactionTableRow } from "./transaction-table-row";

type ScreenProps = StackScreenProps<
    RootStackParamList,
    "BOOKKEEPING_ACCOUNT_VIEW"
>;

export function BookkeepingAccountView({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.accounts");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "transactionAt",
        initialSortDirection: "DESC",
    });
    const [search, setSearch] = useState<string>("");
    const accountId = route.params.id;

    const { data, error, loading } = useBookkeepingAccountQuery({
        variables: {
            id: accountId,
            transactionPagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            transactionSearch: {
                query: search,
            },
        },
        fetchPolicy: "no-cache",
    });
    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list
            onPageChange(0);
        },
        [onPageChange]
    );

    const handleShowInvoice = useCallback(
        (invoiceId: string) => {
            navigate("INVOICE", { id: invoiceId });
        },
        [navigate]
    );

    if (!data) {
        return <LoadingScreen style="light" />;
    }

    const transactions = data.bookkeepingAccount.bookkeepingTransactions;

    return (
        <AdminContainer>
            <Surface>
                <View style={styles.row}>
                    <Headline>{data.bookkeepingAccount.name}</Headline>
                    <View style={styles.buttonContainer}>
                        <Button
                            type="secondary"
                            size="small"
                            onPress={() => {
                                navigate("BOOKKEEPING_ACCOUNT_EDIT", {
                                    id: accountId,
                                });
                            }}
                            iconName="edit"
                        >
                            {t(
                                "backoffice.account.edit_account",
                                "Edit account"
                            )}
                        </Button>
                    </View>
                </View>
                <Headline size="h5">
                    {t(
                        "backoffice.account.transactions.headline",
                        "Transactions"
                    )}
                </Headline>
                <SearchInput onChange={handleSearchTextChange} />
                <DataTable>
                    <TransactionTableHeader
                        onSortChange={onSortChange}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                    />
                    {error ? (
                        <Alert type="error">
                            {t(
                                "backoffice.error.from_server",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : loading ? (
                        <>
                            <Spacer />
                            <Loading />
                            <Spacer />
                        </>
                    ) : (
                        transactions?.data.map(item => (
                            <TransactionTableRow
                                item={item}
                                locale={locale as AvailableLocale}
                                currency={currency}
                                onShowInvoice={handleShowInvoice}
                            />
                        ))
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={transactions?.pagination.pages}
                        itemCount={transactions?.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    row: {
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },

    buttonContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        marginBottom: theme.spacingScale * 2,
    },
});
