import { numberFormat, formatAmount, parseAmount } from "lib";
import type { AvailableLocale } from "locales";

export function renderValue(
    value: any,
    type: string | undefined,
    locale: AvailableLocale
): string {
    if (value === undefined) {
        return "";
    }

    let returnValue: string = "";

    switch (type) {
        case "QUANTITY":
            if (value % 1 === 0) {
                returnValue = numberFormat(value, 0, locale);
            } else {
                returnValue = numberFormat(value, 2, locale);
            }
            break;
        case "AMOUNT":
            returnValue = formatAmount(parseAmount(value), "DKK", {
                printCurrency: false,
                locale: locale,
            });
            break;
        case "DATE":
        case "DATETIME":
        case "SIMPLE":
        default:
            returnValue = value;
    }

    return returnValue;
}

export function formatAsNumeric(formatAs?: string): boolean {
    if (!formatAs) {
        return false;
    }

    return ["AMOUNT", "QUANTITY"].includes(formatAs);
}
