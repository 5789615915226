import {
    Headline,
    Icon,
    InputControl,
    Loading,
    Picker,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQReportParameters, useReportFormQuery } from "graphql-sdk";
import { produce } from "immer";
import type {
    FilterTypeDefinitions,
    ReportDefinition,
    ReportRequest,
} from "lib";
import { useTranslation } from "react-i18next";
import React, { StyleSheet, View } from "react-native";
import Select, {
    DropdownIndicatorProps,
    IndicatorSeparatorProps,
} from "react-select";
import { reportStyleFunc } from "./styles";

// This method returns values from the GQL response, which knows all about the available departments, users, and cash registers
function resolveFilterValues(
    filterName: string,
    filterValues: Pick<
        GQReportParameters,
        "users" | "departments" | "cashRegisters" | "productGroups" | "products"
    >
) {
    const availableFilterTypes = Object.keys(filterValues);

    switch (filterName) {
        case "department": {
            if (!availableFilterTypes.includes("departments")) {
                return [];
            }
            return filterValues.departments.map(dept => ({
                label: dept.name,
                value: dept.id,
            }));
        }
        case "user": {
            if (!availableFilterTypes.includes("users")) {
                return [];
            }
            return filterValues.users.map(user => ({
                label: `${user.firstName} ${user.lastName}`,
                value: user.id,
            }));
        }

        case "cash_register": {
            if (!availableFilterTypes.includes("cashRegisters")) {
                return [];
            }
            return filterValues.cashRegisters.map(reg => ({
                label: reg.name,
                value: reg.id,
            }));
        }

        case "product_group": {
            if (!availableFilterTypes.includes("productGroups")) {
                return [];
            }
            return filterValues.productGroups.map(reg => ({
                label: reg.name,
                value: reg.id,
            }));
        }

        case "product": {
            if (!availableFilterTypes.includes("products")) {
                return [];
            }
            return filterValues.products.map(reg => ({
                label: reg.name,
                value: reg.id,
            }));
        }

        default: {
            console.error(
                "We know nothing of this",
                filterName,
                "of which you speak so highly... Begone!"
            );
            return [];
        }
    }
}

// Component for removing the | just left of the dropdown indicator
const IndicatorSeparator = ({}: IndicatorSeparatorProps<any, true>) => {
    return <></>;
};

// Make the dropdown indicator look like the existing ones
const CustomDropdownIndicator = ({}: DropdownIndicatorProps<any, true>) => {
    const theme = useTheme();
    return (
        <Icon name={"dropdown"} size={"normal"} color={theme.colors.grey800} />
    );
};

export function Filters(props: {
    filters: ReportDefinition["filters"];
    selectedFilters: ReportRequest<ReportDefinition>["filters"];
    onFiltersChange: (
        values: ReportRequest<ReportDefinition>["filters"]
    ) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(reportStyleFunc);
    const returnValue: JSX.Element[] = [];
    const reportFormQuery = useReportFormQuery();
    const theme = useTheme();

    if (reportFormQuery.loading || !reportFormQuery.data) {
        return <Loading />;
    }

    // Check the filters and see if there are some, that should not be shown.
    const availableFilterCount = Object.entries(props.filters).filter(
        ([filterName, filterData]) =>
            filterName !== "report_date" &&
            (!filterData.internalUse || filterData.internalUse !== true)
    ).length;
    if (!availableFilterCount) {
        return null;
    }

    for (const [filterName, filter] of Object.entries(props.filters)) {
        if (
            !filter || // If there was no filter defined, then back off.
            filter.internalUse || // the filters for internal use are ignored in the UI.
            filter.type === "BETWEEN" || // the to/from dates for the Between filter will be taken from date pickers.
            filter.type === "TO" || // the to dates for this filter will be taken from date pickers.
            filter.type === "FROM" // the from dates for this filter will be taken from date pickers.
        ) {
            continue;
        }

        const valuesForFilter = resolveFilterValues(
            filterName,
            reportFormQuery.data.reportParameters
        );

        returnValue.push(
            <InputControl
                style={styles.inputCell}
                key={filterName}
                description={t(
                    "report.description.filter",
                    "Filters the report data by {{ filterName }}",
                    {
                        replace: {
                            filterName: t(
                                filter.translationKey,
                                filter.translationKey
                            ).toLowerCase(),
                        },
                    }
                )}
            >
                {filter.type === "IN" && (
                    <Select
                        isMulti={true}
                        hideSelectedOptions={false}
                        options={valuesForFilter}
                        placeholder={t("report.all", "All")}
                        onChange={selectedItems => {
                            props.onFiltersChange(
                                produce(props.selectedFilters, draft => {
                                    if (selectedItems.length === 0) {
                                        delete draft[filterName];
                                    } else {
                                        draft[filterName] = {
                                            values: selectedItems.map(item =>
                                                String(item.value)
                                            ),
                                        };
                                    }
                                })
                            );
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                            // Need to raise zIndex otherwise the dropdown wont be on top
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            control: (baseStyles, _) => ({
                                ...baseStyles,
                                paddingLeft: 6,
                                paddingRight: 6,
                                borderRadius: theme.borderRadiusSmall,
                                backgroundColor: theme.colors.white,
                                width: "100%",
                                borderWidth: StyleSheet.hairlineWidth,
                                marginBottom: theme.spacingScale,
                                minHeight: 40,
                                borderColor: theme.colors.secondary,
                            }),
                            // Styling of the chip
                            multiValue: (base, _) => ({
                                ...base,
                                borderStyle: "solid",
                                borderRadius: 16,
                                borderColor: theme.colors.grey250,
                                backgroundColor: theme.colors.white,
                                borderWidth: 1.5,
                            }),
                            // Style the text in chip
                            multiValueLabel: base => ({
                                ...base,
                                color: theme.colors.grey800,
                            }),
                            // Remove the hover effect on the remove icon
                            multiValueRemove: base => ({
                                ...base,
                                color: theme.colors.grey250,
                                ":hover": {},
                            }),
                        }}
                        components={{
                            IndicatorSeparator,
                            DropdownIndicator: CustomDropdownIndicator,
                        }}
                    />
                )}

                {filter.type === "EQUALS" && (
                    <Picker
                        label={t(filter.translationKey, filter.translationKey)}
                        selectedValue={
                            props.selectedFilters[filterName]
                                ? (
                                      props.selectedFilters[
                                          filterName
                                      ] as FilterTypeDefinitions["EQUALS"]
                                  ).value
                                : undefined
                        }
                        onValueChange={value => {
                            // If no value is selected, remove the filter, otherwise update the selected filter
                            if (value === "") {
                                props.onFiltersChange(
                                    produce(props.selectedFilters, draft => {
                                        delete draft[filterName];
                                    })
                                );
                            } else {
                                props.onFiltersChange(
                                    produce(props.selectedFilters, draft => {
                                        draft[filterName] = {
                                            value: value,
                                        };
                                    })
                                );
                            }
                        }}
                    >
                        <Picker.Item
                            label={t("report.all", "All")}
                            value=""
                            key={`${filterName}_all`}
                            disabled={valuesForFilter.length === 0}
                        />
                        {valuesForFilter.map((filterValue, index) => (
                            <Picker.Item
                                label={filterValue.label}
                                value={filterValue.value}
                                key={index}
                            />
                        ))}
                    </Picker>
                )}
            </InputControl>
        );
    }

    return (
        <View>
            <Headline size="h5">{t("report.filters", "Filters")}</Headline>
            <View style={styles.row}>
                <>{returnValue}</>
            </View>
        </View>
    );
}
