import { useApolloClient } from "@apollo/client";
import {
    Button,
    Form,
    InputControl,
    NumberInput,
    Picker,
    SortDirection,
    Surface,
    TextInput,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQAccountInput,
    GQTagsWithoutAccountQuery,
    GQTagsWithoutAccountQueryVariables,
    TagsWithoutAccountDocument,
    useAccountAssignTagsMutation,
    useVatsQuery,
} from "graphql-sdk";
import { parseAmount } from "lib";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import {
    AssignTagsModalProps,
    useAssignTagsModal,
} from "../../tags/assign-tags-modal/assign-tags-modal";

import type { ITheme, StyleFunction } from "@venuepos/react-common";
export function AccountForm(props: {
    id: string | undefined;
    form: Form<GQAccountInput>;
    onSubmit: () => void;
    submitButton: [string, string];
    isCreate: boolean;
    isGiftCard: boolean;
    enabledTags?: AssignTagsModalProps["enabledTags"];
    vatId: string | undefined;
}) {
    const [setAccountAssignTags] = useAccountAssignTagsMutation();
    const graphqlClient = useApolloClient();
    const [t] = useTranslation();
    const assignTagsModal = useAssignTagsModal();
    const styles = useThemedStyle(styleFunc);
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const [enabledTags, setEnabledTags] = useState<
        AssignTagsModalProps["enabledTags"]
    >(props.enabledTags || []);

    const { data: vatsData, loading: vatsLoading } = useVatsQuery();

    const merchantConfig = useMerchantConfig();

    const handleTagsFindTabQuery = useCallback(
        async (variables: {
            pagination: {
                page: number;
                pageSize: number;
                sort: string | undefined;
                sortDirection: SortDirection;
            };
            search: {
                query: string;
            };
        }) => {
            const tagsWithoutAccount = await graphqlClient.query<
                GQTagsWithoutAccountQuery,
                GQTagsWithoutAccountQueryVariables
            >({
                query: TagsWithoutAccountDocument,
                variables,
                fetchPolicy: "no-cache",
            });

            if (!tagsWithoutAccount.data) {
                throw new Error("Failed to fetch tags data");
            }

            return {
                data: tagsWithoutAccount.data.tagsWithoutAccount.data,
                loading: tagsWithoutAccount.loading,
                pagination:
                    tagsWithoutAccount.data.tagsWithoutAccount.pagination,
            };
        },
        [graphqlClient]
    );

    const handleAssignTags = useCallback(async () => {
        const tags = await assignTagsModal({
            enabledTags,
            tagsFindQuery: handleTagsFindTabQuery,
        });
        if (tags === undefined) {
            // No update
            return;
        }
        await setAccountAssignTags({
            variables: { id: props.id!, tagIds: tags || [] },
        });
        setEnabledTags(tags);
    }, [
        assignTagsModal,
        enabledTags,
        handleTagsFindTabQuery,
        props.id,
        setAccountAssignTags,
    ]);

    if (vatsLoading || !values) {
        return null;
    }

    return (
        <View style={styles.formBox}>
            <Surface>
                <InputControl error={errors.name}>
                    <TextInput
                        label={t("common.name", "Name")}
                        placeholder={t(
                            "backoffice.common.enter_name",
                            "Enter name"
                        )}
                        defaultValue={values.name}
                        onChangeText={text => setValue("name", text)}
                        testID="account:name"
                    />
                </InputControl>

                <InputControl error={errors.status}>
                    <Picker
                        label={t("common.status", "Status")}
                        onValueChange={value => {
                            setValue("status", value);
                        }}
                        selectedValue={values.status || ""}
                        testID="account:status"
                    >
                        <Picker.Item
                            label={t(
                                "backoffice.account.choose_status",
                                "Choose status"
                            )}
                            value=""
                        />
                        <Picker.Item
                            key="OPEN"
                            value="OPEN"
                            label={t("backoffice.account.status.open", "Open")}
                            testID="open"
                        />
                        <Picker.Item
                            key="CLOSED"
                            value="CLOSED"
                            label={t(
                                "backoffice.account.status.closed",
                                "Closed"
                            )}
                            testID="closed"
                        />
                    </Picker>
                </InputControl>

                <InputControl
                    error={errors.externalId}
                    description={t(
                        "backoffice.account.external_id_description",
                        "Number or ID that is used by another system. Used for reference."
                    )}
                >
                    <TextInput
                        label={t("common.external_id", "External ID")}
                        placeholder={t(
                            "backoffice.common.enter_external_id",
                            "Enter external identifier"
                        )}
                        defaultValue={values.externalId ?? ""}
                        onChangeText={text => setValue("externalId", text)}
                        testID="account:externalId"
                    />
                </InputControl>

                {!props.vatId && props.isGiftCard && (
                    <InputControl error={errors.vatId}>
                        <Picker
                            label={t(
                                "backoffice.account.vat",
                                "VAT percentage"
                            )}
                            onValueChange={value => {
                                setValue("vatId", value);
                            }}
                            selectedValue={values.vatId || undefined}
                            testID="account:vat"
                        >
                            <Picker.Item
                                label={t(
                                    "backoffice.account.choose_vat",
                                    "Choose VAT"
                                )}
                                value=""
                            />
                            {vatsData!.vats.data.map(vat => {
                                return (
                                    <Picker.Item
                                        key={vat.id}
                                        value={vat.id}
                                        label={vat.name}
                                        testID={"vat:" + vat.name}
                                    />
                                );
                            })}
                        </Picker>
                    </InputControl>
                )}

                {!props.isCreate && (
                    <Button
                        style={styles.assignTagsButton}
                        onPress={handleAssignTags}
                        testID="account:assignTags"
                    >
                        {t(
                            "backoffice.account.assign_tags.button",
                            "Assign tags"
                        )}
                    </Button>
                )}

                {props.isCreate && (
                    <InputControl error={errors.amount}>
                        <NumberInput
                            label={t("backoffice.account.amount", "Amount")}
                            placeholder={t(
                                "backoffice.account.enter_amount",
                                "Enter amount"
                            )}
                            defaultValue={values.amount / 100}
                            onChange={value =>
                                setValue("amount", parseAmount(value || 0))
                            }
                            decimals={2}
                            unit={merchantConfig.currency}
                            testID="account:amount"
                        />
                    </InputControl>
                )}

                <Button
                    onPress={handleSubmit(props.onSubmit)}
                    testID="account:save"
                >
                    {t(props.submitButton[0], props.submitButton[1])}
                </Button>
            </Surface>
        </View>
    );
}

const styleFunc: StyleFunction = (theme: ITheme) => ({
    formBox: {
        maxWidth: theme.dimensions.maxFormWidth,
    },
    assignTagsButton: {
        marginBottom: 20,
    },
});
