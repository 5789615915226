import {
    ButtonDimensions,
    ButtonPosition,
    LayoutTableButton,
    TableSection,
} from "lib";
import {
    LayoutActionType,
    LayoutAction,
    LayoutReducerState,
} from "../../layout-operation-reducer";
import { produceNewLayoutSection } from "../../functions";

export type TableLayoutReducerState = Omit<LayoutReducerState, "sections"> & {
    sections: (TableSection & Partial<{ renaming: boolean }>)[];
};

export enum TableLayoutAction {
    ADD_BUTTON_TO_SECTION = "add_button_to_section",
    MOVE_BUTTON_IN_SECTION = "move_button_in_section",
    REMOVE_BUTTON_FROM_SECTION = "remove_button_from_section",
    RESIZE_BUTTON_IN_SECTION = "resize_button_in_section",
    UPDATE_BUTTON_IN_SECTION = "update_button_in_section",
}

export type TableLayoutActionType =
    | {
          type: TableLayoutAction.ADD_BUTTON_TO_SECTION;
          newButton: LayoutTableButton;
      }
    | {
          type: TableLayoutAction.MOVE_BUTTON_IN_SECTION;
          buttonId: LayoutTableButton["id"];
          position: ButtonPosition;
      }
    | {
          type: TableLayoutAction.REMOVE_BUTTON_FROM_SECTION;
          buttonId: LayoutTableButton["id"];
      }
    | {
          type: TableLayoutAction.RESIZE_BUTTON_IN_SECTION;
          buttonId: LayoutTableButton["id"];
          buttonDimensions: ButtonDimensions;
      }
    | {
          type: TableLayoutAction.UPDATE_BUTTON_IN_SECTION;
          updatedButton: LayoutTableButton;
      };

export const tableLayoutReducer = (
    state: TableLayoutReducerState,
    action: LayoutActionType | TableLayoutActionType
) => {
    let newState: typeof state;
    let foundSectionIndex: number;
    let foundSection: typeof newState.sections[0] | undefined;
    let foundButtonIndex: number | undefined;
    let foundButton: LayoutTableButton | undefined;

    switch (action.type) {
        case LayoutAction.ADD_SECTION: {
            if (!action.translationFunction) {
                return state;
            }

            newState = { ...state };

            newState.sections.push(
                produceNewLayoutSection(
                    action.translationFunction
                ) as typeof newState.sections[0]
            );

            break;
        }

        case LayoutAction.DELETE_SECTION: {
            foundSectionIndex = state.sections.findIndex(
                itr => itr.id === action.sectionId
            );

            if (foundSectionIndex === -1) {
                return state;
            }

            newState = { ...state };
            // Find the section and remove it.
            newState.sections.splice(foundSectionIndex, 1);

            let newCurrentSection = state.currentSectionIndex;

            // If this was the last section, then add a new one.
            if (newState.sections.length === 0) {
                newState.sections.push(
                    produceNewLayoutSection(
                        action.translationFunction
                    ) as typeof newState.sections[0]
                );
            }

            // If the currently deleted section was before the currently active section,
            // then adjust the index of the active section
            if (state.currentSectionIndex >= foundSectionIndex) {
                state.currentSectionIndex = state.currentSectionIndex - 1;
            }

            // Make sure the index never gets faulty
            if (newCurrentSection < 0) {
                state.currentSectionIndex = 0;
            }

            break;
        }

        case LayoutAction.MOVE_SECTION: {
            const layoutIndex = state.sections.findIndex(
                section => section.id === action.sectionId
            );

            if (layoutIndex === -1) {
                return state;
            }

            if (!["BEFORE", "AFTER"].includes(action.direction)) {
                return state;
            }

            const newPosition =
                action.direction === "BEFORE"
                    ? layoutIndex - 1
                    : layoutIndex + 1;

            // if newPosition is out of bounds: back off.
            if (newPosition < 0) {
                return state;
            } else if (newPosition > state.sections.length) {
                return state;
            }

            newState = { ...state };

            // Move section from layoutIndex to newPosition
            newState.sections.splice(
                newPosition,
                0,
                newState.sections.splice(layoutIndex, 1)[0]
            );

            // Make sure the selected section makes sense.
            if (layoutIndex === state.currentSectionIndex) {
                newState.currentSectionIndex = newPosition;
            } else if (newPosition === state.currentSectionIndex) {
                newState.currentSectionIndex = layoutIndex;
            }
            break;
        }

        case LayoutAction.RENAME_SECTION: {
            foundSection = state.sections.find(
                itr => itr.id === action.sectionId
            );

            if (!foundSection) {
                return state;
            }

            newState = { ...state };

            // replace section
            newState.sections.map(sectionItr => {
                if (sectionItr.id === action.sectionId) {
                    sectionItr.label = action.newLabel.trim();
                }

                return sectionItr;
            });

            break;
        }

        case LayoutAction.SELECT_SECTION: {
            foundSectionIndex = state.sections.findIndex(
                itr => itr.id === action.sectionId
            );

            if (foundSectionIndex === -1) {
                return state;
            }

            newState = {
                ...state,
                currentSectionIndex: foundSectionIndex,
            };

            break;
        }

        case LayoutAction.TOGGLE_RENAME_SECTION: {
            foundSection = state.sections.find(
                itr => itr.id === action.sectionId
            );

            if (!foundSection) {
                return state;
            }

            newState = { ...state };
            newState.sections.map(sectionItr => {
                if (sectionItr.id === action.sectionId) {
                    // toggle the renaming property for this section
                    sectionItr.renaming = !sectionItr.renaming;
                }
                return sectionItr;
            });
            break;
        }

        case TableLayoutAction.ADD_BUTTON_TO_SECTION: {
            newState = { ...state };

            newState.sections[newState.currentSectionIndex].buttons.push(
                action.newButton
            );

            break;
        }

        case TableLayoutAction.MOVE_BUTTON_IN_SECTION: {
            // If, for some odd reason, the button does not have an id or is dropped in the same location, then back off.
            if (!action.buttonId) {
                return state;
            }

            foundSection = state.sections[state.currentSectionIndex];

            if (!foundSection) {
                return state;
            }

            foundButtonIndex = state.sections[
                state.currentSectionIndex
            ].buttons.findIndex(buttonItr => buttonItr.id === action.buttonId);

            if (foundButtonIndex === -1) {
                return state;
            }

            newState = { ...state };
            foundSection = newState.sections[newState.currentSectionIndex];

            let updatedButton: LayoutTableButton =
                foundSection.buttons[foundButtonIndex];

            updatedButton.x = action.position.x;
            updatedButton.y = action.position.y;

            let updatedButtons: LayoutTableButton[] = foundSection.buttons.map(
                button => {
                    if (button.id === action.buttonId) {
                        return updatedButton;
                    }

                    return button;
                }
            );

            newState.sections[newState.currentSectionIndex].buttons =
                updatedButtons;

            break;
        }

        case TableLayoutAction.REMOVE_BUTTON_FROM_SECTION: {
            if (!action.buttonId) {
                return state;
            }

            foundSection = state.sections[state.currentSectionIndex];

            if (!foundSection) {
                return state;
            }

            foundButton = state.sections[
                state.currentSectionIndex
            ].buttons.find(itr => itr.id === action.buttonId);

            if (!foundButton) {
                return state;
            }

            newState = { ...state };

            newState.sections[newState.currentSectionIndex].buttons =
                newState.sections[newState.currentSectionIndex].buttons.filter(
                    itr => itr.id !== action.buttonId
                );

            break;
        }

        case TableLayoutAction.RESIZE_BUTTON_IN_SECTION: {
            // If, for some odd reason, the button does not have an id or is dropped in the same location, then back off.
            if (!action.buttonId) {
                return state;
            }

            foundSection = state.sections[state.currentSectionIndex];

            if (!foundSection) {
                return state;
            }

            foundButtonIndex = state.sections[
                state.currentSectionIndex
            ].buttons.findIndex(buttonItr => buttonItr.id === action.buttonId);

            if (foundButtonIndex === -1) {
                return state;
            }

            newState = { ...state };
            foundSection = newState.sections[newState.currentSectionIndex];

            let updatedButton: LayoutTableButton =
                foundSection.buttons[foundButtonIndex];

            updatedButton.width = action.buttonDimensions.width;
            updatedButton.height = action.buttonDimensions.height;

            newState.sections[newState.currentSectionIndex].buttons[
                foundButtonIndex
            ] = updatedButton;

            break;
        }

        case TableLayoutAction.UPDATE_BUTTON_IN_SECTION: {
            if (!action.updatedButton.id) {
                return state;
            }

            const foundButtons =
                state.sections[state.currentSectionIndex].buttons;

            if (foundButtons === undefined) {
                return state;
            }

            newState = { ...state };

            foundButtonIndex = foundButtons.findIndex(
                buttonItr => buttonItr?.id === action.updatedButton.id
            );

            if (foundButtonIndex === -1) {
                return state;
            }

            newState.sections[newState.currentSectionIndex].buttons[
                foundButtonIndex
            ] = action.updatedButton;

            break;
        }

        default: {
            throw new Error(
                `Invalid Table Layout Operation: ${JSON.stringify(action)}`
            );
        }
    }

    return newState;
};
