import { ReportDefinition, ReportTypes } from "./types";

export const invoicesDefinition: ReportDefinition = {
    translationKey: "report.invoices.name",
    reportType: ReportTypes.INVOICES,
    primaryTable: "rm_invoice",
    joins: {
        cash_register: {
            sql: "JOIN cash_register ON cash_register.id = rm_invoice.cash_register_id",
        },
        department: {
            sql: "LEFT JOIN department ON department.id = rm_invoice.department_id",
        },
        user: {
            sql: 'JOIN "user" ON "user".id = rm_invoice.user_id',
        },
    },
    fields: {
        department_name: {
            translationKey: "report.columns.department_name",
            type: "SIMPLE",
            field: { table: "department", column: "name" },
            selectedByDefault: true,
            dependOnJoin: ["department"],
            default: "No department",
        },
        cash_register: {
            translationKey: "report.columns.cash_register",
            type: "SIMPLE",
            field: { table: "cash_register", column: "name" },
            selectedByDefault: true,
            dependOnJoin: ["cash_register"],
            default: "No cash register",
        },
        user_name: {
            translationKey: "report.columns.user_name",
            type: "SIMPLE",
            field: { table: "user", column: "username" },
            selectedByDefault: true,
            dependOnJoin: ["user"],
        },
        amount: {
            translationKey: "report.columns.amount",
            type: "AMOUNT",
            field: { table: "rm_invoice", column: "amount" },
            selectedByDefault: true,
        },
        receipt_number: {
            translationKey: "report.columns.receipt_number",
            type: "SIMPLE",
            field: { table: "rm_invoice", column: "receipt_number" },
            selectedByDefault: true,
        },
        discount_amount: {
            translationKey: "report.columns.discount_amount",
            type: "AMOUNT",
            field: { table: "rm_invoice", column: "discount_amount" },
            selectedByDefault: true,
        },
        receipt_name: {
            translationKey: "report.columns.note",
            type: "SIMPLE",
            field: { table: "rm_invoice", column: "name" },
            selectedByDefault: true,
        },
        receipt_date: {
            translationKey: "report.columns.report_date",
            type: "CUSTOM",
            content:
                "TO_CHAR(rm_invoice.created_at::TIMESTAMPTZ AT TIME ZONE {{time_zone}}, 'YYYY-MM-DD HH24:MI')",
            selectedByDefault: true,
            formatAs: "DATE",
        },
    },
    filters: {
        cash_register: {
            field: { table: "cash_register", column: "id" },
            translationKey: "report.columns.cash_register",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["cash_register"],
        },
        department: {
            field: { table: "department", column: "id" },
            translationKey: "report.columns.department",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["department"],
        },
        user: {
            field: { table: "user", column: "id" },
            translationKey: "report.columns.user_name",
            type: "IN",
            inType: "UUID",
            dependOnJoin: ["user"],
        },
        merchant_id: {
            field: { table: "rm_invoice", column: "merchant_id" },
            translationKey: "report.columns.merchant_id",
            type: "EQUALS",
            internalUse: true,
        },
        receipt_date: {
            field: {
                table: "rm_invoice",
                column: "created_at",
            },
            translationKey: "report.columns.created_at",
            type: "BETWEEN",
        },
    },
    grouping: {},
    ordering: {
        cash_register: {
            translationKey: "report.columns.cash_register",
            type: "CUSTOM",
            content: "cash_register",
        },
        department_name: {
            translationKey: "report.columns.department_name",
            type: "CUSTOM",
            content: "department_name",
        },
        user_name: {
            translationKey: "report.columns.user_name",
            type: "CUSTOM",
            content: "user_name",
        },
    },
};
