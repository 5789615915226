import { ModalContext } from "./context";
import React, { useContext } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

/**
 * Where the modals are rendered
 */
export function ModalContainer() {
    const ctx = useContext(ModalContext);
    if (!ctx) {
        return null;
    }

    const { stack } = ctx;

    return (
        <>
            {stack.map(modal => (
                <TouchableOpacity
                    onPress={() => {
                        if (!modal.closeOnOutside) {
                            return;
                        }
                        modal.resolve();
                    }}
                    key={modal.id}
                    style={[StyleSheet.absoluteFill, styles.modalContainer]}
                    activeOpacity={1}
                >
                    {modal.renderContent(modal.resolve)}
                </TouchableOpacity>
            ))}
        </>
    );
}

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
});
