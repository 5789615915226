import {
    Form,
    InputControl,
    InputLabel,
    Slider,
    StyleFunction,
    TextInput,
    useThemedStyle,
} from "@venuepos/react-common";
import type { LayoutButton } from "lib";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

export function ButtonSizeForm({
    form,
}: {
    form: Form<Pick<LayoutButton, "height" | "width" | "metaData">>;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ values, errors }, { setValue }] = form;

    if (!values) {
        return null;
    }

    return (
        <View style={styles.rowContainer}>
            <InputControl
                error={errors.height}
                style={[styles.rowContent, styles.notLastCell]}
            >
                {values.metaData?.maxHeightValue &&
                values.metaData.maxHeightValue > 1 ? (
                    <>
                        <InputLabel>
                            {`${t("layout.button.height", "Button height")}: ${
                                values.height
                            } (${t("general.max", "max.")}: ${
                                values.metaData.maxHeightValue
                            })`}
                        </InputLabel>
                        <Slider
                            key="slider_height"
                            value={values.height}
                            minimumValue={1}
                            maximumValue={values.metaData.maxHeightValue}
                            onValueChange={value =>
                                setValue("height", Number(value))
                            }
                            marks={[
                                { name: "1", value: 1 },
                                {
                                    name: values.metaData.maxHeightValue,
                                    value: values.metaData.maxHeightValue,
                                },
                            ]}
                            containerStyle={styles.sliderContainerStyle}
                        />
                    </>
                ) : (
                    <TextInput
                        disabled={true}
                        label={t("layout.button.height", "Button height")}
                        placeholder={t(
                            "layout.button.height_placeholder",
                            "How tall is the button in rows?"
                        )}
                        defaultValue={(values.height || 1).toString(10)}
                    />
                )}
            </InputControl>
            <InputControl error={errors.width} style={[styles.rowContent]}>
                {values.metaData?.maxWidthValue &&
                values.metaData.maxWidthValue > 1 ? (
                    <>
                        <InputLabel>
                            {`${t("layout.button.width", "Button width")}: ${
                                values.width
                            } (${t("general.max", "max.")}: ${
                                values.metaData.maxWidthValue
                            })`}
                        </InputLabel>
                        <Slider
                            key="slider_width"
                            value={values.width}
                            minimumValue={1}
                            maximumValue={values.metaData.maxWidthValue}
                            onValueChange={value =>
                                setValue("width", Number(value))
                            }
                            containerStyle={styles.sliderContainerStyle}
                            marks={[
                                { name: "1", value: 1 },
                                {
                                    name: values.metaData.maxWidthValue,
                                    value: values.metaData.maxWidthValue,
                                },
                            ]}
                        />
                    </>
                ) : (
                    <TextInput
                        disabled={true}
                        label={t("layout.button.width", "Button width")}
                        placeholder={t(
                            "layout.button.width_placeholder",
                            "How many columns wide is the button?"
                        )}
                        defaultValue={(values.width || 1).toString(10)}
                    />
                )}
            </InputControl>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContent: {
        flex: 1,
        visibility: "visible",
    },
    rowContainer: {
        flexDirection: "row",
    },
    notLastCell: {
        paddingEnd: theme.spacingScale,
    },
    sliderLabel: { marginBottom: theme.spacingScale * 2 },
    sliderContainerStyle: {
        flex: 1,
    },
});
