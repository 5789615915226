import {
    GQCustomerGroupInput,
    useCustomerGroupCreateMutation,
} from "graphql-sdk";
import React, { useCallback } from "react";
import { schemaCustomerGroup, defaultCustomerGroup } from "lib";
import { useTranslation } from "react-i18next";
import { useAuth, useForm } from "@venuepos/react-common";
import { Form } from "../form";
import { useHandleMutationError } from "../../../../../hooks/use-handle-mutation-error";
import { StackScreenProps } from "@react-navigation/stack";
import { RootStackParamList } from "../../../../../navigation";

type ScreenProps = StackScreenProps<
    RootStackParamList,
    "CUSTOMER_GROUP_CREATE"
>;

export function CustomerGroupCreateScreen({
    navigation: { navigate },
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.customer");

    const [t] = useTranslation();
    const [customerGroupCreate] = useCustomerGroupCreateMutation();
    const { handleMutationError } = useHandleMutationError();

    const form = useForm<GQCustomerGroupInput>(
        schemaCustomerGroup,
        defaultCustomerGroup
    );
    const [{ values }, { reset }] = form;

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await handleMutationError(
            async () =>
                await customerGroupCreate({
                    variables: {
                        customerGroup: {
                            name: values.name,
                        },
                    },
                }),
            t("backoffice.customer_group.created", "Customer group created"),
            () => {
                reset();
                navigate("ACCOUNT_PAYMENTS", { refetch: true });
            }
        );
    }, [values, handleMutationError, t, customerGroupCreate, reset, navigate]);

    return (
        <Form
            form={form}
            onSubmit={create}
            submitButton={["common.create", "Create"]}
        />
    );
}
