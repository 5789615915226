import type { PaymentMethodInputForm } from "lib";

export const defaultPaymentMethod: PaymentMethodInputForm = {
    name: "",
    paymentType: null,
    account: "",
    diffAccount: "",
    enabled: true,
    currencyId: "",
};
