import type { IDepartmentInput } from ".";
import type { ValidationSchema } from "../validation";

export const schemaDepartment: ValidationSchema<IDepartmentInput> = {
    name: { required: true },
};

export const defaultDepartment: IDepartmentInput = {
    name: "",
    externalId: null,
};
