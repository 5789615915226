import {
    LoadingScreen,
    StyleFunction,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQTagGroupInput,
    useTagGroupLazyQuery,
    useTagGroupSaveMutation,
} from "graphql-sdk";
import { schemaTagGroup } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useHandleMutationError } from "../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../navigation";
import { TagScreen } from "../../tag-screen";
import { Form } from "../form";
import { GroupTags } from "./group-tags";

type ScreenProps = RootStackScreenProps<"TAG_GROUP_EDIT">;

export function TagGroupEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.tag");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [tagGroupEdit] = useTagGroupSaveMutation();
    const [getTagGroup, tagGroup] = useTagGroupLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<GQTagGroupInput>(schemaTagGroup, null);
    const [{ values }, { setDefaultValues }] = form;
    const { handleMutationError } = useHandleMutationError();
    const groupId = route.params.id;

    useEffect(() => {
        if (!groupId) {
            navigate("TAGS");
            return;
        }
        getTagGroup({ variables: { id: groupId } });
    }, [getTagGroup, navigate, groupId]);

    useEffect(() => {
        if (tagGroup.data && tagGroup.data.tagGroup) {
            setDefaultValues(tagGroup.data.tagGroup);
        }
    }, [tagGroup.data, setDefaultValues]);

    const redirect = useCallback(() => {
        // If the page was loaded with a referrer, then return to that referrer.
        // The referrer could be e.g. CASH_REGISTER_EDIT with a specific id, but could also be to the general MERCHANT_SETTINGS,
        // so the id could be missing from the referrer argument
        if (!route.params.referrer || !route.params.referrer.route) {
            return;
        }

        if (
            route.params.referrer.route === "TAG_EDIT" &&
            route.params.referrer.id
        ) {
            navigate("TAG_EDIT", { id: route.params.referrer.id });
        }
    }, [route.params, navigate]);

    const edit = useCallback(async () => {
        if (!tagGroup.data || !tagGroup.data.tagGroup || !values) {
            return;
        }

        await handleMutationError(
            async () =>
                await tagGroupEdit({
                    variables: {
                        id: tagGroup.data!.tagGroup.id,
                        tagGroup: {
                            name: values.name,
                        },
                    },
                }),
            t("backoffice.tag_group.saved", "Tag group saved"),
            redirect
        );
    }, [tagGroup.data, values, handleMutationError, t, redirect, tagGroupEdit]);

    if (!tagGroup.data) {
        return <LoadingScreen style="light" />;
    }

    return (
        <TagScreen>
            <View style={styles.container}>
                <View style={styles.left}>
                    <Form
                        form={form}
                        onSubmit={edit}
                        submitButton={["common.save", "Save"]}
                    />
                </View>
                <View style={styles.right}>
                    <GroupTags group={groupId} />
                </View>
            </View>
        </TagScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    left: {
        marginBottom: theme.spacingScale * 2,
        marginRight: theme.spacingScale * 3,
        minWidth: 400,
    },
    right: {
        minWidth: 600,
    },
});
