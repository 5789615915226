import type { AuthToken } from "lib";

export function localizeAuthToken(authToken: AuthToken): AuthToken {
    const serverNow = new Date(authToken.now);
    const timeOffset = Date.now() - serverNow.getTime();
    return {
        ...authToken,
        now: new Date(serverNow.getTime() + timeOffset).toISOString(),
        accessExpire: authToken.accessExpire + Math.floor(timeOffset / 1000),
    };
}
