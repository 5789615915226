import { useAuth, useForm, useMerchantConfig } from "@venuepos/react-common";
import {
    usePrintersQuery,
    useProductCreateMutation,
    useVatsQuery,
} from "graphql-sdk";
import { defaultProduct, IProductInput, schemaProduct } from "lib";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { ProductForm } from "../product-form";
import { ProductScreen } from "../product-screen";

type ScreenProps = RootStackScreenProps<"PRODUCT_EDIT">;

export function ProductCreateScreen({ navigation: { navigate } }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.product");

    const { currency } = useMerchantConfig();
    const [t] = useTranslation();
    const [productCreate] = useProductCreateMutation();
    const { handleMutationError } = useHandleMutationError();

    const { data: vatsData } = useVatsQuery();
    const { data: printersData } = usePrintersQuery({
        variables: {
            pagination: {
                pageSize: 999999,
                sort: "name",
                sortDirection: "ASC",
            },
        },
    });

    const form = useForm<IProductInput>(schemaProduct, defaultProduct);
    const [{ values }, { reset }] = form;

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await handleMutationError(
            async () =>
                await productCreate({
                    variables: {
                        product: {
                            parentId: values.parentId,
                            groupId: values.groupId,
                            externalId: values.externalId,
                            name: values.name,
                            amount: values.amount,
                            minimumAmount: values.minimumAmount,
                            costAmount: values.costAmount,
                            ownVatId: values.ownVatId || null,
                            onlineRequired: values.onlineRequired,
                            active: values.active,
                            isExternal: values.isExternal,
                            ownExcludeFromDiscounts:
                                values.ownExcludeFromDiscounts,
                            barcode: values.barcode,
                            barcodes: values.barcodes,
                            printerId: values.printerId || null,
                            inheritPrinter: values.inheritPrinter,
                        },
                    },
                }),
            t("backoffice.product.created", "Product created"),
            () => {
                reset();
                navigate("PRODUCTS", { refetch: true });
            }
        );
    }, [handleMutationError, navigate, productCreate, reset, t, values]);

    return (
        <ProductScreen>
            <ProductForm
                form={form}
                onSubmit={create}
                currency={currency}
                submitButton={["common.create", "Create"]}
                printers={printersData}
                vats={vatsData}
            />
        </ProductScreen>
    );
}
