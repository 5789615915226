import { StyleFunction, useThemedStyle } from "@venuepos/react-common";
import { v4 as uuidv4 } from "uuid";
import type {
    ButtonDimensions,
    ButtonPosition,
    GridDimensions,
    LayoutTableButton,
} from "lib";
import React, { useCallback, useMemo } from "react";
import GridLayout from "react-grid-layout";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import {
    CONTAINER_PADDING,
    CONTAINER_PADDING_VERTICAL,
    ITEM_MARGIN,
    ITEM_MARGIN_VERTICAL,
} from "./layout-editor";
import { VisualGrid } from "./visual-grid";
import { VisualLayoutButton } from "./visual-layout-button";

const newButtonDefaultWidth = 2;
const newButtonDefaultHeight = 2;
const dropItem = {
    i: "dropElement",
    w: newButtonDefaultWidth,
    h: newButtonDefaultHeight,
};

export function GridEditor({
    dimensions,
    gridHeight,
    gridWidth,
    buttons,
    onShowButtonSettings,
    onAddButton,
    onMoveButton,
    onResizeButton,
}: {
    dimensions: GridDimensions;
    gridHeight: number;
    gridWidth: number;
    buttons: LayoutTableButton[];
    onShowButtonSettings: (buttonId: LayoutTableButton["id"]) => void;
    onAddButton: (newButton: LayoutTableButton) => void;
    onMoveButton: (
        buttonId: LayoutTableButton["id"],
        position: ButtonPosition
    ) => void;
    onResizeButton: (
        buttonId: LayoutTableButton["id"],
        dimensions: ButtonDimensions
    ) => void;
}) {
    const styles = useThemedStyle(styleFunc);

    /**
     * Callback for dropping a new table/button onto the grid.
     */
    const handleAddButton = useCallback(
        (layout, layoutItem) => {
            // If the layout algorithm suggest to add the new button outside the grid, then back off. This is not a good idea.
            if (
                layoutItem.y + layoutItem.h > dimensions.rows ||
                layoutItem.x + layoutItem.w > dimensions.columns
            ) {
                return;
            }

            const newButton: LayoutTableButton = {
                buttonType: "TABLE",
                x: layoutItem.x,
                y: layoutItem.y,
                width: newButtonDefaultWidth,
                height: newButtonDefaultHeight,
                color: "",
                label: `${buttons.length + 1}`,
                id: uuidv4(),
            };

            onAddButton(newButton);
        },
        [buttons.length, dimensions.columns, dimensions.rows, onAddButton]
    );

    const handleButtonResize = useCallback(
        (_, prevItem, newItem) => {
            const { w: oldWidth, h: oldHeight } = prevItem;
            const { w: newWidth, h: newHeight } = newItem;

            if (oldWidth === newWidth && oldHeight === newHeight) {
                return;
            }

            onResizeButton(newItem.i, { width: newItem.w, height: newItem.h });
        },
        [onResizeButton]
    );

    const handleButtonMove = useCallback(
        (_, prevItem, newItem) => {
            const { x: oldX, y: oldY } = prevItem;
            const { x: newX, y: newY } = newItem;

            if (oldX === newX && oldY === newY) {
                return;
            }

            onMoveButton(newItem.i, { x: newItem.x, y: newItem.y });
        },
        [onMoveButton]
    );

    /**
     * Make sure the resized items cannot extend below the grid.
     */
    const handleButtonResizeStart = useCallback(
        (_, __, newItem) => {
            newItem.maxW = dimensions.columns - newItem.x;
            newItem.maxH = dimensions.rows - newItem.y;
        },
        [dimensions.columns, dimensions.rows]
    );

    const layoutStyle = useMemo(
        () => ({
            height:
                gridHeight -
                ITEM_MARGIN_VERTICAL * dimensions.rows +
                CONTAINER_PADDING_VERTICAL,
        }),
        [dimensions.rows, gridHeight]
    );

    return (
        <View>
            <DroppableElement />
            <View style={styles.gridEditor}>
                <VisualGrid
                    dimensions={dimensions}
                    height={gridHeight}
                    width={gridWidth}
                />
                <GridLayout
                    className="layout"
                    cols={dimensions.columns}
                    style={layoutStyle}
                    containerPadding={CONTAINER_PADDING}
                    margin={ITEM_MARGIN}
                    rowHeight={
                        gridHeight / dimensions.rows - ITEM_MARGIN_VERTICAL * 2
                    }
                    width={gridWidth}
                    maxRows={dimensions.rows}
                    compactType={null}
                    autoSize={false}
                    preventCollision={true}
                    isDroppable={true}
                    isBounded={true}
                    onDrop={handleAddButton}
                    onResizeStart={handleButtonResizeStart}
                    onResizeStop={handleButtonResize}
                    onDragStop={handleButtonMove}
                    droppingItem={dropItem}
                >
                    {buttons.map((item: LayoutTableButton) => (
                        <div
                            style={cssStyles.button}
                            key={item.id}
                            data-grid={{
                                x: item.x,
                                y: item.y,
                                w: item.width,
                                h: item.height,
                                draggableHandle: ".gridHandle",
                                isDraggable: true, // Allow the item to be moved by the user
                                isResizable: true, // Allow the item to be resized by the user
                                static: true, // Do not move the element, when another element is moved over it.
                            }}
                        >
                            <VisualLayoutButton
                                label={item.label}
                                buttonId={item.id}
                                onPress={onShowButtonSettings}
                            />
                        </div>
                    ))}
                </GridLayout>
            </View>
        </View>
    );
}

function DroppableElement() {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    return (
        <View style={styles.droppableElement}>
            <div
                style={cssStyles.droppableElement}
                className="droppable-element"
                draggable={true}
                unselectable="on"
                // this is a hack for firefox
                // Firefox requires some kind of initialization
                // which we can do by adding this attribute
                // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
                onDragStart={e => e.dataTransfer.setData("text/plain", "")}
            >
                {t(
                    "backoffice.table_layout_editor.drag_from_here",
                    "Drag new table from here"
                )}
            </div>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    gridEditor: {
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: theme.colors.lightGrey,
    },
    droppableElement: {
        width: "auto",
        maxWidth: 200,
        minWidth: 100,
        height: 60,
        borderWidth: StyleSheet.hairlineWidth,
        borderRadius: theme.borderRadiusTiny,
        borderColor: theme.colors.grey250,
        justifyContent: "center",
        backgroundColor: theme.colors.grey100,
        marginVertical: theme.spacingScale,
        fontFamily: "NunitoSans-Regular",
        fontSize: 12,
        color: theme.colors.textDark,
    },
});

// Styles used with the React / HTML elements above
const cssStyles: { [key: string]: React.CSSProperties } = {
    button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ccc",
        padding: 1,
        cursor: "grab",
    },
    droppableElement: {
        cursor: "grab",
        padding: 20,
    },
};
