import React from "react";
import { useTranslation } from "react-i18next";
import type { IIntegrationInput, IntegrationTypes } from "lib";
import {
    Form,
    Button,
    InputControl,
    TextInput,
    Picker,
    CheckBox,
    RequiredText,
    Loading,
} from "@venuepos/react-common";
import { ChartOfAccounts } from "./chart-of-accounts";
import { useIntegrationsQuery } from "graphql-sdk";

export function GeneralForm(props: {
    form: Form<IIntegrationInput>;
    onSubmit: () => void;
    submitButton: [string, string];
    pickerDisabled: boolean;
}) {
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;

    const { data, loading } = useIntegrationsQuery({
        variables: {
            search: {
                query: "",
            },
        },
        fetchPolicy: "no-cache",
    });

    if (!values) {
        return null;
    }

    const integrationTypesArray = data?.integrations.data.map(
        integration => integration.type
    );

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name}
                    onChangeText={text => setValue("name", text)}
                    required={true}
                />
            </InputControl>

            <InputControl error={errors.type}>
                <Picker
                    label={t("backoffice.integrations.type", "Type")}
                    selectedValue={values.type}
                    onValueChange={value => {
                        setValue("type", value as IntegrationTypes);
                    }}
                    disabled={props.pickerDisabled}
                    required={true}
                >
                    <Picker.Item
                        label={t(
                            "backoffice.integrations.choose_type",
                            "Choose type"
                        )}
                        value={undefined}
                    />
                    <Picker.Item
                        label={t(
                            "backoffice.integrations.types.economic",
                            "E-conomic"
                        )}
                        value="E-CONOMIC"
                        disabled={integrationTypesArray?.includes("E-CONOMIC")}
                    />
                    <Picker.Item
                        label={t(
                            "backoffice.integrations.types.partner-api",
                            "PartnerAPI"
                        )}
                        value="PARTNER-API"
                        disabled={integrationTypesArray?.includes(
                            "PARTNER-API"
                        )}
                    />
                    <Picker.Item
                        label={t(
                            "backoffice.integrations.types.venue-access",
                            "Venue Access"
                        )}
                        value="VENUE-ACCESS"
                        disabled={integrationTypesArray?.includes(
                            "VENUE-ACCESS"
                        )}
                    />
                </Picker>
            </InputControl>

            <InputControl error={errors.active}>
                <CheckBox
                    label={t("backoffice.integrations.active", "Active")}
                    value={values.active}
                    onValueChange={value => setValue("active", value)}
                    disabled={values.type === "PARTNER-API"}
                />
            </InputControl>

            <InputControl error={errors.apiKey}>
                <TextInput
                    label={t("backoffice.integrations.api_key", "API key")}
                    placeholder={t(
                        "backoffice.integrations.enter_api_key",
                        "Enter API key"
                    )}
                    defaultValue={values.apiKey}
                    onChangeText={text => setValue("apiKey", text)}
                    required={true}
                />
            </InputControl>

            <ChartOfAccounts
                chartOfAccounts={values.merchantChartOfAccounts}
                setValue={setValue}
            />

            <Button onPress={handleSubmit(props.onSubmit)}>
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>

            <RequiredText />
        </>
    );
}
