import { useApolloClient } from "@apollo/client";
import { useFocusEffect } from "@react-navigation/native";
import {
    Button,
    Headline,
    LoadingScreen,
    SortDirection,
    Spacer,
    Surface,
    useAuth,
    useConfirm,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQTagsWithoutCustomerQuery,
    TagsWithoutCustomerDocument,
    useCustomerAssignTagsMutation,
    useCustomerDeleteMutation,
    useCustomerLazyQuery,
} from "graphql-sdk";
import { formatAmount, ITag } from "lib";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useHandleMutationError } from "../../../../hooks";
import { RootStackScreenProps } from "../../../../navigation";
import { useAdminSession } from "../../../../session";
import { useAssignTagsModal } from "../../../tags/assign-tags-modal/assign-tags-modal";
import { CustomerScreen } from "../customer-screen";
import { CustomerAccountsTab } from "./accounts-tab";
import { CustomerInfo } from "./customer-info";

import type { StyleFunction } from "@venuepos/react-common";
import type { AvailableLocale } from "locales";
type ScreenProps = RootStackScreenProps<"CUSTOMER_VIEW">;

export function CustomerViewScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.customer");

    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const customerId = route.params.id;

    const confirm = useConfirm();
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);

    const [getCustomer, customer] = useCustomerLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [deleteCustomer] = useCustomerDeleteMutation();
    const { handleMutationError } = useHandleMutationError();

    const [setCustomerAssignTags] = useCustomerAssignTagsMutation();
    const graphqlClient = useApolloClient();
    const assignTagsModal = useAssignTagsModal();

    useEffect(() => {
        if (!customerId) {
            navigate("ACCOUNT_PAYMENTS");
            return;
        }

        getCustomer({
            variables: {
                id: customerId,
                tagPagination: { sort: "name", sortDirection: "ASC" },
            },
        });
    }, [getCustomer, navigate, customerId]);

    useFocusEffect(
        useCallback(() => {
            getCustomer({
                variables: {
                    id: customerId,
                    tagPagination: { sort: "name", sortDirection: "ASC" },
                },
            });
        }, [customerId, getCustomer])
    );

    // Actions
    const handleEdit = useCallback(() => {
        navigate("CUSTOMER_EDIT", {
            id: customerId,
        });
    }, [navigate, customerId]);

    const handleDelete = useCallback(async () => {
        if (
            !(await confirm(
                t("backoffice.customer.delete", "Delete Customer?"),
                t(
                    "backoffice.customer.delete_explain",
                    "If you choose to delete this customer, you will not be able to use related accounts, tags, etc. \n\nAre you sure, you want to delete?"
                )
            ))
        ) {
            return;
        }

        await handleMutationError(
            async () =>
                await deleteCustomer({ variables: { id: customerId! } }),
            t("common.deleted", "Deleted"),
            () => {
                navigate("ACCOUNT_PAYMENTS", { refetch: true });
            }
        );
    }, [confirm, deleteCustomer, handleMutationError, navigate, customerId, t]);

    const formattedBalance = useMemo(() => {
        if (!customer.data || !customer.data.customer) {
            return "";
        }

        const customerBalance = customer.data.customer.accounts.reduce(
            (acc, item) => acc + item.amount,
            0
        );

        return formatAmount(customerBalance, currency, {
            locale: locale as AvailableLocale,
            printCurrency: true,
        });
    }, [customer.data, currency, locale]);

    const handleTagsFindTabQuery = useCallback(
        async (variables: {
            pagination: {
                page: number;
                pageSize: number;
                sort: string | undefined;
                sortDirection: SortDirection;
            };
            search: {
                query: string;
            };
        }) => {
            const tagsWithoutCustomer =
                await graphqlClient.query<GQTagsWithoutCustomerQuery>({
                    query: TagsWithoutCustomerDocument,
                    variables,
                    fetchPolicy: "no-cache",
                });

            if (!tagsWithoutCustomer.data) {
                throw new Error("Failed to fetch tags data");
            }

            return {
                data: tagsWithoutCustomer.data.tagsWithoutCustomer.data,
                loading: tagsWithoutCustomer.loading,
                pagination:
                    tagsWithoutCustomer.data.tagsWithoutCustomer.pagination,
            };
        },
        [graphqlClient]
    );

    const handleAssignTags = useCallback(async () => {
        if (!customer || customer.loading || customer.error || !customer) {
            return;
        }

        let enabledTags: ITag["id"][] = [];
        if (customer.data?.customer.tags) {
            enabledTags = customer.data.customer.tags.data.map(
                tagItr => tagItr.id
            );
        }

        const tags = await assignTagsModal({
            enabledTags,
            tagsFindQuery: handleTagsFindTabQuery,
        });
        if (tags === undefined) {
            // No update
            return;
        }

        await handleMutationError(
            async () =>
                await setCustomerAssignTags({
                    variables: { id: customerId, tagIds: tags || [] },
                }),
            t("common.saved", "Saved"),
            () => {
                getCustomer({
                    variables: {
                        id: customerId,
                        tagPagination: { sort: "name", sortDirection: "ASC" },
                    },
                });
            }
        );
    }, [
        assignTagsModal,
        customer,
        customerId,
        getCustomer,
        handleMutationError,
        handleTagsFindTabQuery,
        setCustomerAssignTags,
        t,
    ]);

    if (!customer.data || !customer.data.customer) {
        return <LoadingScreen style="light" />;
    }

    return (
        <CustomerScreen>
            <Surface>
                <View style={styles.headlineContainer}>
                    <Headline numberOfLines={2}>
                        {customer.data.customer.name}
                    </Headline>
                    <View style={styles.buttonContainer}>
                        <Button
                            onPress={handleDelete}
                            iconName="delete"
                            type="warning"
                            size="small"
                            testID="customerAccount:delete"
                            style={styles.button}
                        >
                            {t("common.delete", "Delete")}
                        </Button>
                        <Button
                            onPress={handleAssignTags}
                            iconName="tags"
                            variant="outline"
                            size="small"
                            testID="customerAccount:assignTags"
                            style={styles.button}
                        >
                            {t(
                                "backoffice.customer.assign_tags.button",
                                "Assign tags"
                            )}
                        </Button>
                        <Button
                            onPress={handleEdit}
                            iconName="edit"
                            size="small"
                            testID="customerAccount:edit"
                            style={styles.button}
                        >
                            {t("common.edit", "Edit")}
                        </Button>
                    </View>
                </View>

                <CustomerInfo
                    data={customer.data.customer}
                    balance={formattedBalance}
                    onAssignTags={handleAssignTags}
                />
            </Surface>
            <Spacer space={2} />

            <CustomerAccountsTab customerId={customerId} />
        </CustomerScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    headlineContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },
    buttonContainer: {
        flexDirection: "row",
    },
    button: {
        marginLeft: theme.spacingScale,
    },
    customerTableRow: {
        flexDirection: "row",
    },
    labelHolder: {
        flexBasis: 150,
        marginBottom: theme.spacingScale / 2,
    },
    labelText: {
        fontWeight: "bold",
    },
    customerText: {
        flex: 5,
    },
    tags: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    textLink: {
        ...theme.styles.link,
    },
});
