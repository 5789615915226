import { useThemedStyle } from "@venuepos/react-common";
import React from "react";
import { View } from "react-native";
import { dashboardStyleFunc } from "../styles";
import { InvoiceStats } from "./invoice-stats";
import { InvoicesPerMonthStats } from "./invoices-per-month-stats";

export function InvoiceDashboard() {
    const sharedStyles = useThemedStyle(dashboardStyleFunc);

    return (
        <View style={sharedStyles.row}>
            <InvoiceStats />
        </View>
    );
}

export function InvoicesPerMonthDashboard() {
    const sharedStyles = useThemedStyle(dashboardStyleFunc);

    return (
        <View style={sharedStyles.row}>
            <InvoicesPerMonthStats />
        </View>
    );
}
