import { useCallback, useState } from "react";
import {
    GQUsernameAvailabilityQuery,
    GQUsernameAvailabilityQueryVariables,
    UsernameAvailabilityDocument,
} from "graphql-sdk";
import { useApolloClient } from "@apollo/client";

export function useUsernameAvailability(): [
    (username: string, currentUsername?: string) => Promise<boolean>,
    boolean | undefined
] {
    const gqlClient = useApolloClient();
    const [available, setAvailable] = useState<boolean>();

    return [
        useCallback(
            async (username: string, currentUsername?: string) => {
                if (currentUsername && username === currentUsername) {
                    setAvailable(true);
                    return true;
                }

                // On submit we should check if username is already taken
                const usernameAvailable = await gqlClient.query<
                    GQUsernameAvailabilityQuery,
                    GQUsernameAvailabilityQueryVariables
                >({
                    query: UsernameAvailabilityDocument,
                    variables: {
                        username,
                    },
                });

                const result =
                    !!usernameAvailable.data &&
                    usernameAvailable.data.userAvailability;

                setAvailable(result);
                return result;
            },
            [gqlClient]
        ),
        available,
    ];
}
