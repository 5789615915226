import {
    LoadingScreen,
    StyleFunction,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQCustomerGroupInput,
    useCustomerGroupLazyQuery,
    useCustomerGroupSaveMutation,
} from "graphql-sdk";
import { schemaCustomerGroup } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useHandleMutationError } from "../../../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../../../navigation";
import { Form } from "../form";
import { GroupCustomers } from "./group-customers";

type ScreenProps = RootStackScreenProps<"CUSTOMER_GROUP_EDIT">;

export function CustomerGroupEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.customer");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const groupId = route.params.id;

    const [customerGroupEdit] = useCustomerGroupSaveMutation();
    const [getCustomerGroup, customerGroup] = useCustomerGroupLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<GQCustomerGroupInput>(schemaCustomerGroup, null);
    const [{ values }, { setDefaultValues }] = form;
    const { handleMutationError } = useHandleMutationError();

    useEffect(() => {
        if (!groupId) {
            navigate("ACCOUNT_PAYMENTS");
            return;
        }

        getCustomerGroup({ variables: { id: groupId } });
    }, [getCustomerGroup, navigate, groupId]);

    useEffect(() => {
        if (customerGroup.data && customerGroup.data.customerGroup) {
            setDefaultValues(customerGroup.data.customerGroup);
        }
    }, [customerGroup.data, setDefaultValues]);

    const edit = useCallback(async () => {
        if (
            !customerGroup.data ||
            !customerGroup.data.customerGroup ||
            !values
        ) {
            return;
        }

        await handleMutationError(
            async () =>
                await customerGroupEdit({
                    variables: {
                        id: customerGroup.data!.customerGroup.id,
                        customerGroup: {
                            name: values.name,
                        },
                    },
                }),
            t("backoffice.customer_group.saved", "Customer group saved"),
            () => {
                navigate("ACCOUNT_PAYMENTS", { refetch: true });
            }
        );
    }, [
        customerGroup.data,
        values,
        handleMutationError,
        t,
        customerGroupEdit,
        navigate,
    ]);

    if (!customerGroup.data) {
        return <LoadingScreen style="light" />;
    }

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <Form
                    form={form}
                    onSubmit={edit}
                    submitButton={["common.save", "Save"]}
                />
            </View>
            <View style={styles.right}>
                <GroupCustomers group={groupId} />
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    left: {
        marginBottom: theme.spacingScale * 2,
        marginRight: theme.spacingScale * 3,
        minWidth: 400,
    },
    right: {
        minWidth: 250,
    },
});
