import React from "react";
import {
    Text as TextImpl,
    TextStyle,
    StyleProp,
    TextProps as TextPropsImpl,
} from "react-native";
import { useTheme } from "../../theme";

interface TextProps extends TextPropsImpl {
    style?: StyleProp<TextStyle>;
    children: React.ReactNode;
}

export function Text(props: TextProps) {
    const theme = useTheme();
    return <TextImpl {...props} style={[theme.styles.text, props.style]} />;
}
