import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import { merge } from "lodash";

// Add available locales here
enum availableLocale {
    en = "en",
    da = "da",
    "da-DK" = "da-DK",
    "en-US" = "en-US",
}
export type AvailableLocale = keyof typeof availableLocale;
export const DEFAULT_LOCALE: AvailableLocale = "en";

// Load translations
const resources: InitOptions["resources"] = {
    // TODO: Move the translation into a i18next backend module so that we can load this at runtime instead of storing everything in RAM

    en: {
        translation: merge(
            require("./en/generated-translation.json"),
            require("./en/static-translation.json")
        ),
    },

    da: {
        translation: merge(
            require("./da/generated-translation.json"),
            require("./da/static-translation.json")
        ),
    },
};

i18n.init({
    resources,
    lng: DEFAULT_LOCALE,
    fallbackLng: DEFAULT_LOCALE,
    compatibilityJSON: "v3",
});

export async function setupReact() {
    // Start initializing i18next. Use english as language until we get the language from DeviceInfo
    i18n.use(initReactI18next); // passes i18n down to react-i18next
    await i18n.init({
        resources,
        lng: DEFAULT_LOCALE,
        fallbackLng: DEFAULT_LOCALE,
        compatibilityJSON: "v3",
    });
}

export function isLocale(locale: string): locale is AvailableLocale {
    return locale in availableLocale;
}

export { useTranslation } from "react-i18next";
