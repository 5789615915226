import type { ValidationSchema } from "../validation";
import type { GQAccountInput } from "graphql-sdk";
import type { IAccountInput } from "./iaccount";

export const schemaAccount: ValidationSchema<GQAccountInput> = {
    name: { required: true },
    status: { required: true },
    amount: { required: true },
};

export const schemaGiftCardAccount: ValidationSchema<GQAccountInput> = {
    name: { required: true },
    status: { required: true },
    amount: { required: true },
    vatId: { required: true },
};

export const defaultAccount: IAccountInput = {
    name: "",
    type: "",
    status: "",
    amount: 0,
    externalId: null,
};
