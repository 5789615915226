import {
    Alert,
    Button,
    ConfirmModal,
    DataTable,
    Icon,
    IconButton,
    Loading,
    SearchInput,
    StyleFunction,
    Surface,
    Text,
    useAuth,
    useModal,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    useAllMiscButtonsQuery,
    useMiscButtonsDeleteMutation,
} from "graphql-sdk";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, View } from "react-native";

import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";

type ScreenProps = RootStackScreenProps<"MISC_BUTTONS">;

export function MiscButtonListScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce(
        ["merchant.misc_button.write", "merchant.misc_button.delete"],
        "OR"
    );

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const { render } = useModal();
    const [deleteMiscButton] = useMiscButtonsDeleteMutation();
    const { handleMutationError } = useHandleMutationError();

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination();
    const [search, setSearch] = useState<string>("");

    const { data, loading, error, refetch } = useAllMiscButtonsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const handleDelete = useCallback(
        async (id: string) => {
            if (
                !(await render(onClose => (
                    <ConfirmModal
                        headerText={t(
                            "backoffice.misc_buttons.delete",
                            "Delete Function Keys?"
                        )}
                        bodyText={t(
                            "backoffice.misc_buttons.delete_explain",
                            "If you choose to delete these function keys, the cash registers that use these settings, will stop working. Make sure that no cash register use these settings before you continue.\n\nAre you sure, you want to delete this?"
                        )}
                        onClose={onClose}
                    />
                )))
            ) {
                return;
            }

            await handleMutationError(
                async () => await deleteMiscButton({ variables: { id } }),

                t(
                    "backoffice.misc_button.deleted",
                    "Function Key Configuration deleted"
                ),

                async () => {
                    await refetch();
                }
            );
        },
        [deleteMiscButton, handleMutationError, refetch, render, t]
    );

    const handleEdit = useCallback(
        (id: string) => {
            navigate("MISC_BUTTON_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list (in case the user changed to a different page)
            onPageChange(0);
        },
        [onPageChange]
    );

    if (error) {
        return (
            <Alert type="error">
                {t("common.error", "There was an error: {{errorText}}", {
                    errorText: error.message,
                })}
            </Alert>
        );
    }
    return (
        <AdminContainer>
            <View style={styles.container}>
                {auth.may("merchant.misc_button.write") && (
                    <Button
                        style={styles.button}
                        onPress={() => {
                            navigate("MISC_BUTTON_CREATE");
                        }}
                    >
                        {t("common.create", "Create")}
                    </Button>
                )}
                <Text>
                    {t(
                        "backoffice.misc_buttons.text",
                        "Choose which function keys to show on the application"
                    )}
                </Text>
            </View>
            <SearchInput onChange={handleSearchTextChange} />
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.sortIcon}>
                            <Icon name="sort" color={styles.sortIcon.color} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {(loading && <Loading />) || (
                        <FlatList
                            data={data!.allMiscButtons.data}
                            renderItem={({ item }) => (
                                <DataTable.Row
                                    onPress={
                                        auth.may("merchant.misc_button.write")
                                            ? () => handleEdit(item.id)
                                            : undefined
                                    }
                                >
                                    <DataTable.Cell>{item.name}</DataTable.Cell>
                                    <DataTable.Cell numeric>
                                        {auth.may(
                                            "merchant.misc_button.write"
                                        ) && (
                                            <IconButton
                                                color={styles.rowButton.color}
                                                name="edit"
                                                onPress={() =>
                                                    handleEdit(item.id)
                                                }
                                            />
                                        )}
                                        {auth.may(
                                            "merchant.misc_button.delete"
                                        ) && (
                                            <IconButton
                                                color={styles.rowButton.color}
                                                name="delete"
                                                onPress={() =>
                                                    handleDelete(item.id)
                                                }
                                            />
                                        )}
                                    </DataTable.Cell>
                                </DataTable.Row>
                            )}
                            keyExtractor={(_, index) => index.toString()}
                        />
                    )}

                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.allMiscButtons.pagination.pages}
                        itemCount={data?.allMiscButtons.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "space-between",

        marginBottom: theme.spacingScale * 2,
        flexWrap: "wrap",
    },
    button: { alignSelf: "flex-end" },
    sortIcon: {
        justifyContent: "flex-end",
        color: theme.colors.black,
    },
    rowButton: {
        color: theme.colors.secondary,
    },
});
