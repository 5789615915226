import type { IRolePermissionInput } from "./irole-permission";
import type { IRole } from "./irole";

export type RolePermissionValue = "enabled" | "disabled";

export type RolePermissions = {
    // Merchant scope
    "merchant.user.write": RolePermissionValue;
    "merchant.user.delete": RolePermissionValue;
    "merchant.role": RolePermissionValue;
    "merchant.product": RolePermissionValue;
    "merchant.department.write": RolePermissionValue;
    "merchant.department.delete": RolePermissionValue;
    "merchant.cash_register.write": RolePermissionValue;
    "merchant.cash_register.delete": RolePermissionValue;
    "merchant.layout.write": RolePermissionValue;
    "merchant.layout.delete": RolePermissionValue;

    // POS related permissions
    "merchant.pos.custom_item": RolePermissionValue;
    "merchant.pos.discount": RolePermissionValue;
    "merchant.pos.print_xz_report": RolePermissionValue;
    "merchant.pos.print": RolePermissionValue;
    "merchant.pos.open_cash_drawer": RolePermissionValue;
    "merchant.pos.refund": RolePermissionValue;
    "merchant.pos.return": RolePermissionValue;
    "merchant.pos.scan_with_camera": RolePermissionValue;
    "merchant.pos.unclaim_device": RolePermissionValue;
    "merchant.pos.undo_order": RolePermissionValue;

    /**
     * The `merchant.pos.self_service` permission is used to limit the UI of the POS. This contrasts with the
     * rest of the permissions that are normally used to expand the options of the POS/system in general
     */
    "merchant.pos.self_service": RolePermissionValue;

    "merchant.customer": RolePermissionValue;
    "merchant.discount": RolePermissionValue;
    "merchant.merchant_settings": RolePermissionValue;
    "merchant.misc_button.write": RolePermissionValue;
    "merchant.misc_button.delete": RolePermissionValue;
    "merchant.payment_method.write": RolePermissionValue;
    "merchant.payment_method.delete": RolePermissionValue;
    "merchant.payment_configuration.write": RolePermissionValue;
    "merchant.payment_configuration.delete": RolePermissionValue;
    "merchant.tag": RolePermissionValue;
    "merchant.currency": RolePermissionValue;
    "merchant.report": RolePermissionValue;
    "merchant.invoice": RolePermissionValue;
    "merchant.shift": RolePermissionValue;
    "merchant.accounts": RolePermissionValue;
    "merchant.printer": RolePermissionValue;
    "merchant.vat": RolePermissionValue;

    // Admin scope
    "admin.merchant.write": RolePermissionValue;
    "admin.merchant.delete": RolePermissionValue;
    "admin.merchant.login_as_role": RolePermissionValue;
    "admin.user.write": RolePermissionValue;
    "admin.user.delete": RolePermissionValue;
    "admin.role": RolePermissionValue;
};

export const defaultRolePermissions: RolePermissions = {
    // Merchant scope
    "merchant.user.write": "disabled",
    "merchant.user.delete": "disabled",
    "merchant.role": "disabled",
    "merchant.product": "disabled",
    "merchant.department.write": "disabled",
    "merchant.department.delete": "disabled",
    "merchant.cash_register.write": "disabled",
    "merchant.cash_register.delete": "disabled",
    "merchant.layout.write": "disabled",
    "merchant.layout.delete": "disabled",
    "merchant.pos.custom_item": "disabled",
    "merchant.pos.discount": "disabled",
    "merchant.pos.print_xz_report": "disabled",
    "merchant.pos.print": "disabled",
    "merchant.pos.open_cash_drawer": "disabled",
    "merchant.pos.refund": "disabled",
    "merchant.pos.return": "disabled",
    "merchant.pos.scan_with_camera": "disabled",
    "merchant.pos.unclaim_device": "disabled",
    "merchant.pos.self_service": "disabled",
    "merchant.pos.undo_order": "disabled",
    "merchant.customer": "disabled",
    "merchant.discount": "disabled",
    "merchant.merchant_settings": "disabled",
    "merchant.misc_button.write": "disabled",
    "merchant.misc_button.delete": "disabled",
    "merchant.payment_method.write": "disabled",
    "merchant.payment_method.delete": "disabled",
    "merchant.payment_configuration.write": "disabled",
    "merchant.payment_configuration.delete": "disabled",
    "merchant.tag": "disabled",
    "merchant.currency": "disabled",
    "merchant.report": "disabled",
    "merchant.invoice": "disabled",
    "merchant.shift": "disabled",
    "merchant.accounts": "disabled",
    "merchant.printer": "disabled",
    "merchant.vat": "disabled",

    // Admin scope
    "admin.merchant.write": "disabled",
    "admin.merchant.delete": "disabled",
    "admin.merchant.login_as_role": "disabled",
    "admin.user.write": "disabled",
    "admin.user.delete": "disabled",
    "admin.role": "disabled",
};

export function allRolePermissionsEnabled(
    type: IRole["type"],
    ignorePermissions?: string[]
): RolePermissions {
    const permissions = { ...defaultRolePermissions };
    for (const i in permissions) {
        if (ignorePermissions && ignorePermissions.includes(i)) {
            continue;
        }
        if (permissionScope(i as keyof RolePermissions) === type) {
            permissions[i as keyof RolePermissions] = "enabled";
        }
    }
    return permissions;
}

export function rolePermissionsToInput(
    permissions: RolePermissions
): IRolePermissionInput[] {
    let input: IRolePermissionInput[] = [];
    for (const i in permissions) {
        const key = i as keyof RolePermissions;
        const value = permissions[key];
        input.push({
            key,
            value,
        });
    }
    return input;
}

export function rolePermissionsForType(
    type: IRole["type"]
): Partial<RolePermissions> {
    const permissions: Partial<RolePermissions> = {};
    for (const i in defaultRolePermissions) {
        const key = i as keyof RolePermissions;
        if (permissionScope(key) === type) {
            permissions[key] = defaultRolePermissions[key];
        }
    }
    return permissions;
}

export function permissionScope(permission: keyof RolePermissions) {
    return permission.substring(0, permission.indexOf(".")).toUpperCase();
}

export function roleInScope(
    permission: keyof RolePermissions,
    role: Pick<IRole, "type">
) {
    return permissionScope(permission) === role.type;
}
