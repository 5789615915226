import { Link } from "@react-navigation/native";
import {
    DataTable,
    Headline,
    Loading,
    SearchInput,
    Surface,
    Text,
    useMe,
    usePagination,
    useThemedStyle,
} from "@venuepos/react-common";
import { useDashboardCashRegistersQuery } from "graphql-sdk";
import { formatDateTime } from "lib";
import { AvailableLocale, useTranslation } from "locales";
import React, { useCallback, useState } from "react";
import { View } from "react-native";

import { useAdminSession } from "../../../session";
import { dashboardStyleFunc } from "../styles";

export function CashRegisters() {
    const user = useMe();
    const [t] = useTranslation();
    const [{ locale }] = useAdminSession(["locale"]);
    const sharedStyles = useThemedStyle(dashboardStyleFunc);

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
        initialPageSize: 5,
    });
    const [search, setSearch] = useState<string>("");

    const { data, loading, error } = useDashboardCashRegistersQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        },
        fetchPolicy: "no-cache",
    });

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list (in case the user changed to a different page)
            onPageChange(0);
        },
        [onPageChange]
    );

    return (
        <Surface style={sharedStyles.widget}>
            <View style={sharedStyles.tableHeaders}>
                <Headline size="h4">
                    {t("common.cash_registers", "Cash registers")}
                </Headline>
                <Headline size="h5">
                    {!!data?.dashboardCashRegisters.pagination &&
                        t("common.results", "{{count}} results", {
                            count: data?.dashboardCashRegisters?.pagination
                                .resultCount,
                        })}
                </Headline>
            </View>
            <SearchInput onChange={handleSearchTextChange} />
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title
                        onPress={() => onSortChange("name")}
                        sortDirection={sortBy === "name" && sortDirection}
                    >
                        {t("common.name", "Name")}
                    </DataTable.Title>
                    <DataTable.Title
                        onPress={() => onSortChange("merchantName")}
                        sortDirection={
                            sortBy === "merchantName" && sortDirection
                        }
                    >
                        {t("common.merchant", "Merchant")}
                    </DataTable.Title>
                    <DataTable.Title
                        sortDirection={
                            sortBy === "deviceHardwareModel" && sortDirection
                        }
                        onPress={() => onSortChange("deviceHardwareModel")}
                    >
                        {t("common.model", "Model")}
                    </DataTable.Title>
                    <DataTable.Title
                        numeric
                        sortDirection={
                            sortBy === "deviceAppVersion" && sortDirection
                        }
                        onPress={() => onSortChange("deviceAppVersion")}
                    >
                        {t("common.version", "Version")}
                    </DataTable.Title>
                    <DataTable.Title
                        numeric
                        sortDirection={
                            sortBy === "deviceLastSeen" && sortDirection
                        }
                        onPress={() => onSortChange("deviceLastSeen")}
                    >
                        {t("device.last_seen", "Last seen")}
                    </DataTable.Title>
                </DataTable.Header>
                {(!error && loading && <Loading />) ||
                    data?.dashboardCashRegisters?.data.map(register => {
                        if (!register.device) {
                            return (
                                <DataTable.Row key={register.id}>
                                    <DataTable.Cell>
                                        {user.merchant ? (
                                            <Link
                                                to={{
                                                    screen: "CASH_REGISTER_EDIT",
                                                    params: { id: register.id },
                                                }}
                                            >
                                                {register?.name}
                                            </Link>
                                        ) : (
                                            register.name
                                        )}
                                    </DataTable.Cell>
                                    <DataTable.Cell>
                                        {register.merchant?.name}
                                    </DataTable.Cell>
                                    <DataTable.Cell>{}</DataTable.Cell>
                                    <DataTable.Cell numeric>{}</DataTable.Cell>
                                    <DataTable.Cell numeric>{}</DataTable.Cell>
                                </DataTable.Row>
                            );
                        }
                        return (
                            <DataTable.Row key={register.id}>
                                <DataTable.Cell>
                                    {user.merchant ? (
                                        <Link
                                            to={{
                                                screen: "CASH_REGISTER_EDIT",
                                                params: { id: register.id },
                                            }}
                                        >
                                            {register?.name}
                                        </Link>
                                    ) : (
                                        register.name
                                    )}
                                </DataTable.Cell>
                                <DataTable.Cell>
                                    {register.merchant?.name}
                                </DataTable.Cell>
                                <DataTable.Cell>
                                    {register.device?.hardwareModel}
                                </DataTable.Cell>
                                <DataTable.Cell numeric>
                                    <Text
                                        numberOfLines={1}
                                        lineBreakMode="middle"
                                    >
                                        {register.device?.appVersion}
                                    </Text>
                                </DataTable.Cell>
                                <DataTable.Cell numeric>
                                    {!!register.device?.lastSeen &&
                                        formatDateTime(
                                            register.device?.lastSeen,
                                            locale as AvailableLocale
                                        )}
                                </DataTable.Cell>
                            </DataTable.Row>
                        );
                    })}
                <DataTable.Pagination
                    onPageChange={onPageChange}
                    pageSize={pageSize}
                    onSizeChange={onPageSizeChange}
                    page={page}
                    numberOfPages={
                        data?.dashboardCashRegisters?.pagination.pages
                    }
                    itemCount={
                        data?.dashboardCashRegisters.pagination.resultCount
                    }
                />
            </DataTable>
        </Surface>
    );
}
