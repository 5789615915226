import type { ReportDefinition, ReportRequest } from "lib";
import { useTranslation } from "react-i18next";
import {
    Headline,
    InputControl,
    MultiPicker,
    useThemedStyle,
} from "@venuepos/react-common";
import React, { useCallback } from "react";
import { reportStyleFunc } from "./styles";
import { View } from "react-native";
import { resolveFieldValues } from "./functions";

export function Fields(props: {
    fields: ReportDefinition["fields"];
    selectedFields: ReportRequest<ReportDefinition>["fields"];
    onFieldsChange: (values: ReportRequest<ReportDefinition>["fields"]) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(reportStyleFunc);

    const handleValueChange = useCallback(
        selectedItems => {
            let returnValue: Record<
                keyof ReportDefinition["fields"],
                Record<string, any>
            > = {};
            selectedItems.forEach(
                (fieldName: keyof ReportDefinition["fields"]) => {
                    returnValue[fieldName] = {};
                }
            );

            props.onFieldsChange(returnValue);
        },
        [props]
    );

    let { selectedFields: userSelectedFields } = props;
    const {
        fields,
        selectedFields: preSelectedFields,
        requiredFields,
    } = resolveFieldValues(props.fields, t);

    // Use the pre-selected set from the definition - unless the user has selected something.
    let selectedValues: string[] = preSelectedFields;
    if (Object.keys(userSelectedFields).length !== 0) {
        selectedValues = Object.keys(userSelectedFields);
    }

    const handleOnClear = useCallback(() => {
        // If the user clears all the values in the MultiPicker, then revert the
        // selection of fields to all the required and pre-selected fields
        let returnValue: Record<
            keyof ReportDefinition["fields"],
            Record<string, any>
        > = {};
        fields
            .filter(field =>
                [...preSelectedFields, ...requiredFields].includes(field.value)
            )
            .forEach(field => {
                returnValue[field.value] = {};
            });

        props.onFieldsChange(returnValue);
    }, [fields, preSelectedFields, props, requiredFields]);

    if (!Object.keys(props.fields).length) {
        return null;
    }

    return (
        <View>
            <Headline size="h5">{t("report.fields", "Fields")}</Headline>
            <View style={styles.row}>
                <InputControl
                    style={[styles.inputCell, styles.inputCellMax]}
                    key="fields"
                    description={t(
                        "report.description.fields",
                        "Show the selected fields as columns in the report data"
                    )}
                >
                    <MultiPicker
                        values={fields}
                        selectedValues={selectedValues}
                        fixedValues={requiredFields}
                        onValueChange={handleValueChange}
                        onClear={handleOnClear}
                    />
                </InputControl>
            </View>
        </View>
    );
}
