import "react-datepicker/dist/react-datepicker.min.css";

import {
    da as daLocale,
    enGB as enGBLocale,
    enUS as enUSLocale,
} from "date-fns/locale";
import { dateTimeToDate, formatTime, TimeType } from "lib/src/date";
import { AvailableLocale } from "locales";
import React, { useCallback } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import {
    StyleProp,
    StyleSheet,
    TextInput,
    TextStyle,
    View,
    ViewStyle,
} from "react-native";

import { StyleFunction, useThemedStyle } from "../../theme";
import { InputLabel } from "../input-label";
import { Portal } from "../portal";
import { TimeInput } from "./time-input";

import type { TimeString, Types } from "./common";
registerLocale("da", daLocale);
registerLocale("da-DK", daLocale);
registerLocale("en-GB", enGBLocale);
registerLocale("en-US", enUSLocale);

export function DateTimePickerInput<T extends keyof Types>(props: {
    dateTimeType?: T;
    label?: string;
    disabled?: boolean;
    defaultValue?: Types[T];
    value?: Types[T];
    onChange?: (date: Types[T] | undefined) => void;
    onChangeDate?: (date: Date) => void; // Sometimes it's nice to get the actual Date object
    style?: StyleProp<ViewStyle | TextStyle>;
    testID?: string;
}) {
    const styles = useThemedStyle(styleFunc);
    const [, { language }] = useTranslation();

    // const
    const date =
        (props.value && dateTimeToDate(props.value)) ||
        (props.defaultValue && dateTimeToDate(props.defaultValue));

    const style = StyleSheet.flatten([
        styles.container,
        props.disabled ? styles.disabled : undefined,
    ]);

    const handleChange = useCallback(
        (d: Date | null) => {
            if (!d) {
                return;
            }

            if (props.onChange) {
                const dvalue = decodeEventValue(
                    props.dateTimeType || "dateTime",
                    d
                );
                props.onChange(dvalue as any);
            }

            if (props.onChangeDate) {
                props.onChangeDate(d);
            }
        },
        [props]
    );

    const handleTimeChange = useCallback(
        (time: TimeType) => {
            if (!time) {
                return;
            }

            if (!props.onChange) {
                return;
            }
            props.onChange(time as any);
        },
        [props]
    );

    let dateFormat = "p"; // time only
    if (props.dateTimeType === "dateTime") {
        dateFormat = "Pp";
    }
    if (props.dateTimeType === "date") {
        dateFormat = "P";
    }

    const popperContainer = useCallback(
        ({ children }) => <Portal>{children}</Portal>,
        []
    );

    return (
        <View style={[props.style]}>
            {props.dateTimeType === "time" ? (
                <TimeInput
                    {...props}
                    value={
                        formatTime(
                            !date ? new Date() : date,
                            language as AvailableLocale,
                            true
                        ) as TimeString
                    }
                    onTimeChange={handleTimeChange}
                />
            ) : (
                <>
                    {props.label && <InputLabel>{props.label}</InputLabel>}
                    <DatePicker
                        title={props.label}
                        locale={language}
                        showTimeInput={
                            props.dateTimeType === "dateTime" || false
                        }
                        value={date as any}
                        selected={date}
                        onChange={handleChange}
                        dateFormat={dateFormat}
                        popperContainer={popperContainer} // Renders Datepicker in the upper scope of DOM
                        customInput={<TextInput style={style} />}
                    />
                </>
            )}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        ...theme.styles.input,
        minHeight: 40,
        backgroundColor: theme.colors.white,
        paddingLeft: 6,
        paddingRight: 6,
        borderStyle: "solid",
        borderRadius: theme.borderRadiusSmall,
        width: "100%",
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: theme.colors.secondary,
        marginBottom: theme.spacingScale,
    },
    disabled: {
        backgroundColor: theme.colors.grey50,
        borderColor: theme.colors.grey500,
        color: theme.colors.grey800,
    },
});

function decodeEventValue<T extends keyof Types>(type: T, date: Date) {
    if (!date) {
        throw new Error("Expecting a date object");
    }
    switch (type) {
        case "time":
            return {
                hour: date.getHours(),
                minute: date.getMinutes(),
            } as Types["time"];

        case "dateTime":
            return {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
                hour: date.getHours(),
                minute: date.getMinutes(),
            } as Types["dateTime"];

        case "date":
            return {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
            } as Types["date"];

        default:
            return undefined;
    }
}
