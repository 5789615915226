import React, { ReactNode } from "react";
import {
    View,
    Image,
    ImageSourcePropType,
    StyleSheet,
    StyleProp,
    ViewStyle,
} from "react-native";
import { Surface } from "../surface";
import { Headline } from "../headline";
import { Text } from "../text";

export function Card({
    children,
    elevation = 4,
    style,
}: {
    children: ReactNode;
    elevation?: number;
    style?: StyleProp<ViewStyle>;
}) {
    return <Surface style={[style, { elevation }]}>{children}</Surface>;
}

Card.Title = ({ children }: { children: string }) => (
    <View style={styles.titleContainer}>
        <Headline size="h2">{children}</Headline>
    </View>
);

Card.Content = ({ children }: { children: ReactNode }) => (
    <View>{children}</View>
);

Card.Actions = ({ children }: { children: ReactNode }) => (
    <View style={styles.actions}>{children}</View>
);

Card.Cover = ({ source }: { source: ImageSourcePropType }) => (
    <View style={[styles.cover]}>
        <Image style={styles.coverImage} source={source} />
    </View>
);

Card.Body = ({ children }: { children: string }) => (
    <View style={[styles.body]}>
        <Text>{children}</Text>
    </View>
);

const styles = StyleSheet.create({
    cover: {
        height: 195,
        backgroundColor: "#f7f7f7",
        overflow: "hidden",
    },
    coverImage: {
        flex: 1,
        height: undefined,
        width: undefined,
        padding: 16,
        justifyContent: "flex-end",
        resizeMode: "cover",
    },
    body: {
        marginHorizontal: 16,
        marginBottom: 8,
    },
    actions: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    titleContainer: {
        marginTop: 16,
        marginHorizontal: 16,
    },
});
