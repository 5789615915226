import { GridDimensions, LayoutButton } from "lib";
import { setButtonPosition } from "./set-button-position";

export const getButtonPositionsForSection = (
    layoutButtons: LayoutButton[],
    gridDimensions: GridDimensions
): string[] => {
    let buttonPositions: string[] = [
        ...new Array(gridDimensions.columns * gridDimensions.rows),
    ].fill("");

    for (let i = 0; i < layoutButtons.length; i++) {
        const button = layoutButtons[i];
        buttonPositions = setButtonPosition(
            buttonPositions,
            gridDimensions,
            { x: button.x, y: button.y },
            { width: button.width, height: button.height },
            button.id!
        );
    }

    return buttonPositions;
};
