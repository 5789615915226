import { Link } from "@react-navigation/native";
import {
    DataTable,
    IconButton,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import React from "react";

import type { GQCustomerGroupsQuery } from "graphql-sdk";
export function Row({
    item,
    onSelect,
    onDelete,
}: {
    item: GQCustomerGroupsQuery["customerGroups"]["data"][0];
    onSelect: () => void;
    onDelete: () => void;
}) {
    const styles = useThemedStyle(styleFunc);
    return (
        <DataTable.Row>
            <DataTable.Cell style={styles.customerGroupNameColumn}>
                <Link
                    to={{
                        screen: "CUSTOMER_GROUP_EDIT",
                        params: { id: item.id },
                    }}
                    style={styles.textLink}
                >
                    {item.name}
                </Link>
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.iconColumn}>
                <IconButton
                    color={styles.iconColumn.color}
                    name="edit"
                    onPress={onSelect}
                />
                <IconButton
                    color={styles.iconColumn.color}
                    name="delete"
                    onPress={onDelete}
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    checkbox: {
        marginVertical: 6,
    },
    customerGroupNameColumn: {
        flexGrow: 1,
        flexBasis: 300,
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
        color: theme.colors.secondary,
    },
    textLink: {
        ...theme.styles.link,
    },
});
