import {
    ActivityIndicator,
    ActivityIndicatorProps,
    ColorValue,
    StyleProp,
    StyleSheet,
    TextStyle,
    View,
    ViewStyle,
} from "react-native";
import React, { useMemo } from "react";
import { useTheme, StyleFunction, useThemedStyle } from "../../theme";
import { Text } from "../text";

export function Loading({
    color,
    message,
    style,
    textStyle,
    size = "large",
    direction = "column",
}: {
    color?: ColorValue;
    message?: string;
    style?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    size?: ActivityIndicatorProps["size"];
    direction?: "row" | "column";
}) {
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);

    const containerStyle = useMemo(() => {
        if (direction === "row") {
            return StyleSheet.flatten([
                styles.container,
                styles.rowContainer,
                style,
            ]);
        }

        return StyleSheet.flatten([styles.container, style]);
    }, [direction, style, styles.container, styles.rowContainer]);

    const messageStyle = useMemo(() => {
        if (direction) {
            return StyleSheet.flatten([
                styles.message,
                styles.rowMessage,
                textStyle,
            ]);
        }

        return StyleSheet.flatten([styles.message, textStyle]);
    }, [direction, styles.message, styles.rowMessage, textStyle]);

    if (!message) {
        return (
            <ActivityIndicator
                size={size}
                color={color || theme.colors.primary}
                testID="loading"
            />
        );
    }

    return (
        <View style={containerStyle}>
            <ActivityIndicator
                size={size}
                color={color || theme.colors.primary}
                testID="loading"
            />
            <Text style={messageStyle}>{message}</Text>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        flexContainer: "column",
        justifyContent: "center",
        alignItems: "center",
    },

    rowContainer: {
        flexDirection: "row",
    },

    message: {
        marginTop: theme.spacingScale,
        marginLeft: 0,
        color: theme.colors.textDark,
    },

    rowMessage: {
        marginTop: 0,
        marginLeft: theme.spacingScale,
    },
});
