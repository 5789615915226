import {
    DataTable,
    IconButton,
    StyleFunction,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import { formatAmount, formatDateTime, SupportedCurrenciesType } from "lib";
import React from "react";
import { useTranslation } from "react-i18next";

import type { GQAccountsQuery } from "graphql-sdk";
import type { AvailableLocale } from "locales";
export function AccountRow({
    item,
    onView,
    onEdit,
    locale,
    currency,
}: {
    item: GQAccountsQuery["accounts"]["data"][0];
    onView: () => void;
    onEdit: () => void;
    locale: AvailableLocale;
    currency: SupportedCurrenciesType;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    return (
        <DataTable.Row>
            <DataTable.Cell>
                <Text
                    style={[
                        styles.textLink,
                        !item.name ? styles.noName : undefined,
                    ]}
                    onPress={onView}
                >
                    {item.name || t("common.no_name", "No name")}
                </Text>
            </DataTable.Cell>
            <DataTable.Cell>
                {t(
                    "account.status." + item.status,
                    "account.status." + item.status
                )}
            </DataTable.Cell>
            <DataTable.Cell>
                {formatDateTime(item.createdAt, locale as AvailableLocale)}
            </DataTable.Cell>
            <DataTable.Cell numeric>
                {formatAmount(item.amount, currency, {
                    printCurrency: true,
                    locale: locale,
                })}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.iconColumn}>
                <IconButton
                    color={styles.iconColumn.color}
                    name="edit"
                    onPress={onEdit}
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
        color: theme.colors.secondary,
    },
    textLink: {
        ...theme.styles.link,
    },
    noName: {
        fontStyle: "italic",
    },
});
