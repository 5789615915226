export type Fraction = {
    numerator: number;
    denominator: number;
};

function greatestCommonFactor(a: number, b: number): number {
    if (a === 0) {
        return b;
    }
    return greatestCommonFactor(b % a, a);
}

export function reduceFraction(a: Fraction): Fraction {
    if (a.numerator === 0 || a.denominator === 0) {
        return { numerator: 0, denominator: 0 };
    }

    const gcf = greatestCommonFactor(a.numerator, a.denominator);
    return {
        numerator: a.numerator / gcf,
        denominator: a.denominator / gcf,
    };
}

export function divideFractions(a: Fraction, b: Fraction): Fraction {
    return reduceFraction({
        numerator: a.numerator * b.denominator,
        denominator: a.denominator * b.numerator,
    });
}

export function multiplyFractions(a: Fraction, b: Fraction): Fraction {
    return reduceFraction({
        numerator: a.numerator * b.numerator,
        denominator: a.denominator * b.denominator,
    });
}

export function subtractFractions(a: Fraction, b: Fraction): Fraction {
    if (b.denominator === 0) {
        return a;
    }
    return reduceFraction({
        numerator: a.numerator * b.denominator - a.denominator * b.numerator,
        denominator: a.denominator * b.denominator,
    });
}

export function addFractions(a: Fraction, b: Fraction): Fraction {
    if (a.numerator === 0) {
        return b;
    } else if (b.numerator === 0) {
        return a;
    }

    const gcf = greatestCommonFactor(a.denominator, b.denominator);
    const denominator = (a.denominator * b.denominator) / gcf;

    return reduceFraction({
        numerator:
            (a.numerator * denominator) / a.denominator +
            (b.numerator * denominator) / b.denominator,
        denominator,
    });
}

export function minFractions(a: Fraction, b: Fraction): Fraction {
    return a.numerator / a.denominator < b.numerator / b.denominator ? a : b;
}
