import { Link } from "@react-navigation/native";
import {
    Alert,
    Loading,
    Spacer,
    StyleFunction,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import { useAccountLazyQuery } from "graphql-sdk";
import { useTranslation } from "locales";
import React, { useEffect } from "react";
import { View } from "react-native";

export function Account({ account }: { account: string }) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();

    const [getAccount, { error, loading, data }] = useAccountLazyQuery();

    useEffect(() => {
        if (!account) {
            return;
        }

        getAccount({
            variables: {
                id: account,
            },
        });
    }, [getAccount, account]);

    if (!data || !data.account) {
        return null;
    }

    return (
        <>
            {error ? (
                <Alert type="error">
                    {t(
                        "backoffice.error.from_server",
                        "There was an error: {{errorText}}",
                        {
                            errorText: error.message,
                        }
                    )}
                </Alert>
            ) : loading ? (
                <>
                    <Spacer />
                    <Loading />
                    <Spacer />
                </>
            ) : (
                data && (
                    <View style={styles.row}>
                        <Text style={styles.text}>
                            {t("common.account", "Account")}
                        </Text>
                        <Link
                            to={{
                                screen: "ACCOUNT_VIEW",
                                params: { id: data.account.id },
                            }}
                            style={styles.textLink}
                        >
                            {data.account.name}
                        </Link>
                    </View>
                )
            )}
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    row: {
        marginBottom: theme.spacingScale,
    },

    text: {
        ...theme.fonts.regular,
        fontWeight: "bold",
        color: theme.colors.textDark,
        fontSize: 18,
    },

    textLink: {
        ...theme.styles.link,
    },
});
