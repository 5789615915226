// I want to be able to extend the cell information here
import type { AvailableLocale } from "locales";

export interface ReportHeader {
    name: string;
    formatAs: FormatAs | undefined;
    noSummation?: boolean;
}

export interface ReportData {
    headers: ReportHeader[];
    rows: any[][];
}

export enum ReportTypes {
    "ACCOUNTS" = "ACCOUNTS",
    "BOOKKEEPING" = "BOOKKEEPING",
    "GIFTCARDS" = "GIFTCARDS",
    "HOUR_SALES" = "HOUR_SALES",
    "INVOICES" = "INVOICES",
    "PAYMENTS" = "PAYMENTS",
    "PRODUCT_INVENTORY" = "PRODUCT_INVENTORY",
    "INVENTORY_PRODUCT_NOTES" = "INVENTORY_PRODUCT_NOTES",
    "PRODUCT_PROFIT" = "PRODUCT_PROFIT",
    "PRODUCT_SALES" = "PRODUCT_SALES",
}

export enum ReportFormat {
    "CSV" = "CSV",
    "JSON" = "JSON",
    "PDF" = "PDF",
    "XLS" = "XLS",
}

export type FormatAs = "DATE" | "DATETIME" | "AMOUNT" | "QUANTITY" | "SIMPLE";

export type Field = { table: string; column: string };

export type FieldTypeDefinitions = {
    COUNT: Record<string, unknown>;
    SUM_INT: Record<string, unknown>;
    SUM_FLOAT: Record<string, unknown>;
    SUM_AMOUNT: Record<string, unknown>;
    AMOUNT: Record<string, unknown>;
    SIMPLE: Record<string, unknown>;
    CUSTOM: { values?: { label: string; value: string }[] };
    REPLACEMENT_MARKER: Record<string, unknown>;
};

export type FilterTypeDefinitions = {
    FROM: { values: { from: string } };
    TO: { values: { to: string } };
    BETWEEN: { values: { from: string; to: string } };
    EQUALS: { value: string | number };
    IN: { values: string[]; type: "UUID" };
    CUSTOM: {};
};

export type GroupByTypeDefinitions = {
    DEPARTMENT: Record<string, unknown>;
    CASH_REGISTER: Record<string, unknown>;
    USER: Record<string, unknown>;
    HOUR: Record<string, unknown>;
    DAY: Record<string, unknown>;
    MONTH: Record<string, unknown>;
    YEAR: Record<string, unknown>;
    LABEL: Record<string, unknown>;
    CUSTOM: Record<string, unknown>;
};

export type OrderByTypeDefinitions = {
    FIELD: { direction: "ASC" | "DESC" };
    CUSTOM: {
        direction: "ASC" | "DESC";
        values?: { label: string; value: string | number }[];
    };
};

export type ReportDefinition = {
    translationKey: string;
    primaryTable: string;
    reportType: keyof typeof ReportTypes;
    joins?: {
        [key: string]: {
            sql: string;
            dependOn?: string[];
        };
    };
    fields: {
        [key: string]:
            | {
                  type: Exclude<
                      keyof FieldTypeDefinitions,
                      "CUSTOM" | "SIMPLE" | "REPLACEMENT_MARKER"
                  >;
                  // No translations in the result data for now, but could be replaced by using {{ report.field.xxx }}
                  field: Field;
                  translationKey: string;
                  selectedByDefault: boolean;
                  required?: boolean;
                  dependOnJoin?: string[];
                  formatAs?: FormatAs;
                  // If true, the report/exports will not sum this column (only needed for numeric fields)
                  noSummation?: boolean;
              }
            | {
                  type: Extract<keyof FieldTypeDefinitions, "CUSTOM">;
                  content: string;
                  translationKey: string;
                  selectedByDefault: boolean;
                  required?: boolean;
                  dependOnJoin?: string[];
                  internalUse?: boolean;
                  doNotGroup?: boolean;
                  formatAs?: FormatAs;
                  noSummation?: boolean;
              }
            | {
                  type: Extract<
                      keyof FieldTypeDefinitions,
                      "REPLACEMENT_MARKER"
                  >;
                  marker: string;
                  translationKey: string;
                  selectedByDefault: boolean;
                  required?: boolean;
                  dependOnJoin?: string[];
                  formatAs?: FormatAs;
                  noSummation?: boolean;
              }
            | {
                  type: Extract<keyof FieldTypeDefinitions, "SIMPLE">;
                  field: Field;
                  translationKey: string;
                  selectedByDefault: boolean;
                  required?: boolean;
                  dependOnJoin?: string[];
                  default?: string;
                  formatAs?: FormatAs;
                  noSummation?: boolean;
              };
    };
    filters: {
        [key: string]:
            | {
                  translationKey: string;
                  type: Exclude<keyof FilterTypeDefinitions, "IN" | "CUSTOM">;
                  field: Field;
                  internalUse?: boolean; // This means that only the backend should use it. This could be merchant_id that is set by the backend in the query
                  dependOnJoin?: string[];
              }
            | {
                  translationKey: string;
                  type: Extract<keyof FilterTypeDefinitions, "IN">;
                  field: Field;
                  inType: "UUID"; // Only support UUID for now
                  internalUse?: boolean;
                  dependOnJoin?: string[];
              }
            | {
                  translationKey: string;
                  type: Extract<keyof FilterTypeDefinitions, "CUSTOM">;
                  content: string;
                  internalUse?: boolean;
                  dependOnJoin?: string[];
              };
    };
    grouping: {
        [key: string]:
            | {
                  translationKey: string;
                  type: Extract<
                      keyof GroupByTypeDefinitions,
                      "DEPARTMENT" | "CASH_REGISTER" | "USER"
                  >;
                  dependOnJoin?: string[];
              }
            | {
                  translationKey: string;
                  type: Extract<
                      keyof GroupByTypeDefinitions,
                      "HOUR" | "DAY" | "MONTH" | "YEAR"
                  >;
                  field: Field;
                  dependOnJoin?: string[];
              }
            | {
                  translationKey: string;
                  type: Extract<keyof GroupByTypeDefinitions, "LABEL">;
                  internalUse?: boolean;
                  dependOnJoin?: string[];
              }
            | {
                  translationKey: string;
                  type: Extract<keyof GroupByTypeDefinitions, "CUSTOM">;
                  content: string;
                  internalUse?: boolean;
                  dependOnJoin?: string[];
              };
    };
    ordering: {
        [key: string]:
            | {
                  translationKey: string;
                  type: Exclude<keyof OrderByTypeDefinitions, "CUSTOM">;
                  internalUse?: boolean;
                  field: string;
              }
            | {
                  translationKey: string;
                  type: Extract<keyof OrderByTypeDefinitions, "CUSTOM">;
                  internalUse?: boolean;
                  content: string;
              };
    };
};

// Note that we use the `field` as identifier to not expose too much info on how are db is structured.
export type ReportRequest<D extends ReportDefinition> = {
    reportType: ReportDefinition["reportType"];
    timezone: string;
    language: AvailableLocale;
    fields: keyof D["fields"] extends never
        ? Record<string, unknown>
        : {
              [F in keyof D["fields"]]?: FieldTypeDefinitions[D["fields"][F]["type"]];
          };
    filters: keyof D["filters"] extends never
        ? Record<string, unknown>
        : {
              [F in keyof D["filters"]]?: FilterTypeDefinitions[D["filters"][F]["type"]];
          };
    grouping: keyof D["grouping"] extends never
        ? Record<string, unknown>
        : {
              [G in keyof D["grouping"]]?: GroupByTypeDefinitions[D["grouping"][G]["type"]];
          };
    ordering: keyof D["ordering"] extends never
        ? Record<string, unknown>
        : {
              [O in keyof D["ordering"]]?: OrderByTypeDefinitions[D["ordering"][O]["type"]];
          };
};

export class QueryBuilderError extends Error {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, QueryBuilderError.prototype);
    }
}
