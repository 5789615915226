import { View } from "react-native";
import {
    Button,
    ModalWrapper,
    Picker,
    StyleFunction,
    useModal,
    useThemedStyle,
} from "@venuepos/react-common";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type AddModalProps<T extends { [key: string]: any }> = {
    headline: string;
    types: { [key in keyof T]: string };
    onClose: (type: keyof T | undefined) => void;
};

function AddModal<T extends { [key: string]: any }>({
    types,
    headline,
    onClose,
}: AddModalProps<T>) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [type, setType] = useState<keyof T>(Object.keys(types)[0]);

    return (
        <ModalWrapper onClose={() => onClose(undefined)} title={headline}>
            <View style={styles.box}>
                <Picker onValueChange={setType} selectedValue={type as string}>
                    {Object.keys(types).map(typesKey => (
                        <Picker.Item
                            key={typesKey}
                            value={typesKey}
                            label={types[typesKey]}
                        />
                    ))}
                </Picker>
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={() => {
                            onClose(type);
                        }}
                    >
                        {t("common.add", "Add")}
                    </Button>
                </View>
            </View>
        </ModalWrapper>
    );
}

const styleFunc: StyleFunction = theme => ({
    box: {
        padding: theme.spacingScale * 2,
        borderRadius: theme.borderRadius,
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
});

export function useAddModal<T extends { [key: string]: any }>() {
    const { render } = useModal<keyof T | undefined>();
    return (props: Omit<AddModalProps<T>, "onClose">) =>
        render(onClose => <AddModal<T> {...props} onClose={onClose} />);
}
