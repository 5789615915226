import {
    RouteProp,
    useFocusEffect,
    useNavigation,
    useRoute,
} from "@react-navigation/native";
import {
    Alert,
    Button,
    CheckBox,
    ConfirmModal,
    DataTable,
    Icon,
    Loading,
    SearchInput,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useModal,
    usePagination,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQTagsQuery, useTagDeleteMutation, useTagsQuery } from "graphql-sdk";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { useMultiSelect } from "../../hooks/use-multi-select";
import { RootStackParamList } from "../../navigation";
import { ImportTagsModal } from "./import-modal";
import { TagItem } from "./tag-item";

export function TagsTab() {
    const auth = useAuth();
    auth.enforce("merchant.tag");

    const { navigate } = useNavigation();
    const route = useRoute<RouteProp<RootStackParamList, "TAGS">>();
    const theme = useTheme();
    const [t] = useTranslation();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
    });

    const styles = useThemedStyle(styleFunc);
    const [search, setSearch] = useState<string>("");
    const { render } = useModal();
    const { handleMutationError } = useHandleMutationError();

    const {
        selectedItems: selectedTags,
        setSelectedItems: setSelectedTags,
        handleMultiSelect,
        allItemsSelected: allRowsSelected,
        setAllItemsSelected: setAllRowsSelected,
    } = useMultiSelect<GQTagsQuery["tags"]["data"][0]["id"]>();

    // GraphQL
    const { data, loading, error, refetch } = useTagsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        },
    });
    const [deleteTag] = useTagDeleteMutation();

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    // Actions
    const handleEdit = useCallback(
        (id: string) => {
            navigate("TAG_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleDelete = useCallback(
        async (id: string) => {
            await handleMutationError(
                async () => {
                    await deleteTag({ variables: { id } });
                },
                t("common.deleted", "Deleted"),
                async () => {
                    await refetch();
                }
            );
        },
        [deleteTag, handleMutationError, refetch, t]
    );

    const deleteSelectedTags = useCallback(async () => {
        const tagIds = [...selectedTags];
        if (
            !(await render(onClose => (
                <ConfirmModal
                    headerText={t(
                        "backoffice.tags.delete_tags",
                        "Delete tags?"
                    )}
                    bodyText={t(
                        "backoffice.layout.delete_tags_explain",
                        "Are you sure you want delete the selected tag(s)?"
                    )}
                    onClose={onClose}
                />
            )))
        ) {
            return;
        }

        await Promise.all([tagIds.map(tagId => handleDelete(tagId))]);
        setSelectedTags([]);
        setAllRowsSelected(false);
    }, [
        handleDelete,
        render,
        selectedTags,
        setAllRowsSelected,
        setSelectedTags,
        t,
    ]);

    const handleImportPress = useCallback(async () => {
        await render(onClose => <ImportTagsModal onClose={onClose} />);
    }, [render]);

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list
            onPageChange(0);
        },
        [onPageChange]
    );

    return (
        <View testID="tagListScreen">
            <View style={styles.container}>
                <View style={styles.actionButtonContainer}>
                    <Button
                        style={styles.button}
                        variant="invert"
                        disabled={!selectedTags.length}
                        onPress={deleteSelectedTags}
                        testID="delete"
                    >
                        {t("common.delete", "Delete")}
                    </Button>
                    <Button
                        variant="invert"
                        style={styles.button}
                        onPress={handleImportPress}
                        testID="import"
                    >
                        {t("common.import", "Import")}
                    </Button>
                    <Button
                        style={styles.button}
                        type="secondary"
                        onPress={() => {
                            navigate("TAG_CREATE");
                        }}
                        testID="create"
                    >
                        {t("common.create", "Create")}
                    </Button>
                </View>
            </View>
            <SearchInput onChange={handleSearchTextChange} testID="tagSearch" />
            <Surface>
                <DataTable testID="tag:list">
                    <DataTable.Header>
                        <DataTable.Title style={styles.iconColumn}>
                            <CheckBox
                                value={allRowsSelected}
                                onValueChange={value => {
                                    const allItemIds = data!.tags.data.map(
                                        p => p.id
                                    );
                                    handleMultiSelect(value, allItemIds);
                                    setAllRowsSelected(value);
                                }}
                                testID="check:selectAll"
                            />
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "tagType" && sortDirection
                            }
                            onPress={() => onSortChange("tagType")}
                        >
                            {t("common.type", "Type")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "group.name" && sortDirection
                            }
                            onPress={() => onSortChange("group.name")}
                        >
                            {t("common.group", "Group")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "account.name" && sortDirection
                            }
                            onPress={() => onSortChange("account.name")}
                        >
                            {t("common.account", "Account")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "customer.name" && sortDirection
                            }
                            onPress={() => onSortChange("customer.name")}
                        >
                            {t("common.customer", "Customer")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "created_at" && sortDirection
                            }
                            onPress={() => onSortChange("created_at")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {error ? (
                        <Alert type="error">
                            {t(
                                "backoffice.error.from_server",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : loading ? (
                        <>
                            <Spacer />
                            <Loading />
                            <Spacer />
                        </>
                    ) : (
                        data &&
                        data?.tags.data.map(
                            (item: GQTagsQuery["tags"]["data"][0]) => (
                                <TagItem
                                    key={item.id}
                                    item={item}
                                    onSelect={() => handleEdit(item.id)}
                                    selectedTag={selectedTags.includes(item.id)}
                                    onMultiSelectChange={handleMultiSelect}
                                    onDelete={() => handleDelete(item.id)}
                                />
                            )
                        )
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.tags.pagination.pages}
                        itemCount={data?.tags.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "space-between",
        marginBottom: theme.spacingScale * 2,
        flexWrap: "wrap",
    },
    actionButtonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    button: {
        alignSelf: "flex-end",
        marginLeft: theme.spacingScale * 2,
    },
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 40 },
});
