import React, { useCallback } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import {
    StyleFunction,
    useThemedStyle,
    useTheme,
    CheckBox,
    Text,
    IconButton,
    ColorIndicator,
} from "@venuepos/react-common";
import { Icon } from "@venuepos/react-common";
import { DataTable } from "@venuepos/react-common";
import { formatQuantity } from "lib";
import { AvailableLocale, useTranslation } from "locales";
import { sharedStyleFunc } from "./product-list-screen";

export function ProductRow(props: {
    item: any;
    formattedAmount: string;
    selectedProduct: boolean;
    onSelect: (itemId: string) => void;
    onMultiSelectChange: (selected: boolean, itemIds: string[]) => void;
    style?: StyleProp<ViewStyle>;
    locale: AvailableLocale;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const sharedStyles = useThemedStyle(sharedStyleFunc);
    const theme = useTheme();
    const {
        item,
        formattedAmount,
        selectedProduct,
        onSelect,
        onMultiSelectChange,
    } = props;

    const handleSelect = () => onSelect(item.id);

    const handleMultiSelectChange = useCallback(
        value => onMultiSelectChange(value, [item.id]),
        [item.id, onMultiSelectChange]
    );

    return (
        <DataTable.Row onPress={handleSelect} style={props.style}>
            <DataTable.Cell style={sharedStyles.iconColumn}>
                <CheckBox
                    value={selectedProduct}
                    onValueChange={handleMultiSelectChange}
                    style={styles.checkbox}
                    testID={"select:" + item.name}
                />
            </DataTable.Cell>
            <DataTable.Cell style={sharedStyles.iconColumn}>
                <ColorIndicator status={item.active ? "success" : "warning"} />
            </DataTable.Cell>
            <DataTable.Cell style={sharedStyles.productNameColumn}>
                {item.parent ? (
                    <View>
                        <Text style={styles.parentName}>
                            {item.parent.name}
                            <Icon
                                name="arrow-down"
                                size="tiny"
                                color={theme.colors.grey500}
                                style={styles.arrowIcon}
                            />
                        </Text>
                        <Text numberOfLines={1}>{item.name}</Text>
                    </View>
                ) : (
                    <Text numberOfLines={1}>{item.name}</Text>
                )}
            </DataTable.Cell>
            <DataTable.Cell>{item.externalId}</DataTable.Cell>
            <DataTable.Cell>{item.group.name}</DataTable.Cell>
            <DataTable.Cell style={sharedStyles.externalColumn}>
                {item.isExternal ? t("common.yes", "Yes") : null}
            </DataTable.Cell>
            <DataTable.Cell numeric>{formattedAmount}</DataTable.Cell>
            <DataTable.Cell
                numeric
                style={sharedStyles.vatColumn}
            >{`${item.vat.percentage}%`}</DataTable.Cell>
            <DataTable.Cell numeric style={sharedStyles.quantityColumn}>
                {formatQuantity(item.inventoryProduct?.quantity || 0, {
                    locale: props.locale,
                    maximumDecimals: 0,
                })}
            </DataTable.Cell>
            <DataTable.Cell numeric style={sharedStyles.iconColumn}>
                <IconButton
                    color={theme.colors.secondary}
                    name="edit"
                    onPress={handleSelect}
                    testID={"product:edit:" + item.name}
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    parentName: {
        fontSize: 10,
        color: theme.colors.grey800,
    },
    checkbox: {
        marginVertical: 6,
    },
    arrowIcon: {
        paddingLeft: theme.spacingScale / 2,
    },
    firstRow: {
        borderTopColor: theme.colors.grey250,
        borderTopWidth: 1,
    },
    lastRow: {
        borderBottomColor: theme.colors.grey250,
        borderBottomWidth: 1,
    },
});
