import React, { useState } from "react";
import {
    StyleSheet,
    TextInput as TextInputImpl,
    TextInputProps as TextInputPropsImpl,
    TextStyle,
    View,
} from "react-native";
import { InputLabel } from "../input-label";
import { StyleFunction, useThemedStyle } from "../../theme";
import { IconButton } from "../icon-button";
import { RequiredIndicator } from "../required-input";

interface TextInputProps extends TextInputPropsImpl {
    label?: string;
    disabled?: boolean;
    disabledStyle?: TextStyle;
    upperCase?: boolean;
    required?: boolean;
    onClear?: () => void;
}

export function TextInput(propsIn: TextInputProps) {
    // Omit upperCase from props, as TextInputImpl doesn't support it
    const { upperCase, onClear, ...props } = propsIn;
    const styles = useThemedStyle(styleFunc);

    const [value, setValue] = useState<string>(props.defaultValue || "");

    if (upperCase) {
        props.onChangeText = text => {
            if (propsIn.onChangeText) {
                propsIn.onChangeText(text.toUpperCase());
            }
            setValue(text.toUpperCase());
        };
        props.value = value;
    }

    return (
        <>
            {props.label && (
                <InputLabel>
                    {props.label} {props.required && <RequiredIndicator />}
                </InputLabel>
            )}
            {onClear ? (
                <View style={[styles.clearContainer]}>
                    <TextInputImpl
                        {...props}
                        style={[
                            styles.input,
                            styles.noClearInput,
                            props.style,
                            props.disabled
                                ? [styles.disabled, props.disabledStyle]
                                : undefined,
                        ]}
                        editable={!props.disabled}
                    />
                    <IconButton
                        onPress={onClear}
                        name="close"
                        color={styles.clearButton.color}
                        disabled={props.disabled}
                        style={styles.clearButton}
                        size="normal"
                    />
                </View>
            ) : (
                <TextInputImpl
                    {...props}
                    style={[
                        styles.input,
                        styles.noClearContainer,
                        props.style,
                        props.disabled
                            ? [styles.disabled, props.disabledStyle]
                            : undefined,
                    ]}
                    editable={!props.disabled}
                    testID={props.testID}
                />
            )}
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    input: {
        ...theme.styles.input,
        minHeight: 34,
        backgroundColor: theme.colors.white,
        borderRadius: theme.borderRadiusSmall,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        paddingHorizontal: 8,
        paddingVertical: 0,
    },
    noClearInput: {
        flex: 1,
    },
    noClearContainer: {
        marginBottom: theme.spacingScale,
        borderStyle: "solid",
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: theme.colors.secondary,
        borderRadius: theme.borderRadiusSmall,
        borderTopRightRadius: theme.borderRadiusSmall,
        borderBottomRightRadius: theme.borderRadiusSmall,
        paddingVertical: 8,
    },
    clearContainer: {
        flexDirection: "row",
        marginBottom: theme.spacingScale,
        paddingVertical: 0,
        borderStyle: "solid",
        borderWidth: StyleSheet.hairlineWidth,
        borderRadius: theme.borderRadiusSmall,
        borderColor: theme.colors.secondary,
    },
    disabled: {
        backgroundColor: theme.colors.grey50,
        borderColor: theme.colors.grey500,
        color: theme.colors.grey800,
    },
    clearButton: {
        color: theme.colors.textDark,
        alignSelf: "center",
        borderColor: theme.colors.error,
    },
});
