import React from "react";
import { useMe, Text } from "@venuepos/react-common";
import { MerchantDashboardScreen } from "./merchant-dashboard";
import { AdminDashboardScreen } from "./admin-dashboard";

export function DashboardScreen() {
    const me = useMe();

    switch (me.role?.type) {
        case "MERCHANT":
            return <MerchantDashboardScreen />;

        case "ADMIN":
            return <AdminDashboardScreen />;
    }

    return (
        <Text>
            {`Dashboard not implemented for role ${
                me.role?.type || "UNDEFINED"
            } yet.`}
        </Text>
    );
}
