import type { ReactNode } from "react";
import { createPortal } from "react-dom";

// Renders whatever thrown into Portal at id="root"
export const Portal = ({ children }: { children: ReactNode }) => {
    const appRoot = document.getElementById("root-portal");
    if (!appRoot) {
        return null; // Wait for next render if we cant find it.
    }
    return createPortal(children, appRoot);
};
