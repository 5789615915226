import {
    LoadingScreen,
    StyleFunction,
    Surface,
    Tabs,
    useAuth,
    useForm,
    useMeLazy,
    useThemedStyle,
} from "@venuepos/react-common";
import { useMerchantLazyQuery, useMerchantSaveMutation } from "graphql-sdk";
import {
    MerchantConfig,
    merchantConfigDefault,
    merchantConfigSchema,
    schemaMerchantInput,
} from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { EntityConfigForm } from "../../components/entity-config";
import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { EditForm } from "./edit/edit-form";
import { MerchantScreen } from "./merchant-screen";

import type { IMerchantInput } from "lib";
export function MerchantSettingsScreen() {
    const auth = useAuth();
    auth.enforce("merchant.merchant_settings");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ load: loadMe }, me] = useMeLazy();
    const merchantId = me!.merchant!.id;

    const { handleMutationError } = useHandleMutationError();
    const [merchantEdit] = useMerchantSaveMutation();
    const [getMerchant, merchant] = useMerchantLazyQuery({
        fetchPolicy: "no-cache",
    });
    const form = useForm<IMerchantInput>(schemaMerchantInput, null);
    const [{ values }, { setDefaultValues }] = form;

    useEffect(() => {
        getMerchant({ variables: { id: merchantId } });
    }, [getMerchant, merchantId]);

    useEffect(() => {
        if (merchant.data && merchant.data.merchant) {
            const p = merchant.data.merchant;
            setDefaultValues({
                name: p.name,
            });
        }
    }, [merchant.data, setDefaultValues]);

    const edit = useCallback(async () => {
        if (!merchant.data || !merchant.data.merchant || !values) {
            return;
        }

        await handleMutationError(async () => {
            await merchantEdit({
                variables: {
                    id: merchant.data!.merchant!.id,
                    merchant: {
                        name: values.name,
                    },
                },
            });
        }, t("common.saved", "Saved"));
    }, [handleMutationError, merchant.data, merchantEdit, t, values]);

    const onEntityConfigSave = useCallback(async () => {
        // Make sure that the current session data is updated
        await loadMe();
    }, [loadMe]);

    if (!merchantId || !merchant.data || !merchant.data.merchant) {
        return <LoadingScreen style="light" />;
    }

    const tabs = [
        t("backoffice.merchant_settings.tabs.configuration", "Configuration"),
        t("backoffice.merchant_settings.tabs.merchant", "Merchant"),
    ];

    return (
        <MerchantScreen>
            <Surface>
                <Tabs labels={tabs}>
                    <View style={styles.box}>
                        <View style={styles.container}>
                            <EntityConfigForm<MerchantConfig>
                                entities={[
                                    {
                                        id: merchantId,
                                        type: "merchant",
                                        referrer: "MERCHANT_SETTINGS",
                                    },
                                ]}
                                defaultValues={merchantConfigDefault}
                                schema={merchantConfigSchema}
                                onSave={onEntityConfigSave}
                            />
                        </View>
                    </View>
                    <View style={styles.box}>
                        <View style={styles.container}>
                            <EditForm
                                form={form}
                                onSubmit={edit}
                                submitButton={["common.save", "Save"]}
                            />
                        </View>
                    </View>
                </Tabs>
            </Surface>
        </MerchantScreen>
    );
}
const styleFunc: StyleFunction = theme => {
    return {
        container: {
            width: "100%",
            maxWidth: theme.dimensions.maxFormWidth,
            alignSelf: "flex-start",
        },
    };
};
