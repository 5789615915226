import { AdminContainer } from "../container";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";

export function PaymentConfigurationScreen({
    children,
    maxWidth,
}: {
    children: React.ReactNode;
    maxWidth?: number;
}) {
    const styles: StyleProp<ViewStyle> = maxWidth
        ? {
              width: "100%",
              maxWidth,
              alignSelf: "center",
          }
        : {};

    return (
        <AdminContainer testID="paymentConfigurationScreen">
            <View style={styles}>{children}</View>
        </AdminContainer>
    );
}
