import { useNavigation } from "@react-navigation/native";
import {
    Button,
    InputControl,
    TextInput,
    useForm,
} from "@venuepos/react-common";
import {
    useCashRegisterClaimMutation,
    useDeviceByPinCodeLazyQuery,
} from "graphql-sdk";
import type { ICashRegisterClaim } from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";

export function CashRegisterClaimForm({
    cashRegisterId,
}: {
    cashRegisterId?: string;
}) {
    const [t] = useTranslation();
    const { navigate } = useNavigation();
    const [deviceByPinCode, deviceQuery] = useDeviceByPinCodeLazyQuery();
    const [claimDevice, claimQuery] = useCashRegisterClaimMutation();
    const { handleMutationError } = useHandleMutationError();

    const [{ values, errors }, { setValue, handleSubmit }] =
        useForm<ICashRegisterClaim>(
            {
                pinCode: { required: true },
            },
            { pinCode: "" }
        );

    useEffect(() => {
        if (!deviceQuery.data || !deviceQuery.data.deviceByPinCode) {
            return;
        }

        // If no cash register was given in navigation parameters, we should redirect to cash register create screen
        navigate("CASH_REGISTER_CREATE", {
            id: deviceQuery.data.deviceByPinCode.id,
        });
    }, [deviceQuery, navigate]);

    const claim = useCallback(async () => {
        if (!values) {
            return;
        }

        if (cashRegisterId) {
            // If a cash register id was given the navigation parameters, we should just claim the device for the register
            await handleMutationError(
                async () =>
                    await claimDevice({
                        variables: {
                            id: cashRegisterId,
                            devicePinCode: values.pinCode,
                        },
                    }),
                t(
                    "backoffice.cash_register_claim.claim_success",
                    "Cash register was successfully claimed"
                ),
                success => {
                    // Check if claim was successful
                    if (!success.data || !success.data.cashRegisterClaim) {
                        return;
                    }

                    navigate("CASH_REGISTER_EDIT", {
                        id: cashRegisterId,
                    });
                }
            );
        } else {
            // If not cash register id was given, we should check if any device with the given pin code exist
            deviceByPinCode({
                variables: {
                    pinCode: values.pinCode,
                },
            });
        }
    }, [
        cashRegisterId,
        claimDevice,
        deviceByPinCode,
        handleMutationError,
        navigate,
        t,
        values,
    ]);

    // Check if pin code was valid
    if (
        (deviceQuery.data && !deviceQuery.data.deviceByPinCode) ||
        (claimQuery.data && !claimQuery.data.cashRegisterClaim)
    ) {
        errors.pinCode = {
            message: t(
                "backoffice.cash_register_claim.invalid_device_pin_code",
                "Invalid device PIN code. Make sure you have entered the correct PIN code and try again. Feel free to contact us if you keep having problems with claiming your device."
            ),
        };
    }

    return (
        <>
            <InputControl error={errors.pinCode}>
                <TextInput
                    upperCase
                    autoCorrect={false}
                    label={t(
                        "backoffice.cash_register_claim.device_pin_code",
                        "Device PIN code"
                    )}
                    placeholder={t(
                        "backoffice.cash_register_claim.enter_device_pin_code",
                        "Enter PIN Code"
                    )}
                    onChangeText={text => setValue("pinCode", text)}
                    onSubmitEditing={handleSubmit(claim)}
                />
            </InputControl>
            <Button onPress={handleSubmit(claim)}>
                {t("backoffice.cash_register.claim", "Claim")}
            </Button>
        </>
    );
}
