import { useRoute, Link, RouteProp } from "@react-navigation/native";
import {
    Button,
    Icon,
    InputControl,
    InputLabel,
    Picker,
    RequiredText,
    StyleFunction,
    Surface,
    TextInput,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQTagFormQuery, GQTagInput } from "graphql-sdk";
import { tagTypes } from "lib";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { VALUE_CREATORS, VALUE_EDITORS } from "../../components/entity-config";

import type { Form as FormComponent } from "@venuepos/react-common";
import { RootStackParamList } from "../../navigation";

const property = "tagGroup";

export function Form(props: {
    form: FormComponent<GQTagInput>;
    formData?: GQTagFormQuery;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const [{ values, errors }, { setValue, handleSubmit }] = props.form;

    const route =
        useRoute<RouteProp<RootStackParamList, "TAG_EDIT" | "TAG_CREATE">>();

    // order the available tag types by name
    // i18next t("tag.type.barcode", "Card (barcode)");
    // i18next t("tag.type.wristband_nfc", "Wristband (NFC)");
    // i18next t("tag.type.card_nfc", "Card (NFC)");
    const orderedTagTypes = useMemo(
        () =>
            tagTypes
                .map(type => ({
                    value: type.value,
                    label: t(type.label, type.label),
                }))
                .sort((type1, type2) => type1.label.localeCompare(type2.label)),
        [t]
    );

    const linkToValueCreator = useMemo(
        () =>
            VALUE_CREATORS[property]
                ? {
                      screen: VALUE_CREATORS[property].route,
                      params: {
                          referrer: {
                              route: route.name,
                              id: route.params?.id,
                          },
                      },
                  }
                : undefined,
        [route.name, route.params?.id]
    );

    const linkToValueEditor = useMemo(
        () =>
            VALUE_EDITORS[property]
                ? {
                      screen: VALUE_EDITORS[property].route,
                      params: {
                          id: values?.groupId,
                          referrer: {
                              route: route.name,
                              id: route.params?.id,
                          },
                      },
                  }
                : undefined,
        [route.name, route.params?.id, values?.groupId]
    );

    if (!values) {
        return null;
    }

    return (
        <Surface>
            <InputControl error={errors.identifier}>
                <TextInput
                    label={t("common.identifier", "Identifier")}
                    placeholder={t(
                        "backoffice.tag_form.enter_identifier",
                        "Enter identifier (barcode, NFC id, etc.)"
                    )}
                    defaultValue={values.identifier}
                    onChangeText={text => setValue("identifier", text)}
                    testID="identifier"
                    required={true}
                />
            </InputControl>

            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name || ""}
                    onChangeText={text =>
                        setValue("name", text !== "" ? text : null)
                    }
                    testID="name"
                />
            </InputControl>

            <InputControl error={errors.tagType}>
                <Picker
                    label={t("common.type", "Type")}
                    onValueChange={value => {
                        setValue("tagType", value);
                    }}
                    selectedValue={values.tagType || ""}
                    testID="type"
                    required={true}
                >
                    <Picker.Item
                        label={t(
                            "backoffice.tag_form.choose_tag_type",
                            "Choose a type"
                        )}
                        value=""
                    />

                    {orderedTagTypes.map(tagType => (
                        <Picker.Item
                            key={tagType.value}
                            value={tagType.value}
                            label={t(tagType.label, tagType.label)}
                            testID={"type:" + tagType.value}
                        />
                    ))}
                </Picker>
            </InputControl>

            <View>
                <InputLabel>
                    {t("common.group", "Group")}
                    {linkToValueCreator || linkToValueEditor ? (
                        <View style={styles.valueHelpers}>
                            {linkToValueCreator && (
                                <Link to={linkToValueCreator}>
                                    <Icon
                                        name="plus"
                                        size="small"
                                        color={styles.labelIcon.color}
                                        style={styles.labelIcon}
                                    />
                                </Link>
                            )}
                            {linkToValueEditor && !!values.groupId && (
                                <Link to={linkToValueEditor}>
                                    <Icon
                                        name="edit"
                                        size="small"
                                        color={styles.labelIcon.color}
                                        style={styles.labelIcon}
                                    />
                                </Link>
                            )}
                        </View>
                    ) : null}
                </InputLabel>

                <InputControl error={errors.groupId}>
                    <Picker
                        onValueChange={value => {
                            setValue("groupId", value);
                        }}
                        selectedValue={values.groupId || ""}
                        testID="tag:tagGroup"
                    >
                        <Picker.Item
                            label={t(
                                "backoffice.tag_form.choose_tag_group",
                                "Choose a tag group"
                            )}
                            value=""
                        />
                        {props.formData?.tagGroups.data.map(tagGroup => (
                            <Picker.Item
                                key={tagGroup.id}
                                value={tagGroup.id}
                                label={tagGroup.name}
                                testID={"group:" + tagGroup.name}
                            />
                        ))}
                    </Picker>
                </InputControl>
            </View>

            <Button onPress={handleSubmit(props.onSubmit)} testID="save">
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>

            <RequiredText />
        </Surface>
    );
}

const styleFunc: StyleFunction = theme => ({
    valueHelpers: {
        flexDirection: "row",
        marginLeft: theme.spacingScale,
    },
    labelIcon: {
        // paddingVertical: 0,
        paddingHorizontal: theme.spacingScale / 2,
        color: theme.colors.primary,
    },
});
