import {
    Alert,
    Button,
    DataTable,
    Icon,
    Loading,
    SearchInput,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useMerchantConfig,
    usePagination,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { useGiftCardsQuery } from "graphql-sdk";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { RootStackScreenProps } from "../../../navigation";
import { useAdminSession } from "../../../session";
import { AdminContainer } from "../../container";
import { AccountRow } from "./account-row";

import type { AvailableLocale } from "locales";
type ScreenProps = RootStackScreenProps<"ACCOUNTS">;

export function AccountsListScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.accounts");

    const theme = useTheme();
    const [t] = useTranslation();
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
    });

    const styles = useThemedStyle(styleFunc);
    const [search, setSearch] = useState<string>("");

    // GraphQL
    const { data, loading, error, refetch } = useGiftCardsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            search: {
                query: search,
            },
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    // Actions
    const handleView = useCallback(
        (id: string) => {
            navigate("ACCOUNT_VIEW", {
                id,
            });
        },
        [navigate]
    );

    const handleEdit = useCallback(
        (id: string) => {
            navigate("ACCOUNT_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleSearchTextChange = useCallback(
        text => {
            setSearch(text);
            // return to first page in list
            onPageChange(0);
        },
        [onPageChange]
    );

    return (
        <AdminContainer>
            <View style={styles.container}>
                <View style={styles.actionButtonContainer}>
                    <Button
                        style={styles.button}
                        type="secondary"
                        onPress={() => {
                            navigate("ACCOUNT_CREATE", {
                                type: "GIFT_CARD",
                            });
                        }}
                    >
                        {t("common.create", "Create")}
                    </Button>
                </View>
            </View>
            <SearchInput onChange={handleSearchTextChange} />
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={sortBy === "name" && sortDirection}
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={sortBy === "status" && sortDirection}
                            onPress={() => onSortChange("status")}
                        >
                            {t("common.status", "Status")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "created_at" && sortDirection
                            }
                            onPress={() => onSortChange("created_at")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            sortDirection={sortBy === "amount" && sortDirection}
                            onPress={() => onSortChange("amount")}
                        >
                            {t("common.balance", "Balance")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {error ? (
                        <Alert type="error">
                            {t(
                                "backoffice.error.from_server",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : loading ? (
                        <>
                            <Spacer />
                            <Loading />
                            <Spacer />
                        </>
                    ) : (
                        data?.giftCards.data.map(item => (
                            <AccountRow
                                key={item.id}
                                item={item}
                                onView={() => handleView(item.id)}
                                onEdit={() => handleEdit(item.id)}
                                locale={locale as AvailableLocale}
                                currency={currency}
                            />
                        ))
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.giftCards.pagination.pages}
                        itemCount={data?.giftCards.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "space-between",
        marginBottom: theme.spacingScale * 2,
        flexWrap: "wrap",
    },
    actionButtonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    button: {
        alignSelf: "flex-end",
        marginLeft: theme.spacingScale,
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
    },
});
