import {
    ColorIndicator,
    DataTable,
    StyleFunction,
    ColorIndicatorStatus,
    useThemedStyle,
} from "@venuepos/react-common";
import type { GQCashRegistersStatusQuery } from "graphql-sdk";
import { formatDateTime } from "lib";
import type { AvailableLocale } from "locales";
import { StyleProp, ViewStyle } from "react-native";
import React from "react";

export function CashRegisterStatusRow({
    item,
    locale,
    style,
}: {
    item: GQCashRegistersStatusQuery["cashRegistersStatus"]["data"][0];
    locale: string;
    style?: StyleProp<ViewStyle>;
}) {
    const styles = useThemedStyle(styleFunc);

    return (
        <DataTable.Row style={style}>
            <DataTable.Cell>{item.name}</DataTable.Cell>
            <DataTable.Cell>{item.merchant.name}</DataTable.Cell>
            <DataTable.Cell>
                {item.merchant.lastNotifySentAt
                    ? formatDateTime(
                          item.merchant.lastNotifySentAt,
                          locale as AvailableLocale
                      )
                    : ""}
            </DataTable.Cell>
            <DataTable.Cell>
                {item.lastAppDataSentAt
                    ? formatDateTime(
                          item.lastAppDataSentAt,
                          locale as AvailableLocale
                      )
                    : ""}
            </DataTable.Cell>
            <DataTable.Cell>
                {item.device
                    ? formatDateTime(
                          item.device.lastSeen,
                          locale as AvailableLocale
                      )
                    : ""}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.iconColumn}>
                <ColorIndicator
                    size="large"
                    status={item.status as ColorIndicatorStatus}
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: theme.spacingScale * 6,
    },
});
