import {
    Button,
    LoadingScreen,
    RequiredText,
    StyleFunction,
    Surface,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    useCashRegisterCreateMutation,
    useDepartmentsQuery,
} from "graphql-sdk";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";
import { CashRegisterCommonForm } from "./cash-register-common-form";

import type { ICashRegisterInput } from "lib";
type ScreenProps = RootStackScreenProps<"CASH_REGISTER_CREATE">;

export function CashRegisterCreateScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.cash_register.write");

    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const departments = useDepartmentsQuery({ fetchPolicy: "no-cache" });
    const [cashRegisterCreate] = useCashRegisterCreateMutation();
    const { handleMutationError } = useHandleMutationError();

    const form = useForm<ICashRegisterInput>(
        {
            name: { required: true },
        },
        {
            name: "",
            departmentId: null,
        }
    );
    const [{ values }, { handleSubmit }] = form;

    const create = useCallback(async () => {
        if (!values) {
            return;
        }

        await handleMutationError(
            async () =>
                await cashRegisterCreate({
                    variables: {
                        cashRegister: {
                            name: values.name,
                            departmentId: values.departmentId,
                            deviceId: route.params?.id,
                        },
                    },
                }),
            t("backoffice.cash_register.created", "Cash register created"),
            result => {
                if (result && result.data) {
                    navigate("CASH_REGISTER_EDIT", {
                        id: result.data.cashRegisterCreate.id,
                    });
                }
            }
        );
    }, [
        cashRegisterCreate,
        handleMutationError,
        navigate,
        route.params?.id,
        t,
        values,
    ]);

    if (!departments || !departments.data) {
        return <LoadingScreen style="light" />;
    }

    return (
        <AdminContainer testID="cashRegisterCreateScreen">
            <View style={styles.box}>
                <View style={styles.container}>
                    <Surface>
                        <CashRegisterCommonForm
                            departments={departments.data.departments.data}
                            form={form}
                        />
                        <Button
                            onPress={handleSubmit(create)}
                            testID="cashRegister:createNew"
                        >
                            {t("common.create", "Create")}
                        </Button>
                        <RequiredText />
                    </Surface>
                </View>
            </View>
        </AdminContainer>
    );
}
const styleFunc: StyleFunction = theme => ({
    box: {
        width: "100%",
        backgroundColor: theme.colors.white,
    },
    container: {
        width: "100%",
        maxWidth: theme.dimensions.maxFormWidth,
        alignSelf: "flex-start",
    },
});
